<template>
    <DataTable
        ref="table"
        searchable
        :endpoint="endpoint"
        :columns="[
            {
                title: 'Title',
                field: 'name',
                sortable: true,
            },
            {
                title: 'Subcategory',
                field: 'category_name',
                sortable: true,
            },
            {
                title: 'Supplier',
                field: 'suppliers_name',
                sortable: true,
            },
            {
                title: 'Fixed',
                field: 'fixed_cost',
                hidden: hiddenColumns.includes('fixed'),
            },
            {
                title: 'Budget',
                field: 'budgeted_cost',
                hidden: hiddenColumns.includes('budgeted'),
            },
            {
                title: 'Net Total',
                field: 'subtotal',
            },
            {
                title: 'Invoiced',
                field: 'total_invoiced',
                hidden: hiddenColumns.includes('invoiced'),
            },
            {
                title: 'Paid',
                field: 'total_paid',
                hidden: hiddenColumns.includes('paid'),
            },
            {
                title: 'Outstanding',
                field: 'total_outstanding',
                hidden: hiddenColumns.includes('outstanding'),
            },
            {
                title: '',
                field: 'actions',
                class: '!tw-text-right',
            },
        ]"
    >
        <template #column(category_name)="{ row }">
            <div class="text-truncate" :title="row.category.name" style="width: 140px">
                <i
                    v-if="row.category.xero_account_id"
                    class="mdi mdi-swap-horizontal-circle text-xero"
                    style="font-size: 1rem"
                    title="Connected to Xero"
                ></i>
                {{ row.category.name }}
            </div>
        </template>
        <template #column(suppliers_name)="{ row }">
            <template v-if="row.supplier">
                <a
                    href="#"
                    class="d-block text-truncate"
                    :title="row.supplier.name"
                    style="width: 170px"
                    @click.prevent="openSupplier(row.supplier)"
                >
                    <i
                        v-if="row.supplier.xero_contact_id"
                        class="mdi mdi-swap-horizontal-circle text-xero"
                        style="font-size: 1rem"
                        title="Connected to Xero"
                    ></i>
                    <span v-if="row.supplier">{{ row.supplier.name }}</span>
                    <span v-else class="text-muted">Unassigned</span>
                </a>
            </template>
            <span v-else class="text-muted">&mdash;</span>
        </template>
        <template #column(fixed_cost)="{ row }">
            <template v-if="row.budget_entry">
                {{ localise(parseFloat(row.budget_entry.fixed_cost), 2, true) }}
            </template>
            <span v-else class="text-muted">&mdash;</span>
        </template>
        <template #column(budgeted_cost)="{ row }">
            <template v-if="row.budget_entry">
                {{ localise(parseFloat(row.budget_entry.budgeted_cost), 2, true) }}
            </template>
            <span v-else class="text-muted">&mdash;</span>
        </template>

        <template #column(subtotal)="{ row }">
            {{ localise(row.subtotal, 2, true) }}
        </template>
        <template #column(total_invoiced)="{ row }">
            {{ localise(row.total_invoiced, 2, true) }}
        </template>
        <template #column(total_paid)="{ row }">
            {{ localise(row.total_paid, 2, true) }}
        </template>
        <template #column(total_outstanding)="{ row }">
            {{ localise(row.total_invoiced_gross - row.total_paid - row.total_credit, 2, true) }}
        </template>
        <template #column(actions)="{ row }">
            <Tooltip v-if="Auth.can('view revenue entries')" label="View" class="tw-ml-1" position="left">
                <Button @click="openEntry(row)">
                    <i class="mdi mdi-eye-outline"></i>
                </Button>
            </Tooltip>
        </template>
    </DataTable>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';

export default {
    components: {
        Button,
        Tooltip,
        DataTable,
    },
    props: {
        endpoint: {
            type: String,
        },
        hiddenColumns: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        Eventbus.$on('reload:entries', () => {
            this.reloadData();
        });
    },
    methods: {
        reloadData(newPage = null) {
            /**
             * NOTE: Whilst understandably, you'd think "this ref would never be null", it can be.
             * Sentry has reported yet I (edward) have not been able to reproduce or figure out where
             * it's truly coming from
             */
            this.$refs.table?.refreshData(newPage);
        },
        openEntry(entry) {
            Eventbus.$emit('revenue:entry', {
                entry: entry.id,
            });
        },
        openSupplier(supplier) {
            if (Auth.can('manage suppliers')) {
                window.open(
                    route('account.contacts', {
                        supplier: supplier.id,
                    }),
                    '_blank'
                );
                return;
            }
            Eventbus.$emit('view:supplier', supplier);
        },
    },
};
</script>
