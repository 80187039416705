<template>
    <Drawer
        ref="drawer"
        title="Budget Entry"
        :loading="isLoading || isUploading"
        header-class="p-0 border-0"
        size="is-large"
        @closing="reopenSource()"
        @closed="resetState()"
    >
        <template v-if="entry" #header>
            <div v-if="!isLoading" class="mb-2">
                <p
                    v-if="entry.is_locked && store.get('current_event.is_locked')"
                    class="px-4 py-1 bg-danger text-white"
                >
                    <span style="font-weight: 600">{{ store.get('current_event.name') }}</span>
                    budget is locked
                </p>
                <p v-else-if="entry.is_locked && isDepartmentLocked" class="px-4 py-1 bg-danger text-white">
                    Department
                    <span style="font-weight: 600">{{ entry.category.department.name }}</span>
                    is locked
                </p>
                <p
                    v-else-if="
                        entry.is_locked && !!store.get(`current_event.categories.${entry.category_id}.is_locked`)
                    "
                    class="px-4 py-1 bg-danger text-white"
                >
                    Subcategory
                    <span style="font-weight: 600">{{ entry.category.name }}</span>
                    is locked
                </p>
                <p v-else-if="entry.lock_budget" class="px-4 py-1 bg-danger text-white">
                    This budget entry is locked and can't be edited
                </p>
                <p v-if="entry.purchase_orders && entry.purchase_orders.length" class="px-4 py-1 bg-info text-white">
                    This budget entry has an associated purchase order
                </p>
                <p v-if="isUsed && overbudget && warnBudget" class="px-4 py-1 bg-danger text-white">
                    This entry is currently over budget
                </p>
                <p v-else-if="isUsed && !overbudget && warnBudget" class="px-4 py-1 bg-warning text-white">
                    This entry is currently over budget for unapproved purchase orders
                </p>
                <p v-if="!userCanEdit()" class="px-4 py-1 bg-info text-white">
                    You do not have permission to edit budget entries
                </p>
            </div>
            <div class="tw-px-5 tw-pt-1 tw-pb-2">
                <div class="tw-flex h2">
                    <EditableText
                        v-model="entry.name"
                        :max-length="255"
                        :editable="isEditable"
                        @update:model-value="debouncedUpdateEntry()"
                    />
                    <LockItemControls
                        lockable-name="Budget Entry"
                        lockable-type="entry"
                        :lockable="entry"
                        class="tw-ml-auto"
                        :disabled="
                            !!store.get(`current_event.categories.${entry.category_id}.is_locked`) ||
                            !!store.get('current_event.is_locked')
                        "
                        @toggle="refreshData()"
                    />
                </div>
                <span class="tw-inline-flex tw-gap-2">
                    <ApprovalControls
                        :approvables="[entry]"
                        :locked="entry.is_locked || !isEditable"
                        :status-only="entry.is_source_recharge"
                    />

                    <Tooltip
                        :disabled="!isUsed || !isEditable"
                        label="This budget entry cannot be deleted as it has a related purchase order"
                        position="bottom"
                    >
                        <Button
                            v-if="Auth.can('delete budget entries') && isEditable"
                            type="is-danger"
                            class="tw-flex tw-gap-2"
                            :disabled="isUploading || isUsed"
                            @click="deleteEntry()"
                        >
                            <i class="mdi mdi-delete"></i>
                            Delete
                        </Button>
                    </Tooltip>
                </span>
            </div>
            <div class="tw-flex tw-items-center tw-px-5 tw-pt-2 tw-pb-4 tw-border-b">
                <FormField class="tw-pr-5">
                    <input-edit
                        v-model="entry.fixed_cost"
                        :label="'Fixed ' + (entry.entry_type === 1 ? 'Cost' : 'Revenue')"
                        size="is-small"
                        :currency="true"
                        :editable="isEditable"
                        @updated="updateEntry()"
                    ></input-edit>
                </FormField>
                <FormField class="tw-px-5">
                    <input-edit
                        v-model="entry.budgeted_cost"
                        :label="'Budgeted ' + (entry.entry_type === 1 ? 'Cost' : 'Revenue')"
                        size="is-small"
                        :currency="true"
                        :editable="isEditable"
                        @updated="updateEntry()"
                    ></input-edit>
                </FormField>
                <FormField :label="markupExists ? 'Markup' : null" class="tw-px-5">
                    <Button
                        v-if="!markupExists && !entry.category.is_revenue && isEditable"
                        type="is-primary"
                        class="tw-flex tw-gap-1 tw-items-center"
                        @click="createMarkup"
                    >
                        <i class="mdi mdi-plus"></i>
                        Markup
                    </Button>
                    <div v-else-if="markupExists && entry.markup" class="tw-flex tw-gap-2 tw-mt-1">
                        <Checkbox v-model="entry.markup.markup_sync_fields" @update:model-value="updateEntry()">
                            Also apply to fixed amount
                        </Checkbox>
                    </div>
                </FormField>
                <FormField
                    label="Created by"
                    class="tw-hidden xl:tw-block-block tw-mb-0 tw-pl-5 tw-ml-auto tw-text-right"
                >
                    <User :user="entry.user" size="small" :hide-name="false" />
                </FormField>
            </div>
            <div class="d-flex align-items-center tw-px-5 tw-pt-3 tw-pb-5">
                <FormField label="Subcategory" class="tw-flex-1 tw-pr-4">
                    <Selector
                        v-model="entry.category_id"
                        :options="availableCategories"
                        :all-options="availableCategories"
                        :allow-clear="isEditable"
                        :editable="isEditable"
                        @update:model-value="updateEntry"
                    >
                        <template #optionLabel="{ option }">{{ option.name }} ({{ option.department_name }})</template>
                    </Selector>
                </FormField>
                <FormField class="tw-flex-1 tw-pl-5" label="Supplier">
                    <SupplierSelector
                        ref="supplierSelector"
                        v-model="entry.supplier_id"
                        :allow-clear="false"
                        :editable="isEditable"
                        @update:model-value="
                            (val) => {
                                entry.supplier_id = val;
                                val ? updateEntry() : null;
                            }
                        "
                    />
                </FormField>
            </div>
        </template>
        <Tabs v-if="entry" :tabs="visibleTabs" container-class="tw-max-h-[calc(100%-35px)] tw-overflow-y-scroll">
            <template #tab(activity)>
                <drawer-comments-tab
                    :activity="entry.activity"
                    :comments="entry.comments"
                    :disabled="!isEditable"
                    allow-external
                    @comment="addComment"
                    @external-comment="(val) => addComment(val, true)"
                    @update-comment="updateComment"
                />
            </template>
            <template #tab(files)>
                <drawer-files-tab
                    class="tw-mt-5 tw-mx-3"
                    :endpoint="
                        route('api.account.event.entries.files.upload', {
                            entry: entry.id,
                        })
                    "
                    :complete-endpoint="
                        route('api.account.event.entries.files.completeUpload', {
                            entry: entry.id,
                        })
                    "
                    :files="entry.files"
                    :disabled="!isEditable"
                    @updated="refreshData()"
                />
            </template>
            <template #tab(purchase_orders)>
                <PurchaseOrdersTab
                    :entry="entry"
                    :purchase-orders="entry.purchase_orders ? entry.purchase_orders : []"
                    :disabled="!isEditable"
                    @opened="closeModal()"
                />
            </template>
            <template v-if="store.get('account.scenarios_enabled')" #tab(adjustments)>
                <drawer-adjustment-tab ref="adjustmentsTab" />
            </template>
        </Tabs>
        <template v-if="entry" #footer>
            <PresenceIndicator :channel="'BudgetEntry.' + entry.id" label="Currently viewing this Budget Entry" />
        </template>
        <BudgetMarkup ref="budgetmarkupmodal" @created="refreshData()" />
    </Drawer>
</template>
<script>
import debounce from 'lodash/debounce';
import Drawer from '../Drawer.vue';
import ApprovalControls from '../widgets/ApprovalControls.vue';
import LockItemControls from '../widgets/LockItemControls.vue';
import Selector from '../controls/Selector.vue';
import SupplierSelector from '../controls/SupplierSelector.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import EditableText from '../controls/EditableText.vue';
import PurchaseOrdersTab from './BudgetEntry/PurchaseOrdersTab.vue';
import Button from '@/js/components/controls/Button.vue';
import BudgetMarkup from '@/js/components/modals/Budgeting/BudgetMarkup.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import PresenceIndicator from '@/js/components/widgets/PresenceIndicator.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import User from '@/js/components/widgets/User.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { localise, toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        SupplierSelector,
        Selector,
        ApprovalControls,
        LockItemControls,
        EditableText,
        BudgetMarkup,
        PurchaseOrdersTab,
        Button,
        FormField,
        Tooltip,
        PresenceIndicator,
        Tabs,
        User,
        Checkbox,
    },
    data() {
        const store = useDataStore();
        return {
            isOpen: false,
            isLoading: false,
            isUploading: false,
            isEdited: false,
            editable: true,
            isRecharge: true,
            entryId: 0,
            entry: null,
            updateData: {},
            purchaseOrders: [],
            overbudget: false,
            warnBudget: false,
            warningFlag: false,
            approvedSubtotal: 0.0,
            unApprovedSubtotal: 0.0,
            scenarioId: 0,
            availableCategories: [],
            store: store,
            source: null,
            markupExists: false,
        };
    },
    computed: {
        isEditable() {
            return (
                Auth.can('update budget entries') && !this.entry.is_locked && !this.store.get('current_event.is_closed')
            );
        },
        isDepartmentLocked() {
            if (this.entry.entry_type === 1) {
                return !!this.store.get(
                    `current_event.cost_departments.${this.entry.category.department_id}.is_locked`
                );
            } else {
                let currentStream = Object.values(this.store.get('current_event.revenue_streams')).find((stream) => {
                    return stream.department_id === this.entry.category.department_id;
                });

                return !!currentStream.is_locked;
            }
        },
        isUsed() {
            if (!this.entry) {
                return false;
            }
            return !!this.entry.purchase_orders.length;
        },
        visibleTabs() {
            const tabs = [
                { title: 'Activity', name: 'activity' },
                { title: 'Files', name: 'files' },
            ];
            if (this.entry && this.entry.entry_type !== 2) {
                tabs.push({ title: 'Purchase Orders', name: 'purchase_orders' });
            }
            if (this.store.get('account.scenarios_enabled')) {
                tabs.push({ title: 'Adjustments', name: 'adjustments' });
            }
            return tabs;
        },
    },
    mounted() {
        Eventbus.$on('budget:entry', (payload) => {
            this.entryId = payload.entry;
            if (payload.scenarioId) {
                this.scenarioId = payload.scenarioId;
            }
            if (!Auth.can('view budget entries')) {
                this.closeModal(true);
            }
            if (payload.source) {
                this.source = payload.source;
            }
            this.resetState();
            this.refreshData(true);
            this.isOpen = true;
            if (this.$refs.drawer) {
                this.$refs.drawer.open();
            }
        });
        Eventbus.$on('open:BudgetEntry', (payload) => {
            if (!Auth.can('view budget entries')) {
                this.closeModal(true);
                return;
            }
            this.resetState();
            this.entryId = payload;
            this.refreshData(true);
            this.isOpen = true;
            if (this.$refs.drawer) {
                this.$refs.drawer.open();
            }
        });
        Eventbus.$on('close:BudgetEntry', () => {
            this.closeModal(true);
        });
        Eventbus.$on('lock:BudgetEntry', () => {
            this.refreshData();
        });
        Eventbus.$on('refresh:BudgetEntry', () => {
            this.refreshData();
        });
    },
    methods: {
        closeModal(force = false) {
            if (force) {
                this.reloadRelatedComponents();
                this.isOpen = false;
                this.isLoading = true;
                this.isEdited = false;
                this.scenarioId = 0;
                this.entry = null;
                this.$refs.drawer.close();
                return;
            }
            if (this.isEdited) {
                this.$buefy.dialog.confirm({
                    title: 'Discard Changes',
                    message: 'Would you like to discard your changes?',
                    onConfirm: () => this.closeModal(true),
                });
                return;
            }
            this.closeModal(true);
        },
        reopenSource() {
            if (this.source) {
                Eventbus.$emit(this.source.emitter, this.source.payload);
                this.source = null;
            }
        },
        userCanEdit() {
            return this.entry !== null && Auth.can('update budget entries') === true;
        },
        refreshData(first = false) {
            if (this.entryId === 0) {
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.entries.get', {
                        entry: this.entryId,
                    })
                )
                .then(({ data }) => {
                    this.entry = data;
                    this.isRecharge = this.rechargeCheck();
                    this.updateData['supplier_id'] = data.supplier_id;
                    this.updateData['category_id'] = data.category_id;
                    this.isLoaded = true;
                    if (first) {
                        this.isUploading = false;
                    }
                    this.poData();
                    this.overbudgetCheck();
                    this.reloadCategories();
                    if (this.$refs.supplierSelector) {
                        this.$refs.supplierSelector.reloadSuppliers();
                    }
                    setTimeout(() => {
                        if (this.$refs.adjustmentsTab) {
                            this.$refs.adjustmentsTab.fetchAdjustments(data);
                        }
                    }, 50);
                })
                .finally(() => {
                    this.markupExists = !!this.entry.markup;
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                });
        },
        reloadCategories() {
            const isRevenue = !this.store.get(`current_event.cost_departments.${this.entry.category.department_id}`);
            this.availableCategories = Object.values(
                this.store.get(isRevenue ? 'current_event.revenue_streams' : 'current_event.cost_departments')
            )
                .map((i) => {
                    if (isRevenue && !i.stream_type) {
                        return [];
                    }
                    return Object.values(i.categories).map((c) => {
                        c.department_name = i.name;
                        return c;
                    });
                })
                .flat();
        },
        deleteEntry() {
            this.isUploading = true;
            axios
                .delete(
                    route('api.account.event.entries.delete', {
                        entry: this.entry.id,
                    })
                )
                .then(() => {
                    this.closeModal(true);
                    toast('Success', 'Budget entry deleted', 'success');
                })
                .finally(() => (this.isUploading = false));
        },
        updateEntry() {
            // updates not allowed if overbudget and no unapproved POs
            if ((this.overbudget && this.warnBudget) || !this.entry.category_id || this.isLoading) {
                return;
            }

            // Only change the warning flag if status is Approved
            // status type is 4, approved status == 4
            if (!this.warningFlag && this.entry.status === 'Approved') {
                if (this.warnBudget) {
                    this.warningFlag = true;
                    swal.fire({
                        type: 'warning',
                        text: 'These changes will void any unapproved Purchase Orders and line items, Click Save again to confirm',
                        title: 'Update Budget Entry',
                    });
                    return;
                }
            }
            // If this is the second "save" request delete the unapproved items
            if (this.warningFlag && this.entry.status === 'Approved') {
                // Removing void POs
                this.cancelPOData();
            }
            this.warningFlag = false;
            this.isUploading = true;

            if (this.isRecharge) {
                this.updateRecharge();
                this.isUploading = false;
                return;
            } else {
                this.updateBudgetEntry();
            }
        },
        debouncedUpdateEntry: debounce(function () {
            this.updateEntry();
        }, 3000),
        updateBudgetEntry() {
            const formData = this.entry;
            delete formData.activity;
            delete formData.comments;

            axios
                .post(
                    route('api.account.event.entries.update', {
                        entry: this.entry.id,
                    }),
                    formData
                )
                .then(({ data }) => {
                    this.entry = data;
                    this.isEdited = false;
                    this.updateData = {};
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.isUploading = false;
                    this.warningFlag = false;
                    this.reloadRelatedComponents();
                });
        },
        updateRecharge() {
            const formData = this.entry;
            delete formData.activity;
            delete formData.comments;
            axios
                .post(
                    route('api.account.event.entries.update', {
                        entry: this.entry.id,
                    }),
                    formData
                )
                .then(({ data }) => {
                    this.entry = data;
                    this.isEdited = false;
                    this.updateData = {};
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.isUploading = false;
                    this.warningFlag = false;
                    this.reloadRelatedComponents();
                });
        },
        addComment(commentText, external = false) {
            if (!commentText) {
                return;
            }
            this.isUploading = true;
            axios
                .post(
                    route('api.account.event.entries.comment', {
                        entry: this.entryId,
                    }),
                    {
                        text: commentText,
                        external,
                    }
                )
                .then(() => this.refreshData())
                .finally(() => (this.isUploading = false));
        },
        updateComment(comment) {
            this.isUploading = true;
            axios
                .post(
                    route('api.account.event.comments.update', {
                        comment: comment.id,
                    }),
                    comment
                )
                .then(() => this.refreshData())
                .finally(() => (this.isUploading = false));
        },
        resetEntry() {
            this.isUploading = true;
            axios
                .get(
                    route('api.account.event.entries.reset', {
                        entry: this.entry.id,
                    })
                )
                .then(({ data }) => {
                    toast('Success', 'Entry status set to Draft', 'success');
                    this.entry = data;
                    this.reloadRelatedComponents();
                    this.refreshData();
                })
                .finally(() => {
                    this.isUploading = false;
                });
        },
        resetState() {
            this.isEdited = false;
            this.entry = null;
            this.approvedSubtotal = 0.0;
            this.unApprovedSubtotal = 0.0;
            this.editable = Auth.can('update budget entries');
            this.overbudget = false;
            this.warnBudget = false;
            this.isRecharge = false;
        },
        poData() {
            this.approvedSubtotal = 0.0;
            this.unApprovedSubtotal = 0.0;
            this.purchaseOrders = [];

            this.entry.purchase_orders.forEach((po) => {
                // Ignore any Purchase Orders which are status STATUS_VOID
                if (po.status !== 'Void') {
                    this.purchaseOrders.push({
                        title: po.title,
                        value: localise(po.subtotal, 2, true),
                        id: po.id,
                    });
                    if (po.uncommitted) {
                        this.approvedSubtotal += po.subtotal;
                    } else {
                        this.unApprovedSubtotal += po.subtotal;
                    }
                }
            });

            if (this.purchaseOrders.length > 1) {
                this.purchaseOrders.sort();
            }
        },
        overbudgetCheck() {
            // In order to do comparison, convert everything to integer numbers
            const poTotal = parseFloat(this.approvedSubtotal) + parseFloat(this.unApprovedSubtotal);
            const updatedValue = this.updateData.budgeted_cost
                ? parseFloat(this.updateData['budgeted_cost'])
                : this.entry.budgeted_cost;

            // Test overall Budget
            if (Math.round(poTotal * 100) <= Math.round(updatedValue * 100)) {
                this.overbudget = false;
                this.warnBudget = false;
                // clear all warnings
            }
            // Overbudget conditions
            // overbudget with the unapproved Items
            else if (
                Math.round((updatedValue - this.approvedSubtotal) * 100) < Math.round(this.unApprovedSubtotal * 100)
            ) {
                this.overbudget = false;
                this.warnBudget = true;
                // Go and void all the pending/created Purchase Orders
            } else if (Math.round(updatedValue * 100) < Math.round(this.approvedSubtotal * 100)) {
                this.overbudget = true;
                this.warnBudget = false;
                //Do not allow/save this change Hide the save button
            } else {
                // Over budget for approved items AND unapproved items
                this.overbudget = true;
                this.warnBudget = true;
            }
        },
        cancelPOData() {
            axios
                .delete(
                    route('api.account.event.entries.deletepo', {
                        entry: this.entry.id,
                    })
                )
                .then(() => {
                    this.resetEntry();
                    this.resetState();
                    this.refreshData();
                });
        },
        rechargeCheck() {
            // A recharge entry has these set to the IDs of parent/sibling
            return !(this.entry.parent_id === null || this.entry.sibling_id === null);
        },
        reloadRelatedComponents() {
            Eventbus.$emit('reload:entries', `department_${this.entry.category.department_id}`);
            Eventbus.$emit('reload:entries', `category_${this.entry.category_id}`);
        },
        createMarkup() {
            this.$refs.budgetmarkupmodal.open({
                donorEntry: this.entry,
            });
        },
    },
};
</script>
