<template>
    <Drawer ref="drawer" :title="(scenarioId ? 'Update' : 'Create') + ' Scenario'" :loading="isLoading" size="is-large">
        <div class="px-5 py-4 border-bottom">
            <FormField label="Title">
                <Textbox v-model="scenario.name" maxlength="255" :disabled="eventIsClosed" />
            </FormField>
            <FormField label="Description" class="tw-mt-2">
                <Textbox v-model="scenario.description" maxlength="255" :disabled="eventIsClosed" />
            </FormField>
            <FormField v-if="scenario.user" label="Created By" class="tw-mt-2">
                <User :user="scenario.user" :hide-name="false" />
            </FormField>
        </div>
        <table-scenario-adjustments v-if="scenarioId" class="flex-fill" :scenario="scenarioId" />
        <template v-if="!eventIsClosed" #footer>
            <div class="tw-px-2 tw-py-1 tw-flex">
                <Button @click="closeModal()">Cancel</Button>
                <Button
                    v-if="scenarioId && Auth.can('manage scenarios')"
                    icon-left="delete"
                    type="is-danger"
                    :disabled="isLoading"
                    class="tw-flex tw-gap-2 !tw-mr-2 !tw-ml-auto"
                    @click="deleteEntry()"
                >
                    <i class="mdi mdi-delete"></i>
                    Delete Scenario
                </Button>
                <Button :disabled="isLoading" type="is-primary" @click="scenarioId ? updateEntry() : createEntry()">
                    {{ scenarioId ? 'Update' : 'Create' }} Scenario
                </Button>
            </div>
        </template>
    </Drawer>
</template>
<script>
import Drawer from '../../Drawer.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import User from '@/js/components/widgets/User.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        Button,
        FormField,
        Textbox,
        User,
    },
    data() {
        return {
            isOpen: false,
            isLoading: false,
            isEdited: false,
            isUsed: false,
            scenarioId: null,
            scenario: {},
            store: useDataStore(),
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
    },
    mounted() {
        Eventbus.$on('scenario:drawerOpen', (payload = null) => {
            if (!payload) {
                this.scenarioId = null;

                this.refreshData();

                return;
            }

            this.isLoading = true;

            this.scenarioId = payload.id;

            Eventbus.$emit('scenarioAdjustments:load', {
                scenarioId: this.scenarioId,
            });

            this.refreshData();
            this.isOpen = true;
            if (this.$refs.drawer) {
                this.$refs.drawer.open();
            }
            this.isLoading = false;
        });
    },
    methods: {
        closeModal() {
            if (this.isEdited) {
                this.$buefy.dialog.confirm({
                    title: 'Discard Changes',
                    message: 'Would you like to discard your changes?',
                    onConfirm: () => this.close(),
                });
            } else {
                this.close();
            }
        },
        close() {
            this.isOpen = false;
            this.isLoading = false;
            this.isEdited = false;
            this.scenarioId = null;
            this.scenario = {};
            Eventbus.$emit('scenarioAdjustments:reset');
            this.$refs.drawer.close();
        },
        openDrawer() {
            this.isOpen = true;
            if (this.$refs.drawer) {
                this.$refs.drawer.open();
            }
        },
        async refreshData() {
            if (this.scenarioId) {
                axios.get(route('api.account.event.scenarios.get', { scenario: this.scenarioId })).then(({ data }) => {
                    this.scenario = data;
                    this.isLoading = false;
                });
            } else {
                this.scenarioId = null;
                this.scenario = {
                    name: '',
                    description: '',
                    user: null,
                };
                this.isLoading = false;
                Eventbus.$emit('scenarioAdjustments:reset');
            }
            this.openDrawer();
        },
        createEntry() {
            this.isLoading = true;
            axios
                .post(route('api.account.event.scenarios.create'), this.scenario)
                .then(() => {
                    this.close();
                    toast('Success', 'Your scenario has been created.', 'success');
                    Eventbus.$emit('reload:scenariosTable');
                })
                .finally(() => (this.isLoading = false));
        },
        async deleteEntry() {
            const { value: confirmation } = await swal.fire({
                title: 'Are you sure?',
                text: 'You are about to completely delete this Scenario (including all adjustments)',
                showCancelButton: true,
            });
            if (!confirmation) {
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.event.scenarios.delete', { scenario: this.scenarioId }), this.scenario)
                .then(() => {
                    toast('Success', 'Your scenario has been deleted.', 'success');
                    location.reload();
                });
        },
        updateEntry() {
            axios
                .post(route('api.account.event.scenarios.update', { scenario: this.scenarioId }), this.scenario)
                .then(() => {
                    this.$refs.drawer.close();
                    toast('Success', 'Your scenario has been updated.', 'success');
                    Eventbus.$emit('reload:scenariosTable');
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.modal-dialog {
    width: 33% !important;

    @media (max-width: 1100px) {
        width: 100% !important;
    }
}

.modal-options {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 25px;
    background: var(--light);
}
</style>
