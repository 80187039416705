<template>
    <span class="tw-inline-block tw-rounded tw-px-2 tw-py-1 tw-text-xs" :class="classes">
        <slot>{{ status }}</slot>
    </span>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        required: false,
        validator: (value) => {
            return ['primary', 'success', 'warning', 'danger', 'info', 'disabled'].includes(value);
        },
    },
    status: {
        type: String,
        required: false,
        validator: (value) =>
            [
                'Draft',
                'Awaiting Approval',
                'Awaiting Reapproval',
                'Approved',
                'Sent',
                'Complete',
                'Paid',
                'Rejected',
                'Void',
            ].includes(value),
    },
});

const classes = computed(() => {
    switch (props.status ?? props.type) {
        case 'primary':
            return 'tw-bg-eventwise tw-text-white';
        case 'success':
        case 'Approved':
        case 'Complete':
        case 'Paid':
            return 'tw-bg-emerald-500 tw-text-white';
        case 'warning':
        case 'Awaiting Approval':
        case 'Awaiting Reapproval':
            return 'tw-bg-amber-300 tw-text-slate-600';
        case 'danger':
        case 'Rejected':
            return 'tw-bg-red-500 tw-text-white';
        case 'info':
        case 'Sent':
            return 'tw-bg-sky-500 tw-text-white';
        case 'disabled':
        case 'Void':
            return 'tw-bg-gray-500 tw-text-white';
        default:
            return 'tw-bg-slate-200 tw-text-slate-600';
    }
});
</script>
