<template>
    <div class="tw-relative">
        <Selector
            v-model="model"
            :options
            :all-options="options"
            :max-displayed-options="5"
            :allow-clear
            placeholder="Type to search"
            :editable="!disabled"
            :item-name
            :items-name
        />
        <ContainedLoader v-if="loading" />
    </div>
</template>
<script setup>
import Selector from '@/js/components/controls/Selector.vue';
import ContainedLoader from '@/js/components/controls/ContainedLoader.vue';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    endpoint: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    itemName: {
        type: String,
        default: 'item',
    },
    itemsName: {
        type: String,
        default: 'items',
    },
    allowClear: {
        type: Boolean,
        default: false,
    },
});

const model = defineModel({
    type: Number,
    default: null,
});

const options = ref([]);
const loading = ref(false);

onMounted(() => {
    reloadOptions();
});

watch(
    () => props.endpoint,
    () => {
        reloadOptions();
    }
);

const reloadOptions = async () => {
    loading.value = true;
    const { data } = await axios.get(props.endpoint);
    options.value = data;
    loading.value = false;
};
</script>
