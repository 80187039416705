<template>
    <div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-else class="buefy-wrapper mb-2">
            <div>
                <table class="table table-bordered table-sm smaller-text mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th v-for="(model, i) of profitModels" :key="model.id">
                                <a
                                    v-if="profitModels.length > 2 && i !== 0"
                                    href="#"
                                    class="ml-3"
                                    @click.prevent="moveColumnLeft(i)"
                                >
                                    <i class="mdi mdi-arrow-left"></i>
                                </a>
                                <a href="#" @click.prevent="hideColumn(model.id)">
                                    <span v-if="eventMode">{{ model.name }}</span>
                                    <GroupTag v-else class="float-left ml-2" :tag="localTags[i]" />
                                </a>
                                <a
                                    v-if="profitModels.length > 2 && i !== profitModels.length - 1"
                                    href="#"
                                    class="ml-3"
                                    @click.prevent="moveColumnRight(i)"
                                >
                                    <i class="mdi mdi-arrow-right"></i>
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">Revenue Budgeted</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-budget-${i}`">
                                {{ localise(profitFigure.budgetedRevenue, 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Revenue Fixed</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-revenue-fixed-${i}`">
                                {{ localise(profitFigure.fixedRevenue, 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Revenue Variance</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-revenue-variance-${i}`">
                                {{ localise(revenueVariance(i), 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Costs Budgeted</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-costs-budgeted-${i}`">
                                {{ localise(profitFigure.budgetedCosts, 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Costs Fixed</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-costs-fixed-${i}`">
                                {{ localise(profitFigure.fixedCosts, 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Costs Variance</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-costs-variance-${i}`">
                                {{ localise(costsVariance(i), 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Profit Budgeted</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-profit-budgeted-${i}`">
                                {{ localise(budgetedProfit(i), 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Profit Fixed</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-profit-fixed-${i}`">
                                {{ localise(fixedProfit(i), 2, true) }}
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">Profit Variance</td>
                            <td v-for="(profitFigure, i) of profitFigures" :key="`profit-figure-profit-variance-${i}`">
                                {{ localise(profitVariance(i), 2, true) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from '../../../../widgets/LoadingSpinner.vue';
import GroupTag from '@/js/components/GroupTag.vue';

export default {
    components: { GroupTag, LoadingSpinner },
    props: {
        models: {
            type: Array,
            required: true,
        },
        profitsAndLosses: {
            type: Array,
            required: true,
        },
        visibleColumns: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            default: 'events',
        },
    },
    emits: ['hidden', 'move-column'],
    data() {
        return {
            isLoading: true,
            profitFigures: [],
            numberOfModels: 0,
            profitModels: [],
            showColumns: [],
            localTags: [],
            tagLoaded: false,
        };
    },
    computed: {
        eventMode() {
            return this.mode === 'events';
        },
    },
    watch: {
        profitsAndLosses: {
            handler(newVal) {
                this.profitFigures = newVal;
            },
            deep: true,
        },
        models: {
            handler(newVal) {
                if (newVal.length > 0) {
                    this.profitModels = newVal;
                    this.numberOfModels = this.profitModels.length;
                }
            },
            deep: true,
        },
        visibleColumns: {
            handler(newVal) {
                this.showColumns = newVal;
                this.numberOfModels = this.showColumns.length;
            },
            deep: true,
        },
    },
    mounted() {
        this.profitFigures = this.profitsAndLosses;
        this.profitModels = this.models;
        this.showColumns = this.profitModels.map((e) => e.id);
        this.numberOfModels = this.profitModels.length;
        if (!this.columnsChanged(this.showColumns, this.visibleColumns)) {
            this.showColumns = this.visibleColumns;
        }
        this.isLoading = false;
        if (!this.eventMode) {
            this.fetchTags();
        }
    },
    methods: {
        revenueVariance(index) {
            return this.profitFigures.length > index
                ? this.profitFigures[index].budgetedRevenue - this.profitFigures[index].fixedRevenue
                : 0;
        },
        costsVariance(index) {
            return this.profitFigures.length > index
                ? this.profitFigures[index].budgetedCosts - this.profitFigures[index].fixedCosts
                : 0;
        },
        fixedProfit(index) {
            return this.profitFigures.length > index
                ? this.profitFigures[index].fixedRevenue - this.profitFigures[index].fixedCosts
                : 0;
        },
        budgetedProfit(index) {
            return this.profitFigures.length > index
                ? this.profitFigures[index].budgetedRevenue - this.profitFigures[index].budgetedCosts
                : 0;
        },
        profitVariance(index) {
            return this.profitFigures.length > index ? this.budgetedProfit(index) - this.fixedProfit(index) : 0;
        },
        hideColumn(modelId) {
            if (this.numberOfModels < 3) {
                return;
            }
            const sliceIndex = this.showColumns.indexOf(modelId);
            if (sliceIndex >= 0) {
                this.showColumns = this.showColumns.filter((id) => id !== modelId);
                // send the event.id back to the parent to use elsewhere
                this.$emit('hidden', modelId);
                this.slicedModels();
            }
        },
        slicedModels() {
            this.profitModels = [];
            this.profitFigures = [];
            // reduce the data to the models listed in the showColumns
            // set the numberOfModels variable
            // reduce the profit figures to match
            this.numberOfModels = this.showColumns.length;
            this.showColumns.forEach((id) => {
                this.models.forEach((event) => {
                    if (id === event.id) {
                        this.profitModels.push(event);
                    }
                });

                this.profitsAndLosses.forEach((figures) => {
                    if (id === figures.id) {
                        this.profitFigures.push(figures);
                    }
                });
            });
        },
        moveColumnLeft(index) {
            const tempIndex = this.showColumns[index - 1];
            this.showColumns[index - 1] = this.showColumns[index];
            this.showColumns[index] = tempIndex;
            this.slicedModels();
            this.$emit('move-column', this.showColumns);
        },
        moveColumnRight(index) {
            const tempIndex = this.showColumns[index + 1];
            this.showColumns[index + 1] = this.showColumns[index];
            this.showColumns[index] = tempIndex;
            this.slicedModels();
            this.$emit('move-column', this.showColumns);
        },
        columnsChanged(array1, array2) {
            if (array1.length !== array2.length) {
                return false;
            }
            for (let i = 0; i < array1.length; i++) {
                if (array1[i] !== array2[i]) {
                    return false;
                }
            }
            return true;
        },
        fetchTags() {
            axios.get(route('api.account.tags')).then(({ data }) => {
                this.localTags = data.grouptags.filter((tag) => this.showColumns.includes(tag.id));
            });
        },
    },
};
</script>
<style scoped lang="scss">
.table-bordered tbody tr td {
    border-left: 1px solid #eaf0f7;
    border-right: 1px solid #eaf0f7;
}
</style>
