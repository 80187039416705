<template>
    <div class="tw-relative tw-flex tw-rounded">
        <span
            v-if="currency"
            class="tw-content-center tw-border tw-border-r-0 tw-border-gray-300 tw-text-gray-500 tw-px-2.5 tw-rounded-l tw-select-none"
        >
            {{ currency }}
        </span>
        <input
            :id="name"
            v-model="model"
            :readonly="readonly"
            :placeholder="placeholder"
            :disabled="disabled || loading"
            :required="required"
            :name="name"
            type="number"
            :step="step"
            class="tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400"
            :class="[
                inputClass,
                {
                    'tw-rounded-l-none': currency,
                },
            ]"
        />
        <ContainedLoader v-if="loading" />
    </div>
</template>

<script setup>
import ContainedLoader from '@/js/components/controls/ContainedLoader.vue';
import { watch } from 'vue';

const { dp } = defineProps({
    name: {
        type: String,
        default: null,
    },
    dp: {
        type: [Number, String],
        required: false,
        default: null,
    },
    min: {
        type: [Number, String],
        default: null,
    },
    step: {
        type: [Number, String],
        default: 1,
    },
    max: {
        type: [Number, String],
        default: null,
    },
    currency: {
        type: String,
        default: null,
    },
    required: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    inputClass: {
        type: String,
        default: null,
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const model = defineModel({
    type: [String, Number],
    default: 0,
});

// If dp passed in then dont allow decimals more than dp
watch(model, () => {
    if (dp && model.value.toString().split('.')[1]?.length > dp) {
        model.value = parseFloat(model.value).toFixed(2);
    }
});
</script>
