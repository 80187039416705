<template>
    <div>
        <div class="tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-3 tw-my-3">
            <FormField field-class="tw-flex tw-items-center tw-gap-1" label="User">
                <Select
                    v-model="search.user"
                    placeholder="User"
                    :disabled="!users.length || (!filteredMatrices.length && !search.user)"
                    class="tw-w-full"
                >
                    <option v-for="user in users" :key="user.id" :value="user.id">
                        {{ user.name }}
                    </option>
                </Select>
                <Button :disabled="!search.user" type="is-primary" @click="search.user = null">
                    <i class="mdi mdi-close-thick"></i>
                </Button>
            </FormField>

            <FormField label="Type" field-class="tw-flex tw-items-center tw-gap-1">
                <Select
                    v-model="search.approvable_type"
                    placeholder="Approvable Type"
                    :disabled="!approvables.length || (!filteredMatrices.length && !search.approvable_type)"
                    class="tw-flex-1"
                >
                    <option v-for="type in approvables" :key="type.id" :value="type.id">
                        {{ type.name }}
                    </option>
                </Select>
                <Button :disabled="!search.approvable_type" type="is-primary" @click="search.approvable_type = null">
                    <i class="mdi mdi-close-thick"></i>
                </Button>
            </FormField>

            <FormField label="Event" field-class="tw-flex tw-items-center tw-gap-1">
                <Select
                    v-model="search.event"
                    placeholder="Event"
                    :disabled="!events.length || (!filteredMatrices.length && !search.event)"
                    class="tw-flex-1"
                >
                    <option v-for="event in events" :key="event.id" :value="event.id">
                        {{ event.name }}
                    </option>
                </Select>
                <Button :disabled="!search.event" type="is-primary" @click="search.event = null">
                    <i class="mdi mdi-close-thick"></i>
                </Button>
            </FormField>
            <Button type="is-primary" class="tw-w-fit tw-h-fit tw-mt-auto" @click="addMatrix">
                <i class="mdi mdi-sitemap"></i>
                Add new matrix
            </Button>
        </div>
        <div class="card">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th style="width: 20%">Users*</th>
                        <th style="width: 15%">Approval Limit*</th>
                        <th style="width: 20%">Approvable Type*</th>
                        <th style="width: 20%">Events*</th>
                        <th style="width: 25%">Categories</th>
                    </tr>
                </thead>
                <tbody>
                    <ApprovalRow
                        v-for="matrix in filteredMatrices"
                        :key="`matrix-${matrix.key}`"
                        :matrix="matrix"
                        :approvables
                        :available-events="events"
                        :users
                        @updated="getMatrices"
                    />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import ApprovalRow from './ApprovalMatrices/ApprovalRow.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';

const approvables = ref([]);
const events = ref([]);
const matrices = ref([]);
const users = ref([]);
const search = ref({
    user: null,
    approvable_type: null,
    event: null,
});

const filteredMatrices = computed(() => {
    if (search.value.user) {
        return matrices.value.filter((matrix) => {
            return matrix.selectedUsers.includes(search.value.user);
        });
    }

    if (search.value.approvable_type) {
        return matrices.value.filter((matrix) => {
            return matrix.approvable_type === search.value.approvable_type;
        });
    }

    if (search.value.event) {
        return matrices.value.filter((matrix) => {
            return matrix.selectedEvents.includes(search.value.event);
        });
    }

    return matrices.value;
});

onMounted(() => {
    getMatrices();
});

function getMatrices() {
    axios.get(route('api.account.approvals.matrices')).then(async (response) => {
        matrices.value = [];
        await nextTick();
        approvables.value = response.data.approvables;
        events.value = response.data.events.map((event) => ({ id: event.id, name: event.name }));
        matrices.value = response.data.matrices;
        users.value = response.data.users;

        matrices.value.forEach(function (matrix, index) {
            matrix.key = index;
        });

        matrices.value.unshift({
            key: matrices.value.length,
            approvable_limit: 0.0,
            limit_operator: '<=',
            approvable_type: '',
            selectedUsers: [],
            selectedEvents: [],
            selectedCategories: [],
        });
    });
}

function addMatrix() {
    search.value = {
        user: null,
        approvable_type: null,
        event: null,
    };

    matrices.value.unshift({
        key: matrices.value.length,
        approvable_limit: 0.0,
        limit_operator: '<=',
        approvable_type: '',
        selectedUsers: [],
        selectedEvents: [],
        selectedCategories: [],
    });
}
</script>
