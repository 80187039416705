<template>
    <div id="app">
        <MinimalPage>
            <template #before>
                <h3 class="text-white d-flex align-items-center mb-1">
                    <i class="mdi mdi-lock h2 text-white mr-2 my-0 mb-1"></i>
                    Two-Factor Authentication
                </h3>
                <div class="user-actions">
                    <div class="user-display">
                        <User :user="currentUser" :hide-name="false" />
                    </div>
                    <a :href="route('auth.logout')">
                        <i class="mdi mdi-logout"></i>
                        Logout
                    </a>
                </div>
            </template>
            <div class="text-center px-5">
                <p>Enter the code from your authenticator app to continue accessing Eventwise</p>
                <form v-if="!hasSuccess" class="my-4" @submit="sendCode">
                    <div class="authenticator-entry buefy-wrapper mb-3" @keydown.enter="sendCode">
                        <input
                            v-for="(value, position) in fields"
                            :key="position"
                            ref="inputs"
                            v-model.number.trim="fields[position]"
                            :data-field="position"
                            type="text"
                            maxlength="1"
                            placeholder="*"
                            class="tw-bg-white tw-caret-transparent tw-border tw-border-slate-200 hover:tw-border-slate-400 focus:tw-border-slate-400 focus:tw-ring-2 tw-rounded tw-w-12 tw-h-16 tw-p-1 tw-text-center tw-text-xl"
                            @keydown="handleInputKeyDown"
                        />
                    </div>
                    <Button :loading="isLoading" :disabled="!isValid" type="is-success" @click="sendCode()">
                        Verify Code
                    </Button>
                    <Button v-if="hasError" class="tw-my-2" type="is-danger">
                        The code you entered was invalid, please try again.
                    </Button>
                </form>

                <Banner v-else type="is-success" class="mt-3">
                    <strong>Authentication Successful</strong>
                    <br />
                    You'll be redirected in just a moment
                </Banner>
                <p class="text-muted">
                    Unable to obtain a code?
                    <br />
                    Get in touch with us and we'll help you to regain access to your account.
                </p>
            </div>
        </MinimalPage>
    </div>
</template>
<script>
import axios from 'axios';
import { useDataStore } from '@/js/stores/DataStore.js';
import MinimalPage from './MinimalPage.vue';
import User from './widgets/User.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        MinimalPage,
        User,
        Button,
        Banner,
    },
    data() {
        const dataStore = useDataStore();
        return {
            isLoading: false,
            currentUser: dataStore.get('current_user'),
            fields: {
                1: null,
                2: null,
                3: null,
                4: null,
                5: null,
                6: null,
            },
            hasError: false,
            hasSuccess: false,
        };
    },
    computed: {
        isValid() {
            return Object.values(this.fields).every((field) => field && !isNaN(field));
        },
    },
    methods: {
        sendCode() {
            if (!this.isValid) {
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.user.mfa.verify'), {
                    one_time_password: Object.values(this.fields).join(''),
                })
                .then(() => {
                    this.hasSuccess = true;
                    window.location.reload();
                })
                .catch(() => {
                    this.hasError = true;
                    this.fields[1] = null;
                    this.fields[2] = null;
                    this.fields[3] = null;
                    this.fields[4] = null;
                    this.fields[5] = null;
                    this.fields[6] = null;
                    this.$refs.inputs[0].focus();
                })
                .finally(() => (this.isLoading = false));
        },
        async handleInputKeyDown(e) {
            const field = parseInt(e.target.dataset.field);
            const fieldIndex = field - 1;

            let next = null;
            let selectionEnd = 0;

            if (e.key === 'Backspace') {
                if (this.fields[field] === '') {
                    next = this.$refs.inputs[fieldIndex].previousElementSibling;
                    selectionEnd = 1;
                } else {
                    if (window.getSelection().toString() !== '') {
                        next = this.$refs.inputs[fieldIndex].previousElementSibling;
                        selectionEnd = 1;
                    }
                    this.fields[field] = '';
                }
            } else if (this.numericValidation(e.key)) {
                this.fields[field] = e.key;
                next = this.$refs.inputs[fieldIndex].nextElementSibling;
            } else if (e.key === 'ArrowLeft') {
                next = this.$refs.inputs[fieldIndex].previousElementSibling;
            } else if (e.key === 'ArrowRight') {
                next = this.$refs.inputs[fieldIndex].nextElementSibling;
            }

            if (next || ['ArrowUp', 'ArrowDown'].includes(e.key)) {
                e.preventDefault();
            }

            if (next) {
                next.focus();
                next.setSelectionRange(0, selectionEnd);
            }
        },
        async handlePaste(e) {
            const pasteData = e.clipboardData.getData('text');

            if (pasteData.length === 0 || !this.numericValidation(pasteData)) {
                e.preventDefault();
                return;
            }

            Object.assign(this.fields, {
                1: pasteData[0] ?? null,
                2: pasteData[1] ?? null,
                3: pasteData[2] ?? null,
                4: pasteData[3] ?? null,
                5: pasteData[4] ?? null,
                6: pasteData[5] ?? null,
            });

            let next = Object.keys(this.fields).length - 1;

            if (pasteData.length < next + 1) {
                next = pasteData.length;
                e.preventDefault();
            }
            this.$refs.inputs[next].focus();
        },
        numericValidation(value) {
            return /^[0-9]+$/.test(value);
        },
    },
};
</script>
<style lang="scss">
.spa-page-wrapper {
    padding: 60px 0 0 0;
}

.authenticator-entry {
    display: flex;
    max-width: 340px;
    gap: 8px;
    margin: 0 auto;
}
</style>
