<template>
    <ew-card :expanded="true" :loading="isLoading" paddingless>
        <template #title>
            <h4 class="tw-ml-4 tw-mr-2 tw-my-0">Purchasing Approvals</h4>
            <Tag v-if="totalEntries" rounded type="info" class="!tw-mr-auto">
                {{ totalEntries }}
            </Tag>
        </template>

        <template #actions>
            <Button @click="visitApprovals()">View all</Button>
        </template>
        <div v-if="totalEntries">
            <approvals-row
                v-for="purchaseOrder in purchaseOrderApprovals"
                :key="purchaseOrder.id"
                :purchase-order="purchaseOrder"
            ></approvals-row>
        </div>
        <p v-else class="empty-label m-3 py-3">
            Looks like you're all caught up! There aren't any approvals to show here
        </p>
        <div v-if="purchaseOrderApprovals.length < totalEntries" class="py-3 text-center">
            <a class="tw-block tw-py-3 tw-w-full tw-text-center" :href="route('account.event.approvals')">
                See all {{ totalEntries }} items in the Approvals Dashboard
                <i class="mdi mdi-arrow-right"></i>
            </a>
        </div>
    </ew-card>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Tag from '@/js/components/Tag.vue';

export default {
    components: {
        Button,
        Tag,
    },
    data() {
        return {
            isLoading: false,
            purchaseOrderApprovals: [],
            totalEntries: null,
        };
    },
    mounted() {
        this.reloadData();
        Eventbus.$on('purchaseOrder:approved', () => this.reloadData());
    },
    methods: {
        reloadData() {
            this.isLoading = true;
            axios
                .get(route('api.account.event.purchaseorders.approvals'))
                .then((response) => {
                    this.purchaseOrderApprovals = response.data.data;
                    this.totalEntries = response.data.total;
                })
                .finally(() => (this.isLoading = false));
        },
        visitApprovals() {
            window.location.href = route('account.event.approvals', {
                page: 'purchase-orders',
            });
        },
    },
};
</script>
