<template>
    <Drawer ref="drawer" :loading="isLoading" :z-index="5002" @closing="reset()">
        <template #title>
            <h4>{{ 'Create ' + objectName }}</h4>
        </template>

        <div class="buefy-wrapper">
            <div class="tw-p-4">
                <FormField>
                    <div class="tw-flex tw-gap-2">
                        <Button :active="formData['type'] === 1" class="tw-flex-1" @click="formData['type'] = 1">
                            Cost Department
                        </Button>
                        <Button :active="formData['type'] === 2" class="tw-flex-1" @click="formData['type'] = 2">
                            Revenue Stream
                        </Button>
                    </div>
                </FormField>

                <FormField class="tw-mt-2" :label="objectName + ' Name'">
                    <Textbox v-model="formData['name']" maxlength="255" expanded />
                </FormField>

                <FormField class="tw-mt-2" label="Icon">
                    <control-icon v-model="formData['icon']" />
                </FormField>

                <FormField class="tw-mt-2" label="Description">
                    <Textbox v-model="formData['description']" maxlength="255" type="text" expanded />
                </FormField>

                <FormField
                    v-if="eventContext"
                    class="flex-fill"
                    :label="'Fixed ' + (formData['type'] === 1 ? 'Costs' : 'Revenue')"
                    expanded
                >
                    <div class="tw-flex">
                        <Button disabled>{{ eventCurrencyCode }}</Button>
                        <NumberInput v-model="formData['fixed']" step="0.01" dp="2" min="0" class="tw-flex-1" />
                    </div>
                </FormField>

                <FormField class="tw-mt-2" label="Default Tax Rate">
                    <TaxRateSelector
                        v-model="formData['default_tax_rate_id']"
                        :type="formData['type'] === 2 ? 'income' : 'expense'"
                    />
                </FormField>

                <FormField
                    v-if="formData['type'] === 2"
                    label="What best describes this Revenue Stream?"
                    class="tw-mt-2"
                >
                    <div class="tw-flex tw-gap-1">
                        <Button :active="formData.stream_type === 3" @click="formData.stream_type = 3">
                            General Revenue
                        </Button>
                        <Button :active="formData.stream_type === 0" @click="formData.stream_type = 0">
                            Ticketing
                        </Button>
                        <Button :active="formData.stream_type === 2" @click="formData.stream_type = 2">Traders</Button>
                        <Button :active="formData.stream_type === 1" @click="formData.stream_type = 1">Bars</Button>
                    </div>
                </FormField>
            </div>
        </div>

        <template #footer>
            <div class="tw-flex tw-w-full tw-justify-between">
                <Button @click="close()">Cancel</Button>
                <Button :loading="isLoading" type="is-primary" @click="save()">Create</Button>
            </div>
        </template>
    </Drawer>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Drawer from '../Drawer.vue';
import TaxRateSelector from '../controls/TaxRateSelector.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import { eventCurrencyCode, toast } from '@/js/utils.js';

export default {
    components: {
        TaxRateSelector,
        Drawer,
        Button,
        FormField,
        Textbox,
        NumberInput,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            countries: window.Locales,
            currentEvent: store.get('current_event'),
            supplier: null,
            store: store,
            formData: {
                type: 1,
                fixed: 0.0,
                stream_type: null,
            },
            error: false,
            eventCurrencyCode: eventCurrencyCode,
        };
    },
    computed: {
        objectName() {
            if (this.formData.type && this.formData.type === 2) {
                return 'Revenue Stream';
            }
            return 'Cost Department';
        },
        eventContext() {
            return this.currentEvent && !this.currentEvent.is_closed;
        },
        canDelete() {
            return Auth.can('delete departments');
        },
        canSave() {
            return Auth.can('manage departments');
        },
    },
    mounted() {
        Eventbus.$on('create:department', (payload) => {
            if (payload && payload.type && payload.type === 'revenue') {
                this.formData['type'] = 2;
                this.formData['stream_type'] = 3;
            } else {
                this.formData['type'] = 1;
                this.formData['stream_type'] = null;
            }

            this.open();
        });
    },
    methods: {
        open() {
            this.$refs.drawer.open();
        },
        close() {
            this.reset();
            this.$refs.drawer.close();
        },
        save() {
            this.isLoading = true;

            const uri = this.eventContext
                ? route('api.account.event.departments.create')
                : route('api.account.departments.create');

            axios.post(uri, this.formData).then(
                ({ data }) => {
                    toast(
                        `${this.objectName} Saved`,
                        'Your changes to ' + this.formData.name + ' have been saved successfully',
                        'success'
                    );
                    Eventbus.$emit('subcategory:open', {
                        department: data.id,
                    });
                    Eventbus.$emit('reload:departments');
                    this.reset();
                    this.$refs.drawer.close();
                },
                () => {
                    this.isLoading = false;
                }
            );
        },
        reset() {
            this.formData = {
                type: 1,
                fixed: 0.0,
            };
            this.isLoading = false;
        },
    },
};
</script>
