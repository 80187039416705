<template>
    <div v-if="Auth.can('view tickets')">
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-else class="tw-flex tw-gap-2 tw-items-start">
            <div class="tw-flex tw-flex-col tw-gap-2 tw-overflow-y-auto tw-max-h-[calc(100dvh-175px)]">
                <div
                    v-if="
                        store.get('current_user.is_superadmin') &&
                        store.get('current_user.email').endsWith('@synergitech.co.uk')
                    "
                    class="tw-flex tw-gap-3 tw-p-3 tw-rounded tw-bg-white"
                >
                    <span>Developer options</span>
                    <Button type="is-primary" class="tw-ml-auto tw-w-fit" @click="copyTicketSalesAsCsv()">
                        Copy sale ID's as CSV
                    </Button>
                    <Button type="is-primary" class="tw-w-fit" @click="copyAsCsv()">Copy data as CSV</Button>
                </div>
                <div
                    v-for="(seller, i) of ticketSellers"
                    :key="`seller-${i}`"
                    class="tw-flex tw-flex-col tw-px-0 tw-pb-0 tw-bg-white tw-rounded tw-min-w-[210px] tw-cursor-pointer"
                    :class="{
                        '!tw-bg-eventwise !tw-pb-2': selectedTicketSeller?.id === seller.id,
                    }"
                    @click="selectedTicketSeller = seller"
                >
                    <span
                        class="tw-font-avenir tw-text-base tw-px-3 tw-pt-2 tw-mb-1"
                        :class="{
                            'tw-text-white': selectedTicketSeller?.id === seller.id,
                        }"
                    >
                        {{ seller.name }}
                    </span>
                    <FormField
                        class="tw-px-3 tw-py-1 tw-leading-4 tw-mb-2"
                        :custom-class="
                            selectedTicketSeller?.id === seller.id ? '!tw-text-[rgba(255,255,255,.8)]' : null
                        "
                        label="Received in Bank"
                        :class="{
                            'tw-bg-[rgba(255,255,255,.15)] tw-border-t tw-border-b tw-border-[rgba(255,255,255,.2)] tw-py-1 tw-text-white':
                                selectedTicketSeller?.id === seller.id,
                            'tw-text-[rgba(255,255,255,.8)]': selectedTicketSeller?.id === seller.id,
                        }"
                    >
                        {{ localise(seller.amount_received, 2, true) }}
                    </FormField>
                    <div
                        v-if="Auth.can('manage bank transactions') && selectedTicketSeller?.id === seller.id"
                        class="tw-px-3 tw-flex tw-flex-col tw-gap-1"
                    >
                        <Button @click="viewBankTransactions()">View Bank Transactions</Button>
                        <Button
                            v-if="isEditable"
                            class="tw-flex tw-items-center tw-gap-1 tw-w-full"
                            @click="importTicketSales()"
                        >
                            <i class="mdi mdi-import"></i>
                            Import Ticket Sales
                        </Button>
                    </div>
                </div>
            </div>

            <div class="tw-flex-1">
                <Banner v-if="hasEdited" type="is-warning" class="tw-mb-3">
                    <div class="tw-flex tw-items-center">
                        <div class="tw-flex-1">
                            <span class="tw-font-avenir tw-text-lg">
                                You have made changes to the data on this page
                            </span>
                            <p class="tw-m-0">Your changes won't be reflected until you save them.</p>
                        </div>
                        <Button @click="updateTickets()">Save Changes</Button>
                    </div>
                </Banner>
                <DataTable
                    :key="selectedTicketSeller.id"
                    :columns="
                        [
                            {
                                title: 'Ticket Name',
                                field: 'name',
                            },
                            {
                                title: 'Reference',
                                field: 'reference',
                            },
                            {
                                title: 'Ticket Price',
                                field: 'price',
                            },
                            {
                                title: 'Rebate',
                                field: 'kickback_cost',
                            },
                            {
                                title: 'Direct Cost',
                                field: 'direct_cost',
                            },
                            {
                                title: 'Allocated',
                                field: 'allocated',
                            },
                            {
                                title: 'Sold',
                                field: 'sold',
                            },
                            {
                                title: 'Taxable Rate',
                                field: 'tax_rate_id',
                            },
                            {
                                title: 'Amount Collected',
                                field: 'amount_collected',
                            },
                            Auth.can('view bank transactions')
                                ? {
                                      title: 'Amount Received',
                                      field: 'amount_received',
                                  }
                                : null,
                            {
                                title: '',
                                field: 'actions',
                            },
                        ].filter((c) => !!c)
                    "
                    :data="selectedTicketData"
                >
                    <template #column(name)="{ index }">
                        <EditableText
                            v-if="isEditable"
                            v-model="selectedTicketData[index].name"
                            placeholder="Required"
                        />
                        <template v-else>
                            {{ selectedTicketData[index].name }}
                        </template>
                    </template>
                    <template #column(reference)="{ index }">
                        <EditableText
                            v-if="isEditable"
                            v-model="selectedTicketData[index].ticket_sales[0].reference"
                            placeholder="None"
                            :max-length="100"
                        />
                        <template v-else-if="selectedTicketData[index].ticket_sales[0].reference">
                            {{ selectedTicketData[index].ticket_sales[0].reference }}
                        </template>
                        <span v-else class="text-muted">None</span>
                    </template>
                    <template #column(price)="{ row }">
                        {{ localise(row.ticket_price, 2, true) }}
                    </template>
                    <template #column(kickback_cost)="{ index, row }">
                        <PercentageValue
                            v-if="isEditable"
                            v-model="selectedTicketData[index].ticket_sales[0].kickback_cost"
                            :base-value="row.ticket_price"
                            currency
                            :step="0.0001"
                            size="is-small"
                        />
                        <template v-else>
                            {{ localise(selectedTicketData[index].ticket_sales[0].kickback_cost, 2, true) }}
                        </template>
                    </template>
                    <template #column(direct_cost)="{ index }">
                        <EditableText
                            v-if="isEditable"
                            v-model="selectedTicketData[index].ticket_sales[0].direct_cost"
                            type="currency"
                            :min-value="0"
                        />
                        <template v-else>
                            {{ localise(selectedTicketData[index].ticket_sales[0].direct_cost, 2, true) }}
                        </template>
                    </template>
                    <template #column(allocated)="{ index }">
                        <EditableText
                            v-if="isEditable"
                            v-model="selectedTicketData[index].ticket_sales[0].tickets_available"
                            type="number"
                        />
                        <template v-else>
                            {{ selectedTicketData[index].ticket_sales[0].tickets_available }}
                        </template>
                    </template>
                    <template #column(sold)="{ index }">
                        <EditableText
                            v-if="isEditable"
                            :key="
                                (selectedTicketSeller ? selectedTicketSeller.id : 'direct') +
                                '_' +
                                selectedTicketData[index].ticket_sales[0].id
                            "
                            v-model="selectedTicketData[index].ticket_sales[0].tickets_sold"
                            :editable="editableSoldType(index)"
                            type="number"
                        />
                        <template v-else>
                            {{ selectedTicketData[index].ticket_sales[0].tickets_sold }}
                        </template>
                    </template>
                    <template #column(tax_rate_id)="{ index }">
                        <TaxRateSelector
                            v-if="isEditable"
                            v-model="selectedTicketData[index].ticket_sales[0].tax_rate_id"
                            type="income"
                        />
                        <template v-else>
                            <span v-if="!selectedTicketData[index].ticket_sales[0].tax_rate_id" class="text-muted">
                                Not set
                            </span>
                            <template v-else>
                                {{
                                    store.get(
                                        'account.tax_rates.' + selectedTicketData[index].ticket_sales[0].tax_rate_id
                                    ).display_name
                                }}
                            </template>
                        </template>
                    </template>
                    <template #column(amount_collected)="{ index, row }">
                        <Currency
                            v-if="row.payment_structure !== 'upfront'"
                            v-model="selectedTicketData[index].ticket_sales[0].payment_plan.amount_collected"
                        />
                        <template v-else>
                            {{ localise(selectedTicketData[index].total_gross_actual_revenue, 2, true) }}
                        </template>
                    </template>
                    <template #column(amount_received)="{ index }">
                        <template v-if="selectedTicketData[index].ticket_sales[0].amount_received">
                            {{ localise(selectedTicketData[index].ticket_sales[0].amount_received, 2, true) }}
                        </template>
                        <template v-else>
                            {{ localise(0, 2, true) }}
                        </template>
                    </template>
                    <template #column(actions)="{ index }">
                        <Dropdown v-if="Auth.can('manage bank transactions') && isEditable">
                            <a
                                href="#"
                                @click.prevent="addTicketSaleBankTransaction(selectedTicketData[index].ticket_sales[0])"
                            >
                                <i class="mdi mdi-cash-plus tw-mr-1"></i>
                                Add Bank Transaction
                            </a>
                            <a href="#" @click.prevent="logTicketSales(selectedTicketData[index].ticket_sales[0])">
                                <i class="mdi mdi-database-plus tw-mr-1"></i>
                                Add Ticket Transaction
                            </a>
                        </Dropdown>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Dropdown from '../../controls/Dropdown.vue';
import EditableText from '../../controls/EditableText.vue';
import TaxRateSelector from '../../controls/TaxRateSelector.vue';
import PercentageValue from '../../widgets/PercentageValue.vue';
import LoadingSpinner from '../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Currency from '@/js/components/controls/Currency.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { toast } from '@/js/utils.js';
import FormField from '@/js/components/widgets/FormField.vue';

export default {
    components: {
        LoadingSpinner,
        PercentageValue,
        Dropdown,
        Currency,
        EditableText,
        TaxRateSelector,
        Button,
        DataTable,
        FormField,
        Banner,
    },
    emits: ['add-transaction', 'import-transaction'],
    data() {
        return {
            isLoading: true,
            isDirty: false,
            ticketData: [],
            selectedTicketData: [],
            initialSelectedTicketData: [],
            selectedTicketSeller: {
                name: 'Direct Sales',
                id: null,
            },
            store: useDataStore(),
            dirtyReferences: false,
        };
    },
    computed: {
        isEditable() {
            return Auth.can('manage tickets') && !this.store.get('current_event.is_closed');
        },
        ticketSellers() {
            const suppliers = {};
            this.ticketData.forEach((ticketType) => {
                ticketType.ticket_sales.forEach((ticketSale) => {
                    suppliers[ticketSale.supplier_id] = ticketSale.supplier;
                });
            });
            return [
                {
                    name: 'Direct Sales',
                    id: null,
                    amount_received: this.directSalesAmountReceived,
                },
                ...Object.values(suppliers),
            ].filter((ticketSeller) => ticketSeller);
        },
        directSalesAmountReceived() {
            return this.ticketData
                .map((ticketType) =>
                    ticketType.ticket_sales
                        .filter((ticketSale) => !ticketSale.supplier_id)
                        .map((ticketSale) => ticketSale.amount_received)
                )
                .flat()
                .reduce((c, a) => c + a, 0);
        },
        hasEdited() {
            return JSON.stringify(this.selectedTicketData) !== JSON.stringify(this.initialSelectedTicketData);
        },
    },
    watch: {
        async selectedTicketSeller(value) {
            await this.selectTicketSeller(value);
            this.initialSelectedTicketData = JSON.parse(JSON.stringify(this.selectedTicketData));
        },
    },
    mounted() {
        this.initialise();
    },
    methods: {
        async initialise() {
            this.isLoading = true;
            this.ticketData = await axios.get(route('api.account.event.tickets')).then(({ data }) => data);
            await this.selectTicketSeller(this.selectedTicketSeller);
            this.isLoading = false;
            this.initialSelectedTicketData = JSON.parse(JSON.stringify(this.selectedTicketData));
        },
        async selectTicketSeller(ticketSeller = null) {
            if (!this.hasEdited || this.isLoading) {
                this.processTicketSellerSelection(ticketSeller);
                return;
            }
            const { value } = await swal.fire({
                title: 'You have unsaved changes',
                showCancelButton: true,
                confirmButtonText: 'Save & Continue',
            });
            if (value) {
                this.updateTickets();
            }
            this.processTicketSellerSelection(ticketSeller);
        },
        processTicketSellerSelection(ticketSeller) {
            this.isLoading = true;
            this.selectedTicketSeller = ticketSeller;
            const filteredTicketData = this.filterTicketData(ticketSeller);
            this.selectedTicketData = JSON.parse(JSON.stringify(filteredTicketData)).map((ticketType) => {
                ticketType.ticket_sales[0].old_tickets_sold = ticketType.ticket_sales[0].tickets_sold;
                // Copying ticket type name to empty reference fields
                if (ticketType.ticket_sales[0].reference == null) {
                    ticketType.ticket_sales[0].reference = ticketType.name;
                    this.dirtyReferences = true;
                }
                return ticketType;
            });
            if (this.dirtyReferences) {
                this.updateTickets();
            }
            this.isLoading = false;
        },
        filterTicketData(ticketSeller) {
            return JSON.parse(JSON.stringify(this.ticketData))
                .filter((ticketType) =>
                    ticketType.ticket_sales.find((ticketSale) => ticketSale.supplier_id == ticketSeller?.id)
                )
                .map((ticketType) => {
                    ticketType.ticket_sales = ticketType.ticket_sales.filter(
                        (ticketSale) => ticketSale.supplier_id == ticketSeller?.id
                    );
                    return ticketType;
                });
        },
        updateTickets() {
            if (!this.selectedTicketData?.length) {
                toast('Error', 'You must select a ticket seller in order to save changes', 'error');
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.event.tickets.update'), {
                    data: this.selectedTicketData,
                })
                .then(async () => {
                    if (!this.dirtyReferences) {
                        await this.initialise();
                        toast('Success', 'Your tickets have been updated successfully', 'success');
                    } else {
                        toast('Success', 'Ticket references have been updated for some ticket sellers', 'success');
                        this.dirtyReferences = false;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        addTicketSaleBankTransaction(ticketSale) {
            Eventbus.$emit('create:bankTransaction', {
                parent_type: 'Supplier',
                parent_id: ticketSale.supplier_id,
                transactionable_type: 'TicketSale',
                transactionable_id: ticketSale.id,
            });
        },
        viewBankTransactions() {
            Eventbus.$emit('view:bankTransactions', {
                filterBy: 'Supplier',
            });
        },
        logTicketSales(ticketSeller) {
            this.$emit('add-transaction', {
                ticketType: ticketSeller.ticket_type_id,
                ticketSale: ticketSeller.id,
            });
        },
        importTicketSales() {
            this.$emit('import-transaction', this.selectedTicketSeller);
        },
        copyAsCsv() {
            const exportData = {};
            this.ticketData.forEach((ticket) => {
                ticket.ticket_sales.forEach((ticketSale) => {
                    const supplierName = ticketSale.supplier ? ticketSale.supplier.name : 'Direct Sales';
                    if (!exportData[supplierName]) {
                        exportData[supplierName] = {};
                    }

                    exportData[supplierName][ticket.id] = `${ticket.name},${supplierName},${parseFloat(
                        ticket.ticket_price
                    ).toFixed(2)},${parseFloat(ticketSale.kickback_cost ?? '0').toFixed(2)},${
                        ticketSale.tax_rate?.rate ?? 0
                    },${ticketSale.tickets_available},${ticketSale.tickets_sold}`;
                });
            });
            const csvString = Object.values(exportData)
                .map((lines) => Object.values(lines).join('\n'))
                .join('\n\n');
            navigator.clipboard.writeText(csvString);
            toast('Copied', 'Ticket data copied to clipboard', 'success');
        },
        copyTicketSalesAsCsv() {
            const exportData = [];
            this.ticketData.forEach((ticket) => {
                ticket.ticket_sales.forEach((ticketSale) => exportData.push(ticketSale.id));
            });
            navigator.clipboard.writeText(exportData.join(','));
            toast('Copied', "Ticket sale ID's copied to clipboard", 'success');
        },
        editableSoldType(row) {
            // Disallow editing of sold tickets for system suppliers
            return !this.selectedTicketData[row].ticket_sales.find((ts) => ts.supplier?.is_system);
        },
    },
};
</script>
