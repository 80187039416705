<template>
    <textarea
        ref="input"
        v-model="model"
        class="tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400 tw-text-black"
        :class="{
            'tw-resize-none': resize === 'none',
            'tw-resize-y': resize === 'vertical',
            'tw-resize-x': resize === 'horizontal',
            'tw-resize': resize === 'both',
        }"
        :rows
        :maxlength
        :placeholder
    ></textarea>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    rows: {
        type: [String, Number],
        default: 1,
    },
    resize: {
        type: String,
        default: 'none',
    },
    expandable: {
        type: Boolean,
        default: false,
    },
    maxlength: {
        type: [Number, String],
        required: false,
    },
    placeholder: {
        type: String,
        required: false,
    },
});

const input = ref(null);
const model = defineModel({
    type: String,
    default: '',
});

const resizeExpandableInput = () => {
    if (!props.expandable) {
        return;
    }
    const inputEl = input.value;
    inputEl.style.height = 'auto';
    inputEl.style.height = `${inputEl.scrollHeight + 2}px`;
};

onMounted(() => {
    resizeExpandableInput();
});

watch(model, () => {
    resizeExpandableInput();
});
</script>
