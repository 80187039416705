<template>
    <div>
        <div v-if="isLoading" class="row align-items-center position-relative d-flex flex-column">
            <p class="col-12 text-muted text-center mt-5">
                <i class="las la-spinner la-spin mr-1"></i>
                Loading Data
            </p>
        </div>
        <ew-card v-else class="mt-3 mx-3 card-enhance" title="Ticket Sales Figures">
            <div class="d-flex flex-row">
                <table class="table-striped table-event">
                    <thead>
                        <tr>
                            <th class="header">Event</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="event in events" :key="event.id">
                            <td class="event-name">{{ event.name }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="table-striped table-data">
                    <table :key="keyComponent">
                        <thead>
                            <tr>
                                <th v-for="index in datapoints" :key="'header' + index">{{ index + ' ' }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(event, index1) in events" :key="event.id">
                                <td v-for="index2 in datapoints" :key="'data' + index2" class="data-cell">
                                    {{ tableData[index1][index2 - 1] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ew-card>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        data: {
            type: Array,
            required: true,
        },
        events: {
            type: Array,
            required: true,
        },
        weekly: {
            type: Boolean,
            default: false,
        },
    },
    emits: [],
    data() {
        return {
            isLoading: true,
            tableData: [],
            datapoints: 0,
            isWeekly: null,
            keyComponent: 0,
        };
    },
    computed: {},
    watch: {
        data: {
            handler(newVal) {
                this.datapoints = newVal[0].length;
                this.tableData = [...newVal];
                this.isLoading = false;
                this.keyComponent += 1;
            },
            deep: true,
        },
        weekly: {
            handler(newVal) {
                this.isWeekly = newVal;
                this.keyComponent += 1;
            },
        },
    },

    mounted() {
        this.isWeekly = this.weekly;
        this.tableData = [...this.data];
        if (this.tableData[0]) {
            this.configureTable();
        }
    },
    methods: {
        configureTable() {
            this.datapoints = this.tableData[0].length;
            this.isLoading = false;
        },
    },
};
</script>

<style scoped lang="scss">
.card-enhance {
    max-width: calc(100vw - 117px);
    max-height: 400px;
    padding: 10px;
}
.table-data {
    max-width: 100%;
    overflow-x: auto;
    margin: 10px 10px 10px 0;
    text-align: center;
    vertical-align: middle;
    border: 1px solid rgba(black, 0.1);
}
.table-event {
    margin: 10px 0 10px 10px;
    .header {
        text-align: center;
        border-top: 1px solid #ffffff;
    }
}
.event-name {
    height: 40px;
    min-width: 250px !important;
    border-top: 1px solid rgba(black, 0.1);
    border-bottom: 1px solid rgba(black, 0.1);
    border-left: 1px solid rgba(black, 0.1);
    vertical-align: middle;
    padding-left: 10px;
}
.data-cell {
    min-width: 100px !important;
    height: 40px;
    border-top: 1px solid rgba(black, 0.1);
    border-bottom: 1px solid rgba(black, 0.1);
    border-right: 1px solid rgba(black, 0.1);
    vertical-align: middle;
}
</style>
