<template>
    <CreatePayrun
        :selected-payments="selectedPayments"
        class="tw-mt-3"
        @return="$emit('return')"
        @create="({ date, title }) => createPayrun(date, title)"
    >
        <template #table="{ date }">
            <DataTable
                :endpoint="
                    route('api.account.payruns.payments', {
                        end: date,
                    })
                "
                checkable
                :columns="[
                    { title: 'Payee', field: 'supplier_name' },
                    { title: 'Event', field: 'event_name' },
                    { title: 'Subcategory', field: 'category_name' },
                    { title: 'Purchase Order', field: 'purchase_order' },
                    { title: 'Invoice', field: 'invoice' },
                    { title: 'Invoice Amount', field: 'total_value' },
                    { title: 'Payment Amount', field: 'amount' },
                    { title: 'Payment Date', field: 'date' },
                ]"
                @update:model-value="(v) => (selectedPayments = val)"
            >
                <template #column(supplier_name)="{ row }">
                    <a v-if="row.invoice.supplier" href="#" @click="viewSupplier(row.invoice.supplier.id)">
                        {{ row.invoice.supplier.name }}
                    </a>
                    <span v-else class="text-muted">Deleted Supplier</span>
                </template>
                <template #column(event_name)="{ row }">
                    <a
                        :href="
                            route('account.event.dashboard', {
                                event: row.invoice.event_id,
                            })
                        "
                        target="_blank"
                    >
                        {{ row.invoice.event.name }}
                        <i class="mdi mdi-open-in-new"></i>
                    </a>
                </template>
                <template #column(category_name)="{ row }">
                    <span v-if="hasMultipleSubcategories(row)" class="text-muted">Multiple</span>
                    <a
                        v-else
                        :href="
                            route('account.event.purchaseorders.summary', {
                                event: row.invoice.event_id,
                                page: 'summary',
                                departmentId: row.invoice.purchase_orders[0].category.department_id,
                            })
                        "
                        target="_blank"
                    >
                        {{ row.invoice.purchase_orders[0].category.name }}
                        ({{ row.invoice.purchase_orders[0].category.department.name }})
                        <i class="mdi mdi-open-in-new"></i>
                    </a>
                </template>
                <template #column(purchase_order)="{ row }">
                    <span v-if="row.invoice.purchase_orders.length > 1" class="text-muted">Multiple</span>
                    <a v-else href="#" @click.prevent="viewPurchaseOrder(row.invoice.purchase_orders[0])">
                        {{ row.invoice.purchase_orders[0].title }}
                    </a>
                </template>
                <template #column(invoice)="{ row }">
                    <a href="#" @click="viewInvoice(row.invoice)">
                        {{ row.invoice.invoice_number }}
                    </a>
                </template>
                <template #column(total_value)="{ row }">
                    {{ localise(row.invoice.total_value, 2, true) }}
                </template>
                <template #column(amount)="{ row }">
                    {{ localise(row.amount, 2, true) }}
                </template>
                <template #column(date)="{ row }">
                    {{ DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_MED) }}
                </template>
                <template #column(actions)="{ row }">
                    <Tooltip
                        v-if="row.reject_reason"
                        :label="'Previously rejected: ' + row.reject_reason"
                        position="left"
                    >
                        <i class="mdi mdi-alert text-danger"></i>
                    </Tooltip>
                </template>
                <template #empty>
                    <div class="text-center text-muted">There are no more available payments to add.</div>
                </template>
            </DataTable>
        </template>
    </CreatePayrun>
</template>
<script>
import DataTable from '@/js/components/tables/DataTable.vue';
import CreatePayrun from '@/js/components/CreatePayrun.vue';
import { uniqueArray, toast } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        DataTable,
        CreatePayrun,
    },
    emits: ['created', 'return'],
    data() {
        return {
            selectedPayments: [],
            DateTime,
        };
    },
    methods: {
        hasMultipleSubcategories(row) {
            return uniqueArray(row.invoice.purchase_orders.map((po) => po.category_id)).length > 1;
        },
        viewSupplier(supplierId) {
            Eventbus.$emit('view:supplier', supplierId);
        },
        viewPurchaseOrder(purchaseOrder) {
            Eventbus.$emit('purchaseOrder:view', {
                event: purchaseOrder.event_id,
                purchaseOrder: purchaseOrder.id,
            });
        },
        viewInvoice(invoice) {
            Eventbus.$emit('viewInvoice', 'SupplierInvoice', {
                event: invoice.event_id,
                purchaseOrder: invoice.purchase_orders[0].id,
                supplierInvoice: invoice.id,
            });
        },
        createPayrun(date, title) {
            axios
                .post(route('api.account.payruns.create'), {
                    title: title,
                    payment_date: date,
                    items: this.selectedPayments,
                })
                .then(() => {
                    toast('Success', 'Your payrun has been created successfully', 'success');
                    this.$emit('created');
                });
        },
    },
};
</script>
