<template>
    <div id="topbar">
        <a
            v-if="
                currentAccount &&
                currentAccount.is_connected_to_xero &&
                currentAccount.xero_organisation.reconnection_required &&
                Auth.can('manage integrations')
            "
            class="top-banner tw-bg-eventwise tw-text-white hover:tw-bg-eventwise-400 hover:tw-text-white"
            :href="route('account.xero.reconnect')"
        >
            Click here to reconnect your Xero organisation in order for your integration to continue working
        </a>
        <div
            v-if="currentEvent && currentEvent.is_closed"
            class="top-banner tw-bg-gray-100 tw-text-gray-500 tw-border-b tw-border-gray-200 tw-flex tw-gap-2 tw-justify-center !tw-py-1"
        >
            <i class="mdi mdi-lock tw-block tw-scale-110"></i>
            This event has been marked as closed and is no longer editable
        </div>
        <div class="topbar-account-switcher" @click="switchAccount">
            <template v-if="currentAccount && !currentEvent">
                <h5>{{ currentAccount.name }}</h5>
            </template>
            <template v-else-if="currentAccount && currentEvent">
                <h6>{{ currentAccount.name }}</h6>
                <span>
                    <i v-if="currentEvent.is_closed" class="mdi mdi-lock tw-mr-1"></i>
                    {{ currentEvent.name }}
                </span>
            </template>
            <template v-else><h5>Switch Account</h5></template>
        </div>
        <div class="tw-ml-auto"></div>
        <div v-if="currentEvent && !currentAccount.is_supplier" class="buefy-wrapper tw-ml-auto">
            <Tooltip position="bottom" label="Search" class="tw-py-2">
                <a href="#" @click.prevent="openQuickJump()"><i class="mdi mdi-magnify font-20"></i></a>
            </Tooltip>
        </div>
        <div
            v-if="currentAccount && !currentAccount.is_supplier"
            class="buefy-wrapper tw-ml-3"
            :class="{
                'mr-3': !route().params.account,
                'tw-ml-auto': !currentEvent,
            }"
        >
            <Tooltip position="bottom" label="Currency Converter" class="tw-py-2">
                <a href="#" @click.prevent="openCurrencyConverter()"><i class="mdi mdi-calculator font-20"></i></a>
            </Tooltip>
        </div>
        <Notifications />
        <CreateNewMenu v-if="route().params.account && currentAccount && !currentAccount.is_supplier" />
        <Dropdown anchor-right>
            <template #trigger>
                <User v-if="!sudo" class="py-2" :user="currentUser" :hide-name="false" />
                <div v-else class="sudo-banner">
                    <i class="mdi mdi-alert"></i>
                    <div>
                        <User class="py-2" :user="currentUser" :hide-name="false" />
                    </div>
                </div>
            </template>
            <a :href="route('me')">
                <i class="mdi mdi-account"></i>
                My Details
            </a>
            <a v-if="currentAccount && !currentAccount.is_supplier" :href="route('account')">
                <i class="mdi mdi-cog"></i>
                Account Dashboard
            </a>
            <hr />
            <a :href="route('help')">
                <i class="mdi mdi-lifebuoy"></i>
                Help & Support
            </a>
            <hr />
            <a v-if="!sudo" :href="route('auth.logout')">
                <i class="mdi mdi-logout"></i>
                Logout
            </a>
            <a v-else :href="route('unsudo')" class="bg-danger text-white">
                <i class="mdi mdi-logout"></i>
                Exit sudo mode
            </a>
        </Dropdown>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import User from '../widgets/User.vue';
import CreateNewMenu from '../widgets/CreateNewMenu.vue';
import Notifications from './Topbar/Notifications.vue';
import Dropdown from '../controls/Dropdown.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        User,
        CreateNewMenu,
        Notifications,
        Dropdown,
        Tooltip,
    },
    data() {
        const store = useDataStore();

        return {
            currentAccount: store.get('account'),
            currentEvent: store.get('current_event'),
            currentUser: store.get('current_user'),
            sudo: Auth.sudo ?? false,
        };
    },
    methods: {
        switchAccount() {
            Eventbus.$emit('switchAccount');
        },
        openCurrencyConverter() {
            Eventbus.$emit('currencyConverter');
        },
        openQuickJump() {
            Eventbus.$emit('quickJump');
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

#topbar {
    position: fixed;
    top: 0;
    left: 70px;
    width: calc(100vw - 70px);
    height: 60px;
    background: white;
    border-bottom: 1px solid rgba(black, 0.1);
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 1000;
    // background: linear-gradient(to right, $eventwise-primary, $eventwise-primary-light);

    & > .topbar-account-switcher {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6px 35px 6px 8px;
        border: 1px solid rgba(black, 0.15);
        font-size: 15px;
        line-height: 100%;
        border-radius: 5px;
        position: relative;
        background: white;
        transform: scale(1);
        transition: all 0.2s ease;

        & > h6 {
            opacity: 0.66;
        }
        & > h5,
        & > h6 {
            margin: 0;
        }

        &::before {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            top: 3px;
            right: 3px;
            bottom: 0;
            width: 25px;
            font-family: 'Material Design Icons';
            content: '\F19B1';
            font-size: 16px;
            opacity: 0.66;
            transition: all 0.1s ease;
        }
        &::after {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 4px;
            width: 22px;
            font-family: 'Material Design Icons';
            content: '\F19B0';
            font-size: 16px;
            opacity: 0.66;
            transition: all 0.1s ease;
        }

        &:hover {
            cursor: pointer;
            border-color: rgba(black, 0.33);
            transform: scale(1.025);

            &::before {
                opacity: 1;
                right: 2px;
            }
            &::after {
                opacity: 1;
                width: 21px;
            }
        }
    }

    & > .dropdown > a {
        color: inherit;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }

    .sudo-banner {
        padding: 0 6px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        background: $eventwise-warning-light;
        border: 1px solid $eventwise-warning-dark;
        color: $eventwise-warning-dark;

        & > i {
            margin-right: 5px;
            font-size: 1.33em;
            color: $eventwise-warning-dark;
        }
        & > div {
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
