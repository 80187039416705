<template>
    <div class="tw-flex tw-flex-col tw-gap-2 tw-h-full tw-relative">
        <Banner v-if="!combinedActivity.length">There isn't any activity to show here just yet</Banner>
        <div v-else ref="commentContainer" class="tw-flex tw-flex-col tw-gap-1 tw-flex-1 tw-overflow-y-scroll tw-p-4">
            <div
                v-for="(activity, index) in combinedActivity"
                :key="activity.activity_type + '_' + activity.id"
                class="tw-grid tw-grid-cols-4"
            >
                <template v-if="activity.activity_type === 'comment'">
                    <div
                        v-if="!activity.hideUser"
                        class="tw-col-span-3 tw-text-xs tw-flex tw-gap-2 tw-mb-[2px] tw-mt-2"
                        :class="{
                            'tw-flex-row-reverse tw-col-start-2': Auth.user.id === activity.user.id,
                        }"
                    >
                        <span>{{ activity.user.name }}</span>
                    </div>
                    <div
                        class="tw-col-span-3 tw-flex tw-gap-2 tw-items-center"
                        :class="{
                            'tw-flex-row-reverse tw-col-start-2': Auth.user.id === activity.user.id,
                        }"
                    >
                        <User
                            :user="activity.user"
                            size="small"
                            class="align-self-start"
                            :class="{
                                'tw-opacity-0': activity.hideUser,
                            }"
                        />
                        <div>
                            <div
                                class="tw-max-w-[300px] tw-bg-gray-100 tw-py-2 tw-px-3 tw-rounded-md"
                                :class="{
                                    '!tw-bg-eventwise tw-text-white': activity.is_external,
                                    '!tw-py-0 !tw-px-0': editingComments.includes(activity.id),
                                }"
                            >
                                <span v-if="!editingComments.includes(activity.id)" v-html="activity.text"></span>
                                <Textarea
                                    v-else
                                    v-model="activity.new_text"
                                    rows="3"
                                    class="tw-min-w-[300px] !tw-flex-1 tw-border-none tw-bg-transparent tw-text-sm tw-text-eventwise-100 tw-italic"
                                    @keydown.enter="onUpdateComment($event, activity)"
                                    @keydown.esc="editingComments = editingComments.filter((id) => id !== activity.id)"
                                />
                            </div>
                            <div
                                v-if="activity.is_external"
                                class="tw-flex tw-gap-2 tw-text-xs tw-mt-1 tw-px-1"
                                :class="{
                                    'tw-justify-end': Auth.user.id === activity.user.id,
                                }"
                            >
                                <Button
                                    v-if="!activity.target_comment && !editingComments.includes(activity.id)"
                                    type="is-link"
                                    class="!tw-p-0 tw-text-xs !tw-text-eventwise tw-font-normal"
                                    @click="
                                        () => {
                                            activity.new_text = activity.text;
                                            editingComments.push(activity.id);
                                        }
                                    "
                                >
                                    <i class="mdi mdi-pencil"></i>
                                    Edit
                                </Button>
                                <template v-else-if="!activity.target_comment">
                                    <Button
                                        type="is-link"
                                        class="!tw-p-0 tw-text-xs !tw-text-gray-500 tw-font-normal"
                                        @click="
                                            () => {
                                                editingComments = editingComments.filter((id) => id !== activity.id);
                                            }
                                        "
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="is-link"
                                        class="!tw-p-0 tw-text-xs !tw-text-eventwise tw-font-normal"
                                        @click="onUpdateComment(null, activity)"
                                    >
                                        Save Changes
                                    </Button>
                                </template>
                            </div>
                        </div>
                        <span
                            class="tw-text-gray-400 tw-text-xs tw-flex tw-items-center tw-gap-1"
                            :class="{
                                'tw-pb-4': activity.is_external,
                            }"
                        >
                            <div
                                :class="{
                                    'tw-text-right': Auth.user.id === activity.user.id,
                                }"
                            >
                                <span v-if="!activity.hideDate">
                                    {{ toLocal(activity.created_at).toLocaleString(DateTime.DATE_MED) }} at
                                </span>
                                {{ toLocal(activity.created_at).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                                <span v-if="getLastEditor(activity)">
                                    <br />
                                    <Button
                                        type="is-link"
                                        class="!tw-p-0 tw-font-normal tw-text-xs !tw-text-gray-400"
                                        @click="viewCommentActivity(activity)"
                                    >
                                        Edited
                                        {{
                                            toLocal(activity.created_at).toLocaleString(DateTime.DATE_MED) ==
                                            toLocal(activity.updated_at).toLocaleString(DateTime.DATE_MED)
                                                ? 'at '
                                                : 'on ' + toLocal(activity.updated_at).toLocaleString(DateTime.DATE_MED)
                                        }}
                                        {{ toLocal(activity.updated_at).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                                    </Button>
                                </span>
                            </div>
                            <Tooltip v-if="activity.is_external" label="External comment">
                                <i class="mdi mdi-earth tw-text-eventwise tw-text-lg tw-ml-1"></i>
                            </Tooltip>
                        </span>
                    </div>
                </template>
                <template v-else>
                    <div v-if="index" class="tw-col-span-4 tw-text-center tw-text-lg tw-text-gray-200">
                        <i class="mdi mdi-dots-vertical"></i>
                    </div>
                    <div class="tw-col-span-4 tw-flex tw-gap-1.5 tw-justify-center tw-items-center tw-text-xs">
                        <span>{{ activity.user.name }}</span>
                        <span class="tw-text-gray-400">{{ activity.description }}</span>
                    </div>
                    <div
                        v-if="activity.comment"
                        class="tw-col-span-4 tw-flex tw-gap-1.5 tw-text-gray-400 tw-justify-center tw-items-center tw-text-xs tw-mb-1"
                    >
                        <span class="tw-border tw-py-1 tw-px-2 tw-rounded">{{ activity.comment }}</span>
                    </div>
                    <div
                        class="tw-col-span-4 tw-flex tw-gap-1.5 tw-text-gray-400 tw-justify-center tw-items-center tw-text-xs tw-mb-2"
                    >
                        {{ toLocal(activity.created_at).toLocaleString(DateTime.DATETIME_FULL) }}
                    </div>
                </template>
            </div>
        </div>
        <div class="tw-sticky tw-bottom-0 tw-left-0 tw-flex tw-items-center tw-gap-2 tw-bg-white tw-border-t">
            <Textarea
                v-model="model"
                class="tw-max-h-[80px] tw-border-none"
                :class="{
                    '!tw-pb-5': allowExternal,
                }"
                maxlength="255"
                :placeholder="`Type a comment and press ${getPlatform() === 'Mac' ? 'Cmd' : 'Ctrl'}+Enter to send`"
                :rows="2"
                expandable
                @keydown.enter="onSaveComment"
            ></Textarea>
            <Button :disabled="!model" type="is-primary" class="tw-mr-2" @click="onSaveComment">
                <i class="mdi mdi-send"></i>
            </Button>
            <Checkbox v-if="allowExternal" v-model="displayExternally" class="!tw-absolute tw-bottom-0 tw-left-2">
                Display this comment externally
            </Checkbox>
        </div>
    </div>
    <Modal ref="activityModal" title="Comment History" size="is-xxxlarge" paddingless>
        <DataTable
            v-if="viewingComment"
            :data="viewingComment.activity"
            :columns="[
                {
                    title: 'Date',
                    field: 'created_at',
                },
                {
                    title: 'User',
                    field: 'user',
                },
                {
                    title: 'Action',
                    field: 'description',
                },
                {
                    title: 'Details',
                    field: 'comment',
                },
            ]"
        >
            <template #column(created_at)="{ row }">
                {{ toLocal(row.created_at).toLocaleString(DateTime.DATETIME_FULL) }}
            </template>
            <template #column(user)="{ row }">
                <User :user="row.user" size="xsmall" :hide-name="false" />
            </template>
            <template #columns(description)="row">
                {{ row.description }}
            </template>
            <template #column(comment)="{ row }">
                <span
                    v-html="
                        row.source === 'user_diff'
                            ? htmlDiff(JSON.parse(row.comment).old, JSON.parse(row.comment).new)
                            : row.comment
                    "
                ></span>
            </template>
        </DataTable>
    </Modal>
</template>
<script setup>
import Textarea from '@/js/components/controls/Textarea.vue';
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import User from '@/js/components/widgets/User.vue';
import Modal from '@/js/components/Modal.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toLocal, getPlatform, htmlDiff } from '@/js/utils.js';
import { DateTime } from 'luxon';
import { computed, onMounted, ref, watch } from 'vue';
import Tooltip from '@/js/components/Tooltip.vue';

const props = defineProps({
    activity: {
        type: Array,
        default: () => [],
    },
    comments: {
        type: Array,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    allowExternal: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['comment', 'external-comment', 'update-comment']);
const model = defineModel({
    type: String,
    default: '',
});
const displayExternally = ref(false);
const editingComments = ref([]);
const commentContainer = ref(null);
const viewingComment = ref(null);
const activityModal = ref(null);

const onSaveComment = (e) => {
    if (!e.metaKey && !e.ctrlKey) {
        return;
    }
    e.preventDefault();
    emit(props.allowExternal && displayExternally.value ? 'external-comment' : 'comment', model.value);
    model.value = '';
};
const onUpdateComment = (e, comment) => {
    if (e && !e.metaKey && !e.ctrlKey) {
        return;
    }
    e ? e.preventDefault() : null;
    editingComments.value = editingComments.value.filter((id) => id !== comment.id);
    if (comment.text == comment.new_text) {
        return;
    }
    comment.text = comment.new_text;
    delete comment.new_text;
    emit('update-comment', comment);
};
const viewCommentActivity = (comment) => {
    viewingComment.value = comment;
    activityModal.value.open();
};

const getLastEditor = (comment) => {
    if (!comment.activity) {
        return false;
    }
    const latestEdit = comment.activity.reverse().find((c) => c.source === 'user_diff');
    return latestEdit ? latestEdit.user : false;
};

onMounted(() => {
    resetScroll();
});

watch(
    () => props.comments,
    () => {
        resetScroll();
    }
);
watch(
    () => props.activity,
    () => {
        resetScroll();
    }
);

const resetScroll = () => {
    if (!commentContainer.value) {
        return;
    }
    commentContainer.value.parentNode.parentNode.parentNode.scrollTop =
        commentContainer.value.parentNode.parentNode.parentNode.scrollHeight;
};

const connectedComments = computed(() => {
    let lastComment = null;
    return props.comments.map((comment) => {
        const hideUser = lastComment && lastComment.user.id === comment.user.id;
        const lastCreatedAt = lastComment ? toLocal(lastComment.created_at) : null;
        const hideDate =
            lastCreatedAt &&
            lastCreatedAt.toLocaleString(DateTime.DATE_FULL) ===
                toLocal(comment.created_at).toLocaleString(DateTime.DATE_FULL);
        lastComment = comment;
        return {
            activity_type: 'comment',
            ...comment,
            hideUser,
            hideDate,
        };
    });
});

const combinedActivity = computed(() => {
    return [...props.activity, ...connectedComments.value].sort((x, y) => {
        return DateTime.fromISO(x.created_at) - DateTime.fromISO(y.created_at);
    });
});
</script>
<style lang="scss" scoped>
.comment-container {
    height: 100%;
    padding-right: 15px;
    .comment {
        display: inline-block;
        margin-bottom: 0 !important;
        border-radius: 25px;
        max-width: 75%;

        &.sender {
            margin-left: 25%;
            background: var(--primary);
            color: white;

            & ::v-deep .mentionable {
                display: inline-block;
                font-size: 0.9em;
                background: white;
                border-radius: 4px;
                padding: 1px 5px;
            }
        }

        &.receiver {
            background: var(--light);
        }
    }

    .comment-meta {
        font-size: 90%;
    }
}
</style>
