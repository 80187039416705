<template>
    <div class="activity-event">
        <User v-if="activityEvent.user" :user="activityEvent.user" />

        <div class="activity-content">
            <div class="activity-event-heading">
                <h5>{{ activityEvent.user ? activityEvent.user.name : 'Eventwise' }}</h5>
                <span
                    class="activity-timestamp"
                    :title="DateTime.fromISO(activityEvent.occurred_at).toLocaleString(DateTime.DATETIME_FULL)"
                >
                    {{ formatDate(activityEvent.occurred_at, 'dd/MM/yyyy') }}
                </span>
            </div>
            <p v-if="activityEvent.description" v-html="activityEvent.description"></p>
            <p v-if="activityEvent.comment" class="activity-comment" v-html="activityEvent.comment" />
        </div>
    </div>
</template>
<script>
import User from '../../../../../js/components/widgets/User.vue';
import { formatDate } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        User,
    },
    props: {
        activityEvent: {
            type: Object,
        },
    },
    data() {
        return {
            DateTime,
        };
    },
    methods: {
        formatDate,
    },
};
</script>
<style lang="scss">
.activity-event {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    & > .ew-user-display {
        margin-right: 15px;
        user-select: none;
    }

    & > .activity-content {
        flex: 1;
        border: 1px solid rgba(black, 0.1);
        border-radius: 5px;

        & > .activity-event-heading {
            display: flex;
            user-select: none;
            padding: 5px 8px;
            border-bottom: 1px solid rgba(black, 0.1);
            background: rgba(black, 0.01);

            & > h5 {
                margin: 0;
                position: relative;
                top: 1px;
            }
            & > .activity-timestamp {
                margin-left: auto;
                opacity: 0.5;
                font-size: 0.9em;
            }
        }

        & > p {
            padding: 8px;
            line-height: 100%;

            &.activity-comment {
                font-size: 0.9em;
                opacity: 0.8;
                border-left: 1px solid rgba(black, 0.1);
                padding: 6px;
                margin: 0 8px 8px 8px;
            }
        }
    }
}
</style>
