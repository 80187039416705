<template>
    <div v-if="currentDate && !isCreating && !activePayrun">
        <div class="buefy-wrapper">
            <LoadingSpinner v-if="isLoading" label="Loading Information" />
        </div>
        <DateSelector
            v-if="!isLoading"
            v-model="currentDate"
            :start="store.get('current_event.project_start')"
            :end="store.get('current_event.project_end')"
        >
            <template #append="{ date }">
                <div class="payrun-info-bar">
                    <div v-if="payruns" class="info">
                        <template v-if="payruns[date.toFormat('y-LL-dd')]">
                            {{ payruns[date.toFormat('y-LL-dd')].length }}
                            {{ payruns[date.toFormat('y-LL-dd')].length !== 1 ? ' payruns' : ' payrun' }}
                        </template>
                        <template v-else>No payruns</template>
                    </div>
                    <div v-if="payments" class="info">
                        <template v-if="payments[date.toFormat('y-LL-dd')]">
                            {{ payments[date.toFormat('y-LL-dd')].length }}
                            {{ payments[date.toFormat('y-LL-dd')].length !== 1 ? ' payments' : ' payment' }}
                        </template>
                        <template v-else>No payments</template>
                    </div>
                </div>
            </template>
        </DateSelector>
        <div v-if="!isLoading" class="row buefy-wrapper mt-3">
            <div v-if="payments" class="col-12 col-lg-4">
                <Banner v-if="paymentsAwaitingPayrun.length" class="mb-3" type="is-warning">
                    <div class="d-flex align-items-center">
                        <div class="flex-fill pr-2">
                            There
                            {{
                                paymentsAwaitingPayrun.length > 1
                                    ? 'are ' + paymentsAwaitingPayrun.length + ' available payments'
                                    : 'is 1 available payment'
                            }}
                            that {{ paymentsAwaitingPayrun.length > 1 ? 'have' : 'has' }} not yet been assigned to a
                            payrun
                        </div>
                        <Button v-if="!eventIsClosed" class="tw-ml-1" @click="createPayrun(true)">Create Payrun</Button>
                    </div>
                </Banner>
                <ew-card :title="'Available Payments for ' + currentDate.toLocaleString(DateTime.DATE_MED)" paddingless>
                    <div v-if="!paymentsAwaitingPayrun || !paymentsAwaitingPayrun.length" class="empty-label m-3">
                        There are no payments for the selected date
                    </div>
                    <DataTable
                        v-else
                        :data="paymentsAwaitingPayrun"
                        :columns="[
                            {
                                title: 'Invoice Number',
                                field: 'invoice_number',
                            },
                            {
                                title: 'Due Date',
                                field: 'date',
                            },
                            {
                                title: 'Amount',
                                field: 'amount',
                            },
                            {
                                title: 'Payrun',
                                field: 'payruns_count',
                            },
                            {
                                title: '',
                                field: 'actions',
                                class: '!tw-text-right',
                            },
                        ]"
                        :row-class="(row) => (row.reject_reason && !row.is_paid ? 'row-rejected' : null)"
                    >
                        <template #column(invoice_number)="{ row }">
                            {{ row.invoice.invoice_number }}
                            <Tooltip v-if="!row.is_paid && row.reject_reason" position="right">
                                <i class="mdi mdi-alert text-danger ml-1"></i>
                                <template #content>
                                    This item was previously rejected for the following reason:
                                    <div class="reject-quote">
                                        {{ row.reject_reason }}
                                    </div>
                                </template>
                            </Tooltip>
                        </template>
                        <template #column(date)="{ row }">
                            {{ DateTime.fromFormat(row.date, 'y-LL-dd').toFormat('dd/LL/y') }}
                        </template>
                        <template #column(amount)="{ row }">
                            {{ localise(row.amount, 2, true) }}
                        </template>
                        <template #column(payruns_count)="{ row }">
                            <i v-if="row.payruns_count" class="mdi mdi-check-circle text-success"></i>
                            <i v-else class="mdi mdi-close-circle-outline text-danger"></i>
                        </template>
                        <template #column(actions)="{ row }">
                            <Button @click.prevent="viewInvoice(row.invoice)">
                                <i class="mdi mdi-eye"></i>
                            </Button>
                        </template>
                    </DataTable>
                </ew-card>
            </div>
            <div v-if="payruns" class="col-12 col-lg-8">
                <ew-card :title="'Payruns for ' + currentDate.toLocaleString(DateTime.DATE_MED)" paddingless>
                    <template #actions>
                        <Button
                            v-if="!eventIsClosed"
                            type="is-info"
                            class="tw-flex tw-items-center tw-gap-1"
                            @click="createPayrun()"
                        >
                            <i class="mdi mdi-plus"></i>
                            Create Payrun
                        </Button>
                    </template>
                    <div
                        v-if="
                            !payruns[currentDate.toFormat('y-LL-dd')] ||
                            !payruns[currentDate.toFormat('y-LL-dd')].length
                        "
                        class="empty-label m-3"
                    >
                        There are no payment runs for the selected date
                    </div>
                    <DataTable
                        v-else
                        centered
                        :data="payruns[currentDate.toFormat('y-LL-dd')]"
                        :columns="[
                            {
                                title: 'Title',
                                field: 'title',
                            },
                            {
                                title: 'Value',
                                field: 'subtotal',
                            },
                            {
                                title: 'Items',
                                field: 'items',
                            },
                            {
                                title: 'Payment Date',
                                field: 'payment_date',
                            },
                            {
                                title: 'Status',
                                field: 'status',
                            },
                            {
                                title: '',
                                field: 'actions',
                                class: '!tw-text-right',
                            },
                        ]"
                    >
                        <template #column(title)="{ row }">
                            {{ row.title }}
                        </template>
                        <template #column(subtotal)="{ row }">
                            {{ localise(row.subtotal, 2, true) }}
                        </template>
                        <template #column(items)="{ row }">
                            {{ row.items.length }}
                        </template>
                        <template #column(payment_date)="{ row }">
                            {{ DateTime.fromFormat(row.payment_date, 'y-LL-dd').toLocaleString(DateTime.DATE_FULL) }}
                        </template>
                        <template #column(status)="{ row }">
                            <Tag :status="row.status" />
                        </template>
                        <template #column(actions)="{ row }">
                            <Button @click="viewPayrun(row.id)">
                                <i class="mdi mdi-eye"></i>
                            </Button>
                        </template>
                    </DataTable>
                </ew-card>
            </div>
        </div>
    </div>
    <PageCreatePayrun
        v-else-if="isCreating && !activePayrun"
        ref="createPayrunPage"
        :default-date="currentDate.toFormat('y-LL-dd')"
        :payrun-count="
            payruns && payruns[currentDate.toFormat('y-LL-dd')] ? payruns[currentDate.toFormat('y-LL-dd')].length : 0
        "
        @view-p-o="viewPurchaseOrder"
        @cancel="isCreating = false"
        @created="
            isCreating = false;
            getData();
        "
    />
    <PageViewPayrun v-else-if="!isCreating && activePayrun" :active-payrun="activePayrun" @return="closePayrun" />
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import DateSelector from '../../widgets/DateSelector.vue';
import PageCreatePayrun from './Payruns/CreatePayrun.vue';
import PageViewPayrun from './Payruns/ViewPayrun.vue';
import LoadingSpinner from '../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tag from '@/js/components/Tag.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        LoadingSpinner,
        DateSelector,
        PageCreatePayrun,
        PageViewPayrun,
        Tag,
        Button,
        Banner,
        Tooltip,
        DataTable,
    },
    props: {
        payrunId: {
            type: Number,
            default: null,
        },
    },
    emits: ['payrun-selected'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isCreating: false,
            store: store,
            currentDate: null,
            payruns: null,
            payments: null,
            unAllocated: null,
            activePayrun: null,
            createDate: null,
            DateTime,
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        availablePayments() {
            if (!this.currentDate || !this.payments) {
                return [];
            }
            let payments = [];
            const current = this.currentDate.toFormat('y-LL-dd');
            Object.keys(this.payments).forEach((paymentDate) => {
                if (paymentDate !== current) {
                    payments = payments.concat(this.payments[paymentDate].filter((payment) => payment.is_overdue));
                }
            });
            if (this.payments[current]) {
                payments = payments.concat(this.payments[current]);
            }
            return payments;
        },
        paymentsAwaitingPayrun() {
            if (!this.currentDate || !this.unAllocated) {
                return [];
            }
            let unallocated = [];
            Object.keys(this.unAllocated).forEach((paymentDate) => {
                unallocated = unallocated.concat(this.unAllocated[paymentDate]);
            });
            return unallocated;
        },
    },
    watch: {
        currentDate(newVal) {
            this.getData();
            this.createDate = newVal;
        },
    },
    mounted() {
        this.currentDate = route().params.d ? DateTime.fromFormat(route().params.d, 'y-LL-dd') : DateTime.now();
        this.activePayrun = this.payrunId ?? null;
    },
    methods: {
        getData() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.payments.payruns', {
                        date: this.currentDate ? this.currentDate.toFormat('y-LL-dd') : null,
                    })
                )
                .then((response) => {
                    this.payruns = response.data.payruns;
                    this.payments = response.data.payments;
                    this.unAllocated = response.data.unallocated;
                })
                .finally(() => (this.isLoading = false));
        },
        viewPurchaseOrder(id) {
            Eventbus.$emit('purchaseOrder:view', id);
        },
        viewInvoice(invoice) {
            Eventbus.$emit('viewInvoice', 'SupplierInvoice', {
                purchaseOrder: invoice.purchase_orders[0].id,
                supplierInvoice: invoice.id,
            });
        },
        createPayrun(defaultPayments = false) {
            this.isCreating = true;
            if (defaultPayments) {
                setTimeout(() => {
                    this.$refs.createPayrunPage.selectedPayments = JSON.parse(
                        JSON.stringify(this.paymentsAwaitingPayrun)
                    );
                }, 200);
            }
        },
        viewPayrun(id) {
            this.$emit('payrun-selected', id);
            this.activePayrun = id;
        },
        closePayrun() {
            this.activePayrun = null;
            this.getData();
            window.history.pushState({}, '', '../payruns');
        },
    },
};
</script>
<style lang="scss">
.payrun-info-bar {
    font-size: 0.9em;
    margin-top: 10px;
    display: flex;

    & > .info {
        flex: 1;
        background: rgba(black, 0.05);
        text-align: center;
        padding: 2px 0;
        margin: 0 2px;
        border-radius: 4px;
        box-sizing: border-box;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.active .payrun-info-bar > .info {
    background: white;
    color: black;
}
.row-rejected {
    background: rgba(#f04c4a, 0.1);

    .reject-quote {
        display: block;
        border-left: 2px solid white;
        padding: 1px 0 1px 7px;
        margin: 2px;
        font-style: italic;
        text-align: left;
    }
}
</style>
