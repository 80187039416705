<template>
    <div v-if="isOpen" class="account-event-switcher-wrapper" @click.stop="closeOnBackdrop">
        <div class="account-event-switcher" :style="'min-width:' + minModalWidth">
            <div class="account-event-switcher-header">
                <h4>Switch Account</h4>
                <button @click="isOpen = false"><i class="mdi mdi-window-close"></i></button>
            </div>
            <div class="account-event-switcher-columns">
                <div class="switcher-column">
                    <h6 class="has-actions">
                        <div class="mt-1">Your Accounts</div>
                        <div class="buefy-wrapper d-flex align-items-center ml-auto">
                            <a :href="route('onboarding')"><i class="mdi mdi-plus"></i></a>
                            <Textbox v-model="accountSearchQuery" placeholder="Search for an account..." />
                        </div>
                    </h6>
                    <div class="switcher-list max-height">
                        <a
                            v-for="account in filteredYourAccounts"
                            :key="account.id"
                            href="#"
                            :class="{
                                active: selectedAccount && selectedAccount.id === account.id,
                            }"
                            @click.prevent="selectAccount(account)"
                        >
                            {{ account.name }}
                        </a>
                        <a v-if="!accountSearchQuery" :href="route('onboarding')" class="i-plus">
                            Create {{ yourAccounts.length ? 'another' : 'your own' }} account
                        </a>
                        <small
                            v-if="accountSearchQuery && !!(yourAccounts.length - filteredYourAccounts.length)"
                            class="text-muted pl-2"
                        >
                            {{ yourAccounts.length - filteredYourAccounts.length }} of your accounts are hidden by
                            filters
                        </small>
                    </div>
                    <template v-if="otherAccounts.length">
                        <h6>Other Accounts</h6>
                        <div class="switcher-list max-height">
                            <a
                                v-for="account in filteredOtherAccounts"
                                :key="account.id"
                                href="#"
                                :class="{
                                    active: selectedAccount && selectedAccount.id === account.id,
                                }"
                                @click.prevent="selectAccount(account)"
                            >
                                {{ account.name }}
                            </a>
                            <small
                                v-if="accountSearchQuery && !!(otherAccounts.length - filteredOtherAccounts.length)"
                                class="text-muted pl-2"
                            >
                                {{ otherAccounts.length - filteredOtherAccounts.length }} of other accounts are hidden
                                by filters
                            </small>
                        </div>
                    </template>
                </div>
                <div
                    v-if="
                        selectedAccount &&
                        availableEvents &&
                        selectedAccount.pivot &&
                        !selectedAccount.pivot.is_supplier
                    "
                    class="switcher-column"
                >
                    <h6 class="has-actions">
                        <div class="mt-1">Events</div>
                        <div class="buefy-wrapper d-flex align-items-center ml-auto">
                            <Dropdown :hide-on-click="false" anchor-right>
                                <template #trigger>
                                    <a href="#"><i class="mdi mdi-cog"></i></a>
                                </template>
                                <span>Sort</span>
                                <a
                                    href="#"
                                    :class="{ active: eventSortBy === 'name' }"
                                    @click.prevent="eventSortBy = 'name'"
                                >
                                    <i class="mdi mdi-tag-text"></i>
                                    Name
                                </a>
                                <a
                                    href="#"
                                    :class="{ active: eventSortBy === 'last_accessed' }"
                                    @click.prevent="eventSortBy = 'last_accessed'"
                                >
                                    <i class="mdi mdi-login"></i>
                                    Last Accessed
                                </a>
                                <a
                                    href="#"
                                    :class="{ active: eventSortBy === 'start_date' }"
                                    @click.prevent="eventSortBy = 'start_date'"
                                >
                                    <i class="mdi mdi-calendar-arrow-left"></i>
                                    Start Date
                                </a>
                                <hr />
                                <span>Filters</span>
                                <Checkbox v-model="eventShowPast">Show past events</Checkbox>
                            </Dropdown>
                            <Textbox v-model="eventSearchQuery" placeholder="Search for an event..." class="ml-2" />
                        </div>
                    </h6>
                    <div class="switcher-list">
                        <a
                            :href="
                                route('account', {
                                    account: selectedAccount.id,
                                })
                            "
                        >
                            Account Dashboard
                        </a>
                        <a
                            v-for="event in visibleEvents"
                            :key="eventSortBy + '_' + event.id"
                            :href="
                                route('account.event.dashboard', {
                                    account: selectedAccount.id,
                                    event: event.id,
                                })
                            "
                            :class="{
                                active: currentEvent && currentEvent.id === event.id,
                            }"
                            @click="isLoading = true"
                        >
                            <div class="d-flex flex-column">
                                <div>
                                    <i v-if="event.is_closed" class="mdi mdi-lock tw-mr-1"></i>
                                    {{ event.name }}
                                </div>
                                <span>
                                    <small v-if="event.is_closed">Closed &bull;</small>
                                    <small v-if="DateTime.fromISO(event.starts_at).diffNow('days').days > 0">
                                        Starts
                                        {{
                                            DateTime.fromISO(event.starts_at).diffNow('days').days < 31
                                                ? 'in ' +
                                                  Math.round(DateTime.fromISO(event.starts_at).diffNow('days').days) +
                                                  ' days'
                                                : DateTime.fromISO(event.starts_at).toLocaleString(DateTime.DATE_MED)
                                        }}
                                    </small>
                                    <small v-else>
                                        Ended
                                        {{
                                            DateTime.fromISO(event.ends_at).diffNow('days').days > -31
                                                ? Math.abs(
                                                      Math.round(DateTime.fromISO(event.ends_at).diffNow('days').days)
                                                  ) + ' days ago'
                                                : DateTime.fromISO(event.ends_at).toLocaleString(DateTime.DATE_MED)
                                        }}
                                    </small>
                                </span>
                            </div>
                        </a>
                        <a
                            v-if="
                                !eventSearchQuery &&
                                Auth.can('create events') &&
                                currentAccount &&
                                !currentAccount.is_demo
                            "
                            :href="route('onboarding')"
                            class="i-plus"
                        >
                            Create {{ visibleEvents.length ? 'another' : 'an' }} event
                        </a>
                        <small
                            v-if="eventSearchQuery || visibleEvents.length !== availableEvents.length"
                            class="text-muted pl-2"
                        >
                            Showing {{ visibleEvents.length }} of {{ availableEvents.length }} events
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="buefy-wrapper">
            <LoadingSpinner v-if="isLoading" label="Loading Information" />
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import Dropdown from '../controls/Dropdown.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        LoadingSpinner,
        Dropdown,
        Textbox,
        Checkbox,
    },
    data() {
        const store = useDataStore();
        const settings = useSettingsStore();

        return {
            isOpen: false,
            isLoading: false,
            currentAccount: store.get('account'),
            currentEvent: store.get('current_event'),
            currentUser: store.get('current_user'),
            availableAccounts: store.get('available_accounts'),
            availableEvents: null,
            selectedAccount: null,
            eventSortBy: settings.get('event_switcher_sort_by', 'last_accessed'),
            eventShowPast: settings.get('event_switcher_show_past', true),
            eventSearchQuery: null,
            accountSearchQuery: null,
            DateTime,
        };
    },
    computed: {
        minModalWidth() {
            if (this.selectedAccount === null) {
                return '450px';
            }
            return '900px';
        },
        yourAccounts() {
            return this.availableAccounts.filter((account) => account.created_by.id === this.currentUser.id);
        },
        filteredYourAccounts() {
            if (!this.accountSearchQuery) {
                return this.yourAccounts;
            }
            return this.yourAccounts.filter((a) =>
                a.name.toLowerCase().includes(this.accountSearchQuery.toLowerCase())
            );
        },
        otherAccounts() {
            return this.availableAccounts.filter((account) => !this.yourAccounts.find((a) => a.id === account.id));
        },
        filteredOtherAccounts() {
            if (!this.accountSearchQuery) {
                return this.otherAccounts;
            }
            return this.otherAccounts.filter((a) =>
                a.name.toLowerCase().includes(this.accountSearchQuery.toLowerCase())
            );
        },
        visibleEvents() {
            if (!this.availableEvents) {
                return [];
            }
            let events = this.availableEvents.filter((e) =>
                this.eventShowPast ? true : e.ends_at ? DateTime.fromISO(e.ends_at) > DateTime.now() : true
            );
            if (this.eventSearchQuery) {
                events = events.filter((e) => e.name.toLowerCase().includes(this.eventSearchQuery.toLowerCase()));
            }
            return events.sort((a, b) => {
                if (this.eventSortBy === 'last_accessed') {
                    if (!a.pivot || !b.pivot || !a.pivot.event_last_accessed || !b.pivot.event_last_accessed) {
                        return 0;
                    }
                    return new Date(b.pivot.event_last_accessed) - new Date(a.pivot.event_last_accessed);
                } else if (this.eventSortBy === 'name') {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    } else if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }
                if (!a.starts_at || !b.starts_at) {
                    return 0;
                }
                return new Date(b.starts_at) - new Date(a.starts_at);
            });
        },
    },
    watch: {
        selectedAccount(newVal) {
            if (newVal) {
                if (this.isOpen) {
                    this.getEvents();
                }
            }
        },
        isOpen(newVal) {
            if (newVal && this.selectedAccount) {
                this.getEvents();
            }
        },
        eventSortBy(newVal) {
            useSettingsStore().set('event_switcher_sort_by', newVal);
        },
        eventShowPast(newVal) {
            useSettingsStore().set('event_switcher_show_past', newVal);
        },
    },
    mounted() {
        Eventbus.$on('switchAccount', () => (this.isOpen = true));
        if (this.currentAccount) {
            this.selectedAccount = this.availableAccounts.find((a) => a.id === this.currentAccount.id);
        }
    },
    methods: {
        getEvents() {
            if (!this.selectedAccount) {
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.account.events', {
                        account: this.selectedAccount,
                    })
                )
                .then(({ data }) => (this.availableEvents = data))
                .finally(() => (this.isLoading = false));
        },
        closeOnBackdrop(e) {
            if (e.target && e.target.classList && e.target.classList.contains('account-event-switcher-wrapper')) {
                this.isOpen = false;
            }
        },
        selectAccount(account) {
            if (account.pivot && account.pivot.is_supplier) {
                this.isLoading = true;
                window.location.href = route('account', {
                    account: account.id,
                });
                return;
            }
            this.selectedAccount = account;
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.account-event-switcher-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(black, 0.33);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5500;

    & > .account-event-switcher {
        display: flex;
        flex-direction: column;
        width: min(95vw, 450px);
        max-height: 80vh;
        background: white;
        border-radius: 12px;
        transition: all 0.1s ease;

        & > .account-event-switcher-header {
            display: flex;
            align-items: center;
            min-height: 40px;
            border-bottom: 1px solid rgba(black, 0.1);
            padding: 0 10px;

            & > h4 {
                margin: 2px auto 0 0;
            }

            & > button {
                border: none;
                width: 24px;
                height: 24px;
                font-size: 15px;
                color: rgba(black, 0.66);
                padding: 0;
                border-radius: 4px;
                background: white;

                &:hover {
                    background: rgba(black, 0.05);
                    color: rgba(black, 0.75);
                }
            }
        }

        & > .account-event-switcher-columns {
            display: flex;
            width: 100%;
            flex: 1;

            & > .switcher-column {
                flex: 1;
                display: flex;
                flex-direction: column;
                max-height: 75vh;

                &:not(:last-child):first-child {
                    border-right: 1px solid rgba(black, 0.1);
                }

                & > h6 {
                    padding: 10px 0 7px 10px;
                    margin: 0;
                    border-bottom: 1px solid rgba(black, 0.1);

                    &:not(:first-child) {
                        border-top: 1px solid rgba(black, 0.1);
                    }

                    &.has-actions {
                        padding: 0 0 0 10px;
                        display: flex;
                        align-items: center;

                        input {
                            border-top: none;
                            border-right: none;
                            border-bottom: none;
                            border-left: 1px solid rgba(black, 0.1);
                            border-radius: 0;

                            &:focus {
                                outline: none !important;
                            }
                        }
                    }
                }

                & > .switcher-list {
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    overflow-y: scroll;
                    min-height: 150px;
                    flex: 1;

                    & > a {
                        width: 100%;
                        padding: 6px 10px;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        line-height: 100%;

                        &:after {
                            font-family: 'Material Design Icons';
                            content: '\F0142';
                            margin-left: auto;
                        }
                        &.i-plus::after {
                            content: '\F0415';
                        }

                        small {
                            color: rgba(black, 0.5);
                        }

                        &:hover {
                            background: rgba(black, 0.05);
                        }
                        &.active {
                            background: $eventwise-primary;
                            color: white;

                            &:after {
                                content: '';
                            }

                            small {
                                color: rgba(white, 0.75);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
