<template>
    <div
        v-if="isVisible"
        id="account-quick-jump"
        class="tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0"
        style="z-index: 4975"
    >
        <div
            class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black tw-opacity-[33%]"
            @click="isVisible = false"
        ></div>
        <div
            class="tw-max-w-[95%] md:tw-max-w-[540px] tw-max-h-[90%] tw-mx-auto tw-my-3 md:tw-my-9 tw-bg-gray-100 tw-p-2 tw-z-2 tw-relative tw-rounded"
        >
            <AisInstantSearch
                v-if="searchClient"
                :search-client="searchClient"
                index-name="budget_entries"
                :stalled-search-delay="1000"
            >
                <AisSearchBox id="ew-search-field" show-loading-indicator>
                    <template #default="{ refine }">
                        <Textbox
                            id="quickJump"
                            ref="input"
                            v-model="searchQuery"
                            icon="magnify"
                            large
                            placeholder="Search for something"
                            @focus="showDropdown = searchQuery !== ''"
                            @update:model-value="
                                (e) => {
                                    showDropdown = e !== '';
                                    refine(e);
                                }
                            "
                        />
                    </template>
                </AisSearchBox>
                <div v-if="showDropdown" class="tw-w-100 tw-p-2 !tw-pt-0 tw-overflow-y-scroll tw-max-h-[80vh]">
                    <AisIndex v-for="(index, key) of indexes" :key="key" :index-name="key">
                        <AisStateResults>
                            <template #default="{ results: { hits } }">
                                <span
                                    v-show="hits.length"
                                    class="tw-font-avenir tw-mt-3 tw-block tw-text-xs tw-text-gray-800 tw-pl-3"
                                >
                                    {{ index.title }}
                                </span>
                                <AisHits v-show="hits.length">
                                    <template #default="{ items }">
                                        <ul v-if="items" class="tw-m-0">
                                            <li
                                                v-for="item in items"
                                                :key="item.objectID"
                                                class="tw-py-2 tw-px-3 tw-bg-white tw-rounded tw-mt-1 tw-cursor-pointer tw-shadow hover:tw-bg-eventwise hover:tw-text-white"
                                                @click="
                                                    onObjectClick({
                                                        key,
                                                        item,
                                                    })
                                                "
                                            >
                                                <template v-for="info of modelSettings" :key="info.collection">
                                                    <template v-if="info.collection === key">
                                                        <div
                                                            v-for="(field, i) of info['search-parameters'][
                                                                'query_by'
                                                            ].split(',')"
                                                            :key="`highlight-${item.collection}-${field}-${i}`"
                                                            :class="{
                                                                'tw-text-gray-500 tw-text-xs': i > 0,
                                                            }"
                                                        >
                                                            <AisHighlight :hit="item" :attribute="field" />
                                                        </div>
                                                    </template>
                                                </template>
                                            </li>
                                        </ul>
                                    </template>
                                </AisHits>
                            </template>
                        </AisStateResults>
                    </AisIndex>
                </div>
            </AisInstantSearch>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import {
    AisInstantSearch,
    AisSearchBox,
    AisIndex,
    AisStateResults,
    AisHits,
    AisHighlight,
} from 'vue-instantsearch/vue3/es';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import Textbox from '../controls/Textbox.vue';
import { setRoute } from '@/js/utils.js';

export default {
    components: {
        AisInstantSearch,
        AisSearchBox,
        AisIndex,
        AisStateResults,
        AisHits,
        AisHighlight,
        Textbox,
    },
    data() {
        const store = useDataStore();
        return {
            isVisible: false,
            store: store,
            currentEvent: store.get('current_event'),
            modelSettings: window.data.typesense_model_settings,
            showDropdown: false,
            searchQuery: '',
            searchClient: null,
            indexes: Object.values(window.data.typesense_model_settings).reduce((acc, info) => {
                acc[info.collection] = {
                    title: info.title,
                };
                return acc;
            }, {}),
        };
    },
    watch: {
        isVisible(newVal) {
            if (!newVal) {
                this.showDropdown = false;
                this.searchQuery = '';
                return;
            }
            setTimeout(() => {
                this.$refs.input?.focus();
            }, 50);
        },
    },
    mounted() {
        Eventbus.$on('quickJump', () => {
            this.isVisible = true;
        });

        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && this.isVisible) {
                this.isVisible = false;
            }
            if ((e.ctrlKey || e.metaKey) && ['K', 'k'].includes(e.key) && !this.isVisible) {
                e.preventDefault();
                this.isVisible = true;
            }
        });

        const typesenseConfig = {
            server: {
                apiKey: this.currentEvent.search_api_key,
                nodes: [
                    {
                        host: window.data.typesense_config.host,
                        path: window.data.typesense_config.path,
                        port: window.data.typesense_config.port,
                        protocol: window.data.typesense_config.protocol,
                    },
                ],
                cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
            },
            // The following parameters are directly passed to Typesense's search API endpoint.
            //  So you can pass any parameters supported by the search endpoint below.
            //  query_by is required.
            additionalSearchParameters: {},
            collectionSpecificSearchParameters: {},
        };
        for (const info of Object.values(this.modelSettings)) {
            typesenseConfig.collectionSpecificSearchParameters[info.collection] = {
                query_by: info['search-parameters']['query_by'],
            };
        }
        this.searchClient = new TypesenseInstantSearchAdapter(typesenseConfig).searchClient;
    },
    methods: {
        launchRevenueStream(revenueStreamId) {
            setRoute('account.event.budget', {
                page: 'revenue',
                departmentId: revenueStreamId,
            });
        },
        onObjectClick({ key, item }) {
            this.isVisible = false;
            this.searchQuery = '';
            this.showDropdown = false;
            switch (key) {
                case 'budget_entries':
                    Eventbus.$emit('budget:entry', {
                        entry: item,
                    });
                    break;
                case 'purchase_orders':
                    Eventbus.$emit('purchaseOrder:view', {
                        purchaseOrder: item,
                    });
                    break;
                case 'suppliers':
                    location.href = route('account.contacts', {
                        supplier: item.id,
                    });
                    break;
                case 'departments':
                    location.href = route('account.event.budget', {
                        page: this.store.account.departments[item.id].type === 1 ? 'costs' : 'revenue',
                        departmentId: item.id,
                    });
                    break;
            }
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

mark {
    padding: 0;
    background-color: revert;
}
</style>
