<template>
    <div class="buefy-wrapper">
        <Banner v-if="inviteOnly" class="tw-mb-3" type="is-info">
            Eventwise is currently set to invite only mode - new users will require an invite code in order to sign up
        </Banner>
        <Statistics v-slot="props" class="mb-3" :endpoint="route('api.admin.statistics')">
            <Statistic
                title="Accounts"
                icon="domain"
                :loading="props.loading"
                :model-value="props.data.accounts_count"
                :selectable="true"
                @update:model-value="setPage('accounts')"
            />
            <Statistic
                title="Events"
                icon="calendar-blank-multiple"
                :loading="props.loading"
                :model-value="props.data.events_count"
                :selectable="true"
                @update:model-value="setPage('events')"
            />
            <Statistic
                title="Users"
                icon="account-group"
                :loading="props.loading"
                :model-value="props.data.users_count"
                :selectable="true"
                @update:model-value="setPage('users')"
            />
        </Statistics>

        <Statistics v-slot="props" :endpoint="route('api.admin.statistics.detailed')" class="mt-6">
            <div class="flex-fill">
                <div class="ew-statistic-row flex-fill mb-3">
                    <Statistic
                        title="Cost Budget"
                        icon="credit-card-outline"
                        :loading="props.loading"
                        subtext="Total count / value of all cost budget entries"
                        :model-value="`${props.data.cost_budget_count} / ${localise(props.data.cost_budget_value, 2, true)}`"
                    />
                    <Statistic
                        title="Revenue Budget"
                        icon="cash-multiple"
                        :loading="props.loading"
                        subtext="Total count / value of all revenue budget entries"
                        :model-value="`${props.data.revenue_budget_count} / ${localise(
                            props.data.revenue_budget_value,
                            2,
                            true
                        )}`"
                    />
                    <Statistic
                        title="Actual Costs"
                        icon="cart"
                        :loading="props.loading"
                        subtext="Total count / value of all purchase orders"
                        :model-value="`${props.data.cost_actual_count} / ${localise(props.data.cost_actual_value, 2, true)}`"
                    />
                    <Statistic
                        title="Actual Revenue"
                        icon="finance"
                        :loading="props.loading"
                        subtext="Total count / value of all revenue entries"
                        :model-value="`${props.data.revenue_actual_count} / ${localise(
                            props.data.revenue_actual_value,
                            2,
                            true
                        )}`"
                    />
                </div>

                <div class="ew-statistic-row flex-fill">
                    <Statistic
                        title="Purchase Invoices"
                        icon="receipt-text-minus"
                        :loading="props.loading"
                        subtext="Total count / value of all purchase invoices"
                        :model-value="`${props.data.cost_invoices_processed_count} / ${localise(
                            props.data.cost_invoices_processed_value,
                            2,
                            true
                        )}`"
                    />
                    <Statistic
                        title="Revenue Invoices"
                        icon="receipt-text-plus"
                        :loading="props.loading"
                        subtext="Total count / value of all revenue invoices"
                        :model-value="`${props.data.revenue_invoices_processed_count} / ${localise(
                            props.data.revenue_invoices_processed_value,
                            2,
                            true
                        )}`"
                    />
                    <Statistic
                        title="Payments Made"
                        icon="account-cash"
                        :loading="props.loading"
                        subtext="Total count / value of all payments made"
                        :model-value="`${props.data.payments_made_count} / ${localise(
                            props.data.payments_made_value,
                            2,
                            true
                        )}`"
                    />
                    <Statistic
                        title="Tickets Sold"
                        icon="ticket-confirmation"
                        :loading="props.loading"
                        subtext="Total count / value of all tickets sold"
                        :model-value="`${props.data.tickets_sold_count} / ${localise(
                            props.data.tickets_sold_value,
                            2,
                            true
                        )}`"
                    />
                </div>
            </div>
        </Statistics>
    </div>
</template>
<script>
import Statistics from '../../widgets/statistics/StatisticsRow.vue';
import Statistic from '../../widgets/statistics/StatisticsItem.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Statistics,
        Statistic,
        Banner,
    },
    emits: ['set-page'],
    data() {
        return {
            inviteOnly: window.Eventwise.invite_only,
        };
    },
    methods: {
        setPage(page = null) {
            this.$emit('set-page', page);
        },
    },
};
</script>
