<template>
    <Chart type="bar" :options="chartOptions" :data="chartSeries" />
</template>
<script>
import Chart from '@/js/components/Chart.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        Chart,
    },
    props: {
        events: {
            type: Array,
            required: true,
        },
        profitsAndLosses: {
            type: Array,
            required: true,
        },
        visibleColumns: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            labels: [
                'Revenue Budgeted',
                'Revenue Fixed',
                'Revenue Variance',
                'Costs Budgeted',
                'Costs Fixed',
                'Costs Variance',
                'Profit Budgeted',
                'Profit Fixed',
                'Profit Variance',
            ],
            chartSeries: {
                labels: this.labels,
                datasets: [],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: false,
                        },
                    },
                    y: {
                        title: {
                            display: false,
                            text: 'Amount',
                        },
                        ticks: {
                            callback(value) {
                                return localise(value, 0, true);
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(context) {
                                return ' ' + localise(context.parsed.y, 2, true);
                            },
                        },
                    },
                },
            },
            height: 260,
        };
    },
    mounted() {
        const series = this.events.map((event) => ({
            data: [],
            name: event.name,
            type: 'bar',
        }));

        this.chartSeries = {
            labels: this.labels,
            datasets: series.map((s, index) => ({
                label: s.name,
                data: [
                    this.profitsAndLosses[index].budgetedRevenue,
                    this.profitsAndLosses[index].fixedRevenue,
                    this.revenueVariance(index),
                    this.profitsAndLosses[index].budgetedCosts,
                    this.profitsAndLosses[index].fixedCosts,
                    this.costsVariance(index),
                    this.budgetedProfit(index),
                    this.fixedProfit(index),
                    this.profitVariance(index),
                ],
            })),
        };
    },
    methods: {
        revenueVariance(index) {
            return this.profitsAndLosses[index].fixedRevenue - this.profitsAndLosses[index].budgetedRevenue;
        },
        costsVariance(index) {
            return this.profitsAndLosses[index].budgetedCosts - this.profitsAndLosses[index].fixedCosts;
        },
        fixedProfit(index) {
            return this.profitsAndLosses[index].fixedRevenue - this.profitsAndLosses[index].fixedCosts;
        },
        budgetedProfit(index) {
            return this.profitsAndLosses[index].budgetedRevenue - this.profitsAndLosses[index].budgetedCosts;
        },
        profitVariance(index) {
            return this.budgetedProfit(index) - this.fixedProfit(index);
        },
    },
};
</script>
