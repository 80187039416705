<template>
    <Page v-if="Auth.can('update organisation')" title="Account Settings">
        <template #sidebar>
            <div class="account-settings-sidebar">
                <img
                    v-if="currentAccount.image_url"
                    :src="currentAccount.image_url"
                    class="account-settings-sidebar-logo"
                />
                <h5>{{ currentAccount.name }}</h5>
                <small class="text-muted">
                    Created {{ timeAgo(currentAccount.created_at) }} by {{ currentAccount.created_by_user.name }}
                </small>
            </div>
            <Submenu
                class="[@media(max-width:900px)]:tw-flex-row tw-flex-col"
                :pages="[
                    {
                        title: 'General Settings',
                        value: null,
                        icon: 'mdi mdi-cog',
                        hidden: !Auth.can('update organisation'),
                    },
                    {
                        title: 'Billing',
                        value: 'billing',
                        icon: 'mdi mdi-credit-card',
                        hidden: !Auth.can('manage subscription') || currentAccount.is_demo,
                    },
                    {
                        title: 'Tax Rates',
                        value: 'tax-rates',
                        icon: 'mdi mdi-percent-circle',
                        hidden: !Auth.can('manage tax rates'),
                    },
                    {
                        title: 'Attachable Documents',
                        value: 'attachables',
                        icon: 'mdi mdi-file-document',
                        hidden: !Auth.can('update organisation'),
                    },
                    {
                        title: 'Templates',
                        value: 'templates',
                        icon: 'mdi mdi-file-edit',
                        hidden: !Auth.can('update organisation'),
                    },
                    {
                        title: 'Group Tags',
                        value: 'group-tags',
                        icon: 'mdi mdi-tag',
                        hidden: !Auth.can('update organisation'),
                    },
                ]"
                :model-value="currentPage"
                @update:model-value="setPage"
            />
        </template>
        <div class="mt-3">
            <General v-if="!currentPage" />
            <Billing v-else-if="currentPage === 'billing' && !currentAccount.is_demo" />
            <TaxRates v-else-if="currentPage === 'tax-rates'" />
            <AttachableDocuments v-else-if="currentPage === 'attachables'" />
            <Templates v-else-if="currentPage === 'templates'" />
            <GroupTags v-else-if="currentPage === 'group-tags'" />
        </div>
    </Page>
    <PageNotFound v-else />
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Page from '../../Page.vue';
import PageNotFound from '../../PageNotFound.vue';
import Submenu from '../../controls/Submenu.vue';
import General from './Settings/General.vue';
import Billing from './Settings/Billing.vue';
import TaxRates from './Settings/TaxRates.vue';
import AttachableDocuments from './Settings/AttachableDocuments.vue';
import Templates from './Settings/Templates.vue';
import GroupTags from './Settings/GroupTags.vue';
import { timeAgo, setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        Page,
        PageNotFound,
        Submenu,
        General,
        Billing,
        TaxRates,
        AttachableDocuments,
        Templates,
        GroupTags,
    },
    data() {
        const store = useDataStore();
        return {
            currentPage: null,
            currentAccount: store.get('account'),
        };
    },
    mounted() {
        this.handlePageChange();
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        timeAgo,
        handlePageChange() {
            this.currentPage = route().params.page ? route().params.page : null;
        },
        setPage(page = '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setRoute('account.settings', {
                page: page ?? '',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.account-settings-sidebar {
    line-height: 100%;
    margin-bottom: 16px;

    & > .account-settings-sidebar-logo {
        display: block;
        max-width: 200px;
        margin: 0 auto 10px auto;
    }

    & > h5 {
        margin: 0;
    }
}
</style>
