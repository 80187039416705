<template>
    <div class="buefy-wrapper">
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-if="!isLoading" class="d-flex align-items-center mb-2">
            <Textbox v-model="searchQuery" placeholder="Search for an event..." />
        </div>
        <DataTable
            :columns="[
                { title: 'ID', field: 'id', width: 80 },
                { title: 'Name', field: 'name' },
                { title: 'Categories', field: 'categories_count', width: 125 },
                { title: 'Users', field: 'users_count', width: 125 },
                { title: '', field: 'actions' },
            ]"
            :endpoint="tableEndpoint"
            class="align-middle"
            :searchable-string="searchQuery"
        >
            <template #column(actions)="{ row }">
                <div class="tw-flex tw-justify-end">
                    <Tooltip label="Shadow">
                        <Button type="is-dark" @click="shadow(row.view_url)">
                            <i class="mdi mdi-box-shadow"></i>
                        </Button>
                    </Tooltip>
                    <Dropdown :anchor-right="true">
                        <a href="#" @click.prevent="debugPooledAccounts(row.id)">
                            <i class="mdi mdi-bug"></i>
                            Debug Pooled Accounts
                        </a>
                        <template v-if="isSynergiUser">
                            <hr />
                            <a href="#" @click.prevent="cloneEvent(row)">
                                <i class="mdi mdi-content-copy"></i>
                                Clone Event
                            </a>
                            <a href="#" @click.prevent="archiveEvent(row.id)">
                                <i class="mdi mdi-archive"></i>
                                Archive Event
                            </a>
                        </template>
                    </Dropdown>
                </div>
            </template>
            <template #empty>
                <div class="empty-label">There are no events to show</div>
            </template>
        </DataTable>
        <CloneEventModal v-if="isSynergiUser" ref="cloneEventModal" @cloned="reloadData()" />
    </div>
</template>
<script>
import Dropdown from '../../../controls/Dropdown.vue';
import CloneEventModal from '../Modals/CloneEventModal.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import axios from 'axios';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        Dropdown,
        CloneEventModal,
        LoadingSpinner,
        Button,
        Textbox,
        Tooltip,
    },
    props: {
        account: {
            type: [String, Number],
        },
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            accountId: null,
            searchQuery: null,
            perPage: 15,
            data: null,
            store: store,
            tableEndpoint: '',
        };
    },
    computed: {
        isSynergiUser() {
            return this.store.get('current_user.email').endsWith('@synergitech.co.uk');
        },
    },
    watch: {
        account(newVal) {
            this.accountId = newVal;
            this.tableEndpoint = route('api.admin.account.events', {
                account: this.accountId,
            });
        },
    },
    mounted() {
        this.accountId = this.account;
        this.tableEndpoint = route('api.admin.account.events', {
            account: this.accountId,
        });
    },
    methods: {
        shadow(url) {
            window.open(url, '_blank');
        },
        debugPooledAccounts(eventId) {
            this.isLoading = true;
            axios
                .get(
                    route('api.admin.account.event.xeroProfitLossReport', {
                        account: this.accountId,
                        event: eventId,
                    })
                )
                .then(({ data }) => {
                    // eslint-disable-next-line no-console
                    console.log('Pooled account dump', JSON.stringify(data));
                    // eslint-disable-next-line no-console
                    console.log(data);
                    toast('Success', 'Check the console for the response', 'success');
                })
                .finally(() => (this.isLoading = false));
        },
        cloneEvent(event) {
            this.$refs.cloneEventModal.open(event);
        },
        archiveEvent(eventId) {
            this.isLoading = true;
            axios
                .delete(
                    route('api.admin.account.event.archive', {
                        account: this.accountId,
                        event: eventId,
                    })
                )
                .then(() => {
                    toast('Success', 'Event archived successfully', 'success');
                });
        },
    },
};
</script>
