<template>
    <Modal ref="modal" title="Import Xero Contacts" size="is-xlarge" paddingless @closed="reset()">
        <p class="tw-p-3 tw-m-0">Select which Xero contacts to import from the table below.</p>
        <DataTable
            v-model="selectedContacts"
            :data="xeroContacts ? xeroContacts.data : []"
            :columns="[{ title: 'Name', field: 'name' }]"
            checkable
        >
            <template #column(name)="{ row }">
                <span v-if="row.name">{{ row.name }}</span>
                <span v-else class="text-muted">Not set</span>
            </template>
        </DataTable>
        <Banner v-if="selectedContacts.length" class="tw-rounded-[0px]" type="is-info">
            You have selected {{ selectedContacts.length }}
            {{ selectedContacts.length === 1 ? 'contact' : 'contacts' }} to import
        </Banner>
        <template #footer>
            <Button
                type="is-success"
                :loading="isImporting"
                :disabled="!selectedContacts.length"
                @click="importXeroContacts()"
            >
                Import
            </Button>
        </template>
    </Modal>
</template>
<script>
import axios from 'axios';
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        Banner,
        DataTable,
    },
    data() {
        return {
            isImporting: false,
            xeroContacts: null,
            selectedContacts: [],
        };
    },
    methods: {
        getXeroContacts(page = null) {
            this.selectedContacts = [];
            axios
                .get(
                    route('api.account.xero.contacts', {
                        page: page ?? 1,
                        perPage: 10,
                        availableToImport: 1,
                    })
                )
                .then(({ data }) => (this.xeroContacts = data));
        },
        importXeroContacts() {
            if (!this.selectedContacts.length) {
                return;
            }
            this.isImporting = true;
            axios
                .post(route('api.account.xero.contacts.import'), {
                    options: this.selectedContacts.map((c) => c.id),
                })
                .then(() => {
                    toast(
                        'Import Successful',
                        'Please allow up to 10 minutes for your contacts to be imported',
                        'success'
                    );
                    this.close();
                })
                .finally(() => (this.isImporting = false));
        },
        open() {
            this.getXeroContacts();
            this.$refs.modal.open();
        },
        reset() {
            this.currentPage = 1;
            this.xeroContacts = null;
            this.selectedContacts = [];
        },
        close() {
            this.$refs.modal.close();
        },
    },
};
</script>
