<template>
    <div>
        <Stepper v-model="currentStep" :steps="availableSteps" class="mb-4" />
        <div class="overflow-y-auto overflow-x-hidden" style="max-height: 58vh">
            <div v-if="currentStep === 'Select Event'" class="row px-5 mb-5">
                <div class="col-4">
                    <Textbox v-model="searchStr" placeholder="Search for an event..." />
                    <p class="font-18">Select Event</p>
                    <p>
                        Select an existing event from within {{ account.name }} to create your new event from. When
                        setting up your revenue streams and departments, you can choose to copy budget and revenue
                        entries in the subcategory window.
                    </p>
                </div>
                <div class="col-8">
                    <div v-if="existingEvents.length" id="onboarding-event-list">
                        <button
                            v-for="event in existingEvents.filter((e) =>
                                e.name.toLowerCase().includes(searchStr.toLowerCase())
                            )"
                            :key="event.id"
                            type="button"
                            @click="cloneEvent(event)"
                        >
                            <div class="onboarding-event-image"></div>
                            <div>
                                <p class="text-left">{{ event.name }}</p>
                                <small
                                    v-if="DateTime.fromISO(event.starts_at).diffNow('days').days > 0"
                                    class="text-muted"
                                >
                                    Starts
                                    {{
                                        DateTime.fromISO(event.starts_at).diffNow('days').days < 31
                                            ? 'in ' +
                                              Math.round(DateTime.fromISO(event.starts_at).diffNow('days').days) +
                                              ' days'
                                            : DateTime.fromISO(event.starts_at).toLocaleString(DateTime.DATE_MED)
                                    }}
                                </small>
                                <small v-else>
                                    Ended
                                    {{
                                        DateTime.fromISO(event.ends_at).diffNow('days').days > -31
                                            ? Math.abs(
                                                  Math.round(DateTime.fromISO(event.ends_at).diffNow('days').days)
                                              ) + ' days ago'
                                            : DateTime.fromISO(event.ends_at).toLocaleString(DateTime.DATE_MED)
                                    }}
                                </small>
                            </div>
                        </button>
                    </div>
                    <p v-else class="text-muted text-center">There are no existing events to copy from</p>
                </div>
            </div>
            <div v-else-if="currentStep === 'Details'">
                <div class="row mx-4 mb-4">
                    <div class="col-2">
                        <Upload v-model="newEventData.image" text="Image (optional)" />
                    </div>
                    <div class="col-10">
                        <div class="row mb-3">
                            <div class="col-6">
                                <FormField label="Event Name (required)">
                                    <Textbox v-model="newEventData.name" placeholder="Name" />
                                </FormField>
                            </div>
                            <div class="col-6">
                                <FormField label="Start/End Date" class="tw-mb-1">
                                    <flat-pickr
                                        v-model="newEventData.event_dates"
                                        placeholder="Event Date (required)"
                                        :config="{
                                            mode: 'range',
                                            altInput: true,
                                            altFormat: 'F j, Y',
                                            dateFormat: 'Y-m-d',
                                            locale: {
                                                firstDayOfWeek: 1,
                                            },
                                        }"
                                        class="input control is-clearfix"
                                        @on-close="flatpickrRangeAllowSingle"
                                    />
                                </FormField>
                                <small class="text-muted">
                                    Does your event span across multiple days? Select a date range!
                                </small>
                            </div>
                        </div>
                        <address-form
                            v-model="newEventData.address"
                            :locales="locales"
                            @update:model-value="() => delete newEventData.address.id"
                        />
                        <div class="d-flex w-100 align-items-end mt-1">
                            <small>
                                <Button @click="$refs.selectPreviousAddressModal.open()">Use a previous address</Button>
                            </small>
                        </div>
                        <div class="row mt-4">
                            <div class="col-8">
                                <FormField label="Project Start" class="tw-mb-1">
                                    <flat-pickr
                                        v-model="newEventData.project_start"
                                        placeholder="Required"
                                        :config="flatpickrConfig"
                                        class="input control is-clearfix"
                                    />
                                </FormField>
                                <small class="text-muted">
                                    Your project start is the date that you will begin the planning of your event, you
                                    can change this later.
                                </small>
                            </div>
                            <div class="col-4">
                                <control-currencies v-model="newEventData.currency_code" />
                            </div>
                        </div>
                    </div>
                </div>
                <SelectPreviousAddressModal
                    ref="selectPreviousAddressModal"
                    :account="account.id"
                    @select="selectPreviousAddress"
                />
            </div>
            <div v-else-if="currentStep === 'Revenue'">
                <div class="tw-px-8 tw-border-b-2">
                    <h2 class="tw-mt-0 tw-mb-1 tw-text-lg">Revenue Streams</h2>
                    <p>
                        Let&apos;s setup your revenue streams.
                        <br />
                        You need to create at least one, and each Revenue Stream must have at least one subcategory. It
                        is recommended you also set a fixed amount for each Revenue Stream to quickly create an intial
                        top-line budget for your event.
                    </p>
                </div>
                <div class="tw-flex tw-px-8 tw-py-5 tw-bg-slate-50 tw-flex-wrap">
                    <div v-for="revenueStream in revenueStreams" :key="revenueStream.id" class="onboarding-object">
                        <div class="d-flex">
                            <p class="font-18 flex-fill">{{ revenueStream.name }}</p>
                            <Button type="is-danger" @click="dropRevenueStream(revenueStream)">
                                <i class="mdi mdi-close"></i>
                            </Button>
                        </div>
                        <p class="text-muted mb-3">
                            {{ revenueStream.description ? revenueStream.description : 'No description set' }}
                        </p>
                        <div class="d-flex align-items-end">
                            <FormField label="Fixed Revenue">
                                <input-edit v-model="revenueStream.fixed_amount" size="is-small" :currency="true" />
                            </FormField>
                            <FormField class="tw-ml-auto tw-text-right" label="Subcategories">
                                <Button
                                    class="tw-flex tw-gap-2"
                                    type="is-link"
                                    @click="$refs.manageCategoriesModal.openModal(revenueStream)"
                                >
                                    {{ revenueStream.categories.length }} selected
                                    <i class="mdi mdi-pencil"></i>
                                </Button>
                            </FormField>
                        </div>
                    </div>
                    <button
                        class="onboarding-create-object text-muted p-1"
                        type="button"
                        @click="$refs.addDepartmentModal.openModal()"
                    >
                        <i class="mdi mdi-tooltip-plus-outline tw-mb-4 tw-text-2xl"></i>
                        Add another
                    </button>
                </div>
            </div>
            <div v-else-if="currentStep === 'Costs'">
                <div class="tw-px-8 tw-border-b-2">
                    <h2 class="tw-mt-0 tw-mb-1 tw-text-lg">Cost Departments</h2>
                    <p>
                        Now, let&apos;s setup your cost departments.
                        <br />
                        You need to create at least one, and each Revenue Stream must have at least one subcategory. It
                        is recommended you also set a fixed amount for each Revenue Stream to quickly create an intial
                        top-line budget for your event.
                    </p>
                </div>
                <div class="tw-flex tw-px-8 tw-py-5 tw-bg-slate-50 tw-flex-wrap">
                    <div v-for="department in departments" :key="department.id" class="onboarding-object">
                        <div class="d-flex">
                            <p class="font-18 flex-fill">{{ department.name }}</p>
                            <Button type="is-danger" @click="dropCostDepartment(department)">
                                <i class="mdi mdi-close"></i>
                            </Button>
                        </div>
                        <p class="text-muted mb-3">
                            {{ department.description ? department.description : 'No description set' }}
                        </p>
                        <div class="d-flex align-items-end">
                            <FormField label="Fixed Costs">
                                <input-edit v-model="department.fixed_amount" size="is-small" :currency="true" />
                            </FormField>
                            <FormField class="tw-ml-auto tw-text-right" label="Subcategories">
                                <Button
                                    type="is-link"
                                    class="tw-flex tw-gap-2"
                                    @click="$refs.manageCategoriesModal.openModal(department)"
                                >
                                    {{ department.categories.length }} selected
                                    <i class="mdi mdi-pencil"></i>
                                </Button>
                            </FormField>
                        </div>
                    </div>
                    <button
                        class="onboarding-create-object text-muted p-1"
                        @click="$refs.addDepartmentModal.openModal()"
                    >
                        <i class="mdi mdi-tooltip-plus-outline tw-mb-4 tw-text-2xl"></i>
                        Add another
                    </button>
                </div>
            </div>
            <!-- This is the select team members step, which for now is only available when copying an event over -->
            <div v-else-if="currentStep === 'Team'">
                <div class="tw-px-8 tw-border-b-2">
                    <h2 class="tw-mt-0 tw-mb-1 tw-text-lg">Team Members</h2>
                    <p>Select which members of your team should have access to this event.</p>
                </div>
                <div
                    class="tw-px-8 tw-py-5 tw-bg-slate-50 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4"
                >
                    <div v-for="user in existingUsers" :key="user.id" class="tw-bg-white tw-p-3 tw-border tw-rounded">
                        <Checkbox
                            :model-value="newEventData.users.includes(user.id)"
                            @update:model-value="
                                (v) => {
                                    if (v) {
                                        newEventData.users.push(user.id);
                                    } else {
                                        newEventData.users = newEventData.users.filter((c) => c !== user.id);
                                    }
                                }
                            "
                        >
                            <User :user="user" :hide-name="false" class="tw-ml-3" />
                        </Checkbox>
                    </div>
                </div>
            </div>
            <div v-else-if="currentStep === 'Review'">
                <div class="tw-px-8 tw-border-b-2">
                    <h2 class="tw-mt-0 tw-mb-1 tw-text-lg">Have we got everything?</h2>
                    <p>
                        Give everything a quick double-check. If it all looks good, then go ahead and click the finish
                        button to create your event!
                    </p>
                </div>
                <div class="tw-text-left tw-px-8 tw-py-3 tw-bg-slate-50 tw-w-full">
                    <h3 class="tw-text-base">Cost Departments</h3>
                    <ul v-if="revenueStreams.length">
                        <li
                            v-for="revenueStream in revenueStreams"
                            :key="revenueStream.name"
                            class="tw-mb-2 tw-border tw-rounded tw-bg-white tw-p-3 tw-pt-2"
                        >
                            <p class="tw-text-sm tw-font-medium tw-mb-1">{{ revenueStream.name }}</p>
                            <div class="tw-flex tw-flex-wrap tw-gap-1">
                                <Tag
                                    v-for="category in revenueStream.categories"
                                    :key="revenueStream.name + '_' + category.name"
                                >
                                    {{ category.name }}
                                </Tag>
                            </div>
                        </li>
                    </ul>
                    <Banner v-else class="tw-my-3" :closable="false">
                        You must have at least one Revenue Stream to create an event!
                    </Banner>
                    <h3 class="tw-text-base">Cost Departments</h3>
                    <ul v-if="departments.length">
                        <li
                            v-for="department in departments"
                            :key="department.id"
                            class="tw-mb-2 tw-border tw-rounded tw-bg-white tw-p-3 tw-pt-2"
                        >
                            <p class="tw-text-sm tw-font-medium tw-mb-1">{{ department.name }}</p>
                            <div class="tw-flex tw-flex-wrap tw-gap-1">
                                <Tag
                                    v-for="category in department.categories"
                                    :key="department.name + '_' + category.name"
                                >
                                    {{ category.name }}
                                </Tag>
                            </div>
                        </li>
                    </ul>
                    <Banner v-else class="my-3" :closable="false">
                        You must have at least one Cost Department to create an event!
                    </Banner>
                </div>
            </div>
        </div>
        <onboarding-manage-categories-modal
            ref="manageCategoriesModal"
            :account="account"
            :existing-departments
            :source="copy ? 'onboarding_copy' : 'onboarding'"
            @updated="saveDepartment"
        ></onboarding-manage-categories-modal>
        <onboarding-add-department-modal
            ref="addDepartmentModal"
            :account="account"
            :existing-departments="availableExistingDepartments"
            :selected-departments="selectedDepartments"
            :revenue="currentStep === 'Revenue'"
            @created="addDepartment"
        ></onboarding-add-department-modal>
        <div id="onboarding-event-actions">
            <Button v-if="currentStep === availableSteps[0]" @click="$emit('return')">Return to event selection</Button>
            <Button
                v-else
                :disabled="isLoading"
                @click="currentStep = availableSteps[availableSteps.indexOf(currentStep) - 1]"
            >
                Go back
            </Button>
            <Button
                v-if="currentStep !== availableSteps.at(-1)"
                type="is-success"
                :disabled="!isValid"
                :loading="isLoading"
                @click="currentStep = availableSteps[availableSteps.indexOf(currentStep) + 1]"
            >
                Continue
            </Button>
            <Button v-else type="is-success" :disabled="!isValid" :loading="isLoading" @click="createEvent()">
                Finish
            </Button>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Stepper from '../../widgets/WizardSteps.vue';
import User from '../../widgets/User.vue';
import SelectPreviousAddressModal from './CreateEvent/SelectPreviousAddressModal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Tag from '@/js/components/Tag.vue';
import Upload from '@/js/components/controls/Upload.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        Stepper,
        User,
        SelectPreviousAddressModal,
        Button,
        FormField,
        Tag,
        Textbox,
        Checkbox,
        Banner,
        Tag,
        Upload,
    },
    props: {
        account: {
            type: Object,
        },
        copy: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['return'],
    data() {
        const store = useDataStore();
        const defaultUsers = [];
        defaultUsers.push(store.get('current_user.id'));

        return {
            isLoading: false,
            clonedEvent: null,
            currentStep: this.copy ? 'Select Event' : 'Details',
            currentUser: store.get('current_user'),
            newEventData: {
                name: null,
                currency_code: this.account.currency_code,
                event_type: 'single',
                project_start: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01',
                address: {
                    country: 'United Kingdom',
                },
                users: defaultUsers,
                image: null,
            },
            existingImage: '',
            locales: window.Locales,
            existingEvents: [],
            existingDepartments: [],
            assignedCategories: [],
            existingUsers: [],
            revenueStreams: [],
            departments: [],
            searchStr: '',
            DateTime,
        };
    },
    computed: {
        availableSteps() {
            return [...(this.copy ? ['Select Event'] : []), ...['Details', 'Revenue', 'Costs', 'Team', 'Review']];
        },
        isValid() {
            if (this.currentStep === 'Select Event') {
                return !!this.clonedEvent;
            }
            if (this.currentStep === 'Details') {
                return (
                    !!this.newEventData.name &&
                    !!this.newEventData.event_dates &&
                    !!this.newEventData.address &&
                    !!this.newEventData.address.address1 &&
                    !!this.newEventData.address.city &&
                    !!this.newEventData.address.postcode &&
                    !!this.newEventData.address.country &&
                    !!this.newEventData.project_start &&
                    !!this.newEventData.currency_code
                );
            }
            if (this.currentStep === 'Revenue') {
                return !!this.revenueStreams?.find((item) => item.categories.length);
            }
            if (this.currentStep === 'Costs') {
                return !!this.departments?.find((item) => item.categories.length);
            }
            return true;
        },
        availableExistingDepartments() {
            return this.existingDepartments.filter(
                (existingDepartment) => !this.departments.find((department) => department.id === existingDepartment.id)
            );
        },
        selectedDepartments() {
            return this.departments.concat(this.revenueStreams);
        },
    },
    mounted() {
        this.getExistingDepartments();
        this.getExistingUsers();
    },
    methods: {
        getExistingDepartments() {
            this.isLoading = true;
            if (this.copy) {
                this.getExistingEvents();
            }
            axios
                .get(
                    route('api.account.departments', {
                        account: this.account.id,
                    })
                )
                .then(({ data }) => {
                    data = data.map((existingDepartment) => {
                        if (existingDepartment.revenue_stream) {
                            existingDepartment.stream_type = existingDepartment.revenue_stream.stream_type;
                        }
                        existingDepartment.existing_categories = existingDepartment.categories;
                        return existingDepartment;
                    });
                    this.existingDepartments = data;
                })
                .finally(() => (this.isLoading = false));
        },
        getExistingEvents() {
            axios
                .get(
                    route('api.account.events', {
                        account: this.account.id,
                        expanded: true,
                    })
                )
                .then(({ data }) => {
                    this.existingEvents = data;
                });
        },
        getExistingUsers() {
            axios
                .get(
                    route('api.account.team', {
                        account: this.account.id,
                    })
                )
                .then(({ data }) => (this.existingUsers = data));
        },
        getAssignedCategories(eventId) {
            axios
                .get(
                    route('api.account.event.categories', {
                        account: this.account.id,
                        event: eventId,
                    })
                )
                .then(({ data }) => {
                    this.assignedCategories = data.map((cat) => cat.id);

                    // Iterate though revenue streams and departments removing unassigned categories
                    this.revenueStreams.forEach((stream) => {
                        stream.categories = stream.categories.filter((cat) => this.assignedCategories.includes(cat.id));
                    });
                    this.departments.forEach((department) => {
                        department.categories = department.categories.filter((cat) =>
                            this.assignedCategories.includes(cat.id)
                        );
                    });
                });
        },
        flatpickrRangeAllowSingle(selectedDates, dateStr, instance) {
            if (!selectedDates.length) {
                return;
            }
            return instance.setDate([selectedDates[0], selectedDates[+(selectedDates.length > 1)]], true);
        },
        dropRevenueStream(revenueStream) {
            swal.fire({
                title: 'Remove ' + revenueStream.name,
                text: 'Are you sure you want to remove this Revenue Stream?',
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.revenueStreams = this.revenueStreams.filter((revStream) => revStream.name !== revenueStream.name);
            });
        },
        dropCostDepartment(department) {
            swal.fire({
                title: 'Remove ' + department.name,
                text: 'Are you sure you want to remove this Cost Department?',
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.departments = this.departments.filter((costDept) => costDept.name !== department.name);
            });
        },
        addDepartment(payload) {
            payload.categories = payload.categories.map((payloadCategory) => {
                return typeof payloadCategory === 'object'
                    ? payloadCategory
                    : {
                          name: payloadCategory,
                          xero_account_id: null,
                          fixed_amount: null,
                      };
            });
            if (payload.type === 1) {
                this.departments.push(payload);
                return;
            }
            this.revenueStreams.push(payload);
        },
        saveDepartment(department) {
            if (this.wizardStep === 1) {
                this.revenueStreams = this.revenueStreams.map((revStream) => {
                    if (revStream.name === department.name) {
                        return department;
                    }
                    return revStream;
                });
                return;
            }
            this.departments = this.departments.map((dept) => {
                if (dept.name === department.name) {
                    return department;
                }
                return dept;
            });
        },
        buildEventFormData() {
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.newEventData)) {
                if (Array.isArray(value)) {
                    value.forEach((val) => formData.append(key + '[]', val));
                    continue;
                }
                if (value) {
                    formData.append(key, value);
                }
            }
            formData.append('address', JSON.stringify(this.newEventData.address));
            formData.append('departments', this.selectedDepartments);
            if (this.clonedEvent) {
                formData.append('cloned_event', this.clonedEvent);
                formData.append('clone_image', !!this.existingImage);
            }
            const departments = this.revenueStreams.concat(this.departments).map((d) => {
                d.categories = d.categories.filter((c) => c.name || c.id);
                return d;
            });
            formData.append('departments', JSON.stringify(departments));
            return formData;
        },
        createEvent() {
            if (!this.isValid) {
                swal.fire(
                    'Error',
                    'Please return to event details and ensure that you have completed all required fields',
                    'error'
                );
                return;
            }

            this.isLoading = true;
            const data = this.buildEventFormData();
            axios
                .post(
                    route('api.onboarding.create.event', {
                        account: this.account.id,
                    }),
                    data
                )
                .then(() => this.$emit('return'));
        },
        cloneEvent(event) {
            this.existingImage = event.image_url ?? '';
            this.newEventData.name = event.name;
            this.newEventData.address = {
                id: event.primary_address.id,
                address1: event.primary_address.address1,
                address2: event.primary_address.address2,
                city: event.primary_address.city,
                postcode: event.primary_address.postcode,
                country: event.primary_address.country,
            };
            let startDate = new Date(event.starts_at).toISOString().replace(/T.*/, '').split('-').join('-');
            let endDate = new Date(event.ends_at).toISOString().replace(/T.*/, '').split('-').join('-');
            this.newEventData.event_dates = startDate + ' to ' + endDate;
            this.newEventData.project_start = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01';
            this.newEventData.currency_code = event.currency_code;
            this.revenueStreams = event.revenue_streams.map((stream) => ({
                id: stream.department_id,
                categories: stream.categories.map((category) => ({
                    id: category.id,
                    description: category.description,
                    name: category.name,
                    fixed_amount: category.fixed_amount,
                })),
                description: stream.description,
                fixed_amount: stream.fixed_amount,
                name: stream.name,
                stream_type: stream.stream_type,
                type: 2,
            }));
            this.departments = event.cost_departments.map((department) => ({
                id: department.id,
                categories: department.categories.map((category) => ({
                    id: category.id,
                    description: category.description,
                    name: category.name,
                    fixed_amount: category.fixed_amount,
                })),
                description: department.description,
                fixed_amount: department.fixed_amount,
                name: department.name,
                type: 1,
            }));
            this.getAssignedCategories(event.id);
            this.clonedEvent = event.id;
            this.currentStep = 'Details';
        },
        selectPreviousAddress(address) {
            this.newEventData.address = {
                id: address.id,
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                postcode: address.postcode,
                country: address.country,
            };
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

#onboarding-event-actions {
    border-top: 1px solid rgba(black, 0.1);
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
}

.onboarding-object {
    background: white;
    padding: 15px 15px 6px 15px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.075);
    margin-right: 15px;
    margin-bottom: 15px;
    min-width: min(275px, 100%);

    .field > label.label {
        margin-bottom: 0 !important;
    }
}

.onboarding-create-object {
    box-sizing: border-box;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 125%;
    width: 25%;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
    padding: 20px 0px;

    .icon {
        display: block;
    }

    &:hover {
        color: white !important;
        background: $eventwise-primary;
        border: 2px solid $eventwise-primary;

        .icon {
            color: white !important;
        }
    }
}

#onboarding-event-list {
    display: flex;
    flex-direction: column;
    gap: 6px;

    & > button {
        display: flex;
        align-items: center;
        color: inherit;
        line-height: 95%;
        border: 1px solid rgba(black, 0.1);
        padding: 8px;
        border-radius: 6px;
        transition: all 0.1s ease;

        & > .onboarding-event-image {
            width: 32px;
            height: 32px;
            background: rgba(black, 0.05);
            border-radius: 4px;
            border: 1px solid rgba(black, 0.1);
            margin-right: 8px;
        }

        &:hover {
            transform: scale(1.01);
            background: rgba(black, 0.01);
        }
    }
}
</style>
