<template>
    <div class="user-avatar" :class="{ 'user-avatar-group': grouped }">
        <Tooltip :label="name">
            <img
                v-if="url"
                :src="url"
                alt="user"
                class="rounded-circle"
                :class="{
                    'thumb-xxs': size == 'xxs',
                    'thumb-xs': size == 'xs',
                    'thumb-sm': size == 'sm',
                    'thumb-md': size == 'md',
                    'thumb-lg': size == 'lg',
                    'thumb-xl': size == 'xl',
                    'thumb-xxl': size == 'xxl',
                }"
            />
            <div
                v-else
                class="avatar-box align-self-center"
                :class="{
                    'thumb-xxs': size == 'xxs',
                    'thumb-xs': size == 'xs',
                    'thumb-sm': size == 'sm',
                    'thumb-md': size == 'md',
                    'thumb-lg': size == 'lg',
                    'thumb-xl': size == 'xl',
                    'thumb-xxl': size == 'xxl',
                }"
            >
                <span
                    class="avatar-title bg-soft-purple rounded-circle"
                    :class="{
                        'thumb-xxs': size == 'xxs',
                        'thumb-xs': size == 'xs',
                        'thumb-sm': size == 'sm',
                        'thumb-md': size == 'md',
                        'thumb-lg': size == 'lg',
                        'thumb-xl': size == 'xl',
                        'thumb-xxl': size == 'xxl',
                    }"
                >
                    {{ initials(name) }}
                </span>
            </div>
        </Tooltip>
    </div>
</template>
<script>
import Tooltip from '@/js/components/Tooltip.vue';
import { initials } from '@/js/utils.js';

export default {
    components: {
        Tooltip,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
        grouped: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'sm',
        },
    },
    methods: {
        initials,
    },
};
</script>
<style scoped>
.user-avatar {
    position: relative;

    .disabled {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        .disabled {
            opacity: 1;
        }
    }
}
</style>
