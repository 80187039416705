<template>
    <div>
        <LoadingSpinner v-if="isLoading" label="Loading Event Data" />
        <div v-else class="buefy-wrapper mb-2">
            <Banner v-if="modeSwitcher" class="tw-mt-4 tw-py-4" type="is-primary">
                <h4 class="mb-2">Events</h4>
                <p>
                    Select a single event in order to access the Sales & Costs and History Reports.
                    <br />
                    You can select multiple (up to 4) events to view the Sales & Costs and Multi Event Analysis reports.
                    <br />
                    Alternatively, You can view the reports for your group tags by selecting the button below.
                </p>
            </Banner>
            <Banner v-else class="mt-5 py-3" type="is-primary">
                <h4 class="mb-2">Group Tags</h4>
                <p>
                    Select a single group Tag in order to access the Group Report.
                    <br />
                    You can select multiple (up to 2) tags to view the Multi Group Analysis report.
                    <br />
                    Alternatively, You can view the reports for your Events by selecting the button below.
                </p>
            </Banner>
            <div class="tw-mt-2.5">
                <FormField class="tw-mb-1" field-class="tw-flex tw-gap-1 tw-items-center">
                    <Button :active="currentPage === 'events'" @click="currentPage = 'events'">
                        <span>Events</span>
                    </Button>
                    <Button :active="currentPage === 'groups'" @click="currentPage = 'groups'">
                        <span>Group Tags</span>
                    </Button>
                </FormField>
            </div>
            <div v-if="modeSwitcher" :key="componentKey" class="w-100 py-5 mt-2 card">
                <div class="d-flex justify-content-end ml-3">
                    <span class="h5">Search</span>
                    <Textbox v-model="searchQuery" placeholder="search" class="tw-mx-3" />
                </div>
                <!-- The Events table is constructed by the DataTable component using the endpoint -->
                <DataTable
                    :endpoint="route('api.account.event.eventReport')"
                    :searchable-string="searchQuery"
                    :columns="[
                        {
                            title: 'Logo',
                            field: 'logo',
                        },
                        {
                            title: 'Event',
                            field: 'name',
                        },
                        {
                            title: 'Starts At',
                            field: 'starts_at',
                        },
                        {
                            title: 'Ends At',
                            field: 'ends_at',
                        },
                        {
                            title: 'Address',
                            field: 'address',
                        },
                        {
                            title: '',
                            field: 'actions',
                        },
                    ]"
                >
                    <template #column(logo)="{ row }">
                        <img v-if="row.image_url" :src="row.image_url" class="tw-max-h-10" />
                    </template>
                    <template #column(start_at)="{ row }">
                        {{ toDateString(row.starts_at) }}
                    </template>
                    <template #column(ends_at)="{ row }">
                        {{ toDateString(row.ends_at) }}
                    </template>
                    <template #column(address)="{ row }">
                        {{ row.primary_address.address }}
                    </template>
                    <template #column(actions)="{ row }">
                        <Tooltip v-if="selectedRow(row.id) === true" label="Deselect" class="ml-1" position="left">
                            <Button type="is-danger" @click="selectEntry(row)">
                                <i class="mdi mdi-close"></i>
                            </Button>
                        </Tooltip>
                        <Tooltip v-else label="Select" class="ml-1" position="left">
                            <Button @click="selectEntry(row)">
                                <i class="mdi mdi-check"></i>
                            </Button>
                        </Tooltip>
                    </template>
                </DataTable>
            </div>
            <div v-else class="w-100 py-5 mt-2 card">
                <!-- The GroupTag Table Passes groupTags data to the DataTable component, no endpoint -->
                <DataTable
                    v-if="!isLoading"
                    ref="grouptags-table"
                    :data="groupTags"
                    :columns="[
                        {
                            title: 'Tag',
                            field: 'name',
                        },
                        {
                            title: 'Events',
                            field: 'eventCount',
                        },
                        {
                            title: 'Budgeted Costs',
                            field: 'budgeted_cost',
                        },
                        {
                            title: 'Budgeted Revenue',
                            field: 'budgeted_revenue',
                        },
                        {
                            title: 'Actual Costs',
                            field: 'actual_costs',
                        },
                        {
                            title: 'Actual Revenue',
                            field: 'actual_revenue',
                        },
                        {
                            title: 'Budgeted P/L',
                            field: 'budgeted_pl',
                        },
                        {
                            title: 'Actual P/L',
                            field: 'actual_pl',
                        },
                        {
                            title: 'Tickets Available',
                            field: 'tickets_available',
                        },
                        {
                            title: 'Tickets Sold',
                            field: 'tickets_sold',
                        },
                        {
                            title: '',
                            field: 'actions',
                        },
                    ]"
                >
                    <template #column(name)="{ row }">
                        <GroupTag :tag="row" />
                    </template>
                    <template #column(eventCount)="{ row }">
                        <a href="#" @click="openEditor">{{ eventCount(row) }}</a>
                    </template>
                    <template #column(budgeted_costs)="{ row }">
                        {{ localise(extractTotalsData(row, 0), 2, true) }}
                    </template>
                    <template #column(budgeted_revenue)="{ row }">
                        {{ localise(extractTotalsData(row, 1), 2, true) }}
                    </template>
                    <template #column(actual_costs)="{ row }">
                        {{ localise(extractTotalsData(row, 2), 2, true) }}
                    </template>
                    <template #column(actual_revenue)="{ row }">
                        {{ localise(extractTotalsData(row, 3), 2, true) }}
                    </template>
                    <template #column(budgeted_pl)="{ row }">
                        {{ localise(extractTotalsData(row, 4), 2, true) }}
                    </template>
                    <template #column(actual_pl)="{ row }">
                        {{ localise(extractTotalsData(row, 5), 2, true) }}
                    </template>
                    <template #column(tickets_available)="{ row }">
                        {{ extractTotalsData(row, 6) }}
                    </template>
                    <template #column(tickets_sold)="{ row }">
                        {{ extractTotalsData(row, 7) }} ({{ extractTotalsData(row, 8) }}%)
                    </template>
                    <template #column(actions)="{ row }">
                        <Tooltip v-if="selectedRow(row.id)" label="Deselect" class="ml-1" position="left">
                            <Button type="is-danger" @click="selectEntry(row)">
                                <i class="mdi mdi-close"></i>
                            </Button>
                        </Tooltip>
                        <Tooltip v-else label="Select" class="ml-1" position="left">
                            <Button @click="selectEntry(row)">
                                <i class="mdi mdi-check"></i>
                            </Button>
                        </Tooltip>
                    </template>
                </DataTable>
            </div>
        </div>
        <ModalTagEditor ref="modalTagEditor" />
    </div>
</template>
<script>
import Banner from '../../../Layout/Banner.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import GroupTag from '@/js/components/GroupTag.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Button from '@/js/components/controls/Button.vue';
import ModalTagEditor from '@/js/components/modals/GroupTags/ModalTagEditor.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        DataTable,
        GroupTag,
        Banner,
        LoadingSpinner,
        Button,
        ModalTagEditor,
        FormField,
        Textbox,
        Tooltip,
    },
    props: {
        eventIds: {
            type: Array,
            required: true,
        },
        tagIds: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            default: 'events',
        },
    },
    emits: ['eventselected', 'tagselected', 'mode'],
    data() {
        return {
            isLoading: true,
            eventsList: [],
            events: [],
            availableEvents: [],
            selected: false,
            selectedEvents: [],
            searchQuery: null,
            currentPage: 'events',
            groupTags: [],
            selectedTags: [],
            availableTags: [],
            eventTotals: [],
            eventClass: 'App\\Models\\Event',
            componentKey: 0,
        };
    },
    computed: {
        filteredEvents() {
            if (!this.searchQuery) {
                if (!this.isLoading) {
                    this.tableModels(this.events);
                    return this.availableEvents;
                }
                return this.availableEvents;
            }
            return this.availableEvents.filter(
                (item) =>
                    this.selectedEvents.includes(item.id) ||
                    item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        modeSwitcher() {
            return this.currentPage === 'events';
        },
    },
    watch: {
        currentPage: {
            handler() {
                this.$emit('mode', this.currentPage);
                this.componentKey++;
            },
        },
    },
    mounted() {
        this.selectedEvents = this.eventIds;
        this.selectedTags = this.tagIds;
        this.currentPage = this.mode;
        this.fetchGroupTags();
    },
    methods: {
        fetchGroupTags() {
            this.isLoading = true;
            axios
                .post(route('api.account.tags.allAssigned'), { class: this.eventClass })
                .then(({ data }) => {
                    this.groupTags = data.tags;
                    this.eventTotals = data.events;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        tableModels(models) {
            // sets the data to use in the table
            // The events are handled by the DataTable itself
            // modeSwitcher = false - GroupTags
            if (!this.modeSwitcher) {
                this.availableTags = [];
                models.forEach((item) => {
                    this.availableTags.push({
                        id: item.id,
                        eventCount: item.events.length,
                        budgetedCost: this.extractTotalsData(item, 0),
                        budgetedRevenue: this.extractTotalsData(item, 1),
                        actualCosts: this.extractTotalsData(item, 2),
                        actualRevenue: this.extractTotalsData(item, 3),
                        budgetedPandL: this.extractTotalsData(item, 4),
                        actualPandL: this.extractTotalsData(item, 5),
                        ticketsAvailable: this.extractTotalsData(item, 6),
                        ticketsSold: this.extractTotalsData(item, 7),
                        selected: item.selected ? false : item.selected,
                    });
                });
            }
        },
        toDateString(dateStr) {
            const date = new Date(dateStr);
            return date.toDateString();
        },
        selectEntry(row) {
            // to select the event, place it in the selected Events array
            // modeSwitcher = true for Events
            if (!this.selectedRow(row.id) && this.modeSwitcher) {
                // Add the row, if less than 4
                if (this.selectedEvents.length < 4) {
                    this.selectedEvents.push(row.id);
                    this.toggleSelected(row.id);
                    this.$emit('eventselected', this.selectedEvents);
                }
            } else if (this.selectedRow(row.id) && this.modeSwitcher) {
                // Remove the event from the selected events array
                if (row.id > 0) {
                    this.selectedEvents = this.selectedEvents.filter((id) => id !== row.id);
                    this.toggleSelected(row.id);
                    this.$emit('eventselected', this.selectedEvents);
                }
            } else if (!this.selectedRow(row.id) && !this.modeSwitcher) {
                // Group Tags in use MAX 2
                if (this.selectedTags.length < 2) {
                    this.selectedTags.push(row.id);
                    this.toggleSelected(row.id);
                    // emit selected tags back to the parent
                    this.$emit('tagselected', this.selectedTags);
                }
            } else {
                if (row.id > 0) {
                    this.selectedTags = this.selectedTags.filter((id) => id !== row.id);
                    this.toggleSelected(row.id);
                    // emit selected tags back to the parent
                    this.$emit('tagselected', this.selectedTags);
                }
            }
        },
        toggleSelected(rowId) {
            if (this.modeSwitcher) {
                this.availableEvents.forEach((event) => {
                    if (event.id === rowId) {
                        event.selected = !event.selected;
                    }
                });
            } else {
                this.availableTags.forEach((tag) => {
                    if (tag.id === rowId) {
                        tag.selected = !tag.selected;
                    }
                });
            }
        },
        selectedRow(rowId) {
            return this.modeSwitcher ? this.selectedEvents.includes(rowId) : this.selectedTags.includes(rowId);
        },
        eventCount(tag) {
            return tag.events.length;
        },
        extractTotalsData(tag, type = 0) {
            // Get the tag, which includes events
            // Get the events ids in the tag.events
            const tagEvents = tag.events;
            const tagEventIds = tagEvents.map((event) => event.id);

            // using the eventTotals, get the sum of the type
            const selectedEvents = this.eventTotals.filter((event) => tagEventIds.includes(event.id));
            let requiredValue = 0;
            switch (type) {
                case 0: //budgeted_cost
                    requiredValue = selectedEvents
                        .map((e) => e.budgetFigures.totalCost)
                        .reduce((acc, value) => acc + value, 0);
                    break;
                case 1: // budgeted Revenue
                    requiredValue = selectedEvents
                        .map((e) => e.budgetFigures.totalRevenue)
                        .reduce((acc, value) => acc + value, 0);
                    break;
                case 2: // actual Costs
                    requiredValue = selectedEvents.map((e) => e.actualRevenue).reduce((acc, value) => acc + value, 0);
                    break;
                case 3: // actual_revenue
                    requiredValue = selectedEvents.map((e) => e.actualRevenue).reduce((acc, value) => acc + value, 0);
                    break;
                case 4: // budgeted P/L
                    requiredValue =
                        selectedEvents.map((e) => e.budgetFigures.totalRevenue).reduce((acc, value) => acc + value, 0) -
                        selectedEvents.map((e) => e.budgetFigures.totalCost).reduce((acc, value) => acc + value, 0);
                    break;
                case 5: // Actual P/L
                    requiredValue =
                        selectedEvents.map((e) => e.actualRevenue).reduce((acc, value) => acc + value, 0) -
                        selectedEvents.map((e) => e.purchaseOrders.poTotal).reduce((acc, value) => acc + value, 0);
                case 6: // tickets Available
                    requiredValue = selectedEvents
                        .map((e) => this.catchNullStrings(e.ticket_sales_sum_tickets_available))
                        .reduce((acc, value) => acc + value, 0);
                    break;
                case 7: // tickets sold
                    requiredValue = selectedEvents
                        .map((e) => this.catchNullStrings(e.ticket_orders_sum_quantity))
                        .reduce((acc, value) => acc + value, 0);
                    break;
                case 8: // Percentage Sold
                    const actualSold = selectedEvents
                        .map((e) => this.catchNullStrings(e.ticket_orders_sum_quantity))
                        .reduce((acc, value) => acc + value, 0);
                    const available = selectedEvents
                        .map((e) => this.catchNullStrings(e.ticket_sales_sum_tickets_available))
                        .reduce((acc, value) => acc + value, 0);
                    requiredValue = ((actualSold / available) * 100).toFixed(2);
                    break;
                default:
                    requiredValue = 0;
            }
            return requiredValue;
        },
        catchNullStrings(value) {
            if (value == null) {
                return 0;
            }
            return parseInt(value, 10);
        },
        openEditor() {
            this.$refs.modalTagEditor.openModal();
        },
    },
};
</script>
