<template>
    <Modal ref="modal" :title="modalTitle" :loading="isLoading" size="is-large">
        <template v-if="Auth.can('manage suppliers')">
            <FormField label="Name (required)">
                <Textbox
                    v-model="supplierName"
                    :placeholder="`${objectLabel.charAt(0).toUpperCase() + objectLabel.slice(1)} Name`"
                    required
                />
            </FormField>
            <FormField label="Address Line 1" class="tw-mt-2">
                <Textbox v-model="supplierAddress1" placeholder="Address Line 1" required />
            </FormField>
            <FormField label="Address Line 2" class="tw-mt-2">
                <Textbox v-model="supplierAddress2" placeholder="Address Line 2" />
            </FormField>
            <FormField label="Town/City" class="tw-mt-2">
                <Textbox v-model="supplierCity" placeholder="Town/City" required />
            </FormField>
            <FormField label="County" class="tw-mt-2">
                <Textbox v-model="supplierCounty" placeholder="County" />
            </FormField>
            <div class="tw-flex tw-mt-2">
                <FormField label="Postal Code">
                    <Textbox v-model="supplierPostcode" placeholder="Postal Code" />
                </FormField>
                <FormField label="Country" class="tw-flex-1 tw-ml-3">
                    <Select v-model="supplierCountry" placeholder="Country" required>
                        <option v-for="country in countries" :key="country" :value="country">
                            {{ country }}
                        </option>
                    </Select>
                </FormField>
            </div>
            <FormField label="Email Address" class="tw-mt-2">
                <Textbox v-model="supplierEmail" placeholder="Email Address" />
            </FormField>
        </template>
        <Banner v-else type="is-danger">
            You do not have permission to create new {{ objectLabel }}s.
            <br />
            Please contact your account admin if you believe that this is a mistake.
        </Banner>
        <template #footer>
            <Button
                v-if="Auth.can('manage suppliers')"
                type="is-success"
                :disabled="!isValid"
                @click="createSupplier()"
            >
                {{ modalTitle }}
            </Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
        Select,
        Banner,
    },
    props: {
        objectLabel: {
            type: String,
            default: 'supplier',
        },
    },
    emits: ['created'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            countries: window.Locales,
            supplierType: 0,
            supplierName: null,
            supplierAddress1: null,
            supplierAddress2: null,
            supplierCity: null,
            supplierCounty: null,
            supplierPostcode: null,
            supplierCountry: null,
            supplierEmail: null,
        };
    },
    computed: {
        modalTitle() {
            if (!this.supplierType) {
                return 'Create ' + this.objectLabel.charAt(0).toUpperCase() + this.objectLabel.slice(1);
            } else if (this.supplierType === 1) {
                return 'Create Ticket Seller';
            }
            return 'Create Trader';
        },
        isValid() {
            return !!this.supplierName;
        },
    },
    methods: {
        open(payload = null) {
            this.reset();
            if (payload) {
                this.supplierType = payload.supplierType ? payload.supplierType : 0;
                this.supplierName = payload.supplierName ? payload.supplierName : null;
            }
            this.$refs.modal.open();
        },
        reset() {
            this.supplierType = 0;
            this.supplierName = null;
            this.supplierAddress1 = null;
            this.supplierAddress2 = null;
            this.supplierCity = null;
            this.supplierCounty = null;
            this.supplierPostcode = null;
            this.supplierCountry = null;
            this.supplierEmail = null;
        },
        close() {
            this.$refs.modal.close();
        },
        createSupplier() {
            this.isLoading = true;
            axios
                .post(route('api.account.contacts.create'), {
                    name: this.supplierName,
                    address1: this.supplierAddress1,
                    address2: this.supplierAddress2,
                    city: this.supplierCity,
                    county: this.supplierCounty,
                    country: this.supplierCountry,
                    postal_code: this.supplierPostcode,
                    supplier_type: this.supplierType,
                    email_address: this.supplierEmail,
                })
                .then(({ data }) => {
                    toast(
                        `${this.objectLabel.charAt(0).toUpperCase() + this.objectLabel.slice(1)} Created`,
                        'Your ' + this.objectLabel + ' ' + data.name + ' has been created successfully',
                        'success'
                    );
                    this.$emit('created', data);
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
