<template>
    <DraggableModal ref="currencyConverterModal" title="Currency Converter" :z-index="9999">
        <div class="buefy-wrapper">
            <NumberInput
                v-model="value"
                placeholder="Amount"
                :min="0"
                :step="0.1"
                dp="2"
                @update:model-value="debouncedConvertAmount()"
            />
            <div class="d-flex align-items-center mt-3">
                <Select
                    v-model="fromCurrency"
                    placeholder="From"
                    expanded
                    :loading="isLoading"
                    @update:model-value="debouncedConvertAmount()"
                >
                    <option v-for="currency in currencies" :key="currency.key" :value="currency.key">
                        {{ currency.value }} ({{ currency.key }})
                    </option>
                </Select>
                <i class="mdi mdi-arrow-right mx-2"></i>
                <Select
                    v-model="toCurrency"
                    placeholder="To"
                    expanded
                    :loading="isLoading"
                    @update:model-value="debouncedConvertAmount()"
                >
                    <option v-for="currency in currencies" :key="currency.key" :value="currency.key">
                        {{ currency.value }} ({{ currency.key }})
                    </option>
                </Select>
            </div>
            <div v-if="result" class="tw-flex align-items-center border rounded p-3 mt-3">
                <div>
                    <span>{{ result.from }}</span>
                    <span class="mb-1 tw-text-xl">{{ result.input }}</span>
                    <small class="text-muted">Rate: {{ result.rate }}</small>
                </div>
                <i class="mdi mdi-arrow-right mx-2"></i>
                <div :label="result.to" class="flex-fill text-right mb-0" custom-class="mb-0">
                    <span>{{ result.to }}</span>
                    <span class="mb-1 tw-text-xl">{{ result.output }}</span>
                    <a href="#" @click.prevent="copyResult">
                        <small>
                            <i class="mdi mdi-content-copy"></i>
                            {{ copyText }}
                        </small>
                    </a>
                </div>
            </div>
        </div>
    </DraggableModal>
</template>
<script>
import debounce from 'lodash/debounce';
import { useDataStore } from '@/js/stores/DataStore.js';
import DraggableModal from '../DraggableModal.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import Select from '@/js/components/controls/Select.vue';

export default {
    components: {
        DraggableModal,
        NumberInput,
        Select,
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            currencies: store.get('currencies'),
            value: null,
            fromCurrency: null,
            toCurrency: null,
            result: null,
            copyText: 'Copy value',
        };
    },
    mounted() {
        Eventbus.$on('currencyConverter', () => {
            this.openModal();
        });
    },
    methods: {
        openModal() {
            this.resetData();
            this.$refs.currencyConverterModal.open();
        },
        convertAmount() {
            if (!this.value || !this.fromCurrency || !this.toCurrency) {
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.currency.convert', {
                        inputtext: this.value,
                        convert_from: this.fromCurrency,
                        convert_to: this.toCurrency,
                    })
                )
                .then(({ data }) => {
                    this.result = data;
                })
                .finally(() => (this.isLoading = false));
        },
        debouncedConvertAmount: debounce(function () {
            this.convertAmount();
        }, 500),
        copyResult() {
            if (!this.result) {
                return;
            }
            navigator.clipboard.writeText(this.result.output.replaceAll(',', ''));
            this.copyText = 'Copied!';
            this.resetCopyText();
        },
        resetCopyText: debounce(function () {
            this.copyText = 'Copy value';
        }, 500),
        resetData() {
            this.value = null;
            this.fromCurrency = null;
            this.toCurrency = null;
            this.result = null;
        },
    },
};
</script>
