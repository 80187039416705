<template>
    <div>
        <FormField class="tw-mb-2">
            <Textbox v-model="address.address1" placeholder="Address Line 1 (required)" type="text" class="tw-w-full" />
        </FormField>
        <FormField class="tw-mb-2">
            <Textbox v-model="address.address2" placeholder="Address Line 2" type="text" class="tw-w-full" />
        </FormField>
        <div class="tw-flex tw-mb-2">
            <FormField class="tw-flex-1 tw-pr-2">
                <Textbox v-model="address.city" placeholder="City" type="text" class="tw-w-full" />
            </FormField>
            <FormField class="tw-flex-1 tw-pl-2">
                <Textbox v-model="address.postcode" placeholder="Postcode" type="text" class="tw-w-full" />
            </FormField>
        </div>
        <FormField>
            <Select v-model="address.country" placeholder="Country" class="tw-w-full">
                <option v-for="locale in locales" :key="locale" :value="locale">
                    {{ locale }}
                </option>
            </Select>
        </FormField>
    </div>
</template>
<script>
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';

export default {
    components: {
        FormField,
        Textbox,
        Select,
    },
    props: {
        locales: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: Object,
            default: () => ({
                address1: '',
                address2: '',
                city: '',
                postcode: '',
                country: '',
            }),
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            address: this.modelValue,
        };
    },
    watch: {
        modelValue: {
            handler(newVal) {
                this.address = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        this.address = this.modelValue;
    },
};
</script>
