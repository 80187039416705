<template>
    <Modal ref="modal" title="Export Ticket Transactions" size="is-large">
        <FormField label="Sales Channel">
            <Select v-model="selectedSupplier">
                <option :value="null">Direct Sales</option>
                <option v-for="ticketSeller in availableTicketSellers" :key="ticketSeller.id" :value="ticketSeller.id">
                    {{ ticketSeller.name }}
                </option>
            </Select>
        </FormField>
        <Banner type="is-info">
            Exporting ticket transactions will generate a CSV file containing all ticket sales for the selected sales
            channel.
            <br />
            This file can then be edited and re-imported to correct any ticket transactions on the bulk edit screen.
        </Banner>
        <template #footer>
            <Button @click="close()">Cancel</Button>
            <Button class="tw-ml-auto" type="is-primary" @click="exportTransactions()">Export</Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import { uniqueArray, toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        Banner,
        FormField,
        Select,
    },
    data() {
        const store = useDataStore();

        return {
            selectedSupplier: null,
            store: store,
        };
    },
    computed: {
        availableTicketSellers() {
            const availableSuppliers = uniqueArray(
                Object.values(this.store.get('current_event.ticket_types'))
                    .map((t) => t.ticket_sales.map((s) => s.supplier_id))
                    .flat()
            );
            return Object.values(this.store.get('account.suppliers')).filter(
                (s) => s.supplier_type === 1 && availableSuppliers.includes(s.id)
            );
        },
    },
    methods: {
        open() {
            if (!Auth.can('export data')) {
                toast('Error', 'You do not have permission to export data.', 'error');
                return;
            }
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        exportTransactions() {
            window.open(
                route('api.account.event.orders.export', {
                    supplier: this.selectedSupplier ?? '',
                }),
                '_blank'
            );
        },
    },
};
</script>
