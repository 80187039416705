<template>
    <FullpageContainer>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <template v-if="!isLoading" #header>
            <div v-if="payrun" class="payrun-actions-header buefy-wrapper">
                <Button class="tw-flex tw-gap-1 tw-items-center" @click="$emit('return')">
                    <i class="mdi mdi-arrow-left"></i>
                    Return to Payruns
                </Button>
                <div class="payrun-title">
                    {{ payrun.title }}
                </div>
            </div>
        </template>
        <div v-if="payrun && !isLoading" class="payrun-details buefy-wrapper">
            <div class="payrun-details-content">
                <FormField label="Payments" />
                <DataTable
                    class="align-middle"
                    :data="payrun.items"
                    :columns="[
                        { title: 'Department', field: 'department' },
                        { title: 'Subcategory', field: 'subcategory' },
                        { title: 'Supplier', field: 'supplier' },
                        { title: 'Purchase Order', field: 'po' },
                        { title: 'Invoice Number', field: 'invoice' },
                        { title: 'Due Date', field: 'due_date' },
                        { title: 'Amount', field: 'amount', class: '!tw-text-center' },
                        {
                            title: 'Paid',
                            field: 'paid',
                            hidden: payrun.status !== 'Approved',
                            width: 70,
                        },
                        {
                            title: '',
                            field: 'draft',
                            hidden: payrun.status !== 'Draft',
                        },
                        {
                            title: '',
                            field: 'approval',
                            width: 50,
                            class: 'tw-flex tw-justify-content-center',
                            hidden: !(
                                payrun.status &&
                                payrun.status === 'Awaiting Approval' &&
                                payrun.current_user_can_approve
                            ),
                        },
                    ]"
                >
                    <template #column(department)="{ row }">
                        <span v-if="hasMultipleDepartments(row)" class="text-muted">Multiple</span>
                        <a
                            v-else
                            :href="
                                route('account.event.purchaseorders.summary', {
                                    event: row.invoice.event_id,
                                    page: 'summary',
                                    departmentId: row.invoice.purchase_orders[0].category.department_id,
                                })
                            "
                            target="_blank"
                        >
                            {{ row.invoice.purchase_orders[0].category.department.name }}
                            <i class="mdi mdi-open-in-new"></i>
                        </a>
                    </template>
                    <template #column(subcategory)="{ row }">
                        <span v-if="hasMultipleSubcategories(row)" class="text-muted">Multiple</span>
                        <a
                            v-else-if="
                                row.invoice && row.invoice.purchase_orders && row.invoice.purchase_orders[0].category
                            "
                            :href="
                                route('account.event.purchaseorders.summary', {
                                    event: row.invoice.event_id,
                                    page: 'summary',
                                    departmentId: row.invoice.purchase_orders[0].category.department_id,
                                })
                            "
                            target="_blank"
                        >
                            {{ row.invoice.purchase_orders[0].category.name }}
                            <i class="mdi mdi-open-in-new"></i>
                        </a>
                        <span v-else class="text-muted">&mdash;</span>
                    </template>
                    <template #column(supplier)="{ row }">
                        <a href="#" @click.prevent="openSupplier(row.invoice.supplier.id)">
                            {{ row.invoice.supplier.name }}
                        </a>
                    </template>
                    <template #column(po)="{ row }">
                        <a
                            v-if="
                                row.invoice.purchase_orders.length === 1 &&
                                row.invoice.purchase_orders[0].event_id === store.get('current_event.id')
                            "
                            href="#"
                            @click.prevent="openPurchaseOrder(row.invoice.purchase_orders[0].id)"
                        >
                            {{ row.invoice.purchase_orders[0].title }}
                        </a>
                        <span
                            v-else-if="
                                row.invoice.purchase_orders.length === 1 &&
                                row.invoice.purchase_orders[0].event_id !== store.get('current_event.id')
                            "
                            class="text-muted"
                        >
                            {{ row.invoice.purchase_orders[0].title }} ({{
                                store.get(`account.events.${row.invoice.purchase_orders[0].event_id}.name`)
                            }})
                        </span>
                        <span v-else class="text-muted">Multiple</span>
                    </template>
                    <template #column(invoice)="{ row }">
                        <a href="#" @click.prevent="viewInvoice(row.invoice)">
                            {{ row.invoice.invoice_number }}
                        </a>
                    </template>
                    <template #column(due_date)="{ row }">
                        {{ DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_FULL) }}
                        <Tooltip
                            v-if="
                                DateTime.fromISO(row.date).diff(
                                    DateTime.fromFormat(payrun.payment_date.split(' ')[0], 'y-LL-dd'),
                                    ['days']
                                ).days < 0
                            "
                            label="This payment would be overdue on the selected payrun date"
                        >
                            <i class="mdi mdi-alert text-danger"></i>
                        </Tooltip>
                    </template>
                    <template #column(amount)="{ row }">
                        {{ localise(row.amount, 2, true) }}
                    </template>
                    <template #column(paid)="{ row }">
                        <Checkbox v-model="row.is_paid" @update:model-value="toggleItemPaid(row.id)"></Checkbox>
                    </template>
                    <template #column(draft)="{ row }">
                        <Dropdown v-if="isEditable && !row.is_paid">
                            <a href="#" @click.prevent="removeFromPayrun(row.id)">Remove</a>
                        </Dropdown>
                    </template>

                    <template #column(approval)="{ row }">
                        <Tooltip label="Reject Item" position="left">
                            <Button type="is-danger" @click="rejectItem(row.id)">
                                <i class="mdi mdi-close"></i>
                            </Button>
                        </Tooltip>
                    </template>
                </DataTable>
                <Button
                    v-if="payrun.status && isEditable && payrun.status === 'Draft'"
                    type="is-link"
                    class="tw-flex tw-gap-1 tw-items-center tw-w-full"
                    @click="addPayment"
                >
                    <i class="mdi mdi-plus"></i>
                    Add another payment
                </Button>
            </div>
            <div class="payrun-details-sidebar">
                <FormField class="tw-mb-2" label="Status">
                    <template v-if="payrun.status && payrun.status !== 'Approved'">
                        <ApprovalControls :approvables="[payrun]" :locked="!isEditable" />
                    </template>
                    <template v-else>
                        <span v-if="!payrun.status" class="tag">Cancelled</span>
                        <span v-else class="tag is-success is-light">Paid</span>
                    </template>
                </FormField>
                <div v-if="payrun.status && Auth.can('manage payruns') && payrun.status === 'Approved'" class="tw-mb-2">
                    <button class="button is-small is-success approvals-style-button" @click="completeRun()">
                        Mark As Paid
                    </button>
                </div>
                <FormField class="tw-mt-auto tw-mb-2" label="Payment Date">
                    {{ DateTime.fromSQL(payrun.payment_date).toLocaleString(DateTime.DATE_FULL) }}
                </FormField>
                <FormField class="tw-mb-2" label="Payments">
                    {{ payrun.items.length }}
                </FormField>
                <div class="row tw-mb-2">
                    <div class="col-6">
                        <FormField label="Total">
                            {{ localise(totalValue, 2, true) }}
                        </FormField>
                    </div>
                    <div class="col-6">
                        <FormField label="Paid">
                            {{ localise(totalPaid, 2, true) }}
                        </FormField>
                    </div>
                </div>
                <FormField v-if="payrun.creator" label="Created by">
                    <User :user="payrun.creator" :hide-name="false" />
                </FormField>
            </div>
        </div>
        <Modal ref="addPaymentModal" title="Add Payment" size="is-xxlarge" :loading="isLoading" paddingless>
            <DataTable
                :columns="[
                    { title: '', field: 'checkbox' },
                    { title: 'Department', field: 'department' },
                    { title: 'Subcategory', field: 'subcategory' },
                    { title: 'Purchase Order', field: 'po' },
                    { title: 'Invoice Number', field: 'invoice' },
                    { title: 'Due Date', field: 'due_date' },
                    { title: 'Amount', field: 'amount' },
                ]"
                class="align-middle"
                :data="availablePayments"
            >
                <template #column(checkbox)="{ row }">
                    <Checkbox
                        @update:model-value="
                            (v) => {
                                if (v) {
                                    selectedPayments.push(row);
                                } else {
                                    selectedPayments = selectedPayments.filter((c) => c.id !== row.id);
                                }
                            }
                        "
                    />
                </template>
                <template #column(department)="{ row }">
                    <span
                        v-if="
                            row.invoice &&
                            row.invoice.purchase_orders &&
                            row.invoice.purchase_orders[0].category &&
                            row.invoice.purchase_orders[0].category.department
                        "
                    >
                        {{ row.invoice.purchase_orders[0].category.department.name }}
                    </span>
                    <span v-else class="text-muted">&mdash;</span>
                </template>
                <template #column(subcategory)="{ row }">
                    <span v-if="row.invoice && row.invoice.purchase_orders && row.invoice.purchase_orders[0].category">
                        {{ row.invoice.purchase_orders[0].category.name }}
                    </span>
                    <span v-else class="text-muted">&mdash;</span>
                </template>
                <template #column(po)="{ row }">
                    <span v-if="row.invoice.purchase_orders.length === 1">
                        {{ row.invoice.purchase_orders[0].title }}
                    </span>
                    <span v-else class="text-muted">Multiple</span>
                </template>
                <template #column(invoice)="{ row }">
                    {{ row.invoice.invoice_number }}
                </template>
                <template #column(due_date)="{ row }">
                    {{ DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_FULL) }}
                    <Tooltip
                        v-if="
                            DateTime.fromISO(row.date).diff(
                                DateTime.fromFormat(payrun.payment_date.split(' ')[0], 'y-LL-dd'),
                                ['days']
                            ).days < 0
                        "
                        label="This payment would be overdue on the selected payrun date"
                    >
                        <i class="mdi mdi-alert text-danger"></i>
                    </Tooltip>
                </template>
                <template #column(amount)="{ row }">
                    {{ localise(row.amount, 2, true) }}
                </template>
            </DataTable>
            <template #footer>
                <Button :disabled="!selectedPayments.length" type="is-success" @click="addPayments">
                    Add {{ selectedPayments.length > 1 ? 'Payments' : 'Payment' }}
                </Button>
            </template>
        </Modal>
    </FullpageContainer>
</template>
<script>
import FullpageContainer from '../../../Layout/FullpageContainer.vue';
import Modal from '../../../Modal.vue';
import Dropdown from '../../../controls/Dropdown.vue';
import ApprovalControls from '../../../widgets/ApprovalControls.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import User from '@/js/components/widgets/User.vue';
import { uniqueArray } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        FullpageContainer,
        Modal,
        Dropdown,
        ApprovalControls,
        LoadingSpinner,
        Button,
        FormField,
        Tooltip,
        Checkbox,
        DataTable,
        User,
    },
    props: {
        activePayrun: {
            type: [String, Number],
        },
    },
    emits: ['return'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            payrun: null,
            availablePayments: [],
            selectedPayments: [],
            store: store,
            DateTime,
        };
    },
    computed: {
        isEditable() {
            return Auth.can('manage payruns') && !this.store.get('current_event.is_closed');
        },
        totalValue() {
            if (!this.payrun) {
                return 0;
            }
            return this.payrun.items.map((i) => parseFloat(i.amount)).reduce((p, c) => p + c, 0);
        },
        totalPaid() {
            if (!this.payrun) {
                return 0;
            }
            return this.payrun.items
                .filter((i) => i.is_paid)
                .map((i) => parseFloat(i.amount))
                .reduce((p, c) => p + c, 0);
        },
    },
    watch: {
        activePayrun(newVal) {
            this.loadPayrun(newVal);
        },
    },
    mounted() {
        if (this.activePayrun) {
            this.loadPayrun(this.activePayrun);
        }
        Eventbus.$on('reload:payrun', () => {
            if (this.payrun) {
                this.loadPayrun(this.payrun.id);
            }
        });
    },
    methods: {
        loadPayrun(id) {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.payments.payruns.get', {
                        payrun: id,
                    })
                )
                .then((response) => {
                    this.payrun = response.data;
                })
                .finally(() => (this.isLoading = false));
        },
        savePayrun() {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.payments.payruns.update', {
                        payrun: this.payrun.id,
                    }),
                    this.payrun
                )
                .then((response) => (this.payrun = response.data))
                .finally(() => (this.isLoading = false));
        },
        toggleItemPaid(id) {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.payments.payruns.toggleItemPaid', {
                        payrun: this.payrun.id,
                        item: id,
                    })
                )
                .finally(() => (this.isLoading = false));
        },
        addPayment() {
            this.availablePayments = [];
            this.selectedPayments = [];
            this.$refs.addPaymentModal.open();
            axios
                .get(
                    route('api.account.event.payments.unassigned', {
                        end: this.createDate,
                    })
                )
                .then((response) => {
                    this.availablePayments = response.data.data;
                })
                .finally(() => (this.isLoading = false));
        },
        addPayments() {
            this.isLoading = true;
            this.selectedPayments.forEach((sp) => this.payrun.items.push(sp));
            this.savePayrun();
        },
        removeFromPayrun(id) {
            this.isLoading = true;
            this.payrun.items = this.payrun.items.filter((pi) => pi.id !== id);
            this.savePayrun();
        },
        async rejectItem(id) {
            if (!this.payrun.current_user_can_approve) {
                return;
            }
            const { value: rejectReason } = await swal.fire({
                title: 'Reject Payment',
                text: 'Rejecting this payment will remove it from the payrun entirely ',
                input: 'text',
                inputPlaceholder: 'Provide a reason for rejecting the payment',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You must provide a rejection reason';
                    } else if (value.length > 255) {
                        return 'The rejection reason should be 255 characters or less';
                    }
                },
            });
            if (!rejectReason) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.payments.payruns.rejectItem', {
                        payrun: this.payrun.id,
                        item: id,
                    }),
                    {
                        reject_reason: rejectReason,
                    }
                )
                .then((response) => (this.payrun = response.data))
                .finally(() => (this.isLoading = false));
        },
        isRowChecked(a, b) {
            return a.id === b.id;
        },
        completeRun() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.payments.payruns.complete', {
                        payrun: this.payrun.id,
                    })
                )
                .then((response) => {
                    this.payrun = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        hasMultipleDepartments(row) {
            return uniqueArray(row.invoice.purchase_orders.map((po) => po.category.department_id)).length > 1;
        },
        hasMultipleSubcategories(row) {
            return uniqueArray(row.invoice.purchase_orders.map((po) => po.category_id)).length > 1;
        },
        openPurchaseOrder(purchaseOrderId) {
            Eventbus.$emit('purchaseOrder:view', purchaseOrderId);
        },
        openSupplier(supplierId) {
            Eventbus.$emit('view:supplier', supplierId);
        },
        viewInvoice(invoice) {
            Eventbus.$emit('viewInvoice', 'SupplierInvoice', {
                event: invoice.event_id,
                purchaseOrder: invoice.purchase_orders[0].id,
                supplierInvoice: invoice.id,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.payrun-actions-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & > .payrun-title {
        margin-left: 10px;
    }
}
.payrun-details {
    display: flex;

    & > .payrun-details-sidebar {
        min-width: 250px;
        padding: 15px;
        background: white;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
    }
    & > .payrun-details-content {
        flex: 1;
        padding: 15px 0 0 0;
        margin-right: 15px;
        background: white;
        border-radius: 6px;
    }
}
</style>
