<template>
    <Page title="Account Dashboard">
        <div class="buefy-wrapper tw-py-4">
            <div class="row">
                <div
                    class="col-12"
                    :class="{
                        'col-lg-7 col-xl-9': Auth.can('create events') || Auth.can('manage users'),
                    }"
                >
                    <div class="tw-flex tw-mb-2">
                        <Textbox v-model="searchQuery" placeholder="Search events..." />
                        <div class="tw-flex tw-ml-2 tw-gap-1">
                            <Button
                                v-for="view in ['Card', 'Table']"
                                :key="view"
                                class="tw-flex tw-gap-2 tw-items-center"
                                :type="viewStyle === view.toLowerCase() ? 'is-success' : ''"
                                @click="viewStyle = view.toLowerCase()"
                            >
                                <i class="mdi mdi-cards-outline"></i>
                                {{ view }}
                            </Button>
                        </div>
                    </div>
                    <div v-if="viewStyle === 'card'" class="event-list-cards">
                        <div v-for="event in filteredEvents" :key="event.id" class="event-list-card">
                            <div class="d-flex align-items-center">
                                <h5 class="tw-m-0">{{ event.name }}</h5>
                            </div>
                            <small
                                class="d-block text-center bg-light py-1"
                                style="border-top: 1px solid rgba(0, 0, 0, 0.066)"
                            >
                                {{
                                    event.pivot.event_last_accessed
                                        ? 'Last accessed ' +
                                          relativeTimestamp(event.pivot.event_last_accessed, 'utc', false)
                                        : 'Never accessed'
                                }}
                            </small>
                            <div class="event-card-section">
                                <FormField label="Location">
                                    {{ event.primary_address.address }}
                                </FormField>
                                <FormField
                                    :label="DateTime.fromISO(event.starts_at) <= DateTime.utc() ? 'Started' : 'Starts'"
                                >
                                    {{ localTimestamp(event.starts_at) }}
                                    <small class="text-muted">{{ relativeTimestamp(event.starts_at) }}</small>
                                </FormField>
                            </div>
                            <div class="event-card-actions">
                                <a
                                    :href="
                                        route('account.event.dashboard', {
                                            event: event.id,
                                        })
                                    "
                                >
                                    View Event
                                </a>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        v-else
                        :data="filteredEvents"
                        :columns="[
                            { title: 'Event Name', field: 'name' },
                            { title: 'Location', field: 'primary_address' },
                            { title: 'Starts', field: 'starts_at' },
                            { title: 'Last Accessed', field: 'last_accessed' },
                        ]"
                        row-class="tw-cursor-pointer"
                        @select="viewEvent"
                    >
                        <template #column(primary_address)="{ row }">
                            {{ row.primary_address.address }}
                        </template>
                        <template #column(starts_at)="{ row }">
                            {{ localTimestamp(row.starts_at) }}
                        </template>
                        <template #column(last_accessed)="{ row }">
                            {{
                                row.pivot.event_last_accessed
                                    ? relativeTimestamp(row.pivot.event_last_accessed, 'utc', false)
                                    : 'Never accessed'
                            }}
                        </template>
                        <template #empty>
                            <div class="text-center text-muted">
                                There aren't any events to display.
                                <template v-if="events.length">
                                    <br />
                                    Try
                                    <a href="#" @click.prevent="searchQuery = null">clearing your search</a>
                                    or searching for something else.
                                </template>
                            </div>
                        </template>
                    </DataTable>
                </div>
                <div v-if="Auth.can('create events') || Auth.can('manage users')" class="col-12 col-lg-5 col-xl-3">
                    <ew-card title="Quick Actions" :collapsible="false">
                        <div class="tw-flex tw-flex-col tw-items-start">
                            <Button
                                v-if="Auth.can('create events')"
                                type="is-link"
                                class="!tw-px-0"
                                @click="createEvent()"
                            >
                                Create a new event
                            </Button>
                            <Button
                                v-if="Auth.can('manage users')"
                                type="is-link"
                                class="!tw-px-0"
                                @click="inviteUser()"
                            >
                                Invite a team member
                            </Button>
                        </div>
                    </ew-card>
                </div>
            </div>
            <InviteUser ref="inviteUserModal" @invited="postInviteUser()" />
        </div>
    </Page>
</template>
<script>
import axios from 'axios';
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import Page from '../../Page.vue';
import InviteUser from './Team/Users/InviteUser.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { DateTime } from 'luxon';

import Button from '@/js/components/controls/Button.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { localTimestamp, relativeTimestamp, setRoute, toast } from '@/js/utils.js';

export default {
    components: {
        Page,
        InviteUser,
        FormField,
        Textbox,
        Button,
        DataTable,
    },
    data() {
        const store = useDataStore();
        const settings = useSettingsStore();
        return {
            events: [],
            currentAccount: store.get('account'),
            searchQuery: null,
            viewStyle: settings.account(store.get('account.id'), 'eventViewStyle') ?? 'card',
            settings: settings,
            DateTime,
        };
    },
    computed: {
        filteredEvents() {
            if (!this.searchQuery) {
                return this.events;
            }
            return this.events.filter((event) => {
                return event.name.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        },
    },
    watch: {
        viewStyle(value) {
            this.settings.setAccount(this.currentAccount.id, 'eventViewStyle', value);
        },
    },
    mounted() {
        this.getEvents();
    },
    methods: {
        relativeTimestamp,
        localTimestamp,
        getEvents() {
            axios.get(route('api.account.events')).then(({ data }) => (this.events = data));
        },
        createEvent() {
            window.location.href = route('onboarding');
        },
        viewEvent(event) {
            window.location.href = route('account.event.dashboard', { event: event.id });
        },
        inviteUser() {
            this.$refs.inviteUserModal.open();
        },
        postInviteUser() {
            toast(
                'Success',
                'The user has been sent an email inviting them to join ' + this.currentAccount.name,
                'success'
            );
            setRoute('account.team');
        },
    },
};
</script>
<style lang="scss" scoped>
.event-list-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 15px 0;

    & > .event-list-card {
        padding: 15px 0 0 0;
        background: white;
        border-radius: 8px;
        min-height: 50px;
        min-width: min(325px, 100%);
        max-width: min(325px, 100%);
        line-height: 100%;
        border: 1px solid rgba(black, 0.1);

        & > .d-flex {
            padding: 0 15px 10px 15px;
        }

        & > .event-card-section {
            padding: 15px;
            border-top: 1px solid rgba(black, 0.1);
        }

        & > .event-card-actions {
            display: flex;
            border-top: 1px solid rgba(black, 0.1);

            & > a {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 40px;
                flex: 1;

                &:hover {
                    background: rgba(black, 0.01);
                }
            }
        }
    }
}
</style>
