<template>
    <Modal
        ref="modal"
        title="Invoice Payments"
        size="is-xxlarge"
        :loading="isLoading"
        paddingless
        :close-enable="!isSupplierRaised"
        @closed="resetModal()"
    >
        <PaymentDateBuilder v-if="!isLoading" v-model="paymentDates" :default="remainingBalance" />
        <Banner
            v-if="invoice && isConnectedToXero && invoice.invoice_guid && invoice.invoice_status === 'DRAFT'"
            class="tw-mx-4 tw-my-3"
            type="is-warning"
        >
            <div class="tw-flex tw-items-center tw-gap-4">
                <i class="mdi mdi-alert tw-text-3xl"></i>
                <p class="tw-flex-1 tw-mb-0">
                    Your invoice is currently marked as draft in Xero.
                    <br />
                    When submitting a payment date your invoice will be automatically authorised.
                </p>
            </div>
        </Banner>
        <Banner v-else-if="isConnectedToXero" class="m-3">
            <div class="d-flex align-items-center">
                <img src="@/images/brands/xero_blue.png" class="icon-lg" alt="Xero Logo" />
                <div class="flex-fill pl-3">
                    <strong>You&apos;re connected to Xero!</strong>
                    <br />
                    We&apos;ll automatically sync any payments assigned a bank account to and from Xero
                </div>
            </div>
        </Banner>
        <template #footer>
            <Button :disabled="isSupplierRaised" @click="closeModal()">Cancel</Button>
            <FormField v-if="invoice" label="Invoice Value" class="tw-ml-4">
                {{ localise(parseFloat(invoice.total_value) - totalCredits, 2, true) }}
            </FormField>
            <FormField
                v-if="invoice"
                label="Total Payments"
                class="tw-ml-auto tw-mr-4 tw-text-right"
                :class="{
                    'tw-text-red-400': totalPayments > invoice.total_value,
                }"
            >
                {{ localise(totalPayments, 2, true) }}
            </FormField>
            <Button
                type="is-success"
                :disabled="totalPayments > invoice.total_value || noSupplierPayments"
                @click="upsertPaymentDates()"
            >
                Save Changes
            </Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import PaymentDateBuilder from '../../widgets/PaymentDateBuilder.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import FormField from '@/js/components/widgets/FormField.vue';

export default {
    components: {
        Modal,
        PaymentDateBuilder,
        Button,
        FormField,
        Banner,
    },
    emits: ['closed'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isDisabled: false,
            isConnectedToXero: false,
            event: null,
            purchaseOrder: null,
            revenueEntry: null,
            invoice: null,
            paymentDates: [],
            store: store,
            isSupplierRaised: null,
        };
    },
    computed: {
        invoiceAmount() {
            if (!this.invoice) {
                return 0;
            }
            //Return the Gross figure
            return this.invoice.total_value;
        },
        totalCredits() {
            if (!this.invoice || !this.invoice.credit_notes || !this.invoice.credit_notes.length) {
                return 0;
            }
            return this.invoice.credit_notes
                .map((creditNote) => (creditNote.amount ? parseFloat(creditNote.amount) : 0))
                .reduce((a, c) => a + c, 0);
        },
        totalPayments() {
            return this.paymentDates
                .map((paymentDate) => (paymentDate.amount ? parseFloat(paymentDate.amount) : 0))
                .reduce((a, c) => a + c, 0);
        },
        remainingBalance() {
            return (
                parseFloat(this.invoiceAmount.toFixed(2)) -
                parseFloat(this.totalPayments.toFixed(2)) -
                parseFloat(this.totalCredits.toFixed(2))
            );
        },
        noSupplierPayments() {
            return this.isSupplierRaised && this.totalPayments < 0.01;
        },
    },
    methods: {
        openModal(payload) {
            this.event = payload.event;
            this.purchaseOrder = payload.purchaseOrder;
            this.revenueEntry = payload.revenueEntry;
            this.invoice = payload.invoice;
            this.isSupplierRaised = payload.isSupplierRaised;
            this.isConnectedToXero = this.store.get('account.is_connected_to_xero');
            this.reloadData();
            this.$refs.modal.open();
        },
        closeModal() {
            this.resetModal();
            this.$refs.modal.close();
        },
        resetModal() {
            this.isLoading = false;
            this.purchaseOrder = null;
            this.revenueEntry = null;
            this.invoice = null;
            this.paymentDates = [];
            this.isSupplierRaised = null;
            this.$emit('closed');
        },
        reloadData() {
            this.isLoading = true;
            axios
                .get(
                    this.purchaseOrder
                        ? route(
                              'api.account.event.purchaseorders.invoices.get',
                              this.event
                                  ? {
                                        event: this.event,
                                        purchaseOrder: this.purchaseOrder,
                                        supplierInvoice: this.invoice,
                                    }
                                  : {
                                        purchaseOrder: this.purchaseOrder,
                                        supplierInvoice: this.invoice,
                                    }
                          )
                        : route(
                              'api.account.event.revenue.invoices.get',
                              this.event
                                  ? {
                                        event: this.event,
                                        revenueEntry: this.revenueEntry,
                                        customerInvoice: this.invoice,
                                    }
                                  : {
                                        revenueEntry: this.revenueEntry,
                                        customerInvoice: this.invoice,
                                    }
                          )
                )
                .then((response) => {
                    this.invoice = response.data;
                    this.paymentDates = response.data.payment_dates;
                    this.isLoading = false;
                });
        },
        upsertPaymentDates() {
            this.isLoading = true;
            axios
                .post(
                    this.purchaseOrder
                        ? route(
                              'api.account.event.purchaseorders.invoices.payments.update',
                              this.event
                                  ? {
                                        event: this.event,
                                        purchaseOrder: this.purchaseOrder,
                                        supplierInvoice: this.invoice,
                                    }
                                  : {
                                        purchaseOrder: this.purchaseOrder,
                                        supplierInvoice: this.invoice,
                                    }
                          )
                        : route(
                              'api.account.event.revenue.invoices.payments.update',
                              this.event
                                  ? {
                                        event: this.event,
                                        revenueEntry: this.revenueEntry,
                                        customerInvoice: this.invoice,
                                    }
                                  : {
                                        revenueEntry: this.revenueEntry,
                                        customerInvoice: this.invoice,
                                    }
                          ),
                    {
                        payment_dates: this.paymentDates,
                    }
                )
                .then((response) => {
                    this.paymentDates = response.data;
                    this.closeModal();
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
