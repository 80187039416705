<template>
    <div class="buefy-wrapper">
        <div class="d-flex align-items-center mb-2">
            <Textbox v-model="searchQuery" placeholder="Search for a team member..." />
        </div>
        <DataTable
            :endpoint="
                route('api.admin.account.team', {
                    account: account,
                })
            "
            :columns="[
                {
                    title: 'ID',
                    field: 'id',
                    width: 80,
                },
                {
                    title: 'Name',
                    field: 'users.name',
                },
                {
                    title: 'Email',
                    field: 'users.email',
                },
                {
                    title: 'Role',
                    field: 'roles',
                    class: '!tw-text-center',
                },
                {
                    title: '',
                    field: 'actions',
                    class: '!tw-text-right',
                    hidden: !isSynergiUser,
                },
            ]"
            :searchable-string="searchQuery"
        >
            <!-- eslint-disable -->
            <template #column(id)="{ row }">
                {{ row.id }}
            </template>
            <template #column(users.name)="{ row }">
                {{ row.name }}
            </template>
            <template #column(users.email)="{ row }">
                {{ row.email }}
            </template>
            <template #column(roles)="{ row }">
                <span v-if="!row.roles || !row.roles.length" class="text-muted">None</span>
                <span v-else>{{ row.roles.map((r) => r.name).join(', ') }}</span>
            </template>
            <template #column(actions)="{ row }">
                <Tooltip
                    v-if="!row.email.endsWith('@synergitech.co.uk')"
                    position="left"
                    :label="'Login as ' + row.name"
                >
                    <Button @click="sudoUser(row)">
                        <i class="mdi mdi-login-variant"></i>
                    </Button>
                </Tooltip>
            </template>
        </DataTable>
        <!-- eslint-enable -->
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';

export default {
    components: {
        Button,
        Textbox,
        Tooltip,
        DataTable,
    },
    props: {
        account: {
            type: [String, Number],
        },
    },
    data() {
        const store = useDataStore();
        return {
            searchQuery: null,
            store: store,
        };
    },
    computed: {
        isSynergiUser() {
            return (
                this.store.get('current_user.is_superadmin') &&
                this.store.get('current_user.email').endsWith('@synergitech.co.uk')
            );
        },
    },
    methods: {
        sudoUser(user) {
            swal.fire({
                title: 'Warning',
                html:
                    'You are about to be logged out of ' +
                    Auth.user.email +
                    ' (' +
                    Auth.user.name +
                    '), and will be logged directly into ' +
                    user.email +
                    ' (' +
                    user.name +
                    ').<br /><br />This feature is only to be used to debug advanced issues that normally would not be possible with a standard administrator account.<br /><br />By continuing you acknowledge that your use of this feature will be audited, that your intentions are lawful and within the scope of your role.',
                showCancelButton: true,
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (result.value) {
                    window.location.href = route('admin.sudo', {
                        userToSudo: user.id,
                    });
                }
            });
        },
    },
};
</script>
