<template>
    <div>
        <Banner class="tw-bg-white tw-mb-4">
            <div class="tw-flex tw-align-center">
                <i class="mdi mdi-file-document-plus-outline h1 m-0"></i>
                <div class="tw-flex-grow px-5">
                    <h4>Attachable Documents</h4>
                    Here you can upload and categorise various documents that can be attached to purchase orders and
                    invoices sent from Eventwise.
                </div>
            </div>
        </Banner>
        <ew-card title="Attachable Documents" :collapsible="false" paddingless>
            <div class="tw-px-3">
                <p class="tw-flex-grow">
                    The below table contains a list of all previously uploaded attachable documents.
                    <br />
                    Attachable documents marked as default will be automatically included with newly created purchase
                    orders.
                </p>
                <Button type="is-success" class="tw-flex tw-gap-2 tw-my-2" @click="openCreateModal()">
                    <i class="mdi mdi-upload"></i>
                    Upload Document
                </Button>
            </div>
            <DataTable
                :columns="[
                    { title: 'Filename', field: 'file_name', sortable: true },
                    { title: 'Description', field: 'description', sortable: true },
                    { title: 'Uploaded', field: 'created_at', sortable: true },
                    { title: '', field: 'actions', width: 330, hidden: !Auth.can('update organisation') },
                ]"
                :data="files"
            >
                <template #column(created_at)="{ row }">
                    <div class="tw-flex tw-align-center">
                        {{ timeAgo(row.created_at) }} {{ row.user ? ' by ' + row.user.name : '' }}
                        <HelpLabel
                            class="ml-2"
                            :text="localTimestamp(row.created_at)"
                            :multilined="false"
                            position="top"
                        />
                    </div>
                </template>
                <template #column(actions)="{ row }">
                    <div class="tw-flex tw-justify-end tw-gap-1">
                        <Button
                            class="tw-flex tw-gap-2"
                            :type="row.is_default ? 'is-success' : null"
                            @click="toggleDefault(row.id)"
                        >
                            <i class="mdi" :class="row.is_default ? 'mdi-check-circle' : 'mdi-circle-outline'"></i>
                            Default
                        </Button>
                        <Button class="tw-flex tw-gap-2" @click="viewFile(row)">
                            <i class="mdi mdi-download"></i>
                            Download
                        </Button>
                        <Button type="is-danger" @click="deleteFile(row.id)">
                            <i class="mdi mdi-delete"></i>
                            Delete
                        </Button>
                    </div>
                </template>
                <template #empty>
                    <Banner>There aren't any attachable documents to display here just yet - upload one above!</Banner>
                </template>
            </DataTable>
        </ew-card>
        <UploadDocument ref="uploadDocumentModal" @uploaded="getFiles()" />
    </div>
</template>
<script>
import UploadDocument from './AttachableDocuments/UploadDocument.vue';
import HelpLabel from '../../../widgets/HelpLabel.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { localTimestamp, timeAgo, toast } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        UploadDocument,
        HelpLabel,
        Button,
        Banner,
    },
    data() {
        return {
            isLoading: false,
            files: [],
        };
    },
    mounted() {
        this.getFiles();
    },
    methods: {
        localTimestamp,
        timeAgo,
        getFiles() {
            this.isLoading = true;
            axios
                .get(route('api.account.files'))
                .then(({ data }) => (this.files = data))
                .finally(() => (this.isLoading = false));
        },
        openCreateModal() {
            this.$refs.uploadDocumentModal.open();
        },
        async deleteFile(fileId) {
            const { value: confirmation } = await swal.fire({
                title: 'Warning',
                text: 'Are you sure you wish to delete this document?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                confirmButtonColor: '#28a745',
            });

            if (confirmation) {
                axios
                    .post(route('api.account.tc.delete'), {
                        fileId: fileId,
                    })
                    .then(() => {
                        toast('File Deleted', 'Your attachable document has been deleted successfully', 'success');
                        this.getFiles();
                    });
            }
        },
        viewFile(file) {
            window.open(file.download_url, '_blank');
        },
        toggleDefault(fileId) {
            axios
                .post(
                    route('api.account.files.toggleDefault', {
                        fileUpload: fileId,
                    })
                )
                .then(() => this.getFiles());
        },
    },
};
</script>
