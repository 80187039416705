<template>
    <page v-if="Auth.can('view purchase orders')" :title="pageTitle">
        <template #submenu>
            <a href="#" :class="{ active: activePage === 'summary' }" @click.prevent="setPage('summary')">
                <i class="mdi mdi-receipt-text mr-1"></i>
                Purchase Orders
            </a>
            <a href="#" :class="{ active: activePage === 'payruns' }" @click.prevent="setPage('payruns')">
                <i class="mdi mdi-cash-multiple mr-1"></i>
                Payruns
            </a>
            <a href="#" :class="{ active: activePage === 'analysis' }" @click.prevent="setPage('analysis')">
                <i class="mdi mdi-chart-line mr-1"></i>
                Analysis
            </a>
            <button class="tw-ml-auto" @click="$refs.columnCustomisationModal.openModal()">Customise columns</button>
        </template>
        <div class="mt-3">
            <div v-if="activePage === 'summary'">
                <ew-card title="Cost Budget Overview" :expanded="true" :max-height="400">
                    <chart-departments-overview
                        :height="260"
                        default-view="costs"
                        :department="activeDepartment ? { data: activeDepartment } : null"
                    />
                </ew-card>
                <widget-statistics
                    v-if="!activeDepartment"
                    v-slot="props"
                    class="mb-3"
                    :endpoint="route('api.account.event.purchasing.summaryTotals')"
                >
                    <widget-statistic
                        :id="1"
                        title="Awaiting Approval"
                        subtext="Purchase orders awaiting approval"
                        icon="check-circle-outline"
                        :loading="props.loading"
                        :model-value="props.data.awaitingApproval"
                        :selectable="true"
                        :currency="true"
                        @update:model-value="setAnalysisPage"
                    ></widget-statistic>
                    <widget-statistic
                        :id="2"
                        title="Awaiting Invoices"
                        subtext="Purchase orders awaiting or not fully invoiced"
                        icon="receipt-text-plus-outline"
                        :loading="props.loading"
                        :model-value="props.data.awaitingInvoices"
                        :selectable="true"
                        :currency="true"
                        @update:model-value="setAnalysisPage"
                    ></widget-statistic>
                    <template v-if="Auth.can('manage supplier invoice payments')">
                        <widget-statistic
                            :id="3"
                            title="Outstanding Payments"
                            subtext="Purchase orders awaiting or not fully paid"
                            icon="credit-card-clock-outline"
                            :loading="props.loading"
                            :model-value="props.data.outstandingPayments"
                            :selectable="true"
                            :currency="true"
                            @update:model-value="setAnalysisPage"
                        ></widget-statistic>
                        <widget-statistic
                            :id="4"
                            title="Next Payment Run"
                            subtext="Purchase orders with payments on the next payrun"
                            icon="table-clock"
                            :loading="props.loading"
                            :model-value="props.data.nextPaymentRun ? props.data.nextPaymentRun : 'None'"
                            :selectable="!!props.data.nextPaymentRun"
                            :currency="props.data.nextPaymentRun ? true : false"
                            @update:model-value="setAnalysisPage"
                        ></widget-statistic>
                    </template>
                </widget-statistics>
                <ObjectList
                    v-if="!activeDepartment"
                    :columns="$refs.columnCustomisationModal?.columnsSettings"
                    type="costDepartment"
                    :data="Object.values(store.get('current_event.cost_departments'))"
                    selectable
                    @selected="selectDepartment"
                />

                <ObjectList
                    v-else
                    type="category"
                    :columns="$refs.columnCustomisationModal?.columnsSettings"
                    :data="
                        Object.values(store.get('current_event.categories')).filter(
                            (category) => category.department_id === activeDepartment.id
                        )
                    "
                />
            </div>
            <PagePayruns v-else-if="activePage === 'payruns'" :payrun-id="payrun" @payrun-selected="payrunSelected" />
            <PageAnalysis v-else-if="activePage === 'analysis'" ref="analysisPage" />
            <ObjectListColumnCustomisationModal ref="columnCustomisationModal" type="purchase" />
        </div>
    </page>
</template>
<script>
import Page from '../Page.vue';
import ChartDepartmentsOverview from '../../../assets/js/eventbooks/components/charts/departments/departments-overview.vue';
import WidgetStatistics from '../../../js/components/widgets/statistics/StatisticsRow.vue';
import WidgetStatistic from '../../../js/components/widgets/statistics/StatisticsItem.vue';
import ObjectList from '../widgets/ObjectList.vue';
import PagePayruns from './Purchasing/Payruns.vue';
import PageAnalysis from './Purchasing/Analysis.vue';
import ObjectListColumnCustomisationModal from '../modals/ObjectListColumnCustomisationModal.vue';
import { useSettingsStore } from '@/js/stores/SettingsStore';
import { useDataStore } from '@/js/stores/DataStore.js';
import { setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        Page,
        ChartDepartmentsOverview,
        WidgetStatistics,
        WidgetStatistic,
        ObjectList,
        PagePayruns,
        PageAnalysis,
        ObjectListColumnCustomisationModal,
    },
    props: {
        payrun: {
            type: Number,
            required: false,
        },
    },
    data() {
        const store = useDataStore();
        return {
            activePage: 'summary',
            activeDepartment: null,
            store: store,
            activePayrun: null,
            settings: useSettingsStore(),
        };
    },
    computed: {
        pageTitle() {
            switch (this.activePage) {
                case 'summary':
                    return this.activeDepartment ? this.activeDepartment.name : 'Purchasing Summary';
                case 'payruns':
                    return 'Payment Runs';
                case 'analysis':
                    return 'Purchasing Analysis';
                default:
                    return 'Purchasing';
            }
        },
    },
    mounted() {
        this.handlePageChange();
        Eventbus.$on('pageChanged', () => this.handlePageChange());
        this.activePayrun = this.payrun ?? null;
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        handlePageChange() {
            if (route().params.page === 'summary' && route().params.departmentId) {
                this.activeDepartment = this.store.get('current_event.cost_departments.' + route().params.departmentId);
            } else if (route().params.page === 'payruns' && route().params.departmentId) {
                this.activePage = 'payruns';
                this.activePayrun = route().params.departmentId;
            } else {
                this.activeDepartment = null;
                this.activePage = route().params.page ?? 'summary';
            }
        },
        setPage(page = 'summary') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setRoute('account.event.purchaseorders.summary', {
                page: page,
            });
        },
        selectDepartment(payload) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setRoute('account.event.purchaseorders.summary', {
                page: 'summary',
                departmentId: payload.data.id,
            });
        },
        setAnalysisPage(id) {
            this.setPage('analysis');
            setTimeout(() => {
                this.$refs.analysisPage.selected = id;
            }, 500);
        },
        payrunSelected(payrunId) {
            setRoute('account.event.purchaseorders.summary', {
                event: this.store.get('current_event.id'),
                page: 'payruns',
                departmentId: payrunId,
            });
        },
    },
};
</script>
