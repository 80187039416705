<template>
    <div></div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';

export default {
    data() {
        const store = useDataStore();
        return {
            store: store,
        };
    },
    computed: {
        reconnectionRequired() {
            return (
                this.store.get('account.xero_organisation.reconnection_required') &&
                !route().current('account.xero.*') &&
                Auth.can('manage integrations')
            );
        },
    },
    watch: {
        reconnectionRequired: function (val) {
            if (val) {
                this.reconnectXero();
            }
        },
    },
    methods: {
        async reconnectXero() {
            const { value } = await swal.fire({
                title: 'Reconnect Xero',
                text: 'Please reconnect your Xero account.',
                confirmButtonText: 'Reconnect',
            });
            if (value) {
                window.location = route('account.xero.reconnect');
            }
        },
    },
};
</script>
