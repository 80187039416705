<template>
    <div class="buefy-wrapper">
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <DataTable
            :endpoint="tableEndpoint"
            :columns="[
                { title: 'ID', field: 'id', width: 80 },
                { title: 'Name', field: 'name' },
                { title: 'Permissions', field: 'permissions_count', width: 120 },
                { title: 'Users', field: 'users_count', width: 120 },
            ]"
            class="align-middle"
        >
            <template #expanded-row="{ row }">
                <DataTable
                    :columns="[
                        { title: 'Group', field: 'group', width: 175 },
                        { title: 'Permission', field: 'name' },
                        { title: 'Description', field: 'description' },
                    ]"
                    :data="row.permissions"
                >
                    <template #column(group)="{ row }">
                        {{ row.group }}
                    </template>
                    <template #column(name)="{ row }">
                        {{ row.name }}
                    </template>
                    <template #column(description)="{ row }">
                        <span v-if="!row.description" class="text-muted">None</span>
                        <span v-else>{{ row.description }}</span>
                    </template>
                </DataTable>
            </template>
            <template #empty>
                <div class="empty-label">There are no usergroups to show</div>
            </template>
        </DataTable>
    </div>
</template>
<script>
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';

export default {
    components: {
        DataTable,
        LoadingSpinner,
    },
    props: {
        account: {
            type: [String, Number],
        },
    },
    data() {
        return {
            isLoading: false,
            accountId: null,
            perPage: 15,
            data: null,
            tableEndpoint: '',
        };
    },
    watch: {
        account(newVal) {
            this.accountId = newVal;
        },
    },
    mounted() {
        this.accountId = this.account;
        this.tableEndpoint = route('api.admin.account.usergroups', { account: this.accountId });
    },
};
</script>
