<template>
    <div
        v-if="
            !isDismissed &&
            store.get('current_event') &&
            !settings.event(store.get('current_event.id'), 'dismissInitialTicketScreen')
        "
        class="welcome-container buefy-wrapper"
    >
        <div class="backdrop"></div>
        <div class="welcome-modal">
            <LoadingSpinner v-if="isLoading" label="Storing Initial Tickets" />
            <h3>🎟️ Let&apos;s setup your tickets for {{ store.get('current_event.name') }}</h3>
            <p>
                Give your first ticket a name to get started. Once you have done this you can then set a price and
                allocation.
                <br />
                Your tickets are setup for Direct Sales by default but you can click the
                <i class="mdi mdi-plus-circle-outline"></i>
                icon to add additional sales channels.
            </p>
            <TicketTypeBuilder v-model="ticketData" />
            <div class="d-flex w-100 px-4 mb-4">
                <Button
                    type="is-link"
                    class="!tw-mr-auto"
                    @click="
                        isDismissed = true;
                        settings.setEvent(store.get('current_event.id'), 'dismissInitialTicketScreen', true);
                    "
                >
                    I'll do this later
                </Button>
                <FormField v-if="ticketData" class="tw-mb-0 tw-mr-5 tw-text-right">
                    <template #label>
                        <Tooltip
                            label="Your new tickets will be created with this tax rate, but you can configure them individually after if you wish"
                        >
                            Default Tax Rate
                            <i class="mdi mdi-help-circle"></i>
                        </Tooltip>
                    </template>
                    <TaxRateSelector v-model="ticketData.tax_rate_id" type="income" />
                </FormField>
                <Button type="is-primary" @click="createInitialTickets">Create Tickets</Button>
            </div>
            <Banner
                v-if="
                    !Object.values(store.get('current_event.revenue_streams')).filter((r) => r.stream_type === 0).length
                "
                type="is-warning"
                class="w-100"
            >
                You do not have a revenue stream set up for tickets, one will be created for you when creating your
                tickets.
            </Banner>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import TicketTypeBuilder from '../../widgets/TicketTypeBuilder.vue';
import TaxRateSelector from '../../controls/TaxRateSelector.vue';
import LoadingSpinner from '../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        TicketTypeBuilder,
        TaxRateSelector,
        LoadingSpinner,
        Button,
        FormField,
        Banner,
        Tooltip,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            settings: useSettingsStore(),
            isDismissed: false,
            ticketData: null,
        };
    },
    methods: {
        createInitialTickets() {
            this.isLoading = true;
            axios
                .post(route('api.account.event.tickets.initial'), this.ticketData)
                .then(() => {
                    toast('Tickets Created', 'Your tickets have been set up successfully', 'success');
                    this.isDismissed = true;
                    swal.fire({
                        title: 'Tickets Created',
                        type: 'success',
                        html: 'Your tickets have been setup successfully! Would you like to go to the bulk ticket editor to make further changes?',
                        showCancelButton: true,
                        confirmButtonColor: '#4EA5D9',
                        cancelButtonColor: '#6e6e6e',
                        confirmButtonText: 'Take me there',
                        cancelButtonText: 'No thanks',
                    }).then((result) => {
                        if (!result.value) {
                            return;
                        }
                        window.location.href = route('account.event.tickets', {
                            page: 'bulk',
                        });
                    });
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss" scoped>
.welcome-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    & > .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black, 0.33);
        z-index: -1;
    }

    & > .welcome-modal {
        width: min(1400px, 90%);
        height: auto;
        background: white;
        padding: 40px 0 0 0;
        border-radius: 10px;
        text-align: center;
        box-shadow: 0 0 10px rgba(black, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > p {
            margin: 10px;
        }
        & > .ticket-type-builder {
            width: 100%;
            text-align: left;
            margin: 20px 0;
        }
    }
}
</style>
