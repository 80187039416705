<template>
    <div v-if="!viewingSupplier" class="buefy-wrapper h-100">
        <div class="tw-my-3 tw-flex tw-gap-3 tw-items-center">
            <Textbox v-model="searchableString" placeholder="Search for a contact" />
            <Checkbox id="show-archived" v-model="showArchived">Show archived contacts</Checkbox>
            <Button v-if="is_connected_to_xero" class="tw-ml-auto tw-flex tw-gap-2" @click="importFromXero()">
                <i class="mdi mdi-application-import"></i>
                Import from Xero
            </Button>
            <Button
                :class="[is_connected_to_xero ? '!tw-ml-2' : '!tw-ml-auto', 'tw-flex tw-gap-2']"
                type="is-primary"
                @click="newSupplier()"
            >
                <i class="mdi mdi-plus"></i>
                New Contact
            </Button>
        </div>

        <DataTable
            ref="table"
            :endpoint="
                route('api.account.contacts.paginated', {
                    archived: showArchived,
                })
            "
            :columns="[
                { title: 'Type', field: 'supplier_type', sortable: true },
                { title: 'Contact', field: 'name', sortable: true },
                { title: 'Postal Address', field: 'address1' },
                { title: 'Email', field: 'email_address', sortable: true },
                { title: 'Phone', field: 'phone_number', sortable: true },
                { title: 'Xero', field: 'xero_contact_id' },
                { title: 'Created', field: 'created_at', sortable: true },
                { title: '', field: 'actions' },
            ]"
            searchable
            :searchable-string
            :sidebar="false"
        >
            <template #column(supplier_type)="{ row }">
                <Tag v-if="row.supplier_type === 1" type="info">
                    {{ row.is_archived ? 'Archived ' : '' }}Ticket Seller
                </Tag>
                <Tag v-else-if="row.supplier_type === 2" type="info">
                    {{ row.is_archived ? 'Archived ' : '' }}Trader
                </Tag>
                <Tag v-else type="info">{{ row.is_archived ? 'Archived ' : '' }}Contact</Tag>
            </template>
            <template #column(address1)="{ row }">
                <p v-if="compoundAddress(row)" v-html="compoundAddress(row)"></p>
                <p v-else class="text-muted">
                    <i>Not set</i>
                </p>
            </template>
            <template #column(email_address)="{ row }">
                <p v-if="row.email_address">
                    {{ row.email_address }}
                </p>
                <p v-else class="text-muted">
                    <i>Not set</i>
                </p>
            </template>
            <template #column(phone_number)="{ row }">
                <p v-if="row.phone_number">
                    {{ row.phone_number }}
                </p>
                <p v-else class="text-muted">
                    <i>Not set</i>
                </p>
            </template>
            <template #column(xero_contact_id)="{ row }">
                <Tooltip v-if="row.xero_contact_id" label="Linked with Xero">
                    <img src="@/images/brands/xero_blue.png" class="icon-sm" />
                </Tooltip>
                <Tooltip v-else class="text-muted" label="Not linked with Xero">
                    <img src="@/images/brands/xero_blue.png" class="icon-sm grayscale" />
                </Tooltip>
            </template>
            <template #column(created_at)="{ row }">
                <p class="text-muted">
                    {{ timeAgo(row.created_at) }}
                </p>
            </template>

            <template #column(actions)="{ row }">
                <Tooltip v-if="row.invite_pending === 1" label="Invitation sent" position="left">
                    <Button v-if="Auth.can('manage suppliers') && !row.is_system">
                        <i class="mdi mdi-email-outline"></i>
                    </Button>
                </Tooltip>
                <Tooltip
                    v-else-if="row.invite_pending === 0 && row.supplier_user"
                    label="Invitation accepted"
                    position="left"
                >
                    <Button v-if="Auth.can('manage suppliers') && !row.is_system">
                        <i class="mdi mdi-email"></i>
                    </Button>
                </Tooltip>

                <Tooltip label="Contact Details" position="left">
                    <Button v-if="Auth.can('manage suppliers')" @click="supplierDetails(row.id)">
                        <i class="mdi mdi-receipt-text-outline"></i>
                    </Button>
                </Tooltip>
                <Button v-if="Auth.can('manage suppliers') && !row.is_system" @click="viewSupplier(row.id)">
                    <i class="mdi mdi-pencil"></i>
                </Button>
            </template>
            <template #empty>
                <p class="empty-label py-3">There are no suppliers to show here just yet</p>
            </template>
        </DataTable>
        <ImportXeroContacts v-if="is_connected_to_xero" ref="importContactsModal" />
    </div>
    <SupplierDetails
        v-else
        :supplier-id="viewingSupplier"
        :account-id="accountId"
        :internal="true"
        @update:model-value="viewingSupplier = null"
    />
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import SupplierDetails from './SupplierDetails.vue';
import ImportXeroContacts from './ImportXeroContacts.vue';
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { timeAgo, setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        SupplierDetails,
        ImportXeroContacts,
        Button,
        Checkbox,
        Tooltip,
        Tag,
        Textbox,
        DataTable,
    },
    props: {
        routeName: {
            type: String,
            required: false,
        },
        routeParam: {
            type: String,
            required: false,
        },
    },
    data() {
        const store = useDataStore();
        return {
            store: store,
            showArchived: false,
            is_connected_to_xero: store.get('account.is_connected_to_xero'),
            viewingSupplier: null,
            accountId: store.get('account.id'),
            searchableString: '',
        };
    },
    watch: {
        viewingSupplier(newVal) {
            if (!this.routeParam || !this.routeName) {
                return;
            }
            const params = {};
            params[this.routeParam] = newVal ? newVal : '';
            setRoute(this.routeName, params);
        },
    },
    mounted() {
        Eventbus.$on('reload:suppliers-table', () => {
            this.$refs.table.refreshData();
        });
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        timeAgo,
        handlePageChange() {
            if (!this.routeParam || !this.routeName) {
                return;
            }
            this.viewingSupplier = route().params[this.routeParam] ? parseInt(route().params[this.routeParam]) : null;
        },
        compoundAddress(supplier) {
            const fields = ['address1', 'address2', 'street', 'city', 'county', 'country', 'postal_code'];
            let builder = '';
            fields.forEach((field) => {
                if (supplier[field]) {
                    builder += supplier[field] + '<br />';
                }
            });
            return builder;
        },
        viewSupplier(id) {
            Eventbus.$emit('view:supplier', id);
        },
        newSupplier() {
            Eventbus.$emit('view:supplier');
        },
        importFromXero() {
            this.$refs.importContactsModal.open();
        },
        supplierDetails(id) {
            // Email the registered email address with the invite to the portal
            this.viewingSupplier = id;
        },
    },
};
</script>
