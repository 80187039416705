<template>
    <div class="tw-fixed tw-z-[calc(infinity)] tw-bottom-0 tw-left-2">
        <transition-group name="toastlist" tag="div">
            <div
                v-for="toast in toasts"
                :key="toast.pushed_at"
                class="tw-text-white tw-ml-2 tw-max-w-72 tw-px-1 tw-pt-1.5 tw-pb-1 tw-mb-3 tw-rounded-[5px] tw-text-sm tw-drop-shadow-[0_3px_5px_rgba(0,0,0,0.20)]"
                :class="{
                    'tw-bg-green-500': toast.type === 'success',
                    'tw-bg-red-500': toast.type === 'error',
                    'tw-bg-yellow-500': toast.type === 'warning',
                    'tw-bg-blue-500': toast.type === 'info',
                }"
            >
                <div class="tw-flex tw-min-w-56 tw-px-1">
                    <small>
                        {{ toast.title }}
                    </small>
                    <button
                        class="tw-opacity-70 hover:tw-opacity-90 tw-ml-auto tw-pl-3"
                        title="Close"
                        @click="removeToast(toast)"
                    >
                        <svg class="tw-size-4 tw-fill-current">
                            <path
                                d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"
                            />
                        </svg>
                    </button>
                </div>
                <p
                    class="tw-bg-white tw-w-full tw-h-full tw-text-black tw-mt-1 tw-mb-0 tw-px-2 tw-py-1 tw-rounded-[4px]"
                >
                    {{ toast.content }}
                </p>
            </div>
        </transition-group>
    </div>
</template>

<script>
export default {
    data() {
        return {
            toasts: [],
            timeout: null,
            interval: null,
        };
    },
    mounted() {
        addEventListener('toast', (e) => {
            e.detail.pushed_at = Date.now();
            this.toasts.push(e.detail);
        });
        this.interval = setInterval(this.processToasts, 1000);
    },
    methods: {
        processToasts() {
            this.toasts = this.toasts.filter((t) => Date.now() - t.pushed_at < 10000);
        },
        removeToast(toast) {
            this.toasts = this.toasts.filter((t) => t.pushed_at !== toast.pushed_at);
        },
    },
};
</script>
<style lang="scss" scoped>
.toastlist-enter-active,
.toastlist-leave-active {
    transition: all 0.3s;
}
.toastlist-enter,
.toastlist-leave-to {
    opacity: 0;
    transform: translateX(-100%);
}
</style>
