<template>
    <Modal ref="modal" title="Create Ticket" :loading="isLoading" size="is-xlarge" @closing="reset()">
        <template v-if="revenueStreams.length">
            <div class="tw-flex tw-mb-3">
                <FormField class="tw-flex-1 tw-mb-0" label="Revenue Stream">
                    <Selector v-model="selectedRevenueStream" :options="revenueStreams" />
                </FormField>
                <FormField v-if="selectedRevenueStream" class="tw-flex-1 tw-pl-2" label="Subcategory">
                    <Selector v-model="selectedCategory" :options="categories" />
                </FormField>
            </div>
            <div v-if="selectedCategory" class="tw-py-3 tw-px-4 border rounded bg-light">
                <div class="tw-flex tw-mb-2">
                    <FormField class="tw-flex-1" label="Name">
                        <Textbox v-model="ticketName" placeholder="Ticket Name" required />
                    </FormField>
                    <FormField class="tw-ml-3" label="Ticket Seller">
                        <Select v-model="ticketSupplier">
                            <option :value="null">Direct Sales</option>
                            <option v-for="seller in ticketSellers" :key="seller.id" :value="seller.id">
                                {{ seller.name }}
                            </option>
                        </Select>
                    </FormField>
                </div>
                <div class="row mb-2">
                    <div class="col-6 tw-flex tw-flex-col">
                        <FormField label="Ticket Price" class="tw-mb-2">
                            <Currency v-model="ticketPrice"></Currency>
                        </FormField>
                        <FormField label="Rebate" class="tw-mb-2">
                            <PercentageValue
                                v-model="kickbackCost"
                                :base-value="ticketPrice"
                                :currency="true"
                                :step="0.0001"
                                @update:model-value="(value) => (kickbackCost = value)"
                            />
                        </FormField>
                        <FormField label="Tickets Available" class="tw-mb-2">
                            <NumberInput
                                v-model="ticketsAvailable"
                                placeholder="0"
                                min="0"
                                class="tw-w-full"
                                required
                            />
                        </FormField>
                        <FormField label="Tax Rate" class="tw-mb-2">
                            <TaxRateSelector v-model="taxRateId" type="income" />
                        </FormField>
                        <p class="tw-mt-auto">Fixed Gross Sales: {{ localise(fixedGrossSales, 2, true) }}</p>
                        <div class="tw-mt-auto">Fixed Net Sales: {{ localise(fixedNetSales, 2, true) }}</div>
                    </div>
                    <div class="col-6">
                        <FormField label="Payment Structure">
                            <div class="border rounded bg-white p-3">
                                <Button
                                    v-model="paymentStructure"
                                    class="tw-mb-4"
                                    :active="paymentStructure === 'upfront'"
                                    @click="paymentStructure = 'upfront'"
                                >
                                    <div class="tw-flex tw-items-center tw-mb-1">
                                        <i class="mdi mdi-hand-coin tw-text-xl tw-mr-1"></i>
                                        <span>Payment In Full</span>
                                    </div>
                                    <small class="tw-block">
                                        Customers are expected to pay the entire face value of the ticket
                                    </small>
                                </Button>
                                <Button
                                    v-model="paymentStructure"
                                    :active="paymentStructure === 'payment_plan'"
                                    @click="paymentStructure = 'payment_plan'"
                                >
                                    <div class="tw-flex tw-items-center tw-mb-1">
                                        <i class="mdi mdi-calendar tw-text-xl tw-mr-1"></i>
                                        <span>Payment Plan</span>
                                    </div>
                                    <small class="tw-block">
                                        Customers may spread the cost of the ticket across a fixed period of time
                                    </small>
                                </Button>
                            </div>
                        </FormField>
                    </div>
                </div>
            </div>
            <Banner v-else type="is-info">
                This new ticket will automatically be setup for direct sales.
                <br />
                You can add any additional ticket sellers after creating the ticket.
            </Banner>
        </template>
        <template v-else>
            <Banner>
                You haven&apos;t setup any ticket revenue streams just yet!
                <br />
                You can
                <a href="#" @click.prevent="createRevenueStream()">click here</a>
                to create one.
            </Banner>
        </template>
        <template #footer>
            <Button type="is-success" :disabled="!isValid" @click="createTicket()">Create Ticket</Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import Selector from '../../controls/Selector.vue';
import Currency from '../../controls/Currency.vue';
import TaxRateSelector from '../../controls/TaxRateSelector.vue';
import PercentageValue from '../../widgets/PercentageValue.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        TaxRateSelector,
        Modal,
        Selector,
        Currency,
        PercentageValue,
        Button,
        FormField,
        Textbox,
        Select,
        NumberInput,
        Banner,
    },
    emits: ['created'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            revenueStreams: [],
            ticketSellers: [],
            selectedRevenueStream: null,
            categories: [],
            selectedCategory: null,
            paymentStructure: 'upfront',
            ticketName: '',
            ticketPrice: 0,
            ticketSupplier: null,
            ticketsAvailable: 0,
            taxRates: store.get('account.tax_rates'),
            taxRateId: null,
            kickbackCost: 0,
        };
    },
    computed: {
        fixedGrossSales() {
            return (
                (this.ticketsAvailable ? parseInt(this.ticketsAvailable) : 0) *
                (this.ticketPrice ? parseFloat(this.ticketPrice) : 0)
            );
        },
        fixedNetSales() {
            const taxRate = !this.taxRateId
                ? 0
                : parseFloat(Object.values(this.taxRates).filter((rate) => rate.id === this.taxRateId)[0].rate) / 100;
            return this.ticketsAvailable * (this.ticketPrice / (1 + taxRate));
        },

        isValid() {
            return (
                this.selectedRevenueStream &&
                this.selectedCategory &&
                this.ticketName &&
                !isNaN(this.ticketPrice) &&
                !isNaN(this.ticketsAvailable)
            );
        },
    },
    watch: {
        selectedRevenueStream(newVal) {
            this.selectedCategory = null;
            const revenueStream = this.revenueStreams.find((revenueStream) => revenueStream.id === newVal);
            if (!revenueStream) {
                this.categories = [];
                this.taxRateId = null;
                return;
            }
            this.categories = Object.values(this.store.get('current_event.categories')).filter(
                (c) => c.department_id === revenueStream.department_id && !c.is_pooled
            );
            this.taxRateId =
                revenueStream.default_tax_rate_id ?? this.store.get('account.default_revenue_tax_rate_id') ?? null;
        },
    },
    mounted() {
        Eventbus.$on('create:ticketType', (payload) => this.open(payload));
        this.ticketSellers = Object.values(this.store.get('account.suppliers')).filter((s) => s.supplier_type === 1);
    },
    methods: {
        async reloadData() {
            this.revenueStreams = Object.values(this.store.get('current_event.revenue_streams')).filter(
                (revenueStream) => revenueStream.stream_type === 0
            );
        },
        open(payload) {
            if (payload) {
                this.selectedRevenueStream = payload.revenueStream ? payload.revenueStream : null;
                setTimeout(() => {
                    this.selectedCategory = payload.category ? payload.category : null;
                }, 50);
            }
            this.reloadData();
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.revenueStreams = [];
            this.selectedRevenueStream = null;
            this.paymentStructure = 'upfront';
            this.ticketName = '';
            this.ticketPrice = 0;
            this.ticketSupplier = null;
            this.ticketsAvailable = 0;
            this.taxRateId = null;
            this.kickbackCost = 0;
        },
        createRevenueStream() {
            this.close();
            Eventbus.$emit('create:department', {
                type: 'revenue',
            });
        },
        createTicket() {
            const existingTickets = Object.values(this.store.get('current_event.ticket_types')).filter(
                (ticketType) => ticketType.name === this.ticketName
            );
            if (existingTickets.length) {
                swal.fire(
                    'Error',
                    'There is already a ticket named ' + this.ticketName + ', please choose a different name.',
                    'error'
                );
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.event.tickets.addNewType'), {
                    name: this.ticketName,
                    supplier_id: this.ticketSupplier,
                    revenue_stream_id: this.selectedRevenueStream,
                    category_id: this.selectedCategory,
                    ticket_price: this.ticketPrice,
                    tickets_available: this.ticketsAvailable,
                    kickback_cost: this.kickbackCost,
                    payment_structure: this.paymentStructure,
                    tax_rate_id: this.taxRateId,
                    sortorder: Object.values(this.store.get('current_event.ticket_types')).length + 1,
                })
                .then(({ data }) => {
                    this.store.set('current_event.ticket_types.' + data.id, data);
                    this.$emit('created');
                    Eventbus.$emit('reload:stream', {
                        stream: this.selectedRevenueStream,
                    });
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
