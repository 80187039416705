<template>
    <div class="tw-inline-flex tw-items-center">
        <div v-if="labelLeft" class="tw-text-xs tw-mr-2 tw-cursor-pointer" @click="model = !model" v-html="label" />
        <div class="form-switch">
            <input :id="name" v-model="model" type="checkbox" :disabled="disabled" :name="name" />
            <label class="tw-bg-slate-400 tw-text-xs" :for="name">
                <span class="tw-bg-white shadow-sm" aria-hidden="true" />
                <span class="tw-sr-only" v-html="label" />
            </label>
        </div>
        <div v-if="!labelLeft" class="tw-text-xs tw-ml-2 tw-cursor-pointer" @click="model = !model" v-html="label" />
    </div>
</template>

<script setup>
defineProps({
    name: String,
    label: String,
    autocomplete: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    labelLeft: {
        type: Boolean,
        default: false,
    },
});

const model = defineModel({
    type: Boolean,
});
</script>
<style lang="scss" scoped>
/* Switch element */
.form-switch {
    @apply tw-relative tw-select-none;
    width: 32px;
}

.form-switch label {
    @apply tw-block tw-overflow-hidden tw-cursor-pointer tw-h-5 tw-rounded-full tw-mb-0;
}

.form-switch input {
    @apply tw-hidden;
}

.form-switch label > span:first-child {
    @apply tw-absolute tw-block tw-rounded-full;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all 0.15s ease-out;
}

.form-switch input[type='checkbox']:checked + label {
    @apply tw-bg-eventwise;
}

.form-switch input[type='checkbox']:checked + label > span:first-child {
    left: 14px;
}

.form-switch input[type='checkbox']:disabled + label {
    @apply tw-cursor-not-allowed tw-bg-slate-100 dark:tw-bg-slate-700/20 tw-border tw-border-slate-200 dark:tw-border-slate-700;
}

.form-switch input[type='checkbox']:disabled + label > span:first-child {
    @apply tw-bg-slate-400 dark:tw-bg-slate-600;
}
</style>
