<template>
    <StylisedModal ref="modal" title="Raise Supplier Invoice" @closed="reset()">
        <div class="tw-px-3">
            <FormField label="Title">
                <EditableText v-model="invoiceTitle" placeholder="Required" :max-length="255" />
            </FormField>
            <div class="tw-grid tw-gap-3 tw-grid-cols-3">
                <FormField label="Invoice Number">
                    <Textbox v-model="invoiceNumber" maxlength="32" />
                </FormField>
                <FormField label="Invoice Date">
                    <flat-pickr
                        v-model="invoiceDate"
                        placeholder="Required"
                        :config="flatpickrConfig"
                        class="input is-small"
                        required
                    />
                </FormField>
                <FormField label="Due Date">
                    <flat-pickr
                        v-model="invoiceDueDate"
                        placeholder="Required"
                        :config="flatpickrConfig"
                        class="input is-small"
                        required
                    />
                </FormField>
            </div>
            <hr class="!tw-mt-0" />
            <div class="view-invoice-content tw-grid tw-gap-3">
                <table class="invoice-line-items-table">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th class="text-right">Unit Price</th>
                            <th class="text-right">Qty</th>
                            <th class="text-center">Tax Rate</th>
                            <th class="text-right">Invoice Amount (Gross)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(lineItem, index) in invoiceLineItems" :key="index">
                            <td>
                                {{ lineItem.description }}
                            </td>
                            <td class="text-right">
                                {{ localise(lineItem.unit_price, 2, true) }}
                            </td>
                            <td class="text-right">
                                {{ lineItem.quantity }}
                            </td>
                            <td class="text-right">
                                <TaxRateSelector v-model="lineItem.tax_rate_id" type="expense" />
                            </td>
                            <td class="text-right">
                                <EditableText v-model="lineItem.value" type="currency" :min="0" />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="purchaseOrder" class="row">
                    <div class="col-4">
                        <FormFIeld label="Source Currency">
                            {{ availableCurrencies[purchaseOrder.source_currency] }}
                        </FormFIeld>
                    </div>
                    <div
                        :class="{
                            'col-4': invoiceTargetCurrency && invoiceTargetCurrency != purchaseOrder.source_currency,
                            'col-8': !invoiceTargetCurrency || invoiceTargetCurrency == purchaseOrder.source_currency,
                        }"
                    >
                        <FormField label="Target Currency" custom-class="mb-1">
                            <Select v-model="invoiceTargetCurrency">
                                <option
                                    v-for="(currency, currencyCode) in availableCurrencies"
                                    :key="currencyCode"
                                    :value="currencyCode"
                                >
                                    {{ currency }}
                                </option>
                            </Select>
                        </FormField>
                    </div>
                    <div
                        v-if="invoiceTargetCurrency && invoiceTargetCurrency != purchaseOrder.source_currency"
                        class="col-4"
                    >
                        <FormField v-if="invoiceTargetCurrency" label="Conversion Rate">
                            <NumberInput v-model="invoiceConversionRate" min="0" />
                            <Button @click="openCurrencyConverter()"><i class="mdi mdi-calculator"></i></Button>
                        </FormField>
                    </div>
                </div>

                <FormField>
                    <Upload v-model="invoiceFiles" multiple label="Upload files" />
                    <div v-if="invoiceFiles.length" class="tags">
                        <span v-for="(file, index) in invoiceFiles" :key="index" class="tag is-primary">
                            {{ file.name }}
                            <button
                                class="delete is-small"
                                type="button"
                                @click.prevent="removeAttachment(index)"
                            ></button>
                        </span>
                    </div>
                </FormField>

                <Banner type="is-info">
                    Your invoice will be sent to {{ currentAccount.name }} for approval.
                    <br />
                    You will then be notified by email of any changes to the status.
                </Banner>

                <Checkbox id="terms-confirmed" v-model="invoiceTermsConfirmed">
                    I confirm that I have read the original purchase order and fully understand any terms associated
                    with it
                </Checkbox>

                <Button
                    type="is-success"
                    class="tw-w-full"
                    :disabled="!isValid"
                    :loading="isLoading"
                    @click="submitInvoice()"
                >
                    Submit invoice for approval
                </Button>
            </div>
        </div>
    </StylisedModal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import StylisedModal from '../../../../StylisedModal.vue';
import EditableText from '../../../../controls/EditableText.vue';
import TaxRateSelector from '../../../../controls/TaxRateSelector.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Upload from '@/js/components/controls/Upload.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        StylisedModal,
        EditableText,
        TaxRateSelector,
        Button,
        FormField,
        Checkbox,
        Textbox,
        Upload,
        Banner,
    },
    emits: ['created'],
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            availableCurrencies: store.get('available_currencies'),
            currentAccount: store.get('account'),
            purchaseOrder: null,
            invoiceTitle: null,
            invoiceNumber: null,
            invoiceDate: null,
            invoiceTargetCurrency: null,
            invoiceConversionRate: 1,
            invoiceDueDate: null,
            invoiceLineItems: [],
            invoiceFiles: [],
            invoiceTermsConfirmed: false,
        };
    },
    computed: {
        isValid() {
            if (
                !this.invoiceTitle ||
                !this.invoiceDate ||
                !this.invoiceDueDate ||
                !this.invoiceTermsConfirmed ||
                !this.invoiceNumber ||
                !this.invoiceLineItems.length
            ) {
                return false;
            }

            return !this.invoiceLineItems.filter((li) => !li.value).length;
        },
    },
    methods: {
        open(purchaseOrder) {
            this.purchaseOrder = purchaseOrder;
            this.prepopulateLineItems();
            this.$refs.modal.open();
        },
        prepopulateLineItems() {
            this.invoiceTitle = this.purchaseOrder.title;
            this.invoiceTargetCurrency = this.purchaseOrder.target_currency;
            this.invoiceConversionRate = this.purchaseOrder.conversion_rate;
            this.invoiceLineItems = this.purchaseOrder.line_items.map((li) => {
                return {
                    purchase_order_line_item_id: li.id,
                    description: li.description,
                    unit_price: li.unit_price,
                    quantity: li.quantity,
                    tax_rate_id: li.tax_rate_id,
                    value: li.total.toFixed(2),
                };
            });
        },
        close() {
            this.$refs.modal.close();
            this.reset();
        },
        reset() {
            this.purchaseOrder = null;
            this.invoiceTitle = null;
            this.invoiceDate = null;
            this.invoiceDueDate = null;
            this.invoiceTermsConfirmed = false;
            this.invoiceLineItems = [];
            this.invoiceFiles = [];
        },
        submitInvoice() {
            if (!this.invoiceFiles.length) {
                toast(
                    'Error',
                    'You must attach at least one file to the invoice in order to continue submitting',
                    'error'
                );
                return;
            }
            this.isLoading = true;
            const formData = new FormData();
            formData.append('title', this.invoiceTitle);
            formData.append('invoice_date', this.invoiceDate);
            formData.append('invoice_number', this.invoiceNumber);
            formData.append('due_date', this.invoiceDueDate);
            formData.append('target_currency', this.invoiceTargetCurrency);
            formData.append('conversion_rate', this.invoiceConversionRate);
            this.invoiceLineItems.forEach((li, index) => {
                Object.keys(li).forEach((key) => {
                    formData.append(`items[${index}][${key}]`, li[key] ? li[key] : '');
                });
            });
            if (this.invoiceFiles.length) {
                this.invoiceFiles.forEach((file) => {
                    formData.append('files[]', file);
                });
            }

            axios
                .post(
                    route('portal.purchaseOrder.raiseInvoice', {
                        supplier: this.purchaseOrder.supplier_id,
                        purchaseOrder: this.purchaseOrder.id,
                    }),
                    formData
                )
                .then(() => {
                    this.close();
                    toast(
                        'Success',
                        'Your invoice has been raised pending approval, you will be notified by email of any changes to the invoice status.',
                        'success'
                    );
                    this.$emit('created');
                })
                .finally(() => (this.isLoading = false));
        },
        openCurrencyConverter() {
            Eventbus.$emit('currencyConverter');
        },
        removeAttachment(index) {
            this.invoiceFiles.splice(index, 1);
        },
    },
};
</script>
