<template>
    <Modal ref="modal" title="Alter Currency" size="is-large">
        <div class="buefy-wrapper">
            Here you can alter the target currency and exchange rate.
            <br />
            <div v-if="sourceCurrency" class="row mt-3">
                <div class="col-5">
                    <FormField label="Source Currency">
                        {{ availableCurrencies[sourceCurrency] }}
                    </FormField>
                </div>
                <div class="col-7">
                    <FormField label="Target Currency">
                        <Select
                            v-model="targetCurrency"
                            placeholder="Please select a target currency..."
                            class="tw-w-full"
                        >
                            <option
                                v-for="(currency, currencyCode) in availableCurrencies"
                                :key="currencyCode"
                                :value="currencyCode"
                            >
                                {{ currency }}
                            </option>
                        </Select>
                    </FormField>
                </div>
            </div>
            <div
                v-if="targetCurrency && targetCurrency != sourceCurrency"
                class="mt-3 pt-3 px-3 pb-2 bg-light rounded d-flex"
            >
                <FormField label="Conversion Rate" class="tw-flex-1">
                    <NumberInput v-model="conversionRate" min="0" />
                    <Button @click="openCurrencyConverter()"><i class="mdi mdi-calculator"></i></Button>
                </FormField>
                <FormField label="Converted Amount" class="tw-px-3">
                    {{ localise(figureAmount, 2, true, targetCurrency, conversionRate) }}
                </FormField>
            </div>
        </div>
        <template #footer>
            <Button class="!tw-mr-auto" @click="close()">Cancel</Button>
            <Button type="is-success" :disabled="!targetCurrency" @click="saveChanges()">Save Changes</Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import Select from '@/js/components/controls/Select.vue';

export default {
    components: {
        Modal,
        Button,
        FormField,
        NumberInput,
        Select,
    },
    emits: ['updated'],
    data() {
        const store = useDataStore();

        return {
            availableCurrencies: store.get('available_currencies'),
            sourceCurrency: null,
            targetCurrency: null,
            conversionRate: null,
            figureAmount: null,
        };
    },
    methods: {
        open(source, target = null, rate = 1, amount = 1) {
            this.sourceCurrency = source;
            this.targetCurrency = target;
            this.conversionRate = rate;
            this.figureAmount = amount;
            this.$refs.modal.open();
        },
        saveChanges() {
            this.$emit('updated', {
                source: this.sourceCurrency,
                target: this.targetCurrency,
                rate: this.conversionRate,
            });
            this.close();
        },
        close() {
            this.$refs.modal.close();
        },
        openCurrencyConverter() {
            Eventbus.$emit('currencyConverter');
        },
    },
};
</script>
