<template>
    <div class="stylised-modal-wrapper" :class="{ open: isOpen }">
        <transition name="fade" mode="out-in">
            <div v-if="isOpen" class="backdrop" @click="close()"></div>
        </transition>
        <transition name="slide" mode="out-in">
            <div v-if="isOpen" class="stylised-modal-container buefy-wrapper">
                <div class="stylised-modal">
                    <div class="stylised-modal-header">
                        <h4>{{ modalTitle }}</h4>
                        <div class="stylised-modal-actions">
                            <button @click="close()"><i class="mdi mdi-close"></i></button>
                        </div>
                    </div>
                    <div class="stylised-modal-content">
                        <slot></slot>
                    </div>
                </div>
                <div v-if="$slots.actions || $slots.sidebar" class="stylised-modal-sidebar">
                    <slot name="sidebar"></slot>
                    <template v-if="$slots.actions">
                        <h5>Actions</h5>
                        <div>
                            <nav>
                                <slot name="actions"></slot>
                            </nav>
                        </div>
                    </template>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    emits: ['opening', 'opened', 'closing', 'closed'],
    data() {
        return {
            isOpen: false,
            modalTitle: null,
        };
    },
    watch: {
        title(newVal) {
            this.modalTitle = newVal;
        },
    },
    mounted() {
        this.modalTitle = this.title;
    },
    methods: {
        open() {
            this.$emit('opening');
            this.isOpen = true;
            this.$emit('opened');
        },
        close() {
            this.$emit('closing');
            this.isOpen = false;
            this.$emit('closed');
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.stylised-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5450;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(.open) {
        display: none;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black, 0.33);
    }

    .stylised-modal-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        & > .stylised-modal {
            width: min(600px, 90vw);
            position: relative;
            display: flex;
            flex-direction: column;

            &:after {
                content: '';
                position: absolute;
                bottom: -7px;
                left: 0;
                width: 100%;
                height: 7px;
                background-image: url('@/images/tapered-receipt-end.png');
                background-repeat: repeat-x;
            }

            & > .stylised-modal-header {
                background: white;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                display: flex;
                align-items: center;
                min-height: 50px;
                padding: 0 10px 0 15px;
                user-select: none;

                & > h4 {
                    margin: 2px 0 0 0;
                }

                & > .stylised-modal-actions {
                    margin-left: auto;
                    display: flex;
                    gap: 10px;

                    button {
                        border: none;
                        width: 32px;
                        height: 32px;
                        border-radius: 5px;
                        font-size: 20px;
                        background: transparent;
                        color: rgba(black, 0.66);

                        &:hover {
                            background: rgba(black, 0.05);
                            color: black;
                        }
                    }
                }
            }

            & > .stylised-modal-content {
                flex: 1;
                background: white;
                padding: 5px 0 5px 0;
            }
        }

        & > .stylised-modal-sidebar {
            width: 275px;
            min-width: 275px;
            max-width: 275px;
            background: white;
            border-left: 1px solid rgba(black, 0.1);
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            display: flex;
            flex-direction: column;
            position: absolute;
            right: -275px;

            & > h5 {
                background: rgba(black, 0.01);
                margin: 0;
                padding: 8px 10px 5px 10px;
                border-bottom: 1px solid rgba(black, 0.1);
                font-size: 1.1em;
                user-select: none;

                &:first-child {
                    border-top-right-radius: 8px;
                }
            }
            & > div {
                padding: 10px;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(black, 0.1);
                }

                nav {
                    display: flex;
                    flex-direction: column;

                    & > a {
                        font-family: $eventwise-font-heading;
                    }
                }
            }

            @media (max-width: 1250px) {
                border-top-left-radius: 8px;
                border-bottom-right-radius: 0px;
                left: calc(50% - calc(275px / 2));
                bottom: unset;
                top: 100vh;
                border-top: 1px solid rgba(black, 0.1);
                border-right: 1px solid rgba(black, 0.1);
                transition: all 0.2s ease;

                &::before {
                    content: 'View Actions';
                    position: absolute;
                    top: -30px;
                    background: white;
                    height: 30px;
                    width: 125px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    left: calc(50% - calc(125px / 2));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid rgba(black, 0.2);
                    border-left: 1px solid rgba(black, 0.2);
                    border-right: 1px solid rgba(black, 0.2);
                    transition: all 0.2s ease;
                }

                &:hover {
                    top: unset;
                    bottom: 0;

                    &::before {
                        background: rgb(245, 245, 245);
                    }
                }
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.1s;
    opacity: 1;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s;
    opacity: 1;
    transform: translateY(0);
}
.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateY(400px);
}
</style>
