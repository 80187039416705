<template>
    <div>
        <div class="tw-flex tw-flex-col tw-h-full tw-px-3">
            <p v-if="!approved" class="empty-label tw-py-3 tw-mb-3">
                This Purchase Order has not yet been approved!
                <br />
                Figures below display the effect on the overall budget were it to be approved
            </p>

            <div class="row mb-3">
                <div class="col-md-3 pr-1">
                    <div class="card border rounded shadow-none py-2 px-3 h-100">
                        <h6 class="strong">Budget</h6>
                        <h4 class="text-right mt-auto">{{ localise(budgetedAmount, 2, true) }}</h4>
                    </div>
                </div>

                <div class="col-md-3 px-1">
                    <div class="card border rounded shadow-none py-2 px-3 h-100">
                        <h6 class="strong">Committed Elsewhere</h6>
                        <h4 class="text-right mt-auto">
                            {{ localise(totalCommitted, 2, true) }}
                        </h4>
                    </div>
                </div>

                <div class="col-md-3 px-1">
                    <div class="card border rounded shadow-none py-2 px-3 h-100">
                        <h6 class="strong">This Purchase</h6>
                        <h4 class="text-right mt-auto">{{ localise(subtotal, 2, true) }}</h4>
                    </div>
                </div>

                <div class="col-md-3 pl-1">
                    <div class="card border rounded shadow-none py-2 px-3 h-100">
                        <h6 class="strong">Remaining Budget</h6>
                        <h4
                            class="text-right mt-auto"
                            :class="(budgetedAmount - subtotal - totalCommitted).toFixed(2) < 0 ? 'text-danger' : ''"
                        >
                            {{ localise(budgetedAmount - subtotal - totalCommitted, 2, true) }}
                        </h4>
                    </div>
                </div>
            </div>

            <!-- Budget Entry Line -->
            <div v-if="budgetEntry" class="tw-border tw-p-3 tw-rounded tw-mb-3">
                <div class="tw-grid tw-grid-cols-2 tw-mb-3">
                    <div>
                        <div style="font-size: 0.7rem">Budget Entry</div>
                        <Button type="is-link" @click="onOpen()">
                            {{ budgetEntry.name }}
                        </Button>
                        <Tag class="tw-ml-2" :status="budgetEntry.status" />
                    </div>

                    <div class="text-right">
                        <div style="font-size: 0.7rem">Budget</div>
                        {{ localise(budgetedAmount, 2, true) }}
                    </div>
                </div>

                <Tooltip class="tw-w-full" position="top">
                    <template #content>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-justify-between">
                            <div class="tw-pb-1 tw-border-bottom border-muted">Committed</div>
                            <div class="tw-pb-1 tw-border-bottom border-muted">
                                {{ localise(subtotal + totalCommitted, 2, true) }}
                            </div>
                            <template v-if="budgetedAmount - subtotal - totalCommitted >= 0">
                                <div class="pt-1">Remaining</div>
                                <div class="pt-1">
                                    {{ localise(budgetedAmount - subtotal - totalCommitted, 2, true) }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="tw-pt-1">Over Budget By</div>
                                <div class="tw-pt-1">
                                    {{ localise(subtotal + totalCommitted - budgetedAmount, 2, true) }}
                                </div>
                            </template>
                        </div>
                    </template>
                    <ProgressBar type="success" :progress="(budgetBarVal / budgetBarMax) * 100">
                        {{ localise(budgetBarVal, 2, true) }}
                    </ProgressBar>
                    <ProgressBar
                        v-if="subtotal + totalCommitted - budgetedAmount > 0"
                        class="tw-mt-2"
                        type="danger"
                        :progress="((subtotal + totalCommitted - budgetedAmount) / budgetBarMax) * 100"
                    >
                        {{ localise(budgetBarVal, 2, true) }}
                    </ProgressBar>
                </Tooltip>
            </div>

            <!-- Department Budget Line -->
            <div v-if="figuresLoaded" class="border p-3 rounded mb-3">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div style="font-size: 0.7rem">Department Budget</div>
                        <Button
                            type="is-link"
                            @click="
                                navigateTo(
                                    route('account.event.budget', {
                                        event: budgetEntry.event_id,
                                        page: 'costs',
                                        departmentId: budgetingData.category.department.id,
                                    })
                                )
                            "
                        >
                            {{ budgetingData.category.department.name }}
                        </Button>
                    </div>

                    <div class="col-md-6 text-right">
                        <div style="font-size: 0.7rem">Budget</div>
                        {{ localise(budgetingData.departmentFigures.figures.budget, 2, true) }}
                    </div>
                </div>

                <Tooltip class="tw-w-full">
                    <template #content>
                        <div class="row">
                            <div class="col-6 text-left pb-1 border-bottom border-muted">Committed</div>
                            <div class="col-6 text-right pb-1 border-bottom border-muted">
                                {{
                                    localise(
                                        (approved ? 0 : subtotal) + budgetingData.departmentFigures.figures.committed,
                                        2,
                                        true
                                    )
                                }}
                            </div>
                            <template
                                v-if="
                                    budgetingData.departmentFigures.figures.budget -
                                        (approved ? 0 : subtotal) -
                                        budgetingData.departmentFigures.figures.committed >=
                                    0
                                "
                            >
                                <div class="col-6 pt-1 text-left">Remaining</div>
                                <div class="col-6 pt-1 text-right">
                                    {{
                                        localise(
                                            budgetingData.departmentFigures.figures.budget -
                                                (approved ? 0 : subtotal) -
                                                budgetingData.departmentFigures.figures.committed,
                                            2,
                                            true
                                        )
                                    }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-6 pt-1 text-left">Over Budget By</div>
                                <div class="col-6 pt-1 text-right">
                                    {{
                                        localise(
                                            (approved ? 0 : subtotal) +
                                                budgetingData.departmentFigures.figures.committed -
                                                budgetingData.departmentFigures.figures.budget,
                                            2,
                                            true
                                        )
                                    }}
                                </div>
                            </template>
                        </div>
                    </template>
                    <ProgressBar type="success" :progress="(departmentBarVal / departmentBarMax) * 100">
                        {{ localise(departmentBarVal, 2, true) }}
                    </ProgressBar>

                    <ProgressBar
                        v-if="
                            (approved ? 4 : subtotal) +
                                budgetingData.departmentFigures.figures.committed -
                                budgetingData.departmentFigures.figures.budget >
                            0
                        "
                        class="tw-mt-2"
                        type="danger"
                        :progress="
                            (((approved ? 0 : subtotal) +
                                budgetingData.departmentFigures.figures.committed -
                                budgetingData.departmentFigures.figures.budget) /
                                departmentBarMax) *
                            100
                        "
                    >
                        {{
                            localise(
                                (approved ? 0 : subtotal) +
                                    budgetingData.departmentFigures.figures.committed -
                                    budgetingData.departmentFigures.figures.budget,
                                2,
                                true
                            )
                        }}
                    </ProgressBar>
                </Tooltip>
            </div>

            <!-- Category Budget Line -->
            <div v-if="figuresLoaded" class="border p-3 rounded mb-3">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div style="font-size: 0.7rem">Subcategory Budget</div>
                        <Button
                            type="is-link"
                            @click="
                                navigateTo(
                                    route('account.event.budget', {
                                        event: budgetEntry.event_id,
                                        page: 'costs',
                                        departmentId: budgetingData.category.department.id,
                                        categoryId: budgetingData.category.id,
                                    })
                                )
                            "
                        >
                            {{ budgetingData.category.name }}
                        </Button>
                    </div>

                    <div class="col-md-6 text-right">
                        <div style="font-size: 0.7rem">Budget</div>
                        {{ localise(budgetingData.categoryFigures.figures.budget, 2, true) }}
                    </div>
                </div>

                <Tooltip class="tw-w-full">
                    <template #content>
                        <div class="row">
                            <div class="col-6 text-left pb-1 border-bottom border-muted">Committed</div>
                            <div class="col-6 text-right pb-1 border-bottom border-muted">
                                {{
                                    localise(
                                        (approved ? 0 : subtotal) + budgetingData.categoryFigures.figures.committed,
                                        2,
                                        true
                                    )
                                }}
                            </div>
                            <template
                                v-if="
                                    budgetingData.categoryFigures.figures.budget -
                                        (approved ? 0 : subtotal) -
                                        budgetingData.categoryFigures.figures.committed >=
                                    0
                                "
                            >
                                <div class="col-6 pt-1 text-left">Remaining</div>
                                <div class="col-6 pt-1 text-right">
                                    {{
                                        localise(
                                            budgetingData.categoryFigures.figures.budget -
                                                (approved ? 0 : subtotal) -
                                                budgetingData.categoryFigures.figures.committed,
                                            2,
                                            true
                                        )
                                    }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-6 pt-1 text-left">Over Budget By</div>
                                <div class="col-6 pt-1 text-right">
                                    {{
                                        localise(
                                            (approved ? 0 : subtotal) +
                                                budgetingData.categoryFigures.figures.committed -
                                                budgetingData.categoryFigures.figures.budget,
                                            2,
                                            true
                                        )
                                    }}
                                </div>
                            </template>
                        </div>
                    </template>
                    <ProgressBar type="success" :progress="(categoryBarVal / categoryBarMax) * 100">
                        {{ localise(categoryBarVal, 2, true) }}
                    </ProgressBar>
                    <ProgressBar
                        v-if="
                            (approved ? 0 : subtotal) +
                                budgetingData.categoryFigures.figures.committed -
                                budgetingData.categoryFigures.figures.budget >
                            0
                        "
                        type="danger"
                        :progress="
                            (((approved ? 0 : subtotal) +
                                budgetingData.categoryFigures.figures.committed -
                                budgetingData.categoryFigures.figures.budget) /
                                categoryBarMax) *
                            100
                        "
                    >
                        {{
                            localise(
                                (approved ? 0 : subtotal) +
                                    budgetingData.categoryFigures.figures.committed -
                                    budgetingData.categoryFigures.figures.budget,
                                2,
                                true
                            )
                        }}
                    </ProgressBar>
                </Tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Tag from '@/js/components/Tag.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { localise } from '@/js/utils.js';
import ProgressBar from '@/js/components/ProgressBar.vue';

export default {
    components: {
        Button,
        Tooltip,
        ProgressBar,
        Tag,
    },
    props: {
        budgetEntry: {
            type: Object,
            required: true,
        },
        subtotal: {
            type: Number,
            default: 0,
        },
        approved: {
            type: Boolean,
            default: false,
        },
        onOpen: {
            type: Function,
            required: true,
        },
    },
    data() {
        const store = useDataStore();

        return {
            series: [],
            totalCommitted: 0,
            categoryFigures: 0,
            departmentFigures: 0,
            budgetingData: {},
            figuresLoaded: false,
            budgetedAmount: 0,
            currentAccount: store.get('account'),
        };
    },
    computed: {
        budgetBarMax() {
            return Math.max(this.budgetedAmount, this.subtotal + this.totalCommitted);
        },
        budgetBarVal() {
            return Math.min(this.budgetedAmount, this.subtotal + this.totalCommitted);
        },
        departmentBarMax() {
            return Math.max(
                this.budgetingData?.departmentFigures?.figures?.budget ?? 0,
                (this.approved ? 0 : this.subtotal) + (this.budgetingData?.departmentFigures?.figures?.committed ?? 0)
            );
        },
        departmentBarVal() {
            return Math.min(
                this.budgetingData?.departmentFigures?.figures?.budget ?? 0,
                (this.approved ? 0 : this.subtotal) + (this.budgetingData?.departmentFigures?.figures?.committed ?? 0)
            );
        },
        categoryBarMax() {
            return Math.max(
                this.budgetingData?.categoryFigures?.figures?.budget ?? 0,
                (this.approved ? 0 : this.subtotal) + (this.budgetingData?.categoryFigures?.figures?.committed ?? 0)
            );
        },
        categoryBarVal() {
            return Math.min(
                this.budgetingData?.categoryFigures?.figures?.budget ?? 0,
                (this.approved ? 0 : this.subtotal) + (this.budgetingData?.categoryFigures?.figures?.committed ?? 0)
            );
        },
    },
    mounted() {
        this.budgetingChartSeries();
        this.loadBudgetingFigures();
    },
    methods: {
        localise,
        budgetingChartSeries() {
            this.budgetedAmount = parseFloat(this.budgetEntry.budgeted_cost);
            // total_purchaseorders represents Approved POs only
            this.totalCommitted = parseFloat(this.budgetEntry.total_purchaseorders);
            const thisValue = parseFloat(this.subtotal);
            if (this.isApproved()) {
                // this po is approved take it out of the total committed value
                this.totalCommitted = this.totalCommitted - thisValue < 0 ? 0.1 : this.totalCommitted - thisValue;
                // if this PO approved, totalCommitted = totalCommitted - thisValue;
            }
            // this.series = [totalCommitted, thisValue, remainingBudget]
            const remainingBudget =
                this.budgetedAmount - this.totalCommitted - thisValue < 0
                    ? 0
                    : this.budgetedAmount - this.totalCommitted - thisValue;
            // Remaining budget = 0 if remaining budget is negative
            this.series =
                remainingBudget < 0
                    ? [this.totalCommitted, thisValue, 0]
                    : [this.totalCommitted, thisValue, remainingBudget];
        },
        isApproved() {
            return ['Approved', 'Awaiting Reapproval'].includes(this.budgetEntry.status);
        },
        loadBudgetingFigures() {
            this.figuresLoaded = false;
            axios
                .get(
                    route('api.account.event.purchaseorders.budgetingfigures', {
                        account: this.currentAccount.id,
                        event: this.budgetEntry.event_id,
                        category: this.budgetEntry.category_id,
                    })
                )
                .then(({ data }) => {
                    this.budgetingData = data;
                    this.figuresLoaded = true;
                });
        },
        navigateTo(location) {
            window.location.href = location;
        },
    },
};
</script>
