<template>
    <Modal ref="modal" title="Connect to Skiddle" size="is-xlarge" @closed="reset()">
        <p class="mb-3">
            In order to integrate with Skiddle you require an API key and a promoter access token. If you need
            assistance with retrieving these please get in touch with us from the help centre.
        </p>
        <FormField label="API Key">
            <Textbox v-model="apiKey" placeholder="Required" maxlength="255" :disabled="isLoading" />
        </FormField>
        <FormField label="Promoter Access Token" class="tw-mt-2">
            <Textbox v-model="promoterAccessToken" placeholder="Required" maxlength="255" :disabled="isLoading" />
        </FormField>
        <template #footer>
            <Button class="!tw-mr-auto" @click="close()">Cancel</Button>
            <Button type="is-success" :loading="isLoading" :disabled="!isValid" @click="validateDetails()">
                Validate Connection
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import { toast } from '@/js/utils.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
    },
    emits: ['connected'],
    data() {
        return {
            isLoading: false,
            promoterAccessToken: '',
            apiKey: '',
        };
    },
    computed: {
        isValid() {
            return this.promoterAccessToken && this.apiKey;
        },
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
        open() {
            this.$refs.modal.open();
        },
        reset() {
            this.promoterAccessToken = '';
            this.apiKey = '';
        },
        validateDetails() {
            this.isLoading = true;
            axios
                .post(route('api.account.integrations.skiddle.connect'), {
                    api_key: this.apiKey,
                    promoter_access_token: this.promoterAccessToken,
                })
                .then(() => {
                    toast('Success', "You're now connected to Skiddle", 'success');
                    this.$emit('connected');
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
