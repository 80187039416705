<template>
    <div class="tw-flex tw-items-center tw-gap-1">
        <Button :disabled="currentPage <= 1" @click="$emit('change-page', data.current_page - 1)">
            <i class="mdi mdi-chevron-left"></i>
        </Button>
        <template v-if="currentPage - rangeBefore > 1">
            <Button @click="$emit('change-page', 1)">1</Button>
            <i class="mdi mdi-dots-horizontal tw-text-base tw-text-gray-500"></i>
        </template>
        <Button
            v-for="pageNumber in pageRange"
            :key="pageNumber"
            :active="currentPage == pageNumber"
            @click="$emit('change-page', pageNumber)"
        >
            {{ pageNumber }}
        </Button>
        <template v-if="currentPage + rangeAfter < totalPages">
            <i class="mdi mdi-dots-horizontal tw-text-base tw-text-gray-500"></i>
            <Button @click="$emit('change-page', totalPages)">{{ totalPages }}</Button>
        </template>
        <Button :disabled="currentPage >= totalPages" @click="$emit('change-page', data.current_page + 1)">
            <i class="mdi mdi-chevron-right"></i>
        </Button>
    </div>
</template>
<script>
import Button from './Button.vue';

export default {
    components: {
        Button,
    },
    props: {
        data: {
            type: [Object, Array],
            required: true,
        },
        rangeBefore: {
            type: Number,
            default: 2,
        },
        rangeAfter: {
            type: Number,
            default: 2,
        },
    },
    emits: ['change-page'],
    computed: {
        currentPage() {
            return this.data.current_page;
        },
        totalPages() {
            return Math.ceil(this.data.total / this.data.per_page);
        },
        pageRange() {
            const range = [];
            for (let i = this.currentPage - this.rangeBefore; i <= this.currentPage + this.rangeAfter; i++) {
                if (i > 0 && i <= this.totalPages) {
                    range.push(i);
                }
            }
            return range;
        },
    },
};
</script>
