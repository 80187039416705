<template>
    <ew-card :expanded="true" :loading="isLoading" paddingless>
        <template #title>
            <h4 class="tw-ml-4 tw-mr-2 tw-my-0">Budget Approvals</h4>
            <Tag v-if="totalEntries" type="info" class="mr-auto">
                {{ totalEntries }}
            </Tag>
        </template>

        <template #actions>
            <Button @click="visitApprovals()">View all</Button>
        </template>
        <template v-if="totalEntries">
            <approvals-row
                v-for="budgetEntry in budgetEntryApprovals"
                :key="budgetEntry.id"
                :budget-entry="budgetEntry"
            ></approvals-row>
        </template>
        <p v-else class="empty-label m-3 py-3">
            Looks like you're all caught up! There aren't any approvals to show here
        </p>
        <a
            v-if="budgetEntryApprovals.length < totalEntries"
            class="tw-block tw-py-3 tw-w-full tw-text-center"
            :href="route('account.event.approvals')"
        >
            See all {{ totalEntries }} items in the Approvals Dashboard
            <i class="mdi mdi-arrow-right" />
        </a>
    </ew-card>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Tag from '@/js/components/Tag.vue';

export default {
    components: {
        Button,
        Tag,
    },
    data() {
        return {
            isLoading: false,
            budgetEntryApprovals: [],
            totalEntries: null,
        };
    },
    mounted() {
        this.reloadData();
        Eventbus.$on('reload:entries', () => this.reloadData());
    },
    methods: {
        reloadData() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.entries.byApprovals', {
                        paginated: true,
                    })
                )
                .then((response) => {
                    this.budgetEntryApprovals = response.data.data;
                    this.totalEntries = response.data.total;
                })
                .finally(() => (this.isLoading = false));
        },
        visitApprovals() {
            window.location.href = route('account.event.approvals');
        },
    },
};
</script>
