<template>
    <div class="subcategory-editor">
        <table class="table">
            <thead>
                <tr>
                    <th v-if="displayTags && isEditable"></th>
                    <th>Name</th>
                    <th>Description</th>
                    <th v-if="xeroAccounts && xeroAccounts.length">Xero Account</th>
                    <th v-if="xeroAccounts && xeroAccounts.length && isPoolable" class="text-center">Pooled</th>
                    <th v-if="displayEstimates">Fixed Amount</th>
                    <th v-if="source === 'onboarding_copy' && !tickets" class="!tw-p-0 !tw-pb-1" colspan="2">
                        <Checkbox
                            :model-value="subcategories.find((c) => c.id && c.copy_budget)"
                            :class="revenue ? '!tw-mb-1' : '!tw-mb-0'"
                            @click="toggleAllCopyBudget()"
                        >
                            <span class="tw-font-medium">Budget Entries</span>
                        </Checkbox>
                        <Checkbox
                            :model-value="subcategories.find((c) => c.id && c.copy_revenue)"
                            class="!tw-mb-0"
                            @click="toggleAllCopyRevenue()"
                        >
                            <span class="tw-font-medium">Revenue Entries</span>
                        </Checkbox>
                    </th>
                    <th v-if="displayActions"></th>
                </tr>
            </thead>
            <tbody v-if="isEditable">
                <tr v-for="(subcategory, index) in subcategories" :key="index" class="editable">
                    <td
                        v-if="displayTags"
                        class="sc-tag"
                        :class="{
                            existing: subcategory.id,
                        }"
                    >
                        {{ subcategory.id ? 'Existing' : 'New' }}
                    </td>
                    <td
                        class="sc-name"
                        :class="{
                            invalid:
                                !subcategory.name ||
                                subcategories.filter((sc) => sc.name == subcategory.name).length > 1,
                        }"
                    >
                        <input
                            v-model="subcategory.name"
                            type="text"
                            placeholder="Name"
                            maxlength="255"
                            class="form-control"
                        />
                    </td>
                    <td class="sc-description">
                        <input
                            v-model="subcategory.description"
                            type="text"
                            placeholder="Add a description"
                            class="form-control"
                            maxlength="255"
                        />
                    </td>
                    <td v-if="filteredXeroAccounts && filteredXeroAccounts.length" class="sc-xero-account">
                        <Selector
                            v-model="subcategory.xero_account_id"
                            :options="filteredXeroAccounts"
                            additional-search-key="code"
                            placeholder="Link to a Xero Account"
                            clipped
                            @update:model-value="(value) => (!value ? (subcategory.is_pooled = false) : null)"
                        >
                            <template #optionLabel="{ option }">{{ option.code }} - {{ option.name }}</template>
                        </Selector>
                    </td>
                    <td v-if="filteredXeroAccounts && filteredXeroAccounts.length && isPoolable" class="text-center">
                        <Checkbox
                            v-if="subcategory.xero_account_id"
                            v-model="subcategory.is_pooled"
                            class="tw-justify-center"
                            @click.native.prevent="setPooledAccount(index)"
                        ></Checkbox>
                    </td>
                    <td v-if="displayEstimates" class="sc-fixed">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">&pound;</span>
                            </div>
                            <input
                                v-model="subcategory.fixed_amount"
                                type="text"
                                class="form-control"
                                placeholder="Fixed Amount"
                            />
                        </div>
                    </td>
                    <td v-if="source === 'onboarding_copy' && !tickets">
                        <Checkbox
                            v-if="subcategory.id"
                            :id="`copy-budget-${subcategory.id}`"
                            v-model="subcategory.copy_budget"
                            :class="revenue ? '!tw-mb-1' : '!tw-mb-0'"
                        >
                            Budget Entries
                        </Checkbox>
                        <Checkbox
                            v-if="subcategory.id && revenue"
                            :id="`copy-revenue-${subcategory.id}`"
                            v-model="subcategory.copy_revenue"
                            class="!tw-mb-0"
                        >
                            Revenue Entries
                        </Checkbox>
                    </td>
                    <td v-if="source === 'onboarding_copy' && !tickets">
                        <Select
                            v-model="subcategory.copy_option"
                            :disabled="!subcategory.copy_revenue && !subcategory.copy_budget"
                        >
                            <option :value="null" selected>Copy all entries</option>
                            <option value="single">Copy to a single entry</option>
                        </Select>
                    </td>
                    <td v-if="displayActions" class="sc-actions">
                        <button
                            v-if="!subcategory.id || deleteExisting"
                            class="btn btn-outline-danger"
                            @click="deleteSubcategory(index)"
                        >
                            <i class="mdi mdi-delete"></i>
                        </button>
                        <button
                            v-if="displayEditing"
                            class="btn btn-outline-primary"
                            @click="$emit('edit', subcategory.id)"
                        >
                            <i class="mdi mdi-pencil"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(subcategory, index) in subcategories" :key="index">
                    <td class="sc-name">{{ subcategory.name }}</td>
                    <td class="sc-description">
                        <span v-if="subcategory.description">{{ subcategory.description }}</span>
                        <span v-else class="text-muted">None</span>
                    </td>
                    <td v-if="xeroAccounts && xeroAccounts.length" class="sc-xero-account">-</td>
                    <td v-if="displayEstimates" class="sc-fixed">
                        {{ localise(subcategory.fixed_amount ? subcategory.fixed_amount : 0, 2, true) }}
                    </td>
                    <td v-if="displayActions" class="sc-actions"></td>
                </tr>
            </tbody>
        </table>
        <DeleteSubcategory ref="deleteSubcategoryModal" @delete="confirmDeleteSubcategory" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Selector from '../../../../../js/components/controls/Selector.vue';
import DeleteSubcategory from '../../../../../js/components/modals/Account/DeleteSubcategory.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Select from '@/js/components/controls/Select.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Selector,
        DeleteSubcategory,
        Checkbox,
        Select,
    },
    props: {
        modelValue: {
            type: Array,
            required: false,
        },
        createable: {
            type: Boolean,
            default: true,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        deleteable: {
            type: Boolean,
            default: false,
        },
        poolable: {
            type: Boolean,
            default: true,
        },
        estimates: {
            type: Boolean,
            default: false,
        },
        showTags: {
            type: Boolean,
            default: true,
        },
        showActions: {
            type: Boolean,
            default: true,
        },
        showEditing: {
            type: Boolean,
            default: true,
        },
        enableDeleteExisting: {
            type: Boolean,
            default: false,
        },
        xeroAccounts: {
            type: Array,
            required: false,
        },
        confirmDelete: {
            type: Boolean,
            default: false,
        },
        source: {
            type: String,
            default: 'account',
        },
        revenue: {
            type: Boolean,
            default: false,
        },
        tickets: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['delete', 'edit'],
    data() {
        const store = useDataStore();
        return {
            isEditable: true,
            isCreateable: true,
            isPoolable: true,
            isDeleteable: false,
            displayEstimates: false,
            displayTags: true,
            displayActions: true,
            displayEditing: true,
            deleteExisting: false,
            subcategories: [],
            availableXeroAccounts: null,
            store: store,
        };
    },
    computed: {
        filteredXeroAccounts() {
            if (!this.availableXeroAccounts) {
                return [];
            }
            return this.availableXeroAccounts.filter((xa) => xa.type !== 'BANK');
        },
    },
    watch: {
        editable(newVal) {
            this.isEditable = newVal;
        },
        createable(newVal) {
            this.isCreateable = newVal;
        },
        deleteable(newVal) {
            this.isDeleteable = newVal;
        },
        estimates(newVal) {
            this.displayEstimates = newVal;
        },
        poolable(newVal) {
            this.isPoolable = newVal;
        },
        showTags(newVal) {
            this.displayTags = newVal;
        },
        showActions(newVal) {
            this.displayActions = newVal;
        },
        showEditing(newVal) {
            this.displayEditing = newVal;
        },
        enableDeleteExisting(newVal) {
            this.deleteExisting = newVal;
        },
        xeroAccounts: {
            handler(newVal) {
                this.availableXeroAccounts = newVal;
            },
            deep: true,
        },
        modelValue: {
            handler(newVal) {
                this.subcategories = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        this.isEditable = this.editable;
        this.isCreateable = this.createable;
        this.isDeleteable = this.deleteable;
        this.isPoolable = this.poolable;
        this.displayEstimates = this.estimates;
        this.displayTags = this.showTags;
        this.displayActions = this.showActions;
        this.displayEditing = this.showEditing;
        this.deleteExisting = this.enableDeleteExisting;
        this.availableXeroAccounts = this.xeroAccounts;

        if (this.modelValue) {
            this.subcategories = this.modelValue;
        }
    },
    methods: {
        deleteSubcategory(index) {
            const category = this.subcategories[index];
            if (this.confirmDelete) {
                this.$refs.deleteSubcategoryModal.open(category.department_id, category.id);
                return;
            } else if (!category) {
                return;
            } else if (category.id) {
                this.$emit('delete', category.id);
            }
            this.subcategories.splice(index, 1);
        },
        confirmDeleteSubcategory(id) {
            const index = this.subcategories.findIndex((sc) => sc.id == id);
            if (index < 0) {
                toast('Error', 'There was an error deleting your subcategory: index not found', 'error');
                return;
            }
            this.subcategories.splice(index, 1);
            this.$emit('delete', id);
        },
        setPooledAccount(index) {
            const category = this.subcategories[index];
            if (!category || !category.xero_account_id) {
                return;
            }
            if (category.is_pooled) {
                swal.fire({
                    title: 'Warning',
                    text: 'This subcategory was previously setup as a pooled account with Xero.\n\nIf unpooled we will no longer sync reports from Xero.',
                    showCancelButton: true,
                    confirmButtonColor: '#4EA5D9',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Unpool Subcategory',
                }).then((result) => {
                    if (!result.value) {
                        return;
                    }
                    this.subcategories[index].is_pooled = false;
                });
                return;
            } else if (category.id) {
                swal.fire({
                    title: 'Warning',
                    text: 'Pooling this subcategory will disable the creation of anything within Eventwise and delete all existing data.\n\nInstead we will automatically pull records from Xero based on the tracking category of your event.',
                    showCancelButton: true,
                    confirmButtonColor: '#4EA5D9',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Pool Subcategory',
                }).then((result) => {
                    if (!result.value) {
                        return;
                    }
                    this.subcategories[index].is_pooled = true;
                });
                return;
            }
            this.subcategories[index].is_pooled = true;
        },
        toggleAllCopyBudget() {
            const newValue = this.subcategories.find((c) => c.id && !c.copy_budget) ? true : false;
            this.subcategories = this.subcategories.map((c) => {
                if (c.id) {
                    c.copy_budget = newValue;
                }
                return c;
            });
        },
        toggleAllCopyRevenue() {
            const newValue = this.subcategories.find((c) => c.id && !c.copy_revenue) ? true : false;
            this.subcategories = this.subcategories.map((c) => {
                if (c.id) {
                    c.copy_revenue = newValue;
                }
                return c;
            });
        },
    },
};
</script>
<style lang="scss">
.subcategory-editor {
    width: 100%;

    & > table {
        width: 100%;
        margin: 0;

        & > tbody {
            & > tr {
                & > td {
                    vertical-align: middle !important;

                    &.sc-tag {
                        font-family: 'Poppins', sans-serif;
                        text-align: center;
                        color: white;
                        background: #28bd9d;
                        border-color: darken(#28bd9d, 5);
                        width: 80px;

                        &.existing {
                            background: #0f81c7;
                            border-color: darken(#0f81c7, 5);
                        }
                    }

                    &.sc-users,
                    &.sc-events {
                        width: 190px;
                    }
                    &.sc-fixed {
                        width: 220px;
                    }
                    &.sc-xero-account {
                        width: 270px;
                        max-width: 270px;
                        padding: 1px 5px !important;
                    }
                    &.sc-actions {
                        display: flex;
                        min-width: 40px;
                        justify-content: flex-end;

                        button.btn {
                            border-radius: 0 !important;
                        }
                    }
                }

                &.editable {
                    & > td {
                        padding: 0;

                        & > input {
                            border: none;
                            font-family: 'Poppins', sans-serif;
                            padding: 8px 10px;
                            width: 100%;

                            &:focus {
                                background: rgba(yellow, 0.05);
                            }
                        }

                        & > .input-group {
                            .input-group-text {
                                border-radius: 0;
                            }

                            input {
                                border: none;

                                &:focus {
                                    background: rgba(yellow, 0.05);
                                }
                            }
                        }

                        &.invalid {
                            background: rgba(red, 0.05);

                            input {
                                background: rgba(red, 0.05);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
