<template>
    <div :class="headerClass">
        <ul
            class="tw-flex tw-flex-wrap tw-gap-1 tw-items-end tw-m-0 tw-text-sm tw-text-center tw-text-gray-500 tw-border-b tw-border-gray-200"
            :class="headerClass"
        >
            <li v-for="(tab, index) in tabs" :key="index" :class="tab.headerClass">
                <button
                    v-if="!tab.hidden"
                    class="tw-px-2 tw-py-1.5 tw-border-b-2 tw-border-transparent tw-rounded-t-lg hover:tw-text-slate-600 hover:tw-border-slate-300"
                    :class="[
                        headingClass,
                        {
                            'tw-text-eventwise !tw-border-sky-400 tw-font-medium hover:tw-border-eventwise hover:!tw-text-sky-700':
                                activeTab === index,
                        },
                    ]"
                    @click="activeTab = index"
                >
                    <slot v-if="$slots[`heading(${tab.name})`]" :name="`heading(${tab.name})`"></slot>
                    <span v-else>{{ tab.title }}</span>
                </button>
            </li>
        </ul>
    </div>
    <div :class="containerClass">
        <div v-for="(tab, index) in tabs" v-show="activeTab === index" :key="index" :class="contentClass">
            <slot v-if="$slots[`tab(${tab.name})`]" :name="`tab(${tab.name})`"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        headerClass: {
            type: String,
            required: false,
        },
        headingClass: {
            type: String,
            required: false,
        },
        contentClass: {
            type: String,
            required: false,
        },
        containerClass: {
            type: String,
            required: false,
        },
        modelValue: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            activeTab: this.modelValue,
        };
    },
    watch: {
        modelValue() {
            this.activeTab = this.modelValue;
        },
        activeTab() {
            this.$emit('update:modelValue', this.activeTab);
        },
    },
};
</script>
