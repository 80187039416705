<template>
    <div class="buefy-wrapper">
        <Banner class="tw-bg-white mb-4">
            <div class="d-flex align-items-center">
                <i class="mdi mdi-tag h1 m-0"></i>
                <div class="flex-fill px-5">
                    <h4>Group Tags</h4>
                    Group Tags can be applied to Events and used to compare them across events within the organisation
                    <br />
                </div>
            </div>
        </Banner>
        <ew-card title="Group Tags" :collapsible="false" paddingless>
            <Tabs
                :tabs="[
                    { title: 'Tag Editor', name: 'tag_editor' },
                    { title: 'Model Tags', name: 'model_tags' },
                ]"
                class="tw-mt-2"
            >
                <template #tab(tag_editor)>
                    <TagEditor />
                </template>
                <template #tab(model_tags)>
                    <ModelTags tab-name="Events" class-name="App\Models\Event" />
                </template>
            </Tabs>
        </ew-card>
    </div>
</template>
<script>
import ModelTags from './GroupTags/ModelTags.vue';
import TagEditor from './GroupTags/TagEditor.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';

export default {
    components: {
        ModelTags,
        TagEditor,
        Banner,
        Tabs,
    },
};
</script>
