<template>
    <Modal ref="modal" title="Create Configurable Field" @closed="reset()">
        <FormField label="Field Name" class="mb-3">
            <Textbox v-model="fieldName" required />
        </FormField>
        <FormField label="Field Type">
            <Select v-model="fieldType" class="tw-w-full">
                <option value="text">Text</option>
                <!-- TODO: these have been left here for future implementation when they inevitably get requested -->
                <!-- <option value="wysiwyg">Formatted Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="datetime">Date and Time</option> -->
            </Select>
        </FormField>
        <template #footer>
            <Button @click="close()">Cancel</Button>
            <Button class="!tw-ml-auto" type="is-primary" :loading="isLoading" @click="createField()">
                Create Field
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../Modal.vue';
import FormField from '../widgets/FormField.vue';
import Textbox from '../controls/Textbox.vue';
import Select from '../controls/Select.vue';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: {
        Modal,
        FormField,
        Textbox,
        Select,
        Button,
    },
    emits: ['created'],
    data() {
        return {
            isLoading: false,
            objectType: null,
            objectData: null,
            fieldName: null,
            fieldType: 'text',
        };
    },
    methods: {
        open(type, data) {
            this.objectType = type;
            this.objectData = data;
            this.$refs.modal.open();
        },
        reset() {
            this.objectType = null;
            this.objectData = null;
            this.fieldName = null;
            this.fieldType = 'text';
        },
        close() {
            this.$refs.modal.close();
            this.reset();
        },
        createField() {
            this.isLoading = true;
            axios
                .post(route('api.account.fields.createOrUpdate'), {
                    fields: [
                        {
                            name: this.fieldName,
                            type: this.fieldType,
                            configurable_type: this.objectType,
                            configurable_id: this.objectData.id,
                        },
                    ],
                })
                .then(() => {
                    this.$emit('created');
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss" scoped></style>
