<template>
    <!-- Scenarios Table for use in Scenarios Card, not full width table -->

    <!-- This is the main scenarios card and table from the scenarios Twig -->
    <ew-card title="Scenarios" paddingless>
        <template v-if="scenarios" #append>
            <div class="text-right d-flex ml-auto">
                <Button v-if="!eventIsClosed" type="is-success" @click="openScenarioDrawer()">
                    <i class="mdi mdi-plus tw-mr-1"></i>
                    <span>New Scenario</span>
                </Button>
            </div>
        </template>

        <LoadingSpinner v-if="!isLoaded" label="Loading Scenarios" />
        <div v-else-if="!scenarios.length" class="row mb-2">
            <div class="w-100 empty-label py-5 mx-5 mt-2">
                There are no Scenarios to show here.
                <br />
                Please create your first scenario with the button below.
                <br />
                <br />
                <Button type="is-link" @click="openScenarioDrawer()">Create A New Scenario</Button>
            </div>
        </div>
        <div v-else class="d-flex">
            <div style="flex-grow: 1">
                <DataTable
                    :columns="[
                        { field: 'name', title: 'Name', sortable: true },
                        { field: 'created_at', title: 'Created', sortable: true },
                        {
                            field: 'total_revenue_adjustment_value',
                            title: 'Revenue Adjustment',
                            width: 180,
                            sortable: true,
                        },
                        {
                            field: 'total_cost_adjustment_value',
                            title: 'Cost Adjustment',
                            width: 180,
                            sortable: true,
                        },
                        { field: 'total_adjustment_value', title: 'Total Adjustment', width: 180, sortable: true },
                        { field: 'actions', title: '', width: 120, cellClass: '!tw-text-right' },
                    ]"
                    :data="scenarios"
                >
                    <template #column(name)="{ row }">
                        <Tooltip :disabled="!row.description" position="right" :label="row.description">
                            {{ row.name }}
                            <i v-if="row.description" class="mdi mdi-message-text"></i>
                        </Tooltip>
                    </template>
                    <template #column(created_at)="{ row }">
                        <span v-if="relativeTimestamps" class="timestamp-relative">
                            {{ timeAgo(row.created_at) }}
                        </span>
                        <span v-else class="timestamp">
                            {{ formatDate(row.created_at) }}
                        </span>
                    </template>
                    <template #column(total_revenue_adjustment_value)="{ row }">
                        {{ localise(row.total_revenue_adjustment_value, 2, true) }}
                    </template>
                    <template #column(total_cost_adjustment_value)="{ row }">
                        {{ localise(row.total_cost_adjustment_value, 2, true) }}
                    </template>
                    <template #column(total_adjustment_value)="{ row }">
                        {{ localise(row.total_adjustment_value, 2, true) }}
                    </template>
                    <template #column(actions)="{ row }">
                        <Button @click="openScenarioDrawer(row.id)">
                            <i class="mdi mdi-eye"></i>
                        </Button>
                    </template>
                </DataTable>
            </div>
        </div>
    </ew-card>
</template>
<script>
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { timeAgo, formatDate, localise } from '@/js/utils.js';

export default {
    components: { LoadingSpinner, Button, DataTable, Tooltip },
    props: {
        endpoint: {
            type: String,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        isOpen: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isLoaded: false,
            scenarios: [],
            relativeTimestamps: true,
            event: null,
        };
    },
    computed: {
        eventIsClosed() {
            return this.event && this.event.is_closed;
        },
    },
    watch: {
        isOpen: function (isOpenNow) {
            if (isOpenNow) {
                this.reloadData();
            } else {
                this.resetData();
            }
        },
    },
    mounted() {
        this.event = Auth.event;
        if (this.isOpen) {
            this.reloadData();
        }
        Eventbus.$on('reload:scenariosTable', () => {
            if (this.isOpen) {
                this.reloadData();
            }
        });
    },
    methods: {
        timeAgo,
        formatDate,
        localise,
        reloadData() {
            this.isLoaded = false;
            axios.get(this.endpoint).then(({ data }) => {
                this.scenarios = data;
                this.isLoaded = true;
            });
        },
        resetData() {
            this.isLoaded = false;
            this.scenarios = [];
            this.relativeTimestamps = true;
        },
        openScenarioDrawer(id = null) {
            // if id is undefined open the drawer for create
            // Otherwise open the drawer for update
            if (id === null) {
                Eventbus.$emit('scenario:drawerOpen');
            } else {
                Eventbus.$emit('scenario:drawerOpen', {
                    id: id,
                });
            }
        },
    },
};
</script>
