<template>
    <Ckeditor v-model="content" :editor="editor" :config="editorConfig" @ready="(editor) => $emit('ready', editor)" />
</template>

<script>
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Italic,
    Paragraph,
    Link,
    List,
    Indent,
    IndentBlock,
    EasyImage,
    Heading,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    CKFinderUploadAdapter,
    ListProperties,
    TextTransformation,
    CloudServices,
    CKFinder,
    HtmlEmbed,
    MediaEmbed,
    Table,
    TableToolbar,
    BlockQuote,
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

export default {
    components: {
        Ckeditor,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        without: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['update:modelValue', 'ready'],
    data() {
        return {
            editor: ClassicEditor,
            content: this.modelValue,
            editorConfig: {
                removePlugins: this.without,
                ckfinder: {
                    uploadUrl:
                        'https://ckeditor.com/apps/ckfinder/3.5.0/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',
                },
                toolbar: [
                    'undo',
                    'redo',
                    '|',
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    '|',
                    'link',
                    'uploadImage',
                    'insertTable',
                    'blockQuote',
                    'mediaEmbed',
                    'htmlEmbed',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'outdent',
                    'indent',
                ],
                plugins: [
                    HtmlEmbed,
                    MediaEmbed,
                    TextTransformation,
                    ListProperties,
                    CKFinder,
                    CKFinderUploadAdapter,
                    Bold,
                    Essentials,
                    CloudServices,
                    Italic,
                    Heading,
                    Paragraph,
                    Link,
                    Image,
                    ImageCaption,
                    EasyImage,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    List,
                    Indent,
                    IndentBlock,
                    BlockQuote,
                    Table,
                    TableToolbar,
                ],
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true,
                    },
                },
            },
        };
    },
    watch: {
        content(newVal) {
            this.$emit('update:modelValue', newVal);
        },
        modelValue(newVal) {
            this.content = newVal;
        },
    },
};
</script>
<style>
.ck.ck-balloon-panel {
    display: none !important;
}
.ck-editor__editable {
    min-height: 60vh !important;
}
.ck-editor__editable > ul,
.ck-editor__editable > ol {
    padding-left: 1.5em;
}
</style>
