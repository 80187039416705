<template>
    <div class="d-flex justify-content-between">
        <div class="img-group">
            <Avatar
                v-for="user in users.filter(onlyUnique).slice(0, max)"
                :key="user.id"
                :name="user.name"
                :url="user.avatar_url"
                :size="size"
                grouped
            />
            <a
                v-if="users.filter(onlyUnique).length >= max"
                class="avatar-box align-self-center"
                :class="'thumb-' + size"
            >
                <span class="avatar-title bg-soft-info rounded-circle font-13 font-weight-normal">
                    +{{ users.filter(onlyUnique).length - max + 1 }}
                </span>
            </a>
        </div>
    </div>
</template>
<script setup>
import Avatar from '@/js/components/Avatar.vue';

defineProps({
    users: {
        type: Array,
        default: () => [],
    },
    size: {
        type: String,
        default: 'sm',
    },
    max: {
        type: Number,
        default: 5,
    },
});

const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index && self.lastIndexOf(value) === index;
};
</script>
