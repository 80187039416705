<template>
    <transition name="backdrop">
        <div v-if="isOpen" class="m-container">
            <div v-if="hasBackdrop && isOpen && isCloseEnable" class="m-backdrop" @click.prevent="close()"></div>
            <slot name="content">
                <div v-if="isOpen" class="m-wrapper buefy-wrapper" :class="modalSize">
                    <div class="m-header" :class="headerClass">
                        <h4>{{ modalTitle }}</h4>
                        <button v-if="isCloseEnable" class="m-close" @click="close()">
                            <i class="mdi mdi-close tw-text-2xl"></i>
                        </button>
                    </div>
                    <div class="m-content" :class="{ 'is-paddingless': isPaddingless }">
                        <slot></slot>
                    </div>
                    <div class="m-footer" :class="footerClass">
                        <slot name="footer"></slot>
                    </div>
                    <LoadingSpinner v-if="isLoading" />
                </div>
            </slot>
        </div>
    </transition>
</template>
<script>
import LoadingSpinner from '../components/widgets/LoadingSpinner.vue';

export default {
    components: {
        LoadingSpinner,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        backdrop: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        paddingless: {
            type: Boolean,
            default: false,
        },
        headerClass: {
            type: String,
            required: false,
        },
        footerClass: {
            type: String,
            required: false,
        },
        closeEnable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['opened', 'closed', 'opening', 'closing'],
    data() {
        return {
            isOpen: false,
            isLoading: false,
            isPaddingless: false,
            hasBackdrop: true,
            modalTitle: null,
            modalSize: null,
            isCloseEnable: this.closeEnable,
        };
    },
    watch: {
        title(newVal) {
            this.modalTitle = newVal;
        },
        backdrop(newVal) {
            this.hasBackdrop = newVal;
        },
        size(newVal) {
            this.modalSize = newVal;
        },
        loading(newVal) {
            this.isLoading = newVal;
        },
        paddingless(newVal) {
            this.isPaddingless = newVal;
        },
        isOpen(newVal) {
            if (newVal) {
                this.$emit('opened');
            } else {
                this.$emit('closed');
            }
        },
        closeEnable(newVal) {
            this.isCloseEnable = newVal;
        },
    },
    mounted() {
        this.modalTitle = this.title;
        this.modalSize = this.size;
        this.hasBackdrop = this.backdrop;
        this.isLoading = this.loading;
        this.isPaddingless = this.paddingless;
    },
    methods: {
        open() {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
            this.$emit('opening');
            this.isOpen = true;
        },
        close() {
            document.body.style.overflow = null;
            document.body.style.paddingRight = null;
            this.$emit('closing');
            this.isOpen = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.m-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5500;

    & > .m-wrapper {
        position: relative;
        z-index: 5500;
        display: flex;
        flex-direction: column;
        width: min(calc(100vw - 30px), 520px);
        max-width: min(calc(100vw - 30px), 520px);

        &.is-small {
            width: min(calc(100vw - 30px), 450px);
            max-width: min(calc(100vw - 30px), 450px);
        }
        &.is-large {
            width: min(calc(100vw - 30px), 525px);
            max-width: min(calc(100vw - 30px), 525px);
        }
        &.is-xlarge {
            width: min(calc(100vw - 30px), 650px);
            max-width: min(calc(100vw - 30px), 650px);
        }
        &.is-xxlarge {
            width: min(calc(100vw - 30px), 800px);
            max-width: min(calc(100vw - 30px), 800px);
        }
        &.is-xxxlarge {
            width: min(calc(100vw - 30px), 960px);
            max-width: min(calc(100vw - 30px), 960px);
        }
        &.is-4xlarge {
            width: min(calc(100vw - 30px), 1200px);
            max-width: min(calc(100vw - 30px), 1200px);
        }
        &.is-fullwidth {
            width: calc(100vw - 100px);
            max-width: calc(100vw - 100px);
        }
        &.is-full {
            width: calc(100vw - 48px);
            max-width: calc(100vw - 48px);
        }

        & > .m-header:not(:empty) {
            background: #eaf0f9;
            padding: 8px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(black, 0.05);

            & > h4 {
                margin: 4px 0 0 10px;
            }

            & > .m-close {
                width: 42px;
                height: 42px;
                border: none;
                background: transparent;
                margin-left: auto;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.1s ease;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }
            }
        }

        & > .m-content {
            background: white;
            padding: 14px;
            border-radius: 0;
            border: none;
            max-height: 80vh;
            overflow-y: auto;

            &.is-paddingless {
                padding: 0;
            }
        }

        & > .m-footer:not(:empty) {
            background: white;
            padding: 10px 15px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: 1px solid rgba(black, 0.05);
            background: rgb(245, 245, 245);
        }
    }

    & > .m-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 5499;
        background: rgba(black, 0.33);
    }
}

.backdrop-enter-active,
.backdrop-leave-active {
    transition: all 0.2s ease;
    opacity: 1;
}
.backdrop-enter-active > .m-wrapper,
.backdrop-leave-active > .m-wrapper {
    transition: all 0.33s ease;
    transform: scale(1);
}
.backdrop-enter,
.backdrop-leave-to {
    opacity: 0;
}
.backdrop-enter > .m-wrapper,
.backdrop-leave-to > .m-wrapper {
    transform: scale(0.7);
}
</style>
