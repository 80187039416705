<template>
    <div v-if="xeroOrganisation" class="buefy-wrapper tw-mt-4">
        <div class="integration-header">
            <div class="mr-auto">
                <h3 class="mb-1">{{ xeroOrganisation.tenant_name }}</h3>
                <template v-if="!xeroOrganisation.reconnection_required">
                    <Tag type="success" class="tw-mr-2">Connected</Tag>
                    <Tag type="info">
                        Last full sync performed
                        {{ xeroOrganisation.last_sync ? 'on ' + formatDate(xeroOrganisation.last_sync) : 'never' }}
                    </Tag>
                </template>
            </div>
            <div class="actions tw-flex tw-gap-2 tw-items-center">
                <Button class="!tw-ml-1 tw-flex tw-gap-2" :loading="isSyncing" @click="resync()">
                    <i class="mdi mdi-sync"></i>
                    Resync
                </Button>
                <Button class="tw-flex tw-gap-2" type="is-danger" :loading="isDisconnecting" @click="disconnect()">
                    <i class="mdi mdi-link-off"></i>
                    Disconnect
                </Button>
            </div>
        </div>
        <div class="integration-content">
            <div v-if="xeroOrganisation.reconnection_required" class="tw-w-1/2 tw-mx-auto tw-grid tw-gap-3">
                <div
                    class="tw-bg-amber-400/50 tw-rounded-md tw-border tw-border-amber-400 tw-px-4 tw-py-3 tw-text-center tw-text-base"
                >
                    Your Xero account is no longer syncing, please reconnect to continue using Xero with Eventwise.
                </div>

                <div class="tw-grid tw-grid-cols-2 tw-gap-6">
                    <Button class="tw-h-12" type="is-danger" :loading="isDisconnecting" @click="disconnect()">
                        <i class="mdi mdi-link-off" />
                        Disconnect
                    </Button>

                    <Button type="is-primary" @click="reconnect">
                        <i class="mdi mdi-link" />
                        Reconnect
                    </Button>
                </div>
                <span class="tw-text-center tw-text-gray-500">Alternatively you can disconnect your Xero account.</span>
            </div>
            <Tabs
                v-else
                :tabs="[
                    {
                        title: 'Settings',
                        name: 'settings',
                    },
                    {
                        title: 'Accounts',
                        name: 'accounts',
                    },
                    {
                        title: 'Bank Accounts',
                        name: 'bank_accounts',
                    },
                    {
                        title: 'Tax Rates',
                        name: 'tax_rates',
                    },
                ]"
            >
                <template #tab(settings)>
                    <div class="row">
                        <div class="max-md:tw-w-full max-md:tw-px-10 md:tw-w-5/12 md:tw-px-10">
                            <h4 class="mb-2">Default Statuses</h4>
                            <FormField label="Sales Invoice">
                                <Select
                                    v-model="xeroOrganisation.config.invoice_status.sales"
                                    class="tw-w-full"
                                    @update:model-value="saveChanges()"
                                >
                                    <option value="DRAFT">Draft</option>
                                    <option value="SUBMITTED">Submitted</option>
                                    <option value="AUTHORISED">Authorised</option>
                                </Select>
                            </FormField>
                            <FormField class="tw-mt-2" label="Purchase Invoice">
                                <Select
                                    v-model="xeroOrganisation.config.invoice_status.purchase"
                                    class="tw-w-full"
                                    @update:model-value="saveChanges()"
                                >
                                    <option value="DRAFT">Draft</option>
                                    <option value="SUBMITTED">Submitted</option>
                                    <option value="AUTHORISED">Authorised</option>
                                </Select>
                            </FormField>
                        </div>

                        <div class="max-md:tw-w-full max-md:tw-px-10 md:tw-w-5/12 md:tw-px-10">
                            <h4 class="mb-2">Tracking Category</h4>
                            <Select
                                v-model="xeroOrganisation.tracking_category"
                                class="tw-w-full tw-mt-[24px]"
                                :loading="isLoadingTrackingCategories"
                                expanded
                                @update:model-value="saveChanges()"
                            >
                                <option
                                    v-for="trackingCategory in trackingCategories"
                                    :key="trackingCategory.TrackingCategoryID"
                                    :value="trackingCategory.TrackingCategoryID"
                                >
                                    {{ trackingCategory.Name }}
                                </option>
                            </Select>
                        </div>
                    </div>
                </template>
                <template #heading(accounts)>
                    Accounts
                    <Tag type="info">{{ accounts.length }}</Tag>
                </template>
                <template #tab(accounts)>
                    <DataTable
                        :columns="[
                            {
                                title: 'Code',
                                field: 'code',
                            },
                            {
                                title: 'Name',
                                field: 'name',
                            },
                            {
                                title: 'Account Type',
                                field: 'type',
                            },
                            {
                                title: 'Tax Type',
                                field: 'tax_type',
                            },
                        ]"
                        :data="accounts"
                    />
                </template>
                <template #heading(bank_accounts)>
                    Bank Accounts
                    <Tag type="info">{{ bankAccounts.length }}</Tag>
                </template>
                <template #tab(bank_accounts)>
                    <DataTable
                        :data="bankAccounts"
                        :columns="[
                            {
                                title: 'Code',
                                field: 'code',
                            },
                            {
                                title: 'Name',
                                field: 'name',
                            },
                            {
                                title: 'Tax Type',
                                field: 'tax_type',
                            },
                        ]"
                    />
                </template>
                <template #heading(tax_rates)>
                    Tax Rates
                    <Tag type="info">{{ taxRates.length }}</Tag>
                </template>
                <template #tab(tax_rates)>
                    <DataTable
                        :data="taxRates"
                        :columns="[
                            {
                                title: 'Name',
                                field: 'name',
                            },
                            {
                                title: 'Tax Type',
                                field: 'tax_type',
                            },
                            {
                                title: 'Rate',
                                field: 'rate',
                            },
                            {
                                title: 'Status',
                                field: 'status',
                            },
                            {
                                title: 'Link',
                                field: 'tax_rate_id',
                            },
                        ]"
                    >
                        <template #column(rate)="{ row }">{{ row.rate }}%</template>
                        <template #column(tax_rate_id)="{ row }">
                            <Button class="tw-flex tw-gap-2" @click="importXeroTaxRate(row)">
                                <i class="mdi mdi-link-variant"></i>
                                {{ row.tax_rates.length ? 'Linked (' + row.tax_rates.length + ')' : 'Link' }}
                            </Button>
                        </template>
                    </DataTable>
                </template>
            </Tabs>
        </div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <Modal ref="linkTaxRateModal" title="Link Tax Rates" paddingless size="is-large">
            <p class="p-3">
                Select the tax rates below that you would like to link with
                {{ linkingTaxRate ? linkingTaxRate.name : '' }}.
            </p>
            <template v-if="linkingTaxRate">
                <div class="row pb-1 mx-0 border-bottom">
                    <div class="col-1"></div>
                    <div class="col-5"><strong>Name</strong></div>
                    <div class="col-3"><strong>Type</strong></div>
                    <div class="col-2 text-right"><strong>Rate</strong></div>
                </div>
                <div
                    v-for="taxRate in Object.values(store.get('account.tax_rates'))"
                    :key="taxRate.id"
                    class="row mx-0 py-1"
                >
                    <div class="col-1 text-right">
                        <Tooltip
                            :disabled="!taxRate.xero_tax_rate_id || taxRate.xero_tax_rate_id === linkingTaxRate.id"
                            label="This tax rate is already linked to another Xero tax rate"
                            position="right"
                        >
                            <input
                                v-model="linkingTaxRates"
                                type="checkbox"
                                :value="taxRate.id"
                                :disabled="taxRate.xero_tax_rate_id && taxRate.xero_tax_rate_id !== linkingTaxRate.id"
                            />
                        </Tooltip>
                    </div>
                    <div class="col-5">{{ taxRate.name }}</div>
                    <div class="col-3">
                        <span v-if="!taxRate.type" class="text-muted">Not set</span>
                        <span v-else>{{ taxRate.type === 'income' ? 'Income' : 'Expenses' }}</span>
                    </div>
                    <div class="col-2 text-right">{{ localise(taxRate.rate, 2, false) }}%</div>
                </div>
            </template>
            <template #footer>
                <Button type="is-success" @click="saveLinkedRates">Save Linked Rates</Button>
            </template>
        </Modal>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../../Modal.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Tag from '@/js/components/Tag.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { formatDate, toast } from '@/js/utils.js';

export default {
    components: {
        LoadingSpinner,
        Modal,
        Button,
        Tag,
        Tabs,
        FormField,
        Select,
        DataTable,
        Tooltip,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isSyncing: false,
            isDisconnecting: false,
            isLoadingTrackingCategories: false,
            xeroOrganisation: store.get('account.xero_organisation'),
            trackingCategories: [],
            linkingTaxRate: null,
            linkingTaxRates: [],
            store: store,
        };
    },
    computed: {
        accounts() {
            if (!this.xeroOrganisation) {
                return [];
            }
            return Object.values(this.xeroOrganisation.xero_accounts).filter(
                (xeroAccount) => xeroAccount.type !== 'BANK'
            );
        },
        bankAccounts() {
            if (!this.xeroOrganisation) {
                return [];
            }
            return Object.values(this.xeroOrganisation.xero_accounts).filter(
                (xeroAccount) => xeroAccount.type === 'BANK'
            );
        },
        taxRates() {
            if (!this.xeroOrganisation) {
                return [];
            }
            return Object.values(this.xeroOrganisation.xero_tax_rates);
        },
    },
    mounted() {
        this.getTrackingCategories();
    },
    methods: {
        formatDate,
        resync() {
            this.isSyncing = true;
            axios.post(route('api.account.xero.resync')).then(() => {
                toast('Resync Requested', 'Please allow a few minutes for your sync to be completed', 'success');
                this.isSyncing = false;
            });
        },
        reconnect() {
            location.href = route('account.xero.reconnect');
        },
        disconnect() {
            this.isDisconnecting = true;
            axios.delete(route('api.account.xero.disconnect')).then(() => {
                toast('Xero Disconnected', 'Your Xero organisation has been disconnected from Eventwise', 'success');
                window.location.reload();
            });
        },
        getTrackingCategories() {
            if (this.xeroOrganisation.reconnection_required) {
                return;
            }

            this.isLoadingTrackingCategories = true;
            axios.get(route('api.account.xero.trackingCategories')).then(({ data }) => {
                this.trackingCategories = data;
                this.isLoadingTrackingCategories = false;
            });
        },
        saveChanges() {
            this.isLoading = true;
            axios.post(route('api.account.xero.update'), this.xeroOrganisation).then(() => {
                this.isLoading = false;
                toast('Xero Settings Updated', 'Your changes have been saved', 'success');
            });
        },
        importXeroTaxRate(xeroTaxRate) {
            this.linkingTaxRate = xeroTaxRate;
            this.linkingTaxRates = xeroTaxRate.tax_rates.map((lr) => lr.id);
            this.$refs.linkTaxRateModal.open();
        },
        saveLinkedRates() {
            if (!this.linkingTaxRate) {
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.xero.taxRates.sync'), {
                    xero_tax_rate_id: this.linkingTaxRate.id,
                    tax_rates: this.linkingTaxRates,
                })
                .then(() => {
                    this.reloadTaxRates();
                    toast('Linked Rates Saved', 'Your linked tax rates have been saved successfully', 'success');
                    this.$refs.linkTaxRateModal.close();
                });
        },
        reloadTaxRates() {
            this.isLoading = true;
            axios.get(route('api.account.xero.taxRates')).then(({ data }) => {
                this.xeroOrganisation.xero_tax_rates = data;
                this.isLoading = false;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.integration-header {
    display: flex;
    align-items: center;
    padding: 20px;
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.integration-content {
    background: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: calc(100vh - 285px);
}
</style>
