<template>
    <LoadingSpinner v-if="isLoading" label="Loading Information" />
    <div v-else class="buefy-wrapper">
        <Banner class="mt-5 mb-4 py-3" type="is-primary">
            <p>
                This report displays the Ticket comparisons across multiple group Tags.
                <br />
                The figures for the events associated with the Group Tag are collected together under that heading.
                <br />
            </p>
        </Banner>
        <div class="card justify-content-start p-3">
            <div>
                <span class="h3 ml-3 my-2">Multi Tag Ticket Report</span>
            </div>
            <div class="mt-2">
                TODO I NEED TO CHECK THIS PAGE
                <DataTable
                    :data="dataTableFigures"
                    centered
                    :columns="[
                        {
                            title: 'Group',
                            field: 'name',
                        },
                        {
                            title: 'Event Count',
                            field: 'eventCount',
                        },
                        {
                            title: 'Tickets Available',
                            field: 'ticketsAvailable',
                        },
                        {
                            title: 'Tickets Sold',
                            field: 'ticketsSold',
                        },
                        {
                            title: 'Tickets left',
                            field: 'ticket_left',
                        },
                        {
                            title: 'Avg Price',
                            field: 'avg_price',
                        },
                    ]"
                >
                    <template #column(name)="{ row }">
                        <GroupTag :tag="row[0]" />
                    </template>

                    <template #column(eventCount)="{ row }">
                        <a href="#" @click="openEditor">{{ row[1] }}</a>
                    </template>
                    <template #column(ticketsAvailable)="{ row }">
                        {{ row[2] }}
                    </template>
                    <template #column(ticketsSold)="{ row }">
                        {{ row[3] }}
                        ({{
                            percentageSold(
                                row[2],

                                row[3]
                            )
                        }}%)
                    </template>
                    <template #column(ticket_left)="{ row }">
                        {{ row[4] }}
                    </template>
                    <template #column(avg_price)="{ row }">
                        {{ localise(row[5], 2, true) }}
                    </template>
                </DataTable>
            </div>
        </div>
        <ModalTagEditor ref="modalTagEditor" />
    </div>
</template>
<script>
import Banner from '../../../../../js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import GroupTag from '@/js/components/GroupTag.vue';
import ModalTagEditor from '@/js/components/modals/GroupTags/ModalTagEditor.vue';
import LoadingSpinner from '@/js/components/widgets/LoadingSpinner.vue';
// import GroupTag from '../../../../../js/components/widgets/';

export default {
    components: {
        LoadingSpinner,
        ModalTagEditor,
        GroupTag,
        DataTable,
        Banner,
    },
    props: {
        // TODO tagIds comes from the Reporting component, it will need to be required
        tagIds: {
            type: Array,
            required: true,
        },
    },
    emits: [],
    data() {
        return {
            isLoading: true,
            eventIds: [],
            eventData: [],
            availableEvents: [],
            filteredEvents: [],
            localTags: [],
            tagCount: 0,
            eventClass: 'App\\Models\\Event',
            allAssignedTags: [],
            multiEventFigures: [],
            dataTableFigures: [],
        };
    },
    computed: {
        dataFiguresHeader() {
            const header = ['Figures'];
            this.localTags.forEach((tag) => {
                header.push(tag.name);
            });
            return header;
        },
    },
    watch: {},
    mounted() {
        this.fetchTags();
    },
    methods: {
        fetchTags() {
            this.isLoading = true;
            this.localTags = [];
            this.profitsAndLosses = [];
            this.multiEventFigures = [];
            this.tagCount = this.tagIds.length;
            // foreach tagId
            for (const tagId of this.tagIds) {
                // get the localTags for the tags
                this.reloadTagEvents(tagId);
            }
            this.isLoading = false;
        },
        reloadTagEvents(tagId) {
            axios
                .get(
                    route('api.account.reporting.tagAnalysis', {
                        groupTag: tagId,
                    })
                )
                .then(({ data }) => {
                    this.localTags.push(data.tag);
                    this.fetchGroupTags(tagId, this.eventClass);
                });
        },
        fetchGroupTags(tagId, tagClass) {
            this.isLoading = true;
            axios
                .post(route('api.account.tags.allAssigned'), { class: tagClass })
                .then(({ data }) => {
                    this.allAssignedTags = data.tags;
                    this.fetchEventsData(this.filterTagEvents(tagId));
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        filterTagEvents(tagId) {
            this.filteredEvents = this.allAssignedTags.filter((item) => item.id === tagId)[0].events;
            return this.filteredEvents.map((item) => item.id);
        },
        fetchEventsData(eventIds) {
            // Fetch the Selected Events within the tag
            this.isLoading = true;
            axios
                .get(route('api.account.events.fetch-events', { eventIds: eventIds }))
                .then(({ data }) => {
                    this.eventData = data;
                    this.filterNoTickets();
                    this.multiEventFigures.push(this.availableEvents);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.processTheData();
                });
        },
        toDateString(dateStr) {
            const date = new Date(dateStr);
            return date.toDateString();
        },
        catchNull(value) {
            if (value == null) {
                return 0;
            }
            return value;
        },
        percentageSold(available, quantity) {
            // check for available and quantity being 0 or null to avoid divide by zero errors
            if (available === 0 || quantity === 0 || available == null || quantity == null) {
                return 0;
            }
            return ((quantity / available) * 100).toFixed(2);
        },
        filterNoTickets(filter = false) {
            // filter against having no tickets
            if (!filter) {
                this.availableEvents = this.eventData;
                return;
            }
            this.availableEvents = this.eventData.filter((event) => event.ticket_sales_sum_tickets_available > 0);
        },
        processTheData() {
            let tagCount = this.multiEventFigures.length;
            this.dataTableFigures = [];
            for (let i = 0; i < tagCount; i++) {
                this.sumTicketSaleFiguresForTag(this.localTags[i].id, this.multiEventFigures[i]);
            }
        },

        sumTicketSaleFiguresForTag(tagId, tagEvents) {
            const eventCount = tagEvents.length;

            const tag = this.tagItem(tagId);
            const ticketsAvailable = tagEvents
                .map((event) => parseInt(this.catchNull(event.ticket_sales_sum_tickets_available)))
                .reduce((c, a) => c + a, 0);
            const ticketsSold = tagEvents
                .map((event) => parseInt(this.catchNull(event.ticket_orders_sum_quantity)))
                .reduce((c, a) => c + a, 0);
            const ticketsLeft = ticketsAvailable - ticketsSold;
            const AvgPrice = tagEvents.map((event) => event.ticketSales_mean).reduce((c, a) => c + a, 0) / eventCount;
            this.dataTableFigures.push([tag, eventCount, ticketsAvailable, ticketsSold, ticketsLeft, AvgPrice]);
        },
        tagItem(tagId) {
            return this.localTags.filter((item) => item.id === tagId)[0];
        },
        openEditor() {
            this.$refs.modalTagEditor.openModal();
        },
    },
};
</script>

<style scoped lang="scss"></style>
