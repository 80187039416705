<template>
    <Page :title="pageTitle">
        <template #submenu>
            <a v-if="mode" href="#" :class="{ active: !currentPage }" @click.prevent="setPage()">Events</a>
            <a v-else href="#" :class="{ active: !currentPage }" @click.prevent="setPage('groupTags')">Group Tags</a>
            <a
                v-if="eventCount && mode"
                href="#"
                :class="{ active: currentPage === 'salesAndCosts' }"
                @click.prevent="setPage('salesAndCosts')"
            >
                Sales & Costs
            </a>
            <a
                v-if="mode"
                href="#"
                :class="{ active: currentPage === 'outstanding' }"
                @click.prevent="setPage('outstanding')"
            >
                Outstanding
            </a>
            <a
                v-if="multiCount && mode"
                href="#"
                :class="{ active: currentPage === 'analysis' }"
                @click.prevent="setPage('analysis')"
            >
                Multi Event Analysis
            </a>
            <a
                v-if="multiCount && !mode"
                href="#"
                :class="{ active: currentPage === 'tagAnalysis' }"
                @click.prevent="setPage('tagAnalysis')"
            >
                Multi Tag Analysis
            </a>
            <a
                v-if="eventIds.length === 1 && mode"
                href="#"
                :class="{ active: currentPage === 'history' }"
                @click.prevent="setPage('history')"
            >
                Historical Report
            </a>
            <a
                v-if="tagIds.length === 1 && !mode"
                href="#"
                :class="{ active: currentPage === 'group-analysis' }"
                @click.prevent="setPage('group-analysis')"
            >
                Group Analysis
            </a>
            <a
                v-if="eventCount && mode"
                href="#"
                :class="{ active: currentPage === 'tickets' }"
                @click.prevent="setPage('tickets')"
            >
                Event Ticket Report
            </a>
            <a
                v-if="tagIds.length === 1 && !mode"
                href="#"
                :class="{ active: currentPage === 'groupTagTickets' }"
                @click.prevent="setPage('groupTagTickets')"
            >
                Group Tag Tickets
            </a>
            <a
                v-if="multiCount && !mode"
                href="#"
                :class="{ active: currentPage === 'multiTagTickets' }"
                @click.prevent="setPage('multiTagTickets')"
            >
                Multi Tag Tickets
            </a>
        </template>

        <EventSelector
            v-if="!currentPage"
            :event-ids="eventIds"
            :tag-ids="tagIds"
            :mode="currentMode"
            @eventselected="(value) => (eventIds = value)"
            @tagselected="(value) => (tagIds = value)"
            @mode="(value) => (currentMode = value)"
        />
        <OutstandingReport v-else-if="currentPage === 'outstanding'" :event-ids="eventIds" />
        <HistoryReport v-else-if="currentPage === 'history'" :events="eventIds" />
        <SalesAndCosts v-else-if="currentPage === 'salesAndCosts'" :event-ids="eventIds" />
        <TicketReport v-else-if="currentPage === 'tickets'" :event-ids="eventIds" />
        <MultiEventReport
            v-else-if="['analysis', 'group-analysis'].includes(currentPage)"
            :event-ids="eventIds"
            :mode="currentMode"
            :tag-ids="tagIds"
        />
        <TagAnalysis v-else-if="currentPage === 'tagAnalysis'" :tag-ids="tagIds" :mode="currentMode" />
        <GroupTagTickets v-else-if="currentPage === 'groupTagTickets'" :tag-ids="tagIds" />
        <MultiTagTickets v-else-if="currentPage === 'multiTagTickets'" :tag-ids="tagIds" />
    </Page>
</template>
<script>
import Page from '../../Page.vue';
import EventSelector from './Reporting/EventSelector.vue';
import MultiEventReport from './Reporting/Analysis.vue';
import OutstandingReport from './Reporting/Outstanding.vue';
import SalesAndCosts from './Reporting/SalesAndCosts.vue';
import HistoryReport from './Reporting/History.vue';
import TicketReport from './Reporting/TicketReport.vue';
import TagAnalysis from '@/js/components/pages/Account/Reporting/TagAnalysis.vue';
import GroupTagTickets from '@/js/components/pages/Account/Reporting/GroupTagTickets.vue';
import MultiTagTickets from '@/js/components/pages/Account/Reporting/MultiTagTickets.vue';
import { setRoute } from '@/js/utils.js';

export default {
    components: {
        GroupTagTickets,
        Page,
        EventSelector,
        MultiEventReport,
        OutstandingReport,
        HistoryReport,
        SalesAndCosts,
        TicketReport,
        TagAnalysis,
        MultiTagTickets,
    },
    data() {
        return {
            isLoading: true,
            currentPage: route().params.page ?? null,
            pageTitle: 'Reporting',
            eventIds: [],
            tagIds: [],
            currentMode: 'events',
        };
    },
    computed: {
        eventCount() {
            if (this.currentMode === 'events') {
                return this.eventIds.length > 0;
            }
            return this.tagIds.length > 0;
        },
        multiCount() {
            if (this.currentMode === 'events') {
                return this.eventIds.length > 1;
            }
            return this.tagIds.length > 1;
        },
        mode() {
            return this.currentMode === 'events';
        },
    },
    mounted() {
        this.handlePageChange();
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    methods: {
        handlePageChange() {
            this.currentPage = route().params.page ?? null;
        },
        setPage(page = '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.currentMode = [
                'group-analysis',
                'tagAnalysis',
                'groupTags',
                'groupTagTickets',
                'multiTagTickets',
            ].includes(page)
                ? 'groups'
                : 'events';
            page = page === 'groupTags' ? '' : page;
            setRoute('account.reporting', {
                page: page,
            });
            this.currentPage = page;
        },
    },
};
</script>
<style lang="scss" scoped>
.reporting-options-row {
    display: flex;
    margin-top: 10px;
}

.report-table {
    max-width: calc(100vw - 115px);
    overflow: hidden;

    .table {
        border-radius: 5px;
        overflow-x: auto;

        .selectable {
            cursor: pointer;
            user-select: none;

            i.mdi {
                transform: scale(1.33);
            }

            &:hover {
                background: rgba(black, 0.025);
            }
        }

        .start-row {
            border-top: 2px solid rgba(black, 0.2);
        }
        .end-row {
            border-bottom: 2px solid rgba(black, 0.2);
        }
        .selected {
            background: rgba(black, 0.025);
        }

        &:has(.sticky-column) {
            padding-left: 239px;
            border-right: 1px solid rgba(black, 0.1);

            thead > tr:has(.sticky-column),
            tbody > tr:has(.sticky-column) {
                .sticky-column {
                    position: absolute;
                    top: auto;
                    left: 0;
                    width: 240px;
                    background: white;
                    transform: translateY(1px);
                    border-right: 1px solid rgba(black, 0.1);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.noborder {
                        border-right: none;
                    }
                }
            }
        }
    }
}
</style>
