<template>
    <ew-card title="Event Profit Overview" paddingless :expanded="true" :loading="isLoading" :max-height="400">
        <template #actions>
            <EntryDisplayControls v-if="!isLoading" type="combined" class="mt-1 mr-3" @changed="controlKey()" />
        </template>

        <div class="block-table position-relative" :style="'height: ' + height">
            <!-- header row -->
            <div class="block-table-row tw-max-h-[30px]">
                <div class="heading-y"></div>
                <div v-for="label in typeLabels" :key="label" class="heading-x">
                    {{ label }}
                </div>
            </div>

            <!-- Revenue Row -->
            <div class="block-table-row">
                <div class="heading-y">Revenue</div>
                <p class="data-cell" :style="includeEstimated ? 'background-color: #7BDFE1 !important;' : ''">
                    <span v-if="!isLoading" class="profit-overview-accounting" :class="{ negative: fixedRevenue < 0 }">
                        {{ localise(fixedRevenue, 2, true) }}
                    </span>
                </p>
                <p class="data-cell">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{ negative: budgetedRevenue < 0 }"
                    >
                        {{ localise(budgetedRevenue, 2, true) }}
                    </span>
                </p>
                <p class="data-cell">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{
                            negative: revenueVariance < 0,
                        }"
                    >
                        {{ localise(revenueVariance, 2, true) }}
                    </span>
                </p>
            </div>

            <!-- Costs Row -->
            <div class="block-table-row">
                <div class="heading-y">Costs</div>
                <p class="data-cell" :style="includeEstimated ? 'background-color: #7BDFE1 !important;' : ''">
                    <span v-if="!isLoading" class="profit-overview-accounting" :class="{ negative: fixedCosts < 0 }">
                        {{ localise(fixedCosts, 2, true) }}
                    </span>
                </p>
                <p class="data-cell">
                    <span v-if="!isLoading" class="profit-overview-accounting" :class="{ negative: budgetedCosts < 0 }">
                        {{ localise(budgetedCosts, 2, true) }}
                    </span>
                </p>
                <p class="data-cell">
                    <span v-if="!isLoading" class="profit-overview-accounting" :class="{ negative: costVariance < 0 }">
                        {{ localise(costVariance, 2, true) }}
                    </span>
                </p>
            </div>

            <!-- Profits Row -->
            <div class="block-table-row">
                <div class="heading-y">Profit</div>
                <p class="data-cell" :style="includeEstimated ? 'background-color: #7BDFE1 !important;' : ''">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{
                            negative: estimatedProfit < 0,
                        }"
                    >
                        {{ localise(estimatedProfit, 2, true) }}
                    </span>
                </p>
                <p class="data-cell">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{
                            negative: budgetedProfit < 0,
                        }"
                    >
                        {{ localise(budgetedProfit, 2, true) }}
                    </span>
                </p>
                <p class="data-cell">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{
                            negative: profitVariance < 0,
                        }"
                    >
                        {{ localise(profitVariance, 2, true) }}
                    </span>
                </p>
            </div>

            <!-- Margins Row -->
            <div v-if="includePercentages" class="block-table-row">
                <div class="heading-y !tw-leading-4">
                    Gross
                    <br />
                    Margin
                </div>
                <p class="data-cell" :style="includeEstimated ? 'background-color: #7BDFE1 !important;' : ''">
                    <span v-if="!isLoading" class="profit-overview-accounting">
                        {{ localise(fixedMargin, 2, false) }}%
                    </span>
                </p>
                <p class="data-cell">
                    <span v-if="!isLoading" class="profit-overview-accounting">
                        {{ localise(budgetedMargin, 2, false) }}%
                    </span>
                </p>
                <p class="data-cell">
                    <span v-if="!isLoading" class="profit-overview-accounting">
                        {{ localise(budgetedMargin - fixedMargin, 2, false) }}%
                    </span>
                </p>
            </div>

            <div
                v-if="chartData && chartData['last_updated']"
                class="tw-flex tw-items-end tw-text-xs tw-pl-7 tw-mb-3 tw-mt-1"
            >
                <div class="tw-text-gray-400 !tw-text-left">Last updated {{ timeAgo(chartData['last_updated']) }}</div>
                <div class="tw-flex tw-flex-col tw-gap-1">
                    <Toggle
                        v-if="!store.get('current_scenario') && !vActuals"
                        v-model="includePercentages"
                        name="include_percentages"
                        class="tw-ml-auto"
                        :disabled="isLoading"
                        label="Show gross margin"
                        label-left
                        @update:model-value="reloadData()"
                    />
                    <Toggle
                        v-if="!store.get('current_scenario') && !vActuals"
                        v-model="includeEstimated"
                        name="include_estimated"
                        class="tw-ml-auto"
                        :disabled="isLoading"
                        label="Show manual estimates"
                        label-left
                        @update:model-value="reloadData()"
                    />
                </div>
            </div>
        </div>
    </ew-card>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import EntryDisplayControls from '@/js/components/widgets/EntryDisplayControls.vue';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import Toggle from '@/js/components/Toggle.vue';
import { timeAgo, localise } from '@/js/utils.js';

export default {
    components: {
        EntryDisplayControls,
        Toggle,
    },
    props: {
        height: {
            type: String,
        },
        labels: {
            type: Array,
            default: () => [],
        },
        vActuals: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const store = useDataStore();
        const settings = useSettingsStore();
        return {
            isLoading: true,
            includeEstimated: false,
            includePercentages: false,
            chartData: [],
            typeLabels: [],
            currentEvent: 0,
            store: store,
            settings: settings,
            keyFlag: false,
        };
    },
    computed: {
        fixedRevenue() {
            switch (true) {
                case this.includeEstimated:
                    return this.chartData['streamInitialFixed'];
                case !!this.store.get('current_scenario.id'):
                    return (
                        this.chartData['budgetedRevenue'] +
                        this.store.get('current_scenario.total_revenue_adjustment_value')
                    );
                case this.vActuals:
                    return this.chartData['budgetedRevenue'];
                default:
                    return this.chartData['fixedRevenue'];
            }
        },
        fixedCosts() {
            switch (true) {
                case this.includeEstimated:
                    return this.chartData['costInitialFixed'];
                case !!this.store.get('current_scenario.id'):
                    return (
                        this.chartData['budgetedCosts'] + this.store.get('current_scenario.total_cost_adjustment_value')
                    );
                case this.vActuals:
                    return this.chartData['budgetedCosts'];
                default:
                    return this.chartData['fixedCosts'];
            }
        },
        estimatedProfit() {
            const currentScenario = this.store.get('current_scenario');
            switch (true) {
                case this.includeEstimated:
                    return this.chartData['streamInitialFixed'] - this.chartData['costInitialFixed'];
                case !!currentScenario:
                    return (
                        this.chartData['budgetedRevenue'] +
                        currentScenario.total_revenue_adjustment_value -
                        (this.chartData['budgetedCosts'] + currentScenario.total_cost_adjustment_value)
                    );
                case this.vActuals:
                    return this.chartData['budgetedRevenue'] - this.chartData['budgetedCosts'];
                default:
                    return this.chartData['fixedRevenue'] - this.chartData['fixedCosts'];
            }
        },
        budgetedRevenue() {
            switch (true) {
                case this.vActuals:
                    return this.chartData['actualRevenue'];
                default:
                    return this.chartData['budgetedRevenue'];
            }
        },
        budgetedCosts() {
            switch (true) {
                case this.vActuals:
                    return this.chartData['actualCosts'];
                default:
                    return this.chartData['budgetedCosts'];
            }
        },
        budgetedProfit() {
            return this.budgetedRevenue - this.budgetedCosts;
        },
        revenueVariance() {
            return this.budgetedRevenue - this.fixedRevenue;
        },
        costVariance() {
            return this.fixedCosts - this.budgetedCosts;
        },
        profitVariance() {
            return this.budgetedProfit - this.estimatedProfit;
        },
        fixedMargin() {
            return this.fixedRevenue ? (this.estimatedProfit / this.fixedRevenue) * 100 : 0;
        },
        budgetedMargin() {
            return this.budgetedRevenue ? (this.budgetedProfit / this.budgetedRevenue) * 100 : 0;
        },
    },
    watch: {
        includeEstimated(newVal) {
            Eventbus.$emit('includeEstimated', newVal);
        },
    },
    mounted() {
        this.reloadData();
        this.resetControls();
        Eventbus.$on('forceUpdate:profit-overview', () => this.reloadData(true));
        Eventbus.$on('eventRefetched', () => this.reloadData(true));
    },
    methods: {
        localise,
        timeAgo,
        reloadData(forceUpdate = false) {
            this.isLoading = true;
            this.typeLabels = this.labels && this.labels.length ? this.labels : ['Fixed', 'Budgeted', 'Variance'];

            if (!!this.store.get('current_scenario')) {
                this.typeLabels[0] = 'Scenario';
            }
            const scenario = this.store.get('current_scenario.id');

            axios
                .get(
                    route('api.account.event.chartData.profitOverview', {
                        includeEstimated: this.includeEstimated,
                        scenario: scenario ? scenario : null,
                        forceUpdate: forceUpdate,
                    })
                )
                .then(({ data }) => {
                    this.chartData = data;
                    this.isLoading = false;
                });
        },
        controlKey() {
            if (this.isLoading === true) {
                return;
            }
            let keyStr = '';
            this.keyFlag = false;
            this.chartData['budgetedRevenue'] = 0;
            this.chartData['budgetedCosts'] = 0;
            this.chartData['fixedRevenue'] = 0;
            this.chartData['fixedCosts'] = 0;

            Object.keys(this.settings.get(`budgetFigures.combined`)).forEach((key) => {
                keyStr = this.settings.get(`budgetFigures.combined.${key}`) ? JSON.stringify(key) : '';
                switch (keyStr) {
                    case '"draft"':
                        this.chartData['budgetedRevenue'] += this.chartData['budgetedRevenueDraft'];
                        this.chartData['budgetedCosts'] += this.chartData['budgetedCostsDraft'];
                        this.chartData['fixedRevenue'] += this.chartData['fixedRevenueDraft'];
                        this.chartData['fixedCosts'] += this.chartData['fixedCostsDraft'];
                        this.keyFlag = true;
                        break;
                    case '"approvals"':
                        this.chartData['budgetedRevenue'] += this.chartData['budgetedRevenueApprovals'];
                        this.chartData['budgetedCosts'] += this.chartData['budgetedCostsApprovals'];
                        this.chartData['fixedRevenue'] += this.chartData['fixedRevenueApprovals'];
                        this.chartData['fixedCosts'] += this.chartData['fixedCostsApprovals'];
                        this.keyFlag = true;
                        break;
                    case '"approved"':
                        this.chartData['budgetedRevenue'] += this.chartData['budgetedRevenueApproved'];
                        this.chartData['budgetedCosts'] += this.chartData['budgetedCostsApproved'];
                        this.chartData['fixedRevenue'] += this.chartData['fixedRevenueApproved'];
                        this.chartData['fixedCosts'] += this.chartData['fixedCostsApproved'];
                        this.keyFlag = true;
                        break;
                    default:
                }
            });
            if (this.keyFlag === false) {
                this.settings.set(`budgetFigures.combined.approved`, true);
                this.chartData['budgetedRevenue'] += this.chartData['budgetedRevenueApproved'];
                this.chartData['budgetedCosts'] += this.chartData['budgetedCostsApproved'];
                this.chartData['fixedRevenue'] += this.chartData['fixedRevenueApproved'];
                this.chartData['fixedCosts'] += this.chartData['fixedCostsApproved'];
            }
        },
        resetControls() {
            this.settings.set(`budgetFigures.combined.draft`, false);
            this.settings.set(`budgetFigures.combined.approvals`, false);
            this.settings.set(`budgetFigures.combined.approved`, true);
        },
    },
};
</script>
