<template>
    <Modal
        ref="modal"
        title="Restore Subcategories"
        :loading="isLoading"
        paddingless
        size="is-xxlarge"
        @closed="resetData()"
    >
        <p class="px-3 pt-3 pb-2">
            Here you can restore any previously deleted subcategories within the currently selected department.
        </p>
        <!-- eslint-disable -->
        <DataTable
            :columns="[
                { title: 'Name', field: 'name' },
                { title: 'Events', field: 'events_count' },
                { title: 'Users', field: 'users_count' },
                { title: '', field: 'deleted_on' },
            ]"
            v-model="selectedCategories"
            :data="deletedCategories"
            checkable
        >
            <template #column(name)="{ row }">
                {{ row.name }}
            </template>
            <template #column(events_count)="{ row }">
                {{ row.events_count }}
            </template>
            <template #column(users_count)="{ row }">
                {{ row.users_count }}
            </template>
            <template #column(deleted_on)="{ row }">
                Deleted on {{ DateTime.fromISO(row.deleted_at).toLocaleString(DateTime.DATE_MED) }}
                <template v-if="row.deleted_by">by {{ row.deleted_by.name }}</template>
            </template>
        </DataTable>
        <template #footer>
            <Button type="is-success" :disabled="!selectedCategories.length" @click="restoreCategories()">
                Restore
                {{ selectedCategories.length === 1 ? '1 Subcategory' : selectedCategories.length + ' Subcategories' }}
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        DataTable,
        Modal,
        Button,
    },
    emits: ['restored'],
    data() {
        return {
            isLoading: false,
            departmentId: null,
            deletedCategories: [],
            selectedCategories: [],
            DateTime,
        };
    },
    methods: {
        resetData() {
            this.deletedSubcategories = [];
            this.selectedCategories = [];
            this.isLoading = false;
        },
        getDeletedCategories(departmentId) {
            this.isLoading = true;
            this.departmentId = departmentId;
            this.$refs.modal.open();
            axios
                .get(
                    route('api.account.departments.categories.deleted', {
                        department: this.departmentId,
                    })
                )
                .then((response) => {
                    this.deletedCategories = response.data;
                })
                .finally(() => (this.isLoading = false));
        },
        restoreCategories() {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.departments.categories.restore', {
                        department: this.departmentId,
                    }),
                    {
                        categories: this.selectedCategories.map((c) => c.id),
                    }
                )
                .then(() => {
                    toast(
                        'Subcategories Restored',
                        'The selected subcategories have been restored successfully',
                        'success'
                    );
                    this.$emit('restored');
                    this.$refs.modal.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
