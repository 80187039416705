<template>
    <div>
        <ObjectList
            type="revenueStream"
            :data="Object.values(store.get('current_event.revenue_streams'))"
            selectable
            :columns="['Budgeted', 'Actual', 'Variance', 'Invoiced', 'Outstanding', 'Scenario Variance', 'Scenario']"
            @selected="selectItem"
        />
        <ObjectList
            type="costDepartment"
            :data="Object.values(store.get('current_event.cost_departments'))"
            selectable
            :columns="['Budgeted', 'Actual', 'Variance', 'Invoiced', 'Outstanding', 'Scenario Variance', 'Scenario']"
            @selected="selectItem"
        />
    </div>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import ObjectList from '../../../../../js/components/widgets/ObjectList.vue';

export default {
    components: {
        ObjectList,
    },
    emits: ['selected'],
    data() {
        const store = useDataStore();
        return {
            store,
        };
    },
    methods: {
        selectItem(payload) {
            this.$emit('selected', payload);
        },
    },
};
</script>
