<template>
    <div class="tw-flex tw-items-center">
        <div class="float-left icon-preview">
            <i :class="selectedClass"></i>
        </div>
        <Select v-model="model" class="" placeholder="Select Icon">
            <optgroup v-for="(groupIcons, group) in icons" :key="group" :label="group">
                <option v-for="icon in groupIcons" :key="icon.name" :value="icon.value" :data-class="icon.path">
                    {{ icon.value }}
                </option>
            </optgroup>
        </Select>
    </div>
</template>
<script setup>
import { useDataStore } from '@/js/stores/DataStore.js';
import Select from '@/js/components/controls/Select.vue';
import { computed } from 'vue';

const store = useDataStore();
const model = defineModel({
    type: String,
});
const icons = Object.groupBy(store.get('icons'), ({ type }) => type);
const selectedClass = computed(() => store.get('icons').find((i) => i.value === model.value)?.path);
</script>

<style lang="scss" scoped>
.icon-preview {
    width: 30px;
    height: 30px;
    font-size: 151%;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
</style>
