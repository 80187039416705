<template>
    <Drawer ref="drawer" title="New Revenue Entry" :loading="!isLoaded" size="is-large" @drawer-closing="closeModal()">
        <div class="tw-px-4 tw-py-3 tw-grid tw-divide-y [&>*]:tw-py-4">
            <FormField label="Title">
                <Textbox v-model="formData.name" required />
            </FormField>

            <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                <FormField label="Revenue Stream">
                    <Selector v-model="revenueStreamId" :options="availableRevenueStreams" />
                </FormField>
                <FormField v-if="revenueStreamId" label="Subcategory">
                    <Selector v-model="categoryId" :options="availableCategories" />
                </FormField>
            </div>

            <template v-if="categoryId">
                <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                    <FormField label="Customer">
                        <SupplierSelector v-model="formData.supplier_id" object-label="customer" />
                    </FormField>
                    <FormField label="Budget Entry (optional)">
                        <modal-selector
                            v-model="formData['budget_entry_id']"
                            :endpoint="
                                route('api.account.event.entries', {
                                    category_id: categoryId,
                                })
                            "
                            modal-title="Link Budget Entry"
                            button-title="Link a Budget Entry"
                            allow-search
                        />
                    </FormField>
                </div>

                <FormField label="Line Items">
                    <LineItemBuilder
                        v-model="formData.line_items"
                        tax-rate-type="income"
                        :default-tax-rate-id="selectedRevenueStream.default_tax_rate_id"
                    />
                </FormField>
            </template>
        </div>
        <template #footer>
            <div class="tw-flex tw-justify-end">
                <Button type="is-primary" :loading="!isLoaded" :disabled="!isValid" @click="submitEntry()">
                    Create Revenue Entry
                </Button>
            </div>
        </template>
    </Drawer>
</template>
<script>
import Selector from '../../../../../js/components/controls/Selector.vue';
import SupplierSelector from '../../../../../js/components/controls/SupplierSelector.vue';
import LineItemBuilder from '../../../../../js/components/widgets/LineItemBuilder.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Drawer from '../../../../../js/components/Drawer.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Selector,
        Drawer,
        SupplierSelector,
        LineItemBuilder,
        Button,
        FormField,
        Textbox,
    },
    data() {
        const store = useDataStore();
        return {
            isLoaded: false,
            availableRevenueStreams: Object.values(store.get('current_event.revenue_streams')).filter(
                (r) => r.stream_type !== 0
            ),
            revenueStreamId: null,
            categoryId: null,
            availableCategories: [],
            formData: {
                line_items: [],
                supplier_id: null,
                budget_entry_id: null,
            },
            store: store,
        };
    },
    computed: {
        isValid() {
            return (
                this.categoryId &&
                this.formData.name &&
                !!this.formData.line_items.filter(
                    (lineItem) => lineItem.description && lineItem.quantity && lineItem.unit_price
                ).length
            );
        },
        selectedRevenueStream() {
            if (!this.revenueStreamId) {
                return null;
            }
            return this.availableRevenueStreams.find((r) => r.id === this.revenueStreamId);
        },
    },
    watch: {
        revenueStreamId(newVal) {
            this.categoryId = null;
            this.formData.revenue_stream_id = newVal;
            this.formData.line_items = [
                {
                    description: '',
                    quantity: 0,
                    unit_price: 0,
                    tax_rate_id: this.selectedRevenueStream.default_tax_rate_id ?? null,
                },
            ];
            this.reloadCategories();
        },
        categoryId(newVal) {
            this.formData.category_id = newVal;
        },
    },
    mounted() {
        Eventbus.$on('revenue:create', (payload) => {
            this.resetForm();
            if (payload?.stream) {
                this.formData.revenue_stream_id = payload.stream;
                this.revenueStreamId = payload.stream;
                this.formData.revenue_stream_id = payload.stream;
                this.reloadCategories();
            }
            this.isLoaded = true;
            this.$refs.drawer.open();
        });
    },
    methods: {
        closeModal() {
            this.isLoaded = false;
            Eventbus.$emit('reload:streamlist');
            this.$refs.drawer.close();
        },
        resetForm() {
            this.formData = {
                line_items: [],
                name: '',
                category_id: null,
                supplier_id: null,
            };
            this.categoryId = null;
        },
        submitEntry() {
            if (!this.isValid) {
                toast('Error', 'Please check you have entered all required fields and try to submit again', 'error');
                return;
            }
            // prevent the submit if category_id is missing
            this.isLoaded = false;
            axios
                .post(route('api.account.event.revenue.create'), this.formData)
                .then(({ data }) => {
                    if (this.selectedRevenueStream) {
                        Eventbus.$emit('reload:entries', 'department_' + this.selectedRevenueStream.department_id);
                        Eventbus.$emit('reload:entries', 'category_' + this.categoryId);
                    } else {
                        Eventbus.$emit('reload:entries');
                    }
                    Eventbus.$emit('reload:stream', {
                        stream: this.formData.revenue_stream_id,
                    });
                    this.resetForm();
                    Eventbus.$emit('revenue:entry', {
                        entry: data.id,
                    });
                    this.closeModal();
                })
                .finally(() => (this.isLoaded = true));
        },
        reloadCategories() {
            const revenueStream = this.availableRevenueStreams.find(
                (revenueStream) => revenueStream.id === this.revenueStreamId
            );
            if (!revenueStream) {
                return;
            }
            this.availableCategories = Object.values(this.store.get('current_event.categories')).filter(
                (category) => category.department_id === revenueStream.department_id && !category.is_pooled
            );
        },
    },
};
</script>
<style lang="scss" scoped>
.actions-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
</style>
