<template>
    <div class="ticket-type-builder">
        <div class="ticket-type-builder-row is-header">
            <div>Name</div>
            <div v-if="revenueStreams.length" class="stream-column">Revenue Stream</div>
            <div v-if="categories.length" class="stream-column">Ticket Category</div>
            <div class="price-column">Ticket Price</div>
            <div class="grouped-header">
                <div>Direct Sales</div>
                <div class="subheaders">
                    <div>Allocated</div>
                    <div>Rebate</div>
                </div>
            </div>
            <div v-for="(ticketSeller, index) in ticketSellers" :key="index" class="grouped-header">
                <div>
                    <EditableText v-model="ticketSellers[index]" :placeholder="'Ticket Seller ' + (index + 1)" />
                    <a href="#" class="text-danger" @click.prevent="removeTicketSeller(index)">
                        <i class="mdi mdi-close"></i>
                    </a>
                </div>
                <div class="subheaders">
                    <div>Allocated</div>
                    <div>Rebate</div>
                </div>
            </div>
            <div class="action-column buefy-wrapper">
                <Tooltip position="left" label="Add Ticket Seller">
                    <a href="#" @click.prevent="addTicketSeller()">
                        <i class="mdi mdi-plus-circle-outline"></i>
                    </a>
                </Tooltip>
            </div>
        </div>
        <div v-for="(ticketType, index) in ticketTypes" :key="index" class="ticket-type-builder-row">
            <div>
                <EditableText v-model="ticketType.name" placeholder="Required" />
            </div>
            <div v-if="revenueStreams.length" class="stream-column">
                <template v-if="revenueStreams.length === 1">
                    {{ revenueStreams[0].name }}
                </template>
                <template v-else>
                    <Selector
                        v-model="ticketType.revenue_stream_id"
                        :options="JSON.parse(JSON.stringify(revenueStreams))"
                    />
                </template>
            </div>
            <div v-if="categories.length" class="category-column">
                <Selector
                    v-model="ticketType.category_id"
                    :options="JSON.parse(JSON.stringify(categories))"
                    input-class="is-small"
                    placeholder="Required"
                />
            </div>

            <div class="price-column">
                <EditableText v-model="ticketType.ticket_price" type="currency" :min-value="0" placeholder="Required" />
            </div>
            <div class="grouped-header">
                <div class="subheaders">
                    <div>
                        <EditableText
                            v-model="ticketType.ticket_sales[0].allocated"
                            type="number"
                            :min-value="0"
                            placeholder="Required"
                        />
                    </div>
                    <div>
                        <PercentageValue
                            v-if="Auth.can('manage tickets')"
                            v-model="ticketType.ticket_sales[0].kickback_cost"
                            :base-value="ticketType.ticket_price"
                            :currency="true"
                            :step="0.0001"
                            size="is-small"
                            @update:model-value="(value) => (ticketType.ticket_sales[0].kickback_cost = value)"
                        />
                    </div>
                </div>
            </div>
            <div
                v-for="(ticketSeller, ticketSellerIndex) in ticketSellers"
                :key="`type_${index}_seller_${ticketSellerIndex}`"
                class="grouped-header"
            >
                <div class="subheaders">
                    <div>
                        <EditableText
                            v-model="ticketType.ticket_sales[ticketSellerIndex + 1].allocated"
                            type="number"
                            :min-value="0"
                            placeholder="Required"
                        />
                    </div>
                    <div>
                        <PercentageValue
                            v-if="Auth.can('manage tickets')"
                            v-model="ticketType.ticket_sales[ticketSellerIndex + 1].kickback_cost"
                            :base-value="ticketType.ticket_price"
                            :currency="true"
                            :step="0.0001"
                            size="is-small"
                            @update:model-value="
                                (value) => (ticketType.ticket_sales[ticketSellerIndex + 1].kickback_cost = value)
                            "
                        />
                    </div>
                </div>
            </div>
            <div class="action-column condensed">
                <a href="#" @click.prevent="removeTicketType(index)">
                    <i class="mdi mdi-close"></i>
                </a>
            </div>
        </div>
        <div class="ticket-type-builder-row">
            <div>
                <EditableText
                    v-model="newTicketTypeName"
                    placeholder="Add another ticket type.."
                    :bordered="false"
                    @update:model-value="addNewTicketType"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import EditableText from '../controls/EditableText.vue';
import Selector from '../controls/Selector.vue';
import PercentageValue from './PercentageValue.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        PercentageValue,
        EditableText,
        Selector,
        Tooltip,
    },
    emits: ['update:modelValue'],
    data() {
        const store = useDataStore();
        return {
            ticketTypes: [],
            ticketSellers: [],
            newTicketTypeName: '',
            revenueStreams: Object.values(store.get('current_event.revenue_streams')).filter(
                (r) => r.stream_type === 0
            ),
            categories: Object.values(store.get('current_event.categories')).filter((c) => c.stream_type === 0),
            store: store,
        };
    },
    watch: {
        ticketTypes: {
            handler(newVal) {
                this.$emit(
                    'update:modelValue',
                    JSON.parse(
                        JSON.stringify({
                            tickets: newVal,
                            sellers: this.ticketSellers,
                        })
                    )
                );
            },
            deep: true,
        },
        ticketSellers(newVal) {
            this.$emit(
                'update:modelValue',
                JSON.parse(
                    JSON.stringify({
                        tickets: this.ticketTypes,
                        sellers: newVal,
                    })
                )
            );
        },
    },
    methods: {
        addNewTicketType: debounce(function (name) {
            const ticketSales = [
                {
                    allocated: 0,
                    kickback_cost: 0,
                },
            ];
            this.ticketSellers.forEach(() =>
                ticketSales.push({
                    allocated: 0,
                    kickback_cost: 0,
                })
            );
            this.ticketTypes.push({
                name: name,
                ticket_price: 0,
                revenue_stream_id: this.revenueStreams.length === 1 ? this.revenueStreams[0].id : null,
                category_id: this.categories.length === 1 ? this.categories[0].id : null,
                ticket_sales: ticketSales,
            });
            this.newTicketTypeName = '';
        }, 500),
        removeTicketType(index) {
            const ticketType = this.ticketTypes[index];
            if (!ticketType) {
                return;
            }
            swal.fire({
                title: 'Remove Ticket',
                html: `Are you sure you want to remove ${ticketType.name} from your event?`,
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.ticketTypes.splice(index, 1);
            });
        },
        addTicketSeller() {
            if (this.ticketSellers.length > 1) {
                swal.fire('Add Sales Channel', 'You can add more ticket sellers after creating your first tickets.');
                return;
            }
            this.ticketSellers.push('');
            this.ticketTypes.forEach((ticketType, index) => {
                this.ticketTypes[index].ticket_sales.push({
                    allocated: 0,
                    kickback_cost: 0,
                });
            });
        },
        removeTicketSeller(index) {
            const ticketSeller = this.ticketSellers[index];
            swal.fire({
                title: 'Remove Ticket',
                html: `Are you sure you want to remove ${
                    ticketSeller ? ticketSeller : 'this ticket seller'
                } from your event?`,
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.ticketSellers.splice(index, 1);
                this.ticketTypes.forEach((ticketType, ticketTypeIndex) => {
                    this.ticketTypes[ticketTypeIndex].ticket_sales.splice(index + 1, 1);
                });
            });
        },
    },
};
</script>
<style lang="scss">
.ticket-type-builder {
    & > .ticket-type-builder-row {
        display: flex;
        align-items: center;
        padding: 0 10px;

        &.is-header {
            align-items: flex-end;
            padding: 0 10px;
        }

        &:first-child {
            flex: 1;
        }

        & > div {
            padding: 4px 0;

            &:first-child {
                flex: 1;
            }
            &:not(:first-child) {
                border-left: 1px solid rgba(black, 0.05);
            }
            &.stream-column {
                min-width: 200px;
                text-align: left;
                padding-left: 12px;
            }
            &.category-column {
                min-width: 200px;
                text-align: left;
                padding: 10px 12px 0 12px;
                height: 40px;
            }
            &.price-column {
                min-width: 120px;
                text-align: center;
            }
            &.action-column {
                font-size: 18px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    display: block;
                    color: rgba(black, 0.33);
                    transform: scale(1);
                    transition: all 0.1s ease;

                    &:hover {
                        color: rgba(black, 0.55);
                        transform: scale(1.15);
                    }
                }

                &.condensed {
                    height: unset;
                }
            }
        }

        & > .grouped-header {
            display: flex;
            flex-direction: column;
            min-width: 220px;
            padding: 0;
            align-items: center;

            & > div {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;

                &.subheaders {
                    text-align: center;
                    font-size: 0.95em;

                    & > div {
                        flex: 2;
                        padding: 4px 0;

                        &:last-child {
                            flex: 3;
                        }
                        &:not(:last-child) {
                            border-right: 1px solid rgba(black, 0.05);
                        }
                    }
                }
            }

            a.text-danger {
                opacity: 0.5;
                transform: scale(1);
                transition: all 0.1s ease;
                margin-left: 5px;

                &:hover {
                    transform: scale(1.15);
                    opacity: 1;
                }
            }
        }
    }
}
</style>
