<template>
    <Page>
        <ContactsTable route-name="account.contacts" route-param="supplier" />
    </Page>
</template>
<script>
import ContactsTable from './Contacts/ContactsTable.vue';
import Page from '@/js/components/Page.vue';

export default {
    components: {
        ContactsTable,
        Page,
    },
};
</script>
