<template>
    <DataTable
        :columns="[
            { title: 'ID', field: 'id', width: 80, sortable: true },
            { title: 'Type', field: 'type' },
            { title: 'Name', field: 'name', sortable: true },
            { title: 'Description', field: 'description' },
            { title: 'Icon', field: 'icon' },
        ]"
        searchable
        :endpoint="tableEndpoint"
        class="align-middle"
    >
        <template #column(type)="{ row }">
            <Tag :type="row.type == 1 ? 'success' : 'info'">
                {{ row.type == 1 ? 'Cost' : 'Revenue' }}
            </Tag>
        </template>
        <template #column(icon)="{ row }">
            <i v-if="row.icon" :class="fetchIconPath(row.icon)"></i>
        </template>
        <template #empty>
            <div class="empty-label">There are no departments to show</div>
        </template>
    </DataTable>
</template>
<script setup>
import { useDataStore } from '@/js/stores/DataStore.js';
import Tag from '@/js/components/Tag.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { computed } from 'vue';

const props = defineProps({
    account: {
        type: [String, Number],
    },
});
const store = useDataStore();
const tableEndpoint = computed(() => route('api.admin.account.departments', { account: props.account }));
const fetchIconPath = (icon) => {
    return store.get('icons').find((i) => i.name === icon)?.path ?? '';
};
</script>
