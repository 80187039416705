<template>
    <div id="spa-sidebar">
        <div id="logo"></div>
        <nav>
            <template v-if="currentAccount && !currentEvent">
                <a
                    v-if="lastAccessedEvent"
                    :href="
                        route('account.event.dashboard', {
                            event: lastAccessedEvent.id,
                        })
                    "
                    :title="'Return to ' + lastAccessedEvent.name"
                >
                    <i class="mdi mdi-arrow-left"></i>
                </a>
                <a
                    :href="route('account')"
                    title="Dashboard"
                    :class="{
                        active: currentRoute === 'account',
                    }"
                    @click.prevent="$emit('navigate', 'account')"
                >
                    <i class="mdi mdi-view-dashboard"></i>
                </a>
                <a
                    v-if="Auth.can('update organisation')"
                    :href="route('account.settings')"
                    title="Settings"
                    :class="{
                        active: currentRoute.startsWith('account.settings'),
                    }"
                    @click.prevent="$emit('navigate', 'account.settings')"
                >
                    <i class="mdi mdi-cogs"></i>
                </a>
                <a
                    v-if="!currentAccount.is_supplier"
                    :href="route('account.events')"
                    title="Event Comparison"
                    :class="{
                        active: currentRoute === 'account.events',
                    }"
                    @click.prevent="$emit('navigate', 'account.events')"
                >
                    <i class="mdi mdi-calendar-month"></i>
                </a>
                <a
                    v-if="Auth.can('manage suppliers')"
                    :href="route('account.contacts')"
                    title="Contacts"
                    :class="{
                        active: currentRoute === 'account.contacts',
                    }"
                    @click.prevent="$emit('navigate', 'account.contacts')"
                >
                    <i class="mdi mdi-card-account-details"></i>
                </a>
                <a
                    v-if="Auth.can('manage departments')"
                    :href="route('account.departments')"
                    title="Departments & Subcategories"
                    :class="{
                        active: currentRoute === 'account.departments',
                    }"
                    @click.prevent="$emit('navigate', 'account.departments')"
                >
                    <i class="mdi mdi-tag-text"></i>
                </a>
                <a
                    v-if="Auth.can('manage payruns')"
                    :href="route('account.payruns')"
                    title="Payment Runs"
                    :class="{
                        active: currentRoute === 'account.payruns',
                    }"
                    @click.prevent="$emit('navigate', 'account.payruns')"
                >
                    <i class="mdi mdi-cash-sync"></i>
                </a>
                <a
                    v-if="Auth.can('manage users')"
                    :href="route('account.team')"
                    title="Team & Permissions"
                    :class="{
                        active: currentRoute === 'account.team',
                    }"
                    @click.prevent="$emit('navigate', 'account.team')"
                >
                    <i class="mdi mdi-account-group"></i>
                </a>
                <a
                    v-if="Auth.can('manage integrations')"
                    :href="route('account.integrations')"
                    title="Integrations"
                    :class="{
                        active: currentRoute === 'account.integrations',
                    }"
                    @click.prevent="$emit('navigate', 'account.integrations')"
                >
                    <i class="mdi mdi-connection"></i>
                </a>
                <a
                    v-if="Auth.can('update organisation')"
                    href="#"
                    title="Reporting"
                    :class="{
                        active: currentRoute.startsWith('account.reporting'),
                    }"
                    @click.prevent="$emit('navigate', 'account.reporting')"
                >
                    <i class="mdi mdi-format-columns"></i>
                </a>
            </template>
            <template v-else-if="currentAccount && currentEvent">
                <a
                    :href="route('account.event.dashboard')"
                    title="Dashboard"
                    :class="{ active: currentRoute === 'account.event.dashboard' }"
                    @click.prevent="$emit('navigate', 'account.event.dashboard')"
                >
                    <i class="mdi mdi-view-dashboard"></i>
                </a>
                <a
                    :href="
                        route('account.event.budget', {
                            page: 'summary',
                        })
                    "
                    title="Budget"
                    :class="{ active: currentRoute === 'account.event.budget' }"
                    @click.prevent="$emit('navigate', 'account.event.budget', { page: 'summary' })"
                >
                    <i class="mdi mdi-chart-pie"></i>
                </a>
                <a
                    :href="route('account.event.budgetVActuals')"
                    title="Budget v Actuals"
                    :class="{ active: currentRoute === 'account.event.budgetVActuals' }"
                    @click.prevent="$emit('navigate', 'account.event.budgetVActuals')"
                >
                    <i class="mdi mdi-select-compare"></i>
                </a>
                <a
                    v-if="Auth.can('view revenue entries')"
                    :href="route('account.event.revenue.dashboard')"
                    title="Revenue"
                    :class="{
                        active: currentRoute.startsWith('account.event.revenue.dashboard'),
                    }"
                    @click.prevent="$emit('navigate', 'account.event.revenue.dashboard')"
                >
                    <i class="mdi mdi-finance"></i>
                </a>
                <a
                    v-if="Auth.can('view tickets')"
                    :href="route('account.event.tickets')"
                    title="Ticketing"
                    :class="{
                        active: currentRoute === 'account.event.tickets',
                    }"
                    @click.prevent="$emit('navigate', 'account.event.tickets')"
                >
                    <i class="mdi mdi-ticket-confirmation"></i>
                </a>
                <a
                    v-if="Auth.can('view purchase orders')"
                    :href="route('account.event.purchaseorders.summary')"
                    title="Purchasing"
                    :class="{
                        active: currentRoute === 'account.event.purchaseorders.summary',
                    }"
                    @click.prevent="$emit('navigate', 'account.event.purchaseorders.summary')"
                >
                    <i class="mdi mdi-cart"></i>
                </a>
                <a
                    :href="route('account.event.approvals')"
                    title="Approvals"
                    :class="{ active: currentRoute === 'account.event.approvals' }"
                    @click.prevent="$emit('navigate', 'account.event.approvals')"
                >
                    <i class="mdi mdi-tag-check"></i>
                </a>
                <a
                    v-if="Auth.can('update events')"
                    :href="route('account.event.eventsettings')"
                    title="Event Settings"
                    :class="{
                        active: currentRoute === 'account.event.eventsettings',
                    }"
                    @click.prevent="$emit('navigate', 'account.event.eventsettings')"
                >
                    <i class="mdi mdi-cog"></i>
                </a>
            </template>
            <template v-else-if="!currentAccount && !currentEvent">
                <template v-if="lastAccessedAccount">
                    <a
                        :href="
                            route('account', {
                                account: lastAccessedAccount.id,
                            })
                        "
                        :title="'Return to ' + lastAccessedAccount.name"
                    >
                        <i class="mdi mdi-arrow-left"></i>
                    </a>
                </template>
            </template>
            <div class="divider"></div>
            <template v-if="currentAccount && currentEvent">
                <a
                    :href="route('account')"
                    :title="currentAccount.name"
                    :class="{ active: currentRoute.startsWith('account') && !route().params.hasOwnProperty('event') }"
                >
                    <i class="mdi mdi-home-city"></i>
                </a>
            </template>
            <a
                v-if="store.get('environment') === 'production'"
                href="#"
                class="intercom-launcher text-primary"
                title="Send us a message"
            >
                <i class="mdi mdi-chat-question"></i>
            </a>
            <a :href="route('help')" title="Help & Support">
                <i class="mdi mdi-lifebuoy"></i>
            </a>
            <template v-if="currentUser.is_superadmin">
                <a :href="route('admin.index')" title="Admin">
                    <i class="mdi mdi-police-badge-outline"></i>
                </a>
            </template>
        </nav>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';

export default {
    emits: ['navigate'],
    data() {
        const store = useDataStore();
        return {
            currentAccount: store.get('account'),
            currentEvent: store.get('current_event'),
            currentUser: store.get('current_user'),
            currentRoute: route().current(),
            currentPage: route().params.page ?? null,
            store: store,
        };
    },
    computed: {
        lastAccessedAccount() {
            if (!this.currentUser || !this.currentUser.last_account_id) {
                return null;
            }
            return this.store.get('available_accounts').find((a) => a.id === this.currentUser.last_account_id);
        },
        lastAccessedEvent() {
            if (!this.currentAccount || !this.currentUser.last_event_id) {
                return null;
            }
            return this.store.get('account.events.' + this.currentUser.last_event_id);
        },
    },
    mounted() {
        Eventbus.$on('routeChanged', () => {
            this.currentRoute = route().current();
            this.currentPage = route().params.page ?? null;
        });
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

#spa-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 70px;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    border-right: 1px solid rgba(black, 0.1);
    z-index: 1001;
    // background: linear-gradient(to bottom left, $eventwise-primary, $eventwise-primary-dark);

    & > #logo {
        width: 70px;
        height: 35px;
        background-image: url('@/images/icon.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 15px;
    }
    & > nav {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        & > .divider {
            flex: 1;
        }

        & > a {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 5px;

            & > i {
                font-size: 24px;
            }

            &:after {
                position: absolute;
                left: 100%;
                content: attr(title);
                text-overflow: clip;
                white-space: nowrap;
                background: rgba(0, 0, 0, 0.6);
                padding: 3px 0;
                border-radius: 3px;
                color: white;
                margin-top: 5px;
                margin-left: 10px;
                transition: all 0.1s ease-in-out;
                overflow: hidden;
                max-width: 0px;
            }

            &:hover {
                background: rgba(black, 0.05);

                &:after {
                    max-width: 100vw;
                    padding: 3px 10px;
                }
            }

            &.active {
                background: rgba(black, 0.1);
            }
        }
    }
}
</style>
