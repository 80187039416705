<template>
    <div class="spa-page-wrapper">
        <Dashboard v-if="currentRoute === 'account.event.dashboard'" />
        <BudgetVActualsSettlementReport v-else-if="currentRoute === 'account.event.budgetVActuals.settlementReport'" />
        <Budget
            v-else-if="
                currentRoute.startsWith('account.event.budget') && currentRoute !== 'account.event.budgetVActuals'
            "
        />
        <BudgetVActuals v-else-if="currentRoute === 'account.event.budgetVActuals'" />
        <Revenue v-else-if="currentRoute.startsWith('account.event.revenue.dashboard')" />
        <Ticketing v-else-if="currentRoute === 'account.event.tickets'" />
        <Purchasing v-else-if="currentRoute === 'account.event.purchaseorders.summary'" />
        <Approvals v-else-if="currentRoute === 'account.event.approvals'" />
        <EventSettings v-else-if="currentRoute === 'account.event.eventsettings'" />
    </div>
</template>
<script>
import Dashboard from './pages/Dashboard.vue';
import Budget from './pages/Budget.vue';
import BudgetVActuals from './pages/BudgetVActuals.vue';
import BudgetVActualsSettlementReport from './pages/BudgetVActuals/SettlementReport.vue';
import Revenue from './pages/Revenue.vue';
import Ticketing from './pages/Ticketing.vue';
import Purchasing from './pages/Purchasing.vue';
import Approvals from './pages/Approvals.vue';
import EventSettings from './pages/EventSettings.vue';

export default {
    components: {
        Dashboard,
        Budget,
        BudgetVActuals,
        Revenue,
        Ticketing,
        Purchasing,
        Approvals,
        EventSettings,
        BudgetVActualsSettlementReport,
    },
    data() {
        return {
            currentRoute: route().current(),
        };
    },
    mounted() {
        Eventbus.$on('routeChanged', () => (this.currentRoute = route().current()));
    },
};
</script>
