<template>
    <div
        v-if="budgetEntry"
        class="approval-row budget-entry tw-cursor-pointer hover:!tw-bg-slate-100"
        @click="openBudgetEntry(budgetEntry.id)"
    >
        <div class="info">
            <h5 class="tw-m-0">{{ budgetEntry.name }}</h5>
            <small class="textmuted">
                Created {{ timeAgo(budgetEntry.created_at) }} by
                {{ budgetEntry.user.name }}
            </small>
        </div>
        <div class="figures">
            <FormField class="tw-flex-1 tw-ml-4" label="Fixed Cost">
                {{ localise(budgetEntry.fixed_cost, 2, true) }}
            </FormField>
            <FormField class="tw-flex-1" label="Budgeted Cost">
                {{ localise(budgetEntry.budgeted_cost, 2, true) }}
            </FormField>
            <FormField class="tw-flex-1" label="Supplier">
                {{ budgetEntry.supplier.name }}
            </FormField>
        </div>
    </div>

    <div
        v-else-if="purchaseOrder"
        class="approval-row purchase-order tw-cursor-pointer hover:!tw-bg-slate-100"
        @click="openPurchaseOrder(purchaseOrder.id)"
    >
        <div class="info">
            <h5>{{ purchaseOrder.title }}</h5>
            <small class="textmuted">
                Created {{ timeAgo(purchaseOrder.created_at) }} by
                {{ purchaseOrder.created_by.name }}
            </small>
        </div>
        <div class="figures">
            <FormField class="tw-flex-1 tw-ml-4" label="Subtotal">
                {{ localise(purchaseOrder.subtotal, 2, true) }}
            </FormField>
            <FormField class="tw-flex-1" label="Line Items">
                {{ purchaseOrder.line_items_count }}
            </FormField>
            <FormField class="tw-flex-1" label="Supplier">
                {{ purchaseOrder.supplier.name }}
            </FormField>
        </div>
    </div>
</template>
<script>
import FormField from '@/js/components/widgets/FormField.vue';
import { timeAgo } from '@/js/utils.js';
import { localise } from '@/js/utils.js';

export default {
    components: {
        FormField,
    },
    props: {
        budgetEntry: {
            type: Object,
            required: false,
        },
        purchaseOrder: {
            type: Object,
            required: false,
        },
    },
    methods: {
        timeAgo,
        localise,
        openBudgetEntry() {
            Eventbus.$emit('budget:entry', { entry: this.budgetEntry.id });
        },
        openPurchaseOrder() {
            Eventbus.$emit('purchaseOrder:view', this.purchaseOrder.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.textmuted {
    color: #6c757d;
}
.active {
    background: #ced4da;
    cursor: pointer;

    .textmuted {
        color: #343a40;
    }
}

.figures {
    flex-grow: 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .field-body > .field > .field {
        padding: 4px 4px;
        border-radius: 4px;
        margin-right: 5px !important;
        width: 100px;
        text-align: left;

        &:last-of-type {
            width: unset;
            max-width: 150px;
        }

        .label {
            margin-bottom: 2px !important;
            margin-top: 1px;
            opacity: 0.75;
        }
    }

    .icon {
        position: relative;
        right: -8px;
        transform: scale(0.75);

        &:first-child {
            margin-left: auto;
        }
    }
}

$mobile-breakpoint: 968px;

.approval-row {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 20px;

    @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
    }

    &:nth-child(2n) {
        background: rgba(black, 0.025);
        border-top: 1px solid rgba(black, 0.05);

        &:not(:last-child) {
            border-bottom: 1px solid rgba(black, 0.05);
        }
    }

    .info {
        width: 33.33%;

        @media (max-width: $mobile-breakpoint) {
            width: 100%;
            flex: 1;
        }

        h5 {
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 500;
        }

        small {
            display: block;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .field.figures {
        flex-grow: 1;
        margin-bottom: 0 !important;

        @media (max-width: 1200px) {
            width: 100%;
            flex: 1;
            margin-top: 10px;
        }

        .field {
            margin: 0 35px 0 0 !important;

            &:first-child {
                margin-left: 15px !important;
            }

            @media (max-width: 1400px) {
                margin-right: 15px !important;
            }
        }
    }

    .meta {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;

        div {
            margin: 2px 10px;
        }

        @media (max-width: $mobile-breakpoint) {
            flex-direction: row;
            flex: 1;
            margin-top: 15px;
        }
    }

    .field.actions {
        margin-bottom: 0 !important;
        margin-left: 25px;
    }
}
</style>
