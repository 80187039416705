<template>
    <div v-if="activity && activity.length" class="activity tw-px-4">
        <div v-for="event in activity" :key="event.id" class="activity-info">
            <activity :activity-event="event" />
        </div>
    </div>
    <p v-else class="empty-label tw-py-2">There is no activity to show here just yet</p>
</template>
<script>
export default {
    props: {
        activity: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
<style scoped>
.activity {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
}
</style>
