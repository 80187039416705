<template>
    <div>
        <template v-if="user">
            <div class="d-flex align-items-center tw-my-2">
                <Button class="mr-4" @click="$emit('back')">
                    <i class="mdi mdi-arrow-left"></i>
                </Button>
                <h3>{{ user.name }}</h3>
                <LastSaved class="tw-ml-auto tw-mr-3" :saving="isSaving" :saved="lastSaved" />
                <Button
                    v-if="!isCurrentUser && isAccountOwner"
                    class="tw-flex tw-items-center tw-gap-1"
                    @click="transferOwnership()"
                >
                    <i class="mdi mdi-swap-horizontal"></i>
                    Transfer Ownership
                </Button>
                <Tooltip
                    position="left"
                    :label="
                        isCurrentUser
                            ? 'You cannot remove yourself from a team'
                            : 'You cannot remove a Director from a team, please change their user group first'
                    "
                    :disabled="!isCurrentUser || !isDirector"
                >
                    <Button :disabled="isCurrentUser || isDirector" type="is-danger" @click="removeUserFromAccount">
                        Remove From Team
                    </Button>
                </Tooltip>
            </div>
            <Banner v-if="hasInvitePending" type="is-dark" class="tw-mb-3">
                <div class="d-flex align-items-center">
                    <i class="mdi mdi-send-circle h1 text-white m-0"></i>
                    <div class="flex-fill px-5">
                        <h4 class="text-white">Invite Pending</h4>
                        {{ user.name }} has been sent an invite to join {{ currentAccount.name }} however they haven't
                        accepted it just yet.
                    </div>
                    <Button :disabled="hasResentInvite" @click="resendInvite(user)">Resend Invite</Button>
                </div>
            </Banner>
            <div class="tw-grid xl:tw-grid-cols-5">
                <div class="xl:tw-col-span-2">
                    <ew-card title="Personal Details" :collapsible="false">
                        <div class="row p-2">
                            <div class="col-3 align-self-center">
                                <label class="mb-0">Name</label>
                            </div>
                            <div class="col-9">
                                {{ user.name }}
                            </div>
                            <div class="col-3 mt-4 align-self-center">
                                <label class="mb-0">Email Address</label>
                            </div>
                            <div class="col-9 mt-4">
                                {{ user.email }}
                            </div>
                            <div class="col-3 mt-4 align-self-center">
                                <label class="mb-0">Phone Number</label>
                            </div>
                            <div class="col-9 mt-4">
                                <span v-if="!user.phone" class="text-muted">Not set</span>
                                <span v-else>{{ user.phone }}</span>
                            </div>
                            <div class="col-3 mt-4 align-self-center">
                                <label class="mb-0">Local Timezone</label>
                            </div>
                            <div class="col-9 mt-4">
                                {{ user.timezone }}
                            </div>
                        </div>
                        <Banner type="is-info" class="tw-text-wrap">
                            <template v-if="!isCurrentUser">
                                For security reasons {{ user.name }} must make any changes to their personal details
                                themselves
                            </template>
                            <template v-else>
                                You can change your own personal details from the
                                <a :href="route('me')" target="_blank">
                                    My Details
                                    <i class="mdi mdi-open-in-new"></i>
                                </a>
                                page
                            </template>
                        </Banner>
                    </ew-card>
                    <ew-card title="Access & Permissions" :collapsible="false">
                        <div class="tw-grid tw-gap-3">
                            <FormField label="Group">
                                <div class="tw-flex tw-items-center tw-gap-3">
                                    <Select
                                        v-model="user.usergroup_id"
                                        placeholder="Unassigned"
                                        :disabled="isCurrentUser || currentAccount.created_by === user.id"
                                        class="tw-w-full"
                                        @update:model-value="debouncedUpdateUser()"
                                    >
                                        <option v-for="role in availableRoles" :key="role.id" :value="role.id">
                                            {{ role.name }}
                                        </option>
                                    </Select>
                                    <HelpLabel
                                        text="The user group controls what permissions the user has as well as what they can access within your organisation"
                                    />
                                </div>
                            </FormField>
                            <!-- Check box is disabled if current User is not a director -->
                            <Checkbox
                                :model-value="reportingStatus"
                                :disabled="!currentIsDirector"
                                label="Activity Reports"
                                @update:model-value="(value) => setReportingStatus(value)"
                            >
                                Activity Reports
                            </Checkbox>
                            <Banner v-if="isCurrentUser" type="is-info" class="tw-text-wrap">
                                You are unable to change your own user group
                            </Banner>
                            <Banner
                                v-else-if="currentAccount.created_by === user.id"
                                type="is-info"
                                class="tw-text-wrap"
                            >
                                You are unable to modify {{ user.name }}'s user group as they are the owner of
                                {{ currentAccount.name }}
                            </Banner>
                        </div>
                    </ew-card>
                </div>
                <div class="xl:tw-col-span-3">
                    <ew-card title="Events">
                        <control-select-two-way
                            v-model="user.events"
                            class="m-2"
                            item-name="Events"
                            :options="availableEvents"
                            show-search
                            :titles="false"
                            @update:model-value="debouncedUpdateUser()"
                        >
                            <template #description-left>
                                <p class="text-muted mb-3">
                                    These are available events that the user is unable to access.
                                    <br />
                                    Click on a particular event below to grant access.
                                </p>
                            </template>
                            <template #description-right>
                                <p class="text-muted mb-3">
                                    {{ user.name }} can access the below events.
                                    <br />
                                    Click on a particular event below to revoke access.
                                </p>
                            </template>
                        </control-select-two-way>
                    </ew-card>
                    <ew-card title="Subcategories">
                        <Banner v-if="isDirector" type="is-info">
                            <template v-if="!isCurrentUser">
                                {{ user.name }} is a Director and automatically has access to all subcategories.
                                <br />
                                If you wish to limit their access to subcategories please move them to another
                                usergroup.
                            </template>
                            <template v-else>
                                You are a Director and automatically have access to all subcategories.
                            </template>
                        </Banner>
                        <template v-else>
                            <p class="p-3 text-muted">
                                Subcategories are used to split up your Revenue Streams and Cost Departments, making
                                them easier to manage.
                                <br />
                                Here you can control which subcategories {{ user.name }} can view and interact with.
                            </p>
                            <UserSubcategories
                                v-model="user.categories"
                                :departments="departments"
                                @update:model-value="debouncedUpdateUser()"
                            />
                        </template>
                    </ew-card>
                </div>
            </div>
        </template>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import HelpLabel from '../../../../widgets/HelpLabel.vue';
import UserSubcategories from './User/UserSubcategories.vue';
import LastSaved from '../../../../widgets/LastSaved.vue';
import axios from 'axios';
import LoadingSpinner from '../../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Select from '@/js/components/controls/Select.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { toast } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        LoadingSpinner,
        HelpLabel,
        UserSubcategories,
        LastSaved,
        Button,
        Tooltip,
        Banner,
        Select,
        FormField,
        Checkbox,
    },
    props: {
        userId: {
            type: Number,
        },
    },
    emits: ['back'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isSaving: false,
            lastSaved: null,
            hasResentInvite: false,
            user: null,
            reportingStatus: false,
            currentAccount: store.get('account'),
            currentUser: store.get('current_user'),
            availableRoles: Object.values(store.get('account.user_groups')),
            availableEvents: Object.values(store.get('account.events')),
            departments: Object.values(store.get('account.departments')).map((d) => {
                d.categories = Object.values(d.categories);
                return d;
            }),
            roles: [],
        };
    },
    computed: {
        isAccountOwner() {
            if (!this.currentAccount || !this.currentUser) {
                return;
            }
            return this.currentAccount.created_by === this.currentUser.id;
        },
        isCurrentUser() {
            if (!this.user || !this.currentUser) {
                return false;
            }
            return this.user.id === this.currentUser.id;
        },
        hasInvitePending() {
            if (!this.user) {
                return false;
            }
            return this.user.invite_pending;
        },
        isDirector() {
            if (!this.user || !this.user.roles.length) {
                return false;
            }
            return this.user.roles[0].name === 'Director';
        },
        currentIsDirector() {
            if (!this.currentUser || !this.currentUser.roles.length) {
                return false;
            }
            return this.currentUser.roles[0].name === 'Director';
        },
    },
    mounted() {
        this.getUser();
    },
    methods: {
        getUser() {
            if (!this.userId) {
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.account.team.users', {
                        user: this.userId,
                    })
                )
                .then(({ data }) => {
                    this.user = data;
                    data.usergroup_id = data.roles.length ? data.roles[0].id : null;
                    data.categories = data.categories.map((c) => c.id);
                    this.getReportingStatus();
                })
                .finally(() => (this.isLoading = false));
        },
        debouncedUpdateUser: debounce(function () {
            this.updateUser();
        }, 1000),
        updateUser() {
            this.isSaving = true;
            axios
                .post(
                    route('api.account.team.users.update', {
                        user: this.user.id,
                    }),
                    this.user
                )
                .then(() => (this.lastSaved = DateTime.now()))
                .finally(() => (this.isSaving = false));
        },
        removeUserFromAccount() {
            this.isSaving = true;
            axios
                .delete(
                    route('api.account.team.users.delete', {
                        user: this.user.id,
                    })
                )
                .then(() => {
                    toast('Team Member Removed', this.user.name + ' has been removed.', 'error');
                    this.$emit('back');
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        resendInvite(user) {
            this.isLoading = true;
            axios
                .post(route('api.account.team.invite'), {
                    user_name: user.name,
                    user_email: user.email,
                    groupselect: user.roles[0].id,
                })
                .then(() => {
                    toast('Invite Resent', user.name + ' has been sent another invite ', 'success');
                    this.hasResentInvite = true;
                })
                .finally(() => (this.isLoading = false));
        },
        transferOwnership() {
            swal.fire({
                title: 'Transfer Ownership',
                text:
                    'Are you sure to wish to transfer full ownership of ' +
                    this.currentAccount.name +
                    ' to ' +
                    this.user.name +
                    '?',
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.isLoading = true;
                axios
                    .post(
                        route('api.account.set-owner', {
                            user: this.user.id,
                        })
                    )
                    .then(() => {
                        swal.fire({
                            title: 'Ownership Transferred',
                            text: 'You must now reload the page for some changes to be reflected',
                            showCancelButton: false,
                            confirmButtonColor: '#4EA5D9',
                            confirmButtonText: 'Reload',
                        }).then(() => {
                            window.location.reload();
                        });
                    })
                    .finally(() => (this.isLoading = false));
            });
        },
        setReportingStatus(value) {
            this.isSaving = true;
            axios
                .post(route('api.account.team.users.activityset', { user: this.user.id }), { setting: value })
                .then(({ data }) => {
                    this.reportingStatus = data.activity_reports;
                })
                .finally(() => {
                    this.lastSaved = DateTime.now();
                    this.isSaving = false;
                });
        },
        getReportingStatus() {
            axios.get(route('api.account.team.users.activityget', { user: this.user.id })).then(({ data }) => {
                this.reportingStatus = data.activity_reports;
            });
        },
    },
};
</script>
