<template>
    <div id="onboarding-demo-modal-container">
        <h3 class="tw-m-0">Create a demo account</h3>
        <p class="tw-mt-2">
            A demo account allows you to jump right into Eventwise with sample data.
            <br />
            It's ideal for those wanting to try Eventwise before deciding whether to create a full account, or for those
            who already have an account and would like to tinker freely.
        </p>
        <p class="tw-mb-4">You can only ever have one demo account, but you can return here to reset it at any time.</p>
        <div class="tw-grid tw-grid-cols-2 tw-items-center">
            <div class="tw-border tw-rounded">
                <h4 class="tw-m-0 tw-pt-3 tw-pb-2 border-bottom">What can I do?</h4>
                <ul class="tw-m-0">
                    <li class="tw-py-2 tw-bg-gray-100">
                        <i class="mdi mdi-check-bold tw-mr-2"></i>
                        Create Budget Entries
                    </li>
                    <li class="tw-py-2">
                        <i class="mdi mdi-check-bold tw-mr-2"></i>
                        Create Revenue Entries
                    </li>
                    <li class="tw-py-2 tw-bg-gray-100">
                        <i class="mdi mdi-check-bold tw-mr-2"></i>
                        Create Purchase Orders
                    </li>
                </ul>
            </div>
            <div class="tw-border tw-rounded tw-scale-90">
                <h4 class="tw-m-0 tw-pt-3 tw-pb-2 border-bottom">What am I missing out on?</h4>
                <ul class="tw-m-0">
                    <li class="tw-py-2 tw-bg-gray-100">
                        <i class="mdi mdi-close tw-mr-2"></i>
                        Team Management
                    </li>
                    <li class="tw-py-2">
                        <i class="mdi mdi-close tw-mr-2"></i>
                        Ticketing Integrations
                    </li>
                    <li class="tw-py-2 tw-bg-gray-100">
                        <i class="mdi mdi-close tw-mr-2"></i>
                        Accounting Integrations
                    </li>
                </ul>
            </div>
        </div>
        <br />
        <Banner v-if="demoAccount" type="is-info">You already have a demo account and cannot create another</Banner>
        <div class="onboarding-action-list tw-mt-3">
            <a
                class="onboarding-action-button"
                :class="{ 'bg-white': isCreating }"
                href="#"
                @click.prevent="createDemoAccount()"
            >
                <template v-if="!isCreating">
                    {{ (demoAccount ? 'Reset my existing' : 'Create a') + ' demo account' }}
                </template>
                <div v-else class="align-items-center flex-row">
                    <div
                        class="spinner-border mr-2"
                        role="status"
                        style="border-width: 1px; height: 10px; width: 10px; color: gray"
                    ></div>
                    <small>{{ isCreating }}</small>
                </div>
            </a>
        </div>
        <div class="options-row justify-content-center tw-mt-4">
            <a v-if="!isCreating" href="#" @click.prevent="$emit('back')">Changed your mind?</a>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Banner,
    },
    emits: ['back', 'error', 'created'],
    data() {
        const store = useDataStore();

        return {
            isCreating: false,
            store: store,
        };
    },
    computed: {
        demoAccount() {
            return this.store
                .get('available_accounts')
                .find((a) => a.is_demo && a.created_by.id === this.store.get('current_user.id'));
        },
    },
    mounted() {
        Eventbus.$on('demoAccount', (notification) => {
            if (!notification.account && !notification.error && notification.message) {
                this.isCreating = notification.message;
            } else if (notification.account) {
                this.postAccountCreation(notification.account);
            } else if (notification.error) {
                this.isCreating = null;
                this.$emit('error', notification.error);
            }
            if (notification.message) {
                this.isCreating = notification.message;
            }
        });
    },
    methods: {
        createDemoAccount() {
            if (this.isCreating) {
                return;
            }
            this.isCreating = 'Getting things ready';
            axios
                .post(route('api.onboarding.create.account.demoAccount'))
                .then(() => (this.isCreating = 'Preparing to ' + (this.demoAccount ? 'reset' : 'create') + ' account'))
                .catch(() => {
                    this.isCreating = null;
                });
        },
        postAccountCreation(account) {
            // Filter out the newly reset demo account if it exists
            const availableAccounts = this.demoAccount
                ? this.store.get('available_accounts').filter((da) => da.id !== this.demoAccount.id)
                : this.store.get('available_accounts');
            this.store.set('available_accounts', [account].concat(availableAccounts));
            this.$emit('created', account);
        },
    },
};
</script>
<style lang="scss" scoped>
#onboarding-demo-modal-container {
    z-index: 1000;
    width: min(700px, 95vw);
    max-height: calc(100vh - 2.5vw);
    padding: 0 25px;
    text-align: center;
    background: white;
    border-radius: 10px;
    overflow-y: auto;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
</style>
