<template>
    <Modal ref="modal" size="is-large" title="Bank Transactions" :loading="isLoading" @closing="resetModal()">
        <DataTable
            :columns="[
                { title: 'From', field: 'from' },
                { title: 'Logged by', field: 'logged_by' },
                { title: 'Date', field: 'date' },
                { title: 'Amount', field: 'amount', class: '!tw-text-right' },
                { title: 'Actions', field: 'actions', class: '!tw-text-right' },
            ]"
            :endpoint="route('api.account.event.bankTransactions', queryFilters)"
            centered
        >
            <template #column(from)="{ row }">
                <span v-if="row.parent && row.parent.name">
                    {{ row.parent.name }}
                </span>
                <span v-else class="text-muted">Direct Sales</span>
            </template>
            <template #column(logged_by)="{ row }">
                {{ row.user.name }}
            </template>
            <template #column(date)="{ row }">
                {{ timeAgo(row.created_at) }}
            </template>
            <template #column(amount)="{ row }">
                {{ localise(row.amount, 2, true) }}
            </template>
            <template #column(actions)="{ row }">
                <Tooltip v-if="Auth.can('delete bank transactions')" label="Delete Transaction" position="left">
                    <Button type="is-danger" @click="deleteTransaction(row.id)">
                        <i class="mdi mdi-delete"></i>
                    </Button>
                </Tooltip>
            </template>
        </DataTable>
    </Modal>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Modal from '@/js/components/Modal.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { timeAgo, localise } from '@/js/utils.js';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Button,
        Modal,
        DataTable,
        Tooltip,
    },
    data() {
        return {
            isLoading: false,
            queryFilters: {},
        };
    },
    mounted() {
        Eventbus.$on('view:bankTransactions', (payload) => {
            if (!Auth.can('view bank transactions')) {
                toast(
                    'Error',
                    "You don't have permission to view bank transactions. Please contact your account owner.",
                    'error'
                );
                return;
            }
            if (payload && payload?.filterBy && payload?.filterId) {
                this.queryFilters.filterBy = payload.filterBy;
                this.queryFilters.filterId = payload.filterId;
            }
            if (this.$refs.modal) {
                this.$refs.modal.open();
            }
        });
    },
    methods: {
        timeAgo,
        localise,
        closeModal() {
            this.$refs.modal.close();
        },
        resetModal() {
            this.queryFilters = {};
            this.isLoading = false;
        },
        deleteTransaction(transactionId) {
            this.isLoading = true;
            axios
                .delete(
                    route('api.account.event.bankTransactions.single.delete', {
                        bankTransaction: transactionId,
                    })
                )
                .finally(() => {
                    this.getTransactions(
                        this.queryFilters?.filterBy,
                        this.queryFilters?.filterId,
                        this.queryFilters?.page
                    );
                });
        },
    },
};
</script>
