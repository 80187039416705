<template>
    <div class="row align-items-center position-relative d-flex flex-column">
        <LoadingSpinner v-if="!rawData || !chartLastUpdated" label="Loading Data" />
        <div v-else class="col-12 flex-fill">
            <Chart type="line" :data="chartData" :options="chartOptions" :min-height="320" />
        </div>
    </div>
</template>
<script>
import Chart from '../../Chart.vue';
import LoadingSpinner from '../../widgets/LoadingSpinner.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        LoadingSpinner,
        Chart,
    },
    props: {
        data: {
            type: Array,
            required: false,
        },
        currentPeriod: {
            type: Number,
            required: false,
        },
        revenue: {
            type: Boolean,
            default: false,
        },
        monthly: {
            type: Boolean,
            default: false,
        },
        showSoldOutTarget: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isRevenue: false,
            isMonthly: false,
            isShowingSoldOutTarget: true,
            rawData: null,
            rawCurrentPeriod: null,
            chartLastUpdated: null,
        };
    },
    computed: {
        chartData() {
            this.chartLastUpdated;
            const datasets = [
                {
                    label: 'Current Sales',
                    data: this.rawData.map((rawData, index) => {
                        return index > this.rawCurrentPeriod
                            ? null
                            : rawData.data[this.isRevenue ? 'running_total_revenue' : 'running_total'];
                    }),
                    backgroundColor: 'rgba(54, 162, 235, .5)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                },
            ];

            if (this.isShowingSoldOutTarget) {
                datasets.push({
                    label: 'Sold Out Target',
                    data: this.rawData.map(
                        (rawData) =>
                            rawData.data[this.isRevenue ? 'running_total_stretch_revenue' : 'running_total_stretch']
                    ),
                    backgroundColor: 'rgba(166, 214, 175, .5)',
                    borderColor: 'rgba(166, 214, 175, 1)',
                });
            }

            const targets = [];
            const events = [];
            this.rawData.forEach((rawData) => {
                Object.keys(rawData.targets).forEach((targetName) =>
                    !targets.find((tg) => tg === targetName) ? targets.push(targetName) : null
                );
                Object.keys(rawData.events).forEach((eventName) =>
                    !events.find((ev) => ev === eventName) ? events.push(eventName) : null
                );
            });
            targets.forEach((targetName, index) => {
                datasets.push({
                    label: targetName,
                    data: this.rawData.map(
                        (rawData) => rawData.targets[targetName][this.isRevenue ? 'total_revenue' : 'total']
                    ),
                    backgroundColor: ['rgba(255, 159, 63, .5)', 'rgba(255, 205, 86, .5)'][index],
                    borderColor: ['rgba(255, 159, 63, 1)', 'rgba(255, 205, 86, 1)'][index],
                });
            });
            events.forEach((eventName) => {
                datasets.push({
                    label: eventName,
                    data: this.rawData.map(
                        (rawData) =>
                            rawData.events[eventName][this.isRevenue ? 'running_total_revenue' : 'running_total']
                    ),
                });
            });

            return {
                datasets: datasets,
                labels: this.rawData.map((rawData) => rawData.label),
            };
        },
        chartOptions() {
            this.chartLastUpdated;
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: this.isMonthly ? 'Month' : 'Week',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: this.isRevenue ? 'Ticket Revenue' : 'Tickets Sold',
                        },
                        ticks: {
                            callback(value) {
                                return this.isRevenue ? localise(value, 0, true) : value;
                            },
                        },
                    },
                },
            };
        },
    },
    watch: {
        data: {
            handler(newVal) {
                this.rawData = newVal;
                this.reloadChartData();
            },
            deep: true,
        },
        currentPeriod: {
            handler(newVal) {
                this.rawCurrentPeriod = newVal;
                this.reloadChartData();
            },
            deep: true,
        },
        revenue(newVal) {
            this.isRevenue = newVal;
            this.reloadChartData();
        },
        monthly(newVal) {
            this.isMonthly = newVal;
            this.reloadChartData();
        },
        showSoldOutTarget(newVal) {
            this.isShowingSoldOutTarget = newVal;
        },
    },
    mounted() {
        this.isRevenue = this.revenue;
        this.isMonthly = this.monthly;
        this.isShowingSoldOutTarget = this.showSoldOutTarget;
        this.rawData = this.data;
        this.rawCurrentPeriod = this.currentPeriod;
        this.reloadChartData();
    },
    methods: {
        reloadChartData() {
            this.chartLastUpdated = new Date();
        },
    },
};
</script>
