<template>
    <div class="buefy-wrapper">
        <Banner class="tw-mb-4" :closable="false">
            <div class="tw-flex tw-items-center">
                <i class="mdi mdi-cart-percent tw-text-2xl m-0"></i>
                <div class="flex-1 tw-px-5">
                    <h4>Tax Rates</h4>
                    Percentage modifiers that can be applied to entries, invoices, and tickets. If you're
                    <a href="#" @click.prevent="setRoute('account.integrations')">connected with Xero</a>
                    you can link these to your Xero tax rates.
                </div>
            </div>
        </Banner>
        <ew-card title="Tax Rates" :collapsible="false" paddingless>
            <div class="d-flex align-items-center py-3 px-4">
                <p class="flex-fill">
                    The below table contains a list of all of the saved tax rates within {{ currentAccount.name }}.
                </p>
                <Button type="is-success" class="tw-flex tw-gap-2" @click="createTaxRate()">
                    <i class="mdi mdi-plus"></i>
                    Create Tax Rate
                </Button>
            </div>
            <DataTable
                :data="filteredTaxRates"
                :columns="[
                    { title: 'Name', field: 'name' },
                    { title: 'Type', field: 'type' },
                    { title: 'Rate', field: 'rate' },
                    { title: 'Xero Tax Type', field: 'xero_tax_type', hidden: !currentAccount.is_connected_to_xero },
                    { title: '', field: 'actions', class: 'tw-text-right' },
                ]"
            >
                <template #column(type)="{ row }">{{ row.type === 'income' ? 'Income' : 'Expense' }}</template>
                <template #column(rate)="{ row }">{{ localise(row.rate, 2) }}&percnt;</template>
                <template #column(xero_tax_type)="{ row }">
                    <template v-if="currentAccount.is_connected_to_xero">
                        <span v-if="!row.xero_tax_type" class="text-muted">Not linked to Xero</span>
                        <span v-else>{{ row.xero_tax_type }}</span>
                    </template>
                </template>
                <template #column(actions)="{ row }">
                    <Button type="is-primary" @click="editTaxRate(row)">Edit</Button>
                    <Button class="tw-ml-1" @click="archiveTaxRate(row)">Archive</Button>
                </template>
            </DataTable>
        </ew-card>
        <Modal id="editModal" ref="editModal" title="Edit Tax Rate" :loading="isLoading" size="is-xlarge">
            <div class="row tw-mb-2">
                <div class="col-8 tw-mb-2">
                    <FormField label="Name">
                        <Textbox v-model="editingTaxRate.name" maxlength="255" class="tw-w-full" />
                    </FormField>
                </div>
                <div class="col-4 tw-mb-2 tw-pl-2">
                    <FormField field-class="tw-flex" label="Rate">
                        <Textbox
                            v-model="editingTaxRate.rate"
                            type="number"
                            min="0"
                            max="100"
                            step=".5"
                            class="tw-flex-1"
                        />
                        <Button disabled>%</Button>
                    </FormField>
                </div>
                <div class="col-12">
                    <FormField label="Type">
                        <div class="tw-flex tw-items-center tw-gap-1">
                            <Button
                                :active="editingTaxRate.type === 'income'"
                                class="tw-flex tw-gap-1 tw-items-center"
                                @click="editingTaxRate.type = 'income'"
                            >
                                <i class="mdi mdi-cash-plus"></i>
                                Income
                            </Button>
                            <Button
                                :active="editingTaxRate.type === 'expense'"
                                class="tw-flex tw-gap-1 tw-items-center"
                                @click="editingTaxRate.type = 'expense'"
                            >
                                <i class="mdi mdi-cash-minus"></i>
                                Expense
                            </Button>
                        </div>
                    </FormField>
                </div>
            </div>
            <Banner type="is-warning">
                A tax rate should only be changed to correct an error made when it was created. If the rate of tax you
                pay has changed you should instead create a new tax rate and apply that new rate going forward.
            </Banner>
            <template #footer>
                <Button class="!tw-mr-auto" @click="closeEditModal()">Cancel</Button>
                <Button type="is-success" @click="updateTaxRate()">Update Rate</Button>
            </template>
        </Modal>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { setRoute } from '@/js/utils.js';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        Banner,
        FormField,
        Textbox,
        DataTable,
    },
    emits: ['updated'],
    data() {
        const store = useDataStore();
        return {
            currentAccount: store.get('account'),
            isLoading: false,
            taxRates: store.get('account.tax_rates'),
            editingTaxRate: {},
            store: store,
            updateKey: 1,
        };
    },
    computed: {
        filteredTaxRates() {
            this.updateKey;
            return Object.values(this.taxRates).filter((t) => !t.is_archived);
        },
    },
    mounted() {
        Eventbus.$on('taxRates:closed', () => this.updateKey++);
    },
    methods: {
        setRoute,
        createTaxRate() {
            Eventbus.$emit('taxRates:create');
        },
        editTaxRate(taxRate) {
            this.editingTaxRate = JSON.parse(JSON.stringify(taxRate));
            this.$refs.editModal.open();
        },
        archiveTaxRate(taxRate) {
            swal.fire({
                title: 'Archive ' + taxRate.name,
                text: 'Are you sure you want to archive this tax rate? It will still apply to items it has already been assigned to, however, going forward you will no longer be able to select it.',
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                axios
                    .delete(
                        route('api.account.taxRates.archive', {
                            taxRate: taxRate.id,
                        })
                    )
                    .then(() => {
                        this.store.set(`acount.tax_rates.${taxRate.id}.is_archived`, true);
                        toast('Tax Rate Archived', taxRate.name + ' has been archived successfully', 'success');
                        this.updateKey++;
                    });
            });
        },
        updateTaxRate() {
            this.isLoading = true;
            axios
                .post(route('api.account.taxRates.update', { taxRate: this.editingTaxRate.id }), this.editingTaxRate)
                .then(() => {
                    this.$emit('updated');
                    toast('Success', 'Your tax rate has been updated successfully', 'success');
                    this.closeEditModal();
                })
                .finally(() => (this.isLoading = false));
        },
        closeEditModal() {
            this.editingTaxRate = {};
            this.$refs.editModal.close();
        },
    },
};
</script>
