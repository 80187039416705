<template>
    <div>
        <StylisedModal ref="modal" :title="modalTitle">
            <div class="view-invoice-content">
                <template v-if="invoice">
                    <small
                        v-if="invoice.supplier_raised"
                        class="d-block w-100 py-1 text-center mb-2"
                        :class="{
                            'bg-success text-white': invoice.status === 'approved',
                            'bg-warning text-white': invoice.status === 'awaiting_approval',
                            'bg-danger text-white': invoice.status === 'rejected',
                        }"
                    >
                        <template v-if="invoice.status === 'approved'">
                            This invoice was raised by the supplier and has been approved by {{ invoice.approver.name }}
                        </template>
                        <template v-else-if="invoice.status === 'awaiting_approval'">
                            This invoice was raised by the supplier and is awaiting approval
                        </template>
                        <template v-else-if="invoice.status === 'rejected'">
                            This invoice was rejected by {{ invoice.approver.name }}
                            <span v-if="invoice.approval_comments">
                                with the following comments:
                                <i>{{ invoice.approval_comments }}</i>
                            </span>
                        </template>
                    </small>
                    <div class="invoice-heading pad-x">
                        <FormField label="Title">
                            <h4><EditableText v-model="invoice.title" :editable="isEditable" :max-length="255" /></h4>
                        </FormField>
                        <FormField label="Invoice Number" class="tw-text-right">
                            <h4 class="text-muted">
                                <EditableText
                                    v-model="invoice.invoice_number"
                                    :editable="isEditable"
                                    :max-length="255"
                                />
                            </h4>
                        </FormField>
                    </div>
                    <div class="row pad-x">
                        <div class="col-6 mb-3">
                            <FormField label="Invoice Date">
                                <flat-pickr
                                    v-model="invoice.invoice_date"
                                    placeholder="Required"
                                    :config="flatpickrConfig"
                                    :disabled="!isEditable"
                                    class="input is-small"
                                    required
                                />
                            </FormField>
                        </div>
                        <div class="col-6 mb-3">
                            <FormField label="Due Date">
                                <flat-pickr
                                    v-model="invoice.due_date"
                                    placeholder="Required"
                                    :config="flatpickrConfig"
                                    :disabled="!isEditable"
                                    class="input is-small"
                                    required
                                />
                            </FormField>
                        </div>
                        <div class="col-6">
                            <FormField :label="invoiceType === 'CustomerInvoice' ? 'Customer' : 'Supplier'">
                                <User :user="invoice.supplier" :hide-name="false" size="xsmall" />
                            </FormField>
                        </div>
                        <div class="col-6">
                            <FormField label="Created by">
                                <User :user="invoice.created_by" :hide-name="false" size="xsmall" />
                            </FormField>
                        </div>
                        <div
                            v-if="invoiceType === 'CustomerInvoice' && (isEditable || invoice.payment_terms)"
                            class="col-12 pt-3"
                        >
                            <FormField label="Custom Payment Terms">
                                <EditableText
                                    v-model="invoice.payment_terms"
                                    placeholder="Optional"
                                    :max-length="255"
                                    :editable="isEditable"
                                />
                            </FormField>
                        </div>
                    </div>
                    <hr class="mt-4 mb-0" />
                    <Tabs :tabs="visibleTabs">
                        <template #tab(details)>
                            <table class="invoice-line-items-table tw-mt-2">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th class="tw-text-right">Unit Price</th>
                                        <th class="tw-text-right">Tax Rate</th>
                                        <th class="tw-text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="lineItem in invoice.line_items" :key="lineItem.id">
                                        <td>{{ lineItem.description }}</td>
                                        <td class="tw-text-right">{{ localise(lineItem.unit_price, 2, true) }}</td>
                                        <td
                                            class="tw-text-right"
                                            :class="{ 'text-muted': !lineItem.tax_rate || !lineItem.tax_rate.rate }"
                                        >
                                            {{ lineItem.tax_rate ? lineItem.tax_rate.rate + '%' : 'None' }}
                                        </td>
                                        <td class="tw-text-right">{{ localise(lineItem.subtotal, 2, true) }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colspan="2"></th>
                                        <th class="tw-text-right">Subtotal</th>
                                        <td class="tw-text-right">{{ localise(invoice.subtotal, 2, true) }}</td>
                                    </tr>
                                    <tr>
                                        <th colspan="2"></th>
                                        <th class="tw-text-right">Tax</th>
                                        <td class="tw-text-right">{{ localise(invoice.tax_amount, 2, true) }}</td>
                                    </tr>
                                    <tr>
                                        <th colspan="2"></th>
                                        <th class="tw-text-right">Total</th>
                                        <td class="tw-text-right">{{ localise(invoice.total_value, 2, true) }}</td>
                                    </tr>
                                    <tr v-if="invoice.total_paid || invoice.total_credit">
                                        <th colspan="2"></th>
                                        <th class="tw-text-right">Less Payments/Credits</th>
                                        <td class="tw-text-right">
                                            -{{
                                                localise(
                                                    parseFloat(invoice.total_paid ? invoice.total_paid : 0) +
                                                        parseFloat(invoice.total_credit ? invoice.total_credit : 0),
                                                    2,
                                                    true
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colspan="2"></th>
                                        <th class="tw-text-right">Outstanding</th>
                                        <td class="tw-text-right">{{ localise(totalOutstanding, 2, true) }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <Banner
                                v-if="invoice.source_currency != invoice.target_currency"
                                type="is-info is-light"
                                class="tw-mt-2"
                                :closable="false"
                            >
                                The target currency of this invoice ({{ invoice.target_currency }}) differs from the
                                event currency ({{ invoice.source_currency }}). The true value of this invoice is
                                {{
                                    localise(
                                        invoice.total_value,
                                        2,
                                        true,
                                        invoice.target_currency,
                                        invoice.conversion_rate
                                    )
                                }}
                                (rate: {{ invoice.conversion_rate }}).
                            </Banner>
                        </template>
                        <template #header(payments)>
                            Payments/Credits
                            <span class="badge bg-info text-white ml-1">
                                {{ invoice.combined_payments.length }}
                            </span>
                        </template>
                        <template #tab(payments)>
                            <table class="invoice-line-items-table mt-2 tw-align-middle">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th class="text-right">Amount</th>
                                        <th class="text-right"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(payment, index) in invoice.combined_payments" :key="index">
                                        <td>{{ payment.type === 'credit_note' ? 'Credit Note' : 'Payment' }}</td>
                                        <td>{{ DateTime.fromISO(payment.date).toLocaleString(DateTime.DATE_FULL) }}</td>
                                        <td>
                                            <Tag v-if="payment.type === 'credit_note'" type="warning">Credit</Tag>
                                            <Tag v-else-if="payment.is_overdue && !payment.is_paid" type="danger">
                                                Overdue
                                            </Tag>
                                            <Tag v-else-if="payment.is_paid" type="success">Paid</Tag>
                                            <Tag v-else type="info">Scheduled</Tag>
                                        </td>
                                        <td class="text-right">{{ localise(payment.amount, 2, true) }}</td>
                                        <td class="!tw-text-end">
                                            <button
                                                v-if="canManagePayments && payment.type === 'credit_note'"
                                                @click="deleteCreditNote(payment)"
                                            >
                                                <i class="mdi mdi-close-thick tw-text-red-400 hover:tw-text-red-500" />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                        <template #header(activity)>
                            Activity
                            <span class="badge bg-info text-white ml-1">{{ invoice.activity.length }}</span>
                        </template>
                        <template #tab(activity)>
                            <ul class="activity-list">
                                <li v-for="(activity, index) in invoice.activity" :key="index">
                                    {{ activity.description }}
                                    <template v-if="activity.user">
                                        by
                                        <strong>{{ activity.user.name }}</strong>
                                    </template>
                                </li>
                            </ul>
                        </template>
                        <template #header(purchase_orders)>
                            Purchase Orders
                            <span class="badge bg-info text-white ml-1">{{ invoice.purchase_orders.length }}</span>
                        </template>
                        <template #tab(purchase_orders)>
                            <table class="invoice-line-items-table mt-2">
                                <thead>
                                    <tr>
                                        <th>Reference</th>
                                        <th>Title</th>
                                        <th class="text-right w-10">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="poItem in invoice.purchase_orders" :key="poItem.id">
                                        <!-- @click.prevent="viewPurchaseOrder(poItem.id)" -->
                                        <td>
                                            <a href="#" @click.prevent="viewPurchaseOrder(poItem)">
                                                {{ poItem.reference }}
                                            </a>
                                        </td>
                                        <td>{{ poItem.title }}</td>
                                        <td class="text-right w-10">{{ localise(poItem.subtotal, 2, true) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>

                        <template #header(attachments)>Attachments</template>
                        <template #tab(attachments)>
                            <div class="tags mt-2">
                                <span
                                    v-for="(file, index) in invoice.file_uploads"
                                    :key="index"
                                    class="tw-flex tw-flex-col tw-ml-2 tw-mb-1"
                                >
                                    <span class="tw-flex tw-flex-row">
                                        {{ file.file_name }}
                                        <button type="submit" class="tw-border tw-ml-3" @click="openAttachment(file)">
                                            <i class="mdi mdi-download"></i>
                                        </button>
                                        <button
                                            type="submit"
                                            class="tw-border tw-ml-3"
                                            @click="removeAttachment(index)"
                                        >
                                            <i class="mdi mdi-file-document-remove"></i>
                                        </button>
                                    </span>
                                </span>
                            </div>
                            <FormField class="tw-m-4">
                                <Upload
                                    :key="componentKey"
                                    text="Upload documents"
                                    :accept="[
                                        'application/pdf',
                                        '.xlsx',
                                        '.doc',
                                        '.docx',
                                        'application/msword',
                                        'application/vnd.ms-excel',
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                        'image/*',
                                    ]"
                                    multiple
                                    @update:model-value="(file) => updateFilesChanged(file)"
                                />
                            </FormField>
                        </template>
                    </Tabs>
                    <div
                        v-if="
                            invoice.supplier_raised &&
                            invoice.status === 'awaiting_approval' &&
                            Auth.can('authorise supplier invoices')
                        "
                        class="row px-4 mb-3"
                    >
                        <div v-if="invoice.file_uploads && invoice.file_uploads.length" class="col-12 pb-3">
                            <FormField label="Attached Files">
                                <a
                                    v-for="file in invoice.file_uploads"
                                    :key="file.id"
                                    :href="file.download_url"
                                    target="_blank"
                                    class="d-block"
                                >
                                    {{ file.file_name ? file.file_name : 'Untitled File' }}
                                </a>
                            </FormField>
                        </div>
                        <div class="col-6">
                            <Button type="is-success" class="tw-w-full" @click="authoriseInvoice()">Authorise</Button>
                        </div>
                        <div class="col-6">
                            <Button type="is-danger" class="tw-w-full" @click="rejectInvoice()">Reject</Button>
                        </div>
                    </div>
                </template>
            </div>
            <template
                v-if="
                    invoice &&
                    !currentAccount.is_supplier &&
                    (invoice.status === 'approved' || invoiceType === 'CustomerInvoice') &&
                    (isEditable || invoiceType === 'CustomerInvoice')
                "
                #actions
            >
                <a v-if="isPublishedToXero" :href="invoice.view_url" target="_blank">View in Xero</a>
                <a
                    v-else-if="isConnectedToXero && !eventIsClosed"
                    :class="{
                        'text-muted': isPublishing,
                    }"
                    @click.prevent="isPublishing ? null : publishToXero()"
                >
                    {{ isPublishing ? 'Publishing to Xero...' : 'Publish to Xero' }}
                </a>
                <a
                    v-if="invoiceType === 'CustomerInvoice'"
                    href="#"
                    target="_blank"
                    class="mt-1"
                    @click.prevent="downloadInvoice()"
                >
                    Download invoice (PDF)
                </a>
                <a
                    v-if="invoiceType === 'CustomerInvoice'"
                    href="#"
                    class="mt-1"
                    @click.prevent="showEmailInvoiceModal()"
                >
                    Send invoice by email
                </a>
                <a
                    v-if="canManagePayments"
                    href="#"
                    :class="invoiceType === 'CustomerInvoice' || isPublishedToXero ? 'mt-2' : ''"
                    @click.prevent="managePayments()"
                >
                    Manage payment dates
                </a>
                <Tooltip
                    :disabled="!totalOutstanding"
                    label="You cannot raise a credit note as there is nothing outstanding on this invoice"
                    position="left"
                >
                    <a
                        v-if="canManagePayments"
                        href="#"
                        class="mt-1"
                        :class="{
                            disabled: !totalOutstanding,
                        }"
                        @click.prevent="raiseCreditNote()"
                    >
                        Raise a credit note
                    </a>
                </Tooltip>
                <a
                    v-if="
                        Auth.can(
                            invoiceType === 'CustomerInvoice' ? 'delete customer invoices' : 'delete supplier invoices'
                        ) && isEditable
                    "
                    href="#"
                    class="mt-2 text-danger"
                    @click.prevent="deleteInvoice()"
                >
                    Delete invoice
                </a>
            </template>
        </StylisedModal>
        <InvoicePaymentDates ref="invoicePaymentDatesModal" @closed="reload()" />
        <RaiseCreditNote />
        <AlterCurrency ref="alterCurrencyModal" @updated="updateCurrency" />
        <DeleteInvoice ref="deleteInvoiceModal" @deleted="close()" />
    </div>
</template>
<script>
import StylisedModal from '../../StylisedModal.vue';
import EditableText from '../../controls/EditableText.vue';
import User from '../../widgets/User.vue';
import InvoicePaymentDates from './InvoicePaymentDates.vue';
import DeleteInvoice from './DeleteInvoice.vue';
import RaiseCreditNote from './RaiseCreditNote.vue';
import AlterCurrency from '../AlterCurrency.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';
import { DateTime } from 'luxon';
import { toast, obj2form } from '@/js/utils.js';
import Upload from '@/js/components/controls/Upload.vue';

export default {
    components: {
        Upload,
        StylisedModal,
        EditableText,
        User,
        InvoicePaymentDates,
        DeleteInvoice,
        RaiseCreditNote,
        AlterCurrency,
        Button,
        FormField,
        Banner,
        Tabs,
        Tooltip,
        Tag,
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            isPublishing: false,
            invoiceType: null,
            invoice: null,
            activeTab: 0,
            currentAccount: store.get('account'),
            store: store,
            routeData: null,
            DateTime,
            componentKey: 0,
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        modalTitle() {
            if (!this.invoiceType) {
                return 'View Invoice';
            }
            return this.invoiceType === 'CustomerInvoice' ? 'View Customer Invoice' : 'View Supplier Invoice';
        },
        isEditable() {
            return (
                Auth.can(
                    this.invoiceType === 'CustomerInvoice' ? 'update customer invoices' : 'update supplier invoices'
                ) && !this.eventIsClosed
            );
        },
        canManagePayments() {
            return (
                Auth.can(
                    this.invoiceType === 'CustomerInvoice'
                        ? 'manage customer invoice payments'
                        : 'manage supplier invoice payments'
                ) && !this.eventIsClosed
            );
        },
        isCustomerInvoice() {
            return !!this.invoice?.revenue_entry_id;
        },
        isConnectedToXero() {
            return this.store.get('account.is_connected_to_xero');
        },
        isPublishedToXero() {
            if (!this.invoice) {
                return false;
            }
            return this.invoice.invoice_guid && this.isConnectedToXero && this.invoice.view_url;
        },
        totalOutstanding() {
            if (!this.invoice) {
                return 0;
            }
            return (
                parseFloat(this.invoice.total_value) -
                parseFloat(this.invoice.total_paid ? this.invoice.total_paid : 0) -
                parseFloat(this.invoice.total_credit ? this.invoice.total_credit : 0)
            );
        },
        visibleTabs() {
            if (!this.invoice) return;
            const tabs = [
                {
                    title: 'Details',
                    name: 'details',
                },
            ];
            if (this.invoice.combined_payments.length) {
                tabs.push({
                    title: 'Payments',
                    name: 'payments',
                });
            }
            if (this.invoice.activity.length) {
                tabs.push({
                    title: 'Activity',
                    name: 'activity',
                });
            }
            if (this.invoiceType === 'SupplierInvoice') {
                tabs.push({
                    title: 'Purchase Orders',
                    name: 'purchase_orders',
                });
            }
            if (this.invoiceType === 'SupplierInvoice') {
                tabs.push({
                    title: 'Attachments',
                    name: 'attachments',
                });
            }
            return tabs;
        },
    },
    watch: {
        'invoice.invoice_number': {
            handler(newVal, oldVal) {
                return oldVal === undefined ? null : this.debouncedUpdateInvoice();
            },
            deep: true,
        },
        'invoice.title': {
            handler(newVal, oldVal) {
                return oldVal === undefined ? null : this.debouncedUpdateInvoice();
            },
            deep: true,
        },
        'invoice.invoice_date': {
            handler(newVal, oldVal) {
                return oldVal === undefined ? null : this.debouncedUpdateInvoice();
            },
            deep: true,
        },
        'invoice.due_date': {
            handler(newVal, oldVal) {
                return oldVal === undefined ? null : this.debouncedUpdateInvoice();
            },
            deep: true,
        },
        'invoice.payment_terms': {
            handler(newVal, oldVal) {
                return oldVal === undefined ? null : this.debouncedUpdateInvoice();
            },
            deep: true,
        },
    },
    mounted() {
        Eventbus.$on('viewInvoice', (type, routeData) => {
            if (typeof type != 'string') {
                if (Object.keys(type).includes('routeData')) {
                    routeData = type.routeData;
                    type = type.type;
                }
            }
            this.open(type, routeData);
        });
        Eventbus.$on('reloadInvoice', () => this.reload());
        Eventbus.$on('reloadSupplierInvoices', () => this.reload());
        Eventbus.$on('reloadCustomerInvoices', () => this.reload());
    },
    methods: {
        open(type, routeData) {
            if (!type || !['CustomerInvoice', 'SupplierInvoice'].includes(type)) {
                toast('Error', 'Invalid type specified when opening invoice', 'error');
                return;
            } else if (!routeData) {
                toast('Error', 'Invalid route data specified when opening invoice', 'error');
                return;
            } else if (
                !Auth.can(type === 'CustomerInvoice' ? 'view customer invoices' : 'view supplier invoices') &&
                !this.currentAccount.is_supplier
            ) {
                toast(
                    'Error',
                    `You do not have permission to view ${
                        type === 'CustomerInvoice' ? 'customer' : 'supplier'
                    } invoices`
                );
                return;
            }
            this.invoiceType = type;
            this.routeData = routeData;
            this.isLoading = true;
            // TODO The open() line causes multiple console errors - "Cannot read properties of null (reading 'open')"
            this.$refs.modal.open();
            axios
                .get(
                    type === 'CustomerInvoice'
                        ? route('api.account.event.revenue.invoices.get', routeData)
                        : route('api.account.event.purchaseorders.invoices.get', routeData)
                )
                .then(({ data }) => {
                    this.invoice = data;
                    if (routeData.openPaymentDates) {
                        this.managePayments();
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        reload() {
            if (!this.invoice || !this.invoiceType) {
                return;
            }
            this.isLoading = true;
            this.isPublishing = false;
            axios
                .get(
                    this.invoiceType === 'CustomerInvoice'
                        ? route('api.account.event.revenue.invoices.get', {
                              event: this.invoice.event.id,
                              revenueEntry: this.invoice.revenue_entry_id,
                              customerInvoice: this.invoice.id,
                          })
                        : route('api.account.event.purchaseorders.invoices.get', {
                              event: this.invoice.event_id,
                              purchaseOrder: this.invoice.purchase_orders[0].id,
                              supplierInvoice: this.invoice.id,
                          })
                )
                .then(({ data }) => {
                    this.invoice = data;
                    if (!this.invoice.combined_payments.length) {
                        this.activeTab = 0;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        reset() {
            this.isLoading = false;
            this.isPublishing = false;
            this.invoiceType = null;
            this.invoice = null;
        },
        close() {
            Eventbus.$emit(
                this.invoiceType === 'CustomerInvoice' ? 'reloadCustomerInvoices' : 'reloadSupplierInvoices'
            );
            this.$refs.modal.close();
            this.reset();
        },
        debouncedUpdateInvoice: debounce(function () {
            this.updateInvoice();
        }, 500),
        updateInvoice() {
            if (
                !this.invoice ||
                !Auth.can(
                    this.invoiceType === 'CustomerInvoice' ? 'update customer invoices' : 'update supplier invoices'
                )
            ) {
                return;
            }
            axios.post(
                this.invoiceType === 'CustomerInvoice'
                    ? route('api.account.event.revenue.invoices.update', {
                          event: this.invoice.event.id,
                          revenueEntry: this.invoice.revenue_entry_id,
                          customerInvoice: this.invoice.id,
                      })
                    : route('api.account.event.purchaseorders.invoices.update', {
                          event: this.invoice.event_id,
                          purchaseOrder: this.invoice.purchase_orders[0].id,
                          supplierInvoice: this.invoice.id,
                      }),
                this.invoice
            );
        },
        updateFilesChanged(file) {
            if (!file) {
                return;
            }
            const formData = {
                files: [],
                invoiceId: this.invoice.id,
                purchaseOrderId: this.invoice.purchase_orders[0].id,
            };
            formData.files = file;
            axios
                .post(
                    route('api.account.event.invoices.addUpload', {
                        event: this.invoice.event_id,
                    }),
                    obj2form(formData)
                )
                .then(() => {
                    Eventbus.$emit('reloadInvoice');
                    this.componentKey += 1;
                });
        },
        downloadInvoice() {
            window.open(
                route('api.account.event.revenue.invoices.download', {
                    revenueEntry: this.invoice.revenue_entry_id,
                    customerInvoice: this.invoice.id,
                }),
                '_blank'
            );
        },
        deleteInvoice() {
            if (!this.invoice) {
                return;
            }
            this.$refs.deleteInvoiceModal.open(this.invoice, this.invoiceType);
        },
        managePayments() {
            this.$refs.invoicePaymentDatesModal.openModal(
                this.isCustomerInvoice
                    ? {
                          event: this.invoice.event_id,
                          revenueEntry: this.invoice.revenue_entry_id,
                          invoice: this.invoice.id,
                      }
                    : {
                          event: this.invoice.event_id,
                          purchaseOrder: this.invoice.purchase_orders[0].id,
                          invoice: this.invoice.id,
                          isSupplierRaised: this.invoice.supplier_raised,
                      }
            );
        },
        raiseCreditNote() {
            if (!this.totalOutstanding) {
                return;
            }
            const eventId = this.isCustomerInvoice ? this.invoice.event_id : this.invoice.event_id;
            Eventbus.$emit('raiseCreditNote', this.invoiceType, this.invoice.id, this.totalOutstanding, eventId);
        },
        deleteCreditNote(creditNote) {
            this.isLoading = true;
            axios
                .delete(
                    route('api.account.event.creditNotes.delete', {
                        event: this.invoice.event_id,
                        creditNote: creditNote.id,
                    })
                )
                .then(() => this.reload())
                .finally(() => (this.isLoading = false));
        },
        showEmailInvoiceModal() {
            if (!this.invoice) {
                return;
            }
            Eventbus.$emit('send:email', {
                model: this.invoice,
                type: 'invoice',
            });
        },
        authoriseInvoice() {
            if (!Auth.can('authorise supplier invoices')) {
                toast('Error', 'You do not have permission to authorise supplier invoices', 'error');
                return;
            } else if (!this.invoice.supplier_raised) {
                toast('Error', 'You cannot authorise a non-supplier raised invoice', 'error');
                return;
            } else if (this.invoice.status !== 'awaiting_approval') {
                toast('Error', 'You cannot authorise a supplier invoice unless it is awaiting approval', 'error');
                return;
            }

            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.purchaseorders.invoices.authorise', {
                        event: this.invoice.event_id,
                        purchaseOrder: this.invoice.purchase_orders[0].id,
                        supplierInvoice: this.invoice.id,
                    })
                )
                .then(() => {
                    toast(
                        'Success',
                        'The supplier-raised invoice ' + this.invoice.invoice_number + ' has been authorised',
                        'success'
                    );
                    this.reload();
                    Eventbus.$emit('supplierInvoice:authorised');
                    if (this.invoice.supplier_raised) {
                        if (this.isConnectedToXero && !this.isPublishedToXero) {
                            this.publishToXero();
                        }
                        this.managePayments();
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        rejectInvoice() {
            if (!Auth.can('authorise supplier invoices')) {
                toast('Error', 'You do not have permission to authorise supplier invoices', 'error');
                return;
            } else if (!this.invoice.supplier_raised) {
                toast('Error', 'You cannot reject a non-supplier raised invoice', 'error');
                return;
            } else if (this.invoice.status !== 'awaiting_approval') {
                toast('Error', 'You cannot reject a supplier invoice unless it is awaiting approval', 'error');
                return;
            }

            swal.fire({
                title: 'Reject Supplier Invoice',
                text: 'Please provide a brief reason for rejecting this supplier-raised invoice',
                input: 'text',
                inputAttributes: {
                    class: 'flex-fill',
                    maxlength: '255',
                },
                showCancelButton: true,
            }).then((result) => {
                this.isLoading = true;
                axios
                    .post(
                        route('api.account.event.purchaseorders.invoices.reject', {
                            event: this.invoice.event_id,
                            purchaseOrder: this.invoice.purchase_orders[0].id,
                            supplierInvoice: this.invoice.id,
                        }),
                        {
                            comments: result.value ?? null,
                        }
                    )
                    .then(() => {
                        toast(
                            'Success',
                            'The supplier-raised invoice ' + this.invoice.invoice_number + ' has been rejected',
                            'success'
                        );
                        this.reload();
                    })
                    .finally(() => {
                        this.isLoading = false;
                        Eventbus.$emit('reloadSupplierInvoices');
                    });
            });
        },
        alterCurrency() {
            this.$refs.alterCurrencyModal.open(
                this.invoice.source_currency,
                this.invoice.target_currency,
                this.invoice.conversion_rate,
                this.invoice.total_value
            );
        },
        updateCurrency(currency) {
            this.invoice.target_currency = currency.target;
            this.invoice.conversion_rate = currency.rate;
            this.updateInvoice();
        },
        publishToXero() {
            if (!this.invoice) {
                return;
            }
            this.isPublishing = true;
            axios
                .post(
                    this.invoiceType === 'CustomerInvoice'
                        ? route('api.account.event.revenue.invoices.pushToXero', {
                              event: this.invoice.event_id,
                              revenueEntry: this.invoice.revenue_entry_id,
                              customerInvoice: this.invoice.id,
                          })
                        : route('api.account.event.purchaseorders.invoices.pushToXero', {
                              event: this.invoice.event_id,
                              purchaseOrder: this.invoice.purchase_orders[0].id,
                              supplierInvoice: this.invoice.id,
                          })
                )
                .then(() => {
                    toast('Success', 'The invoice should be published to Xero within a few moments', 'success');
                });
        },
        viewPurchaseOrder(purchaseOrder) {
            Eventbus.$emit('purchaseOrder:view', {
                purchaseOrder: purchaseOrder.id,
                event: purchaseOrder.event_id,
                source: {
                    emitter: 'viewInvoice',
                    payload: {
                        type: 'SupplierInvoice',
                        routeData: this.routeData,
                    },
                },
            });
            this.close();
        },
        removeAttachment(index) {
            const fileUploadId = this.invoice.file_uploads[index].id;
            axios.delete(route('api.account.files.delete', { fileUpload: fileUploadId })).then(() => {
                this.invoice.file_uploads.splice(index, 1);
            });
        },
        openAttachment(file) {
            if (!file.download_url) {
                return;
            }
            window.open(file.download_url, '_blank');
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.view-invoice-content {
    .invoice-heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .pad-x {
        padding: 0 15px;
    }

    .invoice-line-items-table {
        width: calc(100% - 30px);
        margin: 0 15px;
        vertical-align: middle;

        thead > tr > th,
        tfoot > tr > th {
            text-transform: uppercase;
            font-family: $eventwise-font-heading;
            font-size: 0.9em;
            opacity: 0.5;
            padding: 0px 5px;
        }

        tbody {
            & > tr {
                border-bottom: 1px solid rgba(black, 0.1);

                td {
                    padding: 5px;
                }
            }
        }

        tfoot > tr > td.text-right,
        tfoot > tr > th.text-right {
            border-bottom: 1px solid rgba(black, 0.1);
            padding: 5px;
        }
        tfoot > tr > th.text-right {
            padding-top: 7px;
        }
    }

    ul.activity-list {
        display: flex;
        flex-direction: column;
        list-style: inside;
        padding-left: 10px;

        & > li {
            font-size: 0.95em;
            color: rgba(black, 0.55);

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            a,
            strong {
                color: inherit;
                font-weight: 500;
                text-decoration: none;
            }
        }
    }
}
</style>
