<template>
    <nav class="eventwise-submenu tw-overflow-x-auto">
        <slot name="header"></slot>
        <a
            v-for="page in visiblePages"
            :key="JSON.stringify(page[valueKey])"
            href="#"
            :class="[
                {
                    active: currentPage === page[valueKey],
                },
                page.class,
            ]"
            @click.prevent="currentPage = page[valueKey]"
        >
            <slot :page="page">
                <Icon v-if="page.icon" :icon="page.icon" class="p-1 mr-1" />
                {{ page.title ? page.title : page[valueKey] }}
            </slot>
        </a>
        <div v-if="$slots.footer" class="mt-auto"></div>
        <slot name="footer"></slot>
    </nav>
</template>
<script>
import Icon from '../Icon.vue';
export default {
    components: {
        Icon,
    },
    props: {
        pages: {
            type: Array,
            default() {
                return [];
            },
        },
        modelValue: {
            type: [String, Number, Object],
            required: false,
        },
        valueKey: {
            type: String,
            default: 'value',
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            menuPages: [],
            currentPage: null,
        };
    },
    computed: {
        visiblePages() {
            return this.menuPages.filter((p) => !p.hidden);
        },
    },
    watch: {
        modelValue(newVal) {
            this.currentPage = newVal;
        },
        currentPage(newVal) {
            this.$emit('update:modelValue', newVal);
        },
        pages(newVal) {
            this.menuPages = newVal;
        },
    },
    mounted() {
        this.menuPages = this.pages;
        this.currentPage = this.modelValue ?? null;
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

nav.eventwise-submenu {
    display: flex;

    & > a {
        display: flex;
        align-items: center;
        padding: 6px 10px;
        background: rgba(black, 0.033);
        border-radius: 5px;

        & > i {
            font-size: 18px;
            margin-right: 8px;
        }
        &:has(i) {
            padding: 3px 10px;
        }

        &:hover {
            background: rgba(black, 0.05);
        }

        &.active {
            background: $eventwise-gradient;
            color: white;
        }

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    @media (max-width: 900px) {
        & > a {
            &:not(:last-child) {
                margin-bottom: unset;
                margin-right: 5px;
            }
        }
    }
}
</style>
