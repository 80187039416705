<template>
    <Page :title="pageTitle">
        <template #submenu>
            <a :href="route('account.events')" :class="{ active: activePage === null }" @click.prevent="setPage()">
                Upcoming Events
            </a>
            <a
                :href="route('account.events')"
                :class="{ active: activePage === 'past' }"
                @click.prevent="setPage('past')"
            >
                Past Events
            </a>
            <a
                :href="route('account.events')"
                :class="{ active: activePage === 'all' }"
                @click.prevent="setPage('all')"
            >
                All Events
            </a>
            <a
                :href="
                    route('account.events', {
                        page: 'tags',
                    })
                "
                :class="{ active: activePage === 'tags' }"
                @click.prevent="setPage('tags')"
            >
                Group Tags
            </a>
            <a
                v-if="selectedEvents.length > 0 && selectedEvents.length <= 4"
                :href="
                    route('account.events', {
                        page: 'tickets',
                    })
                "
                :class="{ active: activePage === 'tickets' }"
                @click.prevent="setPage('tickets')"
            >
                Tickets
            </a>
        </template>
        <Events v-if="[null, 'past', 'all'].includes(activePage)" v-model="selectedEvents" :date-preset="activePage" />
        <GroupTags v-else-if="activePage === 'tags'" />
        <TicketComparison
            v-else-if="activePage === 'tickets' && selectedEvents.length <= 4"
            :selected-event-ids="selectedEvents.map((event) => event.id)"
        />
    </Page>
</template>
<script>
import Page from '@/js/components/Page.vue';
import Events from '@/js/components/pages/Account/EventComparison/Events.vue';
import GroupTags from '@/js/components/pages/Account/EventComparison/GroupTags.vue';
import { setRoute, flushPageEvents } from '@/js/utils.js';
import TicketComparison from '@/js/components/pages/Account/EventComparison/TicketComparison.vue';

export default {
    components: {
        Page,
        Events,
        GroupTags,
        TicketComparison,
    },
    data() {
        return {
            isLoading: false,
            activePage: route().params.page ?? null,
            selectedEvents: [],
        };
    },
    computed: {
        pageTitle() {
            switch (this.activePage) {
                case 'tags':
                    return 'Group Tag Comparison';
                case 'tickets':
                    return 'Ticket Comparison';
                default:
                    return 'Event Comparison';
            }
        },
    },
    mounted() {
        this.handlePageChange();
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        setPage(page = null) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setRoute(
                'account.events',
                page
                    ? {
                          page: page,
                      }
                    : {}
            );
        },
        handlePageChange() {
            this.activePage = route().params.page ?? null;
        },
    },
};
</script>
