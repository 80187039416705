<template>
    <page v-if="Auth.can('view revenue entries')" title="Revenue Dashboard">
        <template #submenu>
            <button class="ml-auto" @click="$refs.columnCustomisationModal.openModal()">Customise columns</button>
        </template>
        <div class="my-3">
            <WidgetStatistics v-slot="props" class="mb-3" :endpoint="route('api.account.event.streams.totals')">
                <WidgetStatistic
                    title="Total Revenue"
                    subtext="This Month (gross)"
                    icon="calendar-clock"
                    :loading="props.loading"
                    :model-value="props.data.totalMonthRevenue"
                    :currency="true"
                ></WidgetStatistic>
                <WidgetStatistic
                    title="Total Revenue"
                    subtext="Entire Event (net)"
                    icon="cash-multiple"
                    :loading="props.loading"
                    :model-value="props.data.totalRevenue"
                    :currency="true"
                ></WidgetStatistic>
                <WidgetStatistic
                    v-if="Auth.can('view tickets')"
                    title="Total Held With Ticket Sellers"
                    subtext="Sales minus Bank Transactions (gross)"
                    icon="cash-register"
                    :loading="props.loading"
                    :model-value="props.data.totalHeldWithTicketSellers"
                    :currency="true"
                ></WidgetStatistic>
                <WidgetStatistic
                    title="Total Outstanding"
                    subtext="Customer Invoices (gross)"
                    icon="alert-octagon"
                    :loading="props.loading"
                    :model-value="props.data.totalOutstanding"
                    :currency="true"
                ></WidgetStatistic>
            </WidgetStatistics>
        </div>
        <div v-if="Auth.can('view tickets')" class="tw-grid xl:tw-grid-cols-4">
            <TicketsSoldChart class="xl:tw-col-span-1 !tw-mx-0"></TicketsSoldChart>
            <MonthlyTicketSalesChart class="xl:tw-col-span-3 !tw-mx-0"></MonthlyTicketSalesChart>
        </div>
        <ObjectList
            type="revenueStream"
            :data="Object.values(store.get('current_event.revenue_streams'))"
            :columns="$refs.columnCustomisationModal?.columnsSettings"
        />
        <DrawerTicketManagement actuals></DrawerTicketManagement>
        <ObjectListColumnCustomisationModal ref="columnCustomisationModal" type="revenue" />
    </page>
</template>
<script>
import Page from '../Page.vue';
import DrawerTicketManagement from '../drawers/Ticketing/TicketManagement.vue';
import TicketsSoldChart from './Revenue/TicketsSoldChart.vue';
import MonthlyTicketSalesChart from './Revenue/MonthlyTicketSalesChart.vue';
import WidgetStatistics from '../../../js/components/widgets/statistics/StatisticsRow.vue';
import WidgetStatistic from '../../../js/components/widgets/statistics/StatisticsItem.vue';
import ObjectList from '../widgets/ObjectList.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import ObjectListColumnCustomisationModal from '../modals/ObjectListColumnCustomisationModal.vue';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';

export default {
    components: {
        Page,
        DrawerTicketManagement,
        TicketsSoldChart,
        MonthlyTicketSalesChart,
        WidgetStatistics,
        WidgetStatistic,
        ObjectList,
        ObjectListColumnCustomisationModal,
    },
    data() {
        const store = useDataStore();
        return {
            store: store,
            settings: useSettingsStore(),
        };
    },
};
</script>
