<template>
    <div v-if="isVisible" class="developer-tools-modal">
        <div class="developer-tools-modal-header">
            <strong>Developer Tools</strong>
        </div>
        <div class="developer-tools-modal-content tw-flex tw-flex-col">
            <a v-if="store.get('current_event')" href="#" @click.prevent="copyEventSocketLog()">
                Copy store update log to clipboard
            </a>
            <a href="#" @click.prevent="flushSettings()">Flush local settings</a>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';

export default {
    data() {
        return {
            isVisible: false,
            store: useDataStore(),
            settings: useSettingsStore(),
        };
    },
    mounted() {
        window.Logs = {
            storeUpdates: [],
        };
        document.onkeydown = (e) => {
            if (e.key === 'd' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                this.isVisible = !this.isVisible;
            }
        };
    },
    methods: {
        copyEventSocketLog() {
            navigator.clipboard.writeText(JSON.stringify(window.Logs.storeUpdates));
        },
        flushSettings() {
            localStorage.removeItem('EventwiseSettings');
            this.settings.refresh();
        },
    },
};
</script>
<style lang="scss" scoped>
.developer-tools-modal {
    z-index: 9999999999999;
    position: fixed;
    bottom: 20px;
    left: 90px;
    min-width: 150px;
    border: 1px solid black;
    background: white;
    font-family: sans-serif;
    user-select: none;

    & > .developer-tools-modal-header {
        border-bottom: 1px solid black;
        padding: 2px 5px;
    }
    & > .developer-tools-modal-content {
        padding: 5px;
        font-size: 0.9em;

        a {
            font-family: sans-serif;
        }
    }
}
</style>
