<template>
    <div>
        <div class="tw-grid tw-gap-3 tw-grid-cols-2 px-3">
            <div
                v-for="dept of [
                    {
                        title: 'Revenue Streams',
                        type: 2,
                    },
                    {
                        title: 'Cost Departments',
                        type: 1,
                    },
                ]"
                :key="`department-type-${dept.type}-group`"
            >
                <h4>{{ dept.title }}</h4>
                <div
                    v-for="department in departments.filter((d) => d.type == dept.type)"
                    :key="`dept-${department.id}-checkbox`"
                    class="bg-light tw-p-3"
                >
                    <Checkbox
                        :model-value="department.categories.length && hasEveryCategory(department)"
                        :disabled="!department.categories.length"
                        @update:model-value="
                            (v) => {
                                model = model.filter(
                                    (category) => !department.categories.map((cat) => cat.id).includes(category)
                                );
                                if (v) {
                                    model = [...model, ...department.categories.map((cat) => cat.id)];
                                }
                            }
                        "
                    >
                        {{ department.name }}
                    </Checkbox>
                    <template v-if="department.categories.length">
                        <div
                            v-for="category in department.categories"
                            :key="`dept-${department.id}-cat-${category.id}-checkbox`"
                            class="tw-px-2 tw-py-1 tw-mt-2 rounded bg-white"
                        >
                            <Checkbox
                                :model-value="model.includes(category.id)"
                                @update:model-value="
                                    (v) =>
                                        v ? model.push(category.id) : (model = model.filter((c) => c !== category.id))
                                "
                            >
                                {{ category.name }}
                            </Checkbox>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import Checkbox from '@/js/components/controls/Checkbox.vue';

const model = defineModel({
    type: [Array],
    default: () => [],
});
defineProps({
    departments: {
        type: Array,
        default: () => [],
    },
});
const hasEveryCategory = (department) => {
    return department.categories.every((deptCategory) => model.value.includes(deptCategory.id));
};
</script>
