<template>
    <page :title="pageTitle">
        <template #titleActions>
            <Tag v-if="currentEvent.lock_budget" class="tw-ml-3" type="danger">Budget Locked</Tag>

            <span v-if="isDirector" class="buefy-wrapper ml-auto">
                <LockItemControls
                    lockable-name="Event"
                    lockable-type="event"
                    :lockable="currentEvent"
                    @toggle="reloadPage"
                />
            </span>
        </template>
        <template #submenu>
            <a href="#" :class="{ active: activePage === 'summary' }" @click.prevent="setPage('summary')">
                <i class="mdi mdi-monitor-dashboard mr-1"></i>
                Budget Summary
            </a>
            <a href="#" :class="{ active: activePage === 'revenue' }" @click.prevent="setPage('revenue')">
                <i class="mdi mdi-cash-multiple mr-1"></i>
                Revenue Budget
            </a>
            <a href="#" :class="{ active: activePage === 'costs' }" @click.prevent="setPage('costs')">
                <i class="mdi mdi-credit-card-outline mr-1"></i>
                Cost Budget
            </a>
            <div class="mx-auto"></div>
            <div
                v-if="
                    Auth.account.scenarios_enabled &&
                    (Auth.can('manage scenarios') || Auth.can('create scenario adjustments'))
                "
                class="dropdown mr-2"
            >
                <a
                    href="#"
                    class="ml-auto"
                    :class="{ active: activePage === 'scenarios' || !!activeScenario }"
                    @click="Auth.can('manage scenarios') ? setPage('scenarios') : null"
                >
                    <i class="mdi mdi-weather-pouring mr-1"></i>
                    <span v-if="!activeScenario">Scenarios</span>
                    <span v-else>{{ activeScenario.name }}</span>
                </a>
                <div
                    v-if="Object.keys(store.get('current_event.scenarios')).length || activeScenario"
                    class="dropdown-menu dropdown-menu-right"
                >
                    <a v-if="activeScenario" class="dropdown-item" href="#" @click.prevent="setScenario()">
                        Close Scenario
                    </a>
                    <div
                        v-if="activeScenario && Object.keys(store.get('current_event.scenarios')).length"
                        class="dropdown-divider"
                    ></div>
                    <a
                        v-for="(scenario, id) in store.get('current_event.scenarios')"
                        :key="id"
                        class="dropdown-item"
                        href="#"
                        @click.prevent="setScenario(id)"
                    >
                        {{ scenario.name }}
                    </a>
                    <a
                        v-if="Object.values(store.get('current_event.scenarios')).length > 5"
                        class="dropdown-item"
                        href="#"
                        @click.prevent="setPage('scenarios')"
                    >
                        View all
                    </a>
                </div>
            </div>
            <div v-if="Auth.can('export data')" class="dropdown">
                <a href="#">
                    <i class="mdi mdi-download mr-1"></i>
                    Export
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" :href="route('account.event.export.entries.pdf')" target="_blank">
                        Budget Summary
                        <span class="badge badge-light ml-2">PDF</span>
                    </a>
                    <a class="dropdown-item" :href="route('account.event.export.entries.csv')" target="_blank">
                        All Budget Entries
                        <span class="badge badge-light ml-2">CSV</span>
                    </a>
                    <div v-if="activeDepartment">
                        <div class="dropdown-divider"></div>
                        <a
                            class="dropdown-item"
                            :href="
                                route('account.event.export.department.pdf', {
                                    department: activeDepartment.data.id,
                                })
                            "
                            target="_blank"
                        >
                            Department Budget Overview
                            <span class="badge badge-light ml-2">PDF</span>
                        </a>
                    </div>
                    <a class="dropdown-item" :href="route('account.event.export.markups.internal')" target="_blank">
                        Internal Markup Report
                    </a>
                    <a class="dropdown-item" :href="route('account.event.export.markups.external')" target="_blank">
                        External Report
                    </a>
                </div>
            </div>
            <button @click="$refs.columnCustomisationModal.openModal()">Customise columns</button>
        </template>
        <div v-if="['summary', 'revenue', 'costs'].includes(activePage)">
            <div v-if="!activeCategory">
                <div class="row mt-3">
                    <div class="col-12" :class="{ 'col-xl-8': showProfitOverview }">
                        <template v-if="!activeScenario">
                            <ew-card :title="overviewChartTitle" :expanded="true" :max-height="400">
                                <template v-if="!activeCategory && !activeDepartment" #actions>
                                    <div class="tw-flex tw-gap-1 tw-items-center">
                                        <Button
                                            :active="chartView === 'summary'"
                                            class="tw-flex tw-gap-2"
                                            @click="chartView = 'summary'"
                                        >
                                            <i class="mdi mdi-monitor-dashboard"></i>
                                            Summary
                                        </Button>
                                        <Button
                                            :active="chartView === 'revenue'"
                                            class="tw-flex tw-gap-2"
                                            @click="chartView = 'revenue'"
                                        >
                                            <i class="mdi mdi-cash-multiple"></i>
                                            Revenue
                                        </Button>
                                        <Button
                                            :active="chartView === 'costs'"
                                            class="tw-flex tw-gap-2"
                                            @click="chartView = 'costs'"
                                        >
                                            <i class="mdi mdi-credit-card-outline"></i>
                                            Cost
                                        </Button>
                                    </div>
                                </template>

                                <chart-departments-overview
                                    :height="260"
                                    :default-view="chartView"
                                    :department="activeDepartment"
                                />
                            </ew-card>
                        </template>
                        <template v-else>
                            <ew-card :title="overviewChartTitle" :expanded="true" :max-height="400">
                                <chart-scenario-comparison />
                            </ew-card>
                        </template>
                    </div>
                    <div v-if="showProfitOverview" class="col-12 col-xl-4">
                        <ChartProfitOverview height="270px" :labels="[]" />
                    </div>
                </div>
                <template v-if="!activeDepartment">
                    <ObjectList
                        v-if="['summary', 'revenue'].includes(activePage)"
                        :columns="$refs.columnCustomisationModal?.columnsSettings"
                        :data="Object.values(store.get('current_event.revenue_streams'))"
                        type="revenueStream"
                        selectable
                        @selected="selectItem"
                    >
                        <template #controls>
                            <EntryDisplayControls type="revenue" />
                        </template>
                    </ObjectList>

                    <ObjectList
                        v-if="['summary', 'costs'].includes(activePage)"
                        :columns="$refs.columnCustomisationModal?.columnsSettings"
                        type="costDepartment"
                        selectable
                        :data="Object.values(store.get('current_event.cost_departments'))"
                        @selected="selectItem"
                    >
                        <template #controls>
                            <EntryDisplayControls type="cost" />
                        </template>
                        <template #addNew>
                            <Tooltip label="New Cost Department" position="left">
                                <Button @click="emitCreateDepartment()">
                                    <i class="mdi mdi-plus"></i>
                                </Button>
                            </Tooltip>
                        </template>
                    </ObjectList>
                </template>
                <ObjectList
                    v-else
                    :columns="$refs.columnCustomisationModal?.columnsSettings"
                    type="category"
                    :data="
                        Object.values(store.get('current_event.categories')).filter((c) => {
                            return (
                                c.department_id ===
                                (activeDepartment.type === 'revenueStream'
                                    ? activeDepartment.data.department_id
                                    : activeDepartment.data.id)
                            );
                        })
                    "
                    selectable
                    @selected="selectItem"
                >
                    <template #controls>
                        <EntryDisplayControls :type="activeDepartment.type === 'revenueStream' ? 'revenue' : 'cost'" />
                    </template>
                </ObjectList>
            </div>
            <BudgetCategory v-else :category="activeCategory" />
        </div>
        <div v-else-if="activePage === 'scenarios' && Auth.can('manage scenarios')" class="py-3">
            <ScenarioProfitOverviewTable v-if="Object.keys(store.get('current_event.scenarios')).length" />
            <ew-card
                v-if="Object.keys(store.get('current_event.scenarios')).length"
                :title="overviewChartTitle"
                :expanded="true"
                :max-height="400"
            >
                <ChartScenarioComparison
                    :height="260"
                    :endpoint="route('api.account.event.chartData.departments.combined')"
                />
            </ew-card>
            <table-scenarios :endpoint="route('api.account.event.scenarios')" :filterable="false" />
        </div>
        <drawer-ticket-management />
        <Scenario />
        <CreateInitialTickets
            v-if="Auth.can('manage tickets') && !Object.values(store.get('current_event.ticket_types')).length"
        />
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <ObjectListColumnCustomisationModal ref="columnCustomisationModal" type="budget" />
    </page>
</template>
<script>
import Page from '../Page.vue';
import BudgetCategory from './Budget/Category.vue';
import ChartDepartmentsOverview from '../../../assets/js/eventbooks/components/charts/departments/departments-overview.vue';
import ChartProfitOverview from '../../../assets/js/eventbooks/components/charts/event/profit-overview.vue';
import ChartScenarioComparison from '../../../assets/js/eventbooks/components/charts/scenarios/scenario-comparison.vue';
import DrawerTicketManagement from '../drawers/Ticketing/TicketManagement.vue';
import TableScenarios from '../../../assets/js/eventbooks/components/scenarios/table.vue';
import LockItemControls from '../widgets/LockItemControls.vue';
import CreateInitialTickets from '../modals/Ticketing/CreateInitialTickets.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import ObjectList from '../widgets/ObjectList.vue';
import ScenarioProfitOverviewTable from '../tables/Scenario/ProfitOverviewTable.vue';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import Scenario from '../drawers/Scenarios/Scenario.vue';
import EntryDisplayControls from '../widgets/EntryDisplayControls.vue';
import LoadingSpinner from '../widgets/LoadingSpinner.vue';
import ObjectListColumnCustomisationModal from '../modals/ObjectListColumnCustomisationModal.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';
import { setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        LoadingSpinner,
        ScenarioProfitOverviewTable,
        LockItemControls,
        Page,
        BudgetCategory,
        ChartDepartmentsOverview,
        ChartProfitOverview,
        ChartScenarioComparison,
        DrawerTicketManagement,
        TableScenarios,
        ObjectList,
        CreateInitialTickets,
        Scenario,
        EntryDisplayControls,
        ObjectListColumnCustomisationModal,
        Button,
        Tag,
        Tooltip,
    },
    props: {
        category: {
            type: Object,
            required: false,
        },
        scenario: {
            type: Object,
            required: false,
        },
    },
    data() {
        const store = useDataStore();
        const settings = useSettingsStore();
        return {
            isLoading: true,
            activePage: route().params.page,
            activeDepartment: null,
            activeCategory: null,
            chartView: 'summary',
            store: store,
            settings: settings,
            currentEvent: store.get('current_event'),
        };
    },
    computed: {
        pageTitle() {
            switch (true) {
                case !!this.activeCategory:
                    return this.activeCategory.name;
                case !!this.activeDepartment:
                    return this.activeDepartment.data.name;
                case this.activePage === 'summary':
                    return 'Budget Summary';
                case this.activePage === 'revenue':
                    return 'Revenue Budget Summary';
                case this.activePage === 'costs':
                    return 'Cost Budget Summary';
                case this.activePage === 'scenarios':
                    return 'Scenario Budget Summary';
                default:
                    return '';
            }
        },
        overviewChartTitle() {
            switch (true) {
                case !!this.activeCategory:
                    return this.activeCategory.name + ' Overview';
                case !!this.activeDepartment:
                    return this.activeDepartment.data.name + ' Overview';
                case this.activePage === 'summary':
                    return 'Event Budget Overview';
                case this.activePage === 'revenue':
                    return 'Revenue Budget Overview';
                case this.activePage === 'costs':
                    return 'Cost Budget Overview';
                case this.activePage === 'scenarios':
                    return 'Scenario Budget Overview';
                default:
                    return '';
            }
        },
        showProfitOverview() {
            let is_director = !!Auth?.user?.roles?.find((role) => role.name === 'Director');
            let is_bookkeeper = !!Auth?.user?.roles?.find((role) => role.name === 'Bookkeeper');
            return is_director || is_bookkeeper;
        },
        isDirector() {
            return !!Auth?.user?.roles?.find((role) => role.name === 'Director');
        },
        activeScenario() {
            return this.store.get('current_scenario');
        },
    },
    mounted() {
        this.handlePageChange();
        this.isLoading = false;
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        emitCreateDepartment(type) {
            Eventbus.$emit('create:department', type);
        },
        handlePageChange() {
            if (route().params.departmentId) {
                const departmentType = route().params.page === 'revenue' ? 'revenueStream' : 'costDepartment';
                this.activePage = route().params.page;
                this.activeDepartment = {
                    type: departmentType,
                    data: this.store.get(
                        'current_event.' +
                            (departmentType === 'revenueStream' ? 'revenue_streams' : 'cost_departments') +
                            '.' +
                            route().params.departmentId
                    ),
                };
                if (route().params.categoryId) {
                    this.activeCategory = this.activeDepartment.data.categories[route().params.categoryId];
                } else {
                    this.activeCategory = null;
                }
            } else {
                this.activeDepartment = null;
                this.activeCategory = null;
                if (!this.activePage) {
                    this.setPage('summary');
                } else {
                    this.activePage = route().params.page;
                }
            }
        },
        setPage(page = 'summary') {
            Eventbus.$emit('objectRow:reset');
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.activePage = page;
            this.chartView = page;
            setRoute('account.event.budget', {
                page: page,
            });
        },
        setScenario(scenarioId = null) {
            if (!scenarioId) {
                this.store.set('current_scenario', null);
                Eventbus.$emit('forceRefetchEvent');
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.scenarios.get', {
                        scenario: scenarioId,
                    })
                )
                .then(({ data }) => {
                    this.store.set('current_scenario', data);
                    Eventbus.$emit('forceRefetchEvent');
                })
                .finally(() => (this.isLoading = false));
        },
        selectItem(payload) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (payload.type !== 'category') {
                setRoute('account.event.budget', {
                    page: payload.type === 'revenueStream' ? 'revenue' : 'costs',
                    departmentId: payload.data.id,
                });
            } else {
                setRoute('account.event.budget', {
                    page: payload.data.is_revenue ? 'revenue' : 'costs',
                    departmentId: payload.data.is_revenue
                        ? Object.values(this.store.get('current_event.revenue_streams')).find(
                              (r) => r.department_id === payload.data.department_id
                          ).id
                        : payload.data.department_id,
                    categoryId: payload.data.id,
                });
            }
        },
        reloadPage() {
            window.location.reload();
        },
    },
};
</script>
