<template>
    <div v-if="!hidden && (showIfLonely || users.length > 1)">
        <label v-if="label" class="presence-label">{{ label }}</label>
        <div class="presence-list">
            <div v-for="user in displayedUsers" :key="user.id" class="presence-user">
                <img v-if="user.image" :src="user.image" />
                <div v-else class="initials">{{ user.initials }}</div>
                <div>
                    {{ user.name }}
                </div>
            </div>
            <Tooltip v-if="hiddenUsers.length">
                <span class="text-muted">
                    {{ hiddenUsers.length == 1 ? '+1 other user' : '+' + hiddenUsers.length + ' other users' }}
                </span>
                <template #content>
                    <div v-for="hiddenUser in hiddenUsers" :key="hiddenUser.id">{{ hiddenUser.name }}</div>
                </template>
            </Tooltip>
        </div>
    </div>
</template>
<script>
import Tooltip from '@/js/components/Tooltip.vue';
export default {
    components: {
        Tooltip,
    },
    props: {
        channel: {
            type: String,
            required: true,
        },
        showIfLonely: {
            type: Boolean,
            default: false,
        },
        maxDisplayed: {
            type: Number,
            default: 3,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            users: [],
        };
    },
    computed: {
        displayedUsers() {
            return this.users
                .map((user, index) => {
                    if (index < this.maxDisplayed) {
                        return user;
                    }
                    return null;
                })
                .filter(Boolean);
        },
        hiddenUsers() {
            return this.users
                .map((user, index) => {
                    if (index >= this.maxDisplayed) {
                        return user;
                    }
                    return null;
                })
                .filter(Boolean);
        },
    },
    mounted() {
        Eventbus.$emit('Presence:join', this.channel);
        Eventbus.$on(`Presence-${this.channel}`, this.processEvent);
    },
    destroyed() {
        Eventbus.$emit('Presence:leave', this.channel);
    },
    methods: {
        processEvent(e) {
            switch (e.event) {
                case 'here':
                    this.users = e.users;
                    return;
                case 'joining':
                    this.users.push(e.user);
                    return;
                case 'leaving':
                    this.users = this.users.filter((u) => u.id !== e.user.id);
                    return;
                default:
                    return;
            }
        },
    },
};
</script>
<style lang="scss">
.presence-label {
    display: block;
    opacity: 0.75;
    margin-bottom: 6px;
}
.presence-list {
    display: flex;
    align-items: center;
    user-select: none;

    & > .presence-user {
        display: flex;
        align-items: center;
        padding: 2px 5px 2px 5px;
        border: 1px solid rgba(black, 0.1);
        border-radius: 3px;

        &:not(:last-child) {
            margin-right: 4px;
        }

        & > img {
            width: 24px;
            height: 24px;
            border-radius: 3px;
            margin-right: 5px;
        }
        & > .initials {
            width: 24px;
            height: 24px;
            background: rgba(109, 129, 245, 0.08);
            color: #6d81f5;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            border-radius: 3px;
            margin-right: 5px;
        }
    }
}
</style>
