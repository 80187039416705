<template>
    <button
        class="tw-relative tw-px-3 tw-py-1 tw-text-sm tw-border tw-text-center tw-rounded-[3px] disabled:tw-text-gray-400 disabled:tw-border-gray-200 disabled:tw-bg-gray-50 focus:tw-outline-none"
        :class="{
            'tw-bg-eventwise tw-text-white tw-border-eventwise-500 hover:tw-border-eventwise-600':
                active || type === 'is-primary',
            'tw-text-red-500  tw-border-red-500 hover:tw-border-red-600': type === 'is-danger',
            'tw-bg-emerald-500 tw-text-white tw-border-emerald-500 hover:tw-border-green-600 ': type === 'is-success',
            'tw-text-blue-500 tw-border-blue-500 hover:tw-border-blue-600': type === 'is-info',
            'tw-bg-yellow-500 tw-text-white tw-border-yellow-500 hover:tw-border-yellow-600': type === 'is-warning',
            'tw-text-gray-500  tw-border-gray-500 hover:tw-border-gray-500': type === 'is-secondary',
            'tw-bg-xero tw-text-[rgba(255,255,255,.9)] tw-border-none hover:tw-text-white': type === 'is-xero',
            'tw-bg-[transparent] tw-text-link tw-border-none tw-px-0 tw-font-medium hover:tw-underline':
                type === 'is-link',
            'tw-border-gray-300 tw-text-gray-600 tw-bg-white hover:tw-border-gray-400 hover:tw-text-gray-700':
                !type && !active,
            'tw-opacity-50 tw-cursor-not-allowed': disabled,
        }"
        type="button"
        :disabled="disabled || loading"
        @click="(e) => $emit('click', e)"
    >
        <slot></slot>
        <ContainedLoader v-if="loading" />
    </button>
</template>
<script>
import ContainedLoader from '@/js/components/controls/ContainedLoader.vue';

export default {
    components: {
        ContainedLoader,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: false,
        },
    },
    emits: ['click'],
};
</script>
<style lang="scss" scoped>
button {
    & > i,
    & > div > i {
        display: block;
        transform: scale(1.33);
    }
}
</style>
