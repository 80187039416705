<template>
    <div>
        <div>
            <DataTable
                :data="invoices"
                :columns="[
                    {
                        title: 'Invoice #',
                        field: 'invoice_number',
                    },
                    {
                        title: 'Date',
                        field: 'invoice_date',
                    },
                    {
                        title: 'Title',
                        field: 'title',
                    },
                    {
                        title: 'Subtotal',
                        field: 'subtotal',
                        class: '!tw-text-right',
                    },
                    {
                        title: 'Tax',
                        field: 'tax_amount',
                        class: '!tw-text-right',
                    },
                    {
                        title: 'Total',
                        field: 'total_value',
                        class: '!tw-text-right',
                    },
                    {
                        title: '',
                        field: 'actions',
                        class: '!tw-text-right',
                    },
                ]"
            >
                <template #column(invoice_number)="{ row }">
                    {{ row.invoice_number }}
                </template>
                <template #column(invoice_date)="{ row }">
                    {{ formatDate(row.invoice_date, 'dd/MM/yyyy') }}
                </template>
                <template #column(title)="{ row }">
                    {{ row.title }}
                </template>
                <template #column(subtotal)="{ row }">
                    <span v-if="row.pivot && row.pivot.value_modifier < 100">
                        <Tooltip label="This invoice is linked to multiple purchase orders">
                            <i class="mdi mdi-alert tw-text-orange-500"></i>
                        </Tooltip>
                        {{ localise(modifiedSubtotal(row.pivot.value_modifier, row.subtotal), 2, true) }}
                    </span>
                    <span v-else>{{ localise(row.subtotal, 2, true) }}</span>
                </template>
                <template #column(tax_amount)="{ row }">
                    {{ localise(row.tax_amount, 2, true) }}
                </template>
                <template #column(total_value)="{ row }">
                    {{ localise(row.total_value, 2, true) }}
                </template>
                <template #column(actions)="{ row }">
                    <Button @click="viewInvoice(row)">
                        <i class="mdi mdi-eye"></i>
                    </Button>
                </template>
                <template #expanded-row="{ row }">
                    <div v-if="!row.file_uploads.length" class="tw-ml-2">There are no attachments to show here</div>
                    <DataTable
                        v-else
                        :columns="[
                            {
                                title: 'Attached Files',
                                field: 'file_name',
                            },
                            {
                                title: 'Size',
                                field: 'file_size',
                            },
                            {
                                title: '',
                                field: 'actions',
                                class: '!tw-text-right',
                            },
                        ]"
                        :data="row.file_uploads"
                    >
                        <template #column(file_name)="{ row }">
                            {{ row.file_name }}
                        </template>
                        <template #column(file_size)="{ row }">{{ bytes2mb(row.file_size) }}MB</template>
                        <template #column(actions)="{ row }">
                            <Button @click="downloadAttachment(row)">
                                <i class="mdi mdi-download"></i>
                            </Button>
                        </template>
                    </DataTable>
                </template>
            </DataTable>
            <Button
                v-if="canRaiseInvoice"
                type="is-link"
                class="tw-w-full tw-flex tw-gap-2 tw-justify-center"
                @click="onRaiseInvoice()"
            >
                <i class="mdi mdi-plus"></i>
                Raise an invoice
            </Button>
        </div>
        <p
            v-if="!canRaiseInvoice && unableToRaiseInvoiceMessage"
            class="empty-label tw-py-2"
            v-html="unableToRaiseInvoiceMessage"
        ></p>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { formatDate, localise } from '@/js/utils.js';

export default {
    components: {
        Button,
        DataTable,
        Tooltip,
    },
    props: {
        invoices: {
            type: Array,
            default: () => [],
        },
        onRaiseInvoice: {
            type: Function,
            required: true,
        },
        canRaiseInvoiceConditions: {
            type: Array,
            default: () => [
                {
                    can: false,
                },
            ],
        },
    },
    emits: ['view'],
    data() {
        return {
            unableToRaiseInvoiceMessage: null,
        };
    },
    computed: {
        canRaiseInvoice() {
            this.setUnableToRaiseInvoiceMessage();

            this.canRaiseInvoiceConditions.forEach((condition) => {
                if (!condition.can) {
                    this.unableToRaiseInvoiceMessage = condition.message;
                }
            });

            if (!Auth.can('create supplier invoices')) {
                this.setUnableToRaiseInvoiceMessage(
                    'You do not have permission to raise invoices - please contact your account owner to obtain permission.'
                );
            }
            return !this.unableToRaiseInvoiceMessage;
        },
    },
    methods: {
        formatDate,
        localise,
        setUnableToRaiseInvoiceMessage(value = null) {
            this.unableToRaiseInvoiceMessage = value;
        },
        downloadAttachment(fileUpload) {
            if (!fileUpload.download_url) {
                return;
            }
            window.open(fileUpload.download_url, '_blank');
        },
        modifiedSubtotal(modifier, subtotal) {
            return (parseFloat(modifier) / 100) * subtotal;
        },
        viewInvoice(row) {
            this.$emit('view', row.id);
        },
        bytes2kb(bytes) {
            return (parseFloat(bytes) / 1000).toFixed(1);
        },
        bytes2mb(bytes) {
            return (parseFloat(this.bytes2kb(bytes)) / 1000).toFixed(1);
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.selectable-invoice-row {
    color: #485fc7;

    &.rejected {
        color: red;
        background: rgba(red, 0.1);
    }
    &.awaiting_approval {
        color: orange;
        background: rgba(orange, 0.1);
    }
    &:hover {
        cursor: pointer;
        background: rgba(black, 0.05);
        text-decoration: underline;
    }
}
</style>
