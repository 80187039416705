<template>
    <div>
        <LoadingSpinner v-if="!isLoaded" label="Loading Departments" />
        <div v-else class="mb-2">
            <div class="d-flex">
                <div style="flex-grow: 1">
                    <DataTable
                        :columns="[
                            {
                                title: 'Title',
                                field: 'name',
                                sortable: true,
                            },
                            {
                                title: 'Subcategories',
                                field: 'category_count',
                                sortable: true,
                            },
                            {
                                title: 'Budget Entries',
                                field: 'entries_count',
                                sortable: true,
                            },
                            {
                                title: 'Fixed',
                                field: 'fixed_cost',
                                sortable: true,
                            },
                            {
                                title: 'Budgeted',
                                field: 'budgeted_cost',
                                sortable: true,
                            },
                            {
                                title: '',
                                field: 'actions',
                                sortable: false,
                                class: '!tw-text-right',
                            },
                        ]"
                        :data="departments"
                    >
                        <template #column(name)="{ row }">
                            {{ row.name }}
                        </template>
                        <template #column(category_count)="{ row }">
                            {{ row.category_count }}
                        </template>
                        <template #column(entries_count)="{ row }">
                            {{ row.entries_count }}
                        </template>
                        <template #column(fixed_cost)="{ row }">
                            {{ localise(row.fixed_cost, 2, event.currency_code) }}
                        </template>
                        <template #column(budgeted_cost)="{ row }">
                            {{ localise(row.budgeted_cost, 2, event.currency_code) }}
                        </template>
                        <template #column(actions)="{ row }">
                            <ApprovalControls
                                :bulk-controls="true"
                                :approvables="pluckApprovables(row)"
                                :hide-request="true"
                                :locked="eventIsClosed"
                            />
                        </template>
                        <template #expanded-row="{ row: selectedRow }">
                            <table-categories
                                :is-open="true"
                                :event="Auth.event"
                                :checkable="true"
                                :endpoint="
                                    route('api.account.event.categories.approvalsList', {
                                        department: selectedRow.id,
                                    })
                                "
                            ></table-categories>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApprovalControls from '../../../../../js/components/widgets/ApprovalControls.vue';
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { useDataStore } from '@/js/stores/DataStore.js';

export default {
    components: {
        LoadingSpinner,
        ApprovalControls,
        DataTable,
    },
    props: {
        endpoint: {
            type: String,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        isOpen: {
            type: Boolean,
            default: true,
        },
        searchable: {
            type: Object,
            default() {
                return {};
            },
            required: false,
        },
    },
    data() {
        const store = useDataStore();
        return {
            isLoaded: false,
            data: [],
            departments: [],
            event: Auth.event,
            store: store,
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
    },
    watch: {
        isOpen: function (isOpenNow) {
            if (isOpenNow) {
                this.reloadData();
            } else {
                this.resetData();
            }
        },
    },
    mounted() {
        if (this.isOpen) {
            this.reloadData();
        }

        Eventbus.$on('refresh:approvals', () => this.reloadData());
    },
    methods: {
        reloadData() {
            this.isLoaded = false;
            axios.get(this.endpoint).then(({ data }) => {
                this.departments = data;
                this.isLoaded = true;
            });
        },
        resetData() {
            this.isLoaded = false;
            this.data = [];
        },
        pluckApprovables(department) {
            let approvables = [];

            department.categories.forEach((category) => {
                category.entries?.forEach((entry) => {
                    approvables.push(entry);
                });
            });

            return approvables;
        },
    },
};
</script>
<style scoped>
td {
    vertical-align: middle !important;
}
</style>
