<template>
    <Modal ref="modal" title="Clone Event" size="is-large" @closed="reset()">
        <template v-if="cloningEvent && !isCloning">
            <FormField label="New Event Name">
                <Textbox v-model="newEventName" placeholder="Required" />
            </FormField>
            <Checkbox v-model="copyBudgetEntries">Copy budget entries</Checkbox>
            <Banner type="is-info" class="tw-mt-2">
                The cloned event will be created with the same categories and users as the original event. All dates
                will be set to today's date but can be changed within event settings.
            </Banner>
        </template>
        <template v-else-if="isCloning">
            We're currently cloning {{ cloningEvent.name }} into {{ newEventName }}. This may take a few moments.
        </template>
        <template #footer>
            <Button type="is-success" :disabled="!isValid || isCloning" @click="cloneEvent()">Clone Event</Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
        Banner,
        Checkbox,
    },
    emits: ['cloned'],
    data() {
        return {
            isCloning: false,
            cloningEvent: null,
            newEventName: null,
            copyBudgetEntries: true,
        };
    },
    computed: {
        isValid() {
            return !!this.newEventName;
        },
    },
    mounted() {
        Eventbus.$on('admin:cloned-event', this.postCloneEvent);
    },
    methods: {
        open(event) {
            this.cloningEvent = event;
            this.$refs.modal.open();
        },
        reset() {
            this.isCloning = false;
            this.cloningEvent = null;
            this.newEventName = null;
            this.copyBudgetEntries = true;
            this.$emit('cloned');
        },
        close() {
            this.$refs.modal.close();
        },
        cloneEvent() {
            this.isCloning = true;
            axios.post(
                route('api.admin.account.event.clone', {
                    account: this.cloningEvent.account_id,
                    event: this.cloningEvent.id,
                }),
                {
                    name: this.newEventName,
                    copy_entries: this.copyBudgetEntries,
                }
            );
        },
        postCloneEvent(id) {
            if (id !== this.cloningEvent.id) {
                return;
            }
            this.close();
        },
    },
};
</script>
