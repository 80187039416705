<template>
    <Modal ref="modal" title="Change Email Address" size="is-xlarge" @closed="reset()">
        <FormField class="tw-mb-3" label="New Email Address">
            <Textbox v-model="newEmail" required placeholder="john.doe@example.com" :disabled="isLoading" />
        </FormField>
        <Banner type="is-info">
            A link will be sent to your new email address to confirm the change, once you have confirmed the change your
            email will then be updated.
        </Banner>
        <template #footer>
            <Button type="is-success" :disabled="!isValid" :loading="isLoading" @click="changeEmail()">
                Send Confirmation
            </Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { isValidEmail, toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
        Banner,
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            originalEmail: store.get('current_user.email'),
            newEmail: store.get('current_user.email'),
        };
    },
    computed: {
        isValid() {
            return !!this.newEmail && isValidEmail(this.newEmail) && this.newEmail !== this.originalEmail;
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.newEmail = this.originalEmail;
            this.isLoading = false;
        },
        changeEmail() {
            this.isLoading = true;
            axios
                .post(route('api.user.updateEmail'), {
                    email: this.newEmail,
                })
                .then(() => {
                    this.close();
                    toast(
                        'Confirmation Sent',
                        'Click the link sent to your new email address to change your email address',
                        'success'
                    );
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
