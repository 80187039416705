<template>
    <div class="ew-statistic-row flex-fill">
        <slot :data="data" :loading="isLoading"></slot>
    </div>
</template>
<script>
export default {
    props: {
        endpoint: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true,
            data: [],
        };
    },
    mounted() {
        this.reloadData();
        Eventbus.$on('reload:statistics', () => {
            this.reloadData();
        });
    },
    methods: {
        reloadData() {
            if (!this.endpoint) {
                return;
            }
            this.isLoading = true;
            axios
                .get(this.endpoint)
                .then((response) => (this.data = response.data))
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
