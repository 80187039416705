<template>
    <div>
        <div class="tw-flex tw-items-start tw-mb-2.5">
            <div class="tw-flex tw-gap-1 tw-items-center">
                <Button class="tw-flex tw-gap-1" :active="scope === 'sales'" @click="scope = 'sales'">
                    <i class="mdi mdi-ticket-confirmation"></i>
                    Sales
                </Button>
                <Button class="tw-flex tw-gap-1" :active="scope === 'revenue'" @click="scope = 'revenue'">
                    <i class="mdi mdi-currency-gbp"></i>
                    Revenue
                </Button>
            </div>
            <Button v-if="Auth.can('manage tickets') && !eventIsClosed" class="tw-ml-1" @click="logTicketSales()">
                <i class="mdi mdi-database-plus"></i>
                Log Ticket Transaction
            </Button>
            <div class="tw-flex tw-gap-1 tw-items-center tw-ml-auto">
                <Button type="is-link" @click="$refs.selectTicketTypesModal.open()">
                    Showing
                    {{ selectedTicketTypes.length === ticketTypes.length ? 'all' : selectedTicketTypes.length }}
                    ticket types
                </Button>
                <Button :active="duration === 'weekly'" @click="duration = 'weekly'">Weekly</Button>
                <Button :active="duration === 'monthly'" @click="duration = 'monthly'">Monthly</Button>
            </div>
        </div>
        <div class="tw-grid md:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-gap-2">
            <ew-card :title="chartTitle" class="tw-w-full">
                <TicketSalesChart
                    :data="currentData"
                    :revenue="scope === 'revenue'"
                    :monthly="duration === 'monthly'"
                    :current-period="currentPeriodIndex"
                    :show-sold-out-target="showSoldOutTarget"
                />
            </ew-card>
            <ew-card title="Summary" paddingless class="tw-w-full">
                <TicketSalesTable
                    :current-period="currentPeriodData"
                    :monthly="duration === 'monthly'"
                    :revenue="scope === 'revenue'"
                />
            </ew-card>
            <ew-card title="Sales Targets" class="tw-w-full md:tw-col-span-2 2xl:tw-col-span-1">
                <template #actions>
                    <Tooltip v-if="!eventIsClosed" label="New Sales Target" position="left">
                        <Button @click="createTicketTarget()">
                            <i class="mdi mdi-plus"></i>
                        </Button>
                    </Tooltip>
                </template>
                <DataTable
                    v-model="selectedTargets"
                    :data="targets"
                    checkable
                    :columns="[
                        { title: 'Name', field: 'name' },
                        { title: 'Type', field: 'type' },
                        { title: '', field: 'actions' },
                    ]"
                    @update:model-value="getCurrentData()"
                >
                    <template #column(type)="{ row }">
                        <span v-if="row.type === 'weekly'">Weekly</span>
                        <span v-else>Monthly</span>
                    </template>
                    <template #column(actions)="{ row }">
                        <div class="tw-flex tw-gap-1 tw-justify-end">
                            <Tooltip label="Edit">
                                <Button @click="editTicketTarget(row)">
                                    <i class="mdi mdi-pencil"></i>
                                </Button>
                            </Tooltip>
                            <Tooltip label="Delete">
                                <Button class="tw-ml-1" @click="deleteTicketTarget(row.id)">
                                    <i class="mdi mdi-delete"></i>
                                </Button>
                            </Tooltip>
                        </div>
                    </template>
                    <template #empty>
                        <span class="tw-text-gray-400">You haven't created any custom targets just yet!</span>
                        <br />
                        <a v-if="!eventIsClosed" href="#" @click.prevent="createTicketTarget()">Create one now</a>
                    </template>
                    <template #footer>
                        <td><Checkbox v-model="showSoldOutTarget" class="tw-m-0" /></td>
                        <td>Sold Out Target (default)</td>
                        <td></td>
                    </template>
                </DataTable>
            </ew-card>
        </div>
        <ew-card v-if="currentData" title="Detailed View" paddingless>
            <table class="ticket-data-table table table-sm smaller-text mb-0">
                <thead>
                    <tr class="data-row">
                        <th class="blank" rowspan="3"></th>
                        <th
                            v-for="(target, targetIndex) in selectedTargets"
                            :key="targetIndex"
                            :class="'data-cell start end bg-target-' + (targetIndex + 1)"
                            colspan="2"
                        >
                            {{ target.name }}
                        </th>
                        <th v-if="showSoldOutTarget" class="data-cell start end bg-soldout" colspan="2">
                            Sold Out Target
                        </th>
                        <th class="data-cell start end" :class="{ 'bg-current': selectedTargets.length }" colspan="4">
                            Current Sales
                        </th>
                        <th
                            v-if="selectedTargets.length || showSoldOutTarget"
                            class="data-cell start end"
                            :colspan="selectedTargets.length * 2 + (showSoldOutTarget ? 2 : 0)"
                        >
                            Variance
                        </th>
                    </tr>
                    <tr class="data-row">
                        <!-- Custom targets -->
                        <template v-for="(target, targetIndex) in selectedTargets" :key="targetIndex">
                            <th :class="'data-cell start end bg-target-' + (targetIndex + 1)" rowspan="2">
                                Weekly Count
                            </th>
                            <th :class="'data-cell start end bg-target-' + (targetIndex + 1)" rowspan="2">
                                Total Count
                            </th>
                        </template>
                        <!-- Sold out target -->
                        <template v-if="showSoldOutTarget">
                            <th class="data-cell start end bg-soldout" rowspan="2">Weekly Count</th>
                            <th class="data-cell start end bg-soldout" rowspan="2">Total Revenue (Gross)</th>
                        </template>
                        <!-- Current sales -->
                        <th class="data-cell start end" :class="{ 'bg-current': selectedTargets.length }" colspan="2">
                            Weekly
                        </th>
                        <th class="data-cell start end" :class="{ 'bg-current': selectedTargets.length }" colspan="2">
                            Total
                        </th>
                        <!-- Variances -->
                        <th
                            v-for="(target, targetIndex) in selectedTargets"
                            :key="'variance_' + targetIndex"
                            class="data-cell start end"
                            colspan="2"
                        >
                            {{ target.name }}
                        </th>
                        <th v-if="showSoldOutTarget" class="data-cell end" colspan="2">Sold Out Target</th>
                    </tr>
                    <tr class="data-row">
                        <th class="data-cell start" :class="{ 'bg-current': selectedTargets.length }">Count</th>
                        <th class="data-cell end" :class="{ 'bg-current': selectedTargets.length }">Revenue (Gross)</th>
                        <th class="data-cell start" :class="{ 'bg-current': selectedTargets.length }">Count</th>
                        <th class="data-cell end" :class="{ 'bg-current': selectedTargets.length }">Revenue (Gross)</th>
                        <template v-for="(target, targetIndex) in selectedTargets" :key="targetIndex">
                            <th class="data-cell start">Weekly</th>
                            <th class="data-cell end">Total</th>
                        </template>
                        <template v-if="showSoldOutTarget">
                            <th class="data-cell start">Weekly</th>
                            <th class="data-cell end">Total</th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, index) in currentData"
                        :key="index"
                        class="data-row"
                        :class="{ 'd-none': !showPastData && currentPeriodIndex && index < currentPeriodIndex }"
                    >
                        <td>{{ row.label }}</td>
                        <!-- Target weekly/total values -->
                        <template v-for="(target, targetIndex) in selectedTargets" :key="targetIndex">
                            <td
                                :class="
                                    'data-cell start bg-target-' +
                                    (targetIndex + 1) +
                                    (duration === 'weekly' ? ' selectable' : '')
                                "
                                @click="
                                    editTicketTargetRow(
                                        targetIndex,
                                        index,
                                        index
                                            ? target[targetDataKey][index] - target[targetDataKey][index - 1]
                                            : target[targetDataKey][index]
                                    )
                                "
                            >
                                {{
                                    localise(
                                        index
                                            ? target[targetDataKey][index] - target[targetDataKey][index - 1]
                                            : target[targetDataKey][index],
                                        0,
                                        false
                                    )
                                }}
                            </td>
                            <td :class="'data-cell end bg-target-' + (targetIndex + 1)">
                                {{ localise(selectedTargets[targetIndex][targetDataKey][index], 0, false) }}
                            </td>
                        </template>
                        <!-- Sold out target -->
                        <template v-if="showSoldOutTarget && currentData">
                            <td class="data-cell bg-soldout start">
                                {{
                                    index === 0
                                        ? row.data.running_total_stretch
                                        : row.data.running_total_stretch -
                                          currentData[index - 1]['data']['running_total_stretch']
                                }}
                            </td>
                            <td class="data-cell bg-soldout end">
                                {{ localise(row.data.running_total_stretch_revenue, 2, true) }}
                            </td>
                        </template>
                        <!-- Current Sales -->
                        <td class="data-cell start" :class="{ 'bg-current': selectedTargets.length }">
                            {{ localise(row.data.total, 0, false) }}
                        </td>
                        <td class="data-cell end" :class="{ 'bg-current': selectedTargets.length }">
                            {{ localise(row.data.total_revenue, 2, true) }}
                        </td>
                        <td class="data-cell start" :class="{ 'bg-current': selectedTargets.length }">
                            {{ localise(row.data.running_total, 0, false) }}
                        </td>
                        <td class="data-cell end" :class="{ 'bg-current': selectedTargets.length }">
                            {{ localise(row.data.running_total_revenue, 2, true) }}
                        </td>
                        <!-- Target Variances -->
                        <template v-for="(target, targetIndex) in selectedTargets" :key="targetIndex">
                            <td
                                class="data-cell start"
                                :class="{
                                    'figure-negative':
                                        row.data.total -
                                            (index
                                                ? target[targetDataKey][index] - target[targetDataKey][index - 1]
                                                : target[targetDataKey][index]) <
                                        0,
                                    'figure-positive':
                                        row.data.total -
                                            (index
                                                ? target[targetDataKey][index] - target[targetDataKey][index - 1]
                                                : target[targetDataKey][index]) >
                                        0,
                                }"
                            >
                                {{
                                    localise(
                                        row.data.total -
                                            (index
                                                ? target[targetDataKey][index] - target[targetDataKey][index - 1]
                                                : target[targetDataKey][index]),
                                        0,
                                        false
                                    )
                                }}
                            </td>
                            <td
                                class="data-cell end"
                                :class="{
                                    'figure-negative': calcYearlyTargetVariance(targetIndex, index) < 0,
                                    'figure-positive': calcYearlyTargetVariance(targetIndex, index) > 0,
                                }"
                            >
                                {{ localise(calcYearlyTargetVariance(targetIndex, index), 0, false) }}
                            </td>
                        </template>
                        <!-- Sold Out Target Variances -->
                        <template v-if="showSoldOutTarget">
                            <td
                                class="data-cell start"
                                :class="{
                                    'figure-negative': row.data.total_stretch - row.data.total > 0,
                                    'figure-positive': row.data.total_stretch - row.data.total < 0,
                                }"
                            >
                                {{ (row.data.total_stretch - row.data.total) * -1 }}
                            </td>
                            <td
                                class="data-cell end"
                                :class="{
                                    'figure-negative': row.data.running_total_stretch - row.data.running_total > 0,
                                    'figure-positive': row.data.running_total_stretch - row.data.running_total < 0,
                                }"
                            >
                                {{ (row.data.running_total_stretch - row.data.running_total) * -1 }}
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
            <template #actions>
                <Checkbox v-model="showPastData" class="tw-mb-0">
                    Show previous {{ duration === 'weekly' ? 'weeks' : 'months' }}
                </Checkbox>
            </template>
        </ew-card>
        <TicketTargetEditor ref="ticketTargetEditor" @updated="getTicketTargets()" />
        <Modal ref="selectTicketTypesModal" title="Select Ticket Types" paddingless>
            <p class="tw-m-0 tw-px-3 tw-py-2">
                Select the ticket types you want to be included in the data within the ticketing dashboard.
            </p>
            <DataTable
                v-model="selectedTicketTypes"
                :data="ticketTypes"
                :columns="[
                    {
                        title: 'Name',
                        field: 'name',
                    },
                ]"
                checkable
            />
        </Modal>
    </div>
</template>

<script>
import TicketSalesChart from './TicketSalesChart.vue';
import TicketSalesTable from './TicketSalesTable.vue';
import TicketTargetEditor from './TicketTargetEditor.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Modal from '@/js/components/Modal.vue';

export default {
    components: {
        TicketSalesChart,
        TicketSalesTable,
        TicketTargetEditor,
        Button,
        Tooltip,
        Checkbox,
        DataTable,
        Modal,
    },
    emits: ['add-transaction'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            targets: [],
            ticketTypes: Object.values(store.get('current_event.ticket_types')),
            selectedTargets: [],
            selectedTicketTypes: JSON.parse(JSON.stringify(Object.values(store.get('current_event.ticket_types')))),
            scope: 'sales',
            duration: 'weekly',
            currentData: null,
            showPastData: false,
            showSoldOutTarget: true,
            store: store,
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        targetDataKey() {
            return this.duration === 'weekly' ? 'weekly_targets' : 'monthly_targets';
        },
        chartTitle() {
            return (
                (this.duration === 'weekly' ? 'Weekly' : 'Monthly') +
                ' Ticket ' +
                (this.scope === 'sales' ? 'Sales' : 'Revenue')
            );
        },
        currentPeriodIndex() {
            if (!this.currentData) {
                return;
            }
            let dataIndex = 0;
            this.currentData.forEach((dataPoint, index) => {
                if (new Date(dataPoint.period_start).getTime() < new Date().getTime()) {
                    dataIndex = index;
                }
            });
            return dataIndex;
        },
        currentPeriodData() {
            if (!this.currentPeriodIndex) {
                return;
            }
            return this.currentData[this.currentPeriodIndex];
        },
    },
    watch: {
        duration() {
            this.getCurrentData();
        },
        selectedTicketTypes: {
            handler() {
                this.debouncedGetCurrentData();
            },
            deep: true,
        },
    },
    mounted() {
        this.getCurrentData();
        this.getTicketTargets();
    },
    methods: {
        getCurrentData(targets = null) {
            this.isLoading = true;
            this.currentData = null;
            axios
                .get(
                    route('api.account.event.orders.detailed', {
                        monthly: this.duration === 'monthly',
                        targets: targets
                            ? targets.map((target) => target.id)
                            : this.selectedTargets.map((target) => target.id),
                        events: [],
                        ticketTypes: this.selectedTicketTypes.map((ticketType) => ticketType.id),
                    })
                )
                .then((response) => {
                    this.currentData = response.data;
                })
                .finally(() => (this.isLoading = false));
        },
        debouncedGetCurrentData: debounce(function (targets = null) {
            this.getCurrentData(targets);
        }, 250),
        getTicketTargets(clearSelected = true) {
            this.isLoading = true;
            axios
                .get(route('api.account.event.targets'))
                .then((response) => {
                    this.targets = response.data;
                    if (clearSelected) {
                        this.selectedTargets = [];
                    } else {
                        const previousTargets = this.selectedTargets.map((target) => target.id);
                        this.selectedTargets = [];
                        previousTargets.forEach((targetId) => {
                            const target = this.targets.find((target) => target.id === targetId);
                            if (target) {
                                this.selectedTargets.push(target);
                            }
                        });
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        createTicketTarget() {
            this.$refs.ticketTargetEditor.open();
        },
        editTicketTarget(ticketTarget) {
            this.$refs.ticketTargetEditor.open(ticketTarget);
        },
        async editTicketTargetRow(targetIndex, index, diff) {
            if (this.duration !== 'weekly') {
                return;
            }
            const { value: confirmation } = await swal.fire({
                title: 'Update Target',
                text: 'You are about to change this target',
                input: 'number',
                inputValue: diff,
                showCancelButton: true,
            });
            if (!confirmation) {
                return;
            }
            this.isLoading = true;
            const newDiff = confirmation - diff;
            const newTarget = JSON.parse(JSON.stringify(this.selectedTargets[targetIndex]));
            newTarget.type = 'weekly';
            newTarget.targets = newTarget.weekly_targets.map((target, tindex) => {
                if (tindex >= index) {
                    return target + newDiff;
                }
                return target;
            });
            axios
                .post(
                    route('api.account.event.targets.update', {
                        ticketTarget: newTarget.id,
                    }),
                    newTarget
                )
                .then(() => this.getTicketTargets(false));
        },
        async deleteTicketTarget(id) {
            const { value: confirmation } = await swal.fire({
                title: 'Are you sure?',
                text: 'You are about to permanently delete this ticket target',
                showCancelButton: true,
            });
            if (!confirmation) {
                return;
            }
            this.isLoading = true;
            axios
                .delete(
                    route('api.account.event.targets.delete', {
                        ticketTarget: id,
                    })
                )
                .then(() => this.getTicketTargets())
                .finally(() => (this.isLoading = false));
        },
        isTargetRowCheckable(row) {
            return !!this.selectedTargets.find((target) => target.id === row.id) || this.selectedTargets.length <= 1;
        },
        calcYearlyTargetVariance(targetIndex, index) {
            return (
                this.currentData[index].data.running_total -
                this.selectedTargets[targetIndex][this.targetDataKey][index]
            );
        },
        logTicketSales() {
            this.$emit('add-transaction');
        },
    },
};
</script>
<style lang="scss" scoped>
.ticket-data-table {
    .blank {
        background: rgba(black, 0.1);
    }

    .data-row {
        .data-cell {
            text-align: center !important;

            &.selectable:hover {
                cursor: pointer;
                box-shadow: inset 0 0 2px black;
            }
            &.start {
                border-left: 2px solid rgba(black, 0.1);
                border-right: 1px solid rgba(black, 0.05);
            }
            &.end {
                border-left: 1px solid rgba(black, 0.05);
                border-right: 2px solid rgba(black, 0.1);
            }
            &.figure-negative {
                background: rgba(#e36588, 0.25);
            }
            &.figure-positive {
                background: rgba(#28a38d, 0.25);
            }
        }
    }

    .bg-current {
        background: #9ad0f5;
        border-color: rgba(black, 0.1) !important;
        vertical-align: middle !important;
    }
    .bg-soldout {
        background: #a6d6af;
        border-color: rgba(black, 0.1) !important;
        vertical-align: middle !important;
    }
    .bg-target-1 {
        background: #ffcf9f;
        border-color: rgba(black, 0.1) !important;
        vertical-align: middle !important;
    }
    .bg-target-2 {
        background: #ffe6aa;
        border-color: rgba(black, 0.1) !important;
        vertical-align: middle !important;
    }
    .bg-target-3 {
        background: #a5dfdf;
        border-color: rgba(black, 0.1) !important;
        vertical-align: middle !important;
    }
    .bg-target-4 {
        background: #ccb2ff;
        border-color: rgba(black, 0.1) !important;
        vertical-align: middle !important;
    }
}
</style>
