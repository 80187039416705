<template>
    <Modal ref="modal" title="Raise Purchase Invoice" :loading="isLoading" size="is-xxxlarge" @closing="resetModal()">
        <div v-if="purchaseOrder">
            <template v-if="step === 0">
                <FormField v-if="line_item_count" label="Line Items">
                    <Select
                        v-model="selectedLineItems"
                        multiple
                        rows="4"
                        class="tw-w-full"
                        @update:model-value="loadItemsIntoForm"
                    >
                        <option v-for="lineItem in purchaseOrder.line_items" :key="lineItem.id" :value="lineItem.id">
                            {{ lineItem.description }} - {{ localise(lineItem.subtotal, 2, true) }}
                        </option>
                    </Select>
                </FormField>
                <Banner v-else>
                    There are no line items associated with this purchase order.
                    <br />
                    Please reset to draft, add at least one line item and submit for reapproval in order to raise an
                    invoice.
                </Banner>
            </template>

            <template v-else>
                <div class="row mb-2">
                    <div class="col-4">
                        <FormField label="Invoice Number">
                            <Textbox v-model="formData.invoice_number" maxlength="32" class="tw-w-full" />
                        </FormField>
                    </div>
                    <div class="col-4">
                        <FormField label="Invoice Date">
                            <flat-pickr
                                v-model="formData.invoice_date"
                                placeholder="Required"
                                :config="flatpickrConfig"
                                class="input is-small"
                                required
                            ></flat-pickr>
                        </FormField>
                    </div>
                    <div class="col-4">
                        <FormField class="tw-pl-1" label="Due Date">
                            <flat-pickr
                                v-model="formData.due_date"
                                placeholder="Required"
                                :config="flatpickrConfig"
                                class="input is-small"
                                required
                            ></flat-pickr>
                        </FormField>
                    </div>
                </div>

                <line-item-builder
                    v-model="formData.items"
                    :is_invoice="true"
                    :is_purchase="true"
                    tax-rate-type="expense"
                    :hidden-columns="['is_invoiced', 'actions']"
                    @unit-price-change="handleAmountChange"
                    @quantity-change="handleAmountChange"
                >
                    <template #col-append-header>
                        <th>Remaining (Gross)</th>
                        <th>Amount (Gross)</th>
                        <th></th>
                    </template>
                    <template #col-append="{ row, index }">
                        <td>
                            {{ localise(row.max_value, 2, true) }}
                        </td>
                        <td>
                            <Currency v-model="row.value" />
                        </td>
                        <td>
                            <Button type="is-danger" @click="formData.items.splice(index, 1)">
                                <i class="mdi mdi-close"></i>
                            </Button>
                        </td>
                    </template>
                </line-item-builder>

                <div class="row mt-2">
                    <div class="col-4">
                        <FormField label="Source Currency">
                            {{ availableCurrencies[purchaseOrder.source_currency] }}
                        </FormField>
                    </div>
                    <div
                        :class="{
                            'col-4':
                                formData.target_currency && formData.target_currency != purchaseOrder.source_currency,
                            'col-8':
                                !formData.target_currency || formData.target_currency == purchaseOrder.source_currency,
                        }"
                    >
                        <FormField label="Target Currency">
                            <Select
                                v-model="formData.target_currency"
                                class="tw-w-full"
                                @update:model-value="getDefaultConversionRate()"
                            >
                                <option
                                    v-for="(currency, currencyCode) in availableCurrencies"
                                    :key="currencyCode"
                                    :value="currencyCode"
                                >
                                    {{ currency }}
                                </option>
                            </Select>
                        </FormField>
                    </div>
                    <div
                        v-if="formData.target_currency && formData.target_currency != purchaseOrder.source_currency"
                        class="col-4"
                    >
                        <FormField v-if="formData.target_currency" label="Conversion Rate">
                            <NumberInput v-model="formData.conversion_rate" min="0" />
                            <Button @click="openCurrencyConverter()">
                                <i class="mdi mdi-calculator"></i>
                            </Button>
                        </FormField>
                    </div>
                </div>

                <FormField class="tw-mt-4">
                    <Upload
                        v-model="formData.files"
                        text="Upload documents"
                        :accept="[
                            'application/pdf',
                            '.xlsx',
                            '.doc',
                            '.docx',
                            'application/msword',
                            'application/vnd.ms-excel',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'image/*',
                        ]"
                        multiple
                    />
                    <div v-if="formData.files.length" class="tags mt-2">
                        <span v-for="(file, index) in formData.files" :key="index" class="tag is-primary">
                            {{ file.name }}
                            <button
                                class="delete is-small"
                                type="button"
                                @click.prevent="removeAttachment(index)"
                            ></button>
                        </span>
                    </div>
                </FormField>

                <Banner v-if="hasExceededLineItemValue" type="is-warning">
                    One or more of your amounts exceeds the remaining amount for the line item.
                    <br />
                    Please double check your invoice amounts and set the appropriate tax rate.
                </Banner>
            </template>
        </div>

        <template #footer>
            <Button :disabled="isLoading" @click="closeModal()">Cancel</Button>
            <FormField v-if="purchaseOrder && purchaseOrder.supplier" class="tw-pl-4" label="Supplier">
                {{ purchaseOrder.supplier.name }}
            </FormField>
            <div class="flex-fill"></div>
            <FormField v-if="step !== 0" class="tw-text-right tw-pr-5" label="Invoice Value">
                {{ localise(totalValue, 2, true) }}
            </FormField>

            <Button v-if="step > 0" class="mr-1" @click="step--">Back</Button>
            <Button
                v-if="step === 0 || step < totalSteps"
                type="is-success"
                :disabled="!selectedLineItems.length"
                @click="step++"
            >
                Next
            </Button>
            <Button
                v-else
                type="is-success"
                :disabled="!selectedLineItems.length || hasExceededLineItemValue"
                @click="debouncedRaiseInvoice()"
            >
                Raise Invoice
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../Modal.vue';
import LineItemBuilder from '../../widgets/LineItemBuilder.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Select from '@/js/components/controls/Select.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Upload from '@/js/components/controls/Upload.vue';
import Currency from '@/js/components/controls/Currency.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import { obj2form, toast } from '@/js/utils.js';
import debounce from 'lodash/debounce';

export default {
    components: {
        Modal,
        LineItemBuilder,
        Button,
        FormField,
        Banner,
        Select,
        Textbox,
        Upload,
        Currency,
        NumberInput,
    },
    emits: ['created', 'reload'],
    data() {
        const store = useDataStore();
        return {
            step: 0,
            totalSteps: 1,
            isLoading: false,
            isLoadingConversionRate: false,
            purchaseOrder: null,
            selectedLineItems: [],
            availableCurrencies: store.get('available_currencies'),
            formData: {
                invoice_date: new Date().toISOString().split('T')[0],
                due_date: new Date().toISOString().split('T')[0],
                target_currency: null,
                conversion_rate: 1,
                items: [],
                files: [],
            },
            taxRates: Object.values(store.get('account.tax_rates')),
            store: store,
        };
    },
    computed: {
        totalValue() {
            if (!this.formData.items) {
                return 0;
            }
            return this.formData.items
                .map((item) => (item.value && !isNaN(item.value) ? item.value : 0))
                .reduce((a, b) => a + b);
        },
        line_item_count() {
            return Object.values(this.purchaseOrder.line_items).length;
        },
        hasExceededLineItemValue() {
            let quantityOverValue = 0;
            this.formData.items.forEach((item) => {
                const taxRate = this.taxRates.find((rate) => rate.id === item.tax_rate_id);
                const subtotal = taxRate ? (item.value / (100 + taxRate.rate)) * 100 : item.value;
                if (
                    parseInt(Math.round((subtotal + Number.EPSILON) * 100).toString()) >
                    parseInt(Math.round((item.max_value + Number.EPSILON) * 100).toString())
                ) {
                    quantityOverValue++;
                }
            });
            return !!quantityOverValue;
        },
    },
    mounted() {
        this.resetModal();
        Eventbus.$on('purchaseInvoice:create', (payload) => {
            this.loadPurchaseOrder(payload);
            this.openModal();
        });
    },
    methods: {
        loadPurchaseOrder(purchaseOrder) {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.purchaseorders.get', {
                        event: purchaseOrder.event_id,
                        purchaseOrder: purchaseOrder.id,
                    })
                )
                .then((response) => {
                    this.resetModal();
                    this.purchaseOrder = response.data;
                    if (this.line_item_count === 1) {
                        this.selectedLineItems.push(Object.values(this.purchaseOrder.line_items)[0].id);
                        this.loadItemsIntoForm();
                        this.step++;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        openModal() {
            if (this.$refs.modal) {
                this.$refs.modal.open();
            }
        },
        closeModal() {
            if (this.$refs.modal) {
                this.$refs.modal.close();
                this.resetModal();
            }
        },
        resetModal() {
            this.step = 0;
            this.purchaseOrder = null;
            this.selectedLineItems = [];
            this.formData = {
                invoice_number: '',
                invoice_date: new Date().toISOString().split('T')[0],
                due_date: new Date().toISOString().split('T')[0],
                target_currency: null,
                conversion_rate: 1,
                items: [],
                files: [],
            };
            this.formData.files = [];
        },
        loadItemsIntoForm() {
            this.formData.items = [];
            this.selectedLineItems.forEach((lineItemId) => {
                let lineItem = this.purchaseOrder.line_items.find((item) => item.id === lineItemId);
                if (!lineItem) {
                    return;
                }
                this.formData.items.push({
                    purchase_order_line_item_id: lineItem.id,
                    description: lineItem.description ?? null,
                    quantity: lineItem.quantity,
                    unit_price: parseFloat(lineItem.unit_price ?? 0).toFixed(2),
                    tax_rate_id: lineItem.tax_rate_id ?? null,
                    total_invoiced_gross: lineItem.total_invoiced_gross,
                    value: lineItem.total - lineItem.total_invoiced_gross,
                    max_value: lineItem.total - lineItem.total_invoiced_gross,
                });
            });
            this.formData.target_currency = this.purchaseOrder.target_currency;
            this.formData.conversion_rate = this.purchaseOrder.conversion_rate;
        },
        debouncedRaiseInvoice: debounce(function () {
            this.raiseInvoice();
        }, 500),
        raiseInvoice() {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.purchaseorders.invoices.create', {
                        event: this.purchaseOrder.event_id,
                        purchaseOrder: this.purchaseOrder.id,
                    }),
                    obj2form(this.formData)
                )
                .then((response) => {
                    this.isLoading = false;
                    toast('Invoice Created', 'Your invoice has been created successfully', 'success');
                    this.$emit('created', response.data.id);
                    this.closeModal();
                    this.$emit('reload');
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        removeAttachment(index) {
            this.formData.files.splice(index, 1);
        },
        openCurrencyConverter() {
            Eventbus.$emit('currencyConverter');
        },
        getDefaultConversionRate() {
            this.isLoadingConversionRate = true;
            axios
                .get(
                    route('api.currency.convert', {
                        inputtext: '1',
                        convert_from: this.purchaseOrder.source_currency,
                        convert_to: this.formData.target_currency,
                    })
                )
                .then(({ data }) => (this.formData.conversion_rate = data.rate))
                .finally(() => (this.isLoadingConversionRate = false));
        },
        handleAmountChange(row, index) {
            if (!row.unit_price || !row.quantity) {
                return;
            }
            this.formData.items[index].value = parseFloat(row.unit_price) * parseFloat(row.quantity);
        },
    },
};
</script>

<style>
.min-w-135px {
    min-width: 135px;
}
</style>
