<template>
    <FormField label="Primary Currency">
        <Select v-model="model" placeholder="Required" class="tw-w-full" :disabled="disabled" required>
            <option value="GBP">Pound Sterling (GBP)</option>
            <option value="EUR">Euro (EUR)</option>
            <option value="USD">US Dollar (USD)</option>
            <option value="AUD">Australian Dollar (AUD)</option>
            <option value="CHF">Swiss Franc (CHF)</option>
            <option value="JPY">Japanese Yen (JPY)</option>
            <option value="RUB">Russian Ruble (RUB)</option>
            <option value="SEK">Swedish Krona (SEK)</option>
            <option value="DKK">Danish Krone (DKK)</option>
            <option value="ZAR">South African Rand (ZAR)</option>
        </Select>
    </FormField>
</template>
<script setup>
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
});

const model = defineModel({
    type: String,
    default: null,
});
</script>
