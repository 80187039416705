<template>
    <Drawer ref="drawer" :loading="isLoading" :z-index="5002">
        <template #title>
            <h4>Manage Help Categories</h4>
        </template>

        <div class="p-4">
            <h5>Create A New Help Category</h5>
            <div class="help-float p-4 border rounded">
                <div class="d-flex">
                    <FormField class="flex-fill" label="Help Category Name">
                        <Textbox v-model="newHelpCategory['name']" maxlength="64" expanded />
                    </FormField>
                    <FormField class="pl-5" label="Icon">
                        <control-icon v-model="newHelpCategory['icon']" />
                    </FormField>
                </div>
                <div class="d-flex">
                    <FormField class="flex-fill" label="Description">
                        <Textbox v-model="newHelpCategory['description']" type="text" maxlength="255" expanded />
                    </FormField>
                </div>
                <Button type="is-success" class="tw-ml-auto tw-mt-3" @click="createNew">Create New Category</Button>
            </div>
        </div>

        <div class="p-4">
            <h5>Manage Help Categories</h5>
            <div v-for="category in helpCategories" :key="category.id" class="mb-2">
                <div class="help-float p-4 border rounded">
                    <div class="d-flex">
                        <FormField class="flex-fill" label="Help Category Name">
                            <Textbox v-model="category.name" maxlength="64" expanded />
                        </FormField>
                        <FormField class="pl-5" label="Icon">
                            <control-icon v-model="category.icon" />
                        </FormField>
                    </div>
                    <div class="d-flex">
                        <FormField class="flex-fill" label="Description">
                            <Textbox v-model="category.description" type="text" maxlength="255" expanded />
                        </FormField>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="tw-flex tw-justify-end">
                <Button type="is-success" @click="update">Save Categories</Button>
            </div>
        </template>
    </Drawer>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Drawer from '../Drawer.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';

export default {
    components: {
        Drawer,
        Button,
        FormField,
        Textbox,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            newHelpCategory: {
                name: '',
                icon: null,
                description: null,
            },
            helpCategories: [],
        };
    },
    mounted() {
        Eventbus.$on('manage:help-categories', () => this.open());
    },
    methods: {
        open() {
            axios
                .get(route('public-api.help.categories'))
                .then(({ data }) => {
                    this.helpCategories = data.categories;
                })
                .finally(() => (this.isLoaded = true));
            this.$refs.drawer.open();
        },
        createNew() {
            this.isLoaded = false;

            axios
                .post(route('api.admin.help.category.create'), this.newHelpCategory)
                .then(({ data }) => {
                    this.helpCategories = data.categories;
                })
                .finally(() => {
                    this.newHelpCategory = {
                        name: '',
                        icon: null,
                        description: null,
                    };
                    this.isLoaded = true;
                });
        },
        update() {
            this.isLoaded = false;

            axios
                .put(route('api.admin.help.category.update'), {
                    categories: this.helpCategories,
                })
                .finally(() => (this.isLoaded = true));

            this.$refs.drawer.close();
        },
    },
};
</script>
