<template>
    <div>
        <Button class="tw-flex tw-gap-2" type="is-info" @click="modal.open()">
            <i class="mdi mdi-pencil"></i>
            <span v-if="!model.length">
                {{ `All Categories (${categories.length})` }}
            </span>
            <span v-else-if="model.length === categories.length">All Categories ({{ categories.length }})</span>
            <span v-else>{{ model.length }}/{{ categories.length }} selected</span>
        </Button>
        <Modal ref="modal" title="Select Categories" size="is-xxlarge" paddingless>
            <DataTable
                :columns="[
                    { title: 'Name', field: 'name' },
                    { title: 'Department', field: 'department' },
                ]"
                :data="categories"
            >
                <template #column(name)="{ row }">
                    <div class="tw-flex tw-items-center tw-gap-3">
                        <Checkbox
                            :model-value="model.includes(row.id)"
                            @update:model-value="
                                (v) => (v ? model.push(row.id) : model.splice(model.indexOf(row.id), 1))
                            "
                        />
                        {{ row.name }}
                    </div>
                </template>
                <template #column(department)="{ row }">
                    {{ row.department.name }}
                </template>
            </DataTable>
        </Modal>
    </div>
</template>

<script setup>
import Modal from '../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { ref, watch } from 'vue';

const props = defineProps({
    events: {
        type: Array,
        default: () => [],
    },
});

const model = defineModel({
    type: [Array],
});

const modal = ref();
const categories = ref([]);

watch(
    () => props.events,
    () => {
        if (!props.events.length) {
            categories.value = [];
            return;
        }

        axios.get(route('api.account.approvals.matrices.categories', { events: props.events })).then((response) => {
            categories.value = response.data;
        });
    },
    { deep: true, immediate: true }
);
</script>
