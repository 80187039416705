<template>
    <Modal ref="modal" title="Select Previous Address" :loading="isLoading" @closed="reset()">
        <Textbox v-model="searchQuery" placeholder="Type to filter results..." />
        <div
            v-for="address in filteredAddresses"
            :key="address.id"
            class="tw-border tw-border-gray-200 tw-rounded-md tw-p-2 tw-mt-2"
        >
            <div class="tw-flex tw-justify-between tw-items-center">
                <div class="tw-pr-3 tw-text-xs">
                    <div class="tw-text-gray-400">{{ address.name }}</div>
                    <div>{{ address.address }}</div>
                </div>
                <Button @click="selectAddress(address)">Select</Button>
            </div>
        </div>
        <Banner v-if="!filteredAddresses.length" class="tw-mt-2" type="is-info">
            There are no previous addresses to select from{{
                searchQuery
                    ? ', try searching for another address. You can search by name or any of the address fields'
                    : ''
            }}.
        </Banner>
    </Modal>
</template>
<script>
import Modal from '../../../Modal.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Modal,
        Textbox,
        Button,
        Banner,
    },
    props: {
        account: {
            type: Number,
            required: true,
        },
    },
    emits: ['select'],
    data() {
        return {
            isLoading: false,
            addresses: [],
            searchQuery: '',
        };
    },
    computed: {
        filteredAddresses() {
            if (!this.searchQuery) {
                return this.addresses;
            }
            return this.addresses.filter((address) => {
                return (
                    address.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    address.address.toLowerCase().includes(this.searchQuery.toLowerCase())
                );
            });
        },
    },
    methods: {
        open() {
            this.getPreviousAddresses();
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.searchQuery = '';
            this.addresses = [];
        },
        getPreviousAddresses() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.addresses', {
                        account: this.account,
                    })
                )
                .then(({ data }) => (this.addresses = data))
                .finally(() => (this.isLoading = false));
        },
        selectAddress(address) {
            this.isLoading = true;
            this.close();
            this.$emit('select', address);
        },
    },
};
</script>
