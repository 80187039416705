<template>
    <div class="buefy-wrapper">
        <div class="d-flex align-items-center mb-2">
            <Textbox v-model="searchQuery" placeholder="Search for a user..." />
        </div>
        <DataTable
            :columns="[
                { title: 'ID', field: 'id', width: 80 },
                { title: 'Name', field: 'name' },
                { title: 'Email', field: 'email' },
                { title: 'Accounts', field: 'accounts_count', width: 75 },
                { title: 'Events', field: 'events_count', width: 75 },
                { title: '', field: 'action' },
            ]"
            :endpoint="tableEndpoint"
            :searchable-string="searchQuery"
            class="align-middle"
        >
            <template #column(name)="{ row }">
                <User :user="row" :hide-name="false" />
            </template>
            <template #column(action)="{ row }">
                <div class="tw-flex tw justify-content-end">
                    <Tooltip
                        v-if="
                            !row.email.endsWith('@synergitech.co.uk') && (!row.is_superadmin || isSynergiUserOrLocalEnv)
                        "
                        position="left"
                        :label="row.is_disabled ? 'Enable User' : 'Disable User'"
                    >
                        <Button :type="row.is_disabled ? 'is-danger' : null" @click="toggleUser(row)">
                            <i :class="row.is_disabled ? 'mdi mdi-lock' : 'mdi mdi-lock-open-variant'"></i>
                        </Button>
                    </Tooltip>
                    <Tooltip
                        v-if="isSynergiUserOrLocalEnv && !row.email.endsWith('@synergitech.co.uk')"
                        position="left"
                        :label="'Login as ' + row.name"
                    >
                        <Button @click="sudoUser(row)">
                            <i class="mdi mdi-login-variant"></i>
                        </Button>
                    </Tooltip>
                </div>
            </template>
            <template #empty>
                <div class="empty-label">There are no users to show</div>
            </template>
        </DataTable>
    </div>
</template>
<script>
import User from '../../widgets/User.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        User,
        Button,
        Textbox,
        Tooltip,
    },
    data() {
        const store = useDataStore();
        return {
            data: null,
            searchQuery: null,
            perPage: 10,
            sorts: {},
            store: store,
            tableEndpoint: route('api.admin.users'),
        };
    },
    computed: {
        isSynergiUserOrLocalEnv() {
            if (this.store.get('environment') === 'local') {
                return true;
            }

            return (
                this.store.get('current_user.is_superadmin') &&
                this.store.get('current_user.email').endsWith('@synergitech.co.uk')
            );
        },
    },
    methods: {
        toggleSort(field, order = null) {
            this.sorts = {};
            this.sorts[field] = order;
        },
        sudoUser(user) {
            swal.fire({
                title: 'Warning',
                html:
                    'You are about to be logged out of ' +
                    Auth.user.email +
                    ' (' +
                    Auth.user.name +
                    '), and will be logged directly into ' +
                    user.email +
                    ' (' +
                    user.name +
                    ').<br /><br />This feature is only to be used to debug advanced issues that normally would not be possible with a standard administrator account.<br /><br />By continuing you acknowledge that your use of this feature will be audited, that your intentions are lawful and within the scope of your role.',
                showCancelButton: true,
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (result.value) {
                    window.location.href = route('admin.sudo', {
                        userToSudo: user.id,
                    });
                }
            });
        },
        toggleUser(user) {
            axios
                .get(
                    route('api.admin.user.toggle', {
                        user: user.id,
                    })
                )
                .then(() => {
                    if (user.is_disabled) {
                        toast('Access Enabled', user.name + ' has been reactivated', 'success');
                    } else {
                        toast('Access Disabled', user.name + ' has been disabled and can no longer login', 'success');
                    }
                });
        },
    },
};
</script>
