<template>
    <div id="app">
        <MinimalPage>
            <template #before>
                <div class="logo-display">
                    <img src="@/images/icon-white.svg" />
                    <h1>eventwise</h1>
                </div>
                <div class="user-actions">
                    <a href="https://eventwise.com" class="py-3" target="_blank">
                        <i class="mdi mdi-web"></i>
                        Learn more about Eventwise
                    </a>
                </div>
            </template>
            <div class="text-center px-5">
                <template v-if="!isSuccess">
                    <p v-if="!invitedBy">
                        <span v-if="user.is_supplier">
                            You've been invited you to join the
                            <strong>Supplier Portal</strong>
                            on Eventwise.
                        </span>
                        <span v-else>
                            You've been invited to join
                            <strong>{{ account.name }}</strong>
                            on Eventwise.
                        </span>
                        <br />
                        Please check your details below, and create a secure password for your user account.
                    </p>
                    <div v-else class="invite-bubble-wrapper">
                        <User :user="invitedBy" size="xlarge" />
                        <div class="invite-bubble">
                            <small class="d-block text-muted h6 my-0">{{ invitedBy.name }}</small>
                            <span v-if="user.is_supplier">
                                Hey! I've invited you to join the
                                <strong>Supplier Portal</strong>
                                on Eventwise.
                            </span>
                            <span v-else>
                                Hey! I've invited you to join
                                <strong>{{ account.name }}</strong>
                                on Eventwise.
                            </span>
                            <br />
                            Check your details below and create a secure password to continue.
                        </div>
                    </div>
                    <form v-if="newUserData" class="text-left my-4">
                        <FormField label="Full Name" class="tw-mb-2">
                            <Textbox
                                key="registerName"
                                v-model="newUserData.name"
                                placeholder="Required"
                                required
                                :disabled="isLoading"
                            />
                        </FormField>
                        <FormField label="Phone Number" class="tw-mb-2">
                            <div class="tw-flex">
                                <Button>+44</Button>
                                <Textbox
                                    v-model="newUserData.phone"
                                    placeholder="Optional"
                                    :disabled="isLoading"
                                    class="tw-flex-1"
                                />
                            </div>
                        </FormField>
                        <FormField label="Password" :error="passwordError" class="tw-mb-2">
                            <Textbox
                                v-model="newUserData.password"
                                type="password"
                                placeholder="Required"
                                required
                                :disabled="isLoading"
                            />
                        </FormField>
                        <FormField label="Confirm Password" :error="passwordConfirmError" class="tw-mb-2">
                            <Textbox
                                v-model="newUserData.password_confirmation"
                                type="password"
                                placeholder="Required"
                                required
                                :disabled="isLoading"
                            />
                        </FormField>
                    </form>
                    <span v-if="user.is_supplier">
                        <Button
                            class="tw-w-full"
                            type="is-primary"
                            :disabled="!isValid"
                            :loading="isLoading"
                            @click="submitRegister()"
                        >
                            Finish setting up your portal access
                        </Button>
                    </span>
                    <span v-else>
                        <Button
                            class="tw-w-full"
                            type="is-primary"
                            :disabled="!isValid"
                            :loading="isLoading"
                            @click="submitRegister()"
                        >
                            Finish setting up your account
                        </Button>
                    </span>
                </template>
                <Banner v-else type="is-success">
                    <strong>Account Created</strong>
                    <br />
                    Your account has been setup successfully and you have been logged in.
                    <br />
                    You should be redirected to Eventwise shortly.
                </Banner>
            </div>
        </MinimalPage>
    </div>
</template>
<script>
import axios from 'axios';
import MinimalPage from './MinimalPage.vue';
import User from './widgets/User.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        MinimalPage,
        User,
        Button,
        FormField,
        Textbox,
        Banner,
    },
    data() {
        return {
            invite: window.InviteData.invite,
            account: window.InviteData.account,
            user: window.InviteData.user,
            invitedBy: window.InviteData.invited_by,
            isLoading: false,
            newUserData: null,
            isSuccess: false,
        };
    },
    computed: {
        passwordError() {
            let specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if (!this.newUserData || !this.newUserData.password) {
                return null;
            } else if (!(this.newUserData.password.length >= 8)) {
                return 'Your password must be at least 8 characters long';
            } else if (!/[A-Z]/.test(this.newUserData.password)) {
                return 'Your password must contain an uppercase letter';
            } else if (!/[0-9]/.test(this.newUserData.password)) {
                return 'Your password must contain a number';
            } else if (!specialChars.test(this.newUserData.password)) {
                return 'Your password must contain a special character';
            }
            return null;
        },
        passwordConfirmError() {
            if (!this.newUserData || !this.newUserData.password_confirmation) {
                return null;
            } else if (this.newUserData.password !== this.newUserData.password_confirmation) {
                return 'Your password confirmation must match the password above';
            }
            return null;
        },
        isValid() {
            if (!this.newUserData) {
                return false;
            }
            return (
                this.newUserData.name && this.newUserData.password && !this.passwordError && !this.passwordConfirmError
            );
        },
    },
    mounted() {
        this.newUserData = this.user;
    },
    methods: {
        submitRegister() {
            this.isLoading = true;
            axios
                .post(route('invitation-accept'), {
                    invite: this.invite,
                    name: this.newUserData.name,
                    phone: this.newUserData.phone,
                    password: this.newUserData.password,
                    password_confirmation: this.newUserData.password_confirmation,
                })
                .then(() => {
                    this.isSuccess = true;
                    window.location.href = route('onboarding');
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style lang="scss">
.spa-page-wrapper {
    padding: 60px 0 0 0;
}

.invite-bubble-wrapper {
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;

    & > .invite-bubble {
        flex: 1;
        padding: 10px 15px;
        border: 1px solid rgb(220, 220, 220);
        border-radius: 8px;
        position: relative;
        margin-left: 15px;
        text-align: left;

        &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-top: 1px solid rgb(220, 220, 220);
            border-left: 1px solid rgb(220, 220, 220);
            top: 14px;
            left: -6px;
            background: white;
            transform: rotate(-45deg);
        }
    }
}
</style>
