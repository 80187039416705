<template>
    <div>
        <Notifications v-if="currentUser" />
        <AccountEventSwitcher />
        <CurrencyConverter />
        <Welcome v-if="currentUser && !currentUser.accepted_terms" />
        <!-- Account specific Components -->
        <template v-if="currentAccount">
            <Subcategory />
            <Supplier />
            <ModalCreateTaxRate />
            <PurchaseOrder />
            <Department />
            <SendEmail />
            <template
                v-if="
                    currentAccount.is_supplier ||
                    currentRoute === 'account.contacts' ||
                    currentRoute === 'account.payruns'
                "
            >
                <ViewInvoice />
            </template>
        </template>
        <!-- Event specific Components -->
        <template v-if="currentEvent">
            <CreatePurchaseOrder />
            <CreateBudgetEntry />
            <!-- The ordering matters, if we try open a BE from the RE drawer, if RE comp is placed after BE comp, then BE drawer will display behind the RE drawer -->
            <RevenueEntry />
            <BudgetEntry />
            <BudgetEntryRecharge />
            <CreateRevenueEntry />
            <ModalCreateTicket />
            <ModalBankTransactions />
            <RaiseMultiPurchaseInvoice />
            <ViewInvoice />
            <GlobalSearch v-if="currentEvent && !currentAccount.is_supplier" class="tw-mx-auto" />
        </template>
        <Toast />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import { mentionables } from '../mentionables';
import Notifications from '../../assets/js/eventbooks/components/utility/notifications.vue';
import AccountEventSwitcher from './widgets/AccountEventSwitcher.vue';
import CurrencyConverter from './widgets/CurrencyConverter.vue';
import Subcategory from '../../assets/js/eventbooks/components/drawers/subcategory.vue';
import Supplier from './drawers/Supplier.vue';
import Welcome from './modals/Welcome.vue';
import CreatePurchaseOrder from '../../assets/js/eventbooks/components/drawers/new-purchase-order.vue';
import PurchaseOrder from './drawers/Purchasing/PurchaseOrder.vue';
import CreateBudgetEntry from '../../assets/js/eventbooks/components/new-budget-entry.vue';
import BudgetEntry from './drawers/BudgetEntry.vue';
import BudgetEntryRecharge from '../../assets/js/eventbooks/components/drawers/budget-entry-recharge.vue';
import CreateRevenueEntry from '../../assets/js/eventbooks/components/drawers/new-revenue-entry.vue';
import RevenueEntry from './drawers/Revenue/RevenueEntry.vue';
import ModalCreateTaxRate from './modals/TaxRate/CreateTaxRate.vue';
import ModalCreateTicket from '@/js/components/modals/Ticketing/CreateTicket.vue';
import ModalBankTransactions from '../../assets/js/eventbooks/components/bank-transactions/modal.vue';
import RaiseMultiPurchaseInvoice from './modals/Invoices/RaiseMultiPurchaseInvoice.vue';
import ViewInvoice from './modals/Invoices/ViewInvoice.vue';
import GlobalSearch from './widgets/GlobalSearch.vue';
import Department from './drawers/Department.vue';
import SendEmail from './modals/SendEmail.vue';
import Toast from './Toast.vue';

export default {
    components: {
        SendEmail,
        Department,
        Notifications,
        AccountEventSwitcher,
        CurrencyConverter,
        Subcategory,
        Supplier,
        Welcome,
        CreatePurchaseOrder,
        PurchaseOrder,
        CreateBudgetEntry,
        BudgetEntry,
        BudgetEntryRecharge,
        CreateRevenueEntry,
        RevenueEntry,
        ModalCreateTaxRate,
        ModalCreateTicket,
        ModalBankTransactions,
        RaiseMultiPurchaseInvoice,
        ViewInvoice,
        GlobalSearch,
        Toast,
    },
    data() {
        const store = useDataStore();
        return {
            store: store,
            currentAccount: store.get('account'),
            currentEvent: store.get('current_event'),
            currentUser: store.get('current_user'),
            currentRoute: route().current(),
        };
    },
    mounted() {
        Eventbus.$on('routeChanged', () => (this.currentRoute = route().current()));

        if (this.currentEvent) {
            mentionables.initialise();
        }
    },
};
</script>
