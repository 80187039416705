<template>
    <div v-if="isOpen" ref="modal" class="draggable-modal-container" @mousemove="handleDrag">
        <div class="draggable-modal-header">
            <h4 @mousedown="startDrag" @mouseup="stopDrag()">{{ modalTitle }}</h4>
            <button @click="close()"><i class="mdi mdi-window-close"></i></button>
        </div>
        <div class="draggable-modal-content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        zIndex: {
            type: Number,
            default: 3000,
        },
    },
    emits: ['opening', 'opened', 'closing', 'closed'],
    data() {
        return {
            isOpen: false,
            isDragging: false,
            startX: null,
            startY: null,
            modalStartPosition: null,
            modalTitle: null,
        };
    },
    mounted() {
        this.modalTitle = this.title;
    },
    methods: {
        open() {
            this.$emit('opening');
            this.isOpen = true;
            this.$emit('opened');
            setTimeout(() => {
                this.$refs.modal.style.zIndex = this.zIndex;
            }, 100);
        },
        close() {
            this.$emit('closing');
            this.isOpen = false;
            this.$emit('closed');
        },
        startDrag(e) {
            this.modalStartPosition = this.$refs.modal.getBoundingClientRect();
            this.startX = e.clientX;
            this.startY = e.clientY;
            this.isDragging = true;
        },
        handleDrag(e) {
            if (!this.isDragging) {
                return;
            }
            this.$refs.modal.style.top = this.modalStartPosition.y + (e.clientY - this.startY) + 'px';
            this.$refs.modal.style.left = this.modalStartPosition.x + (e.clientX - this.startX) + 'px';
            this.$refs.modal.style.zIndex = this.zIndex;
        },
        stopDrag() {
            this.isDragging = false;
            this.modalStartPosition = null;
            this.startX = null;
            this.startY = null;
        },
    },
};
</script>
<style lang="scss">
.draggable-modal-container {
    position: fixed;
    top: 80px;
    left: 100px;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(black, 0.1);
    border-radius: 6px;

    & > .draggable-modal-header {
        min-width: 250px;
        background: #4ea5d9;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 1px solid rgba(black, 0.1);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        & > h4 {
            margin: 3px 0 0 0;
            font-size: 18px;
            flex: 1;
            cursor: move;
            user-select: none;
            color: white;
        }

        & > button {
            width: 24px;
            height: 24px;
            border: none;
            background: white;
            border-radius: 4px;

            &:hover {
                background: rgba(black, 0.1);
            }
        }
    }

    & > .draggable-modal-content {
        background: white;
        padding: 10px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
</style>
