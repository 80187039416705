<template>
    <div>
        <div
            class="tw-mt-4 tw-px-2 tw-py-2 tw-mb-2 tw-grid sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-5 tw-gap-2 tw-bg-white tw-rounded"
        >
            <FormField label="Search">
                <Textbox v-model="searchQuery" placeholder="Search for an event"></Textbox>
            </FormField>
            <FormField label="Venue">
                <Select v-model="filters.venue">
                    <option value="">All Venues</option>
                    <option disabled>-------------------</option>
                    <option v-for="location in availableVenues" :key="location" :value="location">
                        {{ location }}
                    </option>
                </Select>
            </FormField>
            <FormField label="City">
                <Select v-model="filters.city">
                    <option value="">All Cities</option>
                    <option disabled>-------------------</option>
                    <option v-for="location in availableCities" :key="location" :value="location">
                        {{ location }}
                    </option>
                </Select>
            </FormField>
            <FormField field-class="tw-flex tw-gap-1" label="Start Date">
                <flat-pickr
                    v-model="filteredStartDate"
                    :config="{
                        ...flatpickrConfig,
                        minDate: datePreset === null ? new Date() : null,
                        maxDate: datePreset === 'past' ? new Date() : null,
                    }"
                    placeholder="Event Start Date"
                />
                <Button v-if="filteredStartDate" type="is-primary" @click="filteredStartDate = null">
                    <i class="mdi mdi-close"></i>
                </Button>
            </FormField>
            <FormField field-class="tw-flex tw-gap-1" label="End Date">
                <flat-pickr
                    v-model="filteredEndDate"
                    :config="{
                        ...flatpickrConfig,
                        minDate: datePreset === null ? new Date() : null,
                        maxDate: datePreset === 'past' ? new Date() : null,
                    }"
                    placeholder="Event End Date"
                />
                <Button v-if="filteredEndDate" type="is-primary" @click="filteredEndDate = null">
                    <i class="mdi mdi-close"></i>
                </Button>
            </FormField>
        </div>
        <DataTable
            v-model="selectedEvents"
            checkable
            centered
            :per-page-options="[10, 15, 20]"
            :header="false"
            :sidebar="false"
            :searchable-string="searchQuery"
            :endpoint="dataEndpoint"
            :columns="[
                {
                    title: 'Name',
                    field: 'name',
                },
                {
                    title: 'Dates',
                    field: 'dates',
                },
                {
                    field: 'ticket_sales',
                },
                {
                    field: 'budgeted_revenue',
                },
                {
                    field: 'actual_revenue',
                },
                {
                    field: 'budgeted_costs',
                },
                {
                    field: 'total_committed',
                },
                {
                    field: 'budgeted_profit_loss',
                },
                {
                    field: 'actual_profit_loss',
                },
            ]"
            @options="updateOptions"
        >
            <template #column(name)="{ row }">
                {{ row.name }}
                <br />
                <span class="tw-text-xs tw-text-gray-400">
                    {{ row.address }}
                </span>
            </template>
            <template #column(dates)="{ row }">
                {{ formatEventDate(row.start_date) }}
                -
                {{ formatEventDate(row.end_date) }}
            </template>
            <template #column(ticket_sales)="{ row }">
                <FigureCard :dot="false" value-class="tw-w-full" class="!tw-p-0">
                    <template #title><span class="tw-p-1">Tickets Sold</span></template>
                    <div class="tw-flex tw-gap-3 tw-items-center tw-w-[100%] tw-text-nowrap tw-px-1">
                        <span v-if="row.tickets_available" class="tw-text-[10px] tw-text-gray-500">
                            {{ ((row.tickets_sold / row.tickets_available) * 100).toFixed(1) }}%
                        </span>
                        <span class="tw-ml-auto">{{ row.tickets_sold }} / {{ row.tickets_available }}</span>
                    </div>
                    <div class="tw-bg-red-500 tw-h-[2px]">
                        <div
                            v-if="row.tickets_available"
                            class="tw-bg-green-500 tw-h-[2px] tw-w-full"
                            :style="{
                                width:
                                    (row.tickets_sold / row.tickets_available) * 100 > 100
                                        ? 100
                                        : (row.tickets_sold / row.tickets_available) * 100 + '%',
                            }"
                        ></div>
                    </div>
                </FigureCard>
            </template>
            <template #column(budgeted_revenue)="{ row }">
                <FigureCard title="Budgeted Revenue" :amount="row.budgeted_revenue" figure-class="budgeted" />
            </template>
            <template #column(actual_revenue)="{ row }">
                <FigureCard title="Actual Revenue" :amount="row.actual_revenue" figure-class="committed" />
            </template>
            <template #column(budgeted_costs)="{ row }">
                <FigureCard title="Budgeted Costs" :amount="row.budgeted_costs" figure-class="budgeted" />
            </template>
            <template #column(total_committed)="{ row }">
                <FigureCard title="Actual Costs" :amount="row.total_committed" figure-class="committed" />
            </template>
            <template #column(budgeted_profit_loss)="{ row }">
                <FigureCard
                    :title="'Budgeted ' + (row.budgeted_revenue - row.budgeted_costs < 0 ? 'Loss' : 'Profit')"
                    :amount="row.budgeted_revenue - row.budgeted_costs"
                    :figure-class="[
                        'budgeted',
                        {
                            '!tw-text-red-500': row.budgeted_revenue - row.budgeted_costs < 0,
                        },
                    ]"
                />
            </template>
            <template #column(actual_profit_loss)="{ row }">
                <FigureCard
                    :title="'Actual ' + (row.actual_revenue - row.total_committed < 0 ? 'Loss' : 'Profit')"
                    :amount="row.actual_revenue - row.total_committed"
                    :figure-class="{
                        committed: true,
                        '!tw-text-red-500': row.actual_revenue - row.total_committed < 0,
                    }"
                />
            </template>
            <template #prepend>
                <tr class="tw-h-[60px]">
                    <td class="tw-bg-eventwise tw-leading-[16px]" colspan="3">
                        <span class="tw-block tw-font-avenir tw-text-white">Combined Figures</span>
                        <span class="tw-text-white tw-text-xs tw-opacity-[.66]">Sum of selected events</span>
                    </td>
                    <template v-if="selectedEvents.length">
                        <td class="tw-bg-eventwise">
                            <FigureCard :dot="false" value-class="tw-w-full" class="!tw-p-0 tw-border-0">
                                <template #title><span class="tw-p-1">Tickets Sold</span></template>
                                <div class="tw-flex tw-gap-3 tw-items-center tw-w-[100%] tw-text-nowrap tw-px-1">
                                    <span v-if="totalTicketsAvailable" class="tw-text-[10px] tw-text-gray-500">
                                        {{ ((totalTicketsSold / totalTicketsAvailable) * 100).toFixed(1) }}%
                                    </span>
                                    <span class="tw-ml-auto">{{ totalTicketsSold }} / {{ totalTicketsAvailable }}</span>
                                </div>
                                <div class="tw-bg-red-500 tw-h-[2px]">
                                    <div
                                        v-if="totalTicketsAvailable"
                                        class="tw-bg-green-500 tw-h-[2px] tw-w-full"
                                        :style="{
                                            width:
                                                (totalTicketsSold / totalTicketsAvailable) * 100 > 100
                                                    ? 100
                                                    : (totalTicketsSold / totalTicketsAvailable) * 100 + '%',
                                        }"
                                    ></div>
                                </div>
                            </FigureCard>
                        </td>
                        <td class="tw-bg-eventwise">
                            <FigureCard
                                title="Budgeted Revenue"
                                :amount="totalBudgetedRevenue"
                                class="tw-border-0"
                                :dot="false"
                            />
                        </td>
                        <td class="tw-bg-eventwise">
                            <FigureCard
                                title="Actual Revenue"
                                :amount="totalActualRevenue"
                                class="tw-border-0"
                                :dot="false"
                            />
                        </td>
                        <td class="tw-bg-eventwise">
                            <FigureCard
                                title="Budgeted Costs"
                                :amount="totalBudgetedCosts"
                                class="tw-border-0"
                                :dot="false"
                            />
                        </td>
                        <td class="tw-bg-eventwise">
                            <FigureCard
                                title="Actual Costs"
                                :amount="totalActualCosts"
                                class="tw-border-0"
                                :dot="false"
                            />
                        </td>
                        <td class="tw-bg-eventwise">
                            <FigureCard
                                :title="'Budgeted ' + (totalBudgetedProfitLoss < 0 ? 'Loss' : 'Profit')"
                                :amount="totalBudgetedProfitLoss"
                                class="tw-border-0"
                                :figure-class="{
                                    '!tw-text-red-500': totalBudgetedProfitLoss < 0,
                                }"
                                :dot="false"
                            />
                        </td>
                        <td class="tw-bg-eventwise">
                            <FigureCard
                                :title="'Actual ' + (totalActualProfitLoss < 0 ? 'Loss' : 'Profit')"
                                :amount="totalActualProfitLoss"
                                class="tw-border-0"
                                :figure-class="{
                                    '!tw-text-red-500': totalActualProfitLoss < 0,
                                }"
                                :dot="false"
                            />
                        </td>
                    </template>
                    <td
                        v-else
                        colspan="7"
                        class="tw-bg-eventwise !tw-text-center tw-text-white tw-uppercase tw-text-xs"
                    >
                        Select at least one event to view combined figures
                    </td>
                </tr>
            </template>
        </DataTable>
    </div>
</template>
<script>
import DataTable from '@/js/components/tables/DataTable.vue';
import FigureCard from '@/js/components/widgets/FigureCard.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';

export default {
    components: {
        DataTable,
        FigureCard,
        Textbox,
        Select,
        FormField,
        Button,
    },
    props: {
        modelValue: {
            type: Array,
        },
        datePreset: {
            type: String,
            default: null,
        },
    },
    emits: ['update:modelValue'],
    data() {
        const startDate = useSettingsStore().account(
            useDataStore().get('account.id'),
            'multiEventComparison_startDate'
        );
        const endDate = useSettingsStore().account(useDataStore().get('account.id'), 'multiEventComparison_endDate');

        return {
            selectedEvents: this.modelValue,
            searchQuery: null,
            availableCities: [],
            filters: {
                city: '',
                venue: '',
            },
            availableVenues: [],
            filteredStartDate: startDate ? startDate : null,
            filteredEndDate: endDate ? endDate : null,
        };
    },
    computed: {
        totalTicketsSold() {
            return this.selectedEvents.reduce((acc, event) => acc + event.tickets_sold, 0);
        },
        totalTicketsAvailable() {
            return this.selectedEvents.reduce((acc, event) => acc + event.tickets_available, 0);
        },
        totalBudgetedRevenue() {
            return this.selectedEvents.reduce((acc, event) => acc + event.budgeted_revenue, 0);
        },
        totalActualRevenue() {
            return this.selectedEvents.reduce((acc, event) => acc + event.actual_revenue, 0);
        },
        totalBudgetedCosts() {
            return this.selectedEvents.reduce((acc, event) => acc + event.budgeted_costs, 0);
        },
        totalActualCosts() {
            return this.selectedEvents.reduce((acc, event) => acc + event.total_committed, 0);
        },
        totalBudgetedProfitLoss() {
            return this.selectedEvents.reduce((acc, event) => acc + (event.budgeted_revenue - event.budgeted_costs), 0);
        },
        totalActualProfitLoss() {
            return this.selectedEvents.reduce((acc, event) => acc + (event.actual_revenue - event.total_committed), 0);
        },
        dataEndpoint() {
            return route('api.account.events.multiEventComparison', {
                from: this.filteredStartDate ? this.filteredStartDate : null,
                to: this.filteredEndDate ? this.filteredEndDate : null,
                datePreset: this.datePreset,
                venue: this.filters.venue,
                city: this.filters.city,
            });
        },
    },
    watch: {
        selectedEvents(newVal) {
            this.$emit('update:modelValue', newVal);
        },
        filteredStartDate(newVal) {
            useSettingsStore().setAccount(
                useDataStore().get('account.id'),
                'multiEventComparison_startDate',
                newVal ? newVal : null
            );
        },
        filteredEndDate(newVal) {
            useSettingsStore().setAccount(
                useDataStore().get('account.id'),
                'multiEventComparison_endDate',
                newVal ? newVal : null
            );
        },
    },
    methods: {
        formatEventDate(date) {
            return new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            }).format(date);
        },
        updateOptions(options) {
            this.availableCities = options.cities ?? [];
            this.availableVenues = options.venues ?? [];
        },
    },
};
</script>
