<template>
    <div
        v-if="!isLoading"
        class="d-inline-flex w-100"
        :class="{
            'selector-actions': !!currentSupplier,
        }"
    >
        <Selector
            ref="selector"
            v-model="selectedSupplier"
            :options="filteredSuppliers"
            :all-options="availableSuppliers"
            :max-displayed-options="5"
            :allow-clear
            placeholder="Type to search"
            :editable="editable"
            :input-class="currentSupplier && dropdownVisible ? '!tw-rounded-r-none' : null"
            class="flex-fill"
            :class="{
                'has-dropdown': dropdownVisible,
            }"
            @update:model-value="
                (v) => {
                    $emit('update:modelValue', v);
                }
            "
        >
            <template v-if="currentSupplier && currentSupplier.is_archived" #prefix>
                <Tooltip :label="currentSupplier.name + ' has been archived'">
                    <i class="mdi mdi-archive mr-2"></i>
                </Tooltip>
            </template>
            <template v-else-if="currentSupplier && currentSupplier.has_supplier_account" #prefix>
                <Tooltip :label="currentSupplier.name + ' has an active contact user'">
                    <i class="mdi mdi-account-circle mr-2 text-primary"></i>
                </Tooltip>
            </template>
            <template #customOptions="{ searchValue, hasExactMatch }">
                <a
                    v-if="Auth.can('manage suppliers') && !hasExactMatch"
                    href="#"
                    @mousedown="createSupplier(searchValue)"
                >
                    <i class="mdi mdi-plus" />
                    Create {{ searchValue ? searchValue : ' a new ' + objectLabel }}
                </a>
            </template>
        </Selector>
        <Dropdown v-if="currentSupplier && dropdownVisible">
            <template #trigger>
                <div class="selector-actions-dropdown">
                    <i class="mdi mdi-menu-down"></i>
                </div>
            </template>
            <template v-if="currentSupplier">
                <a v-if="editable" href="#" @click.prevent="resetSelection()">
                    <i class="mdi mdi-swap-vertical-bold mr-1"></i>
                    Change
                </a>
                <template v-if="Auth.can('manage suppliers')">
                    <a
                        v-if="!currentSupplier.is_system && Auth.can('manage suppliers')"
                        href="#"
                        @click.prevent="navigateToSupplier"
                    >
                        <i class="mdi mdi-pencil mr-1"></i>
                        Edit {{ currentSupplier.name }}
                    </a>
                    <template
                        v-if="
                            !currentSupplier.has_supplier_account &&
                            !currentSupplier.is_archived &&
                            !currentSupplier.is_system &&
                            Auth.can('manage suppliers')
                        "
                    >
                        <hr />
                        <a href="#" @click.prevent="inviteSupplier">
                            <i class="mdi mdi-email-fast mr-1"></i>
                            Invite to Eventwise
                        </a>
                    </template>
                </template>
            </template>
        </Dropdown>
        <ModalCreateSupplier
            ref="createSupplierModal"
            :object-label="objectLabel"
            @created="supplierCreated"
        ></ModalCreateSupplier>
        <ModalSupplierInvite v-if="Auth.can('manage users')" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import ModalCreateSupplier from '../modals/Suppliers/CreateSupplier.vue';
import ModalSupplierInvite from '../pages/Portal/InviteSupplier.vue';
import Selector from './Selector.vue';
import Dropdown from './Dropdown.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        ModalCreateSupplier,
        ModalSupplierInvite,
        Selector,
        Dropdown,
        Tooltip,
    },
    props: {
        type: {
            type: [String, Number],
            default: 0,
        },
        modelValue: {
            type: Number,
            default: null,
        },
        objectLabel: {
            type: String,
            default: 'supplier',
        },
        allowClear: {
            type: Boolean,
            default: true,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        autoDefault: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'view'],
    data() {
        const store = useDataStore();
        return {
            store: store,
            supplierType: 0,
            availableSuppliers: [],
            selectedSupplier: null,
            isLoading: true,
            componentKey: 0,
        };
    },
    computed: {
        currentSupplier() {
            return this.store.get('account.suppliers.' + this.selectedSupplier);
        },
        filteredSuppliers() {
            return this.availableSuppliers.filter((s) => !s.is_archived);
        },
        dropdownVisible() {
            return (
                this.currentSupplier &&
                (this.editable ||
                    (!this.currentSupplier.is_system && Auth.can('manage suppliers')) ||
                    (!this.currentSupplier.has_supplier_account &&
                        !this.currentSupplier.is_archived &&
                        !this.currentSupplier.is_system &&
                        Auth.can('manage suppliers')))
            );
        },
    },
    watch: {
        type(newVal) {
            this.supplierType = newVal;
        },
        modelValue(newVal) {
            this.selectedSupplier = newVal;
        },
    },
    mounted() {
        this.supplierType = this.type;
        this.selectedSupplier = this.modelValue;
        this.reloadSuppliers();
    },
    methods: {
        reloadSuppliers() {
            this.availableSuppliers = Object.values(this.store.get('account.suppliers')).filter(
                (supplier) => supplier.supplier_type === this.supplierType
            );
            this.isLoading = false;
            if (!this.modelValue && this.autoDefault) {
                const defaultSupplier = this.availableSuppliers.find((s) => s.is_system);
                this.selectedSupplier = defaultSupplier ? defaultSupplier.id : null;
            } else {
                this.selectedSupplier = this.modelValue;
            }
            this.componentKey += 1;
        },
        createSupplier(supplierName) {
            this.$refs.createSupplierModal.open({
                supplierName: supplierName,
            });
        },
        supplierCreated(supplier) {
            this.availableSuppliers.push(supplier);
            this.selectedSupplier = supplier.id;
            this.store.set('account.suppliers.' + this.selectedSupplier, supplier);
        },
        navigateToSupplier() {
            this.$emit('view', this.selectedSupplier);
            Eventbus.$emit('view:supplier', this.selectedSupplier);
        },
        resetSelection() {
            this.selectedSupplier = null;
            this.$refs.selector.setFocus();
        },
        inviteSupplier() {
            if (!this.currentSupplier) {
                return;
            } else if (this.currentSupplier.has_supplier_account) {
                toast('Error', 'This supplier already has an active contact account.', 'error');
                return;
            }
            Eventbus.$emit('inviteSupplierModal:open', this.currentSupplier.id);
        },
    },
};
</script>
<style lang="scss" scoped>
.selector-actions-dropdown {
    padding: 0 4px;
    border: 1px solid rgba(black, 0.15);
    border-left: none;
    font-size: 19.5px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;

    &:hover {
        border-color: rgba(black, 0.2);
    }
}
</style>
