<template>
    <tr style="transition: background-color 500ms linear">
        <td>
            <Tooltip v-if="approval.id" label="Delete matrix" position="right">
                <Button type="is-danger" @click="deleteMatrix">
                    <i class="mdi mdi-close-thick"></i>
                </Button>
            </Tooltip>
        </td>
        <td>
            <Selector
                :model-value="approval.selectedUsers"
                :options="users"
                multiple
                external-options
                input-class="input is-small"
                placeholder="Select a user"
                @update:model-value="(e) => (approval.selectedUsers = e)"
            />
        </td>
        <td>
            <div class="tw-grid tw-grid-cols-[1fr_2fr] tw-items-center">
                <Button type="is-link" @click="approval.limit_operator = approval.limit_operator === '<=' ? '>' : '<='">
                    {{ approval.limit_operator === '<=' ? 'Up To' : 'Equal or Over' }}
                </Button>

                <NumberInput v-model="approval.approvable_limit" currency="£" />
            </div>
        </td>
        <td>
            <Selector
                v-model="approval.approvable_type"
                :options="approvables"
                input-class="input is-small"
                placeholder="Select an approvable type"
            />
        </td>
        <td>
            <Selector
                :model-value="approval.selectedEvents"
                :options="availableEvents"
                multiple
                external-options
                input-class="input is-small"
                placeholder="Select an event"
                @update:model-value="(e) => (approval.selectedEvents = Array.isArray(e) ? e : e ? [e] : [])"
            />
        </td>
        <td>
            <MultiCategorySelector
                v-if="approval.selectedEvents.length"
                :model-value="approval.selectedCategories"
                :events="approval.selectedEvents"
                @update:model-value="(e) => (approval.selectedCategories = e)"
            />
            <template v-else>
                <small v-if="approval.selectedEvents.length" class="text-muted">
                    There are no categories available
                </small>
                <small v-else class="text-muted">Please select an event to select categories</small>
            </template>
        </td>
    </tr>
</template>

<script setup>
import debounce from 'lodash/debounce';
import Selector from '../../../../controls/Selector.vue';
import MultiCategorySelector from '../../../../controls/MultiCategorySelector.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { toast } from '@/js/utils.js';
import { ref, computed, watch } from 'vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';

const props = defineProps({
    approvables: {
        type: Array,
        required: false,
    },
    availableEvents: {
        type: Array,
        required: false,
    },
    matrix: {
        type: Object,
        required: true,
    },
    users: {
        type: Array,
        required: false,
    },
});

const emit = defineEmits(['updated']);
const approval = ref(props.matrix);
const approvalComputed = computed(() => JSON.parse(JSON.stringify(approval.value)));

const hasValidData = computed(() => {
    return (
        !isNaN(approval.value.approvable_limit) &&
        approval.value.approvable_type &&
        approval.value.selectedUsers.length &&
        approval.value.selectedEvents.length
    );
});

/**
 * We unfortunately have to watch a computed of the ref because old and new val
 * were the same, so we were constantly making requests to save
 * the data when there wasn't a need,
 * and every time we updated the filters, this watcher would be called
 */
watch(
    approvalComputed,
    (newVal, oldVal) => {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        updateMatrix();
    },
    { deep: true }
);

const updateMatrix = debounce(function () {
    if (!hasValidData.value) return;
    if (approval.value.id) {
        axios
            .put(route('api.account.approvals.matrices.update', { matrix: approval.value.id }), approval.value)
            .then(() => {
                toast('Updated', 'Updated approval matrix', 'success');
            });
    } else {
        axios.post(route('api.account.approvals.matrices.create'), approval.value).then(({ data }) => {
            approval.value.id = data.id;
            emit('updated');
            toast('Created', 'Created approval matrix', 'success');
        });
    }
}, 1000);
const deleteMatrix = () => {
    axios.delete(route('api.account.approvals.matrices.delete', { matrix: approval.value })).then(() => {
        emit('updated');
        toast('Deleted', 'Deleted approval matrix', 'warning');
    });
};
</script>

<style lang="scss" scoped>
td {
    vertical-align: top;
}
</style>
