<template>
    <div>
        <Tooltip label="Back to Department" position="left" class="tw-my-4">
            <Button
                type="is-success"
                class="tw-mr-1 tw-flex tw-items-center tw-gap-1"
                @click.stop="returnToDepartment()"
            >
                Back To Department
                <i class="mdi mdi-keyboard-return"></i>
            </Button>
        </Tooltip>

        <div v-if="category" class="tw-grid xl:tw-grid-cols-3 tw-gap-3">
            <ew-card title="Overview" paddingless :collapsible="false" class="tw-w-full">
                <Chart type="bar" :data="chartSeries" :options="chartOptions" class="tw-px-3" />
                <div class="tw-p-3 tw-grid tw-gap-3">
                    <div v-if="category.description">
                        <p>Description</p>
                        {{ category.description }}
                    </div>
                    <div v-if="category.users">
                        <p>Members</p>
                        <widget-avatar-list :users="category.users"></widget-avatar-list>
                    </div>
                </div>
            </ew-card>

            <ew-card title="Budget Entries" paddingless :collapsible="false" class="xl:tw-col-span-2 !tw-mx-[unset]">
                <template #actions>
                    <EntryDisplayControls class="mr-5" :type="departmentType === 'Revenue' ? 'revenue' : 'cost'" />
                    <FormField v-if="ticketsAvailable > 0" label="Available Tickets" style="opacity: 1">
                        <i class="mdi mdi-checkbox-blank-circle tw-text-eventwise"></i>
                        {{ ticketsAvailable }}
                    </FormField>
                    <FormField v-if="!isNaN(scopedEstimateValue)" :label="'Fixed ' + departmentType" style="opacity: 1">
                        <span
                            :class="{
                                'accounting-negative': scopedEstimateValue < 0,
                            }"
                        >
                            <i class="mdi mdi-checkbox-blank-circle tw-text-orange"></i>
                            {{ localise(scopedEstimateValue, 2, true) }}
                        </span>
                    </FormField>
                    <FormField
                        v-if="!isNaN(scopedBudgetedValue)"
                        :label="'Budgeted ' + departmentType"
                        style="opacity: 1"
                    >
                        <span
                            :class="{
                                'accounting-negative': scopedBudgetedValue < 0,
                            }"
                        >
                            <i class="mdi mdi-checkbox-blank-circle tw-text-green-500"></i>
                            {{ localise(scopedBudgetedValue, 2, true) }}
                        </span>
                    </FormField>
                    <LockItemControls
                        v-if="isDirector"
                        lockable-name="Subcategory"
                        lockable-type="category"
                        :lockable="store.get(`current_event.categories.${category.id}`)"
                    />
                    <Tooltip label="New Budget Entry" position="left">
                        <Button
                            class="tw-ml-1"
                            :disabled="category.is_locked"
                            @click.stop="newBudgetEntry(category.department_id, category.id)"
                        >
                            <i class="mdi mdi-plus"></i>
                        </Button>
                    </Tooltip>
                </template>
                <TableBudgetEntries
                    :endpoint="route('api.account.event.categories.entries', category.id)"
                    filterable
                    checkable
                    hide-category
                    :object="category"
                    :sticky-header="false"
                />
            </ew-card>
        </div>
    </div>
</template>
<script>
import LockItemControls from '../../widgets/LockItemControls.vue';
import TableBudgetEntries from '../../tables/Budget/BudgetEntries.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import EntryDisplayControls from '../../widgets/EntryDisplayControls.vue';
import Chart from '@/js/components/Chart.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import { localise, setRoute } from '@/js/utils.js';

export default {
    components: {
        EntryDisplayControls,
        LockItemControls,
        TableBudgetEntries,
        Chart,
        Button,
        Tooltip,
        FormField,
    },
    props: {
        category: {
            type: Object,
        },
    },
    data() {
        const store = useDataStore();
        const settings = useSettingsStore();
        return {
            totalsData: {},
            departmentType: '',
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: false,
                        },
                    },
                    y: {
                        title: {
                            display: false,
                            text: 'Amount',
                        },
                        ticks: {
                            callback(value) {
                                return localise(value, 0, true);
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(context) {
                                return ' ' + localise(context.parsed.y, 2, true);
                            },
                        },
                    },
                },
            },
            chartSeries: {
                labels: ['Budgeted', 'Fixed', 'Actual'],
                datasets: [],
            },
            store: store,
            settings: settings,
            users: [],
        };
    },
    computed: {
        isDirector() {
            return !!Auth?.user?.roles?.find((role) => role.name === 'Director');
        },
        scopedBudgetedValue() {
            let value = 0;

            const type = this.category.is_revenue ? 'revenue' : 'cost';

            Object.keys(this.settings.get(`budgetFigures.${type}`)).forEach((key) => {
                value += this.settings.get(`budgetFigures.${type}.${key}`)
                    ? parseFloat(this.category.total_budget_entries[key])
                    : 0;
            });

            return value;
        },
        scopedEstimateValue() {
            let value = 0;

            const type = this.category.is_revenue ? 'revenue' : 'cost';

            Object.keys(this.settings.get(`budgetFigures.${type}`)).forEach((key) => {
                value += this.settings.get(`budgetFigures.${type}.${key}`)
                    ? parseFloat(this.category.total_budget_estimates[key])
                    : 0;
            });

            return value;
        },
        ticketsAvailable() {
            const ticketTypes = Object.values(this.store.get('current_event.ticket_types')).filter(
                (ticket) => ticket.category_id === this.category.id
            );
            if (!ticketTypes.length) {
                return 0;
            }

            return ticketTypes.map((available) => available.tickets_available).reduce((a, c) => a + Number(c), 0);
        },
    },
    watch: {
        category: {
            handler: function () {
                this.departmentType =
                    data.account.departments[this.category.department_id]?.type === 1 ? 'Costs' : 'Revenue';
                this.getCategoryTotals();
                this.getCategoryUsers();
            },
            immediate: true,
        },
    },
    methods: {
        getCategoryUsers() {
            this.isLoading = true;
            axios.get(route('api.account.event.categories.users', this.category.id)).then(({ data }) => {
                this.users = data;
                this.isLoading = false;
            });
        },
        getCategoryTotals() {
            this.isLoading = true;
            axios.get(route('api.account.event.categories.entryTotals', this.category.id)).then(({ data }) => {
                this.totalsData = data;
                (this.chartSeries.datasets = [
                    {
                        label: 'Fixed',
                        data: [this.totalsData.total_fixed_cost],
                    },
                    {
                        label: 'Budgeted',
                        data: [this.totalsData.total_budgeted_cost],
                    },
                    {
                        label: 'Actual',
                        data: [this.totalsData.total_actual_cost],
                    },
                ]),
                    (this.isLoading = false);
            });
        },
        newBudgetEntry(department = null, category = null) {
            if (department !== null && category !== null) {
                Eventbus.$emit('newBudgetEntry:open', {
                    category: category,
                    department: department,
                });
            } else if (department !== null) {
                Eventbus.$emit('newBudgetEntry:open', {
                    department: department,
                });
            } else {
                Eventbus.$emit('newBudgetEntry:open');
            }
        },
        returnToDepartment() {
            setRoute('account.event.budget', {
                page: route().params.page,
                departmentId: route().params.departmentId,
            });
        },
    },
};
</script>
