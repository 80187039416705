<template>
    <Modal ref="emailModal" :title="modalTitle" size="is-xxxlarge" :loading="isLoading" paddingless @closed="reset">
        <div class="container py-3">
            <div class="row pb-3">
                <div class="col-12">
                    <FormField label="Email Addresses">
                        <Textbox
                            v-model="emailRecipient"
                            placeholder="Type an email address and press enter to add"
                            @keyup.enter.native="addEmailRecipient()"
                        ></Textbox>
                    </FormField>
                    <FormField v-if="emailRecipients.length" class="tw-mt-2" label="To">
                        <Tag
                            v-for="recipient in emailRecipients"
                            :key="recipient"
                            type="primary"
                            class="tw-flex tw-gap-3 tw-items-center"
                        >
                            <span>{{ recipient }}</span>
                            <Button type="is-link" class="!tw-p-0" @click="removeRecipient(recipient)">Remove</Button>
                        </Tag>
                    </FormField>
                </div>
            </div>

            <div v-if="type === 'purchase order'" class="row pb-3">
                <div class="col-12">
                    <Banner :closable="false" type="is-info is-light">
                        Any attachable documents linked to this purchase order will also be attached to the PDF sent in
                        the email.
                    </Banner>
                </div>
            </div>

            <div v-if="type === 'invoice' && availableAttachments.length" class="row pb-3">
                <div class="col-12">
                    <FormField label="Attached Terms and Conditions">
                        <Select v-model="selectedAttachment" placeholder="Select a Document" class="tw-w-full">
                            <option v-for="document in availableAttachments" :key="document.id" :value="document.id">
                                {{ document.description }}
                            </option>
                        </Select>
                    </FormField>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <CKEditor v-model="emailContent" :without="['HtmlEmbed', 'MediaEmbed']" />
                </div>
            </div>
        </div>

        <template #footer>
            <Button class="!tw-mr-auto" @click="close">Cancel</Button>
            <Button type="is-success" :disabled="!emailRecipients.length || isSending" @click="submit">
                Send {{ emailRecipients.length > 1 ? 'Emails' : 'Email' }}
            </Button>
        </template>
    </Modal>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../Modal.vue';
import CKEditor from '../CKEditor.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Select from '@/js/components/controls/Select.vue';
import Tag from '@/js/components/Tag.vue';
import { isValidEmail, toast } from '@/js/utils.js';

export default {
    components: {
        CKEditor,
        Modal,
        Button,
        Textbox,
        FormField,
        Banner,
        Select,
        Tag,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isSending: false,
            emailable: null,
            emailRecipient: '',
            emailRecipients: [],
            emailContent: '',
            store: store,
            type: '',
            availableAttachments: [],
            selectedAttachment: null,
        };
    },
    computed: {
        modalTitle() {
            if (!this.emailable?.reference) {
                return `Send ${this.type} by email`;
            }

            return `Send ${this.type} (${this.emailable?.reference}) by email`;
        },
    },
    watch: {
        emailable: {
            handler(val) {
                if (val) {
                    this.getMailTemplate();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        Eventbus.$on('send:email', (emailable) => {
            this.emailable = emailable.model;
            this.type = emailable.type;
            this.open();
        });
    },
    methods: {
        open() {
            this.$refs.emailModal.open();
        },
        close() {
            this.reset();
            this.$refs.emailModal.close();
        },
        reset() {
            this.emailRecipients = [];
            this.emailContent = '';
            this.emailable = null;
            this.isSending = false;
        },
        submit() {
            this.isSending = true;

            if (this.type === 'purchase order') {
                return this.sendPurchaseOrder();
            } else if (this.type === 'invoice') {
                return this.sendInvoice();
            }
        },
        getMailTemplate() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.emailTemplate', {
                        type: this.type,
                        id: this.emailable.id,
                    })
                )
                .then(({ data }) => {
                    this.emailContent = data.content;
                    this.emailRecipients = data.default_recipients;
                    this.availableAttachments = data.available_attachments;
                    this.isLoading = false;
                });
        },
        sendPurchaseOrder() {
            axios
                .post(
                    route('api.account.event.export.purchaseorders.email', {
                        event: this.emailable.event_id,
                        purchaseOrder: this.emailable.id,
                    }),
                    {
                        recipients: this.emailRecipients,
                        customContent: this.emailContent,
                    }
                )
                .then(
                    () => {
                        Eventbus.$emit('purchaseOrder:sent', this.emailable.id);
                        this.close();
                    },
                    () => {
                        this.isSending = false;
                    }
                );
        },
        sendInvoice() {
            axios
                .post(
                    route('api.account.event.revenue.invoices.emailInvoice', {
                        event: this.emailable.event_id,
                        revenueEntry: this.emailable.revenue_entry_id,
                        customerInvoice: this.emailable.id,
                    }),
                    {
                        emails: this.emailRecipients,
                        fileId: this.selectedAttachment,
                        customContent: this.emailContent,
                    }
                )
                .then(
                    () => {
                        toast(
                            'Invoice Sent',
                            'The invoice ' +
                                this.emailable.invoice_number +
                                ' has been sent to the specified email addresses',
                            'success'
                        );
                        this.close();
                    },
                    () => {
                        this.isSending = false;
                    }
                );
        },
        addEmailRecipient() {
            if (!isValidEmail(this.emailRecipient)) {
                toast('Invalid Email Address', 'Please enter a valid email address and try again', 'error');
                return;
            } else if (this.emailRecipients.includes(this.emailRecipient)) {
                toast('Duplicate Email Address', 'This email address has already been added', 'error');
                return;
            }
            this.emailRecipients.push(this.emailRecipient);
            this.emailRecipient = '';
        },
        removeRecipient(recipient) {
            this.emailRecipients = this.emailRecipients.filter((email) => email !== recipient);
        },
    },
};
</script>

<style lang="scss">
.ck-editor__editable {
    min-height: 40vh !important;

    // This is an almost exact copy / paste from the email template.  Needed to make a couple of edits though.
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    position: relative;

    -webkit-text-size-adjust: none;
    background-color: #ffffff;
    color: #718096;
    height: 100%;
    line-height: 1.4 !important;
    margin: 0;
    padding: 0;
    width: 100% !important;

    p,
    ul,
    ol,
    blockquote {
        line-height: 1.4 !important;
        text-align: left;
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
    }

    a {
        color: #3869d4;
    }

    a img {
        border: none;
    }

    /* Typography */

    h1 {
        color: #3d4852;
        font-size: 18px;
        font-weight: bold;
        margin-top: 0;
        text-align: left;
    }

    h2 {
        color: #3d4852;
        font-size: 18px;
        font-weight: bold;
        margin-top: 0;
        text-align: left;
    }

    h3 {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0;
        text-align: left;
    }

    p {
        font-size: 16px;
        line-height: 1.5em;
        margin-top: 0;
        text-align: left;
    }

    p.sub {
        font-size: 12px;
    }

    img {
        max-width: 100%;
    }

    /* Layout */

    .wrapper {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        background-color: #edf2f7;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .content {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    /* Header */

    .header {
        padding: 25px 0;
        text-align: center;
    }

    .header a,
    .header .a {
        color: #3d4852;
        font-size: 19px;
        font-weight: bold;
        text-decoration: none;
    }

    /* Logo */

    .logo {
        height: 75px;
        max-height: 75px;
        width: 75px;
    }

    /* Body */

    .body {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        background-color: #edf2f7;
        border-bottom: 1px solid #edf2f7;
        border-top: 1px solid #edf2f7;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .inner-body {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 570px;
        background-color: #ffffff;
        border-color: #e8e5ef;
        border-radius: 2px;
        border-width: 1px;
        box-shadow:
            0 2px 0 rgba(0, 0, 150, 0.025),
            2px 4px 0 rgba(0, 0, 150, 0.015);
        margin: 0 auto;
        padding: 0;
        width: 570px;
    }

    /* Subcopy */

    .subcopy {
        border-top: 1px solid #e8e5ef;
        margin-top: 25px;
        padding-top: 25px;
    }

    .subcopy p {
        font-size: 14px;
    }

    /* Footer */

    .footer {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 570px;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        width: 570px;
    }

    .footer p {
        color: #b0adc5;
        font-size: 12px;
        text-align: center;
    }

    .footer a {
        color: #b0adc5;
        text-decoration: underline;
    }

    /* Tables */

    .table table {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        margin: 30px auto;
        width: 100%;
    }

    .table th {
        border-bottom: 1px solid #edeff2;
        margin: 0;
        padding-bottom: 8px;
    }

    .table td {
        color: #74787e;
        font-size: 15px;
        line-height: 18px;
        margin: 0;
        padding: 10px 0;
    }

    .content-cell {
        max-width: 100vw;
        padding: 32px;
    }

    /* Buttons */

    .action {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        margin: 30px auto;
        padding: 0;
        text-align: center;
        width: 100%;
    }

    .button {
        -webkit-text-size-adjust: none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        overflow: hidden;
        text-decoration: none;
    }

    .button-blue,
    .button-primary {
        background-color: #2d3748;
        border-bottom: 8px solid #2d3748;
        border-left: 18px solid #2d3748;
        border-right: 18px solid #2d3748;
        border-top: 8px solid #2d3748;
    }

    .button-green,
    .button-success {
        background-color: #48bb78;
        border-bottom: 8px solid #48bb78;
        border-left: 18px solid #48bb78;
        border-right: 18px solid #48bb78;
        border-top: 8px solid #48bb78;
    }

    .button-red,
    .button-error {
        background-color: #e53e3e;
        border-bottom: 8px solid #e53e3e;
        border-left: 18px solid #e53e3e;
        border-right: 18px solid #e53e3e;
        border-top: 8px solid #e53e3e;
    }

    /* Panels */

    .panel {
        border-left: #2d3748 solid 4px;
        margin: 21px 0;
    }

    .panel-content {
        background-color: #edf2f7;
        color: #718096;
        padding: 16px;
    }

    .panel-content p {
        color: #718096;
    }

    .panel-item {
        padding: 0;
    }

    .panel-item p:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    /* Utilities */

    .break-all {
        word-break: break-all;
    }

    blockquote {
        padding: 20px;
        border-left: 2px solid rgb(128, 128, 128);
        margin-left: 0;
        color: rgb(128, 128, 128);
        margin-bottom: 20px;
        font-style: italic;
    }

    blockquote > p {
        margin: 0;
    }
}
</style>
