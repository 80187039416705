<template>
    <div>
        <div v-if="!isLoading" class="buefy-wrapper">
            <div class="report-table">
                <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-2">
                    <FormField class="tw-mb-1" field-class="tw-flex tw-gap-1 tw-items-center">
                        <Button :active="currentPage === 'summary'" @click="currentPage = 'summary'">Summary</Button>
                        <Button :active="currentPage === 'departments'" @click="currentPage = 'departments'">
                            Departments
                        </Button>
                    </FormField>
                    <Button v-if="hiddenEvents.length" type="is-link" @click="hiddenEvents = []">
                        Reset hidden events
                    </Button>
                    <div class="flex-fill"></div>
                    <FormField class="tw-mb-1" field-class="tw-flex tw-gap-1 tw-items-center">
                        <Button :active="displaying === 'costs'" @click="displaying = 'costs'">Costs</Button>
                        <Button :active="displaying === 'revenue'" @click="displaying = 'revenue'">Revenue</Button>
                    </FormField>
                </div>
                <div class="card">
                    <div v-if="summaryData && currentPage === 'summary'" class="table mb-0">
                        <table class="w-100">
                            <thead>
                                <tr>
                                    <th style="background: rgba(0, 0, 0, 0.025)"></th>
                                    <th
                                        v-for="(event, index) in sortedEvents"
                                        :key="event.id"
                                        class="border-left"
                                        :style="`width:calc(80% / ${sortedEvents.length})`"
                                    >
                                        <div class="d-flex">
                                            <a
                                                v-if="index !== 0"
                                                href="#"
                                                @click.prevent="setOrder(event.id, event.order - 1)"
                                            >
                                                <i class="mdi mdi-arrow-left-bold"></i>
                                            </a>
                                            <div class="flex-fill text-center">
                                                {{ event.name }}
                                                <a
                                                    v-if="hiddenEvents.length < summaryData.length - 1"
                                                    href="#"
                                                    class="ml-1"
                                                    @click.prevent="hideEvent(event.id)"
                                                >
                                                    <i class="mdi mdi-eye-off"></i>
                                                </a>
                                            </div>
                                            <a
                                                v-if="index !== summaryData.length - 1"
                                                href="#"
                                                @click.prevent="setOrder(event.id, event.order + 1, true)"
                                            >
                                                <i class="mdi mdi-arrow-right-bold"></i>
                                            </a>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Subtotal</th>
                                    <td v-for="event in sortedEvents" :key="event.id" class="border-left">
                                        {{ localise(event[displaying].subtotal, 2, true) }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Tax Amount</th>
                                    <td v-for="event in sortedEvents" :key="event.id" class="border-left">
                                        {{ localise(event[displaying].tax_amount, 2, true) }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Total</th>
                                    <td v-for="event in sortedEvents" :key="event.id" class="border-left">
                                        {{ localise(event[displaying].total, 2, true) }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Total Paid</th>
                                    <td v-for="event in sortedEvents" :key="event.id" class="border-left">
                                        {{ localise(event[displaying].total_paid, 2, true) }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Total Pending</th>
                                    <td v-for="event in sortedEvents" :key="event.id" class="border-left">
                                        {{ localise(event[displaying].total_pending, 2, true) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="departmentsData && currentPage === 'departments'" class="table mb-0">
                        <table class="w-100">
                            <thead>
                                <tr>
                                    <th>Departments</th>
                                    <th
                                        v-for="(event, index) in sortedEvents"
                                        :key="event.id"
                                        class="border-left"
                                        :style="`width:calc(80% / ${sortedEvents.length})`"
                                    >
                                        <div class="d-flex">
                                            <a
                                                v-if="index !== 0"
                                                href="#"
                                                @click.prevent="setOrder(event.id, event.order - 1)"
                                            >
                                                <i class="mdi mdi-arrow-left-bold"></i>
                                            </a>
                                            <div class="flex-fill text-center">
                                                {{ event.name }}
                                                <a
                                                    v-if="hiddenEvents.length < summaryData.length - 1"
                                                    href="#"
                                                    class="ml-1"
                                                    @click.prevent="hideEvent(event.id)"
                                                >
                                                    <i class="mdi mdi-eye-off"></i>
                                                </a>
                                            </div>
                                            <a
                                                v-if="index !== summaryData.length - 1"
                                                href="#"
                                                @click.prevent="setOrder(event.id, event.order + 1, true)"
                                            >
                                                <i class="mdi mdi-arrow-right-bold"></i>
                                            </a>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="department in filteredDepartments" :key="department.id">
                                    <tr>
                                        <th
                                            class="selectable"
                                            :class="{
                                                selected: department.is_expanded,
                                                'start-row': department.is_expanded,
                                            }"
                                            @click.prevent="toggleDepartment(department.id)"
                                        >
                                            <div class="d-flex align-items-center">
                                                <div class="mr-auto">{{ department.name }}</div>
                                                <i
                                                    :class="
                                                        department.is_expanded
                                                            ? 'mdi mdi-chevron-up'
                                                            : 'mdi mdi-chevron-down'
                                                    "
                                                ></i>
                                            </div>
                                        </th>
                                        <td
                                            v-for="event in sortedEvents"
                                            :key="'main_' + event.id"
                                            :class="{
                                                'border-left': !department.is_expanded,
                                                'bg-light': !department.events[event.id] && !department.is_expanded,
                                                'start-row selected': department.is_expanded,
                                            }"
                                        >
                                            {{
                                                !!department.events[event.id] && !department.is_expanded
                                                    ? localise(department.events[event.id][1], 2, true)
                                                    : ''
                                            }}
                                        </td>
                                    </tr>
                                    <template v-if="department.is_expanded">
                                        <tr>
                                            <td>Subtotal</td>
                                            <td v-for="event in sortedEvents" :key="'subtotal_' + event.id">
                                                {{
                                                    !!department.events[event.id]
                                                        ? localise(department.events[event.id][1], 2, true)
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tax Amount</td>
                                            <td v-for="event in sortedEvents" :key="'tax_' + event.id">
                                                {{
                                                    !!department.events[event.id]
                                                        ? localise(department.events[event.id][2], 2, true)
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td v-for="event in sortedEvents" :key="'total_' + event.id">
                                                {{
                                                    !!department.events[event.id]
                                                        ? localise(department.events[event.id][3], 2, true)
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Paid</td>
                                            <td v-for="event in sortedEvents" :key="'paid_' + event.id">
                                                {{
                                                    !!department.events[event.id]
                                                        ? localise(department.events[event.id][4], 2, true)
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Pending Payment</td>
                                            <td v-for="event in sortedEvents" :key="'pending_' + event.id">
                                                {{
                                                    !!department.events[event.id]
                                                        ? localise(department.events[event.id][5], 2, true)
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="end-row">Credit Noted</td>
                                            <td
                                                v-for="event in sortedEvents"
                                                :key="'credit_' + event.id"
                                                class="end-row"
                                            >
                                                {{
                                                    !!department.events[event.id]
                                                        ? localise(department.events[event.id][6], 2, true)
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <LoadingSpinner v-else label="Loading Information" />
    </div>
</template>
<script>
import LoadingSpinner from '../../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';

export default {
    components: {
        LoadingSpinner,
        Button,
        FormField,
    },
    props: {
        events: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isLoading: false,
            displayedEvents: [],
            hiddenEvents: [],
            currentPage: 'summary',
            displaying: 'costs',
            summaryData: null,
            departmentsData: null,
        };
    },
    computed: {
        sortedEvents() {
            return this.summaryData.filter((e) => !this.hiddenEvents.includes(e.id)).sort((a, b) => a.order - b.order);
        },
        filteredDepartments() {
            // Map required in order to preserve the original structure of the departmentsData endpoint response post-refactoring
            return this.departmentsData
                .filter((d) => d.type == (this.displaying === 'costs' ? 1 : 2))
                .map((department) => {
                    if (Array.isArray(department.events)) {
                        const newEvents = {};
                        department.events.forEach((event) => {
                            newEvents[event.id] = event.invoices;
                        });
                        department.events = newEvents;
                    }
                    return department;
                });
        },
    },
    watch: {
        currentPage(newVal) {
            if (newVal === 'departments') {
                this.getDepartmentsData();
                return;
            }
            this.getSummaryData();
        },
    },
    mounted() {
        this.displayedEvents = this.events;
        this.getSummaryData();
    },
    methods: {
        getSummaryData() {
            this.isLoading = true;
            axios
                .post(route('api.account.reporting.eventDeptsAndCats'), {
                    showColumns: this.displayedEvents,
                })
                .then(({ data }) => {
                    // data[0] is done here to preserve the original response structure of the API endpoint
                    this.summaryData = data[0]['eventNames'].map((event, index) => {
                        event.order = index;
                        event.costs = {
                            subtotal: data[0]['costs'][0][index + 1],
                            tax_amount: data[0]['costs'][1][index + 1],
                            total: data[0]['costs'][2][index + 1],
                            total_paid: data[0]['costs'][3][index + 1],
                            total_pending: data[0]['costs'][3][index + 1],
                        };
                        event.revenue = {
                            subtotal: data[0]['revenues'][0][index + 1],
                            tax_amount: data[0]['revenues'][1][index + 1],
                            total: data[0]['revenues'][2][index + 1],
                            total_paid: data[0]['revenues'][3][index + 1],
                            total_pending: data[0]['revenues'][3][index + 1],
                        };
                        return event;
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getDepartmentsData() {
            this.isLoading = true;
            axios
                .post(route('api.account.reporting.departmentInvoices'), {
                    showColumns: this.displayedEvents,
                })
                .then(({ data }) => {
                    this.departmentsData = data;
                })
                .finally(() => (this.isLoading = false));
        },
        setOrder(eventId, newOrder, isRight = false) {
            this.summaryData = this.summaryData.map((event) => {
                if (event.id === eventId) {
                    event.order = newOrder;
                } else if (event.order === newOrder) {
                    event.order = isRight ? newOrder - 1 : newOrder + 1;
                }
                return event;
            });
        },
        hideEvent(eventId) {
            if (!this.hiddenEvents.includes(eventId)) {
                this.hiddenEvents.push(eventId);
            }
        },
        toggleDepartment(departmentId) {
            this.departmentsData = this.departmentsData.map((department) => {
                if (department.id === departmentId) {
                    department.is_expanded = !department.is_expanded;
                }
                return department;
            });
        },
    },
};
</script>
