<template>
    <DataTable
        ref="table"
        :data="rows"
        class="buefy-wrapper tw-rounded tw-overflow-hidden tw-mb-3"
        :columns="[
            {
                field: 'title',
                title: 'Title',
            },
            ...columns
                .map((c) => ({
                    field: c.toLowerCase().replace(' ', '_'),
                    title: ['Available Tickets'].includes(c) ? '' : c,
                    width: 175,
                }))
                .filter((c) => (store.get('current_scenario') ? true : !c.title.includes('Scenario'))),
            {
                field: 'actions',
                title: '',
                width: 200,
            },
        ]"
        :row-class="(row) => (isDirectCost(row) ? 'tw-bg-gray-50 !tw-cursor-default' : 'hover:tw-bg-slate-50')"
    >
        <template #heading(title)>
            <div class="tw-flex tw-gap-3 tw-items-center">
                <h4 class="tw-m-0">{{ title }}</h4>
                <Button
                    v-if="$refs.table && $refs.table.expandedRows.length !== data.length"
                    type="is-link"
                    class="tw-font-[400]"
                    @click="() => ($refs.table.expandedRows = data.map((d, i) => i + directCostRows.length))"
                >
                    Expand all
                </Button>
                <Button
                    v-else-if="$refs.table"
                    type="is-link"
                    class="tw-font-[400]"
                    @click="() => ($refs.table.expandedRows = [])"
                >
                    Collapse all
                </Button>
            </div>
        </template>

        <template #heading(manual_estimates)>
            <FigureCard title="Manual Estimate" :amount="totals.initialEstimate" />
        </template>

        <template #heading(fixed)>
            <FigureCard title="Fixed" :amount="totals.fixed" />
        </template>

        <template #heading(budgeted)>
            <FigureCard title="Budgeted" :amount="totals.budgeted" />
        </template>

        <template #heading(actual)>
            <FigureCard title="Actual" :amount="totals.actual" />
        </template>

        <template #heading(variance)>
            <FigureCard title="Variance" :amount="totals.variance" highlight />
        </template>
        <template #heading(tickets_sold)>
            <FigureCard title="Tickets Sold" :amount="totals.ticketsSold" :currency="false" :decimals="0" />
        </template>
        <template #heading(remaining_budget)>
            <FigureCard
                title="Remaining Budget"
                :amount="totals.remainingBudget"
                :value-class="totals.remainingBudget < 0 ? 'danger' : ''"
            />
        </template>
        <template #heading(invoiced)>
            <FigureCard title="Invoiced" :amount="totals.invoiced" />
        </template>
        <template #heading(outstanding)>
            <FigureCard title="Outstanding" :amount="totals.outstanding" />
        </template>
        <template #heading(paid)>
            <FigureCard title="Paid" :amount="totals.paid" />
        </template>

        <template #heading(scenario_variance)>
            <FigureCard :amount="totals.scenarioVariance" title="Scenario Variance" highlight />
        </template>

        <template #heading(markup_amount)>
            <FigureCard
                v-if="type !== 'revenueStream'"
                title="Markup Amount"
                :amount="totals.markupAmount"
                currency
                :decimals="0"
            />
        </template>

        <template #heading(scenario)>
            <FigureCard :amount="totals.scenario" title="Scenario" />
        </template>

        <template #heading(actions)>
            <div class="tw-flex tw-gap-3 tw-justify-end">
                <slot name="controls"></slot>
            </div>
        </template>

        <template #column(title)="{ row: objectData }">
            <div class="tw-flex tw-items-center tw-flex-1 tw-gap-3 tw-p-3">
                <i class="ew-object-icon tw-bg-white" :class="iconPath(objectData.icon ? objectData : type)"></i>
                <div>
                    <h6 class="tw-m-0">
                        {{ objectData.name }}
                        <span
                            v-if="departmentBudgetTolerance(objectData) && !isDirectCost(objectData)"
                            class="tw-inline-flex"
                        >
                            <Tooltip label="This department is approaching overspend" position="right">
                                <i class="mdi mdi-alert tw-text-orange-500"></i>
                            </Tooltip>
                        </span>
                    </h6>
                    <small class="tw-text-slate-400">
                        {{
                            !isDirectCost(departmentBudgetTolerance)
                                ? objectData.description
                                : 'Direct costs associated with a revenue stream'
                        }}
                    </small>
                </div>
            </div>
        </template>

        <template #column(available_tickets)="{ row: objectData }">
            <FigureCard
                v-if="objectData.stream_type === 0"
                title="Available Tickets"
                :amount="totalTicketTypes(objectData)"
                :currency="false"
                :decimals="0"
                figure-class="primary"
            />
        </template>

        <template #column(markup_amount)="{ row }">
            <FigureCard
                v-if="!isTickets(row) && ['costDepartment', 'category'].includes(type) && !row.is_revenue"
                title="Markup Amount"
                :amount="markup(row)"
                currency
                :decimals="0"
                figure-class="primary"
            />
        </template>

        <template #column(tickets_sold)="{ row: objectData }">
            <FigureCard
                v-if="objectData.stream_type === 0"
                title="Tickets Sold"
                :amount="ticketsSoldCount(objectData)"
                :currency="false"
                :decimals="0"
                figure-class="primary"
            />
        </template>

        <template #column(manual_estimates)="{ row: objectData }">
            <Tooltip
                :label="'Click to alter the manual estimate for ' + objectData.name"
                position="left"
                class="tw-w-full"
                :disabled="eventIsClosed"
            >
                <div @click.stop="setEstimate(objectData)">
                    <FigureCard
                        title="Manual Estimate"
                        :amount="objectData.estimates.fixed_amount"
                        figure-class="estimate"
                    >
                        <template v-if="!eventIsClosed" #prepend>
                            <i class="mdi mdi-pencil mr-1"></i>
                        </template>
                    </FigureCard>
                </div>
            </Tooltip>
        </template>

        <template #column(fixed)="{ row: objectData }">
            <FigureCard title="Fixed" :amount="fixed(objectData)" figure-class="fixed" />
        </template>

        <template #column(budgeted)="{ row: objectData }">
            <Tooltip
                class="tw-w-full"
                position="left"
                :disabled="objectData.stream_type === 0 || !objectData.total_budget_entries"
            >
                <FigureCard title="Budgeted" :amount="budgeted(objectData)" figure-class="budgeted" />
                <template #content>
                    Draft: {{ localise(objectData.total_budget_entries.draft, 2, true) }}
                    <br />
                    Awaiting Approval: {{ localise(objectData.total_budget_entries.approvals, 2, true) }}
                    <br />
                    Approved: {{ localise(objectData.total_budget_entries.approved, 2, true) }}
                </template>
            </Tooltip>
        </template>

        <template #column(actual)="{ row: objectData }">
            <FigureCard title="Actual" :amount="actual(objectData)" figure-class="committed" />
        </template>

        <template #column(remaining_budget)="{ row: objectData }">
            <FigureCard
                title="Remaining Budget"
                :amount="remainingBudget(objectData)"
                figure-class="remaining"
                :value-class="remainingBudget(objectData) < 0 ? 'danger' : ''"
            />
        </template>

        <template #column(variance)="{ row: objectData }">
            <FigureCard title="Variance" :amount="variance(objectData)" figure-class="fixed" highlight />
        </template>

        <template #column(invoiced)="{ row: objectData }">
            <FigureCard
                title="Invoiced"
                :amount="objectData.total_invoiced"
                :class="{
                    'tw-opacity-0': isTickets(objectData),
                }"
                figure-class="invoiced"
            />
        </template>

        <template #column(outstanding)="{ row: objectData }">
            <FigureCard
                title="Outstanding"
                :amount="outstanding(objectData)"
                :class="{
                    'tw-opacity-0': isTickets(objectData),
                }"
                figure-class="paid"
            />
        </template>

        <template #column(paid)="{ row: objectData }">
            <FigureCard
                title="Paid"
                :amount="objectData.total_paid"
                :class="{
                    'tw-opacity-0': isTickets(objectData),
                }"
                figure-class="paid"
            />
        </template>

        <template v-if="store.get('current_scenario')" #column(scenario_variance)="{ row: objectData }">
            <FigureCard
                :amount="scenarioVariance(objectData)"
                title="Scenario Variance"
                figure-class="fixed"
                highlight
            />
        </template>

        <template v-if="store.get('current_scenario')" #column(scenario)="{ row: objectData }">
            <FigureCard :amount="scenario(objectData)" title="Scenario" figure-class="scenario" />
        </template>

        <template #column(actions)="{ row: objectData }">
            <div class="tw-flex tw-gap-2 tw-justify-end">
                <LockItemControls
                    v-if="page === 'budget' && isDirector && !isTickets(objectData)"
                    lockable-name=""
                    :lockable-type="type"
                    :lockable="objectData"
                    :disabled="store.get('current_event.is_locked') || eventIsClosed"
                />

                <template v-if="type === 'revenueStream' && !isDirectCost(objectData)">
                    <Tooltip v-if="isTickets(objectData) && page !== 'actuals'" label="Manage Tickets" position="left">
                        <Button @click.stop="openTicketingDrawer()">
                            <i class="mdi mdi-ticket-confirmation-outline"></i>
                        </Button>
                    </Tooltip>
                    <Tooltip
                        v-if="page === 'budget' && !isTickets(objectData) && !eventIsClosed"
                        label="New Budget Entry"
                        position="left"
                    >
                        <Button :disabled="objectData.is_locked" @click.stop="openNewBudgetEntryDrawer(objectData)">
                            <i class="mdi mdi-plus"></i>
                        </Button>
                    </Tooltip>
                    <Tooltip
                        v-else-if="page === 'revenue' && !isTickets(objectData) && !eventIsClosed"
                        label="New Revenue Entry"
                        position="left"
                    >
                        <Button @click.stop="openNewRevenueEntryDrawer(objectData)">
                            <i class="mdi mdi-cash-plus"></i>
                        </Button>
                    </Tooltip>
                </template>
                <template v-else>
                    <Tooltip
                        v-if="
                            page === 'budget' && !isTickets(objectData) && !isDirectCost(objectData) && !eventIsClosed
                        "
                        label="New Budget Entry"
                        position="left"
                    >
                        <Button :disabled="objectData.is_locked" @click.stop="openNewBudgetEntryDrawer(objectData)">
                            <i class="mdi mdi-plus"></i>
                        </Button>
                    </Tooltip>
                    <Tooltip
                        v-else-if="
                            page === 'purchase' &&
                            !isDirectCost(objectData) &&
                            (!type === 'category' || !objectData.is_pooled) &&
                            !eventIsClosed
                        "
                        label="New Purchase Order"
                        position="left"
                    >
                        <Button @click.stop="openNewPurchaseOrderDrawer(objectData)">
                            <i class="mdi mdi-receipt-text-plus"></i>
                        </Button>
                    </Tooltip>
                    <Tag
                        v-if="
                            page === 'purchase' &&
                            !isDirectCost(objectData) &&
                            type === 'category' &&
                            objectData.is_pooled
                        "
                        type="primary"
                    >
                        Pooled
                    </Tag>
                </template>
                <Tooltip
                    v-if="
                        selectable &&
                        !['revenue', 'actuals'].includes(page) &&
                        !(isTickets(objectData) && type === 'category') &&
                        !isDirectCost(objectData)
                    "
                    :label="'Enter ' + objectData.name"
                    position="left"
                >
                    <Button type="is-info" @click.stop="viewObject(objectData)">
                        <i class="mdi mdi-arrow-right"></i>
                    </Button>
                </Tooltip>
                <Tooltip
                    v-else-if="!['revenue', 'actuals'].includes(page) && isTickets(objectData) && type === 'category'"
                    label="Manage Tickets"
                    position="left"
                >
                    <Button @click.stop="openTicketingDrawer()">
                        <i class="mdi mdi-ticket-confirmation-outline"></i>
                    </Button>
                </Tooltip>
            </div>
        </template>

        <template #expanded-row="{ row: selectedRow }">
            <Tabs
                v-if="selectedRow && !isDirectCost(selectedRow)"
                :tabs="
                    [
                        isTickets(selectedRow)
                            ? [
                                  {
                                      name: 'tickets',
                                      title: 'Tickets',
                                  },
                                  {
                                      name: 'ticketSellers',
                                      title: 'Ticket Sellers',
                                  },
                                  page !== 'revenue' && scenariosEnabled
                                      ? {
                                            name: 'ticketScenarios',
                                            title: 'Scenarios',
                                        }
                                      : null,
                              ]
                            : null,
                        !isTickets(selectedRow) && page === 'budget'
                            ? [
                                  ['revenueStream', 'costDepartment'].includes(type)
                                      ? {
                                            name: 'departmentSummary',
                                            title: 'Department Summary',
                                        }
                                      : null,
                                  {
                                      name: 'budgetEntries',
                                      title: 'Budget Entries',
                                  },
                                  type === 'costDepartment'
                                      ? {
                                            name: 'rechargedEntries',
                                            title: 'Recharged Entries',
                                        }
                                      : null,
                                  ['revenueStream', 'costDepartment'].includes(type)
                                      ? {
                                            name: 'departmentInfo',
                                            title: 'Department Info',
                                        }
                                      : null,
                              ]
                            : null,
                        !isTickets(selectedRow) && page === 'purchase'
                            ? [
                                  {
                                      name: 'purchaseOrders',
                                      title: 'Purchase Orders',
                                  },
                              ]
                            : null,
                        !isTickets(selectedRow) && page === 'revenue'
                            ? [
                                  {
                                      name: 'revenueEntries',
                                      title: 'Revenue Entries',
                                  },
                              ]
                            : null,
                        !isTickets(selectedRow) && page === 'actuals'
                            ? [
                                  {
                                      name: 'subcategories',
                                      title: 'Subcategories',
                                  },
                              ]
                            : null,
                    ]
                        .flat()
                        .filter((t) => t)
                "
            >
                <template #tab(tickets)>
                    <TableTickets
                        :revenue-stream="revenueStreamId(selectedRow)"
                        :categories="type === 'category' ? [selectedRow.id] : null"
                        :actuals="page === 'revenue'"
                    />
                </template>
                <template #tab(ticketSellers)>
                    <TableTicketSellers
                        :revenue-stream="revenueStreamId(selectedRow)"
                        :categories="type === 'category' ? [selectedRow.id] : null"
                        :actuals="page === 'revenue'"
                    />
                </template>
                <template #tab(ticketScenarios)>
                    <TableTicketScenarios
                        :revenue-stream="revenueStreamId(selectedRow)"
                        :categories="type === 'category' ? [selectedRow.id] : null"
                    />
                </template>
                <template #tab(departmentSummary)>
                    <ObjectSummary :object="selectedRow" :type="type" />
                </template>
                <template #tab(budgetEntries)>
                    <TableBudgetEntries
                        :hide-category="type === 'category'"
                        :endpoint="
                            type === 'category'
                                ? route('api.account.event.categories.entries', { category: selectedRow.id })
                                : route('api.account.event.departments.entries', {
                                      department: type === 'revenueStream' ? selectedRow.department_id : selectedRow.id,
                                  })
                        "
                        :checkable="!selectedRow.is_locked"
                        :type="type"
                        :object="selectedRow"
                        :has-markups="type === 'costDepartment' || (type === 'category' && !selectedRow.is_revenue)"
                        createable
                        filterable
                        :hidden-columns="
                            [
                                columns.includes('Markup Amount') ? null : 'markup',
                                columns.includes('Available Tickets') ? null : 'available_tickets',
                                columns.includes('Fixed') ? null : 'fixed',
                                columns.includes('Budgeted') ? null : 'budgeted',
                                columns.includes('Actual') ? null : 'actual',
                                columns.includes('Variance') ? null : 'variance',
                            ].filter((c) => c)
                        "
                    />
                </template>
                <template #tab(rechargedEntries)>
                    <TableBudgetEntries
                        :hide-category="type === 'category'"
                        :endpoint="
                            type === 'category'
                                ? route('api.account.event.categories.entries', {
                                      category: selectedRow.id,
                                      recharges: true,
                                  })
                                : route('api.account.event.departments.entries', {
                                      department: type === 'revenueStream' ? selectedRow.department_id : selectedRow.id,
                                      recharges: true,
                                  })
                        "
                        filterable
                        :checkable="!selectedRow.is_locked"
                        :type="type"
                        :object="selectedRow"
                        recharges
                    />
                </template>
                <template #tab(departmentInfo)>
                    <DepartmentInfo :object="selectedRow" />
                </template>
                <template #tab(purchaseOrders)>
                    <TablePurchaseOrders
                        :endpoint="
                            type === 'category'
                                ? route('api.account.event.purchaseorders.category.all', {
                                      category: selectedRow.id,
                                      paginated: 1,
                                  })
                                : route('api.account.event.purchaseorders.department', {
                                      department: selectedRow.id,
                                      paginated: 1,
                                  })
                        "
                        filterable
                        checkable
                        :hide-category="type === 'category'"
                        :hidden-columns="
                            [
                                columns.includes('Markup Amount') ? null : 'markup',
                                columns.includes('Budgeted') ? null : 'budgeted',
                                columns.includes('Actual') ? null : 'actual',
                                columns.includes('Variance') ? null : 'variance',
                                columns.includes('Invoiced') ? null : 'invoiced',
                                columns.includes('Outstanding') ? null : 'outstanding',
                            ].filter((c) => c)
                        "
                    />
                </template>
                <template #tab(revenueEntries)>
                    <TableRevenueEntries
                        :endpoint="
                            type === 'category'
                                ? route('api.account.event.categories.revenueEntries', {
                                      category: selectedRow.id,
                                      paginated: 1,
                                  })
                                : route('api.account.event.streams.entries', {
                                      stream: selectedRow.id,
                                      paginated: 1,
                                  })
                        "
                        :hidden-columns="
                            [
                                columns.includes('Fixed') ? null : 'fixed',
                                columns.includes('Budgeted') ? null : 'budgeted',
                                columns.includes('Actual') ? null : 'actual',
                                columns.includes('Variance') ? null : 'variance',
                                columns.includes('Invoiced') ? null : 'invoiced',
                                columns.includes('Paid') ? null : 'paid',
                                columns.includes('Outstanding') ? null : 'outstanding',
                            ].filter((c) => c)
                        "
                    />
                </template>
                <template #tab(subcategories)>
                    <TableActuals :item="selectedRow" />
                </template>
            </Tabs>
        </template>
    </DataTable>
</template>
<script>
import ObjectSummary from './ObjectRow/ObjectSummary.vue';
import DepartmentInfo from './ObjectRow/DepartmentInfo.vue';
import TableTickets from '../tables/Budget/TicketsTable.vue';
import TableTicketSellers from '../tables/Budget/TicketSellers.vue';
import TableTicketScenarios from '../tables/Budget/TicketScenarios.vue';
import TableBudgetEntries from '../tables/Budget/BudgetEntries.vue';
import TablePurchaseOrders from '../tables/Purchasing/PurchaseOrders.vue';
import TableRevenueEntries from '../tables/Revenue/RevenueEntries.vue';
import TableActuals from '../tables/Actuals/Actuals.vue';
import LockItemControls from './LockItemControls.vue';
import FigureCard from './FigureCard.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import DataTable from '../tables/DataTable.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        ObjectSummary,
        DepartmentInfo,
        TableTickets,
        TableTicketSellers,
        TableTicketScenarios,
        TableBudgetEntries,
        TablePurchaseOrders,
        TableRevenueEntries,
        TableActuals,
        LockItemControls,
        FigureCard,
        DataTable,
        Button,
        Tooltip,
        Tag,
        Tabs,
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
            validator(val) {
                return val.every((v) =>
                    [
                        'Manual Estimates',
                        'Budgeted',
                        'Actual',
                        'Variance',
                        'Remaining Budget',
                        'Invoiced',
                        'Outstanding',
                        'Fixed',
                        'Tickets Sold',
                        'Available Tickets',
                        'Scenario',
                        'Scenario Variance',
                        'Paid',
                        'Markup Amount',
                    ].includes(v)
                );
            },
        },
        type: {
            type: String,
            required: true,
            validator(val) {
                return ['revenueStream', 'costDepartment', 'category'].includes(val);
            },
        },
        data: {
            type: Array,
            default: () => [],
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['selected'],
    data() {
        const store = useDataStore();
        const settings = useSettingsStore();
        return {
            store: store,
            settings: settings,
            page: (() => {
                if (route().current() === 'account.event.budgetVActuals') {
                    return 'actuals';
                }
                if (route().current().startsWith('account.event.budget')) {
                    return 'budget';
                }
                if (route().current() === 'account.event.revenue.dashboard') {
                    return 'revenue';
                }
                if (route().current() === 'account.event.purchaseorders.summary') {
                    return 'purchase';
                }
                return null;
            })(),
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        title() {
            return this.type === 'revenueStream'
                ? 'Revenue Streams'
                : this.type === 'costDepartment'
                  ? 'Cost Departments'
                  : 'Subcategories';
        },
        directCostRows() {
            if (this.type !== 'costDepartment') {
                return [];
            }
            return Object.values(this.store.get('current_event.revenue_streams') ?? {})
                .filter((r) => r.stream_type === 0)
                .sort((a, b) => {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if (x > y) {
                        return 1;
                    } else if (x < y) {
                        return -1;
                    }
                    return 0;
                });
        },
        rows() {
            return [
                ...this.directCostRows,
                ...[...this.data].sort((a, b) => {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if (x > y) {
                        return 1;
                    } else if (x < y) {
                        return -1;
                    }
                    return 0;
                }),
            ];
        },
        scenariosEnabled() {
            return this.store.get('account.scenarios_enabled');
        },
        totals() {
            const totals = {};
            if (this.columns.includes('Budgeted')) {
                totals.budgeted = this.rows.map(this.budgeted).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Actual')) {
                totals.actual = this.rows.map(this.actual).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Variance')) {
                totals.variance = this.rows.map(this.variance).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Remaining Budget')) {
                totals.remainingBudget = this.rows.map(this.remainingBudget).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Markup Amount')) {
                totals.markupAmount = this.rows.map(this.markup).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Invoiced')) {
                totals.invoiced = this.rows.map((row) => row.total_invoiced).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Outstanding')) {
                totals.outstanding = this.rows.map(this.outstanding).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Fixed')) {
                totals.fixed = this.rows.map(this.fixed).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Tickets Sold')) {
                totals.ticketsSold = this.rows.map(this.ticketsSoldCount).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Available Tickets')) {
                totals.tickets = this.rows.map(this.totalTicketTypes).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Manual Estimates')) {
                totals.initialEstimate = this.rows
                    .map((row) => row.estimates?.fixed_amount ?? 0)
                    .reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Scenario Variance') && this.store.get('current_scenario')) {
                totals.scenarioVariance = this.rows.map(this.scenarioVariance).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Scenario') && this.store.get('current_scenario')) {
                totals.scenario = this.rows.map(this.scenario).reduce((a, b) => a + b, 0);
            }
            if (this.columns.includes('Paid')) {
                totals.paid = this.rows.map((row) => row.total_paid).reduce((a, b) => a + b, 0);
            }
            return totals;
        },
    },
    methods: {
        isDirectCost(objectData) {
            return objectData.stream_type === 0 && this.type === 'costDepartment';
        },
        isTickets(objectData) {
            return objectData.stream_type === 0;
        },
        isDirector() {
            return !!Auth?.user?.roles?.find((role) => role.name === 'Director');
        },
        revenueStreamId(objectData) {
            if (!objectData || this.type === 'costDepartment') {
                return null;
            } else if (this.type === 'revenueStream') {
                return objectData.id;
            }
            const revenueStream = Object.values(this.store.get('current_event.revenue_streams')).find(
                (r) => r.department_id === objectData.department_id
            );
            return revenueStream ? revenueStream.id : null;
        },
        ticketsSoldCount(objectData) {
            return Object.values(this.store.get('current_event.ticket_types'))
                .filter((ticket) => ticket.revenue_stream_id === objectData.id)
                .map((ticket) => ticket.tickets_sold)
                .reduce((acc, ticket) => acc + ticket, 0);
        },
        iconPath(objectData) {
            switch (objectData) {
                case 'revenueStream':
                    return 'mdi mdi-cash-multiple';
                case 'costDepartment':
                    return 'mdi mdi-cart-outline';
                case 'category':
                    return 'mdi mdi-folder-outline';
            }
            return this.store.get('icons').find((icon) => icon.value === objectData?.icon)?.path;
        },
        outstanding(objectData) {
            return objectData.total_invoiced_gross - objectData.total_paid - objectData.total_credit;
        },
        variance(objectData) {
            if (this.type === 'revenueStream' || (this.type === 'category' && objectData.is_revenue)) {
                return this.actual(objectData) - this.budgeted(objectData);
            }
            return this.budgeted(objectData) - this.actual(objectData);
        },
        actual(objectData) {
            return !this.isDirectCost(objectData) ? objectData.total_actual : objectData.total_actual_costs;
        },
        remainingBudget(objectData) {
            return !this.isDirectCost(objectData)
                ? objectData.total_budgeted - objectData.total_actual
                : objectData.total_fixed_costs - objectData.total_actual_costs;
        },
        budgeted(objectData) {
            if (this.isTickets(objectData)) {
                return (
                    (['revenueStream', 'category'].includes(this.type)
                        ? objectData.total_budgeted
                        : objectData.total_fixed_costs) ?? 0
                );
            }
            if (!objectData.total_budget_entries) {
                return 0;
            }
            const type =
                this.type === 'category'
                    ? objectData.is_revenue
                        ? 'revenue'
                        : 'cost'
                    : this.type === 'revenueStream'
                      ? 'revenue'
                      : 'cost';
            return Object.keys(this.settings.get(`budgetFigures.${type}`))
                .map((key) => {
                    return this.settings.get(`budgetFigures.${type}.${key}`) ? objectData.total_budget_entries[key] : 0;
                })
                .reduce((a, b) => a + b, 0);
        },
        scenario(objectData) {
            if (this.type === 'revenueStream') {
                if (this.isDirectCost(objectData)) {
                    return objectData.total_fixed_scenario_costs;
                }
                return this.budgeted(objectData) + objectData.total_scenario;
            }

            if (this.isDirectCost(objectData)) {
                return objectData.total_fixed_scenario_costs;
            }
            return this.budgeted(objectData) + objectData.total_scenario;
        },
        scenarioVariance(objectData) {
            if (this.type === 'revenueStream' || (this.type === 'category' && objectData.is_revenue)) {
                return this.actual(objectData) - this.scenario(objectData);
            }
            return this.scenario(objectData) - this.actual(objectData);
        },
        fixed(objectData) {
            if (this.isTickets(objectData)) {
                return (
                    (['revenueStream', 'category'].includes(this.type)
                        ? objectData.total_fixed
                        : objectData.total_fixed_costs) ?? 0
                );
            }
            if (!objectData.total_budget_entries) {
                return 0;
            }
            const type =
                this.type === 'category'
                    ? objectData.is_revenue
                        ? 'revenue'
                        : 'cost'
                    : this.type === 'revenueStream'
                      ? 'revenue'
                      : 'cost';
            return Object.keys(this.settings.get(`budgetFigures.${type}`))
                .map((key) =>
                    this.settings.get(`budgetFigures.${type}.${key}`) ? objectData.total_budget_estimates[key] : 0
                )
                .reduce((a, b) => a + b, 0);
        },
        markup(objectData) {
            if (this.isTickets(objectData) || this.type === 'revenueStream') {
                return 0;
            }
            const type =
                this.type === 'category'
                    ? objectData.is_revenue
                        ? 'revenue'
                        : 'cost'
                    : this.type === 'revenueStream'
                      ? 'revenue'
                      : 'cost';
            return Object.keys(this.settings.get(`budgetFigures.${type}`))
                .map((key) => (this.settings.get(`budgetFigures.${type}.${key}`) ? objectData.total_markups[key] : 0))
                .reduce((a, b) => a + b, 0);
        },
        viewObject(objectData) {
            this.$emit('selected', {
                type: this.type,
                data: objectData,
            });
        },
        openTicketingDrawer() {
            Eventbus.$emit('view:ticketType');
        },
        openNewBudgetEntryDrawer(objectData) {
            switch (this.type) {
                case 'category':
                    Eventbus.$emit('newBudgetEntry:open', {
                        department: objectData.department_id,
                        category: objectData.id,
                    });
                    break;
                case 'revenueStream':
                    Eventbus.$emit('newBudgetEntry:open', {
                        department: objectData.department_id,
                    });
                    break;
                default:
                    Eventbus.$emit('newBudgetEntry:open', {
                        department: objectData.id,
                    });
                    break;
            }
        },
        openNewPurchaseOrderDrawer(objectData) {
            if (this.type === 'category') {
                Eventbus.$emit('purchase-order:create', {
                    department: objectData.department_id,
                    category: objectData.id,
                });
                return;
            }
            Eventbus.$emit('purchase-order:create', {
                department: objectData.id,
            });
        },
        openNewRevenueEntryDrawer(objectData) {
            Eventbus.$emit('revenue:create', { stream: objectData.id });
        },
        setEstimate(objectData) {
            if (this.eventIsClosed) {
                return;
            }
            swal.fire({
                title: 'Change ' + (this.type === 'category' ? 'Subcategory' : 'Department') + ' Estimate',
                text: 'Please enter the new fixed value estimate for ' + objectData.name,
                input: 'number',
                inputValue:
                    objectData['estimates'] && objectData['estimates']['fixed_amount']
                        ? objectData['estimates']['fixed_amount']
                        : 0,
                inputAttributes: {
                    min: 0,
                    step: 0.01,
                    class: 'flex-fill',
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'The fixed value cannot be empty';
                    } else if (value < 0) {
                        return 'The fixed value must be above ' + localise(0, 2, true);
                    }
                },
            }).then((result) => {
                if (
                    result.hasOwnProperty('dismiss') &&
                    [swal.DismissReason.cancel, swal.DismissReason.backdrop].includes(result.dismiss)
                ) {
                    return;
                }

                const url =
                    this.type === 'category'
                        ? route('api.account.event.categories.update', {
                              category: objectData.id,
                          })
                        : route('api.account.event.departments.update', {
                              department: this.type === 'revenueStream' ? objectData.department_id : objectData.id,
                          });
                axios
                    .post(url, {
                        fixed: result.value,
                    })
                    .then(() => {
                        if (this.type === 'revenueStream') {
                            this.store.set(
                                'current_event.revenue_streams.' + objectData.id + '.estimates.fixed_amount',
                                parseFloat(result.value)
                            );
                        } else if (this.type === 'costDepartment') {
                            this.store.set(
                                'current_event.cost_departments.' + objectData.id + '.estimates.fixed_amount',
                                parseFloat(result.value)
                            );
                        } else {
                            Object.values(this.store.get('current_event.revenue_streams')).forEach((revenueStream) => {
                                Object.values(revenueStream.categories).forEach((category) => {
                                    if (category.id === objectData.id) {
                                        this.store.set(
                                            'current_event.revenue_streams.' +
                                                revenueStream.id +
                                                '.categories.' +
                                                category.id +
                                                '.estimates.fixed_amount',
                                            parseFloat(result.value)
                                        );
                                    }
                                });
                            });
                            Object.values(this.store.get('current_event.cost_departments')).forEach(
                                (costDepartment) => {
                                    Object.values(costDepartment.categories).forEach((category) => {
                                        if (category.id === objectData.id) {
                                            this.store.set(
                                                'current_event.cost_departments.' +
                                                    costDepartment.id +
                                                    '.categories.' +
                                                    category.id +
                                                    '.estimates.fixed_amount',
                                                parseFloat(result.value)
                                            );
                                        }
                                    });
                                }
                            );
                        }
                        Eventbus.$emit('forceUpdate:profit-overview');
                    });
            });
        },
        departmentTicketsTypesFromLocalstore(departmentId) {
            const revenueStream = Object.values(this.store.get('current_event.revenue_streams')).find((stream) => {
                return stream.department_id === departmentId;
            });

            if (revenueStream) {
                return Object.values(this.store.get('current_event.ticket_types')).filter((type) => {
                    return type.revenue_stream_id === revenueStream.id;
                });
            }
            return [];
        },
        totalTicketTypes(objectData) {
            if (this.type === 'category') {
                const catTicket = this.departmentTicketsTypesFromLocalstore(objectData.department_id).filter(
                    (ticketType) => ticketType.category_id === objectData.id
                );
                return catTicket.map((ticket) => ticket.tickets_available).reduce((a, c) => a + Number(c), 0);
            }
            return this.departmentTicketsTypesFromLocalstore(objectData.department_id)
                .map((ticket) => ticket.tickets_available)
                .reduce((a, c) => a + Number(c), 0);
        },
        departmentBudgetTolerance(dept) {
            if (dept.type === 1) {
                const budgetSet = this.store.get('current_event.cost_departments.' + dept.id + '.total_budgeted');
                const budgetPaid = this.store.get('current_event.cost_departments.' + dept.id + '.total_paid');
                return budgetPaid > budgetSet * 0.9;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.ew-object-icon {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid rgba(black, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.45em;
}
</style>
