<template>
    <LoadingSpinner v-if="isLoading" label="Loading Information" />
    <div v-else-if="account">
        <div class="d-flex align-items-center mb-3">
            <Tooltip position="right" label="Return to Accounts">
                <Button @click="$emit('back')">
                    <i class="mdi mdi-arrow-left"></i>
                </Button>
            </Tooltip>
            <div class="ml-3">
                <h4>{{ account.name }}</h4>
                <span v-if="account.deleted_at">
                    <small class="text-muted">Deleted {{ timeAgo(account.deleted_at) }}</small>
                </span>
                <span v-else>
                    <small class="text-muted">
                        Created {{ timeAgo(account.created_at) }}
                        <span v-if="account.created_by">by {{ account.created_by.name }}</span>
                    </small>
                </span>
            </div>
            <div class="d-flex align-items-center ml-auto">
                <Tooltip class="mr-1" position="left" label="Shadow">
                    <Button type="is-dark" @click="shadowAccount()">
                        <i class="mdi mdi-box-shadow"></i>
                    </Button>
                </Tooltip>
                <Tooltip v-if="account.deleted_at" position="left" label="Recover Account">
                    <Button type="is-danger" @click="restoreAccount()">
                        <i class="mdi mdi-refresh"></i>
                    </Button>
                </Tooltip>
                <Tooltip v-else position="left" label="Delete Account">
                    <Button type="is-danger" @click="deleteAccount">
                        <i class="mdi mdi-delete"></i>
                    </Button>
                </Tooltip>
            </div>
        </div>
        <div v-if="!isLoading" class="tw-grid md:tw-grid-cols-3 tw-gap-3 xl:tw-grid-cols-4 mt-2">
            <Submenu
                class="md:tw-col-span-1 xl:tw-col-span-1 tw-flex-row md:tw-flex-col"
                :pages="[
                    {
                        title: 'General',
                        value: null,
                    },
                    {
                        title: 'Audits',
                        value: 'audits',
                    },
                    {
                        title: 'Addresses',
                        value: 'addresses',
                    },
                    {
                        title: 'Contacts',
                        value: 'contacts',
                    },
                    {
                        title: 'Departments',
                        value: 'departments',
                    },
                    {
                        title: 'Events',
                        value: 'events',
                    },
                    {
                        title: 'Team',
                        value: 'team',
                    },
                    {
                        title: 'Usergroups',
                        value: 'usergroups',
                    },
                    {
                        title: 'Integrations',
                        value: 'integrations',
                    },
                ]"
                @update:model-value="(page) => (activePage = page)"
            />
            <div class="admin-section-content md:tw-col-span-2 xl:tw-col-span-3">
                <div v-if="!activePage" class="row mb-2">
                    <div class="col-12 col-lg-8">
                        <h5 class="mb-3">General Details</h5>
                        <div class="settings-field mb-2">
                            <label>Name</label>
                            <EditableText
                                v-model="account.name"
                                :max-length="255"
                                @update:model-value="saveChanges"
                            ></EditableText>
                        </div>
                        <div class="settings-field top mb-2">
                            <label>Primary Address</label>
                            <div
                                :class="{
                                    'text-muted': !account.primary_address || !account.primary_address.address,
                                }"
                            >
                                {{
                                    account.primary_address && account.primary_address.address
                                        ? account.primary_address.address
                                        : 'Not set'
                                }}
                            </div>
                        </div>
                        <div class="settings-field mb-2">
                            <label>PO Prefix</label>
                            <EditableText v-model="account.po_prefix" :max-length="255"></EditableText>
                        </div>
                        <div class="settings-field mb-2">
                            <label>Last PO Number</label>
                            <EditableText
                                v-model="account.last_po_number"
                                type="number"
                                :max-length="255"
                            ></EditableText>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <h5 class="mb-3">Features &amp; Flags</h5>
                        <div class="d-flex flex-column">
                            <Checkbox v-model="account.is_demo" :disabled="true">Demo Account</Checkbox>
                            <Checkbox v-model="account.scenarios_enabled" @update:model-value="saveChanges">
                                Scenarios Enabled
                            </Checkbox>
                            <Checkbox v-model="account.eventbrite_enabled" @update:model-value="saveChanges">
                                Eventbrite Enabled
                            </Checkbox>
                            <Checkbox v-model="account.ticketmaster_enabled" @update:model-value="saveChanges">
                                Ticketmaster Enabled
                            </Checkbox>
                            <Checkbox v-model="account.skiddle_enabled" @update:model-value="saveChanges">
                                Skiddle Enabled
                            </Checkbox>
                            <Checkbox v-model="account.two_factor" @update:model-value="saveChanges">
                                Require 2FA
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <Audits v-else-if="activePage === 'audits'" model="App\Models\Account" :model-id="account.id" />
                <Addresses v-else-if="activePage === 'addresses'" :account="account.id" />
                <Contacts v-else-if="activePage === 'contacts'" :account="account.id" />
                <Departments v-else-if="activePage === 'departments'" :account="account.id" />
                <Events v-else-if="activePage === 'events'" :account="account.id" />
                <Team v-else-if="activePage === 'team'" :account="account.id" />
                <Usergroups v-else-if="activePage === 'usergroups'" :account="account.id" />
                <div v-else-if="activePage === 'integrations'" class="tw-items-center">
                    <div class="tw-flex tw-items-center">
                        <img src="@/images/brands/xero_blue.png" class="icon-sm mr-2" />
                        <h4 class="mr-auto">Xero</h4>
                        <Dropdown
                            v-if="
                                account.xero_organisation &&
                                !account.xero_organisation.reconnection_required &&
                                !isSyncingXero
                            "
                        >
                            <a href="#" @click.prevent="triggerFullXeroSync()">Trigger Full Sync</a>
                            <a href="#" @click.prevent="triggerXeroAccountSync()">Sync Accounts</a>
                            <a href="#" @click.prevent="triggerXeroContactSync()">Sync Contacts</a>
                        </Dropdown>
                        <div v-else-if="isSyncingXero" class="tw-animate-spin">
                            <i class="mdi mdi-loading"></i>
                        </div>
                    </div>
                    <template v-if="account.xero_organisation">
                        <div class="row w-100">
                            <div class="col-3 mb-1">Tenant</div>
                            <div class="col-9 mb-1">{{ account.xero_organisation.tenant_name }}</div>
                            <div class="col-3 mb-2">Connected</div>
                            <div class="col-9 mb-2">
                                {{
                                    DateTime.fromISO(account.xero_organisation.created_at).toLocaleString(
                                        DateTime.DATETIME_FULL
                                    )
                                }}
                            </div>
                            <div class="col-12 text-muted mb-1"><small>Last Sync Times</small></div>
                            <div class="col-3 mb-1">Full</div>
                            <div class="col-9 mb-1">
                                {{
                                    account.xero_organisation.last_sync
                                        ? DateTime.fromISO(account.xero_organisation.last_sync).toLocaleString(
                                              DateTime.DATETIME_FULL
                                          )
                                        : 'Never'
                                }}
                            </div>
                            <div class="col-3 mb-1">Accounts</div>
                            <div class="col-9 mb-1">
                                {{
                                    account.xero_organisation.last_account_sync
                                        ? DateTime.fromISO(account.xero_organisation.last_account_sync).toLocaleString(
                                              DateTime.DATETIME_FULL
                                          )
                                        : 'Never'
                                }}
                            </div>
                            <div class="col-3 mb-1">Contacts</div>
                            <div class="col-9 mb-1">
                                {{
                                    account.xero_organisation.last_contact_sync
                                        ? DateTime.fromISO(account.xero_organisation.last_contact_sync).toLocaleString(
                                              DateTime.DATETIME_FULL
                                          )
                                        : 'Never'
                                }}
                            </div>
                            <div class="col-3 mb-1">Tax Rates</div>
                            <div class="col-9 mb-1">
                                {{
                                    account.xero_organisation.last_tax_rate_sync
                                        ? DateTime.fromISO(account.xero_organisation.last_tax_rate_sync).toLocaleString(
                                              DateTime.DATETIME_FULL
                                          )
                                        : 'Never'
                                }}
                            </div>
                            <div class="col-3">Pooled Accs</div>
                            <div class="col-9">
                                {{
                                    account.xero_organisation.last_pooled_account_sync
                                        ? DateTime.fromISO(
                                              account.xero_organisation.last_pooled_account_sync
                                          ).toLocaleString(DateTime.DATETIME_FULL)
                                        : 'Never'
                                }}
                            </div>
                        </div>
                        <Banner
                            v-if="account.xero_organisation.reconnection_required"
                            type="is-warning"
                            class="tw-mt-3"
                        >
                            A reconnection to Xero is required for this account to continue functioning
                        </Banner>
                    </template>
                    <div v-else class="empty-label px-3">
                        {{ account.name }} has not connected Xero to their account
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import Dropdown from '../../controls/Dropdown.vue';
import EditableText from '../../controls/EditableText.vue';
import Audits from './Tables/Audits.vue';
import Addresses from './Tables/Addresses.vue';
import Contacts from './Tables/Contacts.vue';
import Departments from './Tables/Departments.vue';
import Events from './Tables/Events.vue';
import Team from './Tables/Team.vue';
import Usergroups from './Tables/Usergroups.vue';
import LoadingSpinner from '../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Submenu from '@/js/components/controls/Submenu.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { timeAgo, toast } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        Dropdown,
        EditableText,
        Audits,
        Addresses,
        Contacts,
        Departments,
        Events,
        Team,
        Usergroups,
        LoadingSpinner,
        Button,
        Submenu,
        Tooltip,
        Checkbox,
        Banner,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    emits: ['back', 'title'],
    data() {
        return {
            isLoading: true,
            account: null,
            activePage: null,
            isSyncingXero: false,
            DateTime,
        };
    },
    computed: {
        integrationCount() {
            if (!this.account) {
                return 0;
            }
            return this.account.xero_organisation ? 1 : 0;
        },
    },
    watch: {
        id(newVal) {
            this.reloadData(newVal);
        },
    },
    mounted() {
        this.reloadData(this.id);
    },
    methods: {
        timeAgo,
        reloadData(id = null) {
            // in case the account is deleted, get it from a dedicated route for possibly trashed accounts
            this.loading = true;
            axios
                .get(
                    route('api.admin.trashed-account', {
                        account_id: id ? id : this.account.id,
                    })
                )
                .then(({ data }) => {
                    this.account = data;
                    this.account.xero_organisation = {};
                    this.isLoading = false;
                    this.$emit('title', data.name);
                });
        },
        saveChanges: debounce(function () {
            if (!this.account) {
                return;
            }
            axios
                .post(
                    route('api.admin.account.update', {
                        account: this.account.id,
                    }),
                    this.account
                )
                .then(({ data }) => {
                    this.$emit('title', data.name);
                    toast('Changes Saved', 'Your changes to ' + data.name + ' have been saved', 'success');
                });
        }, 500),
        triggerFullXeroSync() {
            this.isSyncingXero = true;
            axios
                .get(
                    route('api.admin.trigger.xero.sync', {
                        xeroOrganisation: this.account.xero_organisation.id,
                    })
                )
                .then(({ data }) => {
                    toast(
                        'Xero Sync Successful',
                        'Successfully synced ' + data.accounts + ' accounts and ' + data.contacts + ' contacts',
                        'success'
                    );
                })
                .finally(() => {
                    this.isSyncingXero = false;
                });
        },
        triggerXeroAccountSync() {
            this.isSyncingXero = true;
            axios
                .get(
                    route('api.admin.trigger.xero.sync.accounts', {
                        xeroOrganisation: this.account.xero_organisation.id,
                    })
                )
                .then(({ data }) => {
                    toast('Xero Sync Successful', 'Successfully synced ' + data.accounts + ' accounts', 'success');
                })
                .finally(() => {
                    this.isSyncingXero = false;
                });
        },
        triggerXeroContactSync() {
            this.isSyncingXero = true;
            axios
                .get(
                    route('api.admin.trigger.xero.sync.contacts', {
                        xeroOrganisation: this.account.xero_organisation.id,
                    })
                )
                .then(({ data }) => {
                    toast('Xero Sync Successful', 'Successfully synced ' + data.contacts + ' contacts', 'success');
                })
                .finally(() => {
                    this.isSyncingXero = false;
                });
        },
        deleteAccount() {
            if (!this.account) {
                return;
            }
            swal.fire({
                title: 'Warning',
                html:
                    'Are you sure that would like to delete ' +
                    this.account.name +
                    '?<br /><br />This will remove the account permanently and users will no longer be able to access it.',
                showCancelButton: true,
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    axios
                        .delete(
                            route('api.admin.account.delete', {
                                account: this.account.id,
                            })
                        )
                        .then(() => {
                            toast('Account Deleted', this.account.name + ' has been deleted successfully', 'success');
                            this.$emit('back');
                        });
                }
            });
        },
        restoreAccount() {
            if (!this.account) {
                return;
            }
            swal.fire({
                title: 'Warning',
                html:
                    'Are you sure that would like to recover ' +
                    this.account.name +
                    '?<br /><br />This will make the account accessible and users will have access to it.',
                showCancelButton: true,
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    axios
                        .post(
                            route('api.admin.account.restore', {
                                account: this.account.id,
                            })
                        )
                        .then(() => {
                            toast('Account Recovered', this.account.name + ' has been recovered', 'success');
                            this.$emit('back');
                        });
                }
            });
        },
        shadowAccount() {
            if (!this.account) {
                return;
            }
            const url = route('admin.switch', {
                accountToSwitchTo: this.account.id,
            });
            window.open(url, '_blank');
        },
    },
};
</script>
<style lang="scss">
.admin-section-menu {
    & > .menu {
        padding: 8px;
        border-radius: 4px;
        background: white;
    }
}
.admin-section-content {
    padding: 20px;
    background: white;
    border-radius: 4px;
}
.settings-field {
    width: 100%;
    display: flex;
    align-items: center;

    &.top {
        align-items: flex-start;
    }

    & > label {
        min-width: 120px;
        margin-right: 15px;
        margin-bottom: 0;
    }
}
</style>
