<template>
    <div class="d-flex department-editor tw-py-4">
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-else class="editor-departments">
            <p class="h4">Revenue Streams</p>
            <ItemList class="tw-my-2" :items="departmentTypes">
                <template #default="{ item: department }">
                    <div
                        class="tw-flex tw-items-center tw-border tw-px-2 tw-py-1 tw-rounded tw-cursor-pointer tw-text-gray-400 hover:tw-bg-gray-100"
                        :class="{
                            '!tw-bg-eventwise !tw-text-[rgba(255,255,255,.8)] tw-border-eventwise-600':
                                selectedDepartment && selectedDepartment.id === department.id,
                        }"
                        @click="selectDepartment(department)"
                    >
                        <div class="tw-w-[38px] tw-pl-1">
                            <Icon v-if="department.icon" class="tw-text-black !tw-text-xl" :icon="department.icon" />
                        </div>
                        <div>
                            <div class="tw-text-black">{{ department.name }}</div>
                            <div class="d-flex">
                                <p v-if="department.events.length > 1" class="tw-mb-0">
                                    {{ department.events.length }}
                                    events
                                </p>
                                <p v-else-if="department.events.length" class="tw-mb-0">1 event</p>
                                <p v-else class="tw-mb-0 tw-text-gray-300">No events</p>
                                <p class="tw-mb-0 tw-text-gray-300 tw-px-2">&bull;</p>
                                <p v-if="department.categories_count > 1" class="tw-mb-0">
                                    {{ department.categories_count }}
                                    subcategories
                                </p>
                                <p v-else-if="department.categories_count" class="tw-mb-0">1 subcategory</p>
                                <p v-else class="tw-mb-0 tw-text-gray-300">No subcategories</p>
                            </div>
                        </div>
                    </div>
                </template>
            </ItemList>
            <Button
                v-if="Auth.can('manage departments')"
                type="is-primary"
                class="tw-flex tw-w-full tw-justify-center"
                @click="createDepartment('revenue')"
            >
                Create Revenue Stream
            </Button>
            <p class="h4 !tw-mt-4">Cost Departments</p>
            <ItemList class="tw-my-2" :items="revenueTypes">
                <template #default="{ item: department }">
                    <div
                        class="tw-flex tw-items-center tw-border tw-px-2 tw-py-1 tw-rounded tw-cursor-pointer tw-text-gray-400 hover:tw-bg-gray-100"
                        :class="{
                            '!tw-bg-eventwise !tw-text-[rgba(255,255,255,.8)] tw-border-eventwise-600':
                                selectedDepartment && selectedDepartment.id === department.id,
                        }"
                        @click="selectDepartment(department)"
                    >
                        <div class="tw-w-[38px] tw-pl-1">
                            <Icon v-if="department.icon" class="tw-text-black !tw-text-xl" :icon="department.icon" />
                        </div>
                        <div>
                            <div class="tw-text-black">{{ department.name }}</div>
                            <div class="d-flex">
                                <p v-if="department.events.length > 1" class="tw-mb-0">
                                    {{ department.events.length }}
                                    events
                                </p>
                                <p v-else-if="department.events.length" class="tw-mb-0">1 event</p>
                                <p v-else class="tw-mb-0 tw-text-gray-300">No events</p>
                                <p class="tw-mb-0 tw-text-gray-300 tw-px-2">&bull;</p>
                                <p v-if="department.categories_count > 1" class="tw-mb-0">
                                    {{ department.categories_count }}
                                    subcategories
                                </p>
                                <p v-else-if="department.categories_count" class="tw-mb-0">1 subcategory</p>
                                <p v-else class="tw-mb-0 tw-text-gray-300">No subcategories</p>
                            </div>
                        </div>
                    </div>
                </template>
            </ItemList>
            <Button
                v-if="Auth.can('manage departments')"
                type="is-primary"
                class="tw-flex tw-gap-3 tw-w-full tw-justify-center"
                @click="createDepartment('cost')"
            >
                Create Cost Department
            </Button>
        </div>

        <!-- Selected Department Card -->
        <div v-if="selectedDepartment" class="editor-details">
            <div class="d-flex py-4 px-5 align-items-center">
                <div>
                    <h3>{{ selectedDepartment.name }}</h3>
                    <p>
                        {{ selectedDepartment.description }}
                    </p>
                </div>
            </div>
            <Tabs
                :key="selectedDepartment.id"
                :tabs="
                    [
                        { title: selectedDepartment.type === 2 ? 'Revenue Stream' : 'Department', name: 'info' },
                        { title: 'Subcategories', name: 'subcategories' },
                        selectedDepartment.revenue_stream_type === null ? { title: 'Markups', name: 'markups' } : null,
                    ].filter((t) => t)
                "
            >
                <template #tab(info)>
                    <div class="p-3">
                        <h4 class="mb-1">General Details</h4>
                        <div class="py-3">
                            <div class="tw-flex">
                                <FormField class="tw-flex-1 pr-3 mb-0" label="Name">
                                    <Textbox
                                        v-model="selectedDepartment.name"
                                        maxlength="255"
                                        :disabled="!Auth.can('manage departments')"
                                    />
                                </FormField>
                                <FormField label="Icon">
                                    <control-icon
                                        v-model="selectedDepartment.icon"
                                        :disabled="!Auth.can('manage departments')"
                                    />
                                </FormField>
                            </div>
                            <FormField class="tw-mb-2" label="Description">
                                <Textbox
                                    v-model="selectedDepartment.description"
                                    maxlength="255"
                                    rows="2"
                                    type="textarea"
                                    :disabled="!Auth.can('manage departments')"
                                />
                            </FormField>
                            <div class="tw-flex">
                                <FormField class="tw-flex-1" label="Default Tax Rate">
                                    <TaxRateSelector
                                        :key="selectedDepartment.id"
                                        v-model="selectedDepartment.default_tax_rate_id"
                                        :type="selectedDepartment.type === 2 ? 'income' : 'expense'"
                                    />
                                </FormField>

                                <control-stream-type
                                    v-if="
                                        selectedDepartment.type === 2 && !isNaN(selectedDepartment.revenue_stream_type)
                                    "
                                    v-model="selectedDepartment.revenue_stream_type"
                                    label="Configure this Revenue Stream for"
                                    :disabled="
                                        !Auth.can('manage departments') || selectedDepartment.revenue_stream_type === 0
                                    "
                                    :disable-tickets="selectedDepartment.revenue_stream_type !== 0"
                                    class="ml-3"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedDepartment" class="d-flex mt-2 mx-2">
                        <Tooltip
                            position="right"
                            :label="
                                'You may only delete a ' +
                                (selectedDepartment.type === 2 ? 'Revenue Stream' : 'Department') +
                                ' when it has no linked events'
                            "
                        >
                            <Button
                                :disabled="!canDeleteDepartment || !Auth.can('delete departments')"
                                type="is-danger"
                                @click="deleteDepartment(selectedDepartment)"
                            >
                                Delete {{ selectedDepartment.type === 2 ? 'Revenue Stream' : 'Department' }}
                            </Button>
                        </Tooltip>
                        <Button
                            v-if="Auth.can('manage departments')"
                            class="ml-auto"
                            type="is-success"
                            @click="saveDepartment()"
                        >
                            Save Changes
                        </Button>
                    </div>
                </template>
                <template #tab(subcategories)>
                    <subcategory-editor
                        v-model="selectedDepartment.categories"
                        :xero-accounts="xeroAccounts"
                        :estimates="false"
                        :poolable="
                            selectedDepartment.type === 1 ||
                            (selectedDepartment.type === 2 && selectedDepartment.revenue_stream_type !== 0)
                        "
                        :enable-delete-existing="true"
                        :confirm-delete="true"
                        @edit="openSubcategory"
                    />
                    <div class="d-flex mt-4 mx-2">
                        <Button type="is-primary" @click="createSubcategory(selectedDepartment.id)">
                            Create Subcategory
                        </Button>
                        <Button
                            v-if="selectedDepartment.deleted_categories_count"
                            class="ml-1"
                            @click="$refs.restoreSubcategoriesModal.getDeletedCategories(selectedDepartment.id)"
                        >
                            Restore ({{ selectedDepartment.deleted_categories_count }})
                        </Button>
                        <Button class="ml-auto" type="is-success" @click="saveDepartment()">Save Changes</Button>
                    </div>
                </template>
                <template #tab(markups)>
                    <DataTable
                        :data="Object.values(store.get(`account.departments.${selectedDepartment.id}.categories`))"
                        :columns="[
                            {
                                title: 'Name',
                                field: 'name',
                            },
                            {
                                title: 'Default Markup Destination',
                                field: 'default_markup_destination',
                            },
                            {
                                title: 'Default Markup Amount',
                                field: 'default_markup_amount',
                            },
                        ]"
                    >
                        <template #column(default_markup_destination)="{ row }">
                            <Select v-model="row.default_markup_destination">
                                <option :value="null">None</option>
                                <optgroup
                                    v-for="(department, index) in Object.keys(availableMarkupCategories)"
                                    :key="department + index"
                                    :label="department"
                                >
                                    <option
                                        v-for="category in availableMarkupCategories[department]"
                                        :key="category.id"
                                        :value="category.id"
                                    >
                                        {{ category.name }}
                                    </option>
                                </optgroup>
                            </Select>
                        </template>
                        <template #column(default_markup_amount)="{ row }">
                            <PercentageValue
                                :key="selectedDepartment.id + '_' + row.id"
                                v-model="row.default_markup_amount"
                                :loading="isLoading"
                                force-percentage
                            />
                        </template>
                    </DataTable>
                    <div class="tw-flex tw-mt-4 tw-mx-2">
                        <Button class="ml-auto" type="is-success" @click="saveMarkups()">Save Changes</Button>
                    </div>
                </template>
            </Tabs>
            <ModalRestoreSubcategories
                ref="restoreSubcategoriesModal"
                @restored="selectDepartment(selectedDepartment)"
            />
        </div>
    </div>
</template>
<script>
import SubcategoryEditor from '../utility/subcategory-editor.vue';
import ModalRestoreSubcategories from '../../../../../js/components/modals/Account/RestoreSubcategories.vue';
import TaxRateSelector from '../../../../../js/components/controls/TaxRateSelector.vue';
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import Icon from '../../../../../js/components/Icon.vue';
import Button from '../../../../../js/components/controls/Button.vue';
import ItemList from '@/js/components/ItemList.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Select from '@/js/components/controls/Select.vue';
import PercentageValue from '@/js/components/widgets/PercentageValue.vue';
import { useDataStore } from '@/js/stores/DataStore.js';

export default {
    components: {
        TaxRateSelector,
        SubcategoryEditor,
        ModalRestoreSubcategories,
        LoadingSpinner,
        Icon,
        Button,
        ItemList,
        Tabs,
        FormField,
        Textbox,
        Tooltip,
        DataTable,
        Select,
        PercentageValue,
    },
    data() {
        return {
            isLoading: true,
            departments: [],
            xeroAccounts: null,
            selectedDepartment: null,
            categoryTabKey: 0,
            store: useDataStore(),
        };
    },
    computed: {
        canDeleteDepartment() {
            if (!this.selectedDepartment) {
                return false;
            }
            return !this.selectedDepartment.events_count;
        },
        departmentTypes() {
            return this.departments.filter((department) => department.type === 2);
        },
        revenueTypes() {
            return this.departments.filter((department) => department.type === 1);
        },
        availableMarkupCategories() {
            const categories = {};
            Object.values(this.store.get('account.departments')).forEach((department) => {
                if (department.type !== 2 || department.is_tickets) {
                    return;
                }
                categories[department.name] = department.categories;
            });
            return categories;
        },
    },
    mounted() {
        this.getDepartments();
        if (Auth?.account?.is_connected_to_xero) {
            this.getXeroAccounts();
        }

        Eventbus.$on('reload:category-list', () => {
            if (this.selectedDepartment) {
                this.selectDepartment(this.selectedDepartment);
            }
            this.getDepartments();
        });
        Eventbus.$on('reload:departments', () => {
            this.getDepartments();
        });
    },
    methods: {
        getDepartments() {
            this.isLoading = true;
            axios
                .get(route('api.account.departments'))
                .then((response) => {
                    this.departments = response.data;
                })
                .finally(() => (this.isLoading = false));
        },
        getXeroAccounts() {
            axios.get(route('api.account.xero.accounts')).then(({ data }) => {
                this.xeroAccounts = data;
            });
        },
        selectDepartment(department) {
            this.isLoading = true;
            const urlStr = route('api.account.departments.get', { department: department.id });
            axios
                .get(urlStr)
                .then((response) => {
                    this.selectedDepartment = response.data;
                })
                .finally(() => (this.isLoading = false));
        },
        createSubcategory(departmentId) {
            Eventbus.$emit('subcategory:open', {
                department: departmentId,
            });
        },
        openSubcategory(categoryId) {
            Eventbus.$emit('subcategory:open', {
                department: this.selectedDepartment.id,
                category: categoryId,
            });
        },
        createDepartment(payload) {
            Eventbus.$emit('create:department', {
                type: payload,
            });
        },
        saveDepartment() {
            if (!this.selectedDepartment) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.departments.update', {
                        department: this.selectedDepartment.id,
                    }),
                    this.selectedDepartment
                )
                .then(({ data }) => (this.selectedDepartment = data))
                .finally(() => (this.isLoading = false));
        },
        saveMarkups() {
            if (!this.selectedDepartment) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.departments.updateMarkups', {
                        department: this.selectedDepartment.id,
                    }),
                    {
                        categories: Object.values(
                            this.store.get(`account.departments.${this.selectedDepartment.id}.categories`)
                        ),
                    }
                )
                .finally(() => (this.isLoading = false));
        },
        deleteDepartment(department) {
            if (!department || !department.id) {
                return;
            }
            this.isLoading = true;
            axios
                .delete(
                    route('api.account.departments.delete', {
                        department: department.id,
                    })
                )
                .then(() => {
                    if (department.id === this.selectedDepartment.id) {
                        this.selectedDepartment = null;
                    }
                    this.getDepartments();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
