<template>
    <div class="tw-py-5 tw-w-full tw-text-center tw-text-gray-400">
        <p v-if="position === 'top'">{{ label }}</p>
        <div class="d-flex justify-content-center">
            <p v-if="position === 'left'" class="isleft">{{ label }}</p>
            <div class="spinner-border mr-2" role="status" style="color: gray"></div>
            <p v-if="position === 'right'" class="isright">{{ label }}</p>
        </div>
        <p v-if="position === 'bottom'">{{ label }}</p>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Loading Information',
        },
        position: {
            type: String,
            default: 'bottom',
        },
    },
};
</script>
<style lang="scss" scoped>
.loader {
    .spinner-border {
        height: 40px;
        width: 40px;
        border-width: 1px;
    }
    .isleft {
        margin-right: 20px;
    }
    .isright {
        margin-left: 20px;
    }
}
.loader-fade-enter-active {
    animation: loaderFade 0.2s;
}
.loader-fade-leave-active {
    animation: loaderFade 0.2s reverse;
}
@keyframes loaderFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
