<template>
    <div
        class="textbox-container tw-flex tw-relative tw-text-gray-400 hover:tw-text-gray-500 hover:tw-border-gray-400 focus:tw-text-gray-400 focus-within:tw-border-gray-400 focus-within:tw-text-gray-600"
        :class="{
            'tw-w-full': expanded,
        }"
    >
        <template v-if="!disabled && (!toggle || isEditing)">
            <slot name="before"></slot>
            <input
                v-if="type !== 'textarea'"
                ref="input"
                v-model="inputValue"
                class="tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400 tw-text-black"
                :class="[
                    inputClass,
                    {
                        'is-compact': compact,
                        'tw-w-full': expanded,
                        'tw-pt-[3px] tw-py-[5px] tw-pl-[2px]': compact,
                        '!tw-px-3 !tw-pl-[38px] !tw-text-base !tw-rounded-[4px]': large,
                        '!tw-pl-8': icon,
                        '!tw-border-red-400': errorText,
                        '!tw-border-emerald-400': successText,
                    },
                ]"
                :type="type === 'currency' ? 'number' : type"
                :maxlength="maxlength"
                :placeholder="placeholder"
                :required="required"
                :step="step"
                :name="name"
                @keypress="handleKeyPress"
            />
            <textarea
                v-else-if="type === 'textarea'"
                ref="input"
                v-model="inputValue"
                class="tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400 tw-text-black"
                :class="{
                    'is-compact': compact,
                    'tw-w-full': expanded,
                    'tw-pt-[3px] tw-py-[5px] tw-pl-[2px]': compact,
                    'tw-p-4 !tw-pl-10 !tw-text-base !tw-rounded-[4px]': large,
                    '!tw-pl-8': icon,
                }"
                :rows="rows"
                :maxlength="maxlength"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                @keypress="handleKeyPress"
            ></textarea>
            <i
                v-if="icon"
                :class="[
                    `mdi mdi-${icon} tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-left-2 tw-text-lg`,
                    {
                        '!tw-text-2xl': large,
                    },
                ]"
            ></i>
            <slot name="after"></slot>
        </template>
        <Button
            v-else
            :disabled="disabled"
            :class="{
                'is-compact': compact,
            }"
            class="tw-text-eventwise-link tw-my-1"
            @click="toggleEdit()"
        >
            {{ inputValue !== '' && inputValue !== null ? formattedInputValue : 'Click to set' }}
            <i v-if="!inputValue" class="mdi mdi-pencil ml-1"></i>
        </Button>
    </div>
</template>
<script>
import { localise } from '@/js/utils.js';
import Button from '@/js/components/controls/Button.vue';
export default {
    components: {
        Button,
    },
    inject: {
        error: {
            default: '',
        },
        success: {
            default: '',
        },
    },
    props: {
        maxlength: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        toggle: {
            type: Boolean,
            required: false,
            default: false,
        },
        modelValue: {
            type: [String, Number],
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: false,
        },
        step: {
            type: String,
            required: false,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            required: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: false,
        },
        rows: {
            type: String,
            required: false,
        },
        inputClass: {
            type: String,
            required: false,
        },
    },
    emits: ['update:modelValue', 'updated', 'toggled'],
    data() {
        return {
            isEditing: false,
            inputValue: this.modelValue,
        };
    },
    computed: {
        formattedInputValue() {
            if (this.type === 'number') {
                const decimalPlaces = this.step ? (this.step.split('.')[1]?.length ?? 0) : 0;
                return localise(this.inputValue ? this.inputValue : 0, decimalPlaces, false);
            } else if (this.type === 'currency') {
                const decimalPlaces = this.step ? (this.step.split('.')[1]?.length ?? 0) : 2;
                return localise(this.inputValue ? this.inputValue : 0, decimalPlaces, true);
            }
            return this.inputValue;
        },
        // The injected variable is not reactive by default so therefore must be called as a function
        errorText() {
            if (!this.error) {
                return '';
            }
            return this.error();
        },
        successText() {
            if (!this.success) {
                return '';
            }
            return this.success();
        },
    },
    watch: {
        modelValue(newVal) {
            this.inputValue = newVal;
        },
        inputValue(newVal) {
            if (!this.toggle) {
                this.$emit('update:modelValue', newVal);
            }
        },
        isEditing(newVal) {
            if (!newVal) {
                this.$emit('update:modelValue', this.inputValue);
            }
        },
    },
    methods: {
        handleKeyPress(e) {
            if (this.toggle && this.isEditing && e.code === 'Enter') {
                if (this.type === 'textarea') {
                    if (e.ctrlKey) {
                        this.toggleEdit();
                    }
                    return;
                }
                this.toggleEdit();
            }
        },
        toggleEdit() {
            if (this.disabled) {
                return;
            }
            this.isEditing = !this.isEditing;
            this.$emit('toggled', this.isEditing);
            if (this.isEditing) {
                setTimeout(() => {
                    this.focus();
                }, 50);
            } else {
                this.$emit('update:modelValue', this.inputValue);
            }
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';
.textbox-container {
    display: flex;

    .input {
        &.is-compact {
            padding: 3px 5px 2px 5px;
            height: auto;
            width: auto;
        }
    }
}
</style>
