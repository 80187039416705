<template>
    <Drawer :ref="drawerId" :title="'Recharge ' + budgetEntry.name" size="is-large" @closed="drawerClosed()">
        <div class="drawer-row pb-2 border-bottom">
            <div class="fill">
                <div v-if="eventDropdownShow" class="row">
                    <div class="col-12">
                        <div v-if="isLoadingEventData" class="preloader text-center">
                            <LoadingSpinner label="Please wait whilst we load your data" />
                        </div>
                        <div v-else>
                            <label for="event-dropdown">Event</label>
                            <select
                                id="event-dropdown"
                                class="form-control"
                                name="event-dropdown"
                                required
                                @change="(v) => changeEvent(v)"
                            >
                                <option disabled selected>--- Please Select ---</option>
                                <option v-for="event in eventDropdownData" :key="event.id" :value="event.id">
                                    {{ event.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        You are currently recharging within the same event. Click
                        <!-- prettier-ignore -->
                        <span style="cursor: pointer; text-decoration: underline" @click="eventSelection()">here</span>
                        to recharge to another event.
                    </div>
                </div>
                <div v-if="!isWaitingForEvent" class="row pt-5">
                    <div class="col-12">
                        <div v-if="!isLoaded" class="preloader text-center">
                            <LoadingSpinner label="Please wait whilst we load your data" />
                        </div>
                        <div v-else-if="departmentList.length > 0" class="form-group">
                            <label for="entry-department">Department</label>
                            <select
                                id="entry-department"
                                v-model="departmentSelectedId"
                                class="form-control"
                                name="department_id"
                                required
                                @change="fetchCategories()"
                            >
                                <option disabled selected>--- Please Select ---</option>
                                <option
                                    v-for="department in departmentList"
                                    :key="department.id"
                                    :value="department.id"
                                >
                                    {{ department.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!isWaitingForEvent"
                    id="rowCategorySelectLoading"
                    class="preloader text-center pt-5"
                    style="display: none"
                >
                    <LoadingSpinner label="Please wait whilst we load your data" />
                </div>
                <div v-if="!isWaitingForEvent" id="rowCategorySelect" class="row">
                    <div class="col-12">
                        <div v-if="categoryList.length > 0" class="form-group">
                            <label for="entry-category">Subcategory</label>
                            <select
                                id="entry-category"
                                v-model="categorySelectedId"
                                class="form-control"
                                name="category_id"
                                required
                            >
                                <option disabled selected>--- Please Select ---</option>
                                <option v-for="category in categoryList" :key="category.id" :value="category.id">
                                    {{ category.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isWaitingForEvent && categorySelectedId" class="display-area fill pt-5">
            <div v-if="categorySelectedId" class="pl-4 pr-4">
                <div class="row">
                    <div class="col-6">
                        <label for="totalAmountFixed">Total Fixed Cost</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    {{ eventCurrencySymbol() }}
                                </span>
                            </div>
                            <input
                                type="text"
                                name="totalAmountFixed"
                                class="form-control"
                                style="background-color: rgb(255, 255, 255)"
                                :model-value="monetary(budgetEntry.fixed_cost)"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="totalAmountBudgeted">Total Budgeted Cost</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    {{ eventCurrencySymbol() }}
                                </span>
                            </div>
                            <input
                                type="text"
                                name="totalAmountBudgeted"
                                class="form-control"
                                style="background-color: rgb(255, 255, 255)"
                                :model-value="monetary(budgetEntry.budgeted_cost)"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-6">
                        <label for="rechargeAmountFixed">Fixed Amount to Recharge</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    {{ eventCurrencySymbol() }}
                                </span>
                            </div>
                            <input
                                id="rechargeAmountFixed"
                                v-model="fixedToRecharge"
                                type="number"
                                name="rechargeAmountFixed"
                                class="form-control"
                                value="0"
                                min="0"
                                step="0.01"
                                required
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="rechargeAmountBudgeted">Budgeted Amount to Recharge</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    {{ eventCurrencySymbol() }}
                                </span>
                            </div>
                            <input
                                id="rechargeAmountBudgeted"
                                v-model="budgetedToRecharge"
                                type="number"
                                name="rechargeAmountBudgeted"
                                class="form-control"
                                value="0"
                                min="0"
                                step="0.01"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="fill">
                <div class="row">
                    <div class="col-6">
                        <Button @click="invokeClose()">Cancel</Button>
                    </div>
                    <div class="col-6 text-right">
                        <Button
                            type="is-success"
                            :loading="isLoading"
                            :disabled="
                                !categorySelectedId ||
                                !eventDropdownSelectedId ||
                                (fixedToRecharge <= 0 && budgetedToRecharge <= 0)
                            "
                            @click="invokeSave()"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </template>
    </Drawer>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import Drawer from '../../../../../js/components/Drawer.vue';
import { eventCurrencySymbol, monetary, toast } from '@/js/utils.js';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: {
        Drawer,
        LoadingSpinner,
        Button,
    },
    props: {
        drawerId: {
            type: String,
            default: 'rechargeDrawer',
        },
    },
    data() {
        const store = useDataStore();
        return {
            budgetEntry: {},
            categoryList: [],
            categorySelectedId: null,
            departmentList: [],
            departmentSelectedId: null,
            eventDropdownData: [],
            eventDropdownSelectedId: null,
            eventDropdownShow: false,
            isEdited: false,
            isLoaded: false,
            isLoading: false,
            isLoadingEventData: false,
            isWaitingForEvent: false,
            type: '',
            store: store,
            fixedToRecharge: 0,
            budgetedToRecharge: 0,
        };
    },
    mounted() {
        Eventbus.$on(this.drawerId + ':open', (payload) => {
            this.budgetEntry = payload['budgetEntry'];
            this.type = payload['type'];
            this.eventDropdownSelectedId = this.budgetEntry.event_id;
            this.fetchDepartments();
            this.$refs[this.drawerId].open();
        });
    },
    methods: {
        eventCurrencySymbol,
        monetary,
        changeEvent(eventId) {
            this.eventDropdownSelectedId = eventId;
            this.isWaitingForEvent = false;
            this.isLoaded = false;
            this.departmentList = [];
            this.departmentSelectedId = null;
            this.categoryList = [];
            this.categorySelectedId = null;
            this.fetchDepartments();
        },
        drawerClosed() {
            this.resetData();
        },
        eventSelection() {
            if (this.eventDropdownShow) {
                return;
            }
            this.isWaitingForEvent = true;
            this.isLoaded = false;
            this.departmentList = [];
            this.departmentSelectedId = null;
            this.categoryList = [];
            this.categorySelectedId = null;
            this.eventDropdownSelectedId = null;
            this.eventDropdownShow = true;
            this.isLoadingEventData = true;
            axios.get(route('api.account.event.allevents')).then(({ data }) => {
                this.eventDropdownData = data;
                this.isLoadingEventData = false;
            });
        },
        fetchCategories() {
            this.categorySelectedId = null;
            this.categoryList = Object.values(
                this.store.get('account.departments.' + this.departmentSelectedId + '.categories')
            );
        },
        fetchDepartments() {
            // Departments are stored in the Global store
            this.departmentList = [];
            const depts = Object.values(this.store.get('account.departments'));
            this.departmentList = depts.filter((it) => {
                return it.type === (this.type === 'revenueStream' ? 2 : 1) && it.is_tickets === false;
            });
            setTimeout(() => (this.isLoaded = true), 150);
        },
        invokeClose() {
            this.$refs[this.drawerId].close();
        },
        invokeSave() {
            if (
                isNaN(this.budgetedToRecharge) ||
                isNaN(this.fixedToRecharge) ||
                this.budgetedToRecharge < 0.01 ||
                this.fixedToRecharge < 0.01
            ) {
                toast('Error', 'Please enter valid fixed and budgeted amounts.', 'error');
                return;
            }
            if (
                this.budgetedToRecharge > this.budgetEntry.budgeted_cost ||
                this.fixedToRecharge > this.budgetEntry.fixed_cost
            ) {
                toast('Error', 'You cannot recharge more than the fixed or budgeted amounts.', 'error');
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.entries.recharge', {
                        entry: this.budgetEntry.id,
                    }),
                    {
                        amount_budgeted: this.budgetedToRecharge,
                        amount_fixed: this.fixedToRecharge,
                        event_id: this.eventDropdownSelectedId,
                        target_category_id: this.categorySelectedId,
                        target_department_id: this.departmentSelectedId,
                    }
                )
                .then(() => this.invokeClose())
                .finally(() => (this.isLoading = false));
        },
        resetData() {
            this.budgetEntry = {};
            this.categoryList = [];
            this.categorySelectedId = null;
            this.departmentList = [];
            this.departmentSelectedId = null;
            this.eventDropdownData = [];
            this.eventDropdownSelectedId = null;
            this.eventDropdownShow = false;
            this.isEdited = false;
            this.isLoaded = false;
            this.isLoading = false;
            this.isLoadingEventData = false;
            this.isOpen = false;
            this.isWaitingForEvent = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.drawer-row {
    display: flex;
    padding: 15px 25px;
    .fill {
        flex-grow: 1;
    }
    .toggle-icon {
        margin: 0 !important;
    }
}

.drawer-column {
    display: flex;
    flex-direction: column;
    .fill {
        flex-grow: 1;
    }
}

.display-area {
    user-select: none !important;
    background: rgba(black, 0.025);
    padding: 25px 0;
    .ticket-group {
        padding: 15px;
        border: 2px dashed rgba(black, 0.1);
        border-radius: 10px;
        box-sizing: border-box;
        margin: 5px 25px;
        &.with-title {
            padding: 10px 25px 25px 25px;
        }
        .ticket-group-title {
            display: block;
            color: rgba(black, 0.4);
            font-size: 125%;
        }
    }
}

.drawer-row.editor {
    padding: 0;
    max-height: 0px;
    min-height: 0px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    &.open {
        min-height: 33%;
        max-height: 9999px;
        transition: all 0.5s ease-in-out;
    }
    .editor-actions {
        max-width: 25%;
        padding-top: 15px;
        button {
            margin-bottom: 5px;
        }
    }
}
</style>
