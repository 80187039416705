<template>
    <Modal ref="modal" title="Change Password" size="is-xlarge" @closed="reset()">
        <FormField class="tw-mb-3" label="Current Password">
            <Textbox
                v-model="currentPassword"
                type="password"
                required
                placeholder="Current Password"
                :disabled="isLoading"
            />
        </FormField>
        <FormField class="tw-mb-3" label="New Password">
            <Textbox v-model="newPassword" type="password" required placeholder="New Password" :disabled="isLoading" />
        </FormField>
        <FormField class="tw-mb-3" label="Confirm New Password">
            <Textbox
                v-model="newPasswordConfirmation"
                type="password"
                required
                placeholder="Confirm New Password"
                :disabled="isLoading"
            />
        </FormField>
        <template #footer>
            <Button type="is-success" :disabled="!isValid" :loading="isLoading" @click="changePassword()">
                Change Password
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
    },
    data() {
        return {
            isLoading: false,
            currentPassword: null,
            newPassword: null,
            newPasswordConfirmation: null,
        };
    },
    computed: {
        isValid() {
            return !!this.currentPassword && !!this.newPassword && this.newPassword === this.newPasswordConfirmation;
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.currentPassword = null;
            this.newPassword = null;
            this.newPasswordConfirmation = null;
            this.isLoading = false;
        },
        changePassword() {
            this.isLoading = true;
            axios
                .post(route('api.user.updatePassword'), {
                    current_password: this.currentPassword,
                    new_password: this.newPassword,
                    new_password_confirmation: this.newPasswordConfirmation,
                })
                .then(() => {
                    this.close();
                    toast(
                        'Password Changed',
                        'Your password has been updated successfully, any active sessions on other devices will be required to login again',
                        'success'
                    );
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
