<template>
    <FormField :label="label">
        <Button
            v-if="!editing"
            :disabled="disabled"
            type="is-link"
            class="!tw-p-0"
            :loading="loading"
            @click="editing = true"
        >
            {{ selectedAddress ? selectedAddress.name : 'Add' }}
        </Button>
        <Select v-else v-model="model" :disabled="disabled" placeholder="Select an address">
            <option v-for="address in addresses" :key="address.id" :value="address.id">
                {{ address.name }}, {{ address.address }}
            </option>
        </Select>
        <slot></slot>
    </FormField>
</template>
<script setup>
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import { ref, onMounted, computed } from 'vue';

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        required: true,
    },
});
const model = defineModel({
    type: [Number],
});
const loading = ref(false);
const editing = ref(false);
const addresses = ref([]);

const selectedAddress = computed(() => addresses.value.find((a) => a.id === model.value));

const getAddresses = () => {
    loading.value = true;
    axios
        .get(route('api.account.addresses'))
        .then(({ data }) => (addresses.value = data))
        .finally(() => (loading.value = false));
};

onMounted(() => getAddresses());
</script>
