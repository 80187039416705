<template>
    <div class="entry-display-tag-container tw-flex tw-gap-1">
        <Tooltip label="Draft" position="left">
            <div
                class="badge entry-display-tag tag-draft"
                :class="settings.get(`budgetFigures.${type}.draft`) ? 'selected' : ''"
                @click="setSetting('draft')"
            >
                D
            </div>
        </Tooltip>

        <Tooltip label="Awaiting Approval" position="left">
            <div
                class="badge entry-display-tag tag-awaiting-approval"
                :class="settings.get(`budgetFigures.${type}.approvals`) ? 'selected' : ''"
                @click="setSetting('approvals')"
            >
                AA
            </div>
        </Tooltip>
        <Tooltip label="Approved" position="left">
            <div
                class="badge entry-display-tag tag-approved"
                :class="settings.get(`budgetFigures.${type}.approved`, true) ? 'selected' : ''"
                @click="setSetting('approved')"
            >
                A
            </div>
        </Tooltip>
    </div>
</template>
<script>
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        Tooltip,
    },
    props: {
        type: {
            type: String,
            required: true,
            validator(val) {
                return ['cost', 'revenue', 'combined'].includes(val);
            },
        },
    },
    emits: ['changed'],
    data() {
        const settings = useSettingsStore();
        return {
            settings: settings,
        };
    },
    methods: {
        setSetting(setting) {
            let currentValue = this.settings.get(`budgetFigures.${this.type}.${setting}`) ?? false;

            this.settings.set(`budgetFigures.${this.type}.${setting}`, !currentValue);

            this.$emit('changed');
        },
    },
};
</script>

<style lang="scss" scoped>
.entry-display-tag-container {
    align-self: center !important;

    .entry-display-tag {
        width: 30px;
        height: 30px;
        cursor: pointer;
        line-height: 0.75;
        user-select: none;
        justify-content: center;
        text-align: center;
        padding-top: 8px;
        font-size: 14px !important;
        border-radius: 2px;

        &.tag-draft {
            background-color: hsl(0deg, 0%, 96%);
            color: rgba(0, 0, 0, 0.7);
            opacity: 0.5;

            &.selected {
                border: 1px solid rgba(0, 0, 0, 0.7);
                opacity: 1;
            }
        }

        &.tag-awaiting-approval {
            background-color: #fffaeb;
            color: #946c00;
            opacity: 0.5;

            &.selected {
                border: 1px solid #946c00;
                opacity: 1;
            }
        }

        &.tag-approved {
            background-color: #effaf5;
            color: #257953;
            opacity: 0.5;

            &.selected {
                border: 1px solid #257953;
                opacity: 1;
            }
        }
    }
}
</style>
