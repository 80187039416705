<template>
    <div>
        <h4 class="tw-text-center tw-mt-0 tw-mb-1">
            Welcome{{ currentUser.last_login_at ? ' back' : '' }} to Eventwise, {{ currentUser.name.split(' ')[0] }}
        </h4>
        <template v-if="availableAccounts.length">
            <p class="tw-text-center tw-text-gray-400">Select an account below to manage your events</p>
            <div class="onboarding-account-list tw-mt-3 tw-mb-6">
                <a
                    v-for="account in visibleAccounts"
                    :key="account.id"
                    class="onboarding-account"
                    href="#"
                    @click.prevent="emit('select', account)"
                >
                    <i class="mdi mdi-account"></i>
                    {{ account.name }}
                    <small class="text-muted">
                        {{
                            account.pivot.account_last_accessed
                                ? 'Last accessed ' + DateTime.fromSQL(account.pivot.account_last_accessed).toRelative()
                                : 'Never accessed'
                        }}
                    </small>
                    <span v-if="account.created_by === currentUser.id" class="owned-badge">YOURS</span>
                    <span v-if="account.pivot.is_supplier" class="demo-badge">SUPPLIER</span>
                    <span v-else-if="account.is_demo" class="demo-badge">DEMO</span>
                </a>

                <a v-if="visibleAccounts.length > 5" class="onboarding-account" href="#" @click.prevent="selectAccount">
                    <i class="mdi mdi-swap-horizontal"></i>
                    Other Accounts
                </a>
            </div>
            <div class="separator-label"><span>Fancy something new?</span></div>
        </template>
        <template v-else>
            <p class="tw-text-center tw-text-gray-400">Let's get you started with an Eventwise account</p>
        </template>
        <div class="onboarding-action-list">
            <a class="onboarding-action-button" href="#" @click.prevent="emit('navigate', 'createAccount')">
                <i class="mdi mdi-account-plus"></i>
                <div>
                    Create a new account
                    <small>Payment Details Required</small>
                </div>
            </a>
            <span class="text-muted">or</span>
            <a class="onboarding-action-button text-center" href="#" @click.prevent="emit('navigate', 'createDemo')">
                <div>
                    Try a demo account
                    <small>Get started straight away</small>
                </div>
            </a>
        </div>
    </div>
</template>
<script setup>
import { useDataStore } from '@/js/stores/DataStore.js';
import { DateTime } from 'luxon';
import { computed } from 'vue';

const emit = defineEmits(['select', 'navigate']);
const currentUser = useDataStore().get('current_user');
const availableAccounts = useDataStore().get('available_accounts');

const visibleAccounts = computed(() => {
    const accounts = JSON.parse(JSON.stringify(availableAccounts));
    return accounts
        .sort((a, b) => {
            return (
                (a.pivot.account_last_accessed ? DateTime.fromSQL(a.pivot.account_last_accessed) : 0) -
                (b.pivot.account_last_accessed ? DateTime.fromSQL(b.pivot.account_last_accessed) : 0)
            );
        })
        .reverse()
        .slice(0, 5);
});
const selectAccount = () => {
    Eventbus.$emit('switchAccount');
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.onboarding-account-list {
    padding: 0 20px;
    display: flex;
    flex-basis: 3;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 800px;

    & > .onboarding-account {
        flex: 1;
        min-height: 100px;
        border: 1px solid rgba(black, 0.1);
        min-width: calc(33% - 10px);
        width: calc(33% - 10px);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        text-align: center;
        color: black;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        & > i {
            font-size: 22px;
            margin-bottom: 5px;
        }

        & > small {
            letter-spacing: -0.25px;
        }

        & > .owned-badge {
            position: absolute;
            background: $eventwise-secondary-dark;
            top: 5px;
            left: 3px;
            padding: 0px 4px;
            border-radius: 4px;
            color: white;
            font-weight: 600;
            transform: scale(0.9);
        }
        & > .demo-badge {
            position: absolute;
            background: orange;
            top: 5px;
            right: 3px;
            padding: 0px 4px;
            border-radius: 4px;
            color: white;
            font-weight: 600;
            transform: scale(0.9);
        }

        &:hover {
            background: rgba(black, 0.025);
        }
    }
}
</style>
