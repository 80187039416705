<template>
    <Drawer ref="drawer" :loading="isLoading" :z-index="5002" @closing="reset()">
        <template #title>
            <span v-if="supplier.id && !supplier.is_system" class="mr-2">
                <Tooltip v-if="supplier.xero_contact_id" label="Linked with Xero" position="bottom">
                    <img src="@/images/brands/xero_blue.png" class="icon-sm" alt="Linked with Xero" />
                </Tooltip>

                <Tooltip v-else label="Not linked with Xero" position="bottom">
                    <img src="@/images/brands/xero_blue.png" class="icon-sm grayscale" alt="Not linked with Xero" />
                </Tooltip>
            </span>

            <h4>{{ drawerTitle }}</h4>
        </template>

        <div v-if="supplier">
            <Banner v-if="supplier.is_system" full-width condensed sticky>
                This is a system generated default contact and cannot be changed.
            </Banner>
            <Banner v-else-if="!canUpdate && !supplier.is_archived" full-width condensed sticky>
                Some fields are hidden as you do not have permission to manage suppliers
            </Banner>
            <Banner v-else-if="supplier.is_archived" type="is-dark" full-width condensed sticky>
                This contact has been archived and can no longer be updated
            </Banner>

            <div class="p-4">
                <FormField label="Company Name">
                    <Textbox
                        v-model="supplier.name"
                        placeholder="Company Name"
                        :disabled="!canUpdate"
                        required
                        maxlength="255"
                    />
                </FormField>
                <FormField label="Type" class="tw-mt-2">
                    <div class="tw-flex tw-gap-1">
                        <Button
                            :active="supplier.supplier_type === 0"
                            size="is-small"
                            :disabled="!canUpdate"
                            @click="supplier.supplier_type = 0"
                        >
                            General
                        </Button>
                        <Button
                            :active="supplier.supplier_type === 1"
                            size="is-small"
                            class="tw-flex tw-gap-2"
                            :disabled="!canUpdate"
                            @click="supplier.supplier_type = 1"
                        >
                            <i class="mdi mdi-ticket-account"></i>
                            Ticket Seller
                        </Button>
                        <Button
                            :active="supplier.supplier_type === 2"
                            size="is-small"
                            class="tw-flex tw-gap-2"
                            :disabled="!canUpdate"
                            @click="supplier.supplier_type = 2"
                        >
                            <i class="mdi mdi-store"></i>
                            Trader
                        </Button>
                    </div>
                </FormField>
            </div>
            <hr class="my-0" />
            <div class="tw-p-4">
                <FormField label="Postal Address">
                    <Textbox
                        v-model="supplier.address1"
                        placeholder="Address Line 1"
                        :disabled="!canUpdate"
                        maxlength="255"
                    />
                </FormField>
                <FormField class="tw-mt-1">
                    <Textbox
                        v-model="supplier.address2"
                        placeholder="Address Line 2"
                        :disabled="!canUpdate"
                        maxlength="255"
                    />
                </FormField>
                <FormField class="tw-mt-1">
                    <Textbox
                        v-model="supplier.street"
                        placeholder="Address Line 3"
                        :disabled="!canUpdate"
                        maxlength="255"
                    />
                </FormField>
                <FormField class="tw-mt-1">
                    <Textbox v-model="supplier.city" placeholder="City" :disabled="!canUpdate" maxlength="255" />
                </FormField>
                <FormField class="tw-mt-1">
                    <Textbox v-model="supplier.county" placeholder="County" :disabled="!canUpdate" maxlength="255" />
                </FormField>
                <FormField class="tw-mt-1">
                    <Textbox
                        v-model="supplier.postal_code"
                        placeholder="Postal Code"
                        :disabled="!canUpdate"
                        maxlength="255"
                    />
                </FormField>
                <FormField class="tw-mt-1">
                    <Select v-model="supplier.country" placeholder="Country" :disabled="!canUpdate" class="tw-w-full">
                        <option v-for="country in countries" :key="country" :value="country">
                            {{ country }}
                        </option>
                    </Select>
                </FormField>
            </div>
            <hr class="my-0" />
            <div class="p-4">
                <FormField label="Email Address">
                    <Textbox
                        v-model="supplier.email_address"
                        maxlength="255"
                        placeholder="Email Address"
                        :disabled="!canUpdate"
                    />
                </FormField>
                <FormField label="Phone Number" class="tw-mt-2">
                    <Textbox
                        v-model="supplier.phone_number"
                        maxlength="255"
                        placeholder="Phone Number"
                        :disabled="!canUpdate"
                    />
                </FormField>
            </div>
            <hr class="my-0" />
            <div v-if="canUpdate" class="p-4">
                <FormField label="Bank Account Name">
                    <Textbox
                        v-model="supplier.bank_account_name"
                        maxlength="255"
                        placeholder="Bank Account Name"
                        :disabled="!canUpdate"
                    />
                </FormField>
                <FormField label="Bank Sort Code" class="tw-mt-2">
                    <Textbox
                        v-model="supplier.bank_sort_code"
                        maxlength="255"
                        placeholder="Bank Sort Code"
                        :disabled="!canUpdate"
                    />
                </FormField>
                <FormField label="Bank Account Number" class="tw-mt-2">
                    <Textbox
                        v-model="supplier.bank_account_number"
                        maxlength="255"
                        placeholder="Bank Account Number"
                        :disabled="!canUpdate"
                    />
                </FormField>
                <FormField label="VAT Number" class="tw-mt-2">
                    <Textbox
                        v-model="supplier.tax_number"
                        maxlength="255"
                        placeholder="VAT Number"
                        :disabled="!canUpdate"
                    />
                </FormField>
            </div>
            <template v-if="canUpdate && supplier.xero_contact_id && store.account.is_connected_to_xero">
                <hr class="my-0" />
                <div class="p-4">
                    <Button type="is-primary" class="tw-w-full" @click="syncFromXero()">Sync From Xero</Button>
                </div>
            </template>
        </div>
        <div v-else class="p-3">
            <p class="empty-label py-2">
                There was an issue loading the supplier
                <br />
                Please try again
            </p>
        </div>
        <template v-if="supplier && (canDelete || canUpdate || canSave)" #footer>
            <div class="d-flex">
                <Button
                    v-if="canDelete && supplier.id && !supplier.is_archived"
                    type="is-dark"
                    class="tw-flex tw-gap-2"
                    @click="deleteSupplier()"
                >
                    <i class="mdi mdi-archive"></i>
                    Archive
                </Button>
                <Button
                    v-if="
                        canDelete &&
                        supplier.id &&
                        !supplier.is_archived &&
                        !supplier.xero_contact_id &&
                        !supplier.is_system
                    "
                    type="is-dark"
                    class="tw-ml-auto tw-flex tw-gap-2"
                    @click="mergeSupplier()"
                >
                    <i class="mdi mdi-merge"></i>
                    Merge
                </Button>
                <template v-if="canUpdate">
                    <Button
                        v-if="supplier.xero_contact_id && store.account.is_connected_to_xero"
                        type="is-primary"
                        class="!tw-ml-auto"
                        @click="syncToXero()"
                    >
                        Save & Update In Xero
                    </Button>
                    <template v-else>
                        <Button
                            type="is-success"
                            class="tw-ml-auto tw-flex tw-gap-2"
                            :disabled="!canSave"
                            @click="updateSupplier()"
                        >
                            <i class="mdi mdi-content-save"></i>
                            Save
                        </Button>
                        <Button
                            v-if="store.account.is_connected_to_xero"
                            type="is-primary"
                            class="tw-ml-2"
                            @click="syncToXero()"
                        >
                            Save & Create In Xero
                        </Button>
                    </template>
                </template>
                <Button
                    v-else-if="Auth.can('manage suppliers') && supplier.id && supplier.is_archived"
                    type="is-success"
                    class="tw-ml-auto tw-flex tw-gap-2"
                    @click="restoreSupplier()"
                >
                    <i class="mdi mdi-reload"></i>
                    Restore Contact
                </Button>
            </div>
            <ModalMergeSupplier ref="mergeSupplierModal" />
        </template>
    </Drawer>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Drawer from '../Drawer.vue';
import Banner from '../Layout/Banner.vue';
import ModalMergeSupplier from '../../components/modals/Suppliers/MergeSupplier.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        Banner,
        ModalMergeSupplier,
        Button,
        Textbox,
        Tooltip,
        FormField,
        Select,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            countries: window.Locales,
            supplier: null,
            store: store,
        };
    },
    computed: {
        drawerTitle() {
            let action = this.supplier?.id ? 'Edit ' : 'Create ';
            if (!this.supplier || this.supplier.supplier_type === 0) {
                return action + 'Contact';
            } else if (this.supplier.supplier_type === 1) {
                return action + 'Ticket Seller';
            } else if (this.supplier.supplier_type === 2) {
                return action + 'Trader';
            }
            return action + 'Contact';
        },
        canUpdate() {
            return Auth.can('manage suppliers') && !this.supplier.is_system && !this.supplier.is_archived;
        },
        canDelete() {
            return Auth.can('delete suppliers') && !this.supplier.is_system;
        },
        canSave() {
            return Auth.can('manage suppliers') && !this.supplier.is_system && !this.supplier.is_archived;
        },
    },
    mounted() {
        Eventbus.$on('view:supplier', (payload) => this.viewSupplier(payload));
    },
    methods: {
        viewSupplier(supplier = null) {
            this.supplier = null;
            if (supplier) {
                const selectedSupplier = this.store.get(
                    'account.suppliers.' + (typeof supplier === 'object' ? supplier.id : supplier)
                );
                if (!selectedSupplier) {
                    toast('Error', 'That supplier could not be found', 'error');
                    return;
                }
                this.supplier = JSON.parse(JSON.stringify(selectedSupplier));
            } else {
                this.supplier = {
                    supplier_type: 0,
                };
            }
            this.$refs.drawer.open();
        },
        updateSupplier() {
            if (!this.supplier || this.supplier.is_system) {
                return;
            }
            this.isLoading = true;
            this.updateStore();
            const url = this.supplier?.id
                ? route('api.account.contacts.update', {
                      supplier: this.supplier.id,
                  })
                : route('api.account.contacts.create');
            const method = this.supplier?.id ? 'patch' : 'post';
            axios[method](url, this.supplier)
                .then(() => {
                    toast(
                        'Supplier Saved',
                        'Your changes to ' + this.supplier.name + ' have been saved successfully',
                        'success'
                    );
                    this.$refs.drawer.close();
                })
                .finally(() => {
                    Eventbus.$emit('reload:suppliers-table');
                    Eventbus.$emit('refresh:BudgetEntry');
                    this.isLoading = false;
                });
        },
        updateStore() {
            this.store.set('account.suppliers.' + this.supplier.id, this.supplier);
        },
        deleteSupplier() {
            if (!this.supplier || this.supplier.is_system) {
                return;
            }
            swal.fire({
                title: 'Are you sure?',
                text: 'Archiving this supplier will mean that it can no longer be selected. Are you sure you want to archive this supplier?',
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Archive',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.isLoading = true;
                axios
                    .delete(
                        route('api.account.contacts.delete', {
                            supplier: this.supplier.id,
                        })
                    )
                    .then(() => {
                        this.store.set('account.suppliers.' + this.supplier.id + '.is_archived', true);
                        toast('Supplier Archived', this.supplier.name + ' has been archived successfully', 'success');
                        this.$refs.drawer.close();
                    })
                    .finally(() => {
                        Eventbus.$emit('reload:suppliers-table');
                        this.isLoading = false;
                    });
            });
        },
        mergeSupplier() {
            this.$refs.mergeSupplierModal.open({
                baseSupplier: this.supplier,
            });
        },
        restoreSupplier() {
            if (!this.supplier || this.supplier.is_system) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.contacts.restore', {
                        supplier: this.supplier.id,
                    })
                )
                .then(() => {
                    this.store.set('account.suppliers.' + this.supplier.id + '.is_archived', false);
                    toast('Supplier Restored', this.supplier.name + ' has been restored successfully', 'success');
                    this.$refs.drawer.close();
                })
                .finally(() => {
                    Eventbus.$emit('reload:suppliers-table');
                    this.isLoading = false;
                });
        },
        syncFromXero() {
            if (!this.supplier || !this.supplier.xero_contact_id) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.contacts.syncFromXero', {
                        supplier: this.supplier.id,
                    })
                )
                .then(
                    () => {
                        toast(
                            'Supplier Synced from Xero',
                            this.supplier.name + ' has been successfully synced from Xero',
                            'success'
                        );
                        this.$refs.drawer.close();
                    },
                    () => {
                        toast(
                            'Unable to Sync Supplier from Xero',
                            this.supplier.name + ' could not be synced from Xero',
                            'error'
                        );
                        this.$refs.drawer.close();
                    }
                )
                .finally(() => {
                    Eventbus.$emit('reload:suppliers-table');
                    this.isLoading = false;
                });
        },
        syncToXero() {
            if (!this.supplier || this.supplier.is_system) {
                return;
            }
            this.isLoading = true;
            let url = this.supplier?.id
                ? route('api.account.contacts.syncToXero', {
                      supplier: this.supplier.id,
                  })
                : route('api.account.contacts.createInXero');
            axios
                .post(url, this.supplier)
                .then(() => {
                    toast(
                        'Supplier Saved',
                        'Your changes to ' + this.supplier.name + ' have been saved successfully and updated in Xero',
                        'success'
                    );
                    this.$refs.drawer.close();
                })
                .finally(() => {
                    Eventbus.$emit('reload:suppliers-table');
                    this.isLoading = false;
                });
        },
        reset() {
            this.supplier = null;
            this.isLoading = false;
        },
    },
};
</script>
