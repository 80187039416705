<template>
    <div>
        <LoadingSpinner v-if="!isLoaded" />
        <div v-else class="tw-grid tw-grid-cols-4 lg:tw-grid-cols-5 xl:tw-grid-cols-6 tw-gap-6">
            <Chart
                type="line"
                :options="chartOptions"
                :data="visibleChartSeries"
                class="tw-col-span-3 lg:tw-col-span-4 xl:tw-col-span-5"
            />

            <div class="tw-col-span-1">
                <strong>Legend</strong>
                <FormField v-for="(item, index) in chartSeries.datasets.map((d) => d.name)" :key="item + ':' + index">
                    <Checkbox v-model="chartSelected[index]" type="is-primary">
                        {{ item }}
                    </Checkbox>
                </FormField>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from '@/js/components/widgets/LoadingSpinner.vue';
import Chart from '@/js/components/Chart.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        LoadingSpinner,
        Chart,
        FormField,
        Checkbox,
    },
    data() {
        return {
            isLoaded: false,
            chartSeries: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            text: 'Departments',
                            display: true,
                        },
                        type: 'category',
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Amount',
                        },
                        ticks: {
                            callback(value) {
                                return localise(value, 0, true);
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(context) {
                                return ' ' + localise(context.parsed.y, 2, true);
                            },
                        },
                    },
                },
            },
            chartSelected: [],
        };
    },
    computed: {
        visibleChartSeries() {
            return {
                labels: this.chartSeries.labels,
                datasets: this.chartSeries.datasets.filter((_series, index) => this.chartSelected[index]),
            };
        },
    },
    mounted() {
        this.reloadData();
        Eventbus.$on('reload:charts', () => {
            this.reloadData();
        });
    },
    methods: {
        reloadData() {
            this.isLoaded = false;
            axios.get(route('api.account.event.chartData.departments.combined')).then(({ data }) => {
                this.chartSeries = {
                    labels: data.labels,
                    datasets: data.series.map((s, i) => ({
                        ...s,
                        label: s.name,
                        backgroundColor: data.colours[i],
                    })),
                };
                this.chartSelected = data.selected;
                this.isLoaded = true;
            });
        },
    },
};
</script>
<style lang="scss">
input[type='checkbox'] {
    opacity: 1 !important;
}
</style>
