<template>
    <div class="date-selector-container">
        <div class="date-selector">
            <div class="actions">
                <button class="interval-shift" @click="previousPeriod()"><i class="mdi mdi-chevron-left"></i></button>
            </div>
            <div class="content">
                <template v-for="(p, index) in period" :key="index">
                    <a
                        v-if="index > currentPeriodIndex - 3 && index < currentPeriodIndex + 3"
                        :key="index"
                        href="#"
                        :class="{ active: index === currentPeriodIndex }"
                        @click.prevent="setCurrentPeriod(index)"
                    >
                        <slot name="prepend" :date="p" :active="index === currentPeriodIndex"></slot>
                        <slot name="item">
                            <template v-if="interval === 'day'">
                                <div v-if="index === currentPeriodIndex - 2 || p.day === 1" class="date-month">
                                    {{ p.toFormat('LLLL y') }}
                                </div>
                                {{ p.toFormat('cccc') }}
                                <div class="date-day">{{ ordinalSuffix(p.day) }}</div>
                            </template>
                            <template v-else>Weekly interval</template>
                        </slot>
                        <slot name="append" :date="p" :active="index === currentPeriodIndex"></slot>
                    </a>
                </template>
                <div v-if="hasApproachedEnd" class="empty">
                    <slot name="empty">There are no more dates to display</slot>
                </div>
            </div>
            <div class="actions">
                <button @click="openDateSelector()"><i class="mdi mdi-calendar-today"></i></button>
                <button class="interval-shift" @click="nextPeriod()"><i class="mdi mdi-chevron-right"></i></button>
            </div>
        </div>
        <Modal ref="selectDateModal">
            <template #content>
                <div class="date-picker">
                    <flat-pickr
                        :model-value="currentPeriod.toFormat('y-LL-dd')"
                        :config="flatpickrDateConfig"
                        @update:model-value="setCurrentPeriodFromDate"
                    ></flat-pickr>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import Modal from '../Modal.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { ordinalSuffix } from '@/js/utils.js';
import { DateTime, Interval } from 'luxon';

export default {
    components: {
        Modal,
    },
    props: {
        modelValue: {
            type: [String, Number, Object],
            required: true,
        },
        interval: {
            type: String,
            default: 'day',
        },
        start: {
            type: [String, Number],
            required: false,
            default: null,
        },
        end: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    emits: ['update:modelValue'],
    data() {
        const store = useDataStore();
        return {
            currentPeriod: null,
            startDate: null,
            endDate: null,
            store: store,
        };
    },
    computed: {
        period() {
            const defaultInterval =
                this.interval === 'day'
                    ? {
                          days: 7,
                      }
                    : {
                          weeks: 4,
                      };

            if (!this.currentPeriod) {
                return [];
            }
            const intervalStart = this.startDate
                ? this.startDate.startOf('day')
                : DateTime.now().minus(defaultInterval).startOf('day');
            const intervalEnd = this.endDate ? this.endDate : DateTime.now().plus(defaultInterval);
            return Interval.fromDateTimes(intervalStart, intervalEnd)
                .splitBy(
                    this.interval === 'day'
                        ? {
                              day: 1,
                          }
                        : {
                              week: 1,
                          }
                )
                .map((d) => d.start);
        },
        currentPeriodIndex() {
            if (!this.currentPeriod) {
                return null;
            }
            let index = null;
            this.period.forEach((date, dateIndex) => {
                if (!index && date.startOf('day').diff(this.currentPeriod.startOf('day')).milliseconds >= 0) {
                    index = dateIndex;
                }
            });
            return index;
        },
        flatpickrDateConfig() {
            return {
                inline: true,
                defaultDate: this.currentPeriod.toFormat('y-LL-dd'),
                minDate: this.startDate.toFormat('y-LL-dd'),
                maxDate: this.endDate.toFormat('y-LL-dd'),
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
            };
        },
        hasApproachedEnd() {
            return this.currentPeriodIndex > this.period.length - 3;
        },
    },
    watch: {
        currentPeriod(newVal) {
            this.$emit('update:modelValue', newVal);
        },
    },
    mounted() {
        this.startDate = this.start ? DateTime.fromISO(this.start) : null;
        this.endDate = this.end ? DateTime.fromISO(this.end) : null;
        const projectEnd = this.store.get('current_event.project_end');
        if (DateTime.fromString(projectEnd, 'y-LL-dd') > DateTime.now()) {
            this.currentPeriod = DateTime.fromString(projectEnd, 'y-LL-dd');
        } else {
            this.currentPeriod = this.modelValue ? this.modelValue : DateTime.now();
        }
    },
    methods: {
        ordinalSuffix,
        setCurrentPeriod(index) {
            this.currentPeriod = this.period[index];
        },
        setCurrentPeriodFromDate(date) {
            this.currentPeriod = DateTime.fromString(date, 'y-LL-dd');
            this.closeDateSelector();
        },
        previousPeriod() {
            this.currentPeriod = this.period[this.currentPeriodIndex - 1];
        },
        nextPeriod() {
            this.currentPeriod = this.period[this.currentPeriodIndex + 1];
        },
        openDateSelector() {
            this.$refs.selectDateModal.open();
        },
        closeDateSelector() {
            this.$refs.selectDateModal.close();
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.date-picker {
    input {
        display: none;
    }
}

.date-selector {
    background: white;
    display: flex;
    min-height: 100px;
    border: 1px solid rgba(black, 0.1);
    border-radius: 8px;
    overflow: hidden;

    & > .actions {
        display: flex;
        flex-direction: column;

        &:first-child {
            border-right: 1px solid rgba(black, 0.1);
        }
        &:last-child {
            border-left: 1px solid rgba(black, 0.1);
        }

        & > button {
            border: none;
            font-size: 20px;
            padding: 0 4px;
            background: transparent;

            &.interval-shift {
                font-size: 26px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(black, 0.1);
            }
            &:last-child {
                flex: 1;
            }
            &:hover {
                background: rgba(black, 0.025);
            }
        }
    }
    & > .content {
        display: flex;
        flex-wrap: nowrap;
        flex: 1;
        overflow: hidden;

        & > a {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 10px 20px;
            min-width: 20%;
            overflow: hidden;
            width: 20%;
            line-height: 125%;

            &:not(:last-child) {
                border-right: 1px solid rgba(black, 0.1);
            }
            &:hover {
                background: rgba(black, 0.01);
                color: black;
                transition: all 0.1s ease;
            }
            &.active {
                background: $object-invoiced;
                color: white;
            }

            .date-month {
                font-size: 0.9em;
                opacity: 0.5;
                padding-bottom: 5px;
                margin-bottom: auto;
            }
            .date-day {
                font-weight: 600;
                font-size: 1.33em;
            }
        }
        & > div.empty {
            background: rgba(black, 0.033);
            color: rgba(black, 0.33);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            flex: 1;
            user-select: none;
        }
    }
}
</style>
