<template>
    <Drawer
        ref="drawer"
        title="Purchase Order"
        :loading="isLoading || isUpdating"
        header-class="p-0 border-0"
        size="is-large"
        @closing="reopenSource()"
        @closed="resetDrawer()"
    >
        <template v-if="purchaseOrder" #header>
            <div v-if="purchaseOrder.is_closed" class="text-white py-1 px-5" style="background: rgba(0, 0, 0, 0.66)">
                This purchase order has been marked as closed
            </div>
            <div class="tw-px-5 tw-pt-4 tw-pb-2">
                <div class="d-flex">
                    <h2 class="drawer-title flex-fill mb-2">
                        <span v-if="purchaseOrder.po_number" class="text-muted mr-1">
                            #{{ purchaseOrder.reference }}
                        </span>
                        <EditableText
                            v-model="purchaseOrder.title"
                            :max-length="255"
                            :editable="!purchaseOrder.is_closed && !purchaseOrder.is_approved && isEditable"
                            @update:model-value="longDebouncedUpdatePO()"
                        />
                    </h2>
                    <FormField v-if="purchaseOrder.created_by" label="Created by" class="tw-ml-3">
                        <User :user="purchaseOrder.created_by" :hide-name="false" class="ml-3" />
                    </FormField>
                </div>

                <ApprovalControls
                    v-if="!purchaseOrder.category.is_pooled"
                    :disable-request="!purchaseOrder.line_items.length || !lineItemsAreValid"
                    :approvables="[purchaseOrder]"
                    :status-only="purchaseOrder.approved && purchaseOrder.invoices && !!purchaseOrder.invoices.length"
                    :locked="!isEditable"
                >
                    <Button
                        v-if="Auth.can('delete purchase orders') && purchaseOrder.uncommitted && isEditable"
                        class="tw-flex tw-gap-2"
                        type="is-danger"
                        :disabled="isLoading"
                        @click="deletePO()"
                    >
                        <i class="mdi mdi-delete"></i>
                        Delete
                    </Button>
                    <template v-if="Auth.can('update purchase orders') && purchaseOrder.status === 'Approved'">
                        <Button type="is-info" @click="downloadPDF()">Download PDF</Button>
                        <Button v-if="isEditable" type="is-info" @click="sendEmail()">Send by Email</Button>
                        <Button v-if="canClosePO && isEditable" type="is-danger" @click="closePO()">
                            Mark as Closed
                        </Button>
                        <Button v-if="purchaseOrder.is_closed && isEditable" @click="reopenPO()">Reopen</Button>
                    </template>
                    <template v-else-if="purchaseOrder.status === 'Sent'">
                        <Button type="is-info" @click="downloadPDF()">Download PDF</Button>
                        <Button v-if="isEditable" type="is-info" @click="sendEmail()">Send by Email</Button>
                        <Button
                            v-if="Auth.account.is_connected_to_xero && isEditable"
                            type="is-info"
                            @click="raiseXeroInvoice()"
                        >
                            Raise Supplier Invoice in Xero
                        </Button>
                    </template>
                </ApprovalControls>
            </div>
            <div
                v-if="!purchaseOrder.category.is_pooled"
                class="tw-flex tw-items-center tw-gap-2 tw-px-5 tw-pt-2 tw-pb-4 tw-border-b"
            >
                <FormField class="tw-flex-1" label="Supplier">
                    <SupplierSelector
                        v-model="initialSupplierId"
                        placeholder="Type to search"
                        :editable="isEditable"
                        @update:model-value="
                            (val) => {
                                updateWarning('supplier', val);
                            }
                        "
                    />
                </FormField>
                <FormField class="tw-flex-1" label="Linked Budget Entry">
                    <div class="tw-flex">
                        <modal-selector
                            v-model="purchaseOrder.budget_entry_id"
                            class="tw-flex-1"
                            :editable="isEditable"
                            :endpoint="
                                route('api.account.event.entries', {
                                    event: purchaseOrder.event_id,
                                    category_id: purchaseOrder.category_id,
                                })
                            "
                            modal-title="Link Budget Entry"
                            button-title="Link a Budget Entry"
                            allow-search
                            @update:model-value="
                                (val) => {
                                    purchaseOrder.budget_entry_id = val;
                                    updatePO();
                                }
                            "
                        />

                        <Tooltip v-if="purchaseOrder.budget_entry_id" label="View Budget Entry" class="tw-ml-2">
                            <Button @click="openLinkedEntry">
                                <i class="mdi mdi-eye-outline"></i>
                            </Button>
                        </Tooltip>

                        <Tooltip
                            v-if="purchaseOrder.budget_entry_id && isEditable"
                            label="Unlink Budget Entry"
                            class="tw-ml-2"
                        >
                            <Button
                                v-if="purchaseOrder.budget_entry_id && purchaseOrder.uncommitted"
                                @click="unLinkedEntry"
                            >
                                <i class="mdi mdi-link-variant-off"></i>
                            </Button>
                        </Tooltip>
                    </div>
                </FormField>
            </div>

            <div class="d-flex tw-px-5 tw-pt-3 tw-pb-3">
                <FormField label="Subtotal" class="mb-0">
                    {{ localise(purchaseOrder.subtotal, 2, true) }}
                    <span v-if="purchaseOrder.source_currency !== purchaseOrder.target_currency">
                        ({{
                            localise(
                                purchaseOrder.subtotal,
                                2,
                                true,
                                purchaseOrder.target_currency,
                                purchaseOrder.conversion_rate
                            )
                        }})
                    </span>
                </FormField>
                <FormField v-if="!purchaseOrder.category.is_pooled" label="Gross" class="tw-pl-5">
                    {{ localise(purchaseOrder.total, 2, true) }}
                    <span v-if="purchaseOrder.source_currency !== purchaseOrder.target_currency">
                        ({{
                            localise(
                                purchaseOrder.total,
                                2,
                                true,
                                purchaseOrder.target_currency,
                                purchaseOrder.conversion_rate
                            )
                        }})
                    </span>
                </FormField>
                <div
                    v-if="!purchaseOrder.budget_entry_id && purchaseOrder.status && purchaseOrder.uncommitted"
                    class="px-5 mb-2"
                >
                    <FormField label="Available Subcategories">
                        <div class="d-inline-flex">
                            <modal-selector
                                v-model="purchaseOrder.category_id"
                                :endpoint="
                                    route('api.account.event.departments.categories', {
                                        department: purchaseOrder.category.department_id,
                                    })
                                "
                                modal-title="Link Subcategory"
                                button-title="Link a Subcategory"
                                :editable="isEditable"
                                allow-search
                                @update:model-value="
                                    (val) => {
                                        purchaseOrder.category_id = val;
                                        updatePO();
                                    }
                                "
                            />
                            <Tooltip v-if="purchaseOrder.budget_entry_id" label="View Budget Entry" class="pl-2">
                                <Button @click="openLinkedEntry">
                                    <i class="mdi mdi-eye-outline"></i>
                                </Button>
                            </Tooltip>
                        </div>
                    </FormField>
                </div>
                <div v-else>
                    <FormField v-if="!purchaseOrder.category.is_pooled" label="Amount Paid" class="tw-pl-5">
                        {{ localise(purchaseOrder.total_paid, 2, true) }}
                    </FormField>
                </div>
                <FormField
                    v-if="taxRates && taxRates.length && !purchaseOrder.category.is_pooled"
                    label="Default Tax Rate"
                    class="tw-flex-1 tw-pl-auto tw-text-right"
                >
                    <TaxRateSelector
                        v-model="purchaseOrder.tax_rate_id"
                        :disabled="!isEditable"
                        type="expense"
                        @update:model-value="updatePO()"
                    />
                </FormField>
            </div>
        </template>
        <Tabs v-if="purchaseOrder" :tabs="visibleTabs">
            <template #tab(line_items)>
                <LineItemBuilder
                    v-model="purchaseOrder.line_items"
                    :editable="isEditable && purchaseOrder.status === 'Draft'"
                    :line_item_type="2"
                    :default-tax-rate-id="purchaseOrder.tax_rate_id ? purchaseOrder.tax_rate_id : null"
                    tax-rate-type="expense"
                    :is-approved-purchase-order="isApproved()"
                    hide-invoiced
                    @update:model-value="() => updateLineItems()"
                />
            </template>
            <template v-if="budgetEntry.id && !isLoading" #tab(budgeting)>
                <drawer-budgeting-tab
                    :approved="isApproved()"
                    :budget-entry="budgetEntry"
                    :subtotal="parseFloat(parseFloat(purchaseOrder.subtotal).toFixed(2))"
                    :on-open="openLinkedEntry"
                />
            </template>
            <template #tab(invoices)>
                <DrawerInvoicesTab
                    :on-raise-invoice="raiseXeroInvoice"
                    :invoices="purchaseOrder.invoices"
                    :can-raise-invoice-conditions="[
                        {
                            can: isApproved() && !purchaseOrder.is_closed,
                            message: purchaseOrder.is_closed
                                ? 'You cannot raise a new invoice on a closed PO'
                                : 'You can only raise invoices on an approved PO',
                        },
                        {
                            can: isEditable,
                            message: 'This purchase order is no longer editable.',
                        },
                    ]"
                    @view="viewInvoice"
                />
            </template>
            <template v-if="!purchaseOrder.category.is_pooled" #tab(extra_info)>
                <drawer-extra-info-tab :disabled="!isEditable" :purchase-order="purchaseOrder" @update="updatePO()" />
            </template>
            <template v-if="!purchaseOrder.category.is_pooled" #tab(activity)>
                <drawer-comments-tab
                    :activity="purchaseOrder.activity"
                    :comments="purchaseOrder.comments"
                    :disabled="!isEditable"
                    @comment="addComment"
                />
            </template>
        </Tabs>
        <modal-raise-purchase-invoice
            v-if="purchaseOrder"
            @reload="loadPurchaseOrder(purchaseOrder.id, purchaseOrder.event_id)"
            @created="(id) => viewInvoice(id, true)"
        />
        <modal-invoice-payment-dates
            v-if="purchaseOrder"
            @closed="loadPurchaseOrder(purchaseOrder.id, purchaseOrder.event_id)"
        />
        <template v-if="purchaseOrder" #footer>
            <Presence :channel="'PurchaseOrder.' + purchaseOrder.id" label="Currently viewing this Purchase Order" />
        </template>
    </Drawer>
</template>
<script>
import Drawer from '../../Drawer.vue';
import debounce from 'lodash/debounce';
import ApprovalControls from '../../widgets/ApprovalControls.vue';
import TaxRateSelector from '../../controls/TaxRateSelector.vue';
import LineItemBuilder from '../../widgets/LineItemBuilder.vue';
import EditableText from '../../controls/EditableText.vue';
import ModalRaisePurchaseInvoice from '../../modals/Invoices/RaisePurchaseInvoice.vue';
import ModalInvoicePaymentDates from '../../modals/Invoices/InvoicePaymentDates.vue';
import SupplierSelector from '../../controls/SupplierSelector.vue';
import Presence from '../../widgets/PresenceIndicator.vue';
import User from '../../widgets/User.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import DrawerInvoicesTab from '@/assets/js/eventbooks/components/drawer-tabs/invoices.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        SupplierSelector,
        ApprovalControls,
        EditableText,
        TaxRateSelector,
        LineItemBuilder,
        ModalRaisePurchaseInvoice,
        ModalInvoicePaymentDates,
        Presence,
        User,
        Button,
        FormField,
        DrawerInvoicesTab,
        Tooltip,
        Tabs,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isUpdating: false,
            purchaseOrder: null,
            budgetEntry: {},
            isEditingDeliveryDetails: false,
            taxRates: Object.values(store.get('account.tax_rates')),
            source: null,
            initialSupplierId: 0,
            store: store,
        };
    },
    computed: {
        isEditable() {
            return Auth.can('update purchase orders') && !this.store.get('current_event.is_closed');
        },
        isConnectedToXero() {
            return this.store.get('account.is_connected_to_xero');
        },
        canClosePO() {
            if (!this.purchaseOrder) {
                return false;
            }
            return !this.purchaseOrder.is_closed && this.purchaseOrder.total_invoiced <= this.purchaseOrder.total;
        },
        lineItemsAreValid() {
            if (!this.purchaseOrder) {
                return false;
            }
            return !this.purchaseOrder.line_items.find(
                (lineItem) => !lineItem.quantity || !lineItem.unit_price || !lineItem.description
            );
        },
        visibleTabs() {
            const tabs = [
                {
                    title: 'Line Items',
                    name: 'line_items',
                },
            ];
            if (this.budgetEntry && !this.isLoading) {
                tabs.push({
                    title: 'Budgeting',
                    name: 'budgeting',
                });
            }
            if (
                this.isApproved ||
                (this.purchaseOrder &&
                    this.purchaseOrder.invoices &&
                    this.purchaseOrder.invoices.length &&
                    !this.purchaseOrder.category.is_pooled)
            ) {
                tabs.push({
                    title: 'Invoices',
                    name: 'invoices',
                });
            }
            if (this.purchaseOrder && !this.purchaseOrder.category.is_pooled) {
                tabs.push({
                    title: 'Extra Info',
                    name: 'extra_info',
                });
                tabs.push({
                    title: 'Activity',
                    name: 'activity',
                });
            }
            return tabs;
        },
    },
    mounted() {
        Eventbus.$on('purchaseOrder:view', (payload) => {
            let eventId = null;
            let poId = payload;
            if (payload && typeof payload === 'object') {
                poId = payload.purchaseOrder;
                eventId = payload.event ?? null;
                this.source = payload.source;
            }
            this.openDrawer();
            this.loadPurchaseOrder(poId, eventId);
        });
        Eventbus.$on('purchaseOrder:reloadLineItems', () => {
            this.loadPurchaseOrder(this.purchaseOrder.id, this.purchaseOrder.event_id);
        });
        Eventbus.$on('open:PurchaseOrder', (payload) => {
            this.openDrawer();
            this.loadPurchaseOrder(payload);
        });
        Eventbus.$on('purchaseOrder:sent', (payload) => {
            if (!this.purchaseOrder || this.purchaseOrder.id != payload) {
                return;
            }
            this.loadPurchaseOrder(this.purchaseOrder.id, this.purchaseOrder.event_id);
        });
        Eventbus.$on('reloadSupplierInvoices', () => {
            if (this.purchaseOrder) {
                this.loadPurchaseOrder(this.purchaseOrder.id, this.purchaseOrder.event_id);
            }
        });
    },
    methods: {
        loadPurchaseOrder(purchaseOrderId, eventId = null) {
            this.isLoading = true;
            axios
                .get(
                    route(
                        'api.account.event.purchaseorders.get',
                        eventId
                            ? {
                                  event: eventId,
                                  purchaseOrder: purchaseOrderId,
                              }
                            : {
                                  purchaseOrder: purchaseOrderId,
                              }
                    )
                )
                .then((response) => {
                    this.purchaseOrder = response.data;
                    if (this.purchaseOrder.budget_entry) {
                        this.budgetEntry = this.purchaseOrder.budget_entry;
                    }
                    this.initialSupplierId = this.purchaseOrder.supplier_id;
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                });
        },
        reopenSource() {
            if (this.source) {
                Eventbus.$emit(this.source.emitter, this.source.payload);
                this.source = null;
            }
        },
        openDrawer() {
            this.$refs?.drawer.open();
        },
        closeDrawer() {
            this.$refs.drawer.close();
            this.resetDrawer();
        },
        resetDrawer() {
            this.isLoading = false;
            this.purchaseOrder = null;
        },
        updatePO() {
            if (!this.purchaseOrder || !this.purchaseOrder.supplier_id || this.isLoading) {
                return;
            }
            this.isUpdating = true;
            axios
                .post(
                    route('api.account.event.purchaseorders.update', {
                        account: this.purchaseOrder.account_id,
                        event: this.purchaseOrder.event_id,
                        purchaseOrder: this.purchaseOrder.id,
                    }),
                    this.purchaseOrder
                )
                .then((response) => (this.purchaseOrder = response.data))
                .finally(() => {
                    this.isUpdating = false;
                    Eventbus.$emit('reload:purchaseorders');
                    Eventbus.$emit('reload:statistics');
                });
        },
        longDebouncedUpdatePO: debounce(function () {
            this.updatePO();
        }, 1500),
        debouncedUpdatePO: debounce(function () {
            this.updatePO();
        }, 500),
        updateWarning(updateType, id) {
            if (this.isApproved() && id != null) {
                if (updateType === 'supplier' && this.purchaseOrder.supplier_id !== id) {
                    swal.fire(
                        'Warning',
                        'Because the supplier has been changed, this PO will require reapproval',
                        'warning'
                    );
                    this.purchaseOrder.supplier_id = id;
                    this.initialSupplierId = id;
                    // reset to draft
                    this.purchaseOrder.status = 'Draft';
                    this.updatePO();
                }
            } else if (!this.isApproved() && id != null) {
                this.purchaseOrder.supplier_id = id;
                this.initialSupplierId = id;
                this.updatePO();
            }
        },
        deletePO() {
            this.isUpdating = true;
            const urlStr = route('api.account.event.purchaseorders.delete', {
                account: this.purchaseOrder.account_id,
                event: this.purchaseOrder.event_id,
                purchaseOrder: this.purchaseOrder.id,
            });
            axios.delete(urlStr).then(() => {
                this.isUpdating = false;
                Eventbus.$emit('reload:purchaseorders');
                Eventbus.$emit('reload:statistics');
                this.closeDrawer();
            });
        },
        closePO() {
            swal.fire({
                title: 'Close Purchase Order',
                text: 'Closing this purchase order will adjust the total value to what has already been invoiced and prevent further editing. Are you sure you wish to continue?',
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Close Purchase Order',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.isUpdating = true;
                axios
                    .post(
                        route('api.account.event.purchaseorders.close', {
                            account: this.purchaseOrder.account_id,
                            event: this.purchaseOrder.event_id,
                            purchaseOrder: this.purchaseOrder.id,
                        })
                    )
                    .then(({ data }) => (this.purchaseOrder = data))
                    .finally(() => (this.isUpdating = false));
            });
        },
        reopenPO() {
            axios
                .post(
                    route('api.account.event.purchaseorders.close', {
                        account: this.purchaseOrder.account_id,
                        event: this.purchaseOrder.event_id,
                        purchaseOrder: this.purchaseOrder.id,
                    })
                )
                .then(({ data }) => (this.purchaseOrder = data))
                .finally(() => (this.isUpdating = false));
        },
        downloadPDF() {
            window.open(
                route('account.event.export.purchaseorders.pdf', {
                    account: this.purchaseOrder.account_id,
                    event: this.purchaseOrder.event_id,
                    purchaseOrder: this.purchaseOrder.id,
                })
            );
        },
        sendEmail() {
            Eventbus.$emit('send:email', {
                model: this.purchaseOrder,
                type: 'purchase order',
            });
        },
        viewInvoice(supplierInvoiceId, openPaymentDates = false) {
            Eventbus.$emit('viewInvoice', 'SupplierInvoice', {
                event: this.purchaseOrder.event_id,
                purchaseOrder: this.purchaseOrder.id,
                supplierInvoice: supplierInvoiceId,
                openPaymentDates: openPaymentDates,
            });
        },
        raiseXeroInvoice() {
            Eventbus.$emit('purchaseInvoice:create', this.purchaseOrder);
        },
        addComment(commentText) {
            if (!commentText) {
                toast('Warning', 'You must provide some text for your comment', 'warning');
                return;
            }
            this.isUpdating = true;
            axios
                .post(
                    route('api.account.event.purchaseorders.comment', {
                        event: this.purchaseOrder.event_id,
                        purchaseOrder: this.purchaseOrder.id,
                    }),
                    { text: commentText }
                )
                .then((response) => {
                    this.purchaseOrder.comments.push(response.data.comment);
                })
                .finally(() => (this.isUpdating = false));
        },
        openLinkedEntry() {
            if (!this.purchaseOrder.budget_entry_id) {
                return;
            }
            Eventbus.$emit('budget:entry', {
                entry: this.purchaseOrder.budget_entry_id,
                source: {
                    emitter: 'open:PurchaseOrder',
                    payload: this.purchaseOrder.id,
                },
            });
            this.closeDrawer();
        },
        unLinkedEntry() {
            this.purchaseOrder.budget_entry_id = null;
            this.updatePO();
        },
        isApproved() {
            return ['Approved', 'Sent', 'Complete'].includes(this.purchaseOrder.status);
        },
        updateLineItems: debounce(function () {
            if (!this.purchaseOrder || !this.lineItemsAreValid || this.isLoading) {
                return;
            }
            this.postLineItems();
        }, 500),
        postLineItems() {
            axios
                .post(
                    route('api.account.event.purchaseorders.update', {
                        account: this.purchaseOrder.account_id,
                        event: this.purchaseOrder.event_id,
                        purchaseOrder: this.purchaseOrder.id,
                    }),
                    {
                        line_items: this.purchaseOrder.line_items,
                    }
                )
                .then(({ data }) => {
                    this.purchaseOrder.subtotal = data.subtotal;
                    this.purchaseOrder.total = data.total;
                    this.purchaseOrder.activity = data.activity;
                    Eventbus.$emit('reload:purchaseorders');
                    Eventbus.$emit('reload:statistics');
                    Eventbus.$emit('refresh:BudgetEntry');
                });
        },
    },
};
</script>
<style lang="scss">
.budgeting-donut-container {
    max-height: 110px;
}
.approvals-style-button {
    height: 2em !important;
    padding: 0.5rem !important;
}
</style>
