<template>
    <div class="buefy-wrapper">
        <Banner class="tw-bg-white">
            <div class="d-flex align-items-center py-3 mb-2">
                <div class="flex-fill pr-5">
                    <img src="@/images/stripe-blurple.svg" style="display: block; max-height: 50px" />
                    <div class="pl-3">
                        All payments are handled by our secure third-party payment provider, Stripe.
                        <br />
                        Click the button to the right to be taken to the billing portal where you can manage your
                        subscription and payment methods.
                    </div>
                </div>
                <Button type="is-primary" style="line-height: 100%" @click="openBillingPortal()">
                    <i class="mdi mdi-open-in-new"></i>
                    Access the billing portal
                    <small class="d-block" style="font-size: 12px">(opens in a new window)</small>
                </Button>
            </div>
        </Banner>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        LoadingSpinner,
        Button,
        Banner,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            currentAccount: store.get('account'),
        };
    },
    methods: {
        openBillingPortal() {
            this.isLoading = true;
            window.location.href = route('account.billingPortal');
        },
    },
};
</script>
