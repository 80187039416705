<template>
    <div>
        <StylisedModal ref="modal" title="Purchase Order">
            <div v-if="purchaseOrder">
                <div class="row mx-4 mb-3">
                    <div class="col-12 mb-3">
                        <h4>
                            {{ purchaseOrder.reference ? purchaseOrder.reference + ' - ' : ''
                            }}{{ purchaseOrder.title }}
                        </h4>
                    </div>
                    <div class="col-4">
                        <FormField label="Status">
                            <Tag :status="purchaseOrder.status" />
                        </FormField>
                    </div>
                    <div class="col-4">
                        <FormField label="Supplier">
                            <span v-if="purchaseOrder.supplier">{{ purchaseOrder.supplier.name }}</span>
                            <span v-else class="text-muted">Unassigned</span>
                        </FormField>
                    </div>
                    <div class="col-4">
                        <FormField label="Last Updated">
                            {{ localTimestamp(purchaseOrder.updated_at) }}
                        </FormField>
                    </div>
                </div>
                <DataTable
                    :data="purchaseOrder.line_items"
                    :columns="[
                        { title: 'Line Item', field: 'description' },
                        { title: 'Quantity', field: 'quantity' },
                        { title: 'Unit Price', field: 'unit_price' },
                        { title: 'Tax Rate', field: 'tax_rate' },
                        { title: 'Amount', field: 'total' },
                    ]"
                >
                    <template #column(unit_price)="{ row }">
                        {{ localise(row.unit_price, 2, true) }}
                    </template>
                    <template #column(tax_rate)="{ row }">
                        <span v-if="row.tax_rate">{{ parseFloat(row.tax_rate.rate).toFixed(2) }}%</span>
                        <span v-else class="text-muted">None</span>
                    </template>
                    <template #column(amount)="{ row }">
                        {{ localise(row.total, 2, true) }}
                    </template>
                    <template #footer>
                        <td colspan="3" class="pl-4" style="vertical-align: bottom">
                            <FormField v-if="purchaseOrder.created_by" label="Created by">
                                <User :user="purchaseOrder.created_by" :hide-name="false" />
                            </FormField>
                        </td>
                        <td width="100" colspan="2">
                            <div class="row">
                                <div class="col-4" style="font-weight: 600">Subtotal</div>
                                <div class="col-8 text-right pr-4">
                                    {{ localise(purchaseOrder.subtotal, 2, true) }}
                                </div>
                                <div class="col-4" style="font-weight: 600">Tax</div>
                                <div class="col-8 text-right pr-4">
                                    {{ localise(purchaseOrder.tax_amount, 2, true) }}
                                </div>
                                <div class="col-4" style="font-weight: 600">Total</div>
                                <div class="col-8 text-right pr-4">
                                    {{ localise(purchaseOrder.total, 2, true) }}
                                </div>
                                <div class="col-4 mt-2" style="font-weight: 600">Invoiced</div>
                                <div class="col-8 mt-2 text-right pr-4">
                                    {{ localise(purchaseOrder.total_invoiced_gross, 2, true) }}
                                </div>
                                <div class="col-4" style="font-weight: 600">Paid</div>
                                <div class="col-8 text-right pr-4">
                                    {{ localise(purchaseOrder.total_paid, 2, true) }}
                                </div>
                            </div>
                        </td>
                    </template>
                </DataTable>
                <Banner type="is-info" class="mt-3 mx-4 mb-2">
                    You are viewing a condensed version of the original purchase order, download to view the full
                    purchase order and any associated terms.
                </Banner>
                <Banner
                    v-if="purchaseOrder.source_currency != purchaseOrder.target_currency"
                    type="is-info is-light"
                    class="mx-4 mb-2"
                >
                    The target currency of this invoice ({{ purchaseOrder.target_currency }}) differs from the event
                    currency ({{ purchaseOrder.source_currency }}). The true value of this invoice is
                    {{
                        localise(
                            purchaseOrder.total,
                            2,
                            true,
                            purchaseOrder.target_currency,
                            purchaseOrder.conversion_rate
                        )
                    }}
                    (rate: {{ purchaseOrder.conversion_rate }}).
                </Banner>
            </div>
            <template #actions>
                <a href="#" class="mb-1" @click.prevent="downloadPdf()">Download PDF</a>
                <a href="#" @click.prevent="raiseInvoice()">Raise an Invoice</a>
            </template>
        </StylisedModal>
        <RaiseSupplierPurchaseInvoice ref="raiseSupplierInvoiceModal" @created="postInvoiceCreation()" />
    </div>
</template>
<script>
import StylisedModal from '../../../../StylisedModal.vue';
import RaiseSupplierPurchaseInvoice from './RaiseSupplierPurchaseInvoice.vue';
import User from '../../../../widgets/UserDisplay.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tag from '@/js/components/Tag.vue';
import { localTimestamp } from '@/js/utils.js';

export default {
    components: {
        StylisedModal,
        RaiseSupplierPurchaseInvoice,
        User,
        FormField,
        Banner,
        DataTable,
        Tag,
    },
    emits: ['refresh'],
    data() {
        return {
            purchaseOrder: null,
        };
    },
    methods: {
        localTimestamp,
        getPurchaseOrder(eventId, purchaseOrderId) {
            axios
                .get(
                    route('api.account.event.purchaseorders.get', {
                        event: eventId,
                        purchaseOrder: purchaseOrderId,
                    })
                )
                .then(({ data }) => (this.purchaseOrder = data));
        },
        open(eventId, purchaseOrderId) {
            this.getPurchaseOrder(eventId, purchaseOrderId);
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        downloadPdf() {
            window.open(
                route('account.event.export.purchaseorders.pdf', {
                    event: this.purchaseOrder.event_id,
                    purchaseOrder: this.purchaseOrder.id,
                })
            );
        },
        raiseInvoice() {
            this.$refs.raiseSupplierInvoiceModal.open(this.purchaseOrder);
        },
        postInvoiceCreation() {
            this.close();
            this.$emit('refresh');
        },
    },
};
</script>
