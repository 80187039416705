<template>
    <!-- This table is for the Scenario drawer only -->
    <div v-if="renderComponent">
        <DataTable
            :columns="[
                {
                    title: 'Base Entry',
                    field: 'name',
                },
                {
                    title: 'Department',
                    field: 'department',
                },
                {
                    title: 'Budget',
                    field: 'budget',
                },
                {
                    title: 'Adjustment',
                    field: 'value',
                },
            ]"
            :data="adjustmentsList"
        >
            <template #column(name)="{ row }">
                {{ row.adjustmentable_type.split('\\').pop() }}
            </template>
            <template #column(department)="{ row }">
                {{ row.adjustmentable.department.name }} ({{
                    row.adjustmentable.department.type == 1 ? 'Costs' : 'Revenue'
                }})
            </template>
            <template #column(budget)="{ row }">
                <span v-if="row.adjustmentable_type === 'App\\Models\\BudgetEntry'">
                    {{ localise(row.adjustmentable.budgeted_cost, 2, true) }}
                </span>
                <span v-else-if="row.adjustmentable_type === 'App\\Models\\TicketType'">
                    {{ localise(row.adjustmentable.gross_fixed, 2, true) }}
                </span>
            </template>
            <template #column(value)="{ row }">
                <span v-if="row.adjustmentable_type === 'App\\Models\\BudgetEntry'">
                    {{ localise(parseFloat(row.adjustmentable.budgeted_cost) + parseFloat(row.value), 2, true) }}
                </span>
                <span v-else-if="row.adjustmentable_type === 'App\\Models\\TicketType'">
                    {{ localise(parseFloat(row.adjustmentable.gross_fixed) + parseFloat(row.value), 2, true) }}
                </span>
            </template>
        </DataTable>
    </div>
</template>
<script>
import DataTable from '@/js/components/tables/DataTable.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        DataTable,
    },
    props: {
        scenario: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            localise,
            isLoaded: false,
            adjustmentsList: [],
            scenarioId: null,
            item: [],
            renderComponent: true,
        };
    },
    mounted() {
        this.scenarioId = this.scenario;
        this.getAdjustments();

        Eventbus.$on('scenarioAdjustments:load', (payload) => {
            this.scenarioId = payload.scenarioId;
            this.getAdjustments();
        });

        Eventbus.$on('scenarioAdjustments:reset', () => {
            this.isLoaded = false;
            this.scenarioId = null;
            this.forceRerender();
        });
        Eventbus.$on('scenarioAdjustments:reload', () => {
            this.isLoaded = false;
            this.getAdjustments();
        });
    },
    methods: {
        getAdjustments() {
            if (!this.scenarioId) {
                return;
            }
            this.isLoaded = false;
            axios
                .get(route('api.account.event.scenarios.adjustments', { scenario: this.scenarioId }))
                .then(({ data }) => (this.adjustmentsList = data))
                .finally(() => (this.isLoaded = true));
        },
        async forceRerender() {
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
        },
    },
};
</script>
