<template>
    <DataTable
        v-if="Auth.can('manage payruns')"
        ref="table"
        :endpoint="route('api.account.event.payments.payruns.approvals')"
        centered
        :columns="[
            {
                title: 'Title',
                field: 'title',
            },
            {
                title: 'Run Value',
                field: 'value',
            },
            {
                title: 'Payment Date',
                field: 'payment_date',
            },
            {
                title: 'Created',
                field: 'created_at',
            },
            {
                title: 'Status',
                field: 'status',
            },
            {
                title: '',
                field: 'actions',
            },
        ]"
    >
        <template #column(value)="{ row }">
            {{ localise(row.items_sum_amount, 2, true) }}
        </template>
        <template #column(payment_date)="{ row }">
            {{ formatDate(row.payment_date, 'dd/MM/yyyy') }}
            <small class="text-muted">({{ timeAgo(row.payment_date) }})</small>
        </template>
        <template #column(created_at)="{ row }">
            {{ timeAgo(row.created_at) }}
        </template>
        <template #column(status)="{ row }">
            <Tag v-if="row.status" :status="row.status" />
            <span v-else>Cancelled</span>
        </template>
        <template #column(actions)="{ row }">
            <Button @click="openPayrun(row)"><i class="mdi mdi-eye"></i></Button>
        </template>
    </DataTable>
    <Banner v-else type="is-warning">
        <strong>You don't have access to view this area</strong>
        <p>If you believe that this is a mistake, please contact your account owner.</p>
    </Banner>
</template>
<script>
import Tag from '@/js/components/Tag.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { timeAgo, formatDate, setRoute } from '@/js/utils.js';

export default {
    components: {
        Button,
        Tag,
        Banner,
        DataTable,
    },
    mounted() {
        Eventbus.$on('reload:payruns', () => this.$refs.table.refreshData());
    },
    methods: {
        timeAgo,
        formatDate,
        openPayrun(payrun) {
            setRoute('account.event.purchaseorders.summary', {
                event: payrun.event_id,
                page: 'payruns',
                departmentId: payrun.id,
            });
        },
    },
};
</script>
