<template>
    <div class="setup-payment-details-wrapper">
        <div class="setup-payment-details">
            <template v-if="account.created_by === currentUser.id || Auth.can('manage subscription')">
                <h3>Payment Details Required</h3>
                <p>Your account requires that you provide valid payment details in order to continue.</p>
                <div class="setup-payment-details-form">
                    <PaymentDetailsForm
                        v-if="paymentIntent && !isLoading"
                        :account="account"
                        :intent="paymentIntent"
                        @setup="$emit('setup')"
                    />
                    <LoadingSpinner v-if="isLoading" label="Loading Information" />
                </div>
                <small>
                    By submitting this form you agree to storing these payment details securely with our third-party
                    payment provider, Stripe. No payment will be taken when you provide these details - although we may
                    check with your card issuer to ensure that they are valid. Eventwise will be in touch at a later
                    date in order to confirm the details of your payment plan before any payments are taken.
                </small>
            </template>
            <template v-else>
                <h3 class="mb-3">Account Disabled</h3>
                <Banner type="is-danger">
                    {{ account.name }} has been temporarily disabled due to a payment issue.
                    <br />
                    Please get in touch with the account owner to resolve this issue and regain access.
                </Banner>
            </template>
            <a class="d-block mt-3" href="#" @click.prevent="$emit('return')">
                {{ account.created_by === currentUser.id ? "I'll do this later" : 'Return to account selection' }}
            </a>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { useDataStore } from '@/js/stores/DataStore.js';
import PaymentDetailsForm from './PaymentDetailsForm.vue';
import LoadingSpinner from './../../widgets/LoadingSpinner.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        PaymentDetailsForm,
        LoadingSpinner,
        Banner,
    },
    props: {
        account: {
            type: Object,
            required: true,
        },
    },
    emits: ['return', 'setup'],
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            currentUser: store.get('current_user'),
            paymentIntent: null,
            store: store,
        };
    },
    mounted() {
        this.getPaymentIntent();
    },
    methods: {
        getPaymentIntent() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.billing.setup', {
                        account: this.account.id,
                    })
                )
                .then(({ data }) => (this.paymentIntent = data))
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss" scoped>
.setup-payment-details-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
    user-select: none;

    & > .setup-payment-details {
        width: min(750px, 95vw);
        padding: 25px;
        min-height: 200px;
        background: white;
        border-radius: 12px;
        text-align: center;

        .setup-payment-details-form {
            position: relative;
            min-height: 100px;
            padding: 20px;
            border-radius: 6px;
            background: rgba(black, 0.05);
            margin: 15px 0;
        }
    }
}
</style>
