<template>
    <i v-if="iconPath" :class="iconPath"></i>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            store: useDataStore(),
            iconPath: null,
        };
    },
    mounted() {
        this.iconPath = this.store.get('icons').find((icon) => icon.value === this.icon)?.path;
    },
};
</script>

<style lang="scss" scoped>
span {
    font-size: 14px;

    &.is-small {
        font-size: 12px;
    }
    &.is-large {
        font-size: 16px;
    }
    &.is-xlarge {
        font-size: 18px;
    }
}
</style>
