<template>
    <Modal ref="modal" title="Merge Contact" :loading="isLoading" size="is-large">
        <div class="d-flex justify-content-between">
            <Select v-model="targetSupplierId" placeholder="Select the Merging Supplier">
                <option v-for="supplier in targetSuppliers" :key="supplier.id" :value="supplier.id">
                    {{ supplier.name }}
                </option>
            </Select>
            <FormField>
                <Button type="is-info" :disabled="mergeDisabled" @click="mergeWarning">Merge</Button>
            </FormField>
        </div>

        <div class="mx-5 mt-3">
            <p>Merging contacts is an irreversible process. It cannot be undone.</p>
            <p>The selected contact will be archived after the process completes</p>
        </div>
    </Modal>
</template>

<script>
import Modal from '../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Select,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            endpoint: route('api.account.contacts'),

            supplierId: null,
            supplierType: 0,
            supplierName: '',
            targetSuppliers: null,
            targetSupplierId: null,

            mergeDisabled: true,
            mergeName: '',
        };
    },
    watch: {
        targetSupplierId(newVal) {
            if (newVal != null) {
                this.mergeDisabled = false;
            }
        },
    },
    methods: {
        open(payload = null) {
            this.reset();
            if (payload) {
                this.supplierId = payload.baseSupplier.id;
                this.supplierType = payload.baseSupplier.supplier_type;
                this.supplierName = payload.baseSupplier.name;
                if (this.supplierType > 0) {
                    this.endpoint =
                        this.supplierType === 1
                            ? route('api.account.contacts.tickets')
                            : route('api.account.contacts.traders');
                }
            }
            this.$refs.modal.open();
            this.reloadData();
        },
        reloadData() {
            this.isLoading = true;
            axios
                .get(this.endpoint)
                .then(({ data }) => {
                    this.targetSuppliers = data;
                    this.filterSuppliers();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        filterSuppliers() {
            /* Restrictions:
            Cannot merge contacts if either is archived (false === not Archived)
            Cannot merge system created contacts (false ==== not system
            Cannot merge if invited to Supplier Portal (is_invited values 1-2)
            Can only merge same supplier types
            Cannot merge contacts if either has Xero Contact Id
            Cannot merge Contact with itself
             */
            const filteredSuppliers = this.targetSuppliers.filter(
                (supplier) =>
                    !supplier.is_archived &&
                    !supplier.is_system &&
                    supplier.is_invited === 0 &&
                    supplier.supplier_type === this.supplierType &&
                    !supplier.xero_contact_id &&
                    supplier.id !== this.supplierId
            );
            this.targetSuppliers = [...filteredSuppliers];
        },
        reset() {
            this.supplierType = 0;
            this.supplierId = null;
            this.targetSuppliers = null;
        },
        close() {
            this.$refs.modal.close();
        },
        mergeContacts() {
            axios
                .post(
                    route('api.account.contacts.merge', {
                        supplier: this.targetSupplierId,
                        id: this.supplierId,
                    })
                )
                .then(({ status }) => {
                    this.closeModal(status);
                });
        },
        async mergeWarning() {
            this.mergeName = this.targetSuppliers.filter((supplier) => supplier.id === this.targetSupplierId)[0].name;
            const { value: confirmation } = await swal.fire({
                type: 'warning',
                html:
                    'Are you sure ?<br> This operation is irreversible. Transactions from <strong>' +
                    this.mergeName +
                    '</strong> will be transferred to <strong>' +
                    this.supplierName +
                    '</strong>',
                title: 'Merging Suppliers',
                showCancelButton: true,
            });
            if (!confirmation) {
                return;
            }
            this.mergeContacts();
        },
        closeModal(code) {
            this.close();
            if (code === 200) {
                toast('Supplier Merged', this.mergeName + ' successfully merged.', 'success');
            } else {
                toast('Supplier Merge failed', 'The merge operation failed.', 'success');
            }
        },
    },
};
</script>
