<template>
    <section id="sidebar" class="pt-4">
        <nav class="mt-3">
            <a href="#" title="Dashboard" :class="{ active: !currentPage }" @click.prevent="setPage()">
                <i class="mdi mdi-view-dashboard"></i>
            </a>
            <a
                href="#"
                title="Accounts"
                :class="{ active: currentPage === 'accounts' }"
                @click.prevent="setPage('accounts')"
            >
                <i class="mdi mdi-domain"></i>
            </a>
            <a href="#" title="Events" :class="{ active: currentPage === 'events' }" @click.prevent="setPage('events')">
                <i class="mdi mdi-calendar-blank-multiple"></i>
            </a>
            <a href="#" title="Users" :class="{ active: currentPage === 'users' }" @click.prevent="setPage('users')">
                <i class="mdi mdi-account-group"></i>
            </a>
            <a
                href="#"
                title="Help Articles"
                :class="{ active: currentPage === 'help' }"
                @click.prevent="setPage('help')"
            >
                <i class="mdi mdi-lifebuoy"></i>
            </a>
            <a
                v-if="inviteOnly"
                href="#"
                title="Invite Codes"
                :class="{ active: currentPage === 'invites' }"
                @click.prevent="setPage('invites')"
            >
                <i class="mdi mdi-tag"></i>
            </a>
            <a
                v-if="isSynergiUser"
                href="#"
                title="Settings"
                :class="{ active: currentPage === 'settings' }"
                @click.prevent="setPage('settings')"
            >
                <i class="mdi mdi-cog"></i>
            </a>
            <a v-if="currentUser" href="#" class="mt-auto" :title="currentUser.name">
                <User :user="currentUser" :hide-name="true" />
            </a>
            <a :href="route('dashboard')" title="Return to Eventwise"><i class="mdi mdi-close"></i></a>
        </nav>
    </section>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import User from '../../widgets/User.vue';

export default {
    components: {
        User,
    },
    props: {
        page: {
            type: String,
            required: false,
        },
    },
    emits: ['set-page'],
    data() {
        const store = useDataStore();
        const inviteOnly = window.Eventwise.invite_only;
        return {
            currentPage: null,
            currentUser: store.get('current_user'),
            store: store,
            inviteOnly,
        };
    },
    computed: {
        isSynergiUser() {
            return this.currentUser && this.currentUser.email.endsWith('@synergitech.co.uk');
        },
    },
    watch: {
        page(newVal) {
            this.currentPage = newVal ? newVal : null;
        },
    },
    mounted() {
        this.currentPage = this.page ? this.page : null;
    },
    methods: {
        setPage(page = null) {
            this.$emit('set-page', page);
        },
    },
};
</script>
