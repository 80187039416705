<template>
    <Modal ref="modal" title="Two-Factor Authentication" size="is-xlarge" :loading="isLoading" @closed="reset()">
        You can increase the security of your account by enabling two-factor authentication.
        <br />
        If enabled you will be required to provide a code each time you login to your account.
        <template v-if="!currentUser.mfa_enabled">
            <div class="row mt-5 mb-2 ml-4">
                <div class="col-2 text-center">
                    <Tag type="warning">
                        <div class="d-flex align-items-center"><i class="mdi mdi-download-box font-20"></i></div>
                    </Tag>
                </div>
                <div class="col-10">
                    Download or open a supported authenticator app
                    <div class="text-muted">We recommend using Google Authenticator or Twilio Authy</div>
                </div>
                <div class="col-2 mt-4 text-center">
                    <Tag type="info">
                        <div class="d-flex align-items-center"><i class="mdi mdi-camera-outline font-20"></i></div>
                    </Tag>
                </div>
                <div class="col-10 mt-4">
                    Add Eventwise to your authenticator by scanning the QR code
                    <template v-if="setupInfo">
                        <div class="text-muted">
                            If you're unable to scan the code you can enter
                            <span class="badge badge-info">{{ setupInfo.secret_key }}</span>
                        </div>
                        <div v-html="setupInfo.qr_image"></div>
                    </template>
                </div>
                <div class="col-2 mt-4 text-center">
                    <Tag type="danger">
                        <div class="d-flex align-items-center"><i class="mdi mdi-numeric font-20"></i></div>
                    </Tag>
                </div>
                <div class="col-10 mt-4">
                    Enter the 6-digit verification code displayed in your authenticator
                    <div class="text-muted">The code changes every 30 seconds</div>
                    <div class="d-flex mt-2 pr-5">
                        <Textbox
                            v-model="verificationCode"
                            placeholder="Verification Code"
                            maxlength="6"
                            class="tw-flex-1"
                        />
                        <Button
                            class="tw-ml-3"
                            type="is-success"
                            :loading="isLoading"
                            :disabled="!verificationCode || verificationCode.length !== 6"
                            @click="verifyCode()"
                        >
                            Verify Code
                        </Button>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <Banner class="tw-mt-3 tw-mb-2" has-icon type="is-success" :closable="false">
                <strong>Great stuff!</strong>
                <br />
                Two-factor authentication is already enabled for your account
            </Banner>
            <div class="d-flex align-items-center p-3 bg-light rounded">
                <div class="flex-fill pr-3">
                    <h6>Disable Two-Factor Authentication</h6>
                    <small class="text-muted">
                        2FA is the best way to secure your account and it is not recommended that you disable it unless
                        absolutely necessary
                    </small>
                </div>
                <Button type="is-danger" @click="disable2fa()">Disable</Button>
            </div>
        </template>
    </Modal>
</template>
<script>
import axios from 'axios';
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tag from '@/js/components/Tag.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        Textbox,
        Tag,
        Banner,
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            store: store,
            currentUser: store.get('current_user'),
            verificationCode: null,
            setupInfo: null,
        };
    },
    computed: {
        isValid() {
            return true;
        },
    },
    methods: {
        open() {
            if (!this.currentUser.mfa_enabled) {
                this.getSetupInfo();
            }
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.setupInfo = null;
            this.verificationCode = null;
            this.isLoading = false;
        },
        getSetupInfo() {
            this.isLoading = true;
            axios
                .get(route('api.user.mfa.setup'))
                .then(({ data }) => (this.setupInfo = data))
                .finally(() => (this.isLoading = false));
        },
        verifyCode() {
            this.isLoading = true;
            axios
                .post(route('api.user.mfa.verify'), {
                    code: this.verificationCode,
                })
                .then(() => {
                    this.close();
                    this.store.set('current_user.mfa_enabled', true);
                    toast(
                        '2FA Enabled',
                        'Two-factor authentication has been enabled successfully for your acccount',
                        'success'
                    );
                })
                .finally(() => (this.isLoading = false));
        },
        disable2fa() {
            this.isLoading = true;
            axios
                .post(route('api.user.mfa.disable'))
                .then(() => {
                    this.close();
                    this.store.set('current_user.mfa_enabled', false);
                    toast(
                        '2FA Disabled',
                        'Two-factor authentication has been disabled successfully for your account',
                        'danger'
                    );
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
