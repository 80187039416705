<template>
    <Dropdown>
        <template #trigger>
            <Button type="is-link" class="tw-text-xs !tw-p-0">
                <span>
                    {{
                        currentTaxRate && currentTaxRate.id
                            ? currentTaxRate[compact ? 'compact_name' : 'display_name']
                            : 'None'
                    }}
                </span>
            </Button>
        </template>
        <div
            v-if="isConnectedToXero"
            class="tw-flex tw-gap-2 tw-text-xs tw-px-2 tw-pt-2"
            :class="{
                'tw-text-cyan-500': !showNonXeroRates,
                'tw-text-orange-500': showNonXeroRates,
            }"
        >
            <div class="tw-flex-1">
                <div v-if="showNonXeroRates" class="tw-leading-3">
                    Showing non-Xero linked tax rates.
                    <br />
                    These rates will not be synced with Xero.
                </div>
                <div v-else>You're linked with Xero!</div>
            </div>
            <Button
                type="is-link"
                class="tw-mb-0 tw-text-xs tw-text-blue-700 tw-underline tw-border-0 !tw-p-0"
                @click="showNonXeroRates = !showNonXeroRates"
            >
                {{ showNonXeroRates ? 'Return' : 'Show other rates' }}
            </Button>
        </div>

        <div class="tw-flex tw-flex-col tw-py-1">
            <div
                v-for="taxRate in availableTaxRates"
                :key="taxRate.id"
                class="tw-py-1 tw-px-2 tw-my-[1px] tw-mx-1 tw-rounded tw-cursor-pointer hover:tw-bg-gray-100"
                :class="{
                    'tw-bg-eventwise tw-text-white hover:!tw-bg-eventwise': taxRate.id === model,
                }"
            >
                <div class="tw-flex tw-flex-row tw-hover:cursor-pointer" @click="model = taxRate.id">
                    <img
                        v-if="isConnectedToXero && taxRate.xero_tax_rate_id && taxRate.xero_tax_type"
                        src="@/images/brands/xero.svg"
                        class="icon-xs mr-2"
                    />
                    <span class="tw-text-xs">{{ taxRate.name }}</span>
                </div>
            </div>
        </div>
    </Dropdown>
</template>

<script setup>
import Dropdown from '@/js/components/controls/Dropdown.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { computed, ref } from 'vue';
import Button from '@/js/components/controls/Button.vue';

const store = useDataStore();
const props = defineProps({
    type: {
        type: String,
        default: null,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    compact: {
        type: Boolean,
        default: false,
    },
    nullable: {
        type: Boolean,
        default: false,
    },
});

// model is the tax_rate_id
const model = defineModel({
    type: [Number, String],
    default: null,
});

const isConnectedToXero = computed(() => store.get('account.is_connected_to_xero'));
const showNonXeroRates = ref(!isConnectedToXero.value);

const availableTaxRates = computed(() => {
    return [props.nullable ? { id: null, name: 'None' } : null]
        .concat(
            Object.values(store.get('account.tax_rates')).filter(
                // Hide archived tax rates and filter by "show non-xero linked tax rates option" if connected to xero
                (taxRate) =>
                    !taxRate.is_archived &&
                    (props.type ? taxRate.type === props.type : true) &&
                    (isConnectedToXero.value
                        ? showNonXeroRates.value
                            ? !taxRate.xero_tax_rate_id && !taxRate.xero_tax_type
                            : taxRate.xero_tax_rate_id && taxRate.xero_tax_type
                        : true)
            )
        )
        .filter((t) => t);
});

const currentTaxRate = computed(() => {
    if (!model.value) {
        return null;
    }
    return store.get(`account.tax_rates.${model.value}`);
});
</script>
