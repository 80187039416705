<template>
    <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center">
        <template v-if="statusOnly || locked">
            <Tag :status="approvables[0].status" />
        </template>

        <template v-else-if="bulkControls">
            <Button v-if="!hideRequest" type="is-info" :disabled="processing" @click="requestAction('request')">
                Request Approval
            </Button>
            <Button v-if="!hideActions" type="is-success" :disabled="processing" @click="requestAction('approve')">
                Approve
            </Button>
            <Button v-if="!hideActions" type="is-danger" :disabled="processing" @click="requestAction('reject')">
                Reject
            </Button>
            <Button v-if="!hideRequest" type="is-info" :disabled="processing" @click="requestAction('reset')">
                Reset To Draft
            </Button>
        </template>

        <template v-else>
            <template v-if="approvables[0].has_outstanding_approval">
                <template v-if="approvables[0].current_user_can_approve && !locked">
                    <Button type="is-success" :disabled="processing" @click="requestAction('approve')">Approve</Button>
                    <Button type="is-danger" :disabled="processing" @click="requestAction('reject')">Reject</Button>
                </template>

                <template v-else>
                    <span class="tag is-warning is-light">
                        Awaiting {{ approvables[0].has_approval_history ? 'Reapproval' : 'Approval' }}
                    </span>
                </template>

                <Button type="is-info" :disabled="processing" @click="requestAction('reset')">Reset To Draft</Button>
            </template>

            <template v-else>
                <template v-if="approvables[0].is_approved">
                    <Tag :status="approvables[0].status" />
                    <Button v-if="!locked" type="is-info" :disabled="processing" @click="requestAction('reset')">
                        Reset To Draft
                    </Button>
                </template>

                <template v-else-if="approvables[0].is_rejected">
                    <Tag :status="approvables[0].status" />
                    <template v-if="!locked">
                        <Button type="is-info" :disabled="processing" @click="requestAction('request')">
                            Request Reapproval
                        </Button>
                        <Button type="is-info" :disabled="processing" @click="requestAction('reset')">
                            Reset To Draft
                        </Button>
                    </template>
                </template>

                <template v-else>
                    <Tag status="Draft" />
                    <Button type="is-info" :disabled="disableRequest || processing" @click="requestAction('request')">
                        Request Approval
                    </Button>
                </template>
            </template>
        </template>

        <slot />

        <Modal
            ref="commentsModal"
            :title="'Add ' + action.charAt(0).toUpperCase() + action.slice(1) + ' Comments'"
            @closed="resetCommentsModal"
        >
            <FormField label="Comments">
                <Textbox v-model="comments" type="textarea" maxlength="255" class="tw-border-gray-600" />
            </FormField>

            <template #footer>
                <Button class="tw-ml-auto" @click="resetCommentsModal">Cancel</Button>
                <Button type="is-primary" :disabled="!comments" @click="postAction">Submit</Button>
            </template>
        </Modal>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Modal from '@/js/components/Modal.vue';
import Tag from '@/js/components/Tag.vue';

export default {
    components: {
        Button,
        Textbox,
        FormField,
        Modal,
        Tag,
    },
    props: {
        approvables: {
            type: [Array, Object],
            required: true,
        },
        bulkControls: {
            type: Boolean,
            default: false,
        },
        disableRequest: {
            type: Boolean,
            default: false,
        },
        locked: {
            type: Boolean,
            default: false,
        },
        hideRequest: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        statusOnly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reload'],
    data() {
        return {
            action: '',
            comments: '',
            processing: false,
        };
    },
    methods: {
        requestAction(action) {
            this.processing = true;
            this.action = action;
            if (this.isCommentable()) {
                this.showCommentsModal();
                return;
            }

            this.postAction();
        },
        showCommentsModal() {
            this.$refs.commentsModal.open();
        },
        resetCommentsModal() {
            this.$refs.commentsModal.close();
            this.comments = '';
            this.action = '';
            this.processing = false;
        },
        actionUrl() {
            switch (this.action) {
                case 'approve':
                    return route('api.account.approvals.approve');
                case 'reject':
                    return route('api.account.approvals.reject');
                case 'reset':
                    return route('api.account.approvals.reset');
                case 'request':
                    return route('api.account.approvals.request');
            }
        },
        postAction() {
            axios
                .post(this.actionUrl(), {
                    approvables: this.approvables,
                    comments: this.comments,
                })
                .then(() => {
                    this.reloadApprovable();
                    this.resetCommentsModal();
                });
        },
        isCommentable() {
            const commentableActions = ['reject', 'reset'];

            return commentableActions.includes(this.action);
        },
        reloadApprovable() {
            this.$emit('reload');
            switch (this.approvables[0].approvable_type) {
                case 'App\\Models\\BudgetEntry':
                    if (this.approvables.length === 1 && !this.bulkControls) {
                        Eventbus.$emit('budget:entry', { entry: this.approvables[0].id });
                    }

                    Eventbus.$emit('reload:entries');
                    break;
                case 'App\\Models\\PurchaseOrder':
                    if (this.approvables.length === 1 && !this.bulkControls) {
                        Eventbus.$emit('purchaseOrder:reloadLineItems');
                    }

                    Eventbus.$emit('reload:purchaseorders');
                    break;
                case 'App\\Models\\Payrun':
                    if (this.approvables.length === 1 && !this.bulkControls) {
                        Eventbus.$emit('reload:payrun');
                    }

                    Eventbus.$emit('reload:payruns');
                    break;
            }

            Eventbus.$emit('refresh:approvals');

            setTimeout(() => {
                this.processing = false;
            }, 300);
        },
    },
};
</script>
