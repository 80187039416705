<template>
    <div class="welcome-container buefy-wrapper">
        <div class="backdrop"></div>
        <div class="welcome-modal">
            <LoadingSpinner v-if="isLoading" label="Loading Information" />
            <h3>🎉 &nbsp; Welcome to Eventwise{{ user ? ', ' + user.name : '' }}</h3>
            <template v-if="!isOnlySupplier">
                <p>Get ready to streamline the financial management of your events with our all-in-one platform.</p>
                <div class="step-view">
                    <div class="step">
                        <i class="mdi mdi-domain"></i>
                        <h4>Setup your organisation</h4>
                        <p>
                            Provide some basic details about your organisation to continue and create your first event.
                        </p>
                    </div>
                    <div class="step">
                        <i class="mdi mdi-calendar-plus"></i>
                        <h4>Create your first event</h4>
                        <p>Complete your event details to setup your first revenue streams and cost departments.</p>
                    </div>
                    <div class="step">
                        <i class="mdi mdi-shimmer"></i>
                        <h4>Get started with Eventwise</h4>
                        <p>
                            Once you&apos;ve created your first event you can setup your tickets and create your first
                            budget.
                        </p>
                    </div>
                </div>
            </template>
            <template v-else>
                <p>
                    Get ready to streamline the supplier management process and view all purchase orders, invoices and
                    payments.
                </p>
                <div class="step-view mb-2">
                    <div class="step">
                        <i class="mdi mdi-invoice-text-multiple-outline"></i>
                        <h4>Purchase Orders</h4>
                        <p>See and download all of your purchase orders and any associated terms in one place.</p>
                    </div>
                    <div class="step">
                        <i class="mdi mdi-calendar-plus"></i>
                        <h4>Add Invoices</h4>
                        <p>
                            Submit your own invoices against purchase orders, delivered directly to the event
                            organisers.
                        </p>
                    </div>
                    <div class="step">
                        <i class="mdi mdi-calendar-plus"></i>
                        <h4>Track Payments</h4>
                        <p>View any payments associated with your invoices and when they have been paid.</p>
                    </div>
                </div>
                <p v-if="isOnlySupplier">
                    If you want to see how Eventwise can benefit your company please get in touch with us at
                    <a href="mailto:info@eventwise.com">info@eventwise.com</a>
                    to book a demo.
                </p>
            </template>
            <Checkbox v-model="hasAgreed">
                I agree to the
                <a :href="route('api.onboarding.terms')" target="_blank">Terms and Conditions</a>
                and
                <a :href="route('api.onboarding.terms.privacy')" target="_blank">Privacy Policy</a>
            </Checkbox>
            <Button type="is-primary" :disabled="!hasAgreed" @click="acceptTerms()">
                {{
                    availableAccounts.length
                        ? isOnlySupplier
                            ? 'Take a quick tour to get started'
                            : 'Continue to Eventwise'
                        : 'Create your organisation'
                }}
            </Button>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import LoadingSpinner from '../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';

export default {
    components: {
        LoadingSpinner,
        Button,
        Checkbox,
    },
    emits: ['accepted'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            user: store.get('current_user'),
            hasAgreed: false,
            store: store,
            availableAccounts: store.get('available_accounts'),
        };
    },
    computed: {
        isOnlySupplier() {
            if (!this.availableAccounts.length) return false;
            return this.availableAccounts.filter((a) => a.pivot.is_supplier).length === this.availableAccounts.length;
        },
    },
    methods: {
        acceptTerms() {
            this.isLoading = true;
            axios.post(route('api.onboarding.terms.accept')).then(() => {
                this.$emit('accepted');
                this.store.set('current_user.accepted_terms', true);
                if (this.isOnlySupplier) {
                    Storylane.Play({
                        type: 'popup',
                        demo_type: 'image',
                        width: 1920,
                        height: 968,
                        scale: '0.95',
                        demo_url: 'https://eventwise.storylane.io/demo/l3tlbzmx0jkf',
                        padding_bottom: 'calc(50.42% + 27px)',
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.welcome-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    & > .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black, 0.33);
        z-index: -1;
    }

    & > .welcome-modal {
        width: min(800px, 90%);
        height: auto;
        background: white;
        padding: 40px;
        border-radius: 10px;
        text-align: center;
        box-shadow: 0 0 10px rgba(black, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > p {
            margin: 10px 0 25px 0;
            max-width: 500px;
            opacity: 0.8;
        }

        & > .step-view {
            display: flex;
            width: 100%;
            margin-bottom: 40px;

            & > .step {
                background: rgb(235, 235, 235);
                flex: 1;
                margin: 2px;
                border-radius: 5px;
                min-height: 120px;
                padding: 20px 25px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                text-align: left;
                position: relative;

                & > i {
                    font-size: 32px;
                    line-height: 32px;
                    position: absolute;
                    top: 23px;
                    right: 20px;
                    opacity: 0.33;
                }

                & > h4 {
                    margin-bottom: 10px;
                    padding-right: 25px;
                }

                & > p {
                    font-size: 0.95em;
                }

                &:first-child {
                    padding-left: 15px;
                }
                &:not(:first-child)::before {
                    content: '';
                    position: absolute;
                    top: calc(50% - 24px);
                    left: 0;
                    width: 0;
                    height: 0;
                    border-top: 24px solid transparent;
                    border-bottom: 24px solid transparent;
                    border-left: 14px solid white;
                    z-index: 9;
                }
                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    top: calc(50% - 25px);
                    right: -14px;
                    width: 0;
                    height: 0;
                    border-top: 25px solid transparent;
                    border-bottom: 25px solid transparent;
                    border-left: 15px solid rgb(235, 235, 235);
                    z-index: 10;
                }
            }

            @media (max-width: 800px) {
                flex-direction: column;

                & > .step {
                    &:before {
                        display: none;
                    }
                    &:after {
                        display: none;
                    }

                    & > i {
                        top: 10px;
                        right: 10px;
                    }
                }
            }
        }

        & > .button {
            margin-top: 10px;
        }
    }
}

.sl-preview-cta:hover .sl-preview-cta-ripple {
    transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    inset: -0.75em !important;
    opacity: 0 !important;
}
.sl-preview-cta:hover .sl-preview-cta-ripple-shadow {
    opacity: 0.125 !important;
}
</style>
