<template>
    <ew-card title="Scenario Profit Overview" paddingless :expanded="true" :loading="isLoading" :max-height="400">
        <div class="block-table buefy-wrapper position-relative" style="height: 300px; padding-bottom: 35px">
            <div class="block-table-row">
                <div class="heading-y" style="flex-grow: 1"></div>
                <div class="heading-x">Budgeted</div>

                <div v-for="scenario in chartData.scenarios" :key="scenario.id" class="heading-x">
                    {{ scenario.name }}
                </div>
            </div>

            <!-- Revenue Row -->
            <div class="block-table-row">
                <div class="heading-y" style="flex-grow: 1">Revenue</div>
                <p class="data-cell">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{ negative: chartData['budgetedRevenue'] < 0 }"
                    >
                        {{ localise(chartData['budgetedRevenue'], 2, true) }}
                    </span>
                </p>
                <p
                    v-for="scenario in chartData.scenarios"
                    :key="scenario.id"
                    class="data-cell"
                    style="line-height: 100%"
                >
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{
                            negative: chartData['budgetedRevenue'] + scenario.total_revenue_adjustment_value < 0,
                        }"
                    >
                        {{ localise(chartData['budgetedRevenue'] + scenario.total_revenue_adjustment_value, 2, true) }}
                        <br />
                        <small
                            style="font-weight: 500"
                            :class="{
                                'text-danger': scenario.total_revenue_adjustment_value < 0,
                                'favourable-value': scenario.total_revenue_adjustment_value > 0,
                            }"
                        >
                            {{
                                (scenario.total_revenue_adjustment_value > 0 ? '+' : '') +
                                localise(scenario.total_revenue_adjustment_value, 2, true)
                            }}
                        </small>
                    </span>
                </p>
            </div>

            <!-- Costs Row -->
            <div class="block-table-row">
                <div class="heading-y" style="flex-grow: 1">Costs</div>
                <p class="data-cell">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{ negative: chartData['budgetedCosts'] < 0 }"
                    >
                        {{ localise(chartData['budgetedCosts'], 2, true) }}
                    </span>
                </p>
                <p
                    v-for="scenario in chartData.scenarios"
                    :key="scenario.id"
                    class="data-cell"
                    style="line-height: 100%"
                >
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{ negative: chartData['budgetedCosts'] + scenario.total_cost_adjustment_value < 0 }"
                    >
                        {{ localise(chartData['budgetedCosts'] + scenario.total_cost_adjustment_value, 2, true) }}
                        <br />
                        <small
                            style="font-weight: 500"
                            :class="{
                                'text-danger': scenario.total_cost_adjustment_value > 0,
                                'favourable-value': scenario.total_cost_adjustment_value < 0,
                            }"
                        >
                            {{
                                (scenario.total_cost_adjustment_value > 0 ? '+' : '') +
                                localise(scenario.total_cost_adjustment_value, 2, true)
                            }}
                        </small>
                    </span>
                </p>
            </div>

            <!-- Profits Row -->
            <div class="block-table-row">
                <div class="heading-y" style="flex-grow: 1">Profit</div>
                <p class="data-cell">
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{
                            negative: chartData['budgetedRevenue'] - chartData['budgetedCosts'] < 0,
                        }"
                    >
                        {{ localise(chartData['budgetedRevenue'] - chartData['budgetedCosts'], 2, true) }}
                    </span>
                </p>
                <p
                    v-for="scenario in chartData.scenarios"
                    :key="scenario.id"
                    class="data-cell"
                    style="line-height: 100%"
                >
                    <span
                        v-if="!isLoading"
                        class="profit-overview-accounting"
                        :class="{
                            negative:
                                chartData['budgetedRevenue'] +
                                    scenario.total_revenue_adjustment_value -
                                    (chartData['budgetedCosts'] + scenario.total_cost_adjustment_value) <
                                0,
                        }"
                    >
                        {{
                            localise(
                                chartData['budgetedRevenue'] +
                                    scenario.total_revenue_adjustment_value -
                                    (chartData['budgetedCosts'] + scenario.total_cost_adjustment_value),
                                2,
                                true
                            )
                        }}
                        <br />
                        <small
                            style="font-weight: 500"
                            :class="{
                                'text-danger':
                                    scenario.total_revenue_adjustment_value - scenario.total_cost_adjustment_value < 0,
                                'favourable-value':
                                    scenario.total_revenue_adjustment_value - scenario.total_cost_adjustment_value > 0,
                            }"
                        >
                            {{
                                (scenario.total_revenue_adjustment_value - scenario.total_cost_adjustment_value > 0
                                    ? '+'
                                    : '') +
                                localise(
                                    scenario.total_revenue_adjustment_value - scenario.total_cost_adjustment_value,
                                    2,
                                    true
                                )
                            }}
                        </small>
                    </span>
                </p>
            </div>
        </div>
    </ew-card>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';

export default {
    data() {
        const store = useDataStore();
        return {
            isLoading: true,
            chartData: [],
            store: store,
        };
    },
    mounted() {
        this.reloadData();
        Eventbus.$on('forceUpdate:scenario-profit-overview', () => this.reloadData());
    },
    methods: {
        reloadData() {
            this.isLoading = true;
            axios
                .get(route('api.account.event.scenarios.overview-data'))
                .then(({ data }) => (this.chartData = data))
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>

<style lang="scss" scoped>
.favourable-value {
    color: #28a745 !important;
}
</style>
