<template>
    <div class="tw-grid">
        <div class="tw-flex tw-justify-between tw-items-center tw-px-5">
            <h3>Department Analysis</h3>
            <FormField label="Comparing">
                <Select v-model="displayedField">
                    <option value="budgeted_amount">Budgeted Amount</option>
                    <option value="fixed_amount">Fixed Amount</option>
                    <option value="total_invoiced">Total Invoiced</option>
                    <option value="total_credit">Total Credit Noted</option>
                    <option value="total_paid">Total Paid</option>
                </Select>
            </FormField>
        </div>
        <DataTable
            v-for="dept of [
                {
                    title: 'Revenue Departments',
                    type: 2,
                },
                {
                    title: 'Cost Departments',
                    type: 1,
                },
            ]"
            :key="dept.type"
            class="tw-mt-5"
            :columns="[
                {
                    title: dept.title,
                    field: 'name',
                    width: 500,
                },
                ...events.map((event) => ({
                    title: event.name,
                    field: `event_${event.id}.sum`,
                    width: 200,
                })),
            ]"
            :data="departments.filter((d) => d.type === dept.type)"
            row-class="tw-cursor-pointer hover:tw-bg-gray-50"
            @select="expandRow"
        >
            <template #column(name)="{ row }">
                <i
                    class="mdi tw-text-eventwise"
                    :class="expandedRows.includes(row.id) ? 'mdi-chevron-down' : 'mdi-chevron-right'"
                ></i>
                {{ row.name }}
            </template>
            <template #row-append="{ row }">
                <tr
                    v-for="category in row.categories"
                    v-show="expandedRows.includes(row.id)"
                    :key="category.id"
                    class="tw-bg-slate-50"
                >
                    <td class="!tw-border-0 tw-bg-grey-500">{{ category.name }}</td>
                    <td v-for="event in eventData" :key="event.id" class="!tw-border-0 tw-bg-grey-500">
                        {{ category[`event_${event.id}.sum`] }}
                    </td>
                </tr>
            </template>
        </DataTable>
    </div>
</template>
<script>
import DataTable from '@/js/components/tables/DataTable.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import { uniqueArray } from '@/js/utils.js';
import { formatCurrency } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        FormField,
        Select,
    },
    props: {
        events: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            eventData: [],
            departments: [],
            expandedRows: [],
            displayedField: 'budgeted_amount',
        };
    },
    computed: {},
    mounted() {
        this.reloadData();
    },
    methods: {
        formatCurrency,
        uniqueArray,
        async reloadData() {
            this.isLoading = true;
            const {
                data: { events, departments },
            } = await axios.get(
                route('api.account.reporting.multiEventAnalysis', {
                    events: this.events.map((e) => e.id),
                })
            );

            this.isLoading = false;
            this.eventData = events;

            this.departments = departments;
            for (const event of this.eventData) {
                for (const department of this.departments) {
                    department[`event_${event.id}.sum`] = formatCurrency(
                        event.categories
                            .filter((c) => c.department_id === department.id)
                            ?.reduce((a, b) => a + b[this.displayedField], 0) ?? 0
                    );
                    for (const category of department.categories) {
                        const eventCat = event.categories.find((c) => c.id === category.id);
                        category[`event_${event.id}.sum`] = eventCat
                            ? formatCurrency(eventCat[this.displayedField])
                            : 'Not present';
                    }
                }
            }
        },
        expandRow(row) {
            if (this.expandedRows.includes(row.id)) {
                this.expandedRows = this.expandedRows.filter((id) => id !== row.id);
                return;
            }
            this.expandedRows.push(row.id);
        },
    },
};
</script>
