<template>
    <Modal
        ref="modal"
        :title="(adjustment.id ? 'Update' : 'Create') + ' Scenario Adjustment'"
        :loading="isLoading"
        size="is-large"
        @closed="close"
    >
        <div class="display-row">
            <div class="mt-2">
                <FormField label="Scenario">
                    <Select v-model="adjustment.scenario_id" placeholder="Select a Scenario" expanded>
                        <option
                            v-for="option in availableScenarios"
                            :key="'scenarios_option_' + option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </Select>
                </FormField>
            </div>
        </div>

        <div class="d-flex flex-row d-flex">
            <div class="mt-2">
                <!-- Financial Figures -->
                <div class="d-flex flex-row d-flex">
                    <FormField class="pr-1" label="Current Value">
                        <Currency :model-value="entry.budgeted_cost" disabled />
                    </FormField>
                    <FormField label="Adjustment Value">
                        <Currency v-model="adjustment.value" />
                    </FormField>
                    <FormField class="tw-pl-1" label="Scenario Value">
                        <Currency
                            :model-value="
                                parseFloat(entry.budgeted_cost) +
                                parseFloat(isNaN(adjustment.value) ? '0' : adjustment.value)
                            "
                            disabled
                        />
                    </FormField>
                </div>
            </div>
        </div>

        <div v-if="adjustment.user" class="d-flex flex-row ml-1 d-flex">
            <FormField label="Created By" class="d-xl-block tw-mb-0">
                <User :user="adjustment.user" :hide-name="false" />
            </FormField>
        </div>

        <template #footer>
            <Button label="Cancel" class="!tw-mr-auto" @click="close">Cancel</Button>
            <Button v-if="adjustment.id" :disabled="isLoading" type="is-danger" @click="deleteAdjustment">
                Delete
            </Button>
            <Button :disabled="isLoading" class="tw-ml-2" type="is-primary" @click="saveAdjustment">
                {{ adjustment.id ? 'Update' : 'Create' }}
            </Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import { toast } from '@/js/utils.js';
import Select from '@/js/components/controls/Select.vue';
import Currency from '@/js/components/controls/Currency.vue';
import User from '@/js/components/widgets/User.vue';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Select,
        User,
        Currency,
    },
    props: {
        entry: {
            type: Object,
            default() {
                return {};
            },
        },
        adjustments: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            scenarios: store.get('current_event.scenarios'),
            adjustment: {},
        };
    },
    computed: {
        availableScenarios() {
            let with_adjustments =
                this.adjustments.map((adj) => {
                    return adj.scenario_id;
                }) ?? [];

            return Object.values(this.scenarios).filter(
                (scenario) => this.adjustment.scenario_id === scenario.id || !with_adjustments.includes(scenario.id)
            );
        },
    },
    methods: {
        open(adjustment = {}) {
            this.adjustment = adjustment;

            if (!this.adjustment.scenario_id) {
                let available_scenario = this.availableScenarios.length === 1 ? this.availableScenarios[0].id : null;

                this.adjustment.scenario_id = this.store.get('current_scenario') ?? available_scenario;
            }

            this.$refs.modal.open();
        },
        close() {
            this.adjustment = {};
            this.isLoading = false;
            this.$refs.modal.close();
        },
        saveAdjustment() {
            this.isLoading = true;
            axios({
                method: this.adjustment.id ? 'patch' : 'post',
                url: this.adjustment.id
                    ? route('api.account.event.entries.adjustments.update', {
                          entry: this.entry,
                          adjustment: this.adjustment.id,
                      })
                    : route('api.account.event.entries.adjustments.create', {
                          entry: this.entry,
                      }),
                data: {
                    scenario_id: this.adjustment.scenario_id,
                    value: this.adjustment.value,
                },
            })
                .then(() => {
                    toast(
                        'Success',
                        `Your scenario adjustment has been ${this.adjustment.id ? 'updated' : 'created'}`,
                        'success'
                    );
                    Eventbus.$emit('reload:adjustmentsTab');
                    Eventbus.$emit('reload:entries');
                    Eventbus.$emit('profit-overview:reload');
                    Eventbus.$emit('reload:charts');
                    Eventbus.$emit('reload:scenariosTable');
                    Eventbus.$emit('scenarioAdjustments:reload');
                    this.close();
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        deleteAdjustment() {
            axios
                .delete(
                    route('api.account.event.entries.adjustments.delete', {
                        entry: this.entry,
                        adjustment: this.adjustment.id,
                    })
                )
                .then(() => {
                    toast('Success', 'Your scenario adjustment has been deleted', 'success');
                    Eventbus.$emit('reload:adjustmentsTab');
                    Eventbus.$emit('reload:entries');
                    Eventbus.$emit('profit-overview:reload');
                    Eventbus.$emit('reload:charts');
                    Eventbus.$emit('scenarioAdjustments:reload');
                    this.close();
                });
        },
    },
};
</script>
