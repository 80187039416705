<template>
    <div class="h-100">
        <div class="buefy-wrapper h-100 position-relative mb-2">
            <LoadingSpinner v-if="isLoading" label="Loading Information" />
            <div class="d-flex h-100">
                <div v-if="filterable" class="table-filters" :class="{ open: filtersOpen }">
                    <div class="d-flex h-100">
                        <div class="pt-2">
                            <div class="inline-table-search-container" :class="{ open: searchOpen }">
                                <button @click="searchOpen = !searchOpen">
                                    <i
                                        :class="{
                                            'mdi mdi-magnify': !searchOpen,
                                            'mdi mdi-close': searchOpen,
                                        }"
                                    ></i>
                                </button>
                                <input
                                    v-model="searchQuery"
                                    type="text"
                                    placeholder="Type to search..."
                                    @keyup.esc="searchOpen = false"
                                />
                            </div>
                            <button class="btn btn-sm btn-icon mr-2 clickable" @click="toggleFilters()">
                                <i class="mdi mdi-chevron-right filter-icon" />
                            </button>
                        </div>
                        <div class="p-2" :class="{ 'd-none': !filtersOpen }">
                            <span class="table-filters-heading">Filter Results</span>
                            <div class="table-filters-group">
                                <span class="filter-title">Statuses</span>
                                <FormField field-class="tw-flex tw-flex-col tw-gap-1">
                                    <div
                                        v-for="status of [
                                            'Draft',
                                            'Awaiting Approval',
                                            'Approved',
                                            'Sent',
                                            'Complete',
                                            'Void',
                                            'Rejected',
                                        ]"
                                        :key="status"
                                        class="control w-100"
                                    >
                                        <Checkbox
                                            :model-value="statusFilter.includes(status)"
                                            @click.native.prevent="
                                                statusFilter.includes(status)
                                                    ? (statusFilter = statusFilter.filter((s) => s !== status))
                                                    : statusFilter.push(status)
                                            "
                                        >
                                            {{ status }}
                                        </Checkbox>
                                    </div>
                                </FormField>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-fill" :class="{ 'border-left': filterable }">
                    <div
                        v-if="selectedData.length > 0"
                        class="alert alert-light rounded-0 text-dark d-flex align-items-center mb-0"
                    >
                        <p class="p-1 flex-fill mr-auto">
                            You have selected
                            <strong>{{ selectedData.length }}</strong>
                            {{ selectedData.length > 1 ? 'purchase orders' : 'purchase order' }}
                        </p>
                        <ApprovalControls
                            :bulk-controls="true"
                            :approvables="selectedData"
                            :hide-request="isApprovalsArea"
                            :hide-actions="userCannotApproveSelected"
                            :locked="eventIsClosed"
                        />
                    </div>
                    <DataTable
                        ref="table"
                        v-model="selectedData"
                        :row-checkable="canSelectPurchaseOrder"
                        :endpoint="dataEndpoint"
                        :checkable="checkable"
                        :searchable-string="searchQuery"
                        :columns="[
                            {
                                title: 'Status',
                                field: 'status',
                                width: 140,
                            },
                            {
                                title: 'Reference',
                                field: 'purchase_orders.reference',
                                sortable: true,
                            },
                            {
                                title: 'Title',
                                field: 'purchase_orders.title',
                                sortable: true,
                            },
                            {
                                title: 'Subcategory',
                                field: 'category.name',
                                visible: !hideCategory,
                            },
                            {
                                title: 'Supplier',
                                field: 'suppliers.name',
                                sortable: true,
                            },
                            {
                                title: 'Budget',
                                field: 'budget_entries.budgeted_cost',
                                width: 120,
                                sortable: true,
                                hidden: hiddenColumns.includes('budgeted'),
                            },
                            {
                                title: 'Committed',
                                field: 'subtotal',
                                width: 120,
                                hidden: hiddenColumns.includes('actual'),
                            },
                            {
                                title: 'Variance',
                                field: 'variance',
                                width: 120,
                                hidden: hiddenColumns.includes('variance'),
                            },
                            {
                                title: 'Invoiced',
                                field: 'total_invoiced',
                                width: 120,
                                hidden: hiddenColumns.includes('invoiced'),
                            },
                            {
                                title: 'Outstanding',
                                field: 'outstanding',
                                width: 120,
                                hidden: hiddenColumns.includes('outstanding'),
                            },
                            {
                                title: '',
                                field: 'actions',
                                width: 100,
                                class: '!tw-text-right',
                            },
                        ]"
                    >
                        <!-- eslint-disable -->
                        <template #column(status)="{ row }">
                            <Tag :status="row.status" />
                        </template>
                        <template #column(purchase_orders.reference)="{ row }">
                            <a
                                href="#"
                                class="d-block text-truncate"
                                :title="row.reference"
                                style="width: 120px"
                                @click.prevent="openEntry(row)"
                            >
                                {{ row.reference }}
                            </a>
                        </template>
                        <template #column(purchase_orders.title)="{ row }">
                            <a
                                href="#"
                                class="d-block text-truncate"
                                :title="row.title"
                                style="width: 120px"
                                @click.prevent="openEntry(row)"
                            >
                                {{ row.title }}
                            </a>
                        </template>
                        <template #column(category.name)="{ row }">
                            <a
                                v-if="row.category"
                                href="#"
                                class="d-block text-truncate"
                                :title="row.category.name"
                                style="width: 140px"
                                @click.prevent="launchCategory(row.category)"
                            >
                                <i
                                    v-if="row.category.xero_account_id"
                                    class="mdi mdi-swap-horizontal-circle text-xero"
                                    style="font-size: 1rem"
                                    title="Connected to Xero"
                                ></i>
                                {{ row.category.name }}
                            </a>
                            <span v-else class="text-muted">&mdash;</span>
                        </template>
                        <template #column(suppliers.name)="{ row }">
                            <a
                                v-if="row.supplier"
                                href="#"
                                class="d-block text-truncate"
                                :title="row.supplier.name"
                                style="width: 170px"
                                @click.prevent="openSupplier(row.supplier)"
                            >
                                <i
                                    v-if="row.supplier.xero_contact_id"
                                    class="mdi mdi-swap-horizontal-circle text-xero"
                                    style="font-size: 1rem"
                                    title="Connected to Xero"
                                ></i>
                                <span v-if="row.supplier">{{ row.supplier.name }}</span>
                                <span v-else class="text-muted">Unassigned</span>
                            </a>
                            <span v-else class="text-muted">&mdash;</span>
                        </template>
                        <template #column(budget_entries.budgeted_cost)="{ row }">
                            <span v-if="row.budget_entry">
                                <span
                                    v-if="
                                        row.budget_entry.purchase_orders_count &&
                                        row.budget_entry.purchase_orders_count > 1
                                    "
                                >
                                    MULTI
                                    <Tooltip
                                        label="This budget entry is linked to multiple purchase orders"
                                        class="tw-inline-flex"
                                    >
                                        <i class="mdi mdi-alert tw-text-orange-500"></i>
                                    </Tooltip>
                                </span>
                                <span v-else>
                                    {{ localise(row.budget_entry ? row.budget_entry.budgeted_cost : 0, 2, true) }}
                                </span>
                            </span>
                            <span v-else>
                                No Budget
                                <Tooltip
                                    label="There is no budget entry linked to this purchase order"
                                    class="tw-inline-flex"
                                >
                                    <i class="mdi mdi-alert tw-text-orange-500"></i>
                                </Tooltip>
                            </span>
                        </template>
                        <template #column(subtotal)="{ row }">
                            {{ localise(row.subtotal, 2, true) }}
                        </template>
                        <template #column(variance)="{ row }">
                            {{
                                localise(
                                    (row.budget_entry ? row.budget_entry.budgeted_cost : 0) - row.subtotal,
                                    2,
                                    true
                                )
                            }}
                        </template>
                        <template #column(total_invoiced)="{ row }">
                            {{ localise(row.total_invoiced, 2, true) }}
                        </template>
                        <template #column(outstanding)="{ row }">
                            {{ localise(row.total_invoiced_gross - row.total_paid - row.total_credit, 2, true) }}
                        </template>
                        <template #column(actions)="{ row }">
                            <Tooltip v-if="Auth.can('view purchase orders')" label="View" class="ml-1" position="left">
                                <Button @click="openEntry(row)">
                                    <i class="mdi mdi-eye-outline"></i>
                                </Button>
                            </Tooltip>
                        </template>
                        <!-- eslint-enable -->
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import ApprovalControls from '../../widgets/ApprovalControls.vue';
import LoadingSpinner from '../../widgets/LoadingSpinner.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Tag from '@/js/components/Tag.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { setRoute } from '@/js/utils.js';

export default {
    components: {
        LoadingSpinner,
        ApprovalControls,
        Tag,
        Button,
        Tooltip,
        FormField,
        Checkbox,
        DataTable,
    },
    props: {
        endpoint: {
            type: String,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        hideCategory: {
            type: Boolean,
            default: false,
        },
        isApprovalsArea: {
            type: Boolean,
            default: false,
        },
        hiddenColumns: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isLoading: false,
            filtersOpen: false,
            searchOpen: false,
            searchQuery: '',
            perPage: 10,
            sorts: {},
            statusFilter: ['Draft', 'Awaiting Approval', 'Approved', 'Sent', 'Complete', 'Void'],
            data: null,
            selectedData: [],
            relativeTimestamps: true,
            store: useDataStore(),
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        userCannotApproveSelected() {
            let cannotApprove = this.selectedData.filter((row) => {
                return !row.current_user_can_approve || row.category.is_pooled;
            });

            return !!cannotApprove.length;
        },
        dataEndpoint() {
            const url = new URL(this.endpoint);
            url.searchParams.append('filter', `purchase_orders.status=${this.statusFilter.join(',')}`);
            return url.href;
        },
    },
    watch: {
        searchQuery() {
            this.debouncedReloadData();
        },
        searchOpen(newVal) {
            if (!newVal) {
                this.searchQuery = '';
            }
        },
        perPage() {
            this.reloadData();
        },
        statusFilter: {
            handler() {
                this.debouncedReloadData();
            },
            deep: true,
        },
    },
    mounted() {
        Eventbus.$on('reload:purchaseorders', () => {
            this.reloadData();
        });
    },
    methods: {
        reloadData(newPage = null) {
            this.$refs.table?.refreshData(newPage);
        },
        debouncedReloadData: debounce(function () {
            this.reloadData();
        }, 500),
        toggleFilters() {
            this.filtersOpen = !this.filtersOpen;
        },
        openEntry(entry) {
            Eventbus.$emit('purchaseOrder:view', entry.id);
        },
        openSupplier(supplier) {
            if (Auth.can('manage suppliers')) {
                window.open(
                    route('account.contacts', {
                        supplier: supplier.id,
                    }),
                    '_blank'
                );
                return;
            }
            Eventbus.$emit('view:supplier', supplier);
        },
        canSelectPurchaseOrder(row) {
            return !(row.invoices && row.invoices.length) && !row.is_closed;
        },
        launchCategory(category) {
            setRoute('account.event.purchaseorders.summary', {
                page: 'summary',
                departmentId: category.department_id,
            });
        },
    },
};
</script>
