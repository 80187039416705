<template>
    <div>
        <LoadingSpinner v-if="!isLoaded" label="Loading Subcategories" />
        <div v-else class="d-flex">
            <div :class="{ 'border-left': filterable }" style="flex-grow: 1">
                <div v-if="selectedData.length > 0" class="alert alert-light rounded-0 text-dark align-content-center">
                    <span class="float-right">
                        <ApprovalControls :bulk-controls="true" :approvables="pluckApprovables()" />
                    </span>
                    <div class="mt-1 mb-2">
                        You have selected
                        <strong>{{ selectedData.length }}</strong>
                        {{ selectedData.length ? language.subcategory.plural : language.subcategory.single }}
                    </div>
                </div>
                <DataTable
                    v-model="selectedData"
                    :columns="[
                        { field: 'name', title: 'Subcategory', sortable: true },
                        { field: 'entry_count', title: 'Budget Entries', sortable: true },
                        { field: 'fixed_cost', title: 'Fixed', sortable: true },
                        { field: 'budgeted_cost', title: 'Budgeted', sortable: true },
                    ]"
                    :data="data"
                    :checkable="checkable"
                >
                    <template #column(name)="{ row }">
                        <a
                            :href="
                                route('account.event.budget', {
                                    page: row.entries[0].entry_type === 1 ? 'cost' : 'revenue',
                                    departmentId: row.entries[0].category.department_id,
                                    categoryId: row.entries[0].category.id,
                                })
                            "
                        >
                            {{ row.name }}
                        </a>
                    </template>
                    <template #column(fixed_cost)="{ row }">
                        {{ localise(row.fixed_cost, 2, event.currency_code) }}
                    </template>
                    <template #column(budgeted_cost)="{ row }">
                        {{ localise(row.budgeted_cost, 2, event.currency_code) }}
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import ApprovalControls from '../../../../../js/components/widgets/ApprovalControls.vue';
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        ApprovalControls,
        LoadingSpinner,
        DataTable,
    },
    props: {
        event: {
            type: Object,
        },
        endpoint: {
            type: String,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        isOpen: {
            type: Boolean,
            default: true,
        },
        language: {
            type: Object,
            default() {
                return {
                    subcategory: {
                        single: 'Subcategory',
                        plural: 'Subcategories',
                    },
                };
            },
            required: false,
        },
    },
    data() {
        return {
            isLoaded: false,
            data: [],
            selectedData: [],
            localise,
        };
    },
    watch: {
        isOpen: function (isOpenNow) {
            if (isOpenNow) {
                this.reloadData();
            } else {
                this.resetData();
            }
        },
    },
    mounted() {
        if (this.isOpen) {
            this.reloadData();
        }
    },
    methods: {
        reloadData() {
            this.isLoaded = false;
            axios.get(this.endpoint).then(({ data }) => {
                this.data = data;
                this.isLoaded = true;
            });
        },
        resetData() {
            this.isLoaded = false;
            this.data = [];
            this.selectedData = [];
        },
        pluckApprovables() {
            let approvables = [];

            this.selectedData.forEach((category) => {
                category.entries?.forEach((entry) => {
                    approvables.push(entry);
                });
            });

            return approvables;
        },
    },
};
</script>
