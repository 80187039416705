<template>
    <div class="buefy-wrapper" style="min-height: calc(100vh - 279px)">
        <div class="row">
            <div class="col-12">
                <Selector v-model="template" :options="templates" placeholder="Select a template" class="mb-3" />
            </div>
        </div>
        <div v-if="template" class="row">
            <div class="col-12 col-xl-6">
                <div style="height: 100%">
                    <div>
                        <span class="h3">Editor</span>
                        <a
                            href="#"
                            class="float-right ml-2"
                            @click.prevent="openButtonModal"
                            v-html="'&#123;&#123; button| &#125;&#125;'"
                        ></a>
                        <a
                            href="#"
                            class="float-right"
                            @click.prevent="openShortcodePicker"
                            v-html="'&#123;&#123; shortcodes &#125;&#125;'"
                        ></a>
                    </div>

                    <CKEditor
                        id="templateEditor"
                        v-model="content"
                        :without="['HtmlEmbed', 'MediaEmbed']"
                        @ready="(editor) => setEditor(editor)"
                    />
                    <div>
                        <button v-if="templateId" class="button my-2 is-danger is-outlined" @click="deleteTemplate">
                            Reset Template
                        </button>
                        <button
                            class="button ml-auto float-right my-2 is-success"
                            :disabled="!content.length"
                            @click="saveTemplate"
                        >
                            Save Template
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6" style="min-height: calc(100vh - 279px)">
                <h3>Preview</h3>
                <iframe
                    width="100%"
                    style="
                        height: calc(100% - 30px);
                        border: 1px solid hsl(0deg, 0%, 86%);
                        border-radius: 5px;
                        background-color: white;
                    "
                    :srcdoc="preview"
                ></iframe>
            </div>
        </div>
        <div v-else class="row px-3">
            <div
                class="col-12 text-center py-5 h3"
                style="border: 1px dashed hsl(0deg, 0%, 86%); border-radius: 5px; background-color: white"
            >
                Please select a template.
            </div>
        </div>
        <ShortcodePicker :shortcodes="shortcodes" @insert="(shortcode) => insertShortcode(shortcode)" />
        <Modal ref="buttonModal" :title="'{{ button| }}'" @closing="resetButtonModal">
            <div class="buefy-wrapper">
                <h4 class="mb-1">Label</h4>
                <FormField>
                    <Select
                        v-model="buttonShortcodeSelect"
                        class="tw-w-full"
                        @update:model-value="(val) => (buttonText = val)"
                    >
                        <option value="" disabled>Select a Label</option>
                        <option v-for="(option, key) in shortcodes" :key="key" :value="`{${key}}`">
                            {{ option.name }} {{ `\{${key}\}` }}
                        </option>
                    </Select>
                </FormField>
                <div class="my-2 text-center">OR</div>
                <FormField>
                    <Textbox v-model="buttonText" type="text" placeholder="Button Text" />
                </FormField>
                <hr />
                <h4 class="mb-1">Link</h4>
                <FormField>
                    <Select
                        v-model="buttonLinkShortcodeSelect"
                        class="tw-w-full"
                        @update:model-value="(val) => (buttonLink = val)"
                    >
                        <option value="" disabled>Select a Link</option>
                        <option v-for="(option, key) in linkcodes" :key="key" :value="`{${key}}`">
                            {{ option.name }} {{ `\{${key}\}` }}
                        </option>
                    </Select>
                </FormField>
                <div class="my-2 text-center">OR</div>
                <FormField>
                    <Textbox v-model="buttonLink" type="text" placeholder="Button Link" />
                </FormField>
                <Button
                    type="is-success"
                    class="tw-float-right"
                    @click="insertButton(`{{ button|${buttonText},${buttonLink} }}`)"
                >
                    Insert Button
                </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import axios from 'axios';
import { useDataStore } from '@/js/stores/DataStore.js';
import CKEditor from '../../../CKEditor.vue';
import Selector from '../../../controls/Selector.vue';
import ShortcodePicker from '../../../widgets/ShortcodePicker.vue';
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        ShortcodePicker,
        CKEditor,
        Selector,
        Modal,
        Button,
        FormField,
        Select,
        Textbox,
    },
    data() {
        const store = useDataStore();
        return {
            store: store,
            templates: [],
            template: null,
            content: '',
            preview: null,
            editor: null,
            shortcodes: {},
            linkcodes: {},
            buttonShortcodeSelect: '',
            buttonLinkShortcodeSelect: '',
            buttonText: '',
            buttonLink: '',
            templateId: null,
        };
    },
    watch: {
        template() {
            this.getPreview();
        },
    },
    mounted() {
        this.getTemplates();
    },
    methods: {
        getTemplates() {
            axios.get(route('api.account.templates.index')).then(({ data }) => {
                this.templates = data;
            });
        },
        getPreview() {
            if (this.template) {
                axios
                    .post(route('account.templates.preview'), {
                        template_type: this.template,
                    })
                    .then(({ data }) => {
                        this.preview = data.preview;
                        this.content = data.template?.content ?? '';
                        this.templateId = data.template?.id;
                        this.shortcodes = data.shortcodes;
                        this.linkcodes = data.linkcodes;
                    });

                return;
            }

            this.preview = null;
            this.content = '';
            this.shortcodes = {};
            this.linkcodes = {};
            this.templateId = null;
        },
        openShortcodePicker() {
            Eventbus.$emit('shortcodePicker');
        },
        openButtonModal() {
            this.$refs.buttonModal.open();
        },
        insertShortcode(shortcode) {
            if (!this.editor) {
                return;
            }

            this.editor.execute('insertText', {
                text: shortcode,
            });
        },
        insertButton(shortcode) {
            this.insertShortcode(shortcode);
            this.resetButtonModal();
            this.$refs.buttonModal.close();
        },
        resetButtonModal() {
            this.buttonShortcodeSelect = '';
            this.buttonText = '';
            this.buttonLink = '';
        },
        setEditor(editor) {
            this.editor = editor;
        },
        saveTemplate() {
            axios
                .post(route('api.account.templates.save'), {
                    template_type: this.template,
                    content: this.content,
                })
                .then(() => {
                    this.getPreview();
                    toast('Template Saved', 'Your template has been saved successfully!', 'success');
                });
        },
        deleteTemplate() {
            axios
                .delete(
                    route('api.account.templates.delete', {
                        template: this.templateId,
                    })
                )
                .then(() => {
                    this.getPreview();
                    toast('Template Reset', 'Your template has been reset successfully!', 'success');
                });
        },
    },
};
</script>

<style lang="scss">
#templateEditor + .ck-editor .ck-content {
    @import '../../../../../views/vendor/mail/html/themes/default';
}
</style>
