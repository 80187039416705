<template>
    <div class="buefy-wrapper">
        <LoadingSpinner v-if="isLoading" label="Loading Addresses" />
        <div v-if="!isLoading" class="d-flex align-items-center mb-2">
            <Textbox v-model="searchQuery" placeholder="Search for an address..." />
        </div>
        <DataTable
            :columns="[
                { title: 'ID', field: 'id', width: 80 },
                { title: 'Type', field: 'type', width: 120 },
                { title: 'Name', field: 'name' },
                { title: 'Line 1', field: 'line1' },
                { title: 'Line 2', field: 'line2' },
                { title: 'City', field: 'city' },
                { title: 'Country', field: 'country' },
                { title: 'Postcode', field: 'postcode' },
            ]"
            :endpoint="tableEndpoint"
            :searchable-string="searchQuery"
            class="align-middle"
        >
            <template #column(name)="{ row }">
                <EditableText
                    v-model="row.name"
                    placeholder="Not set"
                    @update:model-value="updateAddress(accountId, row)"
                />
            </template>
            <template #column(line1)="{ row }">
                <EditableText
                    v-model="row.address1"
                    placeholder="Not set"
                    @update:model-value="updateAddress(accountId, row)"
                />
            </template>
            <template #column(line2)="{ row }">
                <EditableText
                    v-model="row.address2"
                    placeholder="Not set"
                    @update:model-value="updateAddress(accountId, row)"
                />
            </template>
            <template #column(city)="{ row }">
                <EditableText
                    v-model="row.city"
                    placeholder="Not set"
                    @update:model-value="updateAddress(accountId, row)"
                />
            </template>
            <template #column(country)="{ row }">
                <EditableText
                    v-model="row.country"
                    placeholder="Not set"
                    @update:model-value="updateAddress(accountId, row)"
                />
            </template>
            <template #column(postcode)="{ row }">
                <EditableText
                    v-model="row.postcode"
                    placeholder="Not set"
                    @update:model-value="updateAddress(accountId, row)"
                />
            </template>
            <template #empty>
                <div class="empty-label">There are no addresses to show</div>
            </template>
        </DataTable>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import EditableText from '../../../controls/EditableText.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        EditableText,
        LoadingSpinner,
        Textbox,
    },
    props: {
        account: {
            type: [String, Number],
        },
    },
    data() {
        return {
            isLoading: false,
            accountId: null,
            searchQuery: null,
            perPage: 15,
            data: null,
            tableEndpoint: '',
        };
    },
    watch: {
        account(newVal) {
            this.accountId = newVal;
            this.tableEndpoint = route('api.admin.account.addresses', {
                account: this.accountId,
            });
        },
    },
    mounted() {
        this.accountId = this.account;
        this.tableEndpoint = route('api.admin.account.addresses', {
            account: this.accountId,
        });
    },
    methods: {
        updateAddress: debounce(function (accountId, data) {
            axios
                .post(
                    route('api.admin.account.addresses.update', {
                        account: accountId,
                    }),
                    data
                )
                .then(() => {
                    toast(
                        'Address Updated',
                        'The address "' + data.name + '" has been updated successfully',
                        'success'
                    );
                });
        }, 750),
    },
};
</script>
