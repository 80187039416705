<template>
    <Page v-if="Auth.can('manage users')" title="Team & Permissions">
        <template #submenu>
            <a href="#" :class="{ active: !currentPage }" @click.prevent="setPage()">Team Members</a>
            <a
                v-if="Auth.can('manage suppliers')"
                href="#"
                :class="{ active: currentPage === 'contacts' }"
                @click.prevent="setPage('contacts')"
            >
                Contact Users
            </a>
            <a href="#" :class="{ active: currentPage === 'groups' }" @click.prevent="setPage('groups')">User Groups</a>
            <a href="#" :class="{ active: currentPage === 'approvals' }" @click.prevent="setPage('approvals')">
                Approvals
            </a>
        </template>
        <Users v-if="!currentPage" />
        <Contacts v-else-if="Auth.can('manage suppliers') && currentPage === 'contacts'" />
        <Usergroups v-else-if="currentPage === 'groups'" />
        <ApprovalMatrices v-else-if="currentPage === 'approvals'" />
    </Page>
    <PageNotFound v-else />
</template>

<script>
import Page from '../../Page.vue';
import PageNotFound from '../../PageNotFound.vue';
import Users from './Team/Users.vue';
import Contacts from './Team/Contacts.vue';
import Usergroups from './Team/Usergroups.vue';
import ApprovalMatrices from './Team/ApprovalMatrices.vue';
import { setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        Page,
        PageNotFound,
        Users,
        Contacts,
        Usergroups,
        ApprovalMatrices,
    },
    data() {
        return {
            currentPage: route().params.page ?? null,
        };
    },
    mounted() {
        this.handlePageChange();
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        handlePageChange() {
            this.currentPage = route().params.page ?? null;
        },
        setPage(page = '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setRoute('account.team', {
                page: page,
            });
        },
    },
};
</script>
