<template>
    <div class="buefy-wrapper">
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-if="!isLoading" class="d-flex align-items-center mb-2">
            <Textbox v-model="searchQuery" placeholder="Search for an audit log..." />
        </div>
        <DataTable
            v-if="data && !isLoading"
            :columns="[
                { title: 'Date', field: 'date' },
                { title: 'Event', field: 'event' },
                { title: 'User', field: 'user' },
                { title: 'Old Values', field: 'old' },
                { title: 'New Values', field: 'new' },
            ]"
            class="align-middle"
            striped
            :total="data.total"
            :data="data.data"
            :current-page="data.current_page"
            :per-page="data.per_page"
            detailed
            detail-key="id"
            paginated
            backend-pagination
            @page-change="reloadData"
        >
            <template #column(date)="{ row }">
                {{ DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_FULL) }}
            </template>
            <template #column(user)="{ row }">
                <span v-if="!row.user" class="text-muted">System</span>
                <User v-else :user="row.user" :hide-name="false" />
            </template>
            <template #column(old)="{ row }">
                <code v-if="row.old_values">{{ row.old_values }}</code>
            </template>
            <template #column(new)="{ row }">
                <code v-if="row.new_values">{{ row.new_values }}</code>
            </template>
            <template #detail="{ row }">
                <div class="row">
                    <div class="col-3 text-muted mb-2">URL</div>
                    <div class="col-9 mb-2">
                        <span v-if="!row.url" class="text-muted">Unknown</span>
                        <span v-else>{{ row.url }}</span>
                    </div>
                    <div class="col-3 text-muted mb-2">IP Address</div>
                    <div class="col-9 mb-2">
                        <span v-if="!row.ip_address" class="text-muted">Unknown</span>
                        <span v-else>{{ row.ip_address }}</span>
                    </div>
                    <div class="col-3 text-muted mb-2">User Agent</div>
                    <div class="col-9 mb-2">
                        <span v-if="!row.user_agent" class="text-muted">Unknown</span>
                        <span v-else>{{ row.user_agent }}</span>
                    </div>
                </div>
            </template>
            <template #empty>
                <div class="empty-label">There are no audit logs to show</div>
            </template>
        </DataTable>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import User from '../../../widgets/User.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        DataTable,
        User,
        LoadingSpinner,
        Textbox,
    },
    props: {
        model: {
            type: String,
        },
        modelId: {
            type: [String, Number],
        },
    },
    data() {
        return {
            isLoading: false,
            currentModelType: null,
            currentModelId: null,
            searchQuery: null,
            perPage: 15,
            data: null,
            DateTime,
        };
    },
    watch: {
        model(newVal) {
            this.currentModelType = newVal;
        },
        modelId(newVal) {
            this.currentModelId = newVal;
        },
        searchQuery() {
            this.debouncedReloadData();
        },
        perPage() {
            this.reloadData();
        },
    },
    mounted() {
        this.currentModelType = this.model;
        this.currentModelId = this.modelId;
        this.reloadData();
    },
    methods: {
        reloadData(newPage = null) {
            this.isLoading = true;
            const url = new URL(route('api.admin.audits'));
            url.searchParams.set('model', this.currentModelType);
            url.searchParams.set('id', this.currentModelId);
            url.searchParams.set('perPage', this.perPage);
            if (this.searchQuery) {
                url.searchParams.append('query', this.searchQuery);
            }
            if (newPage) {
                url.searchParams.append('page', newPage);
            } else if (this.data && this.data.current_page) {
                url.searchParams.append('page', this.data.current_page);
            }
            axios.get(url.href).then(({ data }) => {
                this.data = data;
                this.isLoading = false;
            });
        },
        debouncedReloadData: debounce(function () {
            this.reloadData();
        }, 500),
    },
};
</script>
