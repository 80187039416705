<template>
    <div
        class="mr-3 tw-text-nowrap rounded bg-white mb-3 card-custom collapsible overflow-x-auto"
        :class="{ 'w-100': expanded, 'tw-mx-auto': !expanded }"
    >
        <div ref="header" class="d-flex align-items-center border-bottom card-custom-header py-2" @scroll="scrollCard">
            <h4 v-if="title" class="tw-my-0 tw-ml-4 tw-mr-3">{{ title }}</h4>
            <slot v-if="$slots['title']" name="title" />
            <slot name="append"><div class="ml-auto"></div></slot>
            <div class="tw-min-w-[146px] tw-flex tw-text-right tw-justify-end tw-gap-2 tw-mx-3">
                <slot name="actions"></slot>
                <Button v-if="collapsible" @click="isCollapsed = !isCollapsed">
                    <i :class="!isCollapsed ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'"></i>
                </Button>
            </div>
        </div>
        <div
            ref="content"
            class="card-custom-content"
            :class="{
                'px-4': !paddingless,
                'pt-2 pb-3': !paddingless && !isCollapsed,
                'inline-collapsible': collapsible,
                'inline-fixed': !collapsible,
                open: !isCollapsed,
            }"
            @scroll="scrollCard"
        >
            <LoadingSpinner v-if="loading" label="Loading Information" />
            <slot v-else></slot>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: { LoadingSpinner, Button },
    props: {
        collapsible: {
            type: Boolean,
            default: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        title: {
            type: [String, Boolean],
            default: false,
        },
        paddingless: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isCollapsed: this.collapsed,
        };
    },
    methods: {
        scrollCard(e) {
            if (e.srcElement.id === 'header') {
                this.$refs.content.scrollLeft = e.srcElement.scrollLeft;
            } else {
                this.$refs.header.scrollLeft = e.srcElement.scrollLeft;
            }
        },
    },
};
</script>

<style scoped>
.card-custom {
    transition: max-height 0.3s ease-in-out;

    .card-custom-header {
        .field {
            margin-bottom: 0 !important;
            margin-right: 20px;
            border-radius: 4px;
            cursor: default;
            transition: all 0.1s ease-in-out;
            opacity: 0.65;

            label {
                margin-bottom: 0 !important;
                opacity: 0.75;
            }

            .icon {
                position: relative;
                transform: scale(0.5);
                left: 4px;

                &.actual {
                    color: $object-actual;
                }
                &.fixed {
                    color: $object-fixed;
                }
                &.primary {
                    color: $eventwise-primary;
                }
                &.estimate {
                    color: $object-estimate;
                }
                &.budgeted {
                    color: $object-budgeted;
                }
                &.committed {
                    color: $object-committed;
                }
                &.remaining {
                    color: $object-remaining;
                }
                &.invoiced {
                    color: $object-invoiced;
                }
                &.paid {
                    color: $object-paid;
                }
                &.scenario {
                    color: $object-scenario;
                }
            }

            &:hover {
                opacity: 1;
            }
        }

        .ignore-field-opacity,
        .ignore-field-opacity .field {
            opacity: 1;
        }

        .js-action-collapse {
            .icon {
                position: relative;
                transition: all 0.2s ease-in-out;
                transform: rotate(0deg);
            }

            &.active {
                .icon {
                    transform: rotate(180deg);
                }
            }

            &:active {
                outline: 0 !important;
            }

            &:focus {
                outline: 0 !important;
                box-shadow: none !important;
            }
        }
    }

    .card-custom-content {
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;

        &.inline-fixed {
            overflow-y: unset;
            overflow-x: unset;
        }
    }
}
</style>
