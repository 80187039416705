<template>
    <div class="dropdown notification-list" @mouseover="active = true" @mouseleave="active = false">
        <button
            class="nav-link dropdown-toggle arrow-none nav-icon-animate"
            data-toggle="dropdown"
            type="button"
            aria-haspopup="false"
            aria-expanded="false"
            @click="active = true"
        >
            <i class="mdi mdi-plus font-22 d-block"></i>
        </button>
        <div
            :class="{
                'd-block': active,
            }"
            class="dropdown-menu dropdown-menu-right dropdown-lg pt-0 rounded"
        >
            <h6
                class="dropdown-item-text font-15 m-0 py-3 mb-1 bg-eventwise text-white d-flex justify-content-between align-items-center rounded-top"
            >
                Create New
            </h6>
            <div class="row pt-0 px-3">
                <div v-if="Auth.can('create events')" class="col-4 py-1 px-1 text-center">
                    <a class="create-blocklink border rounded" @click.prevent="createEvent">
                        <div class="avatar-md bg-primary">
                            <i class="mdi mdi-calendar-plus font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 mb-0 font-12">Event</span>
                    </a>
                </div>

                <div v-if="Auth.can('create budget entries') && !eventIsClosed" class="col-4 py-1 px-1 text-center">
                    <a
                        class="create-blocklink border rounded"
                        :class="{ disabled: !store.get('current_event') }"
                        @click.prevent="createBudgetEntry"
                    >
                        <div class="avatar-md bg-success">
                            <i class="mdi mdi-file-chart font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 font-12">Budget Entry</span>
                    </a>
                </div>

                <div v-if="Auth.can('create purchase orders') && !eventIsClosed" class="col-4 py-1 px-1 text-center">
                    <a
                        class="create-blocklink border rounded"
                        :class="{ disabled: !store.get('current_event') }"
                        @click.prevent="createPurchaseOrder"
                    >
                        <div class="avatar-md bg-xero">
                            <i class="mdi mdi-receipt-text-plus font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 font-12">Purchase Order</span>
                    </a>
                </div>

                <div v-if="Auth.can('manage departments')" class="col-4 py-1 px-1 text-center">
                    <a class="create-blocklink border rounded" @click.prevent="createDepartment">
                        <div class="avatar-md bg-pink">
                            <i class="mdi mdi-tag-multiple font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 font-12">Department</span>
                    </a>
                </div>

                <div v-if="Auth.can('manage subcategories')" class="col-4 py-1 px-1 text-center">
                    <a class="create-blocklink border rounded" @click.prevent="createSubcategory">
                        <div class="avatar-md bg-warning">
                            <i class="mdi mdi-tag font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 mb-0 font-12">Subcategory</span>
                    </a>
                </div>

                <div v-if="Auth.can('manage tickets') && !eventIsClosed" class="col-4 py-1 px-1 text-center">
                    <a
                        class="create-blocklink border rounded"
                        :class="{ disabled: !store.get('current_event') }"
                        @click.prevent="createTicketType"
                    >
                        <div class="avatar-md bg-danger">
                            <i class="mdi mdi-ticket-confirmation font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 font-12">Ticket Type</span>
                    </a>
                </div>

                <div v-if="Auth.can('manage suppliers')" class="col-4 py-1 px-1 text-center">
                    <a class="create-blocklink border rounded" @click.prevent="createContact">
                        <div class="avatar-md bg-info">
                            <i class="mdi mdi-card-account-details font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 font-12">Contact</span>
                    </a>
                </div>

                <div v-if="Auth.can('create supplier invoices') && !eventIsClosed" class="col-4 py-1 px-1 text-center">
                    <a class="create-blocklink border rounded" @click.prevent="createSupplierInvoice">
                        <div class="avatar-md bg-xero">
                            <i class="mdi mdi-receipt-text font-20 text-white"></i>
                        </div>
                        <span class="d-block mt-2 font-12">Supplier Invoice</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';
export default {
    data() {
        const store = useDataStore();
        return {
            store: store,
            active: false,
        };
    },
    computed: {
        eventIsClosed() {
            const currentEvent = this.store.get('current_event');
            return currentEvent && currentEvent.is_closed;
        },
    },
    methods: {
        createEvent() {
            window.location = route('onboarding');
        },
        createBudgetEntry() {
            if (!this.store.get('current_event')) {
                return;
            }
            Eventbus.$emit('newBudgetEntry:open');
        },
        createDepartment() {
            Eventbus.$emit('create:department');
        },
        createSubcategory() {
            Eventbus.$emit('subcategory:open');
        },
        createTicketType() {
            if (!this.store.get('current_event')) {
                return;
            }

            Eventbus.$emit('create:ticketType');
        },
        createContact() {
            Eventbus.$emit('view:supplier');
        },
        createSupplierInvoice() {
            if (!this.store.get('current_event')) {
                return;
            }

            Eventbus.$emit('create:supplierInvoice');
        },
        createPurchaseOrder() {
            if (!this.store.get('current_event')) {
                return;
            }
            // New-Purchase-Order drawer
            Eventbus.$emit('purchase-order:create');
        },
    },
};
</script>
<style lang="scss">
a.disabled {
    cursor: default !important;
    opacity: 0.5;
}
</style>
