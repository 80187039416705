<template>
    <div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-else class="buefy-wrapper multi-event-report">
            <Banner class="mt-3" type="is-primary">
                <div class="d-flex align-items-center">
                    <img src="@/images/reporting.svg" style="max-height: 95px; margin-right: 25px" />
                    <div v-if="eventMode">
                        <h4 class="mb-2">Multi Event Analysis</h4>
                        <p>
                            This report shows all your Profit and Loss totals for the selected events.
                            <br />
                            The Profit and Losses Table shows the sum of Budgeted and Fixed amounts on Approved Budget
                            Entries, for all the departments, Cost and Revenue, within the selected Events
                            <br />
                            along with the current Profit and Variance figures for those events. The Profits and Losses
                            table can be viewed graphically by selecting the Chart option.
                            <br />
                            The Departments table shows the sum of the Budgeted amount or Fixed amount, on Approved
                            Budget Entries, net Total Invoiced or net Total Paid amounts on approved Purchase Orders,
                            <br />
                            for each department, dependant on the type indicated by the selector on the right. These
                            figures are for the events selected.
                            <br />
                            In the Departments table, select the department row, to view the same figures broken down by
                            its associated Categories.
                            <br />
                        </p>
                    </div>
                    <div v-else class="flex-fill">
                        <h4 class="mb-2">Tag Group Analysis</h4>
                        <p>
                            This report shows all your Profit and Loss totals for the events in the selected tag group.
                            <br />
                            The Profit and Losses Table shows the sum of Budgeted and Fixed amounts on Approved Budget
                            Entries, for all the departments, Cost and Revenue, within the selected Event Groups
                            <br />
                            along with the current Profit and Variance figures for those groups. The Profits and Losses
                            table can be viewed graphically by selecting the Chart option.
                            <br />
                        </p>
                    </div>
                </div>
            </Banner>
            <FormField class="tw-w-1/5 tw-mb-1 tw-mt-5" field-class="tw-flex tw-gap-1 tw-items-center">
                <Button :active="currentPage === 'profits'" @click="currentPage = 'profits'">Profits & Losses</Button>
                <Button v-if="eventMode" :active="currentPage === 'departments'" @click="currentPage = 'departments'">
                    Departments
                </Button>
            </FormField>

            <div v-if="currentPage === 'profits'" class="w-100 py-5 my-5 card">
                <div class="d-flex justify-content-between tw-mt-5">
                    <div class="d-inline-flex w-25">
                        <FormField class="tw-mb-1 tw-ml-5" field-class="tw-flex tw-gap-1 tw-items-center">
                            <Button :active="viewType === 'table'" @click="viewType = 'table'">Table</Button>
                            <Button :active="viewType === 'chart'" @click="viewType = 'chart'">Chart</Button>
                        </FormField>
                        <Button v-if="filtered && viewType === 'table'" type="is-link" @click.stop="resetColumns">
                            Reset hidden events
                        </Button>
                    </div>
                    <h3 class="tw-text-center">{{ titlePrefix }}Profits and Losses</h3>
                    <GroupTag v-if="tagLoaded" class="float-left ml-2" :tag="singleTag" />
                    <div class="w-25"></div>
                </div>

                <div v-if="events.length > 0" :key="componentKey" class="m-5">
                    <ProfitAndLossComparison
                        v-if="!isLoading && viewType === 'table'"
                        :models="events"
                        :profits-and-losses="profitsAndLosses"
                        :visible-columns="visibleColumns"
                        @hidden="(value) => hideColumn(value)"
                        @move-column="(value) => moveColumn(value)"
                    />
                    <ProfitAndLossComparisonChart
                        v-if="!isLoading && viewType === 'chart'"
                        :events="events"
                        :profits-and-losses="profitsAndLosses"
                        :visible-columns="visibleColumns"
                    />
                </div>
                <div v-else class="py-5 w-100 text-center text-muted">
                    <i class="d-block font-18"></i>
                    Event Information has been reset, please reselect
                </div>
            </div>

            <DepartmentComparison
                v-if="dataAvailable && currentPage === 'departments'"
                :events="events"
                :departments="departments"
                :categories="categories"
                :visible-columns="visibleColumns"
            />
        </div>
    </div>
</template>
<script>
import ProfitAndLossComparison from './Components/ProfitComparisonTable.vue';
import ProfitAndLossComparisonChart from './Components/ProfitComparisonChart.vue';
import DepartmentComparison from './Components/DepartmentComparison.vue';
import Banner from '../../../Layout/Banner.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import GroupTag from '@/js/components/GroupTag.vue';
import FormField from '@/js/components/widgets/FormField.vue';

export default {
    components: {
        GroupTag,
        LoadingSpinner,
        Banner,
        ProfitAndLossComparison,
        ProfitAndLossComparisonChart,
        DepartmentComparison,
        Button,
        FormField,
    },
    props: {
        eventIds: {
            type: Array,
            required: true,
        },
        tagIds: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            default: 'events',
        },
    },
    data() {
        return {
            isLoading: true,
            events: [],
            profitsAndLosses: [],
            departments: [],
            categories: [],
            localEventIds: [],
            visibleColumns: [],
            componentKey: 0,
            dataAvailable: false,
            currentPage: 'profits',
            viewType: 'table',
            singleTag: null,
            tagLoaded: false,
        };
    },
    computed: {
        filtered() {
            return this.eventIds.length > this.visibleColumns.length;
        },
        eventMode() {
            return this.mode === 'events';
        },
        titlePrefix() {
            return this.eventMode ? '' : 'Group ';
        },
    },
    mounted() {
        this.tagItem(this.tagIds[0]);
        this.resetColumns();
        this.reloadAllData();

        if (this.visibleColumns.length > 0) {
            this.dataAvailable = true;
        }
    },
    methods: {
        async reloadAllData() {
            this.events = [];
            this.profitsAndLosses = [];
            let eventDepartments = [];
            let eventCategories = [];
            this.isLoading = true;

            if (!this.eventMode) {
                this.localEventIds = await this.reloadTagEvents(this.tagIds[0]);
                this.visibleColumns = [...this.localEventIds];
            } else {
                this.localEventIds = [...this.eventIds];
                this.visibleColumns = [...this.eventIds];
            }

            for (const eventId of this.localEventIds) {
                const eventData = await this.reloadEventData(eventId);
                this.events.push(eventData.event);
                this.profitsAndLosses.push(eventData.profitAndLoss);
                eventDepartments.push(eventData.departments);
                eventCategories.push(eventData.categories);
                this.dataAvailable = true;
            }
            this.combineDepartments(eventDepartments);
            this.combineCategories(eventCategories);

            this.isLoading = false;
        },
        async reloadEventData(eventId) {
            let localData = [];
            // Fetch the profit and loss chart figures for the event
            this.isLoading = true;
            await axios
                .get(
                    route('api.account.event.reporting.eventAnalysis', {
                        event: eventId,
                    })
                )
                .then(({ data }) => {
                    localData = data;
                });
            return localData;
        },
        async reloadTagEvents(tagId) {
            return (
                await axios.get(
                    route('api.account.reporting.tagAnalysisEvents', {
                        groupTag: tagId,
                    })
                )
            ).data;
        },
        async tagItem(tagId) {
            if (this.eventMode) {
                return;
            }
            this.tagLoaded = false;
            await axios
                .get(
                    route('api.account.tags.single', {
                        groupTag: tagId,
                    })
                )
                .then(({ data }) => {
                    this.singleTag = data;
                })
                .finally(() => {
                    this.tagLoaded = true;
                });
        },
        hideColumn(eventId) {
            this.visibleColumns = this.visibleColumns.filter((id) => id !== eventId);
        },
        moveColumn(columns) {
            this.visibleColumns = columns;
            this.visibleColumns = this.visibleColumns.filter((id) => id !== 0);
        },
        resetColumns() {
            this.visibleColumns = [...this.eventIds];
            this.componentKey += 1;
            this.$forceUpdate();
        },
        combineDepartments(overallArray) {
            overallArray.forEach((EventDepartments) => {
                EventDepartments.forEach((dept) => {
                    this.departments.push(dept);
                });
            });
        },
        combineCategories(overallArray) {
            overallArray.forEach((EventCategories) => {
                EventCategories.forEach((cat) => {
                    this.categories.push(cat);
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.multi-event-report {
    .page-top-controls {
        margin-top: 10px;
    }
    .table {
        border-radius: 5px;
        overflow: hidden;
    }
}
</style>
