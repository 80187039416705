<template>
    <div class="h-100">
        <div class="buefy-wrapper h-100 position-relative mb-2">
            <div v-if="categories" class="d-flex h-100">
                <div class="d-flex flex-column flex-fill">
                    <DataTable
                        :columns="[
                            {
                                title: 'Category',
                                field: 'name',
                                sortable: true,
                            },
                            {
                                title: departmentType === 1 ? 'Budgeted Costs' : 'Budgeted Revenue',
                                field: 'total_budgeted',
                                sortable: true,
                            },
                            {
                                title: departmentType === 1 ? 'Actual Cost' : 'Actual Revenue',
                                field: 'total_actual',
                                sortable: true,
                            },
                            {
                                title: departmentType === 1 ? 'Cost Variance' : 'Revenue Variance',
                                field: 'variance',
                            },
                            {
                                title: 'Total Invoiced',
                                field: 'total_invoiced',
                                sortable: true,
                            },
                            {
                                title: 'Outstanding',
                                field: 'total_outstanding',
                            },
                        ]"
                        :data="categories"
                    >
                        <template #column(name)="{ row }">
                            {{ row.name }}
                        </template>
                        <template #column(total_budgeted)="{ row }">
                            {{ localise(row.total_budgeted, 2, true) }}
                        </template>
                        <template #column(total_actual)="{ row }">
                            {{ localise(row.total_actual, 2, true) }}
                        </template>
                        <template #column(variance)="{ row }">
                            {{ localise(row.total_budgeted - row.total_actual, 2, true) }}
                        </template>
                        <template #column(total_invoiced)="{ row }">
                            {{ localise(row.total_invoiced, 2, true) }}
                        </template>
                        <template #column(total_outstanding)="{ row }">
                            {{ localise(row.total_invoiced_gross - row.total_paid - row.total_credit, 2, true) }}
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from '@/js/components/tables/DataTable.vue';

export default {
    components: {
        DataTable,
    },
    props: {
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            categories: [],
            departmentType: 1,
        };
    },
    mounted() {
        this.categories = Object.values(this.item.categories);
        this.departmentType = this.item.type;
    },
};
</script>
