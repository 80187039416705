<template>
    <div>
        <DeveloperTools v-if="isAdmin" />
        <Presence v-if="currentAccount" :channel="'Account.' + currentAccount" :hidden="true" />
        <Presence v-if="currentEvent" :channel="'Event.' + currentEvent" :hidden="true" />
    </div>
</template>
<script>
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import Presence from '../../../../../js/components/widgets/PresenceIndicator.vue';
import DeveloperTools from '../../../../../js/components/modals/DeveloperTools.vue';
import { toast } from '@/js/utils.js';
import { recalculateDepartment, recalculateRevenueStream } from '@/js/composables/useRecalculate';

const echo = new Echo({
    broadcaster: 'pusher',
    key: Auth.pusher.key,
    cluster: Auth.pusher.cluster,
    wsHost: Auth.pusher.host === 'soketi' ? '127.0.0.1' : Auth.pusher.host,
    wsPort: Auth.pusher.port,
    wssPort: Auth.pusher.port,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});
export default {
    components: {
        DeveloperTools,
        Presence,
    },
    data() {
        const store = useDataStore();
        return {
            isAdmin: false,
            store: store,
            debug: false,
            currentAccount: null,
            currentEvent: null,
            refreshInterval: null,
        };
    },
    mounted() {
        if (
            this.store.get('current_user.is_superadmin') ||
            this.store.get('current_user.email').endsWith('@synergitech.co.uk')
        ) {
            this.isAdmin = true;
        }

        if (this.store.get('disable_notifications')) {
            return;
        }

        if (this.store.get('environment') === 'local') {
            this.debug = true;
            Pusher.logToConsole = false;
        }

        Eventbus.$on('Presence:join', this.joinPresence);
        Eventbus.$on('Presence:leave', this.leavePresence);
        Eventbus.$on('forceRefetchEvent', () => this.forceRefetchEvent());

        const currentUser = this.store.get('current_user');
        if (currentUser) {
            echo.private('User.' + currentUser.id)
                .listen('.toast', (e) => {
                    toast(e.title, e.message, e.type);
                })
                .listen('.update', (e) => {
                    this.storeUpdate(e);
                })
                .listen('.delete', (e) => {
                    this.storeDelete(e);
                })
                .listen('.eventbus', (e) => {
                    Eventbus.$emit(e.emitter, e.data);
                })
                .listen('.rolechange', (e) => {
                    if (e && e.account && e.account === this.store.get('account.id')) {
                        swal.fire({
                            title: 'Role Changed',
                            text: 'Your assigned role has changed and you must reload the page in order to continue.',
                            allowOutsideClick: false,
                        }).then(() => location.reload());
                    }
                })
                .listen('.reload', (e) => {
                    let body = document.getElementById('app');
                    body.style.cssText += '-webkit-filter: blur(5px);';
                    if (e && e.message) {
                        swal.fire({
                            title: e.message.title,
                            text: e.message.text,
                            allowOutsideClick: false,
                        }).then(() => location.reload());
                    } else {
                        location.reload();
                    }
                })
                .listen('.refresh', (e) => {
                    if (e && e.account && e.account === this.store.get('account.id')) {
                        this.forceRefetchEvent();
                    }
                })
                .notification((e) => {
                    Eventbus.$emit('reloadNotifications', e);
                    Eventbus.$emit('reloadNotificationsPage', e);
                });
        }

        this.currentAccount = this.store.get('account.id');
        this.currentEvent = this.store.get('current_event.id');
        if (this.currentEvent) {
            echo.private('Event.' + this.currentEvent)
                .listen('.toast', (e) => {
                    toast(e.title, e.message, e.type);
                })
                .listen('.update', (e) => {
                    this.storeUpdate(e);
                })
                .listen('.delete', (e) => {
                    this.storeDelete(e);
                })
                .listen('.eventbus', (e) => {
                    Eventbus.$emit(e.emitter, e.data);
                })
                .listen('.refresh', () => {
                    this.refetchEvent();
                });
            if (currentUser) {
                echo.private('Event.' + this.currentEvent + '.User.' + currentUser.id)
                    .listen('.toast', (e) => {
                        toast(e.title, e.message, e.type);
                    })
                    .listen('.update', (e) => {
                        this.storeUpdate(e);
                    })
                    .listen('.delete', (e) => {
                        this.storeDelete(e);
                    })
                    .listen('.eventbus', (e) => {
                        Eventbus.$emit(e.emitter, e.data);
                    })
                    .listen('.refresh', () => {
                        this.refetchEvent();
                    });
            }
            this.refreshInterval = setInterval(() => this.refetchEvent(), 1200000);
        }
        if (this.currentAccount) {
            echo.private('Account.' + this.currentAccount)
                .listen('.toast', (e) => {
                    toast(e.title, e.message, e.type);
                })
                .listen('.update', (e) => {
                    this.storeUpdate(e);
                })
                .listen('.delete', (e) => {
                    this.storeDelete(e);
                })
                .listen('.eventbus', (e) => {
                    Eventbus.$emit(e.emitter, e.data);
                })
                .listen('.refresh', () => {
                    this.refetchAccount();
                });
        }
    },
    methods: {
        joinPresence(channelId) {
            this.debugLog('Joining presence channel: ' + channelId);
            echo.join('Presence.' + channelId)
                .here((users) => {
                    Eventbus.$emit('Presence-' + channelId, {
                        event: 'here',
                        users: users,
                    });
                })
                .joining((user) => {
                    Eventbus.$emit('Presence-' + channelId, {
                        event: 'joining',
                        user: user,
                    });
                })
                .leaving((user) => {
                    Eventbus.$emit('Presence-' + channelId, {
                        event: 'leaving',
                        user: user,
                    });
                })
                // eslint-disable-next-line no-console
                .error((error) => console.error(error));
        },
        leavePresence(channelId) {
            this.debugLog('Leaving presence channel: ' + channelId);
            echo.leave('Presence.' + channelId);
        },
        storeUpdate(data) {
            this.logStoreEvent('update', data);
            Object.keys(data).forEach((key) => {
                this.store.set(key, data[key]);
                if (key.startsWith('current_event.categories.')) {
                    const categoryId = key.split('.')[2] ?? null;
                    if (!categoryId) {
                        // eslint-disable-next-line no-console
                        console.error('Category ID not found in key: ', key);
                        return;
                    }
                    const category = this.store.get(`current_event.categories.${categoryId}`);
                    if (!category) {
                        // eslint-disable-next-line no-console
                        console.error('Category not found in store: ', categoryId);
                        return;
                    }
                    if (!category.is_revenue) {
                        this.debouncedRecalculateDepartment(category.department_id);
                        return;
                    }
                    const revenueStream = Object.values(this.store.get('current_event.revenue_streams')).find(
                        (r) => r.department_id == category.department_id
                    );
                    if (!revenueStream) {
                        // eslint-disable-next-line no-console
                        console.error('Revenue Stream not found in store for department:', category.department_id);
                        return;
                    }
                    this.debouncedRecalculateRevenueStream(revenueStream.id);
                }
            });
        },
        storeDelete(data) {
            this.logStoreEvent('delete', data);
            data.forEach((key) => {
                this.store.delete(key);
            });
        },
        debouncedRecalculateDepartment: debounce(function (departmentId) {
            recalculateDepartment(departmentId);
        }, 500),
        debouncedRecalculateRevenueStream: debounce(function (revenueStreamId) {
            recalculateRevenueStream(revenueStreamId);
        }, 500),
        logStoreEvent(type, data) {
            if (!window.Logs) return;
            window.Logs.storeUpdates.push({
                type,
                ts: new Date().toISOString(),
                data,
            });
            if (window.Logs.storeUpdates.length > 100) {
                window.Logs.storeUpdates.shift();
            }
        },
        debugLog(message) {
            if (!this.debug) {
                return;
            }
            // eslint-disable-next-line no-console
            console.log(`[Sockets] ${message}`);
        },
        refetchEvent: debounce(function () {
            this.forceRefetchEvent();
        }, 2500),
        forceRefetchEvent() {
            if (!this.currentEvent) {
                return;
            }
            const scenario = this.store.get('current_scenario.id');
            axios
                .get(
                    route('api.account.event.single', {
                        resource: 1,
                        scenario: scenario ? scenario : null,
                    })
                )
                .then((response) => {
                    this.store.set('current_event', response.data);
                    Eventbus.$emit('eventRefetched');
                });
        },
        refetchAccount: debounce(function () {
            this.forceRefetchAccount();
        }, 5000),
        forceRefetchAccount() {
            axios
                .get(
                    route('api.account', {
                        resource: 1,
                    })
                )
                .then((response) => this.store.set('account', response.data));
        },
    },
};
</script>
