<template>
    <Modal ref="modal" title="Upload Attachable Document" size="is-large" :loading="isLoading" @closed="reset()">
        <div class="buefy-wrapper">
            <p class="mb-3">
                Click the button below to browser for a document to upload. Currently only PDF documents are supported.
            </p>
            <FormField label="File" class="tw-mb-2">
                <Upload v-model="file" class="file-label" :accept="['.pdf']" label="Click to upload" />
                {{ file ? file.name : '' }}
            </FormField>
            <FormField label="File Description" class="tw-mb-2">
                <Textbox v-model="fileDescription" type="text" :disabled="!file" />
            </FormField>
            <Banner v-if="uploadError" type="is-danger">
                {{ uploadError }}
            </Banner>
        </div>
        <template #footer>
            <Button @click="close()">Close</Button>
            <Button class="!tw-ml-auto" type="is-primary" @click="checkForDuplicateFilename()">Save Changes</Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Upload from '@/js/components/controls/Upload.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Upload,
        Textbox,
        Banner,
    },
    emits: ['uploaded'],
    data() {
        return {
            isLoading: false,
            file: null,
            fileDescription: null,
            uploadError: null,
        };
    },
    methods: {
        reset() {
            this.isLoading = false;
            this.file = null;
            this.fileDescription = null;
            this.uploadError = null;
        },
        close() {
            this.$refs.modal.close();
        },
        open() {
            this.$refs.modal.open();
        },
        checkForDuplicateFilename() {
            this.isLoading = true;
            const fileName = this.file?.name;
            const description = this.fileDescription;

            if (!fileName || fileName.length === 0) {
                return false;
            }

            axios
                .post(route('api.account.tc.duplicate'), {
                    name: fileName,
                    description: description,
                    size: 1,
                })
                .then(({ data }) => (!data.fileId ? this.fileSaveWrapper() : this.overwriteConfirmation(data.fileId)));
        },
        async fileSaveWrapper() {
            const errorMessage = (status) =>
                swal.fire({
                    title: 'Upload Error',
                    text: 'Could not upload the file (status: ' + status + ').',
                    type: 'error',
                });

            // Validate File presence
            const newTC = this.file;
            if (!newTC) {
                this.uploadError = 'Please select a file for upload';
                this.isLoading = false;
                return;
            }
            this.uploadError = null;

            // Upload File
            const file = this.file;
            let uploadDetails, success;

            // Request URL
            try {
                uploadDetails = await axios
                    .post(route('api.account.tc.generate'), {
                        name: file.name,
                        size: file.size,
                    })
                    .then((response) => {
                        return response.data;
                    });
            } catch (error) {
                errorMessage(error.response ? error.response.status : 'Unknown');
            }

            // Second Call
            success = false;
            // uploadDetails['action'] contains the s3 location of the file
            await axios
                .post(
                    uploadDetails['action'],
                    (() => {
                        const payload = new FormData();
                        for (const [key, value] of Object.entries(uploadDetails['inputs'])) {
                            payload.append(key, value);
                        }
                        payload.append('key', uploadDetails['destination']);
                        payload.append('file', file);
                        return payload;
                    })(),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                .then(() => (success = true));

            // Exit on Fail
            if (!success) {
                return;
            }

            // Third Call
            axios
                .post(route('api.account.tc.complete'), {
                    internalSignature: uploadDetails['internalSignature'],
                    description: this.fileDescription,
                    is_tc_pdf: true,
                })
                .then(() => {
                    this.$emit('uploaded');
                    this.close();
                    this.isLoading = false;
                    this.file = null;
                    this.fileDescription = '';
                    this.uploadError = null;
                });
        },
        async overwriteConfirmation(fileId) {
            const { value: confirmation } = await swal.fire({
                title: 'Warning',
                text: 'A duplicate file has been found, press Continue to overwrite it.\n Cancel to go return to the previous dialog.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                confirmButtonColor: '#28a745',
                reverseButtons: true,
            });

            if (confirmation) {
                axios
                    .post(route('api.account.tc.delete'), {
                        fileId: fileId,
                    })
                    .then(() => this.fileSaveWrapper());
            }
        },
    },
};
</script>
<style>
.full-width {
    width: 100%;
}
</style>
