<template>
    <div>
        <div id="topbar">
            <h4 class="m-0">
                <span class="brand"><strong>eventwise</strong></span>
            </h4>
        </div>

        <div id="spa-sidebar">
            <div id="logo"></div>
        </div>
    </div>
</template>

<script setup lang="ts"></script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

#topbar {
    position: fixed;
    top: 0;
    left: 70px;
    width: calc(100vw - 70px);
    height: 60px;
    background: white;
    border-bottom: 1px solid rgba(black, 0.1);
    display: flex;
    align-items: center;
    padding: 0 20px;
    z-index: 1000;
}

#spa-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 70px;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    border-right: 1px solid rgba(black, 0.1);
    z-index: 1000;

    & > #logo {
        width: 70px;
        height: 35px;
        background-image: url('/svg/icon.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 15px;
    }
}

h4 {
    span.brand {
        font-size: 22px;
        & > strong {
            letter-spacing: -0.5px;
        }
    }
}
</style>
