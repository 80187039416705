<template>
    <Drawer
        ref="drawer"
        title="Revenue Entry"
        :loading="isLoading || isUploading"
        header-class="p-0 border-0"
        size="is-large"
        @closed="resetState()"
    >
        <template v-if="entry" #header>
            <div class="px-3 pt-4 pb-2">
                <h2 class="drawer-title">
                    <EditableText
                        v-model="entry.name"
                        :max-length="255"
                        :editable="isEditable"
                        @update:model-value="debouncedUpdateEntry()"
                    />
                </h2>
                <FormField v-if="Auth.can('delete revenue entries') && !entry.category.is_pooled" class="tw-my-2">
                    <Button
                        class="tw-flex tw-gap-2"
                        type="is-danger"
                        :disabled="invoices && !!invoices.length"
                        @click="deleteEntry()"
                    >
                        <i class="mdi mdi-delete"></i>
                        Delete Entry
                    </Button>
                </FormField>
            </div>
            <div
                class="tw-flex flex-row tw-grid tw-grid-cols-5 px-3 tw-pb-2"
                :class="{ 'border-bottom': !entry.category.is_pooled }"
            >
                <FormField label="Net Total" class="tw-pr-5 flex-1">
                    {{ localise(entry.subtotal, 2, true) }}
                </FormField>
                <FormField label="Gross Total" class="tw-pr-5 flex-1">
                    {{ localise(entry.total, 2, true) }}
                </FormField>
                <FormField v-if="!entry.category.is_pooled" label="Paid" class="flex-1">
                    {{ localise(entry.total_paid, 2, true) }}
                </FormField>
                <FormField v-if="!entry.category.is_pooled" label="Linked Budget Entry">
                    <div class="d-inline-flex">
                        <modal-selector
                            v-model="entry.budget_entry_id"
                            :endpoint="
                                route('api.account.event.entries', {
                                    category_id: entry.category_id,
                                })
                            "
                            modal-title="Link Budget Entry"
                            button-title="Link a Budget Entry"
                            allow-search
                            :editable="isEditable"
                            @update:model-value="
                                (val) => {
                                    entry.budget_entry_id = val;
                                    updateEntry();
                                }
                            "
                        />
                        <Tooltip v-if="entry.budget_entry_id" label="View Budget Entry" class="pl-2">
                            <Button @click="openLinkedEntry">
                                <i class="mdi mdi-eye-outline"></i>
                            </Button>
                        </Tooltip>
                    </div>
                </FormField>
                <FormField v-if="entry.user" label="Created by" class="tw-pl-5">
                    <User :user="entry.user" :hide-name="false" />
                </FormField>
            </div>
            <div v-if="!entry.category.is_pooled" class="d-flex align-items-center px-3 pt-2 pb-3 border-bottom">
                <FormField label="Subcategory" class="flex-fill pr-4 mb-0">
                    <Selector
                        v-model="entry.category_id"
                        :options="availableCategories"
                        :allow-clear="false"
                        :editable="isEditable"
                        @update:model-value="updateEntry"
                    />
                </FormField>
                <FormField label="Customer" class="flex-fill mb-0">
                    <SupplierSelector
                        v-model="entry.supplier_id"
                        object-label="customer"
                        :allow-clear="false"
                        :editable="isEditable"
                        @update:model-value="
                            (val) => {
                                entry.supplier_id = val;
                                val ? updateEntry() : val;
                            }
                        "
                    />
                </FormField>
            </div>

            <div class="ml-3 mt-2 tw-flex tw-flex-row">
                <FormField v-if="!entry.category.is_pooled" label="Linked Budget Entry" class="tw-w-10/12">
                    <modal-selector
                        v-model="entry.budget_entry_id"
                        :endpoint="
                            route('api.account.event.entries', {
                                category_id: entry.category_id,
                            })
                        "
                        modal-title="Link Budget Entry"
                        button-title="Link a Budget Entry"
                        allow-search
                        :editable="isEditable"
                        @update:model-value="
                            (val) => {
                                entry.budget_entry_id = val;
                                updateEntry();
                            }
                        "
                    />
                </FormField>

                <Tooltip
                    v-if="entry.budget_entry_id && !entry.category.is_pooled"
                    label="View Budget Entry"
                    class="tw-w-1/12 tw-ml-2 tw-mt-[18px]"
                >
                    <Button @click="openLinkedEntry">
                        <i class="mdi mdi-eye-outline"></i>
                    </Button>
                </Tooltip>
            </div>

            <div v-if="!entry.category.is_pooled" class="flex flex-inline px-3 pt-3 pb-2">
                <input-edit
                    v-model="entry.payment_terms_description"
                    label="Custom Payment Terms"
                    size="is-small"
                    :editable="isEditable"
                    @update:model-value="updateEntry"
                ></input-edit>
            </div>
        </template>
        <Tabs v-if="entry" :tabs="visibleTabs">
            <template #tab(line_items)>
                <line-item-builder
                    v-model="entry.line_items"
                    :editable="Auth.can('create revenue entries') && !entry.category.is_pooled && isEditable"
                    :hide-invoiced="entry.category.is_pooled"
                    tax-rate-type="income"
                    :default-tax-rate-id="
                        parseInt(
                            store.get(`current_event.revenue_streams.${entry.revenue_stream_id}.default_tax_rate_id`)
                        )
                    "
                    @update:model-value="updateLineItems"
                ></line-item-builder>
            </template>
            <template v-if="!entry.category.is_pooled" #tab(activity)>
                <drawer-comments-tab
                    v-if="!entry.category.is_pooled"
                    :activity="entry.activity"
                    :comments="entry.comments"
                    :disabled="!isEditable"
                    @comment="(text) => addComment(text)"
                ></drawer-comments-tab>
            </template>
            <template v-if="!entry.category.is_pooled" #tab(files)>
                <drawer-files-tab
                    v-if="!entry.category.is_pooled"
                    :endpoint="
                        route('api.account.event.revenue.files.upload', {
                            revenueEntry: entry.id,
                        })
                    "
                    :complete-endpoint="
                        route('api.account.event.revenue.files.completeUpload', {
                            revenueEntry: entry.id,
                        })
                    "
                    :files="entry.files"
                    :disabled="!isEditable"
                    @updated="refreshData()"
                ></drawer-files-tab>
            </template>
            <template v-if="!entry.category.is_pooled" #tab(invoices)>
                <DrawerInvoicesTab
                    v-if="!entry.category.is_pooled"
                    :on-raise-invoice="listenOnRaiseXeroInvoice"
                    :invoices="invoices"
                    :has-xero-account="!!entry.category.xero_account_id"
                    :can-raise-invoice-conditions="[
                        {
                            can: !!entry.supplier_id,
                            message: 'Please assign a customer in order to raise an invoice.',
                        },
                        {
                            can: !!hasInvoiceableItems,
                            message: '',
                        },
                        {
                            can: isEditable,
                            message: 'This revenue entry is no longer editable.',
                        },
                    ]"
                    @view="viewInvoice"
                />
            </template>
        </Tabs>
        <RaiseSalesInvoice @reload="refreshData" @created="viewInvoice" />
        <template v-if="entry" #footer>
            <Presence :channel="'RevenueEntry.' + entry.id" label="Currently viewing this Revenue Entry"></Presence>
        </template>
    </Drawer>
</template>
<script>
import debounce from 'lodash/debounce';
import Drawer from '../../Drawer.vue';
import Selector from '../../controls/Selector.vue';
import SupplierSelector from '../../controls/SupplierSelector.vue';
import LineItemBuilder from '../../widgets/LineItemBuilder.vue';
import RaiseSalesInvoice from '../../modals/Invoices/RaiseSalesInvoice.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Presence from '../../widgets/PresenceIndicator.vue';
import EditableText from '../../controls/EditableText.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import DrawerInvoicesTab from '@/assets/js/eventbooks/components/drawer-tabs/invoices.vue';
import User from '@/js/components/widgets/User.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        Selector,
        SupplierSelector,
        LineItemBuilder,
        RaiseSalesInvoice,
        Presence,
        EditableText,
        Button,
        FormField,
        DrawerInvoicesTab,
        Tooltip,
        Tabs,
        User,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: true,
            isUploading: false,
            entryId: 0,
            entry: null,
            invoicesTable: null,
            invoices: [],
            availableCategories: [],
            store: store,
        };
    },
    computed: {
        isEditable() {
            return Auth.can('update revenue entries') && !this.store.get('current_event.is_closed');
        },
        isConnectedToXero() {
            return this.store.get('account.is_connected_to_xero');
        },
        lineItemsAreValid() {
            if (!this.entry) {
                return false;
            }
            return !this.entry.line_items.find(
                (lineItem) => !lineItem.quantity || !lineItem.unit_price || !lineItem.description
            );
        },
        hasInvoiceableItems() {
            if (!this.entry) {
                return false;
            }
            return Object.values(this.entry.line_items).some(function (item) {
                return (
                    item.is_invoiced === false ||
                    (item.quantity > 0 &&
                        item.unit_price > 0 &&
                        item.description.length > 0 &&
                        !item.hasOwnProperty('is_invoiced'))
                );
            });
        },
        visibleTabs() {
            const tabs = [
                {
                    title: 'Line Items',
                    name: 'line_items',
                },
            ];
            if (this.entry && this.entry.category.is_pooled) {
                return tabs;
            }
            return tabs.concat([
                {
                    title: 'Activity',
                    name: 'activity',
                },
                {
                    title: 'Files',
                    name: 'files',
                },
                {
                    title: 'Invoices',
                    name: 'invoices',
                },
            ]);
        },
    },
    watch: {
        'entry.line_items': {
            handler(newVal) {
                this.updateLineItems(newVal);
            },
            deep: true,
        },
    },
    mounted() {
        Eventbus.$on('revenue:entry', (payload) => {
            this.entryId = payload.entry;
            this.refreshData(true);
            if (this.$refs.drawer) {
                this.$refs.drawer.open();
            }
        });
        Eventbus.$on('component-selector:updated', () => {
            this.$forceUpdate();
        });
        Eventbus.$on('open:RevenueEntry', (payload) => {
            this.entryId = payload;
            this.refreshData(true);
            if (this.$refs.drawer) {
                this.$refs.drawer.open();
            }
        });
        Eventbus.$on('reloadCustomerInvoices', () => {
            if (this.entry) {
                this.initialiseInvoicesTable();
            }
        });
    },
    methods: {
        listenOnRaiseXeroInvoice() {
            Eventbus.$emit('salesInvoice:create', {
                entry: this.entryId,
            });
        },
        async initialiseInvoicesTable() {
            if (!this.entry) {
                return;
            }
            axios
                .get(
                    route('api.account.event.revenue.invoices', {
                        revenueEntry: this.entry.id,
                    })
                )
                .then(({ data }) => {
                    this.invoices = data;
                });
        },
        closeModal() {
            this.isLoading = true;
            if (this.$refs.drawer) {
                this.$refs.drawer.close();
            }
            this.reloadRelatedComponents();
        },
        refreshData() {
            axios
                .get(
                    route('api.account.event.revenue.get', {
                        revenueEntry: this.entryId,
                    })
                )
                .then(({ data }) => {
                    this.entry = data;

                    if (Auth.can('view customer invoices')) {
                        this.initialiseInvoicesTable();
                    }
                    this.reloadCategories();
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                });
        },
        reloadCategories() {
            const revenueStream = Object.values(this.store.get('current_event.revenue_streams')).find(
                (revenueStream) => revenueStream.id === this.entry.revenue_stream_id
            );
            if (!revenueStream) {
                return;
            }
            this.availableCategories = Object.values(this.store.get('current_event.categories')).filter(
                (category) => category.department_id === revenueStream.department_id && !category.is_pooled
            );
        },
        deleteEntry() {
            axios
                .delete(
                    route('api.account.event.revenue.delete', {
                        revenueEntry: this.entryId,
                    })
                )
                .then(() => {
                    this.closeModal();
                    toast('Success', 'Revenue entry deleted', 'success');
                });
        },
        updateEntry() {
            if (this.isLoading) {
                return;
            }

            this.isUploading = true;
            let formData = this.entry;
            delete formData.comments;
            delete formData.files;
            delete formData.activity;

            axios
                .post(
                    route('api.account.event.revenue.update', {
                        revenueEntry: this.entry.id,
                    }),
                    formData
                )
                .then(({ data }) => {
                    this.entry = data;
                })
                .finally(() => {
                    this.reloadRelatedComponents();
                    this.isUploading = false;
                });
        },
        debouncedUpdateEntry: debounce(function () {
            this.updateEntry();
        }, 500),
        openLinkedEntry() {
            if (!this.entry.budget_entry_id) {
                return;
            }
            Eventbus.$emit('budget:entry', {
                entry: this.entry.budget_entry_id,
                source: {
                    emitter: 'open:revenueEntry',
                    payload: this.entry.id,
                },
            });
        },
        addComment(text) {
            if (!text) {
                return;
            }
            this.isUploading = true;
            axios
                .post(
                    route('api.account.event.revenue.comment', {
                        revenueEntry: this.entryId,
                    }),
                    { text: text }
                )
                .then(() => {
                    this.refreshData();
                })
                .finally(() => (this.isUploading = false));
        },
        updateLineItems() {
            if (!this.entry || !this.lineItemsAreValid || this.isLoading) {
                return;
            }

            this.postLineItems();
        },
        postLineItems: debounce(function () {
            axios
                .post(
                    route('api.account.event.revenue.update', {
                        revenueEntry: this.entry.id,
                    }),
                    {
                        line_items: this.entry.line_items,
                    }
                )
                .then(({ data }) => {
                    if (this.entry) {
                        this.entry.subtotal = data.subtotal;
                        this.entry.total = data.total;
                    }
                    this.initialiseInvoicesTable();
                    this.reloadRelatedComponents();
                });
        }, 1500),
        viewInvoice(customerInvoiceId) {
            Eventbus.$emit('viewInvoice', 'CustomerInvoice', {
                revenueEntry: this.entry.id,
                customerInvoice: customerInvoiceId,
            });
        },
        resetState() {
            this.isLoading = true;
            this.isUploading = false;
            this.entryId = 0;
            this.entry = null;
            this.invoicesTable = null;
            this.invoices = [];
            this.availableCategories = [];
        },
        reloadRelatedComponents() {
            Eventbus.$emit('reload:entries', `department_${this.entry.category.department_id}`);
            Eventbus.$emit('reload:entries', `category_${this.entry.category_id}`);
        },
    },
};
</script>
