<template>
    <div id="onboarding-account-container">
        <div id="onboarding-account-modal">
            <h3>Create a new account</h3>
            <p class="tw-my-2">
                Enter some basic details about your organisation, you&apos;ll be able to add more details later.
            </p>
            <div class="tw-text-left tw-px-5">
                <FormField label="Company Name" class="tw-mb-2">
                    <Textbox v-model="newAccountData.name" placeholder="Required" />
                </FormField>
                <FormField label="Postal Address" class="tw-mb-2">
                    <div class="tw-flex">
                        <Textbox v-model="newAccountData.address1" placeholder="Line 1" class="tw-flex-1 tw-mr-1" />
                        <Textbox v-model="newAccountData.address2" placeholder="Line 2" class="tw-flex-1 tw-ml-1" />
                    </div>
                </FormField>
                <div class="tw-flex tw-mb-2">
                    <Textbox v-model="newAccountData.city" placeholder="City" class="tw-flex-1 tw-mr-1" />
                    <Textbox v-model="newAccountData.postcode" placeholder="Postal Code" class="tw-ml-1" />
                </div>
                <Select v-model="newAccountData.country" placeholder="Country" class="tw-w-full tw-mb-2">
                    <option v-for="locale in locales" :key="locale" :value="locale">{{ locale }}</option>
                </Select>
                <FormField label="Primary Currency">
                    <Select v-model="newAccountData.currency_code" expanded>
                        <option v-for="currency in currencies" :key="currency.key" :value="currency.key">
                            {{ currency.value }} ({{ currency.key }})
                        </option>
                    </Select>
                </FormField>
            </div>
            <div class="onboarding-action-list mt-5">
                <a
                    class="onboarding-action-button"
                    :class="{
                        disabled: !isValid,
                        loading: isCreating,
                    }"
                    href="#"
                    @click.prevent="createAccount()"
                >
                    Setup Manually
                </a>
                <!-- <template v-if="!isCreating">
                    <span class="text-muted">or</span>
                    <a class="onboarding-action-button bg-xero" href="#">
                        <img src="@/images/brands/xero-white.svg" class="icon-md mr-2" />
                        Create with Xero
                    </a>
                </template> -->
            </div>
            <div v-if="!isCreating" class="options-row justify-content-center mt-3">
                <a href="#" @click.prevent="$emit('back')">Changed your mind?</a>
            </div>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';

export default {
    components: {
        FormField,
        Textbox,
        Select,
    },
    emits: ['back', 'created'],
    data() {
        const store = useDataStore();

        return {
            isCreating: false,
            newAccountData: {
                country: 'United Kingdom',
                currency_code: 'GBP',
            },
            locales: window.Locales,
            currencies: store.get('currencies'),
            store: store,
        };
    },
    computed: {
        isValid() {
            if (!this.newAccountData) {
                return false;
            }
            return (
                !!this.newAccountData.name &&
                !!this.newAccountData.address1 &&
                !!this.newAccountData.city &&
                !!this.newAccountData.postcode &&
                !!this.newAccountData.country &&
                !!this.newAccountData.currency_code
            );
        },
    },
    methods: {
        createAccount() {
            if (!this.isValid) {
                return;
            }
            this.isCreating = true;
            axios
                .post(route('api.onboarding.create.account'), this.newAccountData)
                .then(({ data }) => this.postAccountCreation(data))
                .finally(() => (this.isLoading = false));
        },
        postAccountCreation(account) {
            account.account.pivot = {
                account_last_accessed: null,
            };
            this.store.set('available_accounts', [account.account].concat(this.store.get('available_accounts')));
            this.$emit('created', account.account);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

#onboarding-account-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }

    & > #onboarding-account-modal {
        width: min(700px, 95vw);
        max-height: calc(100vh - 2.5vw);
        padding: 25px 0 15px 0;
        text-align: center;
        background: white;
        border-radius: 10px;
        overflow-y: auto;

        a.bg-xero:hover {
            background: lighten($eventwise-xero, 10) !important;
        }
    }
}
</style>
