<template>
    <div
        class="tw-px-4 tw-py-3 tw-rounded"
        :class="{
            'tw-bg-eventwise tw-text-white': type === 'is-primary',
            'tw-bg-emerald-500 tw-text-white': type === 'is-success',
            'tw-bg-amber-500 tw-text-white': type === 'is-warning',
            'tw-bg-red-500 tw-text-white': type === 'is-danger',
            'tw-bg-sky-500 tw-text-white': type === 'is-info',
            'tw-bg-gray-500 tw-text-white': type === 'is-dark',
            'tw-bg-gray-200': !type,
            'tw-w-full tw-rounded-none': fullWidth,
            'tw-rounded': !fullWidth,
            'tw-text-xs tw-text-center tw-px-2 tw-py-1.5': condensed,
            'tw-py-3 tw-px-4': !condensed,
            'tw-sticky tw-top-0 tw-z-[100]': sticky,
        }"
    >
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: null,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        condensed: {
            type: Boolean,
            default: false,
        },
        sticky: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
