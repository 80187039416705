<template>
    <div>
        <div class="tw-flex tw-items-center tw-gap-1">
            <Button
                v-for="ticketSeller in ticketSellers"
                :key="ticketSeller.id"
                :active="ticketSeller.id === selectedTicketSeller?.id"
                @click="selectedTicketSeller = ticketSeller"
            >
                {{ ticketSeller.name }}
            </Button>
        </div>

        <TableTickets
            v-if="selectedTicketSeller && ticketSellers.length"
            :key="'table_' + (selectedTicketSeller.id ? selectedTicketSeller.id : 'direct')"
            :actuals="isActuals"
            :categories="selectedCategories"
            :revenue-stream="revenueStream"
            show-rebate
            :ticket-data="selectedTicketSeller.ticket_types"
            is-seller
            @reload-data="reloadData"
        />

        <Button
            v-if="canCreateTickets && !isLoading && (!ticketSellers || !ticketSellers.length)"
            type="is-link"
            class="tw-w-full tw-flex tw-gap-1 tw-items-center"
            @click="createTicket"
        >
            <i class="mdi mdi-plus"></i>
            Create New Ticket
        </Button>
    </div>
</template>
<script>
import TableTickets from './TicketsTable.vue';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: {
        TableTickets,
        Button,
    },
    props: {
        actuals: {
            type: Boolean,
            default: false,
        },
        categories: {
            type: Array,
            required: false,
        },
        revenueStream: {
            type: [String, Number],
            required: false,
        },
    },
    data() {
        return {
            isLoading: false,
            isActuals: false,
            ticketSellers: null,
            selectedTicketSeller: null,
            selectedCategories: null,
        };
    },
    computed: {
        canCreateTickets() {
            return Auth.can('create tickets');
        },
    },
    watch: {
        actuals(newVal) {
            this.isActuals = newVal;
        },
        categories(newVal) {
            this.selectedCategories = newVal;
        },
    },
    mounted() {
        this.isActuals = this.actuals;
        this.selectedCategories = this.categories ? this.categories : null;
        this.reloadData();
        Eventbus.$on('reload:stream', (val) => {
            if (this.revenueStream != val.stream) {
                return;
            }
            this.reloadData();
        });
    },
    methods: {
        reloadData() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.tickets.byStream', {
                        revenueStream: this.revenueStream,
                    })
                )
                .then((response) => (this.ticketSellers = this.formatTicketSellers(response.data)))
                .finally(() => (this.isLoading = false));
        },
        formatTicketSellers(ticketData) {
            let ticketSellers = {};
            ticketData.forEach((ticketType) => {
                ticketType.ticket_sales.forEach((ticketSale) => {
                    if (!ticketSale.supplier) {
                        ticketSellers['direct'] = {
                            id: null,
                            name: 'Direct Sales',
                            ticket_types: ticketData.map((tt) => {
                                tt = JSON.parse(JSON.stringify(tt));
                                tt.ticket_sales = tt.ticket_sales.filter((ts) => !ts.supplier);
                                return tt;
                            }),
                        };
                        return;
                    }
                    ticketSellers[ticketSale.supplier.id] = {
                        id: ticketSale.supplier.id,
                        name: ticketSale.supplier.name,
                        ticket_types: ticketData.map((tt) => {
                            tt = JSON.parse(JSON.stringify(tt));
                            tt.ticket_sales = tt.ticket_sales.filter((ts) => ts.supplier_id === ticketSale.supplier.id);
                            return tt;
                        }),
                    };
                });
            });
            this.selectedTicketSeller = Object.values(ticketSellers)[0];
            return Object.values(ticketSellers);
        },
        createTicket() {
            Eventbus.$emit('create:ticketType', {
                revenueStream: this.revenueStream,
                category: null,
            });
        },
    },
};
</script>
