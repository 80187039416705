<template>
    <Modal
        ref="modal"
        title="Invite Portal Contacts"
        :loading="isLoading"
        :size="modalWidth ? 'is-xxxlarge' : 'is-small'"
        :paddingless="true"
        @closing="reset()"
    >
        <template v-if="!isNewInvite">
            <div v-if="!Auth.can('manage suppliers')">
                <Banner type="is-danger" :closable="false">
                    You do not have permission to manage suppliers.
                    <br />
                    If you believe that this may be a mistake, please get in contact with your account owner.
                </Banner>
            </div>
            <div v-else class="portal-contact-invite">
                <div v-if="supplierUsers">
                    <DataTable
                        :data="supplierUsers"
                        :columns="[
                            {
                                title: 'Contact Name',
                                field: 'name',
                            },
                            {
                                title: 'Email Address',
                                field: 'email',
                            },
                            {
                                title: 'Status',
                                field: 'status',
                            },
                            {
                                title: '',
                                field: 'actions',
                                class: '!tw-text-right',
                            },
                        ]"
                    >
                        <template #column(name)="{ row }">
                            {{ row.name }}
                        </template>
                        <template #column(email)="{ row }">
                            {{ row.email }}
                        </template>
                        <template #column(status)="{ row }">
                            <Tag v-if="invitePending(row)" type="info">Invite Pending</Tag>
                            <Tag v-else type="success">Active</Tag>
                        </template>
                        <template #column(actions)="{ row }">
                            <Button
                                v-if="invitePending(row)"
                                class="tw-mr-1"
                                :disabled="resentInvites.includes(row.user_id)"
                                @click="resendInvite(row)"
                            >
                                Resend Invite
                            </Button>
                            <Button type="is-danger" @click="revokeAccess(row)">Revoke Access</Button>
                        </template>
                    </DataTable>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="!Auth.can('manage suppliers')">
                <Banner type="is-danger" :closable="false">
                    You do not have permission to manage suppliers.
                    <br />
                    If you believe that this may be a mistake, please get in contact with your account owner.
                </Banner>
            </div>
            <div v-else class="new-invite-form">
                <FormField label="Contact Name">
                    <Textbox v-model="formData.name" type="text" class="tw-w-full" required />
                </FormField>
                <FormField label="Contact Email Address" class="tw-mt-2">
                    <Textbox v-model="formData.email" type="email" class="tw-w-full" required />
                </FormField>
            </div>
        </template>
        <template #footer>
            <Button v-if="!isNewInvite" type="is-success" class="!tw-mr-auto" @click="swapInviteType">
                New Invite
            </Button>
            <Button v-if="isNewInvite" type="is-dark" class="!tw-mr-auto" @click="swapInviteType">Cancel</Button>
            <Button v-if="isNewInvite" type="is-success" @click.prevent="sendInvite">Send Invite</Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../../components/Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Banner from '@/js/components/Layout/Banner.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tag from '@/js/components/Tag.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { isValidEmail, toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        Banner,
        FormField,
        Textbox,
        Tag,
        DataTable,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            supplierId: null,
            supplierUsers: [],
            formData: {
                name: '',
                email: '',
            },
            isNewInvite: false,
            modalWidth: true,
            resentInvites: [],
        };
    },
    computed: {
        isValid() {
            return this.formData.email && isValidEmail(this.formData.email) && this.formData.name;
        },
        usersPresent() {
            return this.supplierUsers.length > 0;
        },
    },
    mounted() {
        Eventbus.$on('contactPortalInvite:open', (payload) => this.open(payload));
    },
    methods: {
        open(payload = null) {
            if (payload) {
                this.supplierId = payload;
            }
            this.reset();
            this.fetchSupplierContacts();

            this.$refs.modal.open();
        },
        reset() {
            this.formData = {
                name: '',
                email: '',
            };
            this.isLoading = false;
        },
        close() {
            this.$refs.modal.close();
        },
        fetchSupplierContacts() {
            this.isLoading = true;
            axios
                .get(route('api.account.contacts.portal', { supplier: this.supplierId }))
                .then(({ data }) => {
                    this.supplierUsers = data;
                    this.isNewInvite = !this.usersPresent;
                    this.modalWidth = !this.isNewInvite;
                })
                .finally(() => (this.isLoading = false));
        },
        sendInvite() {
            this.isLoading = true;
            if (!this.isValid) {
                this.isLoading = false;
                return;
            }
            axios
                .post(
                    route('api.account.portal.invite', {
                        supplier: this.supplierId,
                    }),
                    this.formData
                )
                .then(() => {
                    toast('Success', 'Your invite has been sent', 'success');
                    this.reset();
                    this.fetchSupplierContacts();
                })
                .finally(() => (this.isLoading = false));
        },
        resendInvite(user) {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.team.contacts.resend', {
                        user: user.user_id,
                    })
                )
                .then(() => {
                    toast('Success', 'The invitation has been resent to ' + user.name + '.', 'success');
                    this.resentInvites.push(user.user_id);
                })
                .finally(() => (this.isLoading = false));
        },
        revokeAccess(user) {
            swal.fire({
                title: 'Revoke Access',
                text: 'Are you sure that you want to remove this contact user from the Supplier Portal? They will no longer be able to access the Portal and will need to be re-invited in order to regain access.',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#4EA5D9',
                confirmButtonText: 'Revoke Access',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.isLoading = true;
                axios
                    .delete(
                        route('api.account.team.contacts.revoke', {
                            user: user.user_id,
                        })
                    )
                    .then(() => {
                        this.fetchSupplierContacts();
                        toast('Sucess', user.name + ' has been removed from the Supplier Portal.', 'success');
                    })
                    .finally(() => (this.isLoading = false));
            });
        },
        invitePending(user) {
            return user.invite != null;
        },
        swapInviteType() {
            if (this.isNewInvite && this.supplierUsers.length === 0) {
                this.close();
            }
            this.isNewInvite = !this.isNewInvite;
            this.modalWidth = !this.isNewInvite;
        },
    },
};
</script>

<style scoped lang="scss">
.portal-contact-invite {
    width: 100%;
    height: 475px;
}
.table-sizing {
    width: 960px;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.new-invite-form {
    padding: 10px 10px 20px 10px;
}
</style>
