<template>
    <div v-if="entry">
        <DataTable
            :data="adjustments"
            :columns="[
                {
                    title: 'Scenario',
                    field: 'scenario_name',
                },
                {
                    title: 'Original Cost',
                    field: 'original_cost',
                },
                {
                    title: 'Adjustment',
                    field: 'value',
                },
                {
                    title: 'Budgeted Cost',
                    field: 'budgeted_cost',
                },
                {
                    title: 'Actions',
                    field: 'id',
                },
            ]"
        >
            <template #column(scenario_name)="{ row }">
                {{ row.scenario.name }}
            </template>

            <template #column(original_cost)>
                {{ localise(parseFloat(entry.budgeted_cost), 2, true) }}
            </template>

            <template #column(value)="{ row }">
                {{ localise(parseFloat(row.value), 2, true) }}
            </template>

            <template #column(budgeted_cost)="{ row }">
                {{ localise(parseFloat(entry.budgeted_cost) + parseFloat(row.value), 2, true) }}
                <span v-html="adjustmentIndicator(row.value)"></span>
            </template>

            <template #column(actions)="{ row }">
                <Button @click="openAdjustment(row)">
                    <i class="mdi mdi-pencil"></i>
                </Button>
            </template>

            <template #empty>
                <p class="empty-label tw-px-2 tw-w-full tw-mb-0">
                    There are currently no scenario adjustments for this budget entry.
                </p>
            </template>
        </DataTable>

        <div class="tw-px-3">
            <Button
                v-if="Object.keys(availableScenarios).length && !eventIsClosed"
                type="is-primary"
                class="tw-flex tw-justify-center tw-gap-2 tw-w-full"
                @click="openAdjustment()"
            >
                <i class="mdi mdi-plus"></i>
                New Scenario Adjustment
            </Button>
            <Button
                v-else-if="!eventIsClosed"
                class="tw-flex tw-justify-center tw-gap-2 tw-w-full"
                type="is-success"
                @click="createScenario"
            >
                <i class="mdi mdi-plus"></i>
                Create A New Scenario
            </Button>
        </div>

        <ModalScenarioAdjustment ref="scenarioAdjustmentModal" :entry="entry" :adjustments="adjustments" />
    </div>
</template>

<script>
import ModalScenarioAdjustment from '../../../../../js/components/modals/Scenarios/Adjustment.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import DataTable from '@/js/components/tables/DataTable.vue';
import { localise } from '@/js/utils.js';

export default {
    components: { ModalScenarioAdjustment, Button, DataTable },
    data() {
        const store = useDataStore();
        return {
            entry: null,
            adjustments: [],
            store: store,
            scenarios: store.get('current_event.scenarios'),
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        availableScenarios() {
            const withAdjustments =
                this.adjustments.map((adj) => {
                    return adj.scenario_id;
                }) ?? [];

            return Object.values(this.scenarios).filter((scenario) => !withAdjustments.includes(scenario.id));
        },
    },
    mounted() {
        Eventbus.$on('reload:adjustmentsTab', () => {
            this.fetchAdjustments(this.entry);
        });
    },
    methods: {
        localise,
        createScenario() {
            Eventbus.$emit('close:BudgetEntry');
            Eventbus.$emit('scenario:drawerOpen');
        },
        openAdjustment(adjustment = {}) {
            this.$refs.scenarioAdjustmentModal.open(adjustment);
        },
        fetchAdjustments(entry) {
            this.entry = entry;
            this.adjustments = [];
            axios
                .get(
                    route('api.account.event.entries.adjustments', {
                        entry: this.entry,
                    })
                )
                .then(({ data }) => {
                    this.adjustments = data;
                });
        },
        adjustmentIndicator(value) {
            if (!value) {
                return '';
            }

            return parseFloat(value) > 0
                ? '<i class="mdi mdi-arrow-up-bold"></i>'
                : '<i class="mdi mdi-arrow-down-bold"></i>';
        },
    },
};
</script>
