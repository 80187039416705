<template>
    <div v-if="purchaseOrderData" class="tw-grid tw-grid-cols-2 tw-gap-3 tw-m-4">
        <FormField label="Source Currency" class="!tw-mb-2">
            {{ purchaseOrder.source_currency }}
        </FormField>
        <FormField label="Target Currency" class="!tw-mb-2">
            {{ purchaseOrder.target_currency }} ({{ purchaseOrder.conversion_rate }})
            <a v-if="!disabled" href="#" class="tw-ml-1" @click.prevent="alterCurrency()">change</a>
        </FormField>

        <control-addresses
            v-model="purchaseOrderData.delivery_address_id"
            :disabled="!Auth.can('update purchase orders') || disabled"
            label="Delivery Address"
            @update:model-value="$emit('update')"
        />
        <input-edit
            v-model="purchaseOrderData.custom_payment_details"
            :editable="!disabled"
            label="Additional Information"
            size="is-small"
            @updated="$emit('update')"
        />
        <FormField label="Attachable Documents" class="!tw-mb-2">
            <Button type="is-link" class="!tw-p-0" :disabled="disabled" @click="openAttachableDocuments()">
                {{ purchaseOrder.file_uploads.length }} document{{ purchaseOrder.file_uploads.length !== 1 ? 's' : '' }}
                included
            </Button>
        </FormField>
        <AlterCurrency ref="alterCurrencyModal" @updated="updateCurrency" />
        <AttachDocuments ref="attachDocumentsModal" @updated="saveAttachableDocuments" />
    </div>
</template>

<script>
import AlterCurrency from '../../../../../js/components/modals/AlterCurrency.vue';
import AttachDocuments from '../../../../../js/components/modals/AttachDocuments.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';

export default {
    components: {
        AlterCurrency,
        AttachDocuments,
        Button,
        FormField,
    },
    props: {
        purchaseOrder: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update'],
    data() {
        return {
            purchaseOrderData: null,
        };
    },
    watch: {
        purchaseOrder(newVal) {
            this.purchaseOrderData = newVal;
        },
    },
    mounted() {
        this.purchaseOrderData = this.purchaseOrder;
    },
    methods: {
        alterCurrency() {
            this.$refs.alterCurrencyModal.open(
                this.purchaseOrderData.source_currency,
                this.purchaseOrderData.target_currency,
                this.purchaseOrderData.conversion_rate,
                this.purchaseOrderData.total
            );
        },
        updateCurrency(payload) {
            this.purchaseOrderData.source_currency = payload.source;
            this.purchaseOrderData.target_currency = payload.target;
            this.purchaseOrderData.conversion_rate = payload.rate;
            this.$emit('update');
        },
        openAttachableDocuments() {
            this.$refs.attachDocumentsModal.open(this.purchaseOrder.file_uploads);
        },
        saveAttachableDocuments(selectedDocuments) {
            this.purchaseOrderData.file_uploads = selectedDocuments;
            this.$emit('update');
        },
    },
};
</script>
