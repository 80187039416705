<template>
    <div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <DataTable
            :columns="[
                { title: 'ID', field: 'id', width: 80 },
                { title: 'Name', field: 'name' },
                { title: 'System', field: 'system', hidden: !isSynergiUser },
            ]"
            :endpoint="
                route('api.admin.account.contacts', {
                    account,
                })
            "
            searchable
            class="align-middle"
        >
            <!-- centered -->
            <template #column(system)="{ row }">
                <Checkbox v-model="row.is_system" class="tw-mb-0" @update:model-value="updateContact(row)" />
            </template>
            <template #empty>
                <div class="empty-label">There are no contacts to show</div>
            </template>
        </DataTable>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import { useDataStore } from '@/js/stores/DataStore.js';
import axios from 'axios';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        LoadingSpinner,
        Checkbox,
    },
    props: {
        account: {
            type: [String, Number],
        },
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            data: null,
            store: store,
        };
    },
    computed: {
        isSynergiUser() {
            return this.store.get('current_user.email').endsWith('@synergitech.co.uk');
        },
    },
    methods: {
        updateContact: debounce(function (data) {
            axios
                .post(
                    route('api.admin.account.contacts.update', {
                        account: this.account,
                    }),
                    data
                )
                .then(() => {
                    toast(
                        'Contact Updated',
                        'The contact "' + data.name + '" has been updated successfully',
                        'success'
                    );
                });
        }, 750),
    },
};
</script>
