<template>
    <div>
        <template v-if="isSeller ? categorisedTicketTypes.length : true">
            <div
                v-for="(ticketCategory, categoryIndex) in categorisedTicketTypes"
                :key="'ticketCategory_' + categoryIndex"
                class="ticket-category"
            >
                <div class="category-heading">
                    <h6>{{ ticketCategory.name }}</h6>
                    <a v-if="Auth.can('manage tickets')" href="#" @click.prevent="createTicket(ticketCategory.id)">
                        New Ticket
                    </a>
                </div>
                <DataTable
                    v-if="!isLoading || tickets"
                    :columns="[
                        { title: 'Name', field: 'name', width: 300 },
                        { title: 'Price', field: 'ticket_price', width: 150 },
                        { title: 'Rebate', field: 'kickback_cost', width: 140, hidden: !showRebate },
                        { title: 'Direct Cost', field: 'direct_cost', hidden: !showRebate },
                        { title: 'Available', field: 'tickets_available', width: 120 },
                        { title: 'Sold', field: 'tickets_sold' },
                        { title: 'Actual Rebate (Gross)', field: 'actual_rebate', width: 240, hidden: !actuals },
                        { title: 'Fixed Rebate (Gross)', field: 'fixed_rebate', width: 240, hidden: actuals },
                        {
                            title: 'Actual Sales (Net)',
                            field: 'total_net_actual_revenue',
                            width: 240,
                            hidden: !actuals,
                        },
                        {
                            title: 'Fixed Sales (Net)',
                            field: 'total_net_fixed_revenue',
                            width: 240,
                            hidden: actuals,
                        },
                        {
                            title: 'Actual Sales (Gross)',
                            field: 'total_gross_actual_revenue',
                            width: 240,
                            hidden: !actuals,
                        },
                        {
                            title: 'Fixed Sales (Gross)',
                            field: 'total_gross_fixed_revenue',
                            width: 240,
                            hidden: actuals,
                        },
                        {
                            title: 'Actual Sales (Net + Rebate)',
                            field: 'actual_sales_net_rebate',
                            width: 240,
                            hidden: !actuals,
                        },
                        {
                            title: 'Actual Sales (Gross + Rebate)',
                            field: 'actual_sales_gross_rebate',
                            width: 240,
                            hidden: !actuals,
                        },
                        { title: '', field: 'action', class: 'tw-flex tw-justify-end' },
                    ]"
                    :data="ticketCategory.data"
                    class="align-middle overflow-x-0"
                    :class="{ 'border-bottom': canCreateTickets }"
                >
                    <template #column(name)="{ row }">
                        <EditableText
                            v-if="Auth.can('manage tickets')"
                            v-model="row.name"
                            placeholder="Required"
                            @update:model-value="debouncedUpdateTicket(row)"
                        />
                        <span v-else>
                            {{ row.name }}
                        </span>
                    </template>

                    <template #column(ticket_price)="{ row }">
                        <EditableText
                            v-if="Auth.can('manage tickets') && !hasOrders(row)"
                            v-model="row.ticket_price"
                            class="tw-flex"
                            type="currency"
                            @update:model-value="debouncedUpdateTicket(row)"
                        />
                        <span v-else class="tw-flex">
                            {{ localise(row.ticket_price, 2, true) }}
                        </span>
                        <Tooltip
                            v-if="hasAnomalousOrders(row)"
                            label="This ticket type contains anomalous transactions where the sold ticket price does not match the one set in Eventwise"
                            position="right"
                        >
                            <i class="mdi mdi-alert tw-text-red-500"></i>
                        </Tooltip>
                        <Tooltip
                            v-else-if="hasOrders(row)"
                            label="This ticket type contains transactions and therefore the price can no longer be edited"
                            position="right"
                        >
                            <i class="mdi mdi-exclamation-thick tw-text-amber-500"></i>
                        </Tooltip>
                    </template>

                    <template #column(kickback_cost)="{ row }">
                        <PercentageValue
                            v-if="Auth.can('manage tickets')"
                            v-model="row.ticket_sales[0].kickback_cost"
                            :base-value="row.ticket_price"
                            currency
                            :step="0.0001"
                            size="is-small"
                            @update:model-value="debouncedUpdateTicket(row)"
                        />
                        <span v-else>
                            {{ localise(row.ticket_sales.length ? row.ticket_sales[0].kickback_cost : 0, 2, true) }}
                        </span>
                    </template>

                    <template #column(direct_cost)="{ row }">
                        <EditableText
                            v-if="Auth.can('manage tickets')"
                            v-model="row.ticket_sales[0].direct_cost"
                            type="currency"
                            @update:model-value="debouncedUpdateTicket(row)"
                        />
                        <span v-else>
                            {{ localise(row.ticket_sales.length ? row.ticket_sales[0].direct_cost : 0, 2, true) }}
                        </span>
                    </template>

                    <!-- centered width="120" -->
                    <template #column(tickets_available)="{ row }">
                        <EditableText
                            v-if="Auth.can('manage tickets') && isSeller"
                            v-model="row.ticket_sales[0].tickets_available"
                            type="number"
                            @update:model-value="debouncedUpdateTicket(row)"
                        />
                        <span v-else>
                            {{ localise(arraySum(row.ticket_sales, 'tickets_available'), 0, false) }}
                        </span>
                    </template>

                    <!-- width="120" -->
                    <template v-if="actuals" #column(tickets_sold)="{ row }">
                        {{ localise(arraySum(row.ticket_sales, 'tickets_sold'), 0, false) }}
                    </template>

                    <!-- Rebate (Gross) -->
                    <template v-if="actuals" #column(actual_rebate)="{ row }">
                        {{ localise(arraySum(row.ticket_sales, 'actual_rebate'), 2, true) }}
                    </template>
                    <template v-else #column(fixed_rebate)="{ row }">
                        {{ localise(calcRebate(row, 'tickets_available'), 2, true) }}
                    </template>

                    <!-- Sales (Net) -->
                    <template #column(total_net_actual_revenue)="{ row }">
                        {{ localise(calcSales({ row, gross: false, rebate: false }), 2, true) }}
                    </template>
                    <template #column(total_net_fixed_revenue)="{ row }">
                        {{ localise(calcSales({ row, gross: false, rebate: true }), 2, true) }}
                    </template>
                    <template #column(actual_sales_net_rebate)="{ row }">
                        {{ localise(calcSales({ row, gross: false, rebate: true }), 2, true) }}
                    </template>

                    <!-- Sales (Gross) -->
                    <template #column(total_gross_actual_revenue)="{ row }">
                        {{ localise(calcSales({ row, gross: true, rebate: false }), 2, true) }}
                    </template>
                    <template #column(total_gross_fixed_revenue)="{ row }">
                        {{ localise(calcSales({ row, gross: true, rebate: true }), 2, true) }}
                    </template>
                    <template #column(actual_sales_gross_rebate)="{ row }">
                        {{ localise(calcSales({ row, gross: true, rebate: true }), 2, true) }}
                    </template>

                    <template #column(action)="{ row }">
                        <Tooltip position="left" label="View Ticket">
                            <Button @click.prevent="viewTicket(row.id)">
                                <i class="mdi mdi-eye"></i>
                            </Button>
                        </Tooltip>
                    </template>
                </DataTable>
            </div>
        </template>

        <Button
            v-if="canCreateTickets && !isLoading && !categorisedTicketTypes.length"
            type="is-link"
            class="tw-w-full tw-flex tw-gap-1 tw-items-center"
            @click="createTicket()"
        >
            <i class="mdi mdi-plus"></i>
            Create New Ticket
        </Button>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import EditableText from '../../controls/EditableText.vue';
import PercentageValue from '../../widgets/PercentageValue.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { gross2net } from '@/js/utils.js';

export default {
    components: {
        EditableText,
        PercentageValue,
        Button,
        Tooltip,
        DataTable,
    },
    props: {
        revenueStream: {
            type: [String, Number],
            required: false,
        },
        actuals: {
            type: Boolean,
            default: false,
        },
        showRebate: {
            type: Boolean,
            default: false,
        },
        ticketData: {
            type: Array,
            required: false,
        },
        categories: {
            type: Array,
            required: false,
        },
        isSeller: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reload-data'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            tickets: null,
            store: store,
        };
    },
    computed: {
        canCreateTickets() {
            return Auth.can('manage tickets');
        },
        categorisedTicketTypes() {
            if (!this.tickets) {
                return [];
            }
            const cats = {};
            this.tickets.forEach((ticketType) => {
                const keyId = ticketType.category_id ? ticketType.category_id : 0;
                if (!cats[keyId]) {
                    const category = this.store.get('current_event.categories.' + keyId)
                        ? this.store.get('current_event.categories.' + keyId)
                        : {
                              id: 0,
                              name: 'Uncategorised',
                          };
                    cats[keyId] = {
                        id: category.id ? category.id : null,
                        name: category.name,
                        data: [],
                    };
                }
                cats[keyId]['data'].push(ticketType);
            });

            if (this.isSeller) {
                Object.keys(cats).forEach((c) => {
                    cats[c].data = cats[c].data.filter((tc) => tc.ticket_sales.length);
                });
            }

            return this.categories
                ? Object.values(cats).filter((c) => this.categories.includes(c.id))
                : Object.values(cats);
        },
    },
    watch: {
        revenueStream() {
            this.reloadData();
        },
        ticketData: {
            handler(newVal) {
                this.tickets = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        if (this.ticketData) {
            this.tickets = this.ticketData;
        } else {
            this.reloadData();
        }

        Eventbus.$on('reload:stream', (val) => {
            if (this.revenueStream !== val.stream) {
                return;
            } else if (this.isSeller) {
                this.$emit('reload-data');
                return;
            }
            this.reloadData();
        });
    },
    methods: {
        reloadData() {
            if (!this.revenueStream) {
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.tickets.byStream', {
                        revenueStream: this.revenueStream,
                    })
                )
                .then((response) => {
                    this.tickets = response.data;
                })
                .finally(() => (this.isLoading = false));
        },
        arraySum(arr, key) {
            return arr.reduce((acca, value) => acca + value[key], 0);
        },
        calcRebate(row, modifier) {
            let total = 0;
            row.ticket_sales.forEach((ticketSale) => {
                const subtotal = ticketSale[modifier] * ticketSale.kickback_cost;
                total += subtotal;
            });
            return total;
        },
        calcSales({ row, gross, rebate }) {
            let totalValue = 0;
            row.ticket_sales.forEach((ticketSale) => {
                const taxModifier = ticketSale.tax_rate ? ticketSale.tax_rate.rate : 0;
                if (this.actuals) {
                    totalValue += gross ? ticketSale.actual_revenue : gross2net(ticketSale.actual_revenue, taxModifier);

                    if (rebate) {
                        totalValue += ticketSale.actual_rebate;
                    }
                    return totalValue;
                }
                const grossTicketRevenue = ticketSale['tickets_available'] * row.ticket_price;
                if (!gross) {
                    totalValue += gross2net(grossTicketRevenue, taxModifier);
                    return totalValue;
                }
                if (rebate) {
                    const grossRebate = ticketSale['tickets_available'] * ticketSale.kickback_cost;
                    totalValue += grossTicketRevenue + grossRebate;
                }
                return;
            });
            return totalValue;
        },
        viewTicket(ticketTypeId = null) {
            Eventbus.$emit('view:ticketType', ticketTypeId);
        },
        createTicket(categoryId = null) {
            if (!this.revenueStream) {
                return;
            }
            Eventbus.$emit('create:ticketType', {
                revenueStream: this.revenueStream,
                category: categoryId,
            });
        },
        updateTicket(ticketType) {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.tickets.updateSingle', {
                        ticketType: ticketType.id,
                    }),
                    ticketType
                )
                .then(() => {
                    if (!this.isSeller) {
                        this.reloadData();
                    }
                })
                .finally(() => {
                    if (this.isSeller) {
                        this.isLoading = false;
                    }
                });
        },
        debouncedUpdateTicket: debounce(function (ticketType) {
            this.updateTicket(ticketType);
        }, 1000),
        hasOrders(ticketType) {
            return ticketType.ticket_sales.find((ticketSale) => {
                return ticketSale.has_orders;
            });
        },
        hasAnomalousOrders(ticketType) {
            return ticketType.ticket_sales.find((ticketSale) => {
                return ticketSale.has_anomalous_orders;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.ticket-category {
    margin: 5px;
    border: 1px solid rgba(black, 0.1);
    border-radius: 4px;

    .category-heading {
        display: flex;
        align-items: center;
        color: rgba(black, 0.4);
        background: rgba(black, 0.01);
        border-bottom: 1px solid rgba(black, 0.1);
        width: 100%;
        padding: 0px 8px;

        & > h6 {
            padding: 8px 0 5px 0;
            margin-right: auto;
            color: rgba(black, 0.4);
        }
        & > a {
            font-family: $eventwise-font-heading;
            margin-top: 2px;
        }
    }
}
</style>
