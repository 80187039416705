<template>
    <Modal
        ref="modal"
        title="Attachable Documents"
        size="is-xxxlarge"
        :loading="isLoading"
        paddingless
        @closed="reset()"
    >
        <DataTable
            v-model="selectedDocuments"
            centered
            :data="availableDocuments"
            checkable
            :columns="[
                {
                    title: 'Filename',
                    field: 'file_name',
                },
                {
                    title: 'Description',
                    field: 'description',
                },
                {
                    title: 'Uploaded',
                    field: 'created_at',
                },
            ]"
        >
            <!-- eslint-disable -->
            <template #column(file_name)="{ row }">
                {{ row.file_name }}
            </template>
            <template #column(description)="{ row }">
                {{ row.description }}
            </template>
            <template #column(created_at)="{ row }">
                {{ localTimestamp(row.created_at) }} {{ row.user ? ' by ' + row.user.name : '' }}
            </template>
            <template #empty>
                <div class="text-center text-muted">
                    There are no attachable documents available.
                    <span v-if="Auth.can('update organisation')">
                        You can upload some in your
                        <a
                            :href="
                                route('account.settings', {
                                    page: 'attachables',
                                })
                            "
                            target="_blank"
                        >
                            account settings
                        </a>
                        .
                    </span>
                </div>
            </template>
            <!-- eslint-enable -->
        </DataTable>
        <template #footer>
            <Button class="!tw-mr-auto" @click="close()">Cancel</Button>
            <Button type="is-success" @click="saveChanges()">Save Changes</Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { localTimestamp, toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        DataTable,
    },
    emits: ['updated'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            currentAccount: store.get('account'),
            availableDocuments: [],
            selectedDocuments: [],
        };
    },
    methods: {
        localTimestamp,
        open(selected = []) {
            this.selectedDocuments = selected;
            this.getAvailableDocuments();
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.availableDocuments = [];
            this.selectedDocuments = [];
        },
        saveChanges() {
            this.isLoading = true;
            this.$emit('updated', this.selectedDocuments);
            this.close();
        },
        getAvailableDocuments() {
            if (!this.currentAccount) {
                toast('Error', 'Unable to retrieve the active account to load attachable documents', 'error');
                return;
            }
            this.isLoading = true;
            axios
                .get(route('api.account.files'))
                .then(({ data }) => (this.availableDocuments = data))
                .finally(() => (this.isLoading = false));
        },
        isRowSelected(a, b) {
            return a.id === b.id;
        },
    },
};
</script>
