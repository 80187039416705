<template>
    <Modal ref="inviteUserModal" title="Invite Team Member" size="is-xlarge" :loading="isLoading" @closed="reset()">
        <p>The person below will receive an email invitation to join {{ currentAccount.name }}.</p>
        <FormField label="Name">
            <Textbox v-model="newUserName" maxlength="255" placeholder="Required" required />
        </FormField>
        <FormField class="tw-mt-2" label="Email Address">
            <Textbox v-model="newUserEmail" type="email" maxlength="255" placeholder="Required" required />
        </FormField>
        <FormField class="tw-mt-2" label="User Group">
            <Select v-model="newUserGroup" expanded placeholder="Required" required>
                <option v-for="role in Object.values(currentAccount.user_groups)" :key="role.id" :value="role.id">
                    {{ role.name }}
                </option>
            </Select>
        </FormField>
        <Banner class="tw-mt-3" type="is-info">
            Once invited you will be taken to the user page where you can set which events and subcategories
            {{ newUserName ? newUserName : 'the user' }} can access.
        </Banner>
        <template #footer>
            <Button class="tw-mr-auto" @click="close()">Cancel</Button>
            <Button type="is-success" :disabled="!isValid || isLoading" @click="sendInvite()">Send Invite</Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
        Select,
        Banner,
    },
    emits: ['invited'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            currentAccount: store.get('account'),
            newUserName: null,
            newUserEmail: null,
            newUserGroup: null,
        };
    },
    computed: {
        inviteEmailIsValid() {
            const expression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return !!(this.newUserEmail && this.newUserEmail.match(expression));
        },
        isValid() {
            return this.newUserName && this.inviteEmailIsValid && this.newUserGroup;
        },
    },
    methods: {
        open() {
            this.$refs.inviteUserModal.open();
        },
        close() {
            this.$refs.inviteUserModal.close();
        },
        reset() {
            this.newUserName = null;
            this.newUserEmail = null;
            this.newUserGroup = null;
        },
        sendInvite() {
            if (!this.isValid) {
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.team.invite'), {
                    user_name: this.newUserName,
                    user_email: this.newUserEmail,
                    groupselect: this.newUserGroup,
                    events: [],
                    categories: [],
                })
                .then(({ data }) => {
                    this.$emit('invited', data.id);
                    this.close();
                })
                .catch(() => (this.isLoading = false))
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
