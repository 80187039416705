<template>
    <div class="ew-user-display-container">
        <div v-for="user in displayedUsers" :key="user.id" class="ew-user">
            <slot :user="user"><User :user="user" size="small" /></slot>
        </div>
    </div>
</template>
<script>
import User from './User.vue';

export default {
    components: {
        User,
    },
    props: {
        users: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            displayedUsers: this.users,
        };
    },
    watch: {
        users(newVal) {
            this.displayedUsers = newVal;
        },
    },
};
</script>
<style lang="scss" scoped>
.ew-user-display-container {
    display: flex;
    flex-wrap: wrap;

    & > div {
        margin-right: -10px;
        cursor: default;

        &:hover {
            z-index: 10;
        }
    }
}
</style>
