<template>
    <LoadingSpinner v-if="isLoading" label="Loading Ticket Data" />
    <div v-else class="buefy-wrapper">
        <Banner class="mt-5 mb-4 py-3" type="is-primary">
            <p>
                This report displays the Ticket comparisons for the events within a single group.
                <br />
                If no Events have been selected, the option to select groups is available to provide comparisons across
                tags
                <br />
            </p>
        </Banner>
        <div class="card justify-content-start p-3">
            <div class="row ml-2">
                <p class="h3 ml-3 my-2 float-left">Group Tag Ticket Report</p>
                <GroupTag class="float-left ml-2" :tag="tagItem(tagIds[0])" />
            </div>
            <div class="mt-2">
                <DataTable
                    :data="availableEvents"
                    centered
                    :columns="[
                        {
                            title: 'Event',
                            field: 'name',
                        },
                        {
                            title: 'Starts At',
                            field: 'starts_at',
                        },
                        {
                            title: 'Ends At',
                            field: 'ends_at',
                        },
                        {
                            title: 'Tickets Available',
                            field: 'ticket_sales_sum_tickets_available',
                        },
                        {
                            title: 'Tickets Sold',
                            field: 'ticket_orders_sum_quantity',
                        },
                        {
                            title: 'Tickets left',
                            field: 'ticket_left',
                        },
                        {
                            title: 'Avg Price',
                            field: 'avg_price',
                        },
                    ]"
                >
                    <template #column(starts_at)="{ row }">
                        {{ toDateString(row.starts_at) }}
                    </template>
                    <template #column(ends_at)="{ row }">
                        {{ toDateString(row.ends_at) }}
                    </template>
                    <template #column(ticket_sales_sum_tickets_available)="{ row }">
                        {{ catchNull(row.ticket_sales_sum_tickets_available) }}
                    </template>
                    <template #column(ticket_orders_sum_quantity)="{ row }">
                        {{ catchNull(row.ticket_orders_sum_quantity) }}
                        ({{
                            percentageSold(
                                row.ticket_sales_sum_tickets_available,

                                row.ticket_orders_sum_quantity
                            )
                        }}%)
                    </template>
                    <template #column(ticket_left)="{ row }">
                        {{ row.ticket_sales_sum_tickets_available - row.ticket_orders_sum_quantity }}
                    </template>
                    <template #column(avg_price)="{ row }">
                        {{ localise(row.ticketSales_mean, 2, true) }}
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '../../../../../js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import GroupTag from '@/js/components/GroupTag.vue';
import LoadingSpinner from '@/js/components/widgets/LoadingSpinner.vue';

export default {
    components: {
        LoadingSpinner,
        DataTable,
        GroupTag,
        Banner,
    },
    props: {
        tagIds: {
            type: Array,
            required: true,
        },
    },
    emits: [],
    data() {
        return {
            isLoading: true,
            eventIds: [],
            eventData: [],
            availableEvents: [],
            filteredEvents: [],
            localTags: [],
            tagCount: 0,
            eventClass: 'App\\Models\\Event',
            allAssignedTags: [],
        };
    },
    watch: {},
    mounted() {
        this.fetchTags();
    },
    methods: {
        fetchTags() {
            this.isLoading = true;
            this.localTags = [];
            this.profitsAndLosses = [];
            this.tagCount = this.tagIds.length;
            // foreach tagId
            for (const tagId of this.tagIds) {
                // get the localTags for the tags
                this.reloadTagEvents(tagId);
            }
        },
        reloadTagEvents(tagId) {
            axios
                .get(
                    route('api.account.reporting.tagAnalysis', {
                        groupTag: tagId,
                    })
                )
                .then(({ data }) => {
                    this.localTags.push(data.tag);
                    this.fetchGroupTags(tagId, this.eventClass);
                });
        },
        fetchGroupTags(tagId, tagClass) {
            axios
                .post(route('api.account.tags.allAssigned'), { class: tagClass })
                .then(({ data }) => {
                    this.allAssignedTags = data.tags;
                    this.filterTagEvents(tagId);
                    this.fetchEventsData();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        filterTagEvents(tagId) {
            this.filteredEvents = this.allAssignedTags.filter((item) => item.id === tagId)[0].events;
            this.eventIds = this.filteredEvents.map((item) => item.id);
        },
        fetchEventsData() {
            // Fetch the Selected Events
            this.isLoading = true;
            axios
                .get(route('api.account.events.fetch-events', { eventIds: this.eventIds }))
                .then(({ data }) => {
                    this.eventData = data;
                    this.filterNoTickets();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        toDateString(dateStr) {
            const date = new Date(dateStr);
            return date.toDateString();
        },
        catchNull(value) {
            if (value == null) {
                return 0;
            }
            return value;
        },
        percentageSold(available, quantity) {
            // check for available and quantity being 0 or null to avoid divide by zero errors
            if (available === 0 || quantity === 0 || available == null || quantity == null) {
                return 0;
            }
            return ((quantity / available) * 100).toFixed(2);
        },
        filterNoTickets(filter = false) {
            // filter against having no tickets
            if (!filter) {
                this.availableEvents = this.eventData;
                return;
            }
            this.availableEvents = this.eventData.filter((event) => event.ticket_sales_sum_tickets_available > 0);
        },
        tagItem(tagId) {
            return this.localTags.filter((item) => item.id === tagId)[0];
        },
    },
};
</script>

<style scoped lang="scss"></style>
