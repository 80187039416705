<template>
    <div>
        <small v-if="!lastSaved && !isSaving">Not yet saved</small>
        <small v-else-if="isSaving" class="d-flex align-items-center">
            <div class="loader"></div>
            &nbsp; Saving your changes
        </small>
        <small v-else-if="lastSaved">Saved{{ lastSavedTimestamp }}</small>
    </div>
</template>
<script>
export default {
    props: {
        saving: {
            type: Boolean,
            default: false,
        },
        saved: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isSaving: false,
            lastSaved: null,
            updateInterval: null,
            updateKey: 0,
        };
    },
    computed: {
        lastSavedTimestamp() {
            this.updateKey;
            if (!this.lastSaved) {
                return '';
            }
            return (' ' + this.lastSaved.toRelative())
                .replace(' 0 seconds ago', ' just now')
                .replace(' in 0 seconds', ' just now');
        },
    },
    watch: {
        saving(newVal) {
            this.isSaving = newVal;
        },
        saved(newVal) {
            this.lastSaved = newVal ? newVal : null;
        },
    },
    mounted() {
        this.isSaving = this.saving;
        if (this.saved) {
            this.lastSaved = this.saved;
        }
        this.updateInterval = setInterval(() => this.updateKey++);
    },
    destroyed() {
        clearInterval(this.updateInterval);
    },
};
</script>
