<template>
    <div class="buefy-wrapper">
        <div v-if="payrun" class="payrun-actions-header mt-4">
            <Button class="tw-flex tw-gap-2" @click="$emit('return')">
                <i class="mdi mdi-arrow-left"></i>
                Return to Payruns
            </Button>
            <div class="payrun-title">
                {{ payrun.title }}
            </div>
        </div>
        <div v-if="payrun" class="account-payrun-details">
            <div class="d-flex align-items-center" style="gap: 25px">
                <FormField label="Title" class="tw-mr-auto">
                    <h5>
                        <EditableText
                            v-model="payrun.title"
                            placeholder="Payrun Title"
                            @update:model-value="debouncedUpdatePayrun()"
                        />
                    </h5>
                </FormField>
                <FormField label="Payment Date">
                    {{ DateTime.fromSQL(payrun.payment_date).toLocaleString(DateTime.DATE_FULL) }}
                </FormField>
                <FormField label="Status">
                    <template v-if="payrun.status && payrun.status !== 'Paid'">
                        <ApprovalControls :approvables="[payrun]" @reload="fetchPayrun(payrunId)" />
                    </template>
                    <template v-else>
                        <span v-if="!payrun.status" class="tag">Cancelled</span>
                        <span v-else class="tag is-success is-light">Paid</span>
                    </template>
                </FormField>
                <div v-if="payrun.status && Auth.can('manage payruns') && isApproved && !isPaid">
                    <button class="button is-small is-success approvals-style-button" @click="completeRun()">
                        Mark As Paid
                    </button>
                </div>
            </div>
        </div>
        <div class="account-payrun-container">
            <div class="account-payrun-header">
                <div class="bg-payee" style="width: 50%">Payee Information</div>
                <div class="flex-fill bg-event">Event Information</div>
                <div
                    v-if="isDraft || isAwaitingApproval || isApproved"
                    :style="isAwaitingApproval || isDraft ? 'width: 75px' : 'width: 100px'"
                ></div>
            </div>
            <DataTable
                :data="payrun ? payrun.items : []"
                :columns="[
                    {
                        title: 'Payee',
                        field: 'payee',
                    },
                    {
                        title: 'Purchase Order',
                        field: 'purchase_order',
                    },
                    {
                        title: 'Invoice',
                        field: 'invoice',
                    },
                    {
                        title: 'Invoice Amount',
                        field: 'invoice_amount',
                    },
                    {
                        title: 'Payment Amount',
                        field: 'payment_amount',
                    },
                    {
                        title: 'Event',
                        field: 'event',
                    },
                    {
                        title: 'Department',
                        field: 'department',
                    },
                    {
                        title: 'Subcategory',
                        field: 'subcategory',
                    },
                    {
                        title: 'Paid',
                        field: 'is_paid',
                    },
                    {
                        title: '',
                        field: 'actions',
                    },
                ]"
            >
                <template #column(payee)="{ row }">
                    <a href="#" @click.prevent="viewSupplier(row.invoice.supplier.id)">
                        {{ row.invoice.supplier.name }}
                    </a>
                </template>
                <template #column(purchase_order)="{ row }">
                    <span v-if="row.invoice.purchase_orders.length > 1" class="text-muted">Multiple</span>
                    <a v-else href="#" @click.prevent="viewPurchaseOrder(row.invoice.purchase_orders[0])">
                        {{ row.invoice.purchase_orders[0].title }}
                    </a>
                </template>
                <template #column(invoice)="{ row }">
                    <a href="#" @click.prevent="viewInvoice(row.invoice)">
                        {{ row.invoice.invoice_number }}
                    </a>
                </template>
                <template #column(invoice_amount)="{ row }">
                    {{ localise(row.invoice.total_value, 2, true) }}
                </template>
                <template #column(payment_amount)="{ row }">
                    {{ localise(row.amount, 2, true) }}
                </template>
                <template #column(event)="{ row }">
                    <a
                        :href="
                            route('account.event.dashboard', {
                                event: row.invoice.event.id,
                            })
                        "
                        target="_blank"
                    >
                        {{ row.invoice.event.name }}
                        <i class="mdi mdi-open-in-new"></i>
                    </a>
                </template>
                <template #column(department)="{ row }">
                    <span v-if="hasMultipleDepartments(row)" class="text-muted">Multiple</span>
                    <a
                        v-else
                        :href="
                            route('account.event.purchaseorders.summary', {
                                event: row.invoice.event.id,
                                page: 'summary',
                                departmentId: row.invoice.purchase_orders[0].category.department_id,
                            })
                        "
                        target="_blank"
                    >
                        {{ row.invoice.purchase_orders[0].category.department.name }}
                        <i class="mdi mdi-open-in-new"></i>
                    </a>
                </template>
                <template #column(subcategory)="{ row }">
                    <span v-if="hasMultipleSubcategories(row)" class="text-muted">Multiple</span>
                    <a
                        v-else
                        :href="
                            route('account.event.purchaseorders.summary', {
                                event: row.invoice.event.id,
                                page: 'summary',
                                departmentId: row.invoice.purchase_orders[0].category.department_id,
                            })
                        "
                        target="_blank"
                    >
                        {{ row.invoice.purchase_orders[0].category.name }}
                        <i class="mdi mdi-open-in-new"></i>
                    </a>
                </template>
                <template #column(paid)="{ row }">
                    <Checkbox
                        v-model="row.is_paid"
                        class="tw-m-0 tw-ml-2"
                        :disabled="isPaid || !Auth.can('manage payruns')"
                        @update:model-value="debouncedUpdatePayrun()"
                    />
                </template>
                <template #column(actions)="{ row }">
                    <Dropdown v-if="isDraft && Auth.can('manage payruns')">
                        <a href="#" @click.prevent="removeFromPayrun(row.id)">Remove from Payrun</a>
                    </Dropdown>
                    <Tooltip
                        v-else-if="isAwaitingApproval && Auth.can('manage payruns')"
                        label="Reject Item"
                        position="left"
                    >
                        <Button type="is-danger" :disabled="payrun.items.length <= 1" @click="rejectItem(row.id)">
                            <i class="mdi mdi-close"></i>
                        </Button>
                    </Tooltip>
                </template>
                <template v-if="isDraft && availablePayments.length && Auth.can('manage payruns')" #footer>
                    <Button type="is-link" class="tw-w-full tw-flex tw-gap-2 tw-justify-center" @click="addPayment()">
                        <i class="mdi mdi-plus"></i>
                        Add another payment ({{ availablePayments.length }} available)
                    </Button>
                </template>
            </DataTable>
            <template v-if="payrun">
                <div class="d-flex">
                    <div class="d-flex" style="width: 50%">
                        <div class="flex-fill bg-status p-2">Total Submitted</div>
                        <div class="p-2 text-right" style="width: 20%">
                            {{ localise(totalValue, 2, true) }}
                        </div>
                    </div>
                    <div class="flex-fill bg-status"></div>
                </div>
                <div class="d-flex">
                    <div class="d-flex" style="width: 50%">
                        <div class="flex-fill bg-success p-2">Total Paid</div>
                        <div class="p-2 text-right" style="width: 20%">
                            {{ localise(totalPaid, 2, true) }}
                        </div>
                    </div>
                    <div class="flex-fill bg-success"></div>
                </div>
                <div class="d-flex mt-2">
                    <div class="d-flex" style="width: 50%">
                        <div class="flex-fill p-2">Account Balance</div>
                        <div class="p-2 text-right" style="width: 20%">
                            <EditableText
                                v-model="payrun.account_balance"
                                type="currency"
                                :editable="Auth.can('manage payruns')"
                                @update:model-value="debouncedUpdatePayrun()"
                            />
                        </div>
                    </div>
                    <div class="flex-fill align-self-center">
                        <HelpLabel
                            v-if="Auth.can('manage payruns')"
                            text="An arbitrary figure that is unique to this payrun. It can be set to assist with visualising the funds to transfer below."
                        />
                    </div>
                </div>
                <div class="d-flex">
                    <div class="d-flex" style="width: 50%">
                        <div class="flex-fill p-2">Funds to Transfer</div>
                        <div class="p-2 text-right" style="width: 20%">
                            {{ localise(payrun.account_balance - totalValue, 2, true) }}
                        </div>
                    </div>
                    <div class="flex-fill"></div>
                </div>
            </template>
        </div>
        <AddPaymentModal ref="addPaymentModal" :payrun="payrun" @add="addPayments" />
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import ApprovalControls from '../../../widgets/ApprovalControls.vue';
import EditableText from '../../../controls/EditableText.vue';
import Dropdown from '../../../controls/Dropdown.vue';
import HelpLabel from '../../../widgets/HelpLabel.vue';
import AddPaymentModal from './ViewPayrun/AddPaymentModal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { uniqueArray } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        EditableText,
        ApprovalControls,
        Dropdown,
        HelpLabel,
        AddPaymentModal,
        Button,
        FormField,
        Checkbox,
        Tooltip,
        DataTable,
    },
    props: {
        payrunId: {
            type: Number,
            required: true,
        },
    },
    emits: ['return'],
    data() {
        return {
            isLoading: false,
            payrun: null,
            availablePayments: [],
            DateTime,
        };
    },
    computed: {
        totalValue() {
            if (!this.payrun) {
                return 0;
            }
            return this.payrun.items.map((i) => parseFloat(i.amount)).reduce((p, c) => p + c, 0);
        },
        totalPaid() {
            if (!this.payrun) {
                return 0;
            }
            return this.payrun.items
                .filter((i) => i.is_paid)
                .map((i) => parseFloat(i.amount))
                .reduce((p, c) => p + c, 0);
        },
        isDraft() {
            return this.payrun?.status === 'Draft';
        },
        isAwaitingApproval() {
            return this.payrun?.status === 'Awaiting Approval';
        },
        isApproved() {
            return ['Approved', 'Paid'].includes(this.payrun?.status);
        },
        isPaid() {
            return this.payrun?.status === 'Paid';
        },
    },
    watch: {
        payrunId(newVal) {
            this.fetchPayrun(newVal);
        },
    },
    mounted() {
        this.fetchPayrun(this.payrunId);
    },
    methods: {
        fetchPayrun(id) {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.payruns.get', {
                        payrun: id,
                    })
                )
                .then(({ data }) => {
                    this.payrun = data;
                    this.getAvailablePayments();
                })
                .finally(() => (this.isLoading = false));
        },
        updatePayrun() {
            if (!Auth.can('manage payruns') || !this.payrun) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.payruns.update', {
                        payrun: this.payrun.id,
                    }),
                    this.payrun
                )
                .then(({ data }) => {
                    this.payrun = data;
                    this.getAvailablePayments();
                })
                .finally(() => (this.isLoading = false));
        },
        debouncedUpdatePayrun: debounce(function () {
            this.updatePayrun();
        }, 500),
        removeFromPayrun(id) {
            this.payrun.items = this.payrun.items.filter((pi) => pi.id !== id);
            this.updatePayrun();
        },
        async rejectItem(id) {
            if (!this.payrun.current_user_can_approve) {
                return;
            }
            const { value: rejectReason } = await swal.fire({
                title: 'Reject Payment',
                text: 'Rejecting this payment will remove it from the payrun entirely ',
                input: 'text',
                inputPlaceholder: 'Provide a reason for rejecting the payment',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You must provide a rejection reason';
                    } else if (value.length > 255) {
                        return 'The rejection reason should be 255 characters or less';
                    }
                },
            });
            if (!rejectReason) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.payruns.rejectPayment', {
                        payrun: this.payrun.id,
                        paymentDate: id,
                    }),
                    {
                        reject_reason: rejectReason,
                    }
                )
                .then((response) => {
                    this.payrun = response.data;
                    this.getAvailablePayments();
                })
                .finally(() => (this.isLoading = false));
        },
        completeRun() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.payments.payruns.complete', {
                        payrun: this.payrun.id,
                    })
                )
                .then(({ data }) => (this.payrun = data))
                .finally(() => (this.isLoading = false));
        },
        getAvailablePayments() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.payruns.payments', {
                        end: this.payrun.payment_date.split(' ')[0],
                    })
                )
                .then(({ data }) => (this.availablePayments = data.data))
                .finally(() => (this.isLoading = false));
        },
        addPayment() {
            this.$refs.addPaymentModal.open();
        },
        addPayments(payments) {
            this.isLoading = true;
            payments.forEach((sp) => this.payrun.items.push(sp));
            this.updatePayrun();
        },
        hasMultipleDepartments(row) {
            return uniqueArray(row.invoice.purchase_orders.map((po) => po.category.department_id)).length > 1;
        },
        hasMultipleSubcategories(row) {
            return uniqueArray(row.invoice.purchase_orders.map((po) => po.category_id)).length > 1;
        },
        viewSupplier(supplierId) {
            Eventbus.$emit('view:supplier', supplierId);
        },
        viewPurchaseOrder(purchaseOrder) {
            Eventbus.$emit('purchaseOrder:view', {
                event: purchaseOrder.event_id,
                purchaseOrder: purchaseOrder.id,
            });
        },
        viewInvoice(invoice) {
            Eventbus.$emit('viewInvoice', 'SupplierInvoice', {
                event: invoice.event_id,
                purchaseOrder: invoice.purchase_orders[0].id,
                supplierInvoice: invoice.id,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.payrun-actions-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & > .payrun-title {
        margin-left: 10px;
    }
}
.account-payrun-container {
    background: white;
    border-radius: 4px;
    overflow: hidden;

    .account-payrun-header {
        display: flex;
        font-family: $eventwise-font-heading;
        text-align: center;

        & > div {
            padding-top: 8px;
            padding-bottom: 5px;
            text-transform: uppercase;
            font-size: 0.85em;
            color: rgba(black, 0.5);
            letter-spacing: 1px;
        }
    }
}

.account-payrun-details {
    background: white;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
}
</style>
