<template>
    <div class="integration-eventbrite buefy-wrapper">
        <div class="d-flex align-items-center my-3">
            <a href="#" @click.prevent="$emit('back')">
                <i class="mdi mdi-arrow-left-thin"></i>
                Return to integrations
            </a>
        </div>
        <template v-if="!integration">
            <Banner type="is-warning">No integration!!</Banner>
        </template>
        <template v-else>
            <div class="integration-eventbrite-organisation">
                <div class="flex-fill">
                    <h4>Organisation</h4>
                    <p class="flex-fill">Link your Eventwise account to an Eventbrite organisation.</p>
                </div>
                <FormField label="Eventbrite Organisation">
                    <div class="tw-flex tw-gap-1 tw-items-center">
                        <Select v-model="integration.organisation_id" :disabled="isLinked || isLoadingOrganisation">
                            <option
                                v-for="organisation in eventbriteOrganisations"
                                :key="organisation.id"
                                :value="organisation.id"
                            >
                                {{ organisation.name }}
                            </option>
                        </Select>
                        <Button v-if="!isLinked" :loading="isLoadingOrganisations" @click="saveOrganisation()">
                            Link
                        </Button>
                        <Button v-else type="is-danger" @click="disconnect()">Disconnect</Button>
                    </div>
                </FormField>
            </div>
            <div v-if="isLinked" class="integration-eventbrite-events">
                <h4>Events</h4>
                <p>Link your Eventwise events to those in Eventbrite.</p>
                <DataTable
                    :columns="[
                        {
                            title: 'Eventwise',
                            field: 'eventwise',
                        },
                        {
                            title: 'Eventbrite',
                            field: 'eventbrite',
                            width: '50%',
                        },
                        {
                            title: '',
                            field: 'actions',
                            width: 70,
                            numeric: true,
                        },
                    ]"
                    :data="Object.values(events)"
                >
                    <template #column(eventwise)="{ row }">
                        <h5>{{ row.name }}</h5>
                        <span v-if="row.starts_at">
                            {{ DateTime.fromISO(row.starts_at).toFormat('dd LLL yyyy') }}
                        </span>
                        <span v-if="row.starts_at && row.ends_at">&mdash;</span>
                        <span v-if="row.ends_at">
                            {{ DateTime.fromISO(row.ends_at).toFormat('dd LLL yyyy') }}
                        </span>
                    </template>
                    <template #column(eventbrite)="{ row }">
                        <div class="tw-flex tw-gap-1 tw-items-center">
                            <Selector
                                v-if="eventbriteEvents"
                                v-model="row.integrations"
                                class="flex-fill me-2"
                                :options="eventbriteEvents"
                                :all-options="eventbriteEvents"
                                placeholder="Not linked"
                                multiple
                            >
                                <template #optionLabel="{ option }">
                                    {{ option.name }}
                                    <template v-if="option.start && option.end">
                                        ({{ DateTime.fromISO(option.start.utc).toFormat('dd LLL yyyy') }} -
                                        {{ DateTime.fromISO(option.end.utc).toFormat('dd LLL yyyy') }})
                                    </template>
                                </template>
                            </Selector>
                            <Tooltip :disabled="!row.integration_id" type="dark" label="Unlink Event">
                                <Button :disabled="!row.integration_id" @click="unlinkEvent(row)">
                                    <i class="mdi mdi-link-variant-remove"></i>
                                </Button>
                            </Tooltip>
                        </div>
                    </template>
                    <template #column(actions)="{ row }">
                        <Tooltip
                            v-if="row.integrations && row.integrations.length"
                            :label="!hasChangedEvents ? 'Link Tickets' : 'Save your changes to link tickets'"
                            :position="!hasChangedEvents ? 'top' : 'left'"
                        >
                            <Button :disabled="hasChangedEvents" @click="linkTickets(row)">
                                <i class="mdi mdi-ticket-confirmation-outline"></i>
                            </Button>
                        </Tooltip>
                    </template>
                </DataTable>
                <div class="d-flex mt-3">
                    <Button class="!tw-ml-auto" type="is-success" :disabled="!hasChangedEvents" @click="saveEvents()">
                        Save Changes
                    </Button>
                </div>
            </div>
            <Modal
                ref="linkTicketsModal"
                title="Link Tickets"
                :loading="isLoadingTickets"
                size="is-xlarge"
                paddingless
                @close="
                    linkingEvent = null;
                    linkingTickets = null;
                    eventbriteTickets = null;
                "
            >
                <div v-if="linkingEvent">
                    <p class="p-3 border-bottom">Linking ticket types for {{ linkingEvent.name }}</p>
                    <DataTable
                        v-if="linkingTickets && eventbriteTickets"
                        :columns="[
                            {
                                title: 'Eventwise',
                                field: 'name',
                            },
                            {
                                title: 'Eventbrite',
                                field: 'eventbrite',
                                width: '50%',
                            },
                        ]"
                        :data="linkingTickets"
                    >
                        <template #column(eventbrite)="{ row }">
                            <div class="tw-flex tw-gap-1 tw-items-center">
                                <Select v-model="row.eventbrite_id" placeholder="Select a ticket...">
                                    <option
                                        v-for="eventbriteTicket in eventbriteTickets"
                                        :key="eventbriteTicket.id"
                                        :value="eventbriteTicket.id"
                                    >
                                        {{ eventbriteTicket.name }} ({{
                                            eventbriteTicket.free ? 'Free' : eventbriteTicket.cost.display
                                        }})
                                    </option>
                                </Select>
                                <Tooltip :disabled="!row.eventbrite_id" label="Unlink" type="dark">
                                    <Button :disabled="!row.eventbrite_id" @click="row.eventbrite_id = null">
                                        <i class="mdi mdi-link-variant-remove"></i>
                                    </Button>
                                </Tooltip>
                            </div>
                        </template>
                    </DataTable>
                </div>
                <template #footer>
                    <Button type="is-success" @click="saveTickets()">Save Changes</Button>
                </template>
            </Modal>
        </template>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../../Modal.vue';
import Selector from '../../../controls/Selector.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        Modal,
        Selector,
        Button,
        Banner,
        FormField,
        Select,
        DataTable,
        Tooltip,
    },
    emits: ['back'],
    data() {
        const store = useDataStore();
        const formattedEvents = this.formatIntegrations(Object.values(store.get('account.events')));

        return {
            isLoadingOrganisations: false,
            isLoadingEvents: false,
            isLoadingTickets: false,
            integration: store.get('account.integrations').find((i) => i.integrator === 'eventbrite'),
            events: formattedEvents,
            originalEvents: JSON.stringify(formattedEvents),
            isLinked: false,
            eventbriteOrganisations: null,
            eventbriteEvents: null,
            eventbriteTickets: null,
            linkingEvent: null,
            linkingTickets: null,
            DateTime,
        };
    },
    computed: {
        hasChangedEvents() {
            return JSON.stringify(this.events) !== this.originalEvents;
        },
    },
    watch: {
        isLinked(newVal) {
            if (newVal) {
                this.getEvents();
            }
        },
    },
    mounted() {
        if (this.integration) {
            this.isLinked = !!this.integration.organisation_id;
            this.getOrganisations();
        }
    },
    methods: {
        formatIntegrations(events) {
            return events.map((event) => {
                return {
                    ...event,
                    integrations: event.integrations
                        .filter((i) => i.integrator === 'eventbrite')
                        .map((i) => parseInt(i.integration_id)),
                };
            });
        },
        getOrganisations() {
            this.isLoadingOrganisations = true;
            axios
                .get(route('api.account.integrations.eventbrite.organisations'))
                .then(({ data }) => (this.eventbriteOrganisations = data))
                .finally(() => (this.isLoadingOrganisations = false));
        },
        getEvents() {
            this.isLoadingEvents = true;
            axios
                .get(route('api.account.integrations.eventbrite.events'))
                .then(
                    ({ data }) =>
                        (this.eventbriteEvents = data.map((ev) => {
                            ev.name = ev.name.text;
                            return ev;
                        }))
                )
                .finally(() => (this.isLoadingEvents = false));
        },
        saveOrganisation() {
            if (!this.integration.organisation_id) {
                return;
            }
            this.isLoadingOrganisations = true;
            axios
                .post(route('api.account.integrations.eventbrite.update'), {
                    organisation_id: this.integration.organisation_id,
                })
                .then(({ data }) => {
                    this.integration = data;
                    this.isLinked = !!this.integration.organisation_id;
                })
                .finally(() => (this.isLoadingOrganisations = false));
        },
        disconnect() {
            swal.fire({
                title: 'Disconnect Eventbrite',
                text: 'Are you sure you wish to completely disconnect your account from Eventbrite?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: 'No',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.isLoadingOrganisations = true;
                    this.isLoadingEvents = true;
                    this.isLoadingTickets = true;
                    axios
                        .delete(route('api.account.integrations.eventbrite.disconnect'))
                        .then(() => window.location.reload());
                }
            });
        },
        saveEvents() {
            this.isLoadingEvents = true;
            axios
                .post(route('api.account.integrations.eventbrite.events.update'), {
                    events: this.events,
                })
                .then(() => (this.originalEvents = JSON.stringify(this.events)))
                .finally(() => (this.isLoadingEvents = false));
        },
        unlinkEvent(event) {
            swal.fire({
                title: 'Unlink Event',
                text: 'Unlinking this event will also remove any previously created links between tickets. Any data within this event that has been imported from Eventbrite will be permanently removed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: 'No',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.events[event.id].integration_id = null;
                    this.saveEvents();
                }
            });
        },
        linkTickets(event) {
            this.linkingEvent = event;
            this.$refs.linkTicketsModal.open();
            this.isLoadingTickets = true;
            axios
                .get(
                    route('api.account.event.tickets', {
                        event: event.id,
                    })
                )
                .then(({ data }) => {
                    this.linkingTickets = data;
                    axios
                        .get(
                            route('api.account.integrations.eventbrite.events.tickets', {
                                eventId: event.integrations.join(','),
                            })
                        )
                        .then((response) => (this.eventbriteTickets = response.data))
                        .finally(() => (this.isLoadingTickets = false));
                });
        },
        saveTickets() {
            if (!this.linkingEvent) {
                return;
            }
            this.isLoadingTickets = true;
            axios
                .post(
                    route('api.account.event.tickets.update', {
                        event: this.linkingEvent.id,
                    }),
                    {
                        data: this.linkingTickets.map((t) => {
                            return {
                                id: t.id,
                                eventbrite_id: t.eventbrite_id,
                            };
                        }),
                    }
                )
                .then(() => this.$refs.linkTicketsModal.close())
                .finally(() => (this.isLoadingTickets = false));
        },
    },
};
</script>
<style lang="scss">
.integration-eventbrite {
    & > .integration-eventbrite-organisation {
        background: white;
        padding: 15px;
        display: flex;
        border-radius: 10px;
        margin-top: 10px;
    }
    & > .integration-eventbrite-events {
        background: white;
        padding: 15px;
        border-radius: 10px;
        margin-top: 15px;
    }
}
</style>
