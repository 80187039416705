<template>
    <div class="event-settings-container">
        <LoadingSpinner v-if="isLoading" label="Loading Event Settings" />
        <div v-if="event && !isLoading" class="met-profile-main tw-flex tw-gap-3">
            <Avatar :name="event.name" :url="event.image_url" size="xl" />
            <div>
                <h3 class="mb-1">{{ event.name }}</h3>
                <p v-if="event.primary_address" class="tw-text-sm tw-text-slate-400">
                    {{ event.primary_address.address }}
                </p>
            </div>
        </div>
        <Upload
            class="tw-w-fit tw-mt-2"
            :accept="['image/jpg', 'image/png']"
            text="Change event image"
            @update:model-value="uploadEventImage"
        />
        <Tabs
            v-if="event"
            :tabs="[
                {
                    title: 'General',
                    name: 'general',
                },
                {
                    title: 'Subcategories',
                    name: 'subcategories',
                },
                {
                    title: 'Reporting',
                    name: 'reporting',
                },
                {
                    title: event.is_closed ? 'Reopen Event' : 'Close Event',
                    name: 'close_event',
                    hidden: !Auth.can('delete events'),
                    headerClass: 'tw-ml-auto',
                },
                {
                    title: 'Delete Event',
                    name: 'delete_event',
                    hidden: !Auth.can('delete events'),
                },
            ]"
            container-class="tw-bg-white tw-rounded tw-p-5"
        >
            <template #tab(general)>
                <div class="d-flex flex-column flex-fill">
                    <h4 class="mb-3">General Details</h4>
                    <div class="tw-grid tw-gap-3 tw-grid-cols-2 mb-3">
                        <div>
                            <FormField label="Name" class="!tw-mb-3">
                                <Textbox v-model="event.name" :disabled="!isEditable" required />
                            </FormField>
                            <FormField label="Location" class="!tw-py-2 !tw-px-3 !tw-mb-2 tw-border tw-rounded">
                                <div>
                                    <FormField label="Venue" class="!tw-mb-2">
                                        <Textbox
                                            v-model="event.primary_address.address1"
                                            :disabled="!isEditable"
                                            placeholder="Venue"
                                        />
                                    </FormField>
                                    <FormField label="Address Line 2" class="!tw-mb-2">
                                        <Textbox
                                            v-model="event.primary_address.address2"
                                            :disabled="!isEditable"
                                            placeholder="Address Line 2"
                                        />
                                    </FormField>
                                    <FormField label="City" class="!tw-mb-2">
                                        <Textbox
                                            v-model="event.primary_address.city"
                                            :disabled="!isEditable"
                                            placeholder="City"
                                        />
                                    </FormField>
                                    <div class="tw-flex tw-mb-2">
                                        <FormField label="Postal Code">
                                            <Textbox
                                                v-model="event.primary_address.postcode"
                                                :disabled="!isEditable"
                                                placeholder="Postal Code"
                                            />
                                        </FormField>
                                        <FormField class="tw-flex-1 !tw-pl-3" label="Country">
                                            <Select
                                                v-model="event.primary_address.country"
                                                :disabled="!isEditable"
                                                class="tw-w-full"
                                                placeholder="Country"
                                            >
                                                <option
                                                    v-for="country in availableCountries"
                                                    :key="country"
                                                    :value="country"
                                                >
                                                    {{ country }}
                                                </option>
                                            </Select>
                                        </FormField>
                                    </div>
                                </div>
                            </FormField>
                        </div>
                        <div>
                            <FormField label="Base Currency" class="!tw-mb-3">
                                <Select
                                    v-model="event.currency_code"
                                    :disabled="!isEditable"
                                    class="tw-w-full"
                                    placeholder="Select a currency"
                                >
                                    <option value="GBP">Pound Sterling (GBP)</option>
                                    <option value="EUR">Euro (EUR)</option>
                                    <option value="USD">US Dollar (USD)</option>
                                    <option value="AUD">Australian Dollar (AUD)</option>
                                    <option value="CHF">Swiss Franc (CHF)</option>
                                    <option value="JPY">Japanese Yen (JPY)</option>
                                    <option value="RUB">Russian Ruble (RUB)</option>
                                    <option value="SEK">Swedish Krona (SEK)</option>
                                    <option value="DKK">Danish Krone (DKK)</option>
                                    <option value="ZAR">South African Rand (ZAR)</option>
                                </Select>
                            </FormField>
                            <FormField label="Default Markup Percentage" class="!tw-mb-3">
                                <PercentageValue
                                    v-model="event.default_markup"
                                    base-value="1"
                                    :currency="true"
                                    :step="0.0001"
                                    :percentage="true"
                                    :lock="true"
                                    size="is-form"
                                    force-percentage
                                />
                            </FormField>
                            <FormField label="Tags" class="!tw-mb-3">
                                <!-- Wrapping div required as b-field displays as flex -->
                                <div>
                                    <div v-if="event.assigned_tags.length" class="tw-flex tw-gap-1">
                                        <GroupTag v-for="tag in event.assigned_tags" :key="tag.id" :tag="tag" />
                                    </div>
                                    <p v-else class="text-muted">There are no tags assigned to this event.</p>
                                    <p v-if="Auth.can('update organisation')" class="tw-block mt-3">
                                        You can manage assigned tags in
                                        <a
                                            target="_blank"
                                            :href="
                                                route('account.settings', {
                                                    page: 'group-tags',
                                                })
                                            "
                                        >
                                            account settings
                                        </a>
                                    </p>
                                </div>
                            </FormField>
                            <FormField label="Event Date" class="!tw-mb-3">
                                <flat-pickr
                                    v-model="event.event_dates"
                                    :disabled="!isEditable"
                                    class="form-control input"
                                    placeholder="Required"
                                    :config="{
                                        mode: 'range',
                                        altInput: true,
                                        altFormat: 'F j, Y',
                                        dateFormat: 'Y-m-d',
                                        locale: {
                                            firstDayOfWeek: 1,
                                        },
                                    }"
                                    required
                                ></flat-pickr>
                            </FormField>
                            <FormField label="Project Start" class="!tw-mb-3">
                                <flat-pickr
                                    v-model="event.project_start"
                                    :disabled="!isEditable"
                                    class="form-control input"
                                    placeholder="Required"
                                    :config="flatpickrConfig"
                                    required
                                ></flat-pickr>
                            </FormField>
                            <FormField label="Project End">
                                <flat-pickr
                                    v-model="event.project_end"
                                    :disabled="!isEditable"
                                    class="form-control input"
                                    placeholder="Required"
                                    :config="flatpickrConfig"
                                    required
                                ></flat-pickr>
                            </FormField>
                        </div>
                    </div>
                    <div v-if="trackingOptions && trackingOptions.length" class="tw-mt-5">
                        <h4 class="mb-3">Xero</h4>
                        <FormField label="Tracking Option" class="!tw-mb-2">
                            <Select
                                v-model="event.tracking_category_option_id"
                                :disabled="!isEditable"
                                expanded
                                placeholder="Select a tracking option"
                            >
                                <option
                                    v-for="trackingOption in trackingOptions"
                                    :key="trackingOption.TrackingOptionID"
                                    :value="trackingOption.TrackingOptionID"
                                >
                                    {{ trackingOption.Name }}
                                </option>
                            </Select>
                        </FormField>
                    </div>
                    <div v-else-if="hasTrackingCategories" class="mt-5">
                        <h4 class="mb-3">Xero</h4>
                        <Banner type="is-warning">Unable to load tracking categories from Xero.</Banner>
                    </div>
                    <div class="mt-auto tw-flex tw-justify-end">
                        <Button type="is-success" :disabled="!isEditable" @click="updateEvent()">Save Changes</Button>
                    </div>
                </div>
            </template>
            <template #tab(subcategories)>
                <div class="d-flex flex-column flex-fill">
                    <h4 class="mb-3">Subcategories</h4>
                    <p class="mb-4">
                        Here you can alter which Revenue Streams, Cost Departments, and their respective subcategories
                        have been assigned to this event.
                    </p>
                    <div class="tw-grid md:tw-grid-cols-2 tw-gap-3">
                        <div
                            v-for="(info, i) of [
                                {
                                    title: 'Revenue Streams',
                                    data: revenueStreams,
                                },
                                {
                                    title: 'Cost Departments',
                                    data: costDepartments,
                                },
                            ]"
                            :key="`data-col-${i}`"
                        >
                            <h5 class="mb-2">{{ info.title }}</h5>
                            <FormField
                                v-for="data in info.data"
                                :key="`col-${data.id}`"
                                :label="data.name"
                                class="tw-border tw-rounded !tw-p-2 tw-mb-2"
                            >
                                <template #label>
                                    <Tooltip
                                        position="right"
                                        :label="
                                            departmentIsChecked(data) && (hasAnyEntries(data) || !isEditable)
                                                ? `This department contains active entries and cannot be unassigned`
                                                : 'Select all categories'
                                        "
                                    >
                                        <Checkbox
                                            :id="`dept-${data.id}`"
                                            :model-value="departmentIsChecked(data)"
                                            :indeterminate="
                                                selectedDepartmentCategories(data).length && !departmentIsChecked(data)
                                            "
                                            :disabled="
                                                departmentIsChecked(data) && (hasAnyEntries(data) || !isEditable)
                                            "
                                            @update:model-value="(value) => toggleAllDepartmentCategories(value, data)"
                                        >
                                            {{ data.name }}
                                        </Checkbox>
                                    </Tooltip>
                                </template>
                                <div class="tw-flex tw-flex-wrap tw-gap-1 tw-mt-1">
                                    <FormField
                                        v-for="category in data.categories"
                                        :key="`dept-${data.id}-cat-${category.id}`"
                                        class="tw-inline"
                                    >
                                        <Tooltip
                                            :label="
                                                hasEntries(category.id)
                                                    ? `This subcategory contains active entries and cannot be unassigned (${getEntries(category.id)})`
                                                    : 'Select this subcategory'
                                            "
                                        >
                                            <Checkbox
                                                :id="`rev-stream-${data.id}-cat-${category.id}`"
                                                :model-value="selectedEventCategories.includes(category.id)"
                                                :disabled="!isEditable || hasEntries(category.id)"
                                                class="tw-mr-2"
                                                @update:model-value="
                                                    (v) =>
                                                        v
                                                            ? selectedEventCategories.push(category.id)
                                                            : (selectedEventCategories = selectedEventCategories.filter(
                                                                  (c) => c !== category.id
                                                              ))
                                                "
                                            >
                                                {{ category.name }}
                                            </Checkbox>
                                        </Tooltip>
                                    </FormField>
                                </div>
                            </FormField>
                        </div>
                    </div>
                </div>
            </template>
            <template #tab(reporting)>
                <RecentEventActivity :event="event" />
            </template>
            <template #tab(close_event)>
                <h4 class="mb-3">Close Your Event</h4>
                <div>
                    You are about to close
                    <strong>{{ event.name }}</strong>
                    . Once your event has been closed it will remain accessible but in a read-only state.
                    <br />
                    Once an event has been closed you can reopen it at any time by returning to this page.
                </div>
                <div class="tw-mt-4">
                    <Button type="is-dark" @click="closeEvent()">
                        {{ event.is_closed ? 'Reopen' : 'Close' }} {{ event.name }}
                    </Button>
                </div>
            </template>
            <template #tab(delete_event)>
                <div class="d-flex flex-column flex-fill">
                    <h4 class="mb-3">Delete Your Event</h4>
                    <div>
                        You are about to delete everything within
                        <strong>{{ event.name }}</strong>
                        . Once your event has been deleted it will no longer be visible or accessible from within your
                        account.
                        <br />
                        <br />
                        Deleting an event
                        <i>will not</i>
                        delete any items from connected services such as Xero, you must do this yourself if required.
                        <br />
                        It may also not be possible to recover this event once it has been deleted.
                    </div>
                    <div class="tw-mt-4">
                        <FormField>
                            <Checkbox v-model="deleteEventConfirmation">
                                I ackowledge and understand the risks associated with deleting this event
                            </Checkbox>
                        </FormField>
                        <Button type="is-danger" :disabled="!deleteEventConfirmation" @click="deleteEvent()">
                            Delete {{ event.name }}
                        </Button>
                    </div>
                </div>
            </template>
        </Tabs>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import RecentEventActivity from '../../../../../js/components/reporting/RecentEventActivity.vue';
import PercentageValue from '@/js/components/widgets/PercentageValue.vue';
import GroupTag from '../../../../../js/components/GroupTag.vue';
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Upload from '@/js/components/controls/Upload.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { obj2form } from '@/js/utils.js';
import Avatar from '@/js/components/Avatar.vue';

export default {
    components: {
        RecentEventActivity,
        Avatar,
        PercentageValue,
        GroupTag,
        LoadingSpinner,
        Button,
        Upload,
        Tooltip,
        Tabs,
        FormField,
        Textbox,
        Select,
        Banner,
        Checkbox,
    },
    props: {
        trackingOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            availableCountries: window.Locales,
            event: null,
            deleteEventConfirmation: false,
            selectedEventCategories: Object.keys(store.get('current_event.categories')).map((key) => parseInt(key)),
            hasTrackingCategories: store.get('account.xero_organisation.tracking_category'),
            store: store,
        };
    },
    computed: {
        isEditable() {
            return Auth.can('update events') && !this.event.is_closed;
        },
        departments() {
            return Object.values(this.store.get('account.departments')).map((department) => {
                department.categories = Object.values(department.categories);
                return department;
            });
        },
        revenueStreams() {
            return this.departments.filter((department) => department.type === 2);
        },
        costDepartments() {
            return this.departments.filter((department) => department.type === 1);
        },
    },
    watch: {
        selectedEventCategories: {
            handler(newVal) {
                axios.post(route('api.account.event.update'), {
                    categories: newVal,
                });
            },
            deep: true,
        },
    },
    mounted() {
        this.getEvent();
    },
    methods: {
        selectedDepartmentCategories(department) {
            return this.selectedEventCategories.filter((c) => department.categories.map((cat) => cat.id).includes(c));
        },
        departmentIsChecked(department) {
            return (
                department.categories.length ===
                this.selectedEventCategories.filter((c) => department.categories.map((cat) => cat.id).includes(c))
                    .length
            );
        },
        uploadEventImage(file) {
            this.isLoading = true;
            this.event.image = file;
            const formData = new FormData();
            formData.append('image', file);

            axios.post(route('api.account.event.update-image'), formData).then(({ data }) => {
                delete this.event.image;
                this.event = data;
                this.isLoading = false;
            });
        },
        getEvent() {
            this.isLoading = true;
            axios.get(route('api.account.event.single')).then(({ data }) => {
                this.event = data;
                this.isLoading = false;
            });
        },
        updateEvent() {
            this.isLoading = true;
            this.event.tracking_category_option_name = this.event.tracking_category_option_id
                ? this.trackingOptions.find(
                      (option) => option.TrackingOptionID === this.event.tracking_category_option_id
                  )?.Name
                : null;
            axios
                .post(route('api.account.event.update'), obj2form(this.event))
                .then(({ data }) => {
                    if (this.event.image) {
                        delete this.event.image;
                    }
                    this.event = data;
                })
                .finally(() => (this.isLoading = false));
        },
        closeEvent() {
            this.isLoading = true;
            axios.patch(route('api.account.event.close')).then(() => {
                window.location.reload();
            });
        },
        deleteEvent() {
            this.isLoading = true;
            axios.delete(route('api.account.event.delete')).then(() => {
                window.location.href = route('onboarding');
            });
        },
        toggleAllDepartmentCategories(value, department) {
            if (value) {
                department.categories.forEach((category) => {
                    if (!this.selectedEventCategories.includes(category.id)) {
                        this.selectedEventCategories.push(category.id);
                    }
                });
                return;
            }
            const departmentCategories = department.categories.map((category) => category.id);
            this.selectedEventCategories = this.selectedEventCategories.filter(
                (category) => !departmentCategories.includes(category)
            );
        },
        toggleEventCategory(categoryId) {
            if (this.selectedEventCategories.includes(categoryId)) {
                this.selectedEventCategories = this.selectedEventCategories.filter((id) => id !== categoryId);
                return;
            }
            this.selectedEventCategories.push(categoryId);
        },
        hasEntries(categoryId) {
            const category = Object.values(this.store.get('current_event.categories')).find((c) => c.id === categoryId);
            if (!category) {
                return false;
            }
            return !!(
                category.budget_entries_count ||
                category.purchase_orders_count ||
                category.revenue_entries_count ||
                category.ticket_types_count
            );
        },
        hasAnyEntries(department) {
            const categories = Object.values(department.categories).find((c) => {
                return this.hasEntries(c.id);
            });
            return !!categories;
        },
        getEntries(categoryId) {
            const category = Object.values(this.store.get('current_event.categories')).find((c) => c.id === categoryId);
            if (
                !(
                    category.budget_entries_count ||
                    category.purchase_orders_count ||
                    category.revenue_entries_count ||
                    category.ticket_types_count
                )
            ) {
                return false;
            }
            const messages = [];
            if (category.budget_entries_count) {
                messages.push(
                    category.budget_entries_count + ' budget entr' + (category.budget_entries_count === 1 ? 'y' : 'ies')
                );
            }
            if (category.purchase_orders_count) {
                messages.push(
                    category.purchase_orders_count +
                        ' purchase order' +
                        (category.purchase_orders_count === 1 ? '' : 's')
                );
            }
            if (category.revenue_entries_count) {
                messages.push(
                    category.revenue_entries_count +
                        ' revenue entr' +
                        (category.revenue_entries_count === 1 ? 'y' : 'ies')
                );
            }
            if (category.ticket_types_count) {
                messages.push(
                    category.ticket_types_count + ' ticket type' + (category.ticket_types_count === 1 ? '' : 's')
                );
            }
            return messages.join(', ');
        },
    },
};
</script>
