<template>
    <div>
        <div v-if="userGroup" class="card">
            <LoadingSpinner v-if="isLoading" label="Loading Information" />
            <div v-else class="card-body px-0">
                <div class="met-profile px-4">
                    <div class="met-profile-main">
                        <div class="met-profile_user-detail">
                            <h3>{{ userGroup.name }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-box buefy-wrapper">
                    <Tabs
                        v-if="Auth.can('manage roles')"
                        :tabs="[
                            {
                                name: 'general',
                                title: 'General',
                            },
                            {
                                name: 'members',
                                title: 'Members',
                            },
                            {
                                name: 'permissions',
                                title: 'Permissions',
                            },
                        ]"
                    >
                        <template #tab(general)>
                            <FormField class="tw-px-3 tw-mt-5" label="Group Name">
                                <Textbox type="text" :model-value="userGroup.name" disabled required class="tw-pl-1" />
                            </FormField>
                        </template>
                        <template #tab(members)>
                            <div class="tw-px-3">
                                <div v-if="!isOwnGroup && !isLoadingMembers">
                                    <control-select-two-way
                                        v-model="selectedMembers"
                                        item-name="Members"
                                        :loading="isLoadingMembers"
                                        :options="availableMembers"
                                        @update:model-value="hasEdited = true"
                                    >
                                        <template #item="item">
                                            <User :user="item.element" :hide-name="false" />
                                        </template>
                                    </control-select-two-way>
                                </div>
                                <div v-else class="tw-flex tw-mt-4">
                                    <div
                                        v-for="member in selectedMembers"
                                        :key="'userGroup.memberListView.' + member.id"
                                        class="flex-fill"
                                    >
                                        <User :user="member" :hide-name="false" />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #tab(permissions)>
                            <div class="tw-px-3">
                                <PermissionBuilder
                                    v-if="!isOwnGroup && !isLoadingPermissions"
                                    v-model="selectedPermissions"
                                    :disable-all="userGroup.reference === 'director'"
                                    @update:model-value="processPermissionChanges"
                                />
                            </div>
                        </template>
                    </Tabs>
                    <div v-if="!isOwnGroup" class="d-flex px-4 mt-2">
                        <Button
                            :disabled="!hasEdited || isLoading"
                            :loading="isSaving"
                            type="is-success"
                            class="tw-ml-auto"
                            @click="saveChanges()"
                        >
                            Save Changes
                        </Button>
                    </div>
                    <Banner v-if="isOwnGroup" class="tw-mx-4 tw-mt-4 tw-text-center" type="is-info">
                        You are unable to edit this group as you are the only member within it
                    </Banner>
                </div>
            </div>
        </div>
        <Banner v-if="hasEdited" type="is-warning">
            You have made changes to this user group, don't forget to save your changes!
        </Banner>
    </div>
</template>
<script>
import PermissionBuilder from './PermissionBuilder.vue';
import LoadingSpinner from '../../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import User from '@/js/components/widgets/User.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        PermissionBuilder,
        LoadingSpinner,
        Button,
        Tabs,
        FormField,
        Textbox,
        Banner,
        User,
    },
    props: {
        userGroup: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            isLoadingMembers: false,
            isLoadingPermissions: false,
            isSaving: false,
            hasEdited: false,
            availableMembers: [],
            selectedMembers: [],
            initialPermissions: [],
            availablePermissions: [],
            selectedPermissions: [],
        };
    },
    computed: {
        isOwnGroup() {
            return (
                Auth &&
                Auth.user &&
                Auth.user.roles &&
                Auth.user.roles.find((role) => role.id === this.userGroup.id) &&
                this.selectedMembers.length < 2
            );
        },
        isLoading() {
            return this.isLoadingMembers || this.isLoadingPermissions;
        },
    },
    watch: {
        userGroup: function () {
            this.getAvailablePermissions();
        },
    },
    mounted() {
        this.getAvailablePermissions();
        this.getTeamMembers();
    },
    methods: {
        getAvailablePermissions() {
            this.isLoadingPermissions = true;
            this.selectedPermissions = [];
            //Fetch all the permissions available
            axios
                .get(
                    route('api.account.team.groups.get', {
                        userGroup: this.userGroup.id,
                    })
                )
                .then(({ data }) => {
                    this.initialPermissions = data.permissions.map((p) => p.name).sort();
                    this.selectedPermissions = data.permissions.map((p) => p.name);
                    this.isLoadingPermissions = false;
                });
        },
        getTeamMembers() {
            this.isLoadingMembers = true;
            axios.get(route('api.account.team')).then((response) => {
                this.availableMembers = response.data;
                this.selectedMembers = this.userGroup.users;
                this.isLoadingMembers = false;
            });
        },
        processPermissionChanges(newVal) {
            if (JSON.stringify(JSON.parse(JSON.stringify(newVal)).sort()) !== JSON.stringify(this.initialPermissions)) {
                this.hasEdited = true;
            }
        },
        saveChanges() {
            this.isSaving = true;
            axios
                .post(
                    route('api.account.team.groups.update', {
                        userGroup: this.userGroup.id,
                    }),
                    {
                        members: this.selectedMembers.map((member) => member.id),
                        permissions: this.selectedPermissions,
                    }
                )
                .then(() => {
                    this.initialPermissions = JSON.parse(JSON.stringify(this.selectedPermissions)).sort();
                    this.hasEdited = false;
                    toast('Success', 'The ' + this.userGroup.name + ' group has been successfully updated', 'success');
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
    },
};
</script>
