<template>
    <Modal
        ref="modal"
        title="Create Ticket Target"
        size="is-xxlarge"
        :loading="isLoading"
        width="850px"
        @closing="reset()"
    >
        <template v-if="targetSelection">
            <p>
                Assign a name to your target, then you can adjust the points on the chart accordingly to align with your
                new objective.
            </p>
            <p v-if="targetTotal">This target is for a total of {{ targetTotal }} tickets sold.</p>
            <div class="tw-flex tw-mt-2 tw-mb-2">
                <FormField class="tw-flex-1 tw-pr-2" label="Name">
                    <Textbox v-model="targetName" placeholder="Target Name" required class="tw-w-full" />
                </FormField>
                <FormField field-class="tw-flex tw-gap-1" label="Type">
                    <Button :active="targetType === 'weekly'" @click="targetType = 'weekly'">Weekly</Button>
                    <Button :active="targetType === 'monthly'" @click="targetType = 'monthly'">Monthly</Button>
                </FormField>
            </div>
            <div v-if="existingData" class="border rounded p-1 pr-5">
                <Chart
                    type="line"
                    :data="existingData"
                    :options="chartOptions"
                    :min-height="380"
                    :downloadable="false"
                />
            </div>
        </template>

        <div v-else>
            <Banner
                v-if="target > totalAvailable"
                type="is-warning"
                style="margin-right: 20px; margin-left: 20px; margin-bottom: 20px"
            >
                You cannot create a target for more tickets than you have available.
            </Banner>

            <div class="text-center">
                <h5 class="mb-3">Welcome to the Ticket Target Wizard!</h5>
                <p class="mb-3">
                    You can define the total amount of tickets sold for your target. We've pre-filled this field with
                    your maximum available tickets. Keep in mind that the value you enter here cannot exceed your
                    maximum available tickets.
                </p>
                <p class="mb-5">Let's embark on creating a successful ticket target together!</p>
                <FormField>
                    <NumberInput
                        v-model="target"
                        min="0"
                        :max="totalAvailable"
                        step="1"
                        class="tw-w-[25%] tw-mx-auto"
                        @keyup.enter="createTarget()"
                    />
                </FormField>
            </div>
        </div>

        <template #footer>
            <Button class="!tw-mr-auto" @click="close()">Cancel</Button>
            <Button
                v-if="!targetSelection"
                :disabled="target > totalAvailable"
                type="is-success"
                @click="createTarget()"
            >
                Next
            </Button>
            <Button v-else type="is-success" :disabled="!targetName" @click="saveTarget()">Save Target</Button>
        </template>
    </Modal>
</template>
<script>
import Chart from '../../Chart.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '@/js/components/Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Chart,
        Modal,
        Button,
        FormField,
        Textbox,
        NumberInput,
        Banner,
    },
    emits: ['updated'],
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            existingData: null,
            targetName: null,
            targetType: 'weekly',
            chartOptions: {
                plugins: {
                    dragData: {
                        round: 0,
                        onDragEnd: (e, datasetIndex, index, value) => this.onDragEndFunction(index, value),
                    },
                },
                animations: false,
            },
            targetSelection: null,
            target: null,
            store: store,
            targetTotal: null,
        };
    },
    computed: {
        totalAvailable() {
            const tickets = Object.values(this.store.get('current_event.ticket_types'));

            if (!tickets) {
                return null;
            }

            return tickets.reduce((c, t) => c + t.tickets_available, 0);
        },
    },
    watch: {
        targetType() {
            this.getExistingTargets();
        },
    },
    methods: {
        onDragEndFunction(index, value) {
            if (value > this.totalAvailable) {
                this.existingData.datasets[0].data.splice(index, 1, this.totalAvailable);
            }

            if (index === this.existingData.datasets[0].data.length - 1) {
                this.targetTotal = this.existingData?.datasets[0].data.slice(-1)[0];
            }
        },
        open(ticketTarget) {
            this.target = this.totalAvailable;
            if (ticketTarget) {
                this.ticketTarget = ticketTarget;
                this.targetName = ticketTarget.name;
                this.targetType = ticketTarget.type;
                this.targetSelection = true;
            }
            this.getExistingTargets();
            this.$refs.modal.open();
        },
        close() {
            this.reset();
            this.$refs.modal.close();
        },
        reset() {
            this.ticketTarget = null;
            this.targetName = null;
            this.targetType = 'weekly';
            this.targetSelection = null;
            this.formData = {};
        },
        createTarget() {
            if (this.target > this.totalAvailable) {
                return;
            }

            this.targetSelection = true;
            this.getExistingTargets();
        },
        getExistingTargets() {
            if (!this.targetSelection) {
                return;
            }

            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.orders.salesytd', {
                        monthly: this.targetType === 'monthly',
                        targets: this.ticketTarget ? [this.ticketTarget.id] : undefined,
                        target: this.target,
                    })
                )
                .then((response) => {
                    const series = response.data.series;

                    series[0].borderColor = 'rgba(0, 0, 0, 0.5)';
                    series[0].backgroundColor = 'rgba(0, 0, 0, 0.4)';
                    series[0].dragData = false;
                    series[1].dragData = false;

                    if (!this.ticketTarget) {
                        series.unshift({
                            borderColor: 'rgba(45,132,182, 0.5)',
                            backgroundColor: 'rgba(78,165,217, 0.5)',
                            label: 'New Target',
                            data: JSON.parse(JSON.stringify(series[2].data)),
                            dragData: true,
                        });
                    } else {
                        const target = series.pop();
                        target.borderColor = 'rgba(45,132,182, 0.5)';
                        target.backgroundColor = 'rgba(78,165,217, 0.5)';
                        series.unshift(target);
                    }

                    series.pop();

                    this.existingData = {
                        datasets: series,
                        labels: response.data.labels,
                    };

                    this.targetTotal = this.existingData?.datasets[0].data.slice(-1)[0];

                    this.isLoading = false;
                });
        },
        saveTarget() {
            this.isLoading = true;
            axios
                .post(
                    this.ticketTarget
                        ? route('api.account.event.targets.update', {
                              ticketTarget: this.ticketTarget.id,
                          })
                        : route('api.account.event.targets.create'),
                    {
                        name: this.targetName,
                        type: this.targetType,
                        targets: this.existingData.datasets[0].data,
                    }
                )
                .then(() => {
                    this.$emit('updated');
                    this.close();
                    toast(
                        'Target ' + (this.ticketTarget ? 'Updated' : 'Created'),
                        'Your ticket target has been ' + (this.ticketTarget ? 'updated' : 'created') + ' successfully',
                        'success'
                    );
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
