<template>
    <div>
        <p v-if="isLoading" class="col-12 text-muted text-center">
            <i class="las la-spinner la-spin mr-1"></i>
            Loading Data
        </p>
        <template v-else>
            <ew-card class="tw-my-4 card-enhance" title="Ticket Sales Comparison">
                <template #actions>
                    <Radio
                        v-model="chartView"
                        name="chart-view"
                        :options="[
                            {
                                label: 'Weekly',
                                value: 'weekly',
                            },
                            {
                                label: 'Monthly',
                                value: 'monthly',
                            },
                        ]"
                    />
                    <Radio
                        v-model="dataMode"
                        name="data-mode"
                        :options="[
                            {
                                label: 'Period',
                                value: 'period',
                            },
                            {
                                label: 'Summing',
                                value: 'tally',
                            },
                        ]"
                    />
                </template>
                <TicketComparisonChart :data="chartArray" :events="events" :weekly="isWeekly" />
            </ew-card>
            <TicketComparisonTable :data="tableArray" :events="events" :weekly="isWeekly" />
        </template>
    </div>
</template>
<script setup>
import TicketComparisonChart from './TicketComparisonChart.vue';
import TicketComparisonTable from './TicketComparisonTable.vue';
import Radio from '@/js/components/Radio.vue';
import { ref, watch } from 'vue';

const props = defineProps({
    selectedEventIds: {
        type: Array,
        required: true,
    },
    weekly: {
        type: Boolean,
        default: true,
    },
});
const events = ref([]);
const isLoading = ref(true);
const chartView = ref('weekly');
const dataMode = ref('period');
const chartArray = ref([]);
const tableArray = ref([]);
const isWeekly = ref(props.weekly);

axios
    .get(
        route('api.account.comparison.eventComparisonData', {
            events: props.selectedEventIds,
        })
    )
    .then(({ data }) => {
        events.value = data;
        createTotals();
    })
    .finally(() => (isLoading.value = false));

const createTotals = () => {
    chartArray.value = [];
    tableArray.value = [];
    events.value.forEach((event) => {
        totalsByPeriod(event.tickets_sold);
    });
};
const totalsByPeriod = (ticketsSoldArray) => {
    const figures = [];
    const tableFigures = [];
    let indexCounter = 0;
    // ticketsSoldArray works backwards !
    const dailyTotalsArray = [...ticketsSoldArray].reverse();
    // Day[0] is the start of monthly period 1, [1] for weekly
    let initialDate = !isWeekly.value
        ? new Date(Object.keys(dailyTotalsArray[0]).toString())
        : new Date(Object.keys(dailyTotalsArray[1]).toString());
    let periodEndDate = new Date(addDays(initialDate, getPeriod(initialDate) - 1));

    let periodTicketsSold = 0;
    dailyTotalsArray.forEach((item) => {
        if (new Date(Object.keys(item).toString()) >= periodEndDate) {
            // get the figures for the periodEndDate and push them
            periodTicketsSold += parseInt(Object.values(item));
            figures.push({ x: indexCounter, y: periodTicketsSold });
            tableFigures.push(periodTicketsSold);
            if (dataMode.value === 'period') {
                // reset if not Tallying
                periodTicketsSold = 0;
            }

            // Set the new initial and end dates
            initialDate = new Date(addDays(periodEndDate, 1));
            periodEndDate = new Date(addDays(initialDate, getPeriod(initialDate) - 1));
            // increment the period counter
            indexCounter++;
        } else {
            // This isn't a boundary so just check the params
            if (
                new Date(Object.keys(item).toString()) >= initialDate &&
                new Date(Object.keys(item).toString()) <= periodEndDate
            ) {
                periodTicketsSold += parseInt(Object.values(item));
            }
        }
    });
    chartArray.value.push(figures);
    tableArray.value.push(tableFigures);
};
const addDays = (startDate, daysToAdd) => {
    const result = new Date(startDate);
    result.setDate(result.getDate() + daysToAdd);
    return result;
};
const getPeriod = (initialDate) => {
    if (isWeekly.value) {
        return 7;
    }
    const leapYear = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1).getFullYear() % 4 === 0;
    return [0, 31, leapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][initialDate.getMonth() + 1];
};

watch(chartView, (newVal) => {
    isWeekly.value = newVal === 'weekly';
    createTotals();
});
watch(dataMode, () => {
    createTotals();
});
</script>
<style scoped lang="scss">
.card-enhance {
    max-height: 400px;
}
</style>
