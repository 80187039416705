<template>
    <div id="page-minimal" class="buefy-wrapper">
        <slot name="before"></slot>
        <div
            class="minimal-panel"
            :class="{
                expanded: isExpanded,
                'py-0': noPadding,
            }"
        >
            <slot></slot>
        </div>
        <slot name="side"></slot>
        <div class="footnote"><slot name="footnote"></slot></div>
    </div>
</template>
<script>
export default {
    props: {
        expanded: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isExpanded: false,
        };
    },
    watch: {
        expanded(newVal) {
            this.isExpanded = newVal;
        },
    },
    mounted() {
        this.isExpanded = this.expanded;
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

body:has(#page-minimal) {
    overflow: none;
    background: radial-gradient(
            circle at 39% 47%,
            hsla(0, 0%, 42%, 0.08) 0%,
            hsla(0, 0%, 42%, 0.04) 33.333%,
            rgba(72, 72, 72, 0.06) 0,
            rgba(72, 72, 72, 0.08) 66.666%,
            rgba(36, 36, 36, 0.04) 0,
            rgba(36, 36, 36, 0.04) 99.999%
        ),
        radial-gradient(
            circle at 53% 74%,
            hsla(0, 0%, 71%, 0.06) 0%,
            hsla(0, 0%, 71%, 0.04) 33.333%,
            hsla(0, 0%, 79%, 0.08) 0,
            hsla(0, 0%, 79%, 0.04) 66.666%,
            hsla(0, 0%, 87%, 0.04) 0,
            hsla(0, 0%, 87%, 0.06) 99.999%
        ),
        radial-gradient(
            circle at 14% 98%,
            hsla(0, 0%, 72%, 0.08) 0%,
            hsla(0, 0%, 72%, 0.06) 33.333%,
            rgba(96, 96, 96, 0.04) 0,
            rgba(96, 96, 96, 0.08) 66.666%,
            rgba(7, 7, 7, 0.04) 0,
            rgba(7, 7, 7, 0.06) 99.999%
        ),
        linear-gradient(90deg, #4ea5d9, #2d84b6);
}
div#page-minimal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > .minimal-panel {
        min-width: 35%;
        background: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(black, 0.1);
        transition: all 0.2s ease;

        @media (max-width: 1400px) {
            min-width: 500px;
        }
        @media (max-width: 800px) {
            min-width: 100vw;
            top: 40px;
            left: 40px;
            align-items: center;
        }

        &.expanded {
            min-width: 85%;
            max-width: 85%;
            max-height: 75vh;
            justify-content: flex-start;
            padding-bottom: 0;
        }
    }
}
.footnote {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 15px;
    left: 20px;
    right: 20px;
    color: rgba(white, 0.5);
    font-size: 0.9em;
    user-select: none;
    font-family: $eventwise-font-heading;
}
</style>
