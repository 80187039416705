<template>
    <div class="form-container">
        <div id="card-element" :class="{ disabled: isLoading }">
            <FormField label="Cardholder Name">
                <input id="card-name" v-model="cardholderName" type="text" placeholder="John Doe" />
            </FormField>
            <FormField label="Card Number">
                <div id="card-number"></div>
            </FormField>
            <div class="tw-grid tw-grid-cols-3 tw-gap-3">
                <FormField label="Expiry Date" class="tw-col-span-2">
                    <div id="card-expiry"></div>
                </FormField>
                <FormField label="CVC">
                    <div id="card-cvc"></div>
                </FormField>
            </div>
        </div>
        <Button
            type="is-success"
            class="tw-w-full"
            :disabled="!cardholderName"
            :loading="isLoading"
            @click="postPaymentMethod()"
        >
            Setup Payment Details
        </Button>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: {
        FormField,
        Button,
    },
    props: {
        intent: {
            type: Object,
        },
        account: {
            type: Object,
        },
    },
    emits: ['setup'],
    data() {
        const store = useDataStore();

        return {
            stripeKey: store.get('providers.stripe.public_key'),
            isLoading: false,
            accountData: null,
            cardholderName: null,
            paymentIntent: null,
            stripe: null,
            stripeElements: {},
        };
    },
    mounted() {
        this.accountData = this.account;
        this.paymentIntent = this.intent;
        setTimeout(() => this.preparePaymentForm(), 250);
    },
    methods: {
        preparePaymentForm() {
            this.isLoading = true;
            const stripe = Stripe(this.stripeKey);
            const elements = stripe.elements();
            const cardNumber = elements.create('cardNumber');
            const cardExpiry = elements.create('cardExpiry');
            const cardCvc = elements.create('cardCvc');

            cardNumber.mount('#card-number');
            cardExpiry.mount('#card-expiry');
            cardCvc.mount('#card-cvc');
            this.stripe = stripe;
            this.stripeElements = {
                cardNumber: cardNumber,
                cardExpiry: cardExpiry,
                cardCvc: cardCvc,
            };
            this.isLoading = false;
        },
        async postPaymentMethod() {
            this.isLoading = true;
            const { setupIntent, error } = await this.stripe.confirmCardSetup(this.paymentIntent.client_secret, {
                payment_method: {
                    card: this.stripeElements.cardNumber,
                    billing_details: { name: this.cardholderName },
                },
            });

            if (error) {
                this.setupIntent = error.setup_intent;
                swal.fire('Error', error.message, 'error');
                this.isLoading = false;
            } else {
                this.$emit('setup', setupIntent);
                this.isLoading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

#card-element {
    user-select: auto;
    opacity: 1;
    transition: all 0.2s ease;
    text-align: left;

    &.disabled {
        user-select: none;
        opacity: 0.5;
    }

    #card-name {
        width: 100%;
        padding: 6px 8px;
    }
    #card-number {
        min-width: 300px;
    }
    #card-name,
    #card-number,
    #card-expiry,
    #card-cvc {
        border: 1px solid rgba(black, 0.1);
        padding: 8px;
        border-radius: 6px;
        margin-bottom: 6px;
        background: white;
    }
}
</style>
