<template>
    <div v-if="groupedPermissions" class="permission-selector buefy-wrapper">
        <div v-for="group in Object.keys(groupedPermissions)" :key="'group_' + group" class="permission-selector-group">
            <h5>{{ capitalise(group) }}</h5>
            <div class="permission-selector-subgroup">
                <div class="row">
                    <div
                        v-for="subgroup in Object.keys(groupedPermissions[group])"
                        :key="'subgroup_' + subgroup"
                        class="col-12 col-lg-6"
                    >
                        <FormField :label="capitalise(subgroup)">
                            <Tooltip
                                v-for="permission in groupedPermissions[group][subgroup]"
                                :key="permission.name"
                                :label="
                                    hasActiveDependants(permission)
                                        ? 'Required by the following permissions: ' + permission.required_by.join(', ')
                                        : null
                                "
                                :disabled="!hasActiveDependants(permission)"
                            >
                                <Checkbox
                                    :model-value="selectedPermissions.includes(permission.name)"
                                    :disabled="hasActiveDependants(permission) || disableAll"
                                    @click.native="selectPermission(permission)"
                                >
                                    <div class="d-flex flex-column">
                                        <div>{{ capitalise(permission.name) }}</div>
                                        <small class="text-muted">{{ permission.description }}</small>
                                    </div>
                                </Checkbox>
                            </Tooltip>
                        </FormField>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { capitalise } from '@/js/utils.js';

export default {
    components: {
        FormField,
        Tooltip,
        Checkbox,
    },
    props: {
        modelValue: {
            type: Array,
            required: false,
        },
        disableAll: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        const store = useDataStore();
        return {
            availablePermissions: store.get('permissions'),
            selectedPermissions: [],
            store: store,
        };
    },
    computed: {
        groupedPermissions() {
            if (!this.availablePermissions) {
                return {};
            }
            const permissionGroups = {};
            this.availablePermissions.forEach((permission) => {
                if (!permissionGroups[permission.group]) {
                    permissionGroups[permission.group] = {};
                }
                let subgroup = permission.name.split(' ');
                subgroup.shift();
                subgroup = subgroup.join(' ');
                if (!permissionGroups[permission.group][subgroup]) {
                    permissionGroups[permission.group][subgroup] = [];
                }
                permissionGroups[permission.group][subgroup].push(permission);
            });
            return permissionGroups;
        },
    },
    watch: {
        selectedPermissions(newVal) {
            this.$emit('update:modelValue', newVal);
        },
    },
    mounted() {
        if (this.modelValue) {
            this.selectedPermissions = this.modelValue;
        }
    },
    methods: {
        capitalise,
        async selectPermission(permission) {
            if (this.hasActiveDependants(permission)) {
                return;
            } else if (this.selectedPermissions.includes(permission.name)) {
                this.selectedPermissions = this.selectedPermissions.filter((p) => p !== permission.name);
                return;
            } else if (permission.depends_on) {
                const unassignedDependants = permission.depends_on.filter((p) => !this.selectedPermissions.includes(p));
                if (unassignedDependants.length > 0) {
                    const { value: confirmation } = await swal.fire({
                        title: 'Required Permissions',
                        html: `The ${
                            permission.name
                        } permission also requires the following permissions: ${unassignedDependants.join(
                            ', '
                        )}.<br /><br />Would you also like to assign these permissions?`,
                        showCancelButton: true,
                    });
                    if (!confirmation) {
                        return;
                    }
                    unassignedDependants.forEach((p) => this.selectedPermissions.push(p));
                }
            }
            this.selectedPermissions.push(permission.name);
        },
        hasActiveDependants(permission) {
            return (
                !!permission.required_by && !!this.selectedPermissions.find((p) => permission.required_by.includes(p))
            );
        },
    },
};
</script>
<style lang="scss" scoped>
.permission-selector > .permission-selector-group {
    & > h5 {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    & > .permission-selector-subgroup {
        margin-bottom: 10px;

        & .row > .col-12 {
            > .field {
                margin-bottom: 10px;

                .field {
                    flex-wrap: wrap;
                }
            }
        }
    }
}
</style>
