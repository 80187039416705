<template>
    <div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <ew-card v-if="!isLoading" title="Recent Activity Report" paddingless :collapsible="false" class="">
            <div class="d-flex justify-content-between">
                <DaysOfTheWeekSelector v-model="days_selected" class="mt-1" @update:model-value="processDayChange" />
                <Button class="tw-mr-3 tw-my-1" type="is-info" @click.prevent="emailActivityNow">Now</Button>
            </div>
            <div class="bg-light pt-1 pb-2 px-3">
                This panel shows a condensed version of recent activity, Not all changes are displayed here, if you
                require further assistance please get in touch with support.
            </div>
            <div v-if="!pageData" class="d-flex align-items-center text-muted p-3 justify-content-center">
                <div class="loader"></div>
                &nbsp; Loading Event's recent activity
            </div>
            <DataTable
                v-else
                class="tw-ml-2"
                :total="pageData.total"
                :data="pageData.data"
                :current-page="pageData.current_page"
                :per-page="pageData.per_page"
                paginated
                backend-pagination
                :columns="[
                    {
                        title: 'Type',
                        field: 'type',
                    },
                    {
                        title: 'Action',
                        field: 'action',
                    },
                    {
                        title: 'Occurred',
                        field: 'occurred_at',
                    },
                ]"
                @page-change="fetchActivity"
            >
                <template #column(type)="{ row }">
                    {{ row.activityable_type.replace('App\\Models\\', '') }}
                </template>
                <template #column(action)="{ row }">
                    <span v-if="!row.description" class="text-muted">None</span>
                    <span v-else>{{ row.description }}</span>
                </template>
                <template #column(occurred)="{ row }">
                    {{ DateTime.fromISO(row.occurred_at).toRelative() }}
                </template>
                <template #empty>
                    <div class="text-center text-muted">
                        There&apos;s no activity to show here just yet, check back later!
                    </div>
                </template>
            </DataTable>
        </ew-card>
    </div>
</template>
<script>
import DaysOfTheWeekSelector from '../widgets/DaysOfTheWeekSelector.vue';
import LoadingSpinner from '../widgets/LoadingSpinner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Button from '@/js/components/controls/Button.vue';
import { toast } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        LoadingSpinner,
        DaysOfTheWeekSelector,
        DataTable,
        Button,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            selectedDaysValue: 0,
            activityEmail: false,
            days_selected: 0,
            eventActivity: null,
            currentUser: Auth.user,
            localEvent: {},
            isLoading: false,
            reportStartDate: '',
            perPage: 10,
            pageData: null,
            searchQuery: null,
            DateTime,
        };
    },
    mounted() {
        this.localEvent = this.event;
        this.fetchActivity();
        this.days_selected = this.event.activity_report_days;
    },
    methods: {
        fetchActivity(newPage = null) {
            this.isLoading = true;
            //Pagination code
            const url = new URL(route('api.account.event.recentActivity', { event: this.event.id }));
            url.searchParams.set('perPage', this.perPage);
            // if (this.searchQuery) {
            //     url.searchParams.append('query', this.searchQuery);
            // }
            if (newPage) {
                url.searchParams.append('page', newPage);
            } else if (this.pageData && this.pageData.current_page) {
                url.searchParams.append('page', this.pageData.current_page);
            }
            //            url.searchParams.append('sort', Object.keys(this.sorts).map(key => `${key}=${this.sorts[key]}`).join(','));
            // fetch activity for given event id
            axios
                .get(url.href)
                .then(({ data }) => {
                    this.pageData = data;
                    // this.eventActivity = pageData.items;
                })
                .finally(() => (this.isLoading = false));
        },
        processDayChange(selectedValue) {
            this.days_selected = selectedValue;

            this.localEvent.activity_report_days = this.days_selected;
            axios.post(route('api.account.event.update'), this.localEvent).then(({ data }) => {
                this.localEvent = data;
            });
        },
        emailActivityNow() {
            this.isLoading = true;
            const recipients = [this.currentUser.email];
            // Recipients can be an array of target Emails
            axios
                .post(route('api.account.event.export.activities.pdf'), { recipients: recipients })
                .then(({ data }) => {
                    if (data) {
                        toast(
                            'Email sent',
                            'The recent activity email for ' + this.currentUser.name + ' has been sent.',
                            'success'
                        );
                    } else {
                        toast(
                            'Email Error',
                            'The recent activity email for ' + this.currentUser.name + ' failed to send.',
                            'error'
                        );
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>

<style scoped lang="scss">
.width50 {
    width: 50%;
}
</style>
