<template>
    <div class="px-3">
        <div v-if="!isConnected">
            <div class="d-flex mt-2">
                <div class="h3 mr-auto">Connect with Xero</div>
                <div class="pt-1">
                    <img src="@/images/brands/xero_blue.png" class="icon-lg" alt="Xero Logo" />
                </div>
            </div>

            <Submenu
                class="mb-1 [@media(max-width:900px)]:tw-flex-row tw-flex-col"
                :pages="availableTenants"
                @update:model-value="(v) => setSelected(v)"
            >
                <template #header>
                    <p class="mb-4">
                        You have previously connected to Xero with Eventwise
                        <br />
                        Please select which organisation you would like to connect:
                    </p>
                </template>
                <template #default="{ page }">
                    <span v-if="isAlreadyConnected(page)">
                        <i class="mdi mdi-alert"></i>
                        {{ page.tenantName }}
                        <Tag class="float-right" type="warning">Already Connected</Tag>
                    </span>
                    <span v-else>{{ page.tenantName }}</span>
                </template>
            </Submenu>
            <Button type="is-success" :disabled="selectedItem === null" class="tw-w-full" @click="processSelection()">
                Complete Connection
            </Button>
        </div>
        <div v-else>
            <div class="d-flex my-2">
                <div class="h3 mr-auto">Connected to Xero</div>
                <div class="pt-1">
                    <img src="@/images/brands/xero_blue.png" class="icon-lg" alt="Xero Logo" />
                </div>
            </div>
            <Banner type="is-success" has-icon :closable="false">
                You have successfully connected your Xero organisation.
            </Banner>
            <FormField class="my-2" style="text-align: center">
                <a :href="redirectTo">Go back</a>
            </FormField>
        </div>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Submenu from '@/js/components/controls/Submenu.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Submenu,
        Button,
        FormField,
        Banner,
    },
    props: {
        redirectTo: {
            type: String,
        },
        alreadyConnected: {
            type: Array,
        },
        xeroTenants: {
            type: Array,
        },
    },
    data() {
        return {
            isConnected: false,
            selectedItem: null,
        };
    },
    computed: {
        availableTenants() {
            return this.xeroTenants.map((tenant) => {
                return {
                    title: tenant.name,
                    value: tenant.id,
                };
            });
        },
    },
    methods: {
        setSelected(tenant) {
            this.selectedItem = tenant;
        },
        processSelection() {
            const tempSelected = this.selectedItem;
            this.selectedItem = null;
            const postData = {
                selectedTenant: JSON.stringify(tempSelected),
            };
            axios
                .post(route('xero.processTenantSelection'), postData)
                .then(() => this.completeConnection())
                .catch(() => {
                    this.selectedItem = tempSelected;
                });
        },
        isAlreadyConnected(tenant) {
            return !!this.alreadyConnected.find((xeroTenant) => xeroTenant == tenant.tenantId);
        },
        completeConnection() {
            this.isConnected = true;
        },
    },
};
</script>
<style lang="scss"></style>
