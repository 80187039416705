<template>
    <div class="spa-page-wrapper">
        <Dashboard v-if="currentRoute === 'account'" />
        <EventComparison v-else-if="currentRoute === 'account.events'" />
        <Contacts v-else-if="currentRoute === 'account.contacts'" />
        <Departments v-else-if="currentRoute === 'account.departments'" />
        <Payruns v-else-if="currentRoute === 'account.payruns'" />
        <Team v-else-if="currentRoute === 'account.team'" />
        <Integrations v-else-if="currentRoute === 'account.integrations'" />
        <Settings v-else-if="currentRoute === 'account.settings'" />
        <Reporting v-else-if="currentRoute === 'account.reporting'" />
    </div>
</template>
<script>
import Dashboard from './pages/Account/Dashboard.vue';
import EventComparison from './pages/Account/EventComparison.vue';
import Contacts from './pages/Account/Contacts.vue';
import Departments from './pages/Account/Departments.vue';
import Payruns from './pages/Account/Payruns.vue';
import Team from './pages/Account/Team.vue';
import Integrations from './pages/Account/Integrations.vue';
import Settings from './pages/Account/Settings.vue';
import Reporting from './pages/Account/Reporting.vue';

export default {
    components: {
        Reporting,
        Dashboard,
        EventComparison,
        Contacts,
        Departments,
        Payruns,
        Team,
        Integrations,
        Settings,
    },
    data() {
        return {
            currentRoute: route().current(),
        };
    },
    mounted() {
        Eventbus.$on('routeChanged', () => (this.currentRoute = route().current()));
    },
};
</script>
