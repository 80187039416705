<template>
    <div>
        <StatisticsRow v-slot="props" :endpoint="route('api.account.event.purchasing.summaryTotals')" class="mb-3">
            <StatisticsItem
                :id="1"
                title="Awaiting Approval"
                subtext="Purchase orders awaiting approval"
                icon="check-circle-outline"
                :loading="props.loading"
                :model-value="props.data.awaitingApproval"
                :currency="true"
                :selectable="true"
                :class="{ selected: selected === 1 }"
                @update:model-value="(value) => (selected = value)"
            />
            <StatisticsItem
                :id="2"
                title="Awaiting Invoices"
                subtext="Purchase orders awaiting or not fully invoiced"
                icon="receipt-text-plus-outline"
                :loading="props.loading"
                :model-value="props.data.awaitingInvoices"
                :currency="true"
                :selectable="true"
                :class="{ selected: selected === 2 }"
                @update:model-value="(value) => (selected = value)"
            />
            <template v-if="Auth.can('manage supplier invoice payments')">
                <StatisticsItem
                    :id="3"
                    title="Outstanding Payments"
                    subtext="Purchase orders awaiting or not fully paid"
                    icon="credit-card-clock-outline"
                    :loading="props.loading"
                    :model-value="props.data.outstandingPayments"
                    :currency="true"
                    :selectable="true"
                    :class="{ selected: selected === 3 }"
                    @update:model-value="(value) => (selected = value)"
                />
                <StatisticsItem
                    :id="4"
                    title="Next Payment Run"
                    subtext="Purchase orders with payments on the next payrun"
                    icon="table-clock"
                    :loading="props.loading"
                    :model-value="props.data.nextPaymentRun !== 0 ? props.data.nextPaymentRun : 'None'"
                    :currency="props.data.nextPaymentRun ? true : false"
                    :selectable="!!props.data.nextPaymentRun"
                    :class="{ selected: selected === 4 }"
                    @update:model-value="(value) => (selected = value)"
                />
            </template>
        </StatisticsRow>

        <ew-card v-if="selected" :title="selectedItem" paddingless>
            <template #actions>
                <Tooltip label="Clear Filters" position="left">
                    <Button class="tw-ml-1" @click.prevent="() => (selected = null)">
                        <i class="mdi mdi-refresh"></i>
                    </Button>
                </Tooltip>
            </template>

            <div class="h-100">
                <div class="buefy-wrapper h-100 mb-2">
                    <div class="d-flex h-100">
                        <div class="d-flex flex-column flex-fill">
                            <DataTable
                                ref="table"
                                :endpoint="
                                    route('api.account.event.purchaseorders.allAnalysis', {
                                        filter: selected,
                                    })
                                "
                                :columns="[
                                    {
                                        title: 'Status',
                                        field: 'status',
                                    },
                                    {
                                        title: 'PO Title',
                                        field: 'purchase_orders.title',
                                    },
                                    {
                                        title: 'Department',
                                        field: 'departments.name',
                                    },
                                    {
                                        title: 'Subcategory',
                                        field: 'categories.name',
                                    },
                                    {
                                        title: 'Supplier',
                                        field: 'suppliers.name',
                                    },
                                    {
                                        title: 'Net Total',
                                        field: 'subtotal',
                                    },
                                    {
                                        title: 'Invoiced',
                                        field: 'total_invoiced',
                                    },
                                    {
                                        title: 'Paid',
                                        field: 'total_paid',
                                    },
                                    {
                                        title: 'Outstanding',
                                        field: 'outstanding',
                                    },
                                    {
                                        title: '',
                                        field: 'actions',
                                        width: 100,
                                        hidden:
                                            !Auth.can('view purchase orders') && !Auth.can('update purchase orders'),
                                    },
                                ]"
                            >
                                <!-- eslint-disable -->
                                <template #column(status)="{ row }">
                                    <Tag :status="row.status" />
                                </template>
                                <template #column(purchase_orders.title)="{ row }">
                                    {{ row.title }}
                                </template>
                                <template #column(departments.name)="{ row }">
                                    {{ row.category.department.name }}
                                </template>
                                <template #column(categories.name)="{ row }">
                                    <i
                                        v-if="row.category.xero_account_id"
                                        class="mdi mdi-swap-horizontal-circle text-xero"
                                        style="font-size: 1rem"
                                        title="Connected to Xero"
                                    ></i>
                                    {{ categoryPlaceholder(row.category.name) }}
                                </template>
                                <template #column(suppliers.name)="{ row }">
                                    <i
                                        v-if="row.supplier.xero_contact_id"
                                        class="mdi mdi-swap-horizontal-circle text-xero"
                                        style="font-size: 1rem"
                                        title="Connected to Xero"
                                    ></i>
                                    {{ row.supplier.name }}
                                </template>
                                <template #column(subtotal)="{ row }">
                                    {{ localise(row.subtotal, 2, true) }}
                                </template>
                                <template #column(total_invoiced)="{ row }">
                                    {{ localise(row.total_invoiced, 2, true) }}
                                </template>
                                <template #column(total_paid)="{ row }">
                                    {{ localise(row.total_paid, 2, true) }}
                                </template>
                                <template #column(outstanding)="{ row }">
                                    {{
                                        localise(row.total_invoiced_gross - row.total_paid - row.total_credit, 2, true)
                                    }}
                                </template>
                                <template #column(actions)="{ row }">
                                    <Tooltip
                                        v-if="Auth.can('view purchase orders')"
                                        label="View"
                                        class="ml-1"
                                        position="left"
                                    >
                                        <Button @click="openPurchaseOrder(row)">
                                            <i class="mdi mdi-eye-outline"></i>
                                        </Button>
                                    </Tooltip>
                                </template>
                                <template #empty>
                                    <div class="empty-label">There are no purchase orders to show</div>
                                </template>
                                <!-- eslint-enable -->
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </ew-card>
        <p v-else class="empty-label">Select one of the statistics above to view the associated purchase orders</p>
    </div>
</template>
<script>
import StatisticsRow from '../../widgets/statistics/StatisticsRow.vue';
import StatisticsItem from '../../widgets/statistics/StatisticsItem.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tag from '@/js/components/Tag.vue';

export default {
    components: {
        StatisticsRow,
        StatisticsItem,
        Button,
        Tooltip,
        DataTable,
        Tag,
    },
    data() {
        return {
            selected: null,
        };
    },
    computed: {
        selectedItem() {
            switch (this.selected) {
                case 1:
                    return 'Purchase Orders (Awaiting Approval)';
                case 2:
                    return 'Purchase Orders (Awaiting Invoices)';
                case 3:
                    return 'Purchase Orders (Outstanding Payments)';
                case 4:
                    return 'Purchase Orders (Next Payment Run)';
                default:
                    return 'Purchase Order Analysis';
            }
        },
    },
    watch: {
        selected() {
            this.reloadData();
        },
    },
    mounted() {
        this.reloadData();
    },
    methods: {
        reloadData() {
            this.$refs.table?.refreshData();
        },
        categoryPlaceholder(rowData) {
            return rowData ?? 'No Category';
        },
        openPurchaseOrder(po) {
            Eventbus.$emit('purchaseOrder:view', po.id);
        },
    },
};
</script>
