<template>
    <UtilityPage>
        <template #before>
            <div class="tw-flex tw-gap-2 tw-items-center tw-justify-center tw-w-full tw-h-full tw-bg-[rgba(0,0,0,.1)]">
                <img src="@/images/icon-white.svg" alt="eventwise" class="tw-max-w-[52px]" />
                <h1 class="!tw-font-dm-sans tw-font-[600] tw-text-white tw-tracking-tight">eventwise</h1>
            </div>
        </template>
        <template v-if="!currentPage && !isResettingPassword">
            <h1 class="tw-text-white tw-text-2xl !tw-font-dm-sans tw-mb-6">Sign in</h1>
            <form ref="loginForm" :action="route('auth.loginsubmit')" class="tw-min-w-[50%]" method="POST">
                <input type="hidden" name="_token" :value="csrfToken" />
                <FormField
                    class="tw-mb-2"
                    :error="loginEmail && !loginEmailIsValid ? 'Please enter a valid email address' : null"
                >
                    <Textbox
                        v-model="loginEmail"
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                        required
                    />
                </FormField>
                <FormField class="tw-mb-2">
                    <Textbox
                        v-model="loginPassword"
                        type="password"
                        placeholder="Password"
                        name="password"
                        input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                        required
                    />
                </FormField>
                <Banner v-if="hasError" type="is-danger" class="tw-mb-3 tw-font-roboto">
                    You entered an invalid email address or password
                    <br />
                    Please check your credentials and try again
                </Banner>
                <Banner v-if="hasSubmittedReset" type="is-success" class="tw-mb-3 tw-font-roboto">
                    If you have an Eventwise account associated with the email address
                    <br />
                    provided we have sent instructions on how to reset your password.
                </Banner>
                <Button
                    class="tw-w-full !tw-py-2.5 !tw-font-roboto !tw-text-sm !tw-font-medium"
                    :disabled="!loginEmailIsValid || !loginPassword"
                    @click="$refs.loginForm.submit()"
                >
                    Login
                </Button>
                <div class="tw-flex tw-justify-between tw-mt-3 tw-w-full">
                    <a
                        href="#"
                        class="tw-text-white hover:tw-text-white tw-font-roboto hover:tw-underline"
                        @click.prevent="currentPage = 'reset'"
                    >
                        Forgot password?
                    </a>
                    <a
                        href="#"
                        class="tw-text-white hover:tw-text-white tw-font-roboto hover:tw-underline"
                        @click.prevent="currentPage = 'register'"
                    >
                        Create an account
                    </a>
                </div>
                <div class="tw-mb-5 tw-mt-4 tw-text-white tw-opacity-[33%] tw-relative tw-w-full tw-text-center">
                    <div class="tw-h-[1px] tw-bg-white tw-w-full tw-absolute tw-top-2.5 tw-left-0 tw-z-[0]"></div>
                    <span class="tw-px-3 tw-bg-eventwise-dark tw-relative tw-z-[1] tw-font-roboto tw-font-light">
                        or sign in with
                    </span>
                </div>
                <div class="tw-flex tw-gap-2 tw-w-full">
                    <Button
                        class="tw-flex tw-gap-2 tw-w-full tw-pb-2 tw-pt-1.5 tw-text-base tw-items-center tw-justify-center tw-font-roboto"
                        @click="authenticateWithSso('google')"
                    >
                        <img src="@/images/google.svg" class="!tw-h-[24px]" alt="Google" />
                        Google
                    </Button>
                    <Button
                        class="tw-flex tw-gap-2 tw-w-full tw-pb-2 tw-pt-1.5 tw-text-base tw-items-center tw-justify-center tw-font-roboto"
                        @click="authenticateWithSso('microsoft')"
                    >
                        <img src="@/images/microsoft.svg" class="!tw-h-[24px]" alt="Microsoft" />
                        Microsoft
                    </Button>
                    <Button
                        class="tw-flex tw-gap-2 tw-w-full tw-pb-2 tw-pt-1.5 tw-text-base tw-items-center tw-justify-center tw-font-roboto"
                        @click="authenticateWithSso('xero')"
                    >
                        <img src="@/images/brands/xero.svg" class="!tw-h-[24px]" alt="Xero" />
                        Xero
                    </Button>
                </div>
            </form>
        </template>
        <div v-else-if="!currentPage && isResettingPassword">
            <div class="tw-min-w-[50%]">
                <Banner class="tw-mb-3 tw-text-center">
                    You are resetting your password for the account associated with
                    <br />
                    <span class="!tw-font-medium">{{ route().params.email }}</span>
                </Banner>
                <form ref="resetForm" :action="route('password.update')" method="POST">
                    <input type="hidden" name="_token" :value="csrfToken" />
                    <input type="hidden" name="token" :value="route().params.token" />
                    <input type="hidden" name="email" :value="route().params.email" />
                    <FormField class="tw-mb-2">
                        <Textbox
                            v-model="newUserData.password"
                            autocomplete="new-password"
                            type="password"
                            placeholder="New Password"
                            name="password"
                            required
                        />
                    </FormField>
                    <FormField class="tw-mb-2">
                        <Textbox
                            v-model="newUserData.password_confirm"
                            autocomplete="new-password"
                            type="password"
                            placeholder="Confirm New Password"
                            name="password_confirmation"
                            required
                        />
                    </FormField>
                    <Banner v-if="Auth.errors.length" type="is-danger" class="tw-mb-3">
                        {{ Auth.errors.join(' ') }}
                    </Banner>
                    <Button
                        class="tw-w-full !tw-py-2.5 !tw-font-roboto !tw-text-sm !tw-font-medium"
                        @click.prevent="submitResetSet()"
                    >
                        Reset Password
                    </Button>
                </form>
            </div>
        </div>
        <template v-else-if="currentPage === 'register'">
            <h1 class="tw-text-white tw-text-2xl !tw-font-dm-sans tw-mb-6">Create an account</h1>
            <form class="tw-min-w-[50%] tw-mb-6">
                <FormField class="tw-mb-2">
                    <Textbox
                        key="registerName"
                        v-model="newUserData.name"
                        autocomplete="name"
                        placeholder="Your Name"
                        required
                        input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                    />
                </FormField>
                <FormField class="tw-mb-2">
                    <Textbox
                        key="registerEmail"
                        v-model="newUserData.email"
                        autocomplete="email"
                        type="email"
                        placeholder="Email Address"
                        required
                        input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                    />
                </FormField>
                <FormField class="tw-mb-2">
                    <div class="tw-flex">
                        <div
                            class="tw-rounded-l tw-bg-[rgba(255,255,255,.05)] tw-font-roboto tw-font-[300] tw-text-white tw-px-3 tw-flex tw-items-center"
                        >
                            +44
                        </div>
                        <Textbox
                            v-model="newUserData.phone"
                            autocomplete="tel"
                            class="tw-w-full"
                            placeholder="Phone Number"
                            input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded-l-0 !tw-rounded-r !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                        />
                    </div>
                </FormField>
                <FormField class="tw-mb-2">
                    <Textbox
                        v-model="newUserData.password"
                        autocomplete="new-password"
                        type="password"
                        placeholder="Password"
                        required
                        input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                    />
                </FormField>
                <FormField class="tw-mb-3">
                    <Textbox
                        v-model="newUserData.password_confirm"
                        autocomplete="new-password"
                        type="password"
                        placeholder="Confirm Password"
                        required
                        input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                    />
                </FormField>
                <Button
                    class="tw-w-full !tw-py-2.5 !tw-font-roboto !tw-text-sm !tw-font-medium"
                    @click.prevent="submitRegister()"
                >
                    Create Account
                </Button>

                <div class="tw-my-5 tw-text-white tw-opacity-[33%] tw-relative tw-w-full tw-text-center">
                    <div class="tw-h-[1px] tw-bg-white tw-w-full tw-absolute tw-top-2.5 tw-left-0 tw-z-[0]"></div>
                    <span class="tw-px-3 tw-bg-eventwise-dark tw-relative tw-z-[1] tw-font-roboto tw-font-light">
                        or create an account with
                    </span>
                </div>
                <div class="tw-flex tw-gap-2 tw-w-full">
                    <Button
                        class="tw-flex tw-gap-2 tw-w-full tw-pb-2 tw-pt-1.5 tw-text-base tw-items-center tw-justify-center tw-font-roboto"
                        @click="authenticateWithSso('google')"
                    >
                        <img src="@/images/google.svg" class="!tw-h-[24px]" alt="Google" />
                        Google
                    </Button>
                    <Button
                        class="tw-flex tw-gap-2 tw-w-full tw-pb-2 tw-pt-1.5 tw-text-base tw-items-center tw-justify-center tw-font-roboto"
                        @click="authenticateWithSso('microsoft')"
                    >
                        <img src="@/images/microsoft.svg" class="!tw-h-[24px]" alt="Microsoft" />
                        Microsoft
                    </Button>
                    <Button
                        class="tw-flex tw-gap-2 tw-w-full tw-pb-2 tw-pt-1.5 tw-text-base tw-items-center tw-justify-center tw-font-roboto"
                        @click="authenticateWithSso('xero')"
                    >
                        <img src="@/images/brands/xero.svg" class="!tw-h-[24px]" alt="Xero" />
                        Xero
                    </Button>
                </div>
            </form>
            <a
                href="#"
                class="tw-text-white hover:tw-text-white tw-font-roboto hover:tw-underline"
                @click.prevent="currentPage = null"
            >
                &#8592;&nbsp; Return to login
            </a>
        </template>
        <template v-else-if="currentPage === 'reset'">
            <h1 class="tw-text-white tw-text-2xl !tw-font-dm-sans tw-mb-3">Forgot password</h1>
            <div class="tw-min-w-[50%] tw-mb-6">
                <p class="tw-mb-6 tw-font-roboto tw-text-center tw-text-[rgba(255,255,255,.5)] tw-leading-5">
                    If you have forgotten your password you can enter your email address
                    <br />
                    below and we'll send you a link to reset your password.
                </p>
                <FormField class="tw-mb-3">
                    <Textbox
                        key="forgotEmail"
                        v-model="forgotPasswordEmail"
                        type="email"
                        placeholder="Email Address"
                        :disabled="isLoading"
                        required
                        input-class="!tw-px-5 !tw-py-2.5 !tw-text-sm !tw-bg-[rgba(255,255,255,0.1)] !tw-border-none !tw-rounded !tw-font-roboto hover:!tw-bg-[rgba(255,255,255,0.15)] focus:!tw-bg-[rgba(255,255,255,0.15)] !tw-text-white"
                    />
                </FormField>
                <Button
                    class="tw-w-full !tw-py-2.5 !tw-font-roboto !tw-text-sm !tw-font-medium"
                    :disabled="!resetEmailIsValid"
                    :loading="isLoading"
                    @click="submitReset()"
                >
                    Reset Password
                </Button>
            </div>
            <a
                href="#"
                class="tw-text-white hover:tw-text-white tw-font-roboto hover:tw-underline"
                @click.prevent="currentPage = null"
            >
                &#8592;&nbsp; Return to login
            </a>
        </template>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <template #footnote>
            {{ eventwiseVersion }}
            <span>&copy; Copyright Eventwise 2023.</span>
        </template>
    </UtilityPage>
</template>
<script>
import axios from 'axios';
import UtilityPage from '@/js/components/UtilityPage.vue';
import LoadingSpinner from './widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        LoadingSpinner,
        UtilityPage,
        Button,
        FormField,
        Textbox,
        Banner,
    },
    data() {
        return {
            isLoading: false,
            currentPage: null,
            csrfToken: window.Laravel.csrfToken,
            hasError: window.Auth.errors.length,
            hasSubmittedReset: false,
            eventwiseVersion: window.Eventwise.version,
            newUserData: {},
            loginEmail: null,
            loginPassword: null,
            forgotPasswordEmail: null,
        };
    },
    computed: {
        loginEmailIsValid() {
            const expression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return !!(this.loginEmail && this.loginEmail.match(expression));
        },
        resetEmailIsValid() {
            const expression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return !!(this.forgotPasswordEmail && this.forgotPasswordEmail.match(expression));
        },
        isResettingPassword() {
            return route().current() === 'password.reset' && route().params.token && route().params.email;
        },
    },
    watch: {
        currentPage() {
            this.newUserData = {};
            this.hasError = false;
            this.forgotPasswordEmail = null;
        },
    },
    methods: {
        authenticateWithSso(provider) {
            window.location.href = '/auth/sso/' + provider;
        },
        submitLogin() {
            this.isLoading = true;
            this.$refs.loginForm.submit();
        },
        submitRegister() {
            this.isLoading = true;
            axios
                .post(route('api.onboarding.register'), this.newUserData)
                .then(() => (window.location.href = route('onboarding')))
                .finally(() => (this.isLoading = false));
        },
        submitReset() {
            if (!this.forgotPasswordEmail || !this.resetEmailIsValid) {
                return;
            }
            this.isLoading = true;
            axios
                .post(route('password.email'), {
                    email: this.forgotPasswordEmail,
                })
                .then(() => {
                    this.hasSubmittedReset = true;
                    this.currentPage = null;
                })
                .finally(() => (this.isLoading = false));
        },
        submitResetSet() {
            this.isLoading = true;
            this.$refs.resetForm.submit();
        },
    },
};
</script>
