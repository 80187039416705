<template>
    <section class="page-content" style="padding-left: 70px">
        <div class="page-main-content">
            <div class="page-main-content-container">
                <div class="container-fluid text-center py-5">
                    <i class="mdi mdi-file-question-outline" style="font-size: 72px"></i>
                    <h2>404 - Page Not Found</h2>
                    <p>
                        Unfortunately the page you were looking for is not here.
                        <br />
                        Please make use of the navigation to get back on track.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<style lang="scss">
section.page-content {
    & > .page-bar {
        padding: 3px 16px 0 16px;
        background: white;

        & > .page-info {
            display: flex;
            align-items: center;
            width: 100%;

            .page-title {
                margin: 10px 0;
            }
        }

        & > .submenu:not(:empty) {
            display: flex;
            align-items: flex-end;
            min-height: 35px;

            & > a,
            & > .dropdown > a {
                display: block;
                box-sizing: border-box;
                padding: 5px 4px;
                margin-top: 3px;
                opacity: 0.6;
                transition: all 0.1s ease;

                &.active {
                    border-bottom: 3px solid #3a667d;
                    color: #3a667d !important;
                    margin-top: 0;
                    opacity: 1;
                }

                &:hover:not(.active) {
                    border-bottom: 3px solid #4ea5d9;
                    margin-top: 0;
                    color: #4ea5d9;
                    opacity: 1;
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            & > .dropdown {
                &:hover {
                    & > a {
                        border-bottom: 3px solid #4ea5d9;
                        margin-top: 0;
                        color: #4ea5d9;
                        opacity: 1;
                    }
                    & > .dropdown-menu {
                        display: block;
                    }
                }
            }
        }
    }

    & > .page-main-content {
        display: flex;

        & > .page-sidebar {
            background: white;
            min-width: 260px;
            max-width: 300px;
            padding: 16px;
            height: calc(100vh - 106px);
            border-right: 1px solid rgba(black, 0.05);

            &.has-submenu {
                height: calc(100vh - 140px);
            }
        }

        @media (max-width: 900px) {
            flex-direction: column;

            & > .page-sidebar {
                min-width: unset;
                padding: 0 20px 10px 20px;
                max-width: unset;
                height: unset;
            }
        }

        & > div:not(.page-sidebar) {
            flex: 1;
        }
    }
}
</style>
