import { DateTime } from 'luxon';
import { defineStore } from 'pinia';

export const useSettingsStore = defineStore('EventwiseSettings', {
    state: () => {
        let storedSettings = localStorage.getItem('EventwiseSettings');
        storedSettings = storedSettings ? JSON.parse(storedSettings) : null;
        const currentRevision = parseInt(storedSettings?.settings_revision ?? 0);
        const globalRevision = parseInt(window.Eventwise.global_settings.settings_revision);
        if (currentRevision < globalRevision) {
            localStorage.removeItem('EventwiseSettings');
            storedSettings = null;
        }
        return (
            storedSettings ?? {
                settings_revision: Math.floor(DateTime.now().toUTC().toSeconds()),
                budgetFigures: {
                    cost: {
                        draft: false,
                        approvals: false,
                        approved: true,
                        rejected: false,
                    },
                    revenue: {
                        draft: false,
                        approvals: false,
                        approved: true,
                        rejected: false,
                    },
                },
            }
        );
    },
    getters: {},
    actions: {
        get(key, defaultValue = false) {
            let keys = key.split('.');

            let target = this.$state;

            keys.forEach((item) => {
                if (target !== undefined) {
                    target = target[item] ?? defaultValue;
                }
            });

            if (target === undefined) {
                target = defaultValue;
            }

            if (target && target === defaultValue) {
                this.set(key, defaultValue);
            }

            return target;
        },
        account(id, key) {
            return this.get(`account_${id}_${key}`);
        },
        event(id, key) {
            return this.get(`event_${id}_${key}`);
        },
        set(path, value) {
            const keys = Array.isArray(path) ? path : path.split('.');
            let currentStep = this.$state;

            for (let i = 0; i < keys.length - 1; i++) {
                const key = keys[i];

                if (!currentStep[key] && !currentStep.hasOwnProperty(key)) {
                    // So; commenting this out as pretty sure we don't care about arrays here...
                    // const nextKey = keys[i + 1];
                    // const useArray = /^\+?(0|[1-9]\d*)$/.test(nextKey);
                    // currentStep[key] = useArray ? [] : {};
                    currentStep[key] = {};
                }

                currentStep = currentStep[key];
            }

            const finalStep = keys[keys.length - 1];
            currentStep[finalStep] = value;
            this.saveToStorage();
        },
        setEvent(id, key, value) {
            return this.set(`event_${id}_${key}`, value);
        },
        setAccount(id, key, value) {
            return this.set(`account_${id}_${key}`, value);
        },
        delete(key) {
            let keys = key.split('.');
            let target = this.$state;

            keys.forEach((item, index) => {
                if (target !== undefined) {
                    try {
                        if (index === keys.length - 1) {
                            delete target[item];
                        } else {
                            target = target[item];
                        }
                    } catch (e) {
                        target = !e;
                    }
                }
            });
            this.saveToStorage();
        },
        saveToStorage() {
            const currentSettings = this.$state;
            localStorage.setItem('EventwiseSettings', JSON.stringify(currentSettings));
        },
        refresh() {
            return this;
        },
    },
});
