const initialise = () => {
    window.addEventListener('click', (e) => {
        if (e.target && e.target.classList.contains('mentionable')) {
            e.preventDefault();
            switch (e.target.dataset.model) {
                case 'BudgetEntry':
                    Eventbus.$emit('budget:entry', {
                        entry: e.target.dataset.id,
                    });
                    break;
                case 'PurchaseOrder':
                    Eventbus.$emit('purchaseOrder:view', {
                        event: e.target.dataset.event,
                        purchaseOrder: e.target.dataset.id,
                    });
                    break;
                case 'RevenueEntry':
                    Eventbus.$emit('revenue:entry', {
                        entry: e.target.dataset.id,
                    });
                    break;
                default:
                    break;
            }
        }
    });
};

export const mentionables = {
    initialise,
};
