<template>
    <Page title="Settlement Report" class="tw-text-center">
        <a :href="route('account.event.export.settlement-report')" class="tw-mb-3 tw-block tw-mt-5">
            Download PDF
            <i class="mdi mdi-download"></i>
        </a>
        <div class="tw-grid lg:tw-grid-cols-2 tw-gap-3 tw-mb-5">
            <DataTable
                :data="revenueRows"
                :columns="[
                    {
                        title: 'Turnover',
                        field: 'turnover',
                    },
                    {
                        title: 'Actual',
                        field: 'total_actual',
                    },
                    {
                        title: 'Forecast',
                        field: 'total_budgeted',
                    },
                    {
                        title: 'Variance',
                        field: 'variance',
                    },
                ]"
            >
                <template #before>
                    <strong class="tw-text-xl">Revenue</strong>
                </template>
                <template #column(turnover)="{ row }">
                    {{ row.name }}
                </template>
                <template #column(total_actual)="{ row }">
                    <span
                        :class="{
                            'tw-text-red-500': row.total_actual < 0,
                            'tw-text-green-500': row.total_actual >= 0,
                            '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                        }"
                    >
                        {{ localise(row.total_actual, 2, true) }}
                    </span>
                </template>
                <template #column(total_budgeted)="{ row }">
                    <span
                        :class="{
                            'tw-text-red-500': row.total_budgeted < 0,
                            'tw-text-green-500': row.total_budgeted >= 0,
                            '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                        }"
                    >
                        {{ localise(row.total_budgeted, 2, true) }}
                    </span>
                </template>
                <template #column(variance)="{ row }">
                    <span
                        :class="{
                            'tw-text-red-500': row.total_actual < 0,
                            'tw-text-green-500': row.total_actual >= 0,
                            '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                        }"
                    >
                        {{ localise(row.total_actual - row.total_budgeted, 2, true) }}
                    </span>
                </template>
            </DataTable>
            <DataTable
                :data="costRows"
                :columns="[
                    {
                        title: 'Turnover',
                        field: 'turnover',
                    },
                    {
                        title: 'Actual',
                        field: 'total_actual',
                    },
                    {
                        title: 'Forecast',
                        field: 'total_budgeted',
                    },
                    {
                        title: 'Variance',
                        field: 'variance',
                    },
                ]"
            >
                <template #before>
                    <strong class="tw-text-xl">Costs</strong>
                </template>
                <template #column(turnover)="{ row }">
                    {{ row.name }}
                </template>
                <template #column(total_actual)="{ row }">
                    <span
                        :class="{
                            '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                        }"
                    >
                        {{ localise(row.total_actual, 2, true) }}
                    </span>
                </template>
                <template #column(total_budgeted)="{ row }">
                    <span
                        :class="{
                            '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                        }"
                    >
                        {{ localise(row.total_budgeted, 2, true) }}
                    </span>
                </template>
                <template #column(variance)="{ row }">
                    <span
                        :class="{
                            'tw-text-red-500': row.total_actual < 0,
                            'tw-text-green-500': row.total_actual >= 0,
                            '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                        }"
                    >
                        {{ localise(row.total_actual - row.total_budgeted, 2, true) }}
                    </span>
                </template>
            </DataTable>
        </div>

        <!-- <div class="tw-grid lg:tw-grid-cols-2 tw-gap-3">
                <DataTable :data="grossRows" class="tw-max-h-[25rem] tw-overflow-y-auto">
                    <template #before>
                        <strong class="tw-text-xl">Gross</strong>
                    </template>
                    <template #default="{ row }">
                        <DataTableColumn
                            title=""
                            field="name"
                            :class="{
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ row.name }}
                        </DataTableColumn>
                        <DataTableColumn
                            title=""
                            field="total_actual"
                            :class="{
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ localise(row.total_actual, 2, true) }}
                        </DataTableColumn>
                        <DataTableColumn
                            title=""
                            field="total_budgeted"
                            :class="{
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ localise(row.total_budgeted, 2, true) }}
                        </DataTableColumn>
                        <DataTableColumn
                            title=""
                            field="variance"
                            :class="{
                                'tw-text-red-500': row.total_actual - row.total_budgeted >= 0,
                                'tw-text-green-500': row.total_actual - row.total_budgeted < 0,
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ localise(row.total_actual - row.total_budgeted, 2, true) }}
                        </DataTableColumn>
                    </template>
                </DataTable>

                <DataTable :data="kpiRows" class="tw-max-h-[25rem] tw-overflow-y-auto">
                    <template #before>
                        <strong class="tw-text-xl">KPIs</strong>
                    </template>
                    <template #default="{ row }">
                        <DataTableColumn
                            title=""
                            field="name"
                            :class="{
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ row.name }}
                        </DataTableColumn>
                        <DataTableColumn
                            title=""
                            field="total_actual"
                            :class="{
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ localise(row.total_actual, 2, true) }}
                        </DataTableColumn>
                        <DataTableColumn
                            title=""
                            field="total_budgeted"
                            :class="{
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ localise(row.total_budgeted, 2, true) }}
                        </DataTableColumn>
                        <DataTableColumn
                            title=""
                            field="variance"
                            :class="{
                                'tw-text-red-500': row.total_actual - row.total_budgeted >= 0,
                                'tw-text-green-500': row.total_actual - row.total_budgeted < 0,
                                '!tw-font-bold tw-bg-slate-200': row.is_total_column,
                            }"
                        >
                            {{ localise(row.total_actual - row.total_budgeted, 2, true) }}
                        </DataTableColumn>
                    </template>
                </DataTable>
            </div> -->
    </Page>
</template>
<script>
import Page from '../../Page.vue';
import DataTable from '../../tables/DataTable.vue';
import { useDataStore } from '@/js/stores/DataStore.js';

export default {
    components: {
        Page,
        DataTable,
    },

    data() {
        const store = useDataStore();
        return {
            revenueRows: Object.values(store.get('current_event.categories')).filter((r) => r.is_revenue),
            costRows: Object.values(store.get('current_event.categories')).filter((r) => !r.is_revenue),
            grossRows: [],
            kpiRows: [],
        };
    },

    mounted() {
        this.revenueRows.push({
            name: 'Total',
            total_actual: this.revenueRows.reduce((acc, row) => acc + row.total_actual, 0),
            total_budgeted: this.revenueRows.reduce((acc, row) => acc + row.total_budgeted, 0),
            total_variance: this.revenueRows.reduce((acc, row) => acc + row.total_actual - row.total_budgeted, 0),
            is_total_column: true,
        });
        this.costRows.push({
            name: 'Total',
            total_actual: this.costRows.reduce((acc, row) => acc + row.total_actual, 0),
            total_budgeted: this.costRows.reduce((acc, row) => acc + row.total_budgeted, 0),
            total_variance: this.costRows.reduce((acc, row) => acc + row.total_actual - row.total_budgeted, 0),
            is_total_column: true,
        });
        // this.grossRows = [
        //     {
        //         name: 'Gross Profit',
        //         total_actual: 0,
        //         total_forecast: 0,
        //         total_variance: 0,
        //         is_total_column: true,
        //     },
        //     {
        //         name: 'Gross Margin',
        //        total_actual: 0,
        //         total_forecast: 0,
        //         total_variance: 0,
        //         is_total_column: true,
        //     }
        // ]
    },
};
</script>

<style scoped>
.ew-data-table {
    @apply tw-h-fit;
}
</style>
