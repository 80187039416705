<template>
    <Modal
        id="createBankTransactionModal"
        ref="modal"
        size="is-large"
        title="Create Bank Transaction"
        :loading="isLoading"
        @closing="resetForm()"
    >
        <Banner v-if="formData.transactionable_type" type="is-info" :closable="false">
            You are adding a bank transaction for money received relating to a
            {{ formData.transactionable_type }}.
        </Banner>
        <FormField v-if="isConnectedToXero" class="tw-mb-10">
            <Select
                v-model="formData.xero_account_id"
                :loading="!xeroAccounts.length"
                placeholder="Select a Bank Account"
            >
                <option v-for="xeroAccount in xeroAccounts" :key="xeroAccount.id" :value="xeroAccount.id">
                    {{ xeroAccount.name }}
                </option>
            </Select>
        </FormField>
        <FormField class="tw-mt-3" label="From" grouped>
            <Select v-model="formData.parent_type" :loading="isLoading" expanded>
                <option value="Supplier">Supplier</option>
            </Select>
            <Selector
                v-if="!isLoading && formData.parent_type === 'Supplier' && formData.parent_id"
                v-model="formData.parent_id"
                editable
                :endpoint="route('api.account.contacts')"
                :create-endpoint="route('api.account.contacts.create')"
                allow-create
                allow-search
            ></Selector>
            <Textbox v-else model-value="Direct Sales" disabled></Textbox>
        </FormField>
        <FormField class="tw-mt-3" label="Name">
            <Textbox v-model="formData.name" maxlength="255" expanded></Textbox>
        </FormField>
        <FormField class="tw-mt-3">
            <Currency v-model="formData.amount" label="Amount" />
        </FormField>
        <FormField class="tw-mt-3">
            <input-edit v-model="formData.notes" label="Additional Notes (optional)"></input-edit>
        </FormField>
        <template #footer>
            <div class="tw-flex tw-justify-between">
                <Button :loading="isLoading" @click="closeModal()">Cancel</Button>
                <Button :disabled="!isValid" :loading="isLoading" type="is-primary" @click="submitForm()">
                    Add Transaction
                </Button>
            </div>
        </template>
    </Modal>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import Currency from '@/js/components/controls/Currency.vue';
import Selector from '@/assets/js/eventbooks/components/modals/selector.vue';
import Modal from '@/js/components/Modal.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Selector,
        Button,
        Textbox,
        Banner,
        FormField,
        Select,
        Currency,
    },
    data() {
        return {
            isLoading: false,
            isConnectedToXero: false,
            xeroAccounts: [],
            formData: {
                amount: 0,
            },
        };
    },
    computed: {
        isValid() {
            return (
                this.formData.amount &&
                this.formData.parent_type &&
                this.formData.name &&
                (this.isConnectedToXero ? this.formData.xero_account_id : true)
            );
        },
    },
    mounted() {
        Eventbus.$on('create:bankTransaction', (payload) => {
            if (payload) {
                if (!payload.parent_type) {
                    swal.fire('Error', 'Payload for create:bankTransaction does not contain a parent type', 'error');
                    return;
                }
                this.formData = { ...this.formData, ...payload };
            } else {
                swal.fire('Error', 'Payload for create:bankTransaction is not an object', 'error');
                return;
            }
            if (Auth && Auth.account && Auth.account.is_connected_to_xero) {
                this.isConnectedToXero = true;
                this.getXeroAccounts();
            }
            if (this.$refs.modal) {
                this.$refs.modal.open();
            }
        });
    },
    methods: {
        resetForm() {
            this.isConnectedToXero = false;
            this.isLoading = false;
            this.xeroAccounts = [];
            this.formData = {
                amount: 0,
            };
        },
        getXeroAccounts() {
            axios
                .get(route('api.account.xero.accounts'))
                .then(({ data }) => (this.xeroAccounts = data.filter((xeroAccount) => xeroAccount.type == 'BANK')))
                .finally(() => {
                    if (!this.xeroAccounts.length) {
                        swal.fire('Error', "You don't have any bank accounts imported from Xero", 'error');
                        this.closeModal();
                    }
                });
        },
        closeModal() {
            this.$refs.modal.close();
        },
        submitForm() {
            if (!this.isValid) {
                swal.fire('Error', 'Please check you have completed all required fields and try again', 'error');
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.event.bankTransactions.create'), this.formData)
                .then(() =>
                    toast(
                        'Success',
                        'Your bank transaction has been created successfully, it may take a few moments for the page to update',
                        'success'
                    )
                )
                .finally(() => {
                    this.isLoading = false;
                    this.closeModal();
                });
        },
    },
};
</script>
