<template>
    <div class="onboarding-events-display">
        <div class="onboarding-events-panel">
            <div class="tw-flex tw-items-center tw-gap-2 tw-mb-3">
                <h4 class="tw-flex-1 tw-m-0">Your Events</h4>
                <Textbox v-model="searchQuery" type="text" placeholder="Search for an event..." />
                <Dropdown :hide-on-click="false" anchor-right>
                    <template #trigger>
                        <a href="#"><i class="mdi mdi-cog"></i></a>
                    </template>
                    <span>Sort</span>
                    <a href="#" :class="{ active: sortBy === 'name' }" @click.prevent="sortBy = 'name'">
                        <i class="mdi mdi-tag-text"></i>
                        Name
                    </a>
                    <a
                        href="#"
                        :class="{ active: sortBy === 'last_accessed' }"
                        @click.prevent="sortBy = 'last_accessed'"
                    >
                        <i class="mdi mdi-login"></i>
                        Last Accessed
                    </a>
                    <a href="#" :class="{ active: sortBy === 'start_date' }" @click.prevent="sortBy = 'start_date'">
                        <i class="mdi mdi-calendar-arrow-left"></i>
                        Start Date
                    </a>
                    <hr />
                    <span>Filters</span>
                    <Checkbox v-model="showPast">Show past events</Checkbox>
                </Dropdown>
            </div>
            <div v-if="visibleEvents.length" class="onboarding-event-list">
                <a
                    v-for="event in visibleEvents"
                    :key="event.id"
                    :href="
                        route('account.event.dashboard', {
                            account: event.account_id,
                            event: event.id,
                        })
                    "
                    class="hover:tw-bg-gray-50"
                    @click.prevent="launchEvent(event)"
                >
                    <div class="onboarding-event-image"></div>
                    <div>
                        <h6 class="tw-m-0">
                            <i v-if="event.is_closed" class="mdi mdi-lock"></i>
                            {{ event.name }}
                        </h6>
                        <div class="d-flex">
                            <template v-if="event.is_closed">
                                <small>Closed</small>
                                <small class="tw-mx-1">&bull;</small>
                            </template>
                            <small v-if="DateTime.fromISO(event.starts_at).diffNow('days').days > 0">
                                Starts
                                {{
                                    DateTime.fromISO(event.starts_at).diffNow('days').days < 31
                                        ? 'in ' +
                                          Math.round(DateTime.fromISO(event.starts_at).diffNow('days').days) +
                                          ' days'
                                        : DateTime.fromISO(event.starts_at).toLocaleString(DateTime.DATE_MED)
                                }}
                            </small>
                            <small v-else>
                                Ended
                                {{
                                    DateTime.fromISO(event.ends_at).diffNow('days').days > -31
                                        ? Math.abs(Math.round(DateTime.fromISO(event.ends_at).diffNow('days').days)) +
                                          ' days ago'
                                        : DateTime.fromISO(event.ends_at).toLocaleString(DateTime.DATE_MED)
                                }}
                            </small>
                            <small class="tw-mx-1">&bull;</small>
                            <small>
                                {{
                                    event.pivot.event_last_accessed
                                        ? 'Last accessed ' +
                                          DateTime.fromSQL(event.pivot.event_last_accessed).toRelative()
                                        : 'Never accessed'
                                }}
                            </small>
                        </div>
                    </div>
                </a>
            </div>
            <div v-else class="text-muted text-center">You haven&apos;t accessed any events recently</div>
        </div>
        <div class="onboarding-actions-panel bg-light">
            <a
                href="#"
                :class="{ upgrade: account.is_demo }"
                @click.prevent="account.is_demo ? null : $emit('navigate', 'create')"
            >
                <i class="mdi mdi-calendar-plus"></i>
                <div>
                    Create a new event
                    <small>Enter your details and create a new event from scratch</small>
                </div>
            </a>
            <a
                href="#"
                :class="{ upgrade: account.is_demo }"
                @click.prevent="account.is_demo ? null : $emit('navigate', 'copy')"
            >
                <i class="mdi mdi-content-copy"></i>
                <div>
                    Copy from a previous event
                    <small>Use an existing event as a base for a new or recurring event</small>
                </div>
            </a>
            <a class="coming-soon" href="#">
                <i class="mdi mdi-content-duplicate"></i>
                <div>
                    Create from a template
                    <small>Use one of our pre-defined templates to get your event started</small>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import Dropdown from '../../controls/Dropdown.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        Dropdown,
        Checkbox,
        Textbox,
    },
    props: {
        account: {
            type: Object,
            required: true,
        },
    },
    emits: ['navigate'],
    data() {
        return {
            isLoading: false,
            sortBy: 'last_accessed',
            showPast: true,
            availableEvents: [],
            searchQuery: null,
            DateTime,
        };
    },
    computed: {
        visibleEvents() {
            let events = this.availableEvents.filter((e) =>
                this.showPast ? true : e.ends_at ? DateTime.fromISO(e.ends_at) > DateTime.now() : true
            );
            if (this.searchQuery) {
                events = events.filter((e) => e.name.includes(this.searchQuery));
            }
            events = events
                .sort((a, b) => {
                    if (this.sortBy === 'last_accessed') {
                        return (
                            (a.pivot.event_last_accessed ? DateTime.fromSQL(a.pivot.event_last_accessed) : 0) -
                            (b.pivot.event_last_accessed ? DateTime.fromSQL(b.pivot.event_last_accessed) : 0)
                        );
                    } else if (this.sortBy === 'name') {
                        return a.name - b.name;
                    }
                    return (
                        (a.starts_at ? DateTime.fromSQL(a.starts_at) : 0) -
                        (b.starts_at ? DateTime.fromSQL(b.starts_at) : 0)
                    );
                })
                .reverse();
            return events.slice(0, 5);
        },
    },
    mounted() {
        this.getEvents();
    },
    methods: {
        getEvents() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.events', {
                        account: this.account.id,
                    })
                )
                .then(({ data }) => (this.availableEvents = data))
                .finally(() => (this.isLoading = false));
        },
        launchEvent(event) {
            window.location.href = route('account.event.dashboard', {
                account: this.account.id,
                event: event.id,
            });
        },
    },
};
</script>
