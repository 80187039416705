<template>
    <div class="buefy-wrapper">
        <Banner class="mt-5 mb-2 py-3" type="is-primary">
            <p>
                This report displays your total sales or costs for the selected events.
                <br />
                If you have selected multiple events you can reorder the columns with the header arrows, or temporarily
                hide an event by clicking the
                <i class="mdi mdi-eye-off"></i>
                Eye icon.
            </p>
        </Banner>
        <SalesAndCostsTable :events="eventIds" />
    </div>
</template>
<script>
import SalesAndCostsTable from './Components/SalesAndCostsTable.vue';
import Banner from '../../../Layout/Banner.vue';
export default {
    components: {
        Banner,
        SalesAndCostsTable,
    },
    props: {
        eventIds: {
            type: Array,
            required: true,
        },
    },
};
</script>
