<template>
    <div>
        <Modal ref="modal" title="Raise Purchase Invoice" size="is-xxlarge" :loading="isLoading" @closed="close()">
            <template v-if="step == 1">
                <p>
                    Search for an approved purchase order in order to raise a purchase invoice.
                    <br />
                    You can also raise a single invoice across multiple purchase orders providing that the suppliers are
                    the same.
                </p>
                <template v-if="selectedPurchaseOrders.length">
                    <div class="purchase-order-selection">
                        <div v-for="(result, index) in selectedPurchaseOrders" :key="result.id" class="purchase-order">
                            <div class="po-number">{{ result.reference }}</div>
                            <div class="po-title">
                                {{ result.title }}
                                <small class="supplier">
                                    {{ result.supplier ? result.supplier.name : 'No Supplier' }}
                                </small>
                            </div>
                            <FormField class="tw-text-right tw-pr-4" label="Total">
                                {{ localise(result.total, 2, true) }}
                            </FormField>
                            <FormField class="tw-text-right tw-pr-4" label="Invoiced">
                                {{ localise(result.total_invoiced, 2, true) }}
                            </FormField>
                            <FormField class="tw-text-right" label="Paid">
                                {{ localise(result.total_paid, 2, true) }}
                            </FormField>
                            <a href="#" class="text-danger ml-3" @click.prevent="removeSelection(index)">
                                <i class="mdi mdi-close"></i>
                            </a>
                        </div>
                    </div>
                </template>
                <div class="purchase-order-search mt-3">
                    <Textbox
                        v-model="searchQuery"
                        placeholder="Search for an approved purchase order by reference, title, or supplier"
                    />
                    <div v-if="searchResults && searchResults.length" class="purchase-order-search-results">
                        <a
                            v-for="result in searchResults"
                            :key="result.id"
                            href="#"
                            @click.prevent="selectPurchaseOrder(result)"
                        >
                            <div class="po-number">{{ result.reference }}</div>
                            <div class="po-title">
                                {{ result.title }}
                                <small class="supplier">
                                    {{ result.supplier ? result.supplier.name : 'No Supplier' }}
                                </small>
                            </div>
                            <FormField class="tw-text-right tw-pr-4" label="Total">
                                {{ localise(result.total, 2, true) }}
                            </FormField>
                            <FormField class="tw-text-right tw-pr-4" label="Invoiced">
                                {{ localise(result.total_invoiced, 2, true) }}
                            </FormField>
                            <FormField class="tw-text-right" label="Paid">
                                {{ localise(result.total_paid, 2, true) }}
                            </FormField>
                        </a>
                    </div>
                    <div v-else-if="searchResults" class="empty-label mt-2">
                        There are no approved purchase orders that match your query
                    </div>
                </div>
            </template>
            <template v-else-if="step == 2">
                <FormField
                    v-if="selectedPurchaseOrders.length > 1"
                    class="tw-flex-1"
                    label="Invoice Title (only requested for Multi PO invoices)"
                >
                    <Textbox v-model="formData.title" class="tw-w-full" />
                </FormField>
                <div class="tw-flex">
                    <FormField class="tw-flex-1 tw-pr-1" label="Invoice Number">
                        <Textbox v-model="formData.invoice_number" class="tw-w-full" />
                    </FormField>
                    <FormField class="tw-flex-1 tw-px-1" label="Invoice Date">
                        <flat-pickr
                            v-model="formData.invoice_date"
                            placeholder="Required"
                            :config="flatpickrConfig"
                            class="input is-small"
                            required
                        ></flat-pickr>
                    </FormField>
                    <FormField class="tw-flex-1 tw-pl-1" label="Due Date">
                        <flat-pickr
                            v-model="formData.due_date"
                            placeholder="Required"
                            :config="flatpickrConfig"
                            class="input is-small"
                            required
                        ></flat-pickr>
                    </FormField>
                </div>
                <LineItemBuilder
                    v-model="formData.items"
                    is-invoice
                    is-purchase
                    hide-totals
                    :hidden-columns="['is_invoiced', 'actions']"
                    @unit-price-change="handleAmountChange"
                    @quantity-change="handleAmountChange"
                >
                    <template #col-prepend-header>
                        <th>#</th>
                        <th>PO</th>
                    </template>
                    <template #col-prepend="{ row, index }">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td>
                            {{ row.po_reference }}
                        </td>
                    </template>
                    <template #col-append-header>
                        <th>Remaining (Gross)</th>
                        <th>Amount (Gross)</th>
                        <th></th>
                    </template>
                    <template #col-append="{ row }">
                        <td>
                            {{ localise(row.max_value, 2, true) }}
                        </td>
                        <td>
                            <EditableText
                                v-model="row.value"
                                type="currency"
                                :min-value="0"
                                :max-value="parseFloat(row.max_value)"
                            />
                        </td>
                        <td>
                            <Button type="is-danger" @click="formData.items.splice(index, 1)">
                                <i class="mdi mdi-close"></i>
                            </Button>
                        </td>
                    </template>
                </LineItemBuilder>
                <div class="row mt-2">
                    <div class="col-4">
                        <FormField label="Source Currency">
                            {{ availableCurrencies[baseCurrency] }}
                        </FormField>
                    </div>
                    <div
                        :class="{
                            'col-4': formData.target_currency && formData.target_currency != baseCurrency,
                            'col-8': !formData.target_currency || formData.target_currency == baseCurrency,
                        }"
                    >
                        <FormField label="Target Currency" custom-class="mb-1">
                            <Select
                                v-model="formData.target_currency"
                                size="is-small"
                                expanded
                                @update:model-value="getDefaultConversionRate()"
                            >
                                <option
                                    v-for="(currency, currencyCode) in availableCurrencies"
                                    :key="currencyCode"
                                    :value="currencyCode"
                                >
                                    {{ currency }}
                                </option>
                            </Select>
                        </FormField>
                    </div>
                    <div v-if="formData.target_currency && formData.target_currency != baseCurrency" class="col-4">
                        <FormField v-if="formData.target_currency" label="Conversion Rate" custom-class="mb-1">
                            <NumberInput v-model="formData.conversion_rate" min="0" />
                            <Button @click="openCurrencyConverter()">
                                <i class="mdi mdi-calculator"></i>
                            </Button>
                        </FormField>
                    </div>
                </div>
                <FormField class="tw-mt-4">
                    <Upload v-model="formData.files" text="Upload documents" :accept="['application/pdf']" multiple />
                    <div v-if="formData.files.length" class="tags mt-2">
                        <span v-for="(file, index) in formData.files" :key="index" class="tag is-primary">
                            {{ file.name }}
                            <button
                                class="delete is-small"
                                type="button"
                                @click.prevent="removeAttachment(index)"
                            ></button>
                        </span>
                    </div>
                </FormField>
            </template>
            <template #footer>
                <Button @click="step == 1 ? close() : (step = 1)">{{ step == 1 ? 'Cancel' : 'Back' }}</Button>
                <FormField v-if="step == 2 && selectedPurchaseOrders[0].supplier" class="tw-pl-4" label="Supplier">
                    {{ selectedPurchaseOrders[0].supplier.name }}
                </FormField>
                <div class="flex-fill"></div>
                <FormField v-if="step == 2" class="tw-text-right tw-pr-5" label="Invoice Value">
                    {{ localise(totalValue, 2, true) }}
                </FormField>
                <Button
                    type="is-success"
                    :disabled="!selectedPurchaseOrders.length"
                    @click="step == 1 ? (step = step + 1) : raiseInvoice()"
                >
                    {{ step == 1 ? 'Continue' : 'Raise Invoice' }}
                </Button>
            </template>
        </Modal>
        <ModalInvoicePaymentDates ref="paymentDatesModal" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import Modal from '../../Modal.vue';
import LineItemBuilder from '../../widgets/LineItemBuilder.vue';
import EditableText from '../../controls/EditableText.vue';
import ModalInvoicePaymentDates from './InvoicePaymentDates.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import Select from '@/js/components/controls/Select.vue';
import Upload from '@/js/components/controls/Upload.vue';
import { obj2form } from '@/js/utils.js';
import { toast } from '@/js/utils.js';

export default {
    components: {
        ModalInvoicePaymentDates,
        Modal,
        LineItemBuilder,
        EditableText,
        Button,
        FormField,
        Textbox,
        NumberInput,
        Select,
        Upload,
    },
    emits: ['created', 'reload'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isLoadingConversionRate: false,
            searchQuery: null,
            searchResults: null,
            selectedPurchaseOrders: [],
            step: 1,
            availableCurrencies: store.get('available_currencies'),
            baseCurrency: store.get('current_event.currency_code'),
            taxRates: store.get('account.tax_rates'),
            formData: {
                title: null,
                supplier_id: null,
                invoice_date: new Date().toISOString().split('T')[0],
                due_date: new Date().toISOString().split('T')[0],
                target_currency: JSON.parse(JSON.stringify(store.get('current_event.currency_code'))),
                conversion_rate: 1,
                items: [],
                files: [],
            },
            store,
        };
    },
    computed: {
        totalValue() {
            if (!this.formData.items) {
                return 0;
            }
            return this.formData.items.map((item) => item.value).reduce((a, b) => a + b);
        },
    },
    watch: {
        searchQuery(newVal) {
            if (!newVal) {
                this.searchResults = null;
                return;
            }
            this.debouncedSearch();
        },
        step(newVal) {
            if (newVal !== 2) {
                return;
            }
            this.formData.supplier_id = this.selectedPurchaseOrders[0].supplier_id;
            this.formData.items = this.selectedPurchaseOrders
                .map((po) => {
                    return po.line_items.map((li) => {
                        return {
                            purchase_order_line_item_id: li.id,
                            description: li.description,
                            quantity: li.quantity,
                            unit_price: parseFloat(li.unit_price ?? 0).toFixed(2),
                            tax_rate_id: li.tax_rate_id ?? null,
                            value:
                                li.total - li.total_invoiced_gross < 0
                                    ? 0
                                    : (li.total - li.total_invoiced_gross).toFixed(2),
                            max_value: (li.total - li.total_invoiced_gross).toFixed(2),
                            po_reference: po.reference,
                        };
                    });
                })
                .flat();
        },
    },
    mounted() {
        Eventbus.$on('create:supplierInvoice', () => this.open());
    },
    methods: {
        open() {
            // Because this method is enabled by mounted() it calls $refs before it has
            // been initialised, creating an 'undefined' console error.
            this.$refs?.modal.open();
        },

        reset() {
            this.isLoading = false;
            this.isLoadingConversionRate = false;
            this.searchQuery = null;
            this.searchResults = null;
            this.step = 1;
            this.selectedPurchaseOrders = [];
            this.formData = {
                title: null,
                supplier_id: null,
                invoice_date: new Date().toISOString().split('T')[0],
                due_date: new Date().toISOString().split('T')[0],
                target_currency: JSON.parse(JSON.stringify(this.baseCurrency)),
                conversion_rate: 1,
                items: [],
                files: [],
            };
            this.getDefaultConversionRate();
        },
        close() {
            this.reset();
            this.$refs.modal.close();
        },
        search() {
            this.isLoading = true;
            const url = new URL(
                route('api.account.event.purchaseorders', {
                    paginated: 1,
                })
            );
            url.searchParams.set('perPage', 5);
            url.searchParams.set('query', this.searchQuery);
            // todo :: could probs make this cleaner
            url.searchParams.set('filter', 'status=Approved,Sent,Complete');
            if (this.selectedPurchaseOrders.length) {
                const supplierId = this.selectedPurchaseOrders[0].supplier_id;
                // todo :: could probs make this cleaner
                url.searchParams.set('filter', 'status=Approved,Sent,Complete;suppliers.id=' + supplierId);
                url.searchParams.set(
                    'exclude',
                    'purchase_orders.id=' + this.selectedPurchaseOrders.map((p) => p.id).join(',')
                );
            }
            axios
                .get(url.href)
                .then((response) => {
                    this.searchResults = response.data.data;
                })
                .finally(() => (this.isLoading = false));
        },
        debouncedSearch: debounce(function () {
            this.search();
        }, 500),
        selectPurchaseOrder(purchaseOrder) {
            this.selectedPurchaseOrders.push(purchaseOrder);
            if (this.selectedPurchaseOrders.length === 1) {
                this.formData.title = purchaseOrder.title;
            } else if (this.selectedPurchaseOrders.length > 1) {
                this.formData.title = '';
            }
            if (this.searchResults && this.searchResults.length === 1) {
                this.searchQuery = null;
                this.searchResults = null;
            } else {
                this.search();
            }
        },
        removeSelection(index) {
            this.selectedPurchaseOrders.splice(index, 1);
            if (this.searchQuery) {
                this.search();
            }
        },
        removeAttachment(index) {
            this.formData.files.splice(index, 1);
        },
        raiseInvoice() {
            this.isLoading = true;
            axios
                .post(route('api.account.event.invoices.create'), obj2form(this.formData))
                .then((response) => {
                    this.isLoading = false;
                    toast('Invoice Created', 'Your invoice has been created successfully', 'success');
                    this.openInvoicePaymentDates(response.data.id);
                    this.$emit('created', response.data.id);
                    this.close();
                    this.$emit('reload');
                })
                .finally(() => (this.isLoading = false));
        },
        openInvoicePaymentDates(supplierInvoiceId) {
            //use the fist po id
            const purchaseOrderId = this.selectedPurchaseOrders.map((po) => po.id)[0];
            this.$refs.paymentDatesModal.openModal({
                invoice: supplierInvoiceId,
                purchaseOrder: purchaseOrderId,
            });
        },
        getDefaultConversionRate() {
            this.isLoadingConversionRate = true;
            axios
                .get(
                    route('api.currency.convert', {
                        inputtext: '1',
                        convert_from: this.baseCurrency,
                        convert_to: this.formData.target_currency,
                    })
                )
                .then(({ data }) => (this.formData.conversion_rate = data.rate))
                .finally(() => (this.isLoadingConversionRate = false));
        },
        handleAmountChange(row, index) {
            if (!row.unit_price || !row.quantity) {
                return;
            }
            this.formData.items[index].value = parseFloat(row.unit_price) * parseFloat(row.quantity);
        },
    },
};
</script>
<style lang="scss">
.purchase-order-search-results,
.purchase-order-selection {
    display: flex;
    flex-direction: column;

    & > a,
    & > div {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border: 1px solid rgba(black, 0.1);
        border-radius: 4px;
        margin-top: 5px;
        color: rgba(black, 0.75);
        transform: scale(1);
        background: white;
        transition: all 0.1s ease;

        &:first-child {
            margin-top: 10px;
        }

        & > .po-number {
            width: 70px;
        }
        & > .po-title {
            display: flex;
            flex-direction: column;
            flex: 1;

            & > .supplier {
                opacity: 0.75;
            }
        }
    }
    & > a {
        &:hover {
            transform: scale(1.02);
            background: rgba(black, 0.05);
            color: black;
        }
    }
}
</style>
