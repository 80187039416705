<template>
    <label
        :for="id"
        class="tw-mb-0 tw-text-xs tw-text-gray-600 tw-select-none tw-cursor-pointer tw-font-work-sans tw-flex tw-relative tw-items-center tw-gap-[6px] tw-mb-1"
        :class="{
            'tw-cursor-default': disabled,
        }"
    >
        <input :id ref="input" v-model="model" type="checkbox" :disabled />
        <slot></slot>
    </label>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
const { indeterminate } = defineProps({
    id: {
        type: String,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    indeterminate: {
        type: Boolean,
        default: false,
    },
});
const model = defineModel({
    type: [Boolean],
    default: false,
});
const input = ref(null);

onMounted(() => (input.value.indeterminate = indeterminate));
watch(
    () => indeterminate,
    (value) => {
        input.value.indeterminate = value;
    }
);
</script>

<style scoped>
[type='checkbox'] {
    @apply tw-bg-white tw-border tw-rounded tw-shadow-sm tw-text-eventwise-500 tw-size-4 tw-appearance-none;
    &:checked {
        @apply tw-bg-eventwise-400 tw-border-eventwise;
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }
    &:not(disabled) {
        @apply tw-cursor-pointer;
    }
    &:disabled {
        @apply tw-bg-gray-200 tw-border-gray-200 tw-text-gray-400 tw-cursor-not-allowed;
    }
    &:indeterminate {
        background-image: url('@/images/dash.svg');
    }
}
</style>
