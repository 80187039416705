<template>
    <div>
        <div class="tw-flex tw-gap-3 mb-1">
            <div v-if="internal" class="tw-flex tw-items-center">
                <h3>{{ activeSupplier.name }}{{ activeSupplier.is_archived ? ' (Archived)' : '' }}</h3>
                <Button v-if="internal" class="tw-flex tw-gap-2 tw-ml-3" @click="detailsReturn()">
                    <i class="mdi mdi-reply"></i>
                    Return to Suppliers
                </Button>
            </div>

            <div class="tw-flex tw-items-center tw-gap-3">
                <Select v-model="yearFilter">
                    <option value="last12">Showing: Last 12 months</option>
                    <option value="thisYear">Showing: This year ({{ DateTime.now().year }})</option>
                    <option value="lastYear">Showing: Last year ({{ DateTime.now().minus({ year: 1 }).year }})</option>
                    <option value="allTime">Showing: All time</option>
                    <option value="custom">Showing: Custom range</option>
                </Select>
                <template v-if="yearFilter === 'custom'">
                    <div class="d-flex flex-fill">
                        <flat-pickr
                            v-model="customRangeStart"
                            placeholder="Start Date"
                            :config="flatpickrConfig"
                            class="input control is-small is-clearfix"
                        />
                        <Button :disabled="!customRangeStart" @click="customRangeStart = null">
                            <i class="mdi mdi-close"></i>
                        </Button>
                    </div>
                    <div class="d-flex flex-fill">
                        <flat-pickr
                            v-model="customRangeEnd"
                            placeholder="End Date"
                            :config="flatpickrConfig"
                            class="input control is-small is-clearfix"
                        />
                        <Button :disabled="!customRangeEnd" @click="customRangeEnd = null">
                            <i class="mdi mdi-close"></i>
                        </Button>
                    </div>
                </template>
            </div>

            <div class="tw-flex tw-items-center tw-ml-auto tw-gap-3">
                <Button v-if="internal" class="tw-flex tw-gap-2" @click="viewSupplier(activeSupplier.id)">
                    <i class="mdi mdi-pencil"></i>
                    Edit Supplier
                </Button>
                <Button
                    v-if="
                        internal &&
                        Auth.can('manage suppliers') &&
                        !activeSupplier.is_system &&
                        !activeSupplier.is_archived
                    "
                    class="tw-flex tw-gap-2"
                    @click="inviteSupplier()"
                >
                    <i class="mdi mdi-email"></i>
                    Portal Invite
                </Button>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-4">
                <ew-card title="Purchase Orders" class="flex-fill" paddingless>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">
                            Orders Placed
                            <span class="text-muted">(net)</span>
                        </div>
                        <div class="col-5 text-right">{{ localise(totalApprovedPurchaseOrdersNet, 2, true) }}</div>
                    </div>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">
                            Orders Invoiced
                            <span class="text-muted">(net)</span>
                        </div>
                        <div class="col-5 text-right">{{ localise(totalInvoicedPurchaseOrdersNet, 2, true) }}</div>
                    </div>
                    <div class="row py-2 px-4">
                        <div class="col-7">
                            Orders in Progress
                            <span class="text-muted">(net)</span>
                        </div>
                        <div class="col-5 text-right">{{ localise(totalPendingPurchaseOrdersNet, 2, true) }}</div>
                    </div>
                </ew-card>
            </div>
            <div class="col-12 col-lg-4">
                <ew-card title="Purchase Invoices" class="flex-fill" paddingless>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Orders Placed</div>
                        <div class="col-5 text-right">
                            {{ localise(totalApprovedPurchaseOrdersGross, 2, true) }}
                        </div>
                    </div>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Orders Invoiced</div>
                        <div class="col-5 text-right">
                            {{ localise(totalInvoicedPurchaseOrdersGross, 2, true) }}
                        </div>
                    </div>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Pending Invoices</div>
                        <div class="col-5 text-right">
                            {{ localise(totalApprovedPurchaseOrdersGross - totalInvoicedPurchaseOrdersGross, 2, true) }}
                        </div>
                    </div>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Payments Made</div>
                        <div class="col-5 text-right">{{ localise(totalPurchasePayments, 2, true) }}</div>
                    </div>
                    <div class="row py-2 px-4">
                        <div class="col-7">Payments Outstanding</div>
                        <div class="col-5 text-right">{{ localise(totalPendingPurchasePayments, 2, true) }}</div>
                    </div>
                </ew-card>
            </div>
            <div class="col-12 col-lg-4">
                <ew-card title="Sales Invoices" class="flex-fill" paddingless>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Payments Expected</div>
                        <div class="col-5 text-right">{{ localise(totalPaymentsExpectedGross, 2, true) }}</div>
                    </div>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Payments Received</div>
                        <div class="col-5 text-right">{{ localise(totalPaymentsReceived, 2, true) }}</div>
                    </div>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Invoices Outstanding</div>
                        <div class="col-5 text-right">{{ localise(totalSalesPaymentsExpected, 2, true) }}</div>
                    </div>
                    <div class="row py-2 px-4 border-bottom">
                        <div class="col-7">Orders Invoiced</div>
                        <div class="col-5 text-right">{{ localise(totalInvoicedRevenueEntries, 2, true) }}</div>
                    </div>
                    <div class="row py-2 px-4">
                        <div class="col-7">Orders Not Invoiced</div>
                        <div class="col-5 text-right">
                            {{ localise(totalPaymentsExpectedGross - totalInvoicedRevenueEntries, 2, true) }}
                        </div>
                    </div>
                </ew-card>
            </div>
        </div>

        <ew-card title="History" paddingless>
            <template #actions>
                <Select v-model="chartView">
                    <option value="yearly">Viewing: Yearly</option>
                    <option value="financial">Viewing: Financial</option>
                    <option value="fiscal">Viewing: Fiscal</option>
                </Select>
            </template>
            <div class="px-5 pt-3">
                <Chart :options="historyChartOptions" :data="historyChartData" :min-height="275" :max-height="275" />
            </div>
        </ew-card>
        <ew-card title="Entries" :collapsible="false" paddingless>
            <div class="inline-table-search-container ml-5" :class="{ open: searchOpen }">
                <button @click="searchOpen = !searchOpen">
                    <i
                        :class="{
                            'mdi mdi-magnify': !searchOpen,
                            'mdi mdi-close': searchOpen,
                        }"
                    ></i>
                </button>
                <input
                    v-model="searchQuery"
                    type="text"
                    placeholder="Type to search..."
                    @keyup.esc="searchOpen = false"
                />
            </div>
            <Tabs
                :tabs="[
                    {
                        title: 'Purchase Orders',
                        name: 'purchaseOrders',
                    },
                    {
                        title: 'Purchase Invoices',
                        name: 'purchaseInvoices',
                    },
                    {
                        title: 'Sales Invoices',
                        name: 'salesInvoices',
                    },
                ]"
            >
                <template #tab(purchaseOrders)>
                    <DataTable
                        v-if="purchaseOrders"
                        :data="purchaseOrders"
                        :columns="[
                            {
                                title: 'Reference',
                                field: 'reference',
                                sortable: true,
                            },
                            {
                                title: 'Event',
                                field: 'event_name',
                                sortable: true,
                            },
                            {
                                title: 'Department',
                                field: 'department_name',
                                sortable: true,
                            },
                            {
                                title: 'Category',
                                field: 'category_name',
                                sortable: true,
                            },
                            {
                                title: 'Title',
                                field: 'title',
                                sortable: true,
                            },
                            {
                                title: 'Net Total',
                                field: 'subtotal',
                                sortable: true,
                            },
                            {
                                title: 'Gross Total',
                                field: 'total',
                                sortable: true,
                            },
                            {
                                title: 'Invoiced (gross)',
                                field: 'total_invoiced_gross',
                                sortable: true,
                            },
                            {
                                title: 'Paid',
                                field: 'paid',
                                sortable: true,
                            },
                            {
                                title: 'Outstanding',
                                field: 'outstanding',
                                sortable: true,
                            },
                        ]"
                        @select="viewPurchaseOrder"
                    >
                        <template #column(reference)="{ row }">
                            <span v-if="row.reference">{{ row.reference }}</span>
                            <span v-else class="text-muted">Not set</span>
                        </template>
                        <template #column(event_name)="{ row }">
                            {{ row.event.name }}
                        </template>
                        <template #column(department_name)="{ row }">
                            <a
                                v-if="!isSupplierUser && row.category"
                                :href="
                                    route('account.event.purchaseorders.summary', {
                                        event: row.event_id,
                                        page: 'summary',
                                        departmentId: row.category.department_id,
                                    })
                                "
                                target="_blank"
                            >
                                {{ row.category.department.name }}
                            </a>
                            <span v-else-if="row.category">
                                {{ row.category.department.name }}
                            </span>
                            <span v-else class="text-muted">&mdash;</span>
                        </template>
                        <template #column(category_name)="{ row }">
                            <a
                                v-if="!isSupplierUser && row.category"
                                :href="
                                    route('account.event.purchaseorders.summary', {
                                        event: row.event_id,
                                        page: 'summary',
                                        departmentId: row.category.department_id,
                                    })
                                "
                                target="_blank"
                            >
                                {{ row.category.name }}
                            </a>
                            <span v-else-if="row.category">{{ row.category.name }}</span>
                            <span v-else class="text-muted">&mdash;</span>
                        </template>
                        <template #column(subtotal)="{ row }">
                            {{ localise(row.subtotal, 2, true) }}
                        </template>
                        <template #column(total)="{ row }">
                            {{ localise(row.total, 2, true) }}
                        </template>
                        <template #column(total_invoiced_gross)="{ row }">
                            >
                            {{ localise(row.total_invoiced_gross, 2, true) }}
                        </template>
                        <template #column(paid)="{ row }">
                            {{ localise(row.total_paid, 2, true) }}
                        </template>
                        <template #column(outstanding)="{ row }">
                            {{ localise(row.total_invoiced_gross - row.total_credit - row.total_paid, 2, true) }}
                        </template>
                        <template #empty>
                            <div class="text-center text-muted">There are no purchase orders to display here</div>
                        </template>
                    </DataTable>
                </template>
                <template #tab(purchaseInvoices)>
                    <DataTable
                        :data="purchaseInvoices"
                        :columns="[
                            {
                                title: 'Invoice #',
                                field: 'invoice_number',
                                sortable: true,
                            },
                            {
                                title: 'Event',
                                field: 'event_name',
                                sortable: true,
                            },
                            {
                                title: 'Title',
                                field: 'title',
                                sortable: true,
                            },
                            {
                                title: 'Date',
                                field: 'invoice_date',
                                sortable: true,
                            },
                            {
                                title: 'Due Date',
                                field: 'due_date',
                                sortable: true,
                            },
                            {
                                title: 'Subtotal',
                                field: 'subtotal',
                                sortable: true,
                            },
                            {
                                title: 'Total',
                                field: 'total_value',
                                sortable: true,
                            },
                            {
                                title: 'Paid',
                                field: 'total_paid',
                                sortable: true,
                            },
                            {
                                title: 'Credit',
                                field: 'total_credit',
                                sortable: true,
                            },
                            {
                                title: 'Outstanding',
                                field: 'outstanding',
                            },
                        ]"
                        @click="viewInvoice"
                    >
                        <template #column(event_name)="{ row }">
                            {{ row.event.name }}
                        </template>
                        <template #column(invoice_date)="{ row }">
                            {{ DateTime.fromISO(row.invoice_date).toLocaleString(DateTime.DATE_MED) }}
                        </template>
                        <template #column(due_date)="{ row }">
                            {{ DateTime.fromISO(row.due_date).toLocaleString(DateTime.DATE_MED) }}
                        </template>
                        <template #column(subtotal)="{ row }">
                            {{ localise(row.subtotal, 2, true) }}
                        </template>
                        <template #column(total_value)="{ row }">
                            {{ localise(row.total_value, 2, true) }}
                        </template>
                        <template #column(total_paid)="{ row }">
                            {{ localise(row.total_paid, 2, true) }}
                        </template>
                        <template #column(total_credit)="{ row }">
                            {{ localise(row.total_credit, 2, true) }}
                        </template>
                        <template #column(outstanding)="{ row }">
                            {{ localise(row.total_value - row.total_credit - row.total_paid, 2, true) }}
                        </template>
                        <template #empty>
                            <div class="text-center text-muted">There are no purchase invoices to display here</div>
                        </template>
                    </DataTable>
                </template>
                <template #tab(salesInvoices)>
                    <DataTable
                        :data="salesInvoices"
                        :columns="[
                            {
                                title: 'Invoice #',
                                field: 'invoice_number',
                                sortable: true,
                            },
                            {
                                title: 'Event',
                                field: 'event_name',
                                sortable: true,
                            },
                            {
                                title: 'Title',
                                field: 'title',
                                sortable: true,
                            },
                            {
                                title: 'Date',
                                field: 'invoice_date',
                                sortable: true,
                            },
                            {
                                title: 'Due Date',
                                field: 'due_date',
                                sortable: true,
                            },
                            {
                                title: 'Subtotal',
                                field: 'subtotal',
                                sortable: true,
                            },
                            {
                                title: 'Total',
                                field: 'total_value',
                                sortable: true,
                            },
                            {
                                title: 'Paid',
                                field: 'total_paid',
                                sortable: true,
                            },
                            {
                                title: 'Credit',
                                field: 'total_credit',
                                sortable: true,
                            },
                            {
                                title: 'Outstanding',
                                field: 'outstanding',
                            },
                        ]"
                        @select="viewInvoice"
                    >
                        <template #column(event_name)="{ row }">
                            {{ row.event.name }}
                        </template>
                        <template #column(invoice_date)="{ row }">
                            {{ DateTime.fromISO(row.invoice_date).toLocaleString(DateTime.DATE_MED) }}
                        </template>
                        <template #column(due_date)="{ row }">
                            {{ DateTime.fromISO(row.due_date).toLocaleString(DateTime.DATE_MED) }}
                        </template>
                        <template #column(subtotal)="{ row }">
                            {{ localise(row.subtotal, 2, true) }}
                        </template>
                        <template #column(total_value)="{ row }">
                            {{ localise(row.total_value, 2, true) }}
                        </template>
                        <template #column(total_paid)="{ row }">
                            {{ localise(row.total_paid, 2, true) }}
                        </template>
                        <template #column(total_credit)="{ row }">
                            {{ localise(row.total_credit, 2, true) }}
                        </template>
                        <template #column(outstanding)="{ row }">
                            {{ localise(row.total_value - row.total_credit - row.total_paid, 2, true) }}
                        </template>
                    </DataTable>
                </template>
            </Tabs>
        </ew-card>
        <br />

        <ModalSupplierInvite v-if="internal" :supplier-object="activeSupplier" />
        <ReadOnlyPurchaseOrder ref="readOnlyPurchaseOrderModal" @refresh="reloadData()" />
        <ContactPortalInvite />
    </div>
</template>

<script>
import ModalSupplierInvite from '../../Portal/InviteSupplier.vue';
import Chart from '../../../Chart.vue';
import ReadOnlyPurchaseOrder from './SupplierDetails/ReadOnlyPurchaseOrder.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import ContactPortalInvite from '../../../modals/Suppliers/ContactPortalInvite.vue';
import Button from '@/js/components/controls/Button.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import Select from '@/js/components/controls/Select.vue';
import { localise, toast } from '@/js/utils.js';
import { DateTime } from 'luxon';
import DataTable from '@/js/components/tables/DataTable.vue';

export default {
    components: {
        ModalSupplierInvite,
        Chart,
        ReadOnlyPurchaseOrder,
        ContactPortalInvite,
        Button,
        Tabs,
        Select,
        DataTable,
    },
    props: {
        supplierId: {
            type: Number,
            required: true,
        },
        accountId: {
            type: Number,
            default: null,
        },
        internal: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        const store = useDataStore();

        return {
            currentAccount: store.get('account'),
            activeSupplier: store.get(`account.suppliers.${this.supplierId}`),
            yearFilter: 'last12',
            chartView: 'yearly',
            customRangeStart: null,
            customRangeEnd: null,
            purchaseOrders: [],
            purchaseInvoices: [],
            salesInvoices: [],
            revenueEntries: [],
            data: null,
            searchQuery: null,
            searchOpen: false,
            DateTime,
        };
    },
    computed: {
        totalApprovedPurchaseOrdersNet() {
            if (!this.purchaseOrders || this.purchaseOrders.length === 0) {
                return 0;
            }
            return this.purchaseOrders
                .map((po) => (['Approved', 'Sent', 'Complete'].includes(po.status) ? po.subtotal : 0))
                .reduce((s, a) => s + a, 0);
        },
        totalApprovedPurchaseOrdersGross() {
            if (!this.purchaseOrders || this.purchaseOrders.length === 0) {
                return 0;
            }
            return this.purchaseOrders
                .map((po) => (['Approved', 'Sent', 'Complete'].includes(po.status) ? po.total : 0))
                .reduce((s, a) => s + a, 0);
        },
        totalInvoicedPurchaseOrdersNet() {
            if (!this.purchaseOrders || this.purchaseOrders.length === 0) {
                return 0;
            }
            return this.purchaseOrders
                .map((po) => (['Approved', 'Sent', 'Complete'].includes(po.status) ? po.total_invoiced : 0))
                .reduce((s, a) => s + a, 0);
        },
        totalInvoicedPurchaseOrdersGross() {
            if (!this.purchaseOrders || this.purchaseOrders.length === 0) {
                return 0;
            }
            return this.purchaseOrders
                .map((po) => (['Approved', 'Sent', 'Complete'].includes(po.status) ? po.total_invoiced_gross : 0))
                .reduce((s, a) => s + a, 0);
        },
        totalPendingPurchaseOrdersNet() {
            if (!this.purchaseOrders || this.purchaseOrders.length === 0) {
                return 0;
            }
            return this.purchaseOrders
                .map((po) => (['Draft', 'Awaiting Approval'].includes(po.status) ? po.subtotal : 0))
                .reduce((s, a) => s + a, 0);
        },
        totalPurchasePayments() {
            if (!this.purchaseOrders || this.purchaseOrders.length === 0) {
                return 0;
            }
            return this.purchaseOrders
                .map((po) => (['Approved', 'Sent', 'Complete'].includes(po.status) ? po.total_paid : 0))
                .reduce((s, a) => s + a, 0);
        },
        totalPendingPurchasePayments() {
            if (!this.purchaseOrders || this.purchaseOrders.length === 0) {
                return 0;
            }
            return this.purchaseOrders
                .map((po) => (['Approved', 'Sent', 'Complete'].includes(po.status) ? po.total_pending : 0))
                .reduce((s, a) => s + a, 0);
        },
        totalPaymentsExpectedGross() {
            if (!this.revenueEntries || this.revenueEntries.length === 0) {
                return 0;
            }
            return this.revenueEntries.map((re) => re.total).reduce((s, a) => s + a, 0);
        },
        totalPaymentsReceived() {
            if (!this.revenueEntries || this.revenueEntries.length === 0) {
                return 0;
            }
            return this.revenueEntries.map((re) => re.total_paid).reduce((s, a) => s + a, 0);
        },
        totalInvoicedRevenueEntries() {
            if (!this.revenueEntries || this.revenueEntries.length === 0) {
                return 0;
            }
            return this.revenueEntries.map((re) => re.total_invoiced_gross).reduce((s, a) => s + a, 0);
        },
        totalSalesPaymentsExpected() {
            if (!this.salesInvoices || this.salesInvoices.length === 0) {
                return 0;
            }
            return this.salesInvoices
                .map((si) => si.total_value - si.total_paid - si.total_credit)
                .reduce((s, a) => s + a, 0);
        },
        historyChartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: false,
                    },
                    y: {
                        stacked: false,
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    tooltip: {
                        callbacks: {
                            label(context) {
                                return ' ' + context.dataset.label + ': ' + localise(context.parsed.y, 2, true);
                            },
                        },
                    },
                },
            };
        },
        historyChartLabels() {
            const labels = [];
            if (this.chartView === 'yearly') {
                for (let i = 0; i < 12; i += 1) {
                    let month = DateTime.utc().endOf('year').minus({ months: i });
                    labels.push(month.toFormat('MMM yy'));
                }
            } else if (this.chartView === 'financial') {
                let currentMonth = DateTime.utc().month;
                let offset = currentMonth < 4 ? 3 - currentMonth : 15 - currentMonth;
                for (let i = 0; i < 12; i += 1) {
                    let month = DateTime.utc()
                        .startOf('month')
                        .minus({ months: i + offset });
                    labels.push(month.toFormat('MMM yy'));
                }
            } else if (this.chartView === 'fiscal') {
                let currentMonth = DateTime.utc().month;
                let offset = currentMonth < 9 ? -2 - currentMonth : 10 - currentMonth;
                for (let i = 0; i < 12; i += 1) {
                    let month = DateTime.utc()
                        .startOf('month')
                        .minus({ months: i + offset });
                    labels.push(month.toFormat('MMM yy'));
                }
            }
            return labels.reverse();
        },
        historyChartData() {
            return {
                labels: this.historyChartLabels,
                datasets: [
                    {
                        label: 'Purchase Orders',
                        data: Object.fromEntries(
                            this.historyChartLabels.map((label) => {
                                let figure = this.purchaseOrders
                                    .filter((po) => {
                                        return ['Approved', 'Sent', 'Complete'].includes(po.status)
                                            ? DateTime.fromISO(po.created_at).toFormat('MMM yy') === label
                                            : false;
                                    })
                                    .map((po) => po.subtotal)
                                    .reduce((c, a) => c + a, 0);
                                return [label, figure];
                            })
                        ),
                    },
                    {
                        label: 'Purchase Invoices',
                        data: Object.fromEntries(
                            this.historyChartLabels.map((label) => {
                                let figure = this.purchaseOrders
                                    .filter((po) => {
                                        return ['Approved', 'Sent', 'Complete'].includes(po.status)
                                            ? DateTime.fromISO(po.created_at).toFormat('MMM yy') === label
                                            : false;
                                    })
                                    .map((po) => po.total_invoiced_gross - po.total_credit)
                                    .reduce((c, a) => c + a, 0);
                                return [label, figure];
                            })
                        ),
                        stack: 'purchaseInvoices',
                    },
                    {
                        label: 'Purchase Invoice Credit Notes',
                        data: Object.fromEntries(
                            this.historyChartLabels.map((label) => {
                                let figure = this.purchaseOrders
                                    .filter((po) => {
                                        return ['Approved', 'Sent', 'Complete'].includes(po.status)
                                            ? DateTime.fromISO(po.created_at).toFormat('MMM yy') === label
                                            : false;
                                    })
                                    .map((po) => po.total_credit)
                                    .reduce((c, a) => c + a, 0);
                                return [label, figure];
                            })
                        ),
                        stack: 'purchaseInvoices',
                    },
                    {
                        label: 'Sales Invoices',
                        data: Object.fromEntries(
                            this.historyChartLabels.map((label) => {
                                let figure = this.salesInvoices
                                    .filter((si) => {
                                        return DateTime.fromISO(si.created_at).toFormat('MMM yy') === label;
                                    })
                                    .map((si) => si.total_value - si.total_credit)
                                    .reduce((c, a) => c + a, 0);
                                return [label, figure];
                            })
                        ),
                        stack: 'salesInvoices',
                    },
                    {
                        label: 'Sales Invoice Credit Notes',
                        data: Object.fromEntries(
                            this.historyChartLabels.map((label) => {
                                let figure = this.salesInvoices
                                    .filter((si) => {
                                        return DateTime.fromISO(si.created_at).toFormat('MMM yy') === label;
                                    })
                                    .map((si) => si.total_credit)
                                    .reduce((c, a) => c + a, 0);
                                return [label, figure];
                            })
                        ),
                        stack: 'salesInvoices',
                    },
                ],
            };
        },
        isSupplierUser() {
            return this.currentAccount && this.currentAccount.is_supplier;
        },
    },
    watch: {
        yearFilter() {
            this.reloadData();
        },
        customRangeStart() {
            this.reloadData();
        },
        customRangeEnd() {
            this.reloadData();
        },
        searchQuery() {
            this.debouncedReloadData();
        },
        searchOpen(newVal) {
            if (!newVal) {
                this.searchQuery = '';
            }
        },
    },
    mounted() {
        this.reloadData();
    },
    methods: {
        reloadData() {
            this.getPurchaseOrders();
            this.getPurchaseInvoices();
            this.getSalesInvoices();
            this.getRevenueEntries();
        },
        debouncedReloadData: debounce(function () {
            this.reloadData();
        }, 500),
        getPurchaseOrders(newPage = null) {
            const params = {
                account: this.accountId,
                supplier: this.supplierId,
                filter: this.yearFilter,
            };
            const url = new URL(route('portal.purchaseOrders', params));

            if (this.searchQuery) {
                url.searchParams.append('query', this.searchQuery);
            }
            if (newPage) {
                url.searchParams.append('page', newPage);
            } else if (this.data && this.data.current_page) {
                url.searchParams.append('page', this.data.current_page);
            }
            if (this.yearFilter === 'custom') {
                params.start = this.customRangeStart ?? '';
                params.end = this.customRangeEnd ?? '';
            }
            axios.get(url.href, params).then(({ data }) => {
                this.data = data;
                this.purchaseOrders = data.data;
            });
        },
        getPurchaseInvoices(newPage = null) {
            const params = {
                account: this.accountId,
                supplier: this.supplierId,
                filter: this.yearFilter,
            };
            const url = new URL(route('portal.purchaseInvoices', params));
            if (this.searchQuery) {
                url.searchParams.append('query', this.searchQuery);
            }
            if (newPage) {
                url.searchParams.append('page', newPage);
            } else if (this.data && this.data.current_page) {
                url.searchParams.append('page', this.data.current_page);
            }
            axios.get(url.href, params).then(({ data }) => {
                this.data = data;
                this.purchaseInvoices = data.data.filter((d) => !!d);
            });
        },
        getSalesInvoices() {
            const params = {
                account: this.accountId,
                supplier: this.supplierId,
                filter: this.yearFilter,
            };
            const url = new URL(route('portal.salesInvoices', params));
            if (this.searchQuery) {
                url.searchParams.append('query', this.searchQuery);
            }
            if (this.data && this.data.current_page) {
                url.searchParams.append('page', this.data.current_page);
            }
            axios.get(url.href, params).then(({ data }) => {
                this.data = data;
                this.salesInvoices = data.data.filter((d) => !!d);
            });
        },
        getRevenueEntries() {
            axios
                .get(
                    route('portal.revenueEntries', {
                        account: this.accountId,
                        supplier: this.supplierId,
                        filter: this.yearFilter,
                    })
                )
                .then(({ data }) => (this.revenueEntries = data));
        },
        inviteSupplier() {
            Eventbus.$emit('contactPortalInvite:open', this.activeSupplier.id);
        },
        viewSupplier(id) {
            Eventbus.$emit('view:supplier', id);
        },
        detailsReturn() {
            this.$emit('update:modelValue');
        },
        viewInvoice(invoice) {
            !!invoice.revenue_entry_id ? this.viewCustomerInvoice(invoice) : this.viewSupplierInvoice(invoice);
        },
        viewPurchaseOrder(purchaseOrder) {
            if (!this.currentAccount.is_supplier) {
                this.$refs.readOnlyPurchaseOrderModal.open(purchaseOrder.event_id, purchaseOrder.id);
                return;
            }
            Eventbus.$emit('purchaseOrder:view', {
                event: purchaseOrder.event_id,
                purchaseOrder: purchaseOrder.id,
            });
        },
        viewSupplierInvoice(invoice) {
            if (!invoice || !invoice.purchase_orders.length) {
                toast('Error', 'The invoice has no linked purchase orders and cannot be opened', 'error');
            }
            Eventbus.$emit('viewInvoice', 'SupplierInvoice', {
                event: invoice.event_id,
                purchaseOrder: invoice.purchase_orders[0].id,
                supplierInvoice: invoice.id,
            });
        },
        viewCustomerInvoice(invoice) {
            Eventbus.$emit('viewInvoice', 'CustomerInvoice', {
                event: invoice.event_id,
                revenueEntry: invoice.revenue_entry_id,
                customerInvoice: invoice.id,
            });
        },
    },
};
</script>
