<template>
    <div>
        <Drawer ref="drawer" title="Ticket Management" size="is-xlarge" :loading="isLoading" @closing="resetData()">
            <template #header>
                <div class="ticket-header tw-gap-2">
                    <Selector
                        v-if="!selectedTicketType"
                        v-model="selectedTicketType"
                        class="flex-fill"
                        :options="ticketTypes"
                        :groups="ticketGroups"
                        group-by="category_id"
                        placeholder="Select a ticket type..."
                    ></Selector>
                    <template v-else-if="selectedTicketType && ticketType">
                        <div class="ticket-meta-block" :class="{ editable: isEditable }" @click="updateTicketName()">
                            <div>
                                <h6 class="tw-m-0">Ticket Name</h6>
                                {{ ticketType.name }}
                            </div>
                            <i v-if="isEditable" class="mdi mdi-pencil"></i>
                        </div>
                    </template>

                    <div
                        v-if="currentSubcategory"
                        class="ticket-meta-block"
                        :class="{ editable: isEditable }"
                        @click="updateSubcategory()"
                    >
                        <div>
                            <h6 class="tw-m-0">Subcategory</h6>
                            {{ currentSubcategory.name }}
                        </div>
                        <i v-if="isEditable" class="mdi mdi-pencil"></i>
                    </div>

                    <div
                        v-if="selectedTicketType && ticketType"
                        class="ticket-meta-block"
                        :class="{ editable: isEditable && !hasOrders }"
                        @click="hasOrders ? null : updateTicketPrice()"
                    >
                        <div>
                            <h6 class="tw-m-0">Ticket Price</h6>
                            {{ localise(ticketType.ticket_price, 2, true) }}
                        </div>
                        <i v-if="isEditable && !hasOrders" class="mdi mdi-pencil"></i>
                    </div>

                    <div v-if="Auth.account.scenarios_enabled" class="tw-flex tw-gap-1">
                        <Tooltip position="left" label="Tickets">
                            <Button :active="activeSection === 'tickets'" @click="activeSection = 'tickets'">
                                <i class="mdi mdi-ticket-confirmation-outline"></i>
                            </Button>
                        </Tooltip>
                        <Tooltip position="left" label="Scenarios">
                            <Button :active="activeSection === 'scenarios'" @click="activeSection = 'scenarios'">
                                <i class="mdi mdi-weather-pouring"></i>
                            </Button>
                        </Tooltip>
                    </div>

                    <Button
                        v-if="selectedTicketType && ticketType && Auth.can('delete tickets') && !eventIsClosed"
                        type="is-danger"
                        :disabled="isScenario"
                        @click="deleteTicket()"
                    >
                        <i class="mdi mdi-delete"></i>
                    </Button>
                </div>
            </template>

            <transition name="fade" mode="out-in">
                <div v-if="!isScenario">
                    <Banner v-if="!selectedTicketType || !ticketType" class="tw-m-2" type="is-light">
                        Please select a ticket type to view the ticket seller breakdown
                    </Banner>
                    <Tabs
                        v-else
                        :tabs="[
                            { title: 'Info', name: 'info' },
                            { title: 'Comments', name: 'comments' },
                        ]"
                        type="is-boxed"
                    >
                        <template #tab(info)>
                            <DataTable
                                :columns="[
                                    {
                                        field: 'name',
                                        title: 'Ticket Seller',
                                    },
                                    {
                                        field: 'reference',
                                        title: 'Reference',
                                    },
                                    {
                                        field: 'tickets_available',
                                        title: 'Allocated',
                                    },
                                    {
                                        field: 'tickets_sold',
                                        title: 'Sold',
                                    },
                                    {
                                        field: 'kickback_cost',
                                        title: 'Rebate',
                                    },
                                    {
                                        field: 'tax_rate_id',
                                        title: 'Tax Rate',
                                    },
                                    {
                                        field: 'net_sales',
                                        title: 'Net Sales',
                                    },
                                    {
                                        field: 'gross_sales',
                                        title: 'Gross Sales',
                                    },
                                    {
                                        field: 'actions',
                                        title: '',
                                    },
                                ]"
                                :data="ticketType.ticket_sales"
                            >
                                <template #column(name)="{ row, index }">
                                    <Selector
                                        v-if="Auth.can('manage tickets') && !row.id && !eventIsClosed"
                                        v-model="ticketType.ticket_sales[index].supplier_id"
                                        input-class="input is-small"
                                        :options="availableTicketSellers"
                                        :all-options="ticketSellers"
                                        @update:model-value="updateSupplier(index)"
                                    />
                                    <template v-else>
                                        {{ row.supplier ? row.supplier.name : 'Direct Sales' }}
                                    </template>
                                </template>
                                <template #column(reference)="{ row, index }">
                                    <EditableText
                                        v-if="Auth.can('manage tickets') && !eventIsClosed"
                                        v-model="ticketType.ticket_sales[index].reference"
                                        :max-length="100"
                                        placeholder="None"
                                    />
                                    <template v-else>
                                        {{ row.reference }}
                                    </template>
                                </template>
                                <template #column(tickets_available)="{ row, index }">
                                    <EditableText
                                        v-if="Auth.can('manage tickets') && !eventIsClosed"
                                        v-model="ticketType.ticket_sales[index].tickets_available"
                                        type="number"
                                        :min-value="0"
                                        :decimal-places="0"
                                    />
                                    <template v-else>
                                        {{ localise(row.tickets_available, 0, false) }}
                                    </template>
                                </template>
                                <template #column(tickets_sold)="{ row }">
                                    {{
                                        typeof row.tickets_sold === 'number'
                                            ? localise(row.tickets_sold, 0, false)
                                            : '-'
                                    }}
                                </template>
                                <template #column(kickback_cost)="{ row, index }">
                                    <PercentageValue
                                        v-if="Auth.can('manage tickets') && !eventIsClosed"
                                        v-model="ticketType.ticket_sales[index].kickback_cost"
                                        :base-value="ticketType.ticket_price"
                                        :currency="true"
                                        :step="0.0001"
                                        size="is-small"
                                        @update:model-value="
                                            (value) => (ticketType.ticket_sales[index].kickback_cost = value)
                                        "
                                    />
                                    <template v-else>
                                        {{ localise(row.kickback_cost, 2, true) }}
                                    </template>
                                </template>
                                <template #column(tax_rate_id)="{ row, index }">
                                    <TaxRateSelector
                                        v-if="Auth.can('manage tickets') && !eventIsClosed"
                                        v-model="ticketType.ticket_sales[index].tax_rate_id"
                                        type="income"
                                        @update:model-value="updateTaxRate(index)"
                                    />
                                    <template v-else>
                                        {{ row.tax_rate ? localise(row.tax_rate.rate, 2, false) + '%' : 'None' }}
                                    </template>
                                </template>
                                <template #column(net_sales)="{ row }">
                                    {{ localise(calcNetSales(row, 'tickets_available'), 2, true) }}
                                </template>
                                <template #column(gross_sales)="{ index }">
                                    {{ localise(calcGrossSales(index, 'tickets_available'), 2, true) }}
                                </template>
                                <template #column(actions)="{ row }">
                                    <Button
                                        v-if="canDelete(row) && !eventIsClosed"
                                        type="is-danger"
                                        @click="deleteTicketSale(row)"
                                    >
                                        <i class="mdi mdi-minus"></i>
                                    </Button>
                                </template>
                            </DataTable>
                            <Button
                                v-if="
                                    availableTicketSellers.length &&
                                    newTicketSellers.length < availableTicketSellers.length &&
                                    Auth.can('manage tickets') &&
                                    !eventIsClosed
                                "
                                type="is-link"
                                class="tw-w-full tw-flex tw-gap-1 tw-justify-center"
                                @click="addTicketSeller"
                            >
                                <i class="mdi mdi-plus"></i>
                                Add Ticket Seller
                            </Button>
                        </template>
                        <template #tab(comments)>
                            <drawer-comments-tab :comments="comments" :disabled="eventIsClosed" @comment="addComment" />
                        </template>
                    </Tabs>
                </div>
                <div v-else>
                    <div
                        v-for="(adjustment, adjustmentIndex) in adjustments"
                        :key="'adjustment_' + adjustmentIndex"
                        class="border rounded m-2"
                    >
                        <div class="d-flex align-items-center p-2">
                            <Selector
                                v-model="adjustment.scenario_id"
                                input-class="input"
                                :options="availableScenarios"
                                :editable="!eventIsClosed"
                                :all-options="Object.values(scenarios)"
                                class="w-100"
                            />
                            <div class="ml-2 ticket-header">
                                <div
                                    class="ticket-meta-block mx-0"
                                    :class="{
                                        editable: !eventIsClosed,
                                    }"
                                    style="min-width: 110px"
                                    @click="updateAdjustmentTicketPrice(adjustmentIndex)"
                                >
                                    <div>
                                        <h6 class="tw-m-0">Ticket Price</h6>
                                        {{ localise(adjustment.ticket_price, 2, true) }}
                                    </div>
                                    <i v-if="!eventIsClosed" class="mdi mdi-pencil"></i>
                                </div>
                            </div>
                            <Tooltip v-if="!eventIsClosed" position="left" label="Delete Scenario Adjustment">
                                <Button class="tw-ml-2" type="is-danger" @click="deleteAdjustment(adjustment)">
                                    <i class="mdi mdi-delete"></i>
                                </Button>
                            </Tooltip>
                        </div>

                        <DataTable
                            :columns="[
                                {
                                    field: 'name',
                                    title: 'Ticket Seller',
                                },
                                {
                                    field: 'tickets_available',
                                    title: 'Allocated',
                                },
                                {
                                    field: 'kickback_cost',
                                    title: 'Rebate',
                                },
                                {
                                    field: 'direct_cost',
                                    title: 'Direct Cost',
                                },
                                {
                                    field: 'tax_rate_id',
                                    title: 'Tax Rate',
                                },
                                {
                                    field: 'net_sales',
                                    title: 'Scenario Net Sales',
                                },
                                {
                                    field: 'gross_sales',
                                    title: 'Scenario Gross Sales',
                                },
                            ]"
                            :data="adjustment.ticket_sales"
                        >
                            <template #column(name)="{ row }">
                                {{ row.supplier ? row.supplier.name : 'Direct Sales' }}
                            </template>
                            <template #column(tickets_available)="{ index }">
                                <input
                                    id="scenarioTicketsAvailable"
                                    v-model="adjustment.ticket_sales[index].tickets_available"
                                    :disabled="eventIsClosed"
                                    type="number"
                                    name="scenarioTicketsAvailable"
                                    class="input is-small"
                                />
                            </template>
                            <template #column(kickback_cost)="{ row }">
                                {{ localise(row.kickback_cost, 2, true) }}
                            </template>
                            <template #column(direct_cost)="{ row, index }">
                                <EditableText
                                    v-if="Auth.can('manage scenarios') && !eventIsClosed"
                                    v-model="adjustment.ticket_sales[index].direct_cost"
                                    type="currency"
                                    :min-value="0"
                                    placeholder="Required"
                                />
                                <template v-else>
                                    {{ localise(row.direct_cost, 2, true) }}
                                </template>
                            </template>
                            <template #column(tax_rate_id)="{ row }">
                                {{ row.tax_rate ? localise(row.tax_rate.rate, 2, false) + '%' : 'None' }}
                            </template>
                            <template #column(reference)="{ row, index }">
                                {{
                                    localise(
                                        calcNetAdjustmentSales(adjustmentIndex, index, 'tickets_available'),
                                        2,
                                        true
                                    )
                                }}
                                <span
                                    v-html="
                                        adjustmentIndicator(
                                            calcNetAdjustmentSales(adjustmentIndex, index, 'tickets_available'),
                                            ticketType.ticket_price,
                                            row.supplier ? row.supplier.id : null
                                        )
                                    "
                                ></span>
                            </template>
                            <template #column(gross_sales)="{ row, index }">
                                {{
                                    localise(
                                        calcGrossAdjustmentSales(adjustmentIndex, index, 'tickets_available'),
                                        2,
                                        true
                                    )
                                }}
                                <span
                                    v-html="
                                        adjustmentIndicator(
                                            calcGrossAdjustmentSales(adjustmentIndex, index, 'tickets_available'),
                                            ticketType.ticket_price,
                                            row.supplier ? row.supplier.id : null,
                                            true
                                        )
                                    "
                                ></span>
                            </template>
                        </DataTable>
                    </div>

                    <div v-if="adjustments.length && !eventIsClosed" class="m-2">
                        <Button type="is-success" class="tw-w-full" @click="saveAdjustments">
                            Save Scenario {{ adjustments.length > 1 ? 'Adjustments' : 'Adjustment' }}
                        </Button>
                    </div>

                    <div class="m-2">
                        <Button
                            v-if="
                                Object.keys(availableScenarios).length &&
                                adjustments.length < Object.values(scenarios).length &&
                                !eventIsClosed
                            "
                            type="is-primary"
                            class="tw-w-full tw-flex tw-gap-2 tw-justify-center"
                            @click="createAdjustment"
                        >
                            <i class="mdi mdi-plus"></i>
                            New Scenario Adjustment
                        </Button>

                        <Button
                            v-else-if="!eventIsClosed"
                            type="is-success"
                            class="tw-w-full tw-flex tw-gap-2 tw-justify-center"
                            @click="createScenario"
                        >
                            <i class="mdi mdi-plus"></i>
                            Create A New Scenario
                        </Button>
                    </div>
                </div>
            </transition>

            <template #footer>
                <Button
                    v-if="!eventIsClosed"
                    type="is-success"
                    :disabled="!isValid || !hasChanged"
                    class="tw-w-full"
                    @click.prevent="saveChanges()"
                >
                    Save Changes
                </Button>
            </template>
        </Drawer>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Drawer from '../../Drawer.vue';
import Selector from '../../controls/Selector.vue';
import TaxRateSelector from '../../controls/TaxRateSelector.vue';
import EditableText from '../../controls/EditableText.vue';
import PercentageValue from '../../widgets/PercentageValue.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { localise, gross2net, toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        Selector,
        TaxRateSelector,
        EditableText,
        PercentageValue,
        Button,
        Tooltip,
        Banner,
        Tabs,
        DataTable,
    },
    props: {
        actuals: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            store: store,
            selectedTicketType: null,
            ticketGroups: [],
            ticketTypes: [],
            ticketType: null,
            ticketSellers: [],
            originalTotalFixedNet: null,
            originalTotalFixedGross: null,
            originalConcatenatedReference: null,
            activeSection: 'tickets',
            scenarios: store.get('current_event.scenarios'),
            adjustments: [],
            comments: [],
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        isEditable() {
            return !this.eventIsClosed && Auth.can('manage tickets') && !this.isScenario;
        },
        availableTicketSellers() {
            if (!this.ticketType) {
                return this.ticketSellers;
            }
            return this.ticketSellers.filter(
                (ticketSeller) =>
                    !this.ticketType.ticket_sales.find((ticketSale) => ticketSale.supplier_id === ticketSeller.id)
            );
        },
        newTicketSellers() {
            if (!this.ticketType) {
                return [];
            }
            return this.ticketType.ticket_sales.filter((ticketSale) => !ticketSale.id);
        },
        totalFixedNetSales() {
            if (!this.ticketType) {
                return 0;
            }
            return this.ticketType.ticket_sales
                .map((ticketSale) => {
                    return this.calcNetSales(ticketSale, 'tickets_available');
                })
                .reduce((p, c) => p + c, 0);
        },
        totalFixedGrossSales() {
            if (!this.ticketType) {
                return 0;
            }
            return this.ticketType.ticket_sales
                .map((ticketSale) => {
                    return this.net2gross(
                        this.calcNetSales(ticketSale, 'tickets_available'),
                        ticketSale.tax_rate ? ticketSale.tax_rate.rate : 0
                    );
                })
                .reduce((p, c) => p + c, 0);
        },
        concatenatedReference() {
            if (!this.ticketType) {
                return null;
            }
            return this.ticketType.ticket_sales.map((ts) => (ts.reference ? ts.reference : '')).join('');
        },
        hasChanged() {
            return (
                this.originalTotalFixedNet !== this.totalFixedNetSales ||
                this.originalTotalFixedGross !== this.totalFixedGrossSales ||
                this.originalConcatenatedReference !== this.concatenatedReference
            );
        },
        isValid() {
            if (!this.ticketType) {
                return false;
            }
            this.totalFixedGrossSales;
            return !this.ticketType.ticket_sales.filter((ticketSale) => {
                if (ticketSale.id) {
                    return isNaN(ticketSale.tickets_available);
                }
                return !ticketSale.supplier_id || isNaN(ticketSale.tickets_available);
            }).length;
        },
        currentSubcategory() {
            if (!this.selectedTicketType || !this.ticketType) {
                return null;
            }
            const category = this.ticketGroups.find((subcategory) => subcategory.id === this.ticketType.category_id);
            return category
                ? category
                : {
                      id: null,
                      name: 'Unassigned',
                  };
        },
        isScenario() {
            return this.activeSection === 'scenarios';
        },
        availableScenarios() {
            let with_adjustments =
                this.adjustments.map((adj) => {
                    return adj.scenario_id;
                }) ?? [];

            return Object.values(this.scenarios).filter((scenario) => !with_adjustments.includes(scenario.id));
        },
        hasOrders() {
            return this.ticketType && !!this.ticketType.ticket_sales.find((ticketSale) => ticketSale.has_orders);
        },
    },
    watch: {
        selectedTicketType(newVal) {
            if (newVal) {
                this.reloadTicketType();
            }
        },
    },
    mounted() {
        this.refreshData();
        Eventbus.$on('view:ticketType', (payload) => {
            this.refreshData();
            if (payload) {
                this.selectedTicketType = payload;
                this.getComments();
            }
            this.$refs.drawer?.open();
        });
    },
    methods: {
        net2gross(netValue, taxRate) {
            return netValue + (netValue / 100) * taxRate;
        },
        refreshData() {
            this.ticketGroups = Object.values(this.store.get('current_event.categories'));
            this.ticketTypes = Object.values(this.store.get('current_event.ticket_types'));
            this.ticketSellers = Object.values(this.store.get('account.suppliers')).filter(
                (supplier) => supplier.supplier_type === 1
            );
        },
        resetData() {
            this.ticketType = null;
            this.selectedTicketType = null;
            this.originalJson = null;
            this.activeSection = 'tickets';
        },
        async getComments() {
            const { data } = await axios.get(
                route('api.account.event.tickets.comments', {
                    ticketType: this.selectedTicketType,
                })
            );
            this.comments = data.comments;
        },
        async addComment(commentText) {
            if (!commentText) {
                return;
            }
            await axios.post(
                route('api.account.event.tickets.comments.create', {
                    ticketType: this.ticketType.id,
                }),
                { text: commentText }
            );
            this.getComments();
        },
        reloadTicketType() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.tickets.get', {
                        ticketType: this.selectedTicketType,
                    })
                )
                .then((response) => {
                    this.ticketType = response.data;
                    this.originalTotalFixedNet = this.totalFixedNetSales;
                    this.originalTotalFixedGross = this.totalFixedGrossSales;
                    this.originalConcatenatedReference = this.concatenatedReference;
                    this.fetchAdjustments();
                })
                .finally(() => (this.isLoading = false));
        },
        calcGrossSales(index, modifier) {
            return (
                (this.ticketType.ticket_price +
                    (this.ticketType.ticket_sales[index].kickback_cost
                        ? parseFloat(this.ticketType.ticket_sales[index].kickback_cost)
                        : 0)) *
                this.ticketType.ticket_sales[index][modifier]
            );
        },
        calcNetSales(ticketSale, modifier) {
            return gross2net(
                (this.ticketType.ticket_price + (ticketSale.kickback_cost ? parseFloat(ticketSale.kickback_cost) : 0)) *
                    ticketSale[modifier],
                ticketSale.tax_rate ? ticketSale.tax_rate.rate : 0
            );
        },
        calcGrossAdjustmentSales(adjustmentIndex, index, modifier) {
            return (
                (this.adjustments[adjustmentIndex].ticket_price +
                    (this.adjustments[adjustmentIndex].ticket_sales[index].kickback_cost
                        ? parseFloat(this.adjustments[adjustmentIndex].ticket_sales[index].kickback_cost)
                        : 0)) *
                this.adjustments[adjustmentIndex].ticket_sales[index][modifier]
            );
        },
        calcNetAdjustmentSales(adjustmentIndex, index, modifier) {
            return gross2net(
                (this.adjustments[adjustmentIndex].ticket_price +
                    (this.adjustments[adjustmentIndex].ticket_sales[index].kickback_cost
                        ? parseFloat(this.adjustments[adjustmentIndex].ticket_sales[index].kickback_cost)
                        : 0)) *
                    this.adjustments[adjustmentIndex].ticket_sales[index][modifier],
                this.adjustments[adjustmentIndex].ticket_sales[index].tax_rate
                    ? this.adjustments[adjustmentIndex].ticket_sales[index].tax_rate.rate
                    : 0
            );
        },
        updateTaxRate(index) {
            this.ticketType.ticket_sales[index].tax_rate = this.store.get(
                'account.tax_rates.' + this.ticketType.ticket_sales[index].tax_rate_id
            );
        },
        updateSupplier(index) {
            this.ticketType.ticket_sales[index].supplier = this.store.get(
                'account.suppliers.' + this.ticketType.ticket_sales[index].supplier_id
            );
        },
        addTicketSeller() {
            this.ticketType.ticket_sales.push({
                ticket_type_id: this.selectedTicketType,
                supplier_id: null,
                supplier: null,
                tickets_available: 0,
                kickback_cost: 0,
                tax_rate_id: null,
                tax_rate: null,
            });
        },
        saveChanges(close = true) {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.tickets.updateSingle', {
                        ticketType: this.selectedTicketType,
                    }),
                    this.ticketType
                )
                .then((response) => {
                    this.ticketType = response.data;
                    this.originalTotalFixedNet = this.totalFixedNetSales;
                    this.originalTotalFixedGross = this.totalFixedGrossSales;
                    this.originalConcatenatedReference = this.concatenatedReference;
                    Eventbus.$emit('reload:stream', {
                        stream: this.ticketType.revenue_stream_id,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                    if (close) {
                        this.$refs.drawer.close();
                    }
                });
        },
        async updateSubcategory() {
            if (this.isScenario || this.eventIsClosed) {
                return;
            }

            const subcategories = {};
            Object.values(this.store.get('current_event.revenue_streams'))
                .filter((stream) => stream.stream_type === 0)
                .forEach((stream) => {
                    Object.values(this.store.get('current_event.categories'))
                        .filter((category) => category.department_id === stream.department_id)
                        .forEach((category) => {
                            subcategories[category.id] = category.name + ' (' + stream.name + ')';
                        });
                });
            const { value: category } = await swal.fire({
                title: 'Change Subcategory (' + this.ticketType.name + ')',
                text: 'Please select the new subcategory for ' + this.ticketType.name + ' tickets',
                input: 'select',
                inputOptions: subcategories,
                // inputValue: this.ticketType.category_id,
                inputPlaceholder: 'Select a subcategory',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You must select a subcategory';
                    }
                },
            });
            if (category) {
                this.ticketType.category_id = parseInt(category);
                this.saveChanges(false);
            }
        },
        async updateTicketPrice() {
            if (this.isScenario || this.eventIsClosed) {
                return;
            }

            if (!this.ticketType || !Auth.can('manage tickets')) {
                return;
            }
            const { value: newPrice } = await swal.fire({
                title: 'Change Ticket Price (' + this.ticketType.name + ')',
                text: 'Please enter the face value for your ticket',
                input: 'number',
                inputValue: Math.round(this.ticketType.ticket_price * 100) / 100,
                inputAttributes: {
                    min: 0,
                    step: 0.5,
                    class: 'flex-fill',
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'The ticket price cannot be empty';
                    } else if (value < 0) {
                        return 'The ticket price must be at least ' + localise(0, 2, true);
                    }
                },
            });
            if (newPrice) {
                this.ticketType.ticket_price = parseFloat(newPrice);
                this.saveChanges(false);
            }
        },
        async updateTicketName() {
            if (this.isScenario || this.eventIsClosed) {
                return;
            }

            if (!this.ticketType || !Auth.can('manage tickets')) {
                return;
            }
            const { value: newName } = await swal.fire({
                title: 'Change Ticket Name (' + this.ticketType.name + ')',
                text: 'Please enter the new name for your ticket',
                input: 'text',
                inputValue: this.ticketType.name,
                inputAttributes: {
                    class: 'flex-fill',
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'The ticket name cannot be empty';
                    } else if (this.ticketTypes.find((tt) => tt.name === value && tt.id !== this.ticketType.id)) {
                        return 'There is already a ticket type called: ' + value;
                    }
                },
            });
            if (newName) {
                this.ticketType.name = newName;
                this.saveChanges(false);
            }
        },
        async updateAdjustmentTicketPrice(adjustmentIndex) {
            if (!this.ticketType || !this.isScenario || this.eventIsClosed) {
                return;
            }

            const { value: newPrice } = await swal.fire({
                title: 'Change Ticket Price (' + this.ticketType.name + ')',
                text: 'Please enter the ticket face value for this scenario',
                input: 'number',
                inputValue: Math.round(this.adjustments[adjustmentIndex].ticket_price * 100) / 100,
                inputAttributes: {
                    min: 0,
                    step: 0.5,
                    class: 'flex-fill',
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'The ticket price cannot be empty';
                    } else if (value < 0) {
                        return 'The ticket price must be at least ' + localise(0, 2, true);
                    }
                },
            });

            if (newPrice) {
                this.adjustments[adjustmentIndex].ticket_price = parseFloat(newPrice);
            }
        },
        saveAdjustments() {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.tickets.adjustments.create', {
                        ticketType: this.ticketType,
                    }),
                    { adjustments: this.adjustments }
                )
                .catch(() => {
                    this.isLoading = false;
                })
                .then((response) => {
                    this.adjustments = response.data;
                    this.isLoading = false;
                    toast('Scenario Saved', 'Your scenario adjustments have been saved successfully', 'success');
                });
        },
        deleteAdjustment(adjustment) {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.tickets.adjustments.delete', {
                        ticketType: this.ticketType,
                    }),
                    { adjustments: [adjustment] }
                )
                .catch(() => {
                    this.isLoading = false;
                })
                .then((response) => {
                    this.adjustments = response.data;
                    this.isLoading = false;
                    toast('Adjustment Deleted', 'The selected scenario adjustment has been deleted', 'success');
                });
        },
        deleteTicket() {
            this.isLoading = true;
            swal.fire({
                title: 'Delete Ticket (' + this.ticketType.name + ')',
                text: 'Are you sure you want to delete this ticket?',
                showCancelButton: true,
                confirmButtonColor: '#4EA5D9',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (!result.value) {
                    this.isLoading = false;
                    return;
                }
                axios
                    .delete(
                        route('api.account.event.tickets.delete', {
                            ticketType: this.ticketType.id,
                        })
                    )
                    .then(() => {
                        toast('Ticket Deleted', 'Your ticket type has been removed successfully', 'success');
                        this.resetData();
                        this.isLoading = false;
                        Eventbus.$emit('reload:stream', {
                            stream: this.ticketType.revenue_stream_id,
                        });
                        Eventbus.$emit('reload:tickets');
                    });
            });
        },
        async deleteTicketSale(ticketData) {
            const { value: confirmation } = await swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete ${ticketData.reference} tickets for ${ticketData.supplier.name}.`,
                showCancelButton: true,
            });
            if (!confirmation) {
                return;
            }
            axios
                .delete(
                    route('api.account.event.tickets.deleteTicketSale', {
                        ticketType: ticketData.ticket_type_id,
                        supplier_id: ticketData.supplier_id,
                    })
                )
                .then((response) => {
                    if (response.status === 204) {
                        this.reloadTicketType();
                    }
                });
        },
        canDelete(ticketsData) {
            const ticketsNumber = typeof ticketsData.tickets_sold === 'number' ? ticketsData.tickets_sold : '0';
            return ticketsNumber === 0 && Auth.can('manage tickets');
        },
        createScenario() {
            this.$refs.drawer.close();
            Eventbus.$emit('scenario:drawerOpen');
        },
        createAdjustment() {
            const sales = this.ticketType.ticket_sales.map((seller) => {
                return {
                    ticket_sales_id: seller.id,
                    supplier: seller.supplier
                        ? {
                              id: seller.supplier.id,
                              name: seller.supplier.name,
                          }
                        : null,
                    tax_rate: seller.tax_rate
                        ? {
                              id: seller.tax_rate.id,
                              rate: seller.tax_rate.rate,
                          }
                        : null,
                    tickets_available: seller.tickets_available,
                    kickback_cost: seller.kickback_cost,
                };
            });

            this.adjustments.push({
                scenario_id: null,
                ticket_price: this.ticketType.ticket_price,
                ticket_sales: sales,
            });
        },
        fetchAdjustments() {
            this.adjustments = [];
            axios
                .get(
                    route('api.account.event.tickets.adjustments', {
                        ticketType: this.ticketType,
                    })
                )
                .then(({ data }) => {
                    this.adjustments = data;
                });
        },
        adjustmentIndicator(value, ticketPrice, supplierId, isGross = false) {
            if (!value || !ticketPrice) {
                return '';
            }

            const ticket = this.ticketType.ticket_sales.find((s) => {
                return s.supplier_id === supplierId;
            });

            let originalValue =
                (ticketPrice + (ticket.kickback_cost ? parseFloat(ticket.kickback_cost) : 0)) *
                ticket.tickets_available;

            originalValue = isGross
                ? original_value
                : gross2net(original_value, ticket.tax_rate ? ticket.tax_rate.rate : 0);

            const diff = value - originalValue;

            if (diff === 0) {
                return '';
            }

            return diff > 0 ? '<i class="mdi mdi-arrow-up-bold"></i>' : '<i class="mdi mdi-arrow-down-bold"></i>';
        },
    },
};
</script>
<style lang="scss" scoped>
.ticket-header {
    display: flex;

    & > .ticket-meta-block {
        border: 1px solid rgba(black, 0.1);
        padding: 3px 5px 1px 7px;
        border-radius: 4px;
        margin-left: 5px;
        user-select: none;
        display: flex;
        align-items: center;
        transition: all 0.1s ease;

        & > div > h6 {
            opacity: 0.5;
            font-size: 0.9em;
        }

        &.editable {
            cursor: pointer;

            & > .mdi {
                display: block;
                opacity: 0.5;
                transition: all 0.1s ease;
                margin-left: 8px;
            }

            &:hover {
                background: rgba(black, 0.01);

                & > .mdi {
                    opacity: 0.75;
                }
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s;
    opacity: 1;
    transform: translateX(0);
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateX(100%);
}
</style>
