<template>
    <div class="tw-grid tw-gap-5">
        <div class="create-payrun-banner buefy-wrapper">
            <FormField label="Payrun Title" class="tw-flex-1">
                <Textbox v-model="payrunTitle" placeholder="Required" maxlength="64" />
            </FormField>
            <FormField label="Payment Date">
                <flat-pickr
                    v-model="payrunDate"
                    class="form-control input"
                    placeholder="Required"
                    :config="flatpickrConfig"
                    required
                ></flat-pickr>
            </FormField>
        </div>
        <Banner v-if="!payrunDate" type="is-info">
            Please enter a title and select a payment date to view available payments.
        </Banner>
        <div v-else class="buefy-wrapper">
            <FormField label="Available Payments">
                <slot name="table" :date="payrunDate"></slot>
            </FormField>
        </div>
        <div class="create-payrun-banner tw-gap-3">
            <Button @click="$emit('return')">Cancel</Button>
            <FormField label="Run Value" class="tw-ml-4">
                {{ localise(totalValue, 2, true) }}
            </FormField>
            <FormField label="Payments" class="tw-ml-5">
                {{ selectedPayments.length }}
            </FormField>
            <Button
                class="tw-ml-auto"
                :disabled="!isValid"
                type="is-success"
                @click="$emit('create', { date: payrunDate, title: payrunTitle })"
            >
                Create Payrun
            </Button>
        </div>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Button,
        FormField,
        Textbox,
        Banner,
    },
    props: {
        selectedPayments: {
            type: Array,
            required: true,
        },
    },
    emits: ['return', 'create'],
    data() {
        return {
            payrunTitle: null,
            payrunDate: null,
        };
    },
    computed: {
        totalValue() {
            return this.selectedPayments.map((sp) => parseFloat(sp.amount)).reduce((p, c) => p + c, 0);
        },
        isValid() {
            return this.payrunTitle && this.payrunDate && this.selectedPayments.length;
        },
    },
};
</script>

<style lang="scss" scoped>
.create-payrun-banner {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 6px;
    background: white;
}
</style>
