<template>
    <div
        ref="stat"
        class="ew-statistic"
        :class="{
            selectable: isSelectable,
            disabled: id && !isSelectable,
        }"
        @click="setClick"
    >
        <div class="stat-meta">
            <i v-if="icon" class="tw-absolute tw-bottom-4 tw-left-5 tw-text-2xl mdi" :class="`mdi-${icon}`" />
            <h4 class="stat-title">{{ title }}</h4>
            <p v-if="subtext" class="stat-subtext">{{ subtext }}</p>
        </div>
        <div class="stat-figure">
            <span v-if="currency">{{ localise(modelValue, 2, true) }}</span>
            <span v-else>{{ modelValue }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        subtext: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        currency: {
            type: Boolean,
            default: false,
        },
        minHeight: {
            type: String,
            default: null,
        },
        id: {
            type: Number,
            default: null,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            isSelectable: false,
        };
    },
    watch: {
        selectable(newVal) {
            this.isSelectable = newVal;
        },
    },
    mounted() {
        this.isSelectable = this.selectable;
        if (this.minHeight) {
            this.$refs.stat.style.cssText = 'min-height:' + this.minHeight;
        }
    },
    methods: {
        setClick() {
            if (!this.isSelectable) return;
            this.$emit('update:modelValue', this.id);
        },
    },
};
</script>

<style lang="scss">
@import '../../../../assets/sass/eventbooks/eventwise';

.ew-statistic {
    &.selected {
        background: $eventwise-primary;
        color: white;

        h4 {
            color: white;
        }
        .stat-subtext {
            opacity: 0.75 !important;
        }
    }
    &.disabled {
        cursor: not-allowed;
    }
}
</style>
