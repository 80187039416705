<template>
    <div id="notification-dropdown-wrapper" class="dropdown notification-list">
        <button
            class="nav-link arrow-none dropdown-toggle nav-icon-animate"
            :class="{
                'has-unread': hasUnreadNotifications,
            }"
            type="button"
            aria-haspopup="false"
            aria-expanded="false"
            @click="showNotificationMenu"
        >
            <i :class="dropdownIconClass"></i>
        </button>
        <div ref="dropdown" class="dropdown-menu dropdown-menu-right dropdown-lg pt-0 rounded">
            <div v-if="notifications && notifications.data.length" class="notification-list-container">
                <div
                    v-for="notification in notifications.data"
                    :key="notification.id"
                    class="notification"
                    :class="{
                        'is-success': notification.data.type === 'success',
                        'is-warning': notification.data.type === 'warning',
                        'is-danger': notification.data.type === 'danger',
                        'is-info': notification.data.type === 'info',
                        'is-unread': !notification.read_at,
                    }"
                >
                    <div id="notification-icon">
                        <i :class="notification.data.icon ? notification.data.icon : 'mdi mdi-cog'"></i>
                    </div>
                    <div id="notification-content">
                        <h6>{{ notification.data.title }}</h6>
                        <p
                            :class="{
                                'is-clamped': notification.data.clamp,
                            }"
                            v-html="notification.data.message"
                        ></p>
                        <small>
                            <span>{{ relativeTimestamp(notification.created_at) }}</span>
                            <a href="#" @click.prevent.stop="toggleReadStatus(notification.id)">
                                Mark {{ notification.read_at ? 'Unread' : 'Read' }}
                            </a>
                            <a v-if="notification.data.url" :href="notification.data.url" target="_blank">See More</a>
                        </small>
                    </div>
                </div>
            </div>
            <small v-else class="tw-block bg-light py-3 text-center text-muted">
                You have no unread notifications! &#127881;
                <br />
                See past notifications in the notification centre.
            </small>
            <a id="notification-centre-link" :href="route('notifications')">
                Notification Centre
                <i class="mdi mdi-arrow-right-thin"></i>
            </a>
            <Loader v-if="isLoading" />
        </div>
    </div>
</template>

<script>
import Loader from '../../widgets/LoadingSpinner.vue';
import { relativeTimestamp } from '@/js/utils.js';

export default {
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            notifications: null,
        };
    },
    computed: {
        dropdownIconClass() {
            return 'mdi mdi-bell font-20 d-block';
        },
        hasUnreadNotifications() {
            return this.notifications && this.notifications.data.length;
        },
    },
    mounted() {
        this.getNotifications();
        Eventbus.$on('reloadNotifications', () => this.getNotifications());
        document.addEventListener('click', this.onDocumentClick);
    },
    unmounted() {
        Eventbus.$off('reloadNotifications');
        document.removeEventListener('click', this.onDocumentClick);
    },
    methods: {
        onDocumentClick(e) {
            if (!e.target.closest('.dropdown-toggle')) {
                this.$refs.dropdown.classList.remove('show');
                return;
            }
        },
        relativeTimestamp,
        getNotifications() {
            this.isLoading = true;
            axios
                .get(
                    route('api.user.notifications.unread', {
                        include_accounts: '*',
                    })
                )
                .then(({ data }) => (this.notifications = data))
                .finally(() => (this.isLoading = false));
        },
        showNotificationMenu() {
            this.$refs.dropdown.classList.add('show');
        },
        toggleReadStatus(notificationId) {
            this.isLoading = true;
            axios
                .post(
                    route('api.user.notifications.toggleRead', {
                        notificationId: notificationId,
                    })
                )
                .then(({ data }) => {
                    this.notifications = data;
                    Eventbus.$emit('reloadNotificationsPage');
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

button.nav-link.has-unread {
    &::before {
        position: absolute;
        top: calc(50% + 2px);
        left: calc(50% + 2px);
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $eventwise-danger;
        border: 2px solid white;
    }
}

.notification-list-container {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    & > .notification {
        display: flex;
        cursor: default;
        border-left: 3px solid transparent;
        border-bottom: 1px solid rgba(black, 0.05);

        & > #notification-icon {
            flex: 0 0 47px;
            display: flex;
            justify-content: center;
            font-size: 18px;
            padding-top: 8px;
        }
        & > #notification-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 10px 20px 10px 0;

            & > h6 {
                margin: 5px 0 2px 0;
            }
            & > p {
                margin: 0 0 5px 0;
                font-size: 0.9em;
                line-height: 1.05rem;

                &.is-clamped {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                }
            }
            & > small {
                display: flex;
                gap: 12px;

                & > span {
                    opacity: 0.66;
                    margin-right: auto;
                }
            }
        }

        &:not(.is-unread) {
            opacity: 0.66;
        }
        &.is-unread {
            border-left-color: $eventwise-dark;
        }

        &.is-success {
            background: lighten($eventwise-secondary, 35);
            color: darken($eventwise-secondary, 20);

            & > #notification-content > h6,
            & > #notification-icon {
                color: darken($eventwise-secondary, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-secondary;
            }
        }
        &.is-warning {
            background: lighten($eventwise-warning, 25);
            color: darken($eventwise-warning, 20);

            & > #notification-content > h6,
            & > #notification-icon {
                color: darken($eventwise-warning, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-warning;
            }
        }
        &.is-danger {
            background: lighten($eventwise-danger, 30);
            color: darken($eventwise-danger, 20);

            & > #notification-content > h6,
            & > #notification-icon {
                color: darken($eventwise-danger, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-danger;
            }
        }
        &.is-info {
            background: lighten($eventwise-primary, 40);
            color: darken($eventwise-primary, 20);

            & > #notification-content > h6,
            & > #notification-icon {
                color: darken($eventwise-primary, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-primary;
            }
        }
    }
}

#notification-centre-link {
    display: block;
    padding: 10px 0 5px 0;
    text-align: center;
}
</style>
