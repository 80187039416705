<template>
    <div>
        <div v-if="!isLoading" class="buefy-wrapper report-table">
            <Banner class="mt-5 mb-4 py-3" type="is-primary">
                <p>
                    This report displays the history of approved budgeted amounts within a single event.
                    <br />
                    Each cell displays the change in the budgeted amount compared to the previous month, unless the
                    cumulative option is selected where totals will accumulate and carry over to the next month.
                    <br />
                    The overall total is displayed on the left alongside the fixed total (fixed amount).
                </p>
            </Banner>
            <div class="reporting-options-row">
                <FormField class="tw-mb-1" field-class="tw-flex tw-gap-1 tw-items-center">
                    <Button
                        :active="reportScope === 'departments'"
                        :disabled="isLoading"
                        @click="reportScope = 'departments'"
                    >
                        Departments
                    </Button>
                    <Button
                        :active="reportScope === 'categories'"
                        :disabled="isLoading"
                        @click="reportScope = 'categories'"
                    >
                        Categories
                    </Button>
                    <Button :active="reportScope === 'tickets'" :disabled="isLoading" @click="reportScope = 'tickets'">
                        Tickets
                    </Button>
                </FormField>
                <div class="flex-fill"></div>
                <Checkbox v-model="cumulative" class="tw-mr-3" :disabled="isLoading">Cumulative</Checkbox>
                <FormField
                    v-if="reportScope !== 'tickets'"
                    class="tw-mb-1"
                    field-class="tw-flex tw-gap-1 tw-items-center"
                >
                    <Button :active="displaying === 'all'" :disabled="isLoading" @click="displaying = 'all'">
                        All
                    </Button>
                    <Button :active="displaying === 'revenue'" :disabled="isLoading" @click="displaying = 'revenue'">
                        Revenue
                    </Button>
                    <Button :active="displaying === 'costs'" :disabled="isLoading" @click="displaying = 'costs'">
                        Costs
                    </Button>
                </FormField>
            </div>
            <div v-if="reportData" class="card">
                <div class="table mb-0">
                    <table class="w-100">
                        <thead>
                            <tr>
                                <th class="sticky-column">
                                    {{ reportScope }}
                                </th>
                                <th class="history-report-title">
                                    <span v-if="reportScope === 'tickets'">Total sold</span>
                                    <span v-else>Total</span>
                                </th>
                                <th class="history-report-title">
                                    <span v-if="reportScope === 'tickets'">Total Available</span>
                                    <span v-else>Fixed</span>
                                </th>
                                <th v-for="label in reportData.labels" :key="label.key" class="history-report-title">
                                    {{ label.label }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Revenue Departments -->
                            <template
                                v-if="
                                    revenueObjects.length &&
                                    ['all', 'revenue'].includes(displaying) &&
                                    reportScope !== 'tickets'
                                "
                            >
                                <tr>
                                    <th class="sticky-column noborder bg-light">Revenue</th>
                                    <td class="bg-light" :colspan="reportData.labels.length + 2">&nbsp;</td>
                                </tr>
                                <tr v-for="object in revenueObjects" :key="object.id">
                                    <td class="sticky-column" :title="object.name">{{ object.name }}</td>

                                    <!-- if Revenue Stream && Ticket type - this row is tickets -->
                                    <template v-if="ticketRow(object)">
                                        <td class="history-report-data">
                                            <!-- Total -->
                                            {{ localise(ticketMap[object.id]['total'], 2, true) }}
                                        </td>
                                        <!-- Fixed -->
                                        <td class="history-report-data">
                                            {{ localise(ticketMap[object.id]['available'], 2, true) }}
                                        </td>
                                        <!-- Individual Months -->
                                        <td
                                            v-for="label in reportData.labels"
                                            :key="label.key"
                                            class="history-report-data"
                                            :class="{
                                                'no-value': !(ticketMap[object.id]['ticket_orders_' + label.key]
                                                    ? ticketMap[object.id]['ticket_orders_' + label.key]
                                                    : 0),
                                            }"
                                        >
                                            {{ localise(ticketMap[object.id]['ticket_orders_' + label.key], 2, true) }}
                                        </td>
                                    </template>
                                    <template v-else>
                                        <!-- Total Column -->
                                        <td class="history-report-data">
                                            {{ totalDeptFigure(object) }}
                                        </td>

                                        <!-- Fixed Column -->
                                        <td class="history-report-data">
                                            {{
                                                localise(
                                                    object.fixed_total ? parseFloat(object.fixed_total) : 0,
                                                    2,
                                                    true
                                                )
                                            }}
                                        </td>
                                        <!-- Monthly columns -->
                                        <td
                                            v-for="label in reportData.labels"
                                            :key="label.key"
                                            class="history-report-data"
                                            :class="{
                                                'no-value': !(object['budgeted_' + label.key]
                                                    ? object['budgeted_' + label.key]
                                                    : 0),
                                            }"
                                        >
                                            {{
                                                localise(
                                                    object['budgeted_' + label.key]
                                                        ? object['budgeted_' + label.key]
                                                        : 0,
                                                    2,
                                                    true
                                                )
                                            }}
                                        </td>
                                    </template>
                                </tr>

                                <tr>
                                    <th class="sticky-column">Total Sales</th>
                                    <th class="history-report-data">
                                        <template v-if="!cumulative">
                                            {{ localise(totalSales, 2, true) }}
                                        </template>
                                        <template v-else-if="reportData.labels.length">
                                            {{ localise(totalSales, 2, true) }}
                                        </template>
                                        <template v-else>{{ localise(totalSales, 2, true) }}</template>
                                    </th>
                                    <!-- Total Sales Fixed -->
                                    <th class="history-report-data">
                                        {{ localise(totalFixed, 2, true) }}
                                    </th>

                                    <!-- Total Sales Monthly Figures -->
                                    <th v-for="label in reportData.labels" :key="label.key" class="history-report-data">
                                        {{ localise(totalMonthlies['total_sales_' + label.key], 2, true) }}
                                    </th>
                                </tr>
                            </template>
                            <template v-else-if="!revenueObjects.length && reportScope !== 'tickets'">
                                <tr>
                                    <th class="sticky-column noborder bg-light">Revenue</th>
                                    <td class="bg-light" :colspan="reportData.labels.length + 2">
                                        There are no revenue {{ reportScope }} to display
                                    </td>
                                </tr>
                            </template>
                            <!-- Cost Departments -->
                            <template
                                v-if="
                                    costObjects.length &&
                                    ['all', 'costs'].includes(displaying) &&
                                    reportScope !== 'tickets'
                                "
                            >
                                <tr>
                                    <th class="sticky-column noborder bg-light">Costs</th>
                                    <td class="bg-light" :colspan="reportData.labels.length + 2">&nbsp;</td>
                                </tr>
                                <tr v-for="object in costObjects" :key="object.id">
                                    <td class="sticky-column" :title="object.name">{{ object.name }}</td>
                                    <td class="history-report-data">
                                        {{ totalDeptFigure(object) }}
                                    </td>
                                    <td class="history-report-data">
                                        {{ localise(object.fixed_total ? parseFloat(object.fixed_total) : 0, 2, true) }}
                                    </td>
                                    <td
                                        v-for="label in reportData.labels"
                                        :key="label.key"
                                        class="history-report-data"
                                        :class="{
                                            'no-value': !(object['budgeted_' + label.key]
                                                ? object['budgeted_' + label.key]
                                                : 0),
                                        }"
                                    >
                                        {{
                                            localise(
                                                object['budgeted_' + label.key] ? object['budgeted_' + label.key] : 0,
                                                2,
                                                true
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="sticky-column">Total Expenses</th>
                                    <th class="history-report-data">
                                        <template v-if="!cumulative">
                                            {{
                                                localise(
                                                    reportData.labels
                                                        .map((label) => {
                                                            return costObjects
                                                                .map((object) =>
                                                                    object['budgeted_' + label.key]
                                                                        ? parseFloat(object['budgeted_' + label.key])
                                                                        : 0
                                                                )
                                                                .reduce((c, a) => c + a, 0);
                                                        })
                                                        .reduce((c, a) => c + a, 0),
                                                    2,
                                                    true
                                                )
                                            }}
                                        </template>
                                        <template v-else-if="reportData.labels.length">
                                            {{
                                                localise(
                                                    costObjects
                                                        .map((object) =>
                                                            object[
                                                                'budgeted_' +
                                                                    reportData.labels[reportData.labels.length - 1].key
                                                            ]
                                                                ? parseFloat(
                                                                      object[
                                                                          'budgeted_' +
                                                                              reportData.labels[
                                                                                  reportData.labels.length - 1
                                                                              ].key
                                                                      ]
                                                                  )
                                                                : 0
                                                        )
                                                        .reduce((c, a) => c + a, 0),
                                                    2,
                                                    true
                                                )
                                            }}
                                        </template>
                                        <template v-else>{{ localise(0, 2, true) }}</template>
                                    </th>
                                    <th class="history-report-data">
                                        {{
                                            localise(
                                                costObjects
                                                    .map((r) => (r.fixed_total ? parseFloat(r.fixed_total) : 0))
                                                    .reduce((c, a) => c + a, 0),
                                                2,
                                                true
                                            )
                                        }}
                                    </th>
                                    <th v-for="label in reportData.labels" :key="label.key" class="history-report-data">
                                        {{
                                            localise(
                                                costObjects
                                                    .map((r) =>
                                                        r['budgeted_' + label.key]
                                                            ? parseFloat(r['budgeted_' + label.key])
                                                            : 0
                                                    )
                                                    .reduce((c, a) => c + a, 0),
                                                2,
                                                true
                                            )
                                        }}
                                    </th>
                                </tr>
                            </template>
                            <template v-else-if="!revenueObjects.length && reportScope !== 'tickets'">
                                <tr>
                                    <th class="sticky-column noborder bg-light">Costs</th>
                                    <td class="bg-light" :colspan="reportData.labels.length + 2">
                                        There are no cost {{ reportScope }} to display
                                    </td>
                                </tr>
                            </template>
                            <template v-else-if="!ticketObjects.length && reportScope === 'tickets'">
                                <tr>
                                    <th class="sticky-column noborder bg-light">Tickets</th>
                                    <td class="bg-light" :colspan="reportData.labels.length + 2">
                                        There are no {{ reportScope }} to display
                                    </td>
                                </tr>
                            </template>
                            <template v-else-if="ticketObjects.length && reportScope === 'tickets'">
                                <tr>
                                    <th class="sticky-column noborder bg-light">Tickets</th>
                                    <td class="bg-light" :colspan="reportData.labels.length + 2">&nbsp;</td>
                                </tr>
                                <tr v-for="object in ticketObjects" :key="object.id">
                                    <td class="sticky-column" :title="object.name">{{ object.name }}</td>
                                    <td class="history-report-data">
                                        {{
                                            localise(
                                                reportData.labels
                                                    .map((label) =>
                                                        object['ticketOrders_' + label.key]
                                                            ? parseFloat(object['ticketOrders_' + label.key])
                                                            : 0
                                                    )
                                                    .reduce((c, a) => c + a, 0),
                                                2,
                                                true
                                            )
                                        }}
                                    </td>
                                    <td class="history-report-data">
                                        {{ localise(object.gross_fixed ? parseFloat(object.gross_fixed) : 0, 2, true) }}
                                    </td>
                                    <td
                                        v-for="label in reportData.labels"
                                        :key="label.key"
                                        class="history-report-data"
                                        :class="{
                                            'no-value': !(object['ticketOrders_' + label.key]
                                                ? object['ticketOrders_' + label.key]
                                                : 0),
                                        }"
                                    >
                                        {{
                                            localise(
                                                object['ticketOrders_' + label.key]
                                                    ? object['ticketOrders_' + label.key]
                                                    : 0,
                                                2,
                                                true
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="sticky-column">Total Sales</th>
                                    <th class="history-report-data">
                                        <template v-if="!cumulative">
                                            {{
                                                localise(
                                                    reportData.labels
                                                        .map((label) => {
                                                            return ticketObjects
                                                                .map((object) =>
                                                                    object['ticketOrders_' + label.key]
                                                                        ? parseFloat(
                                                                              object['ticketOrders_' + label.key]
                                                                          )
                                                                        : 0
                                                                )
                                                                .reduce((c, a) => c + a, 0);
                                                        })
                                                        .reduce((c, a) => c + a, 0),
                                                    2,
                                                    true
                                                )
                                            }}
                                        </template>
                                        <template v-else-if="reportData.labels.length">
                                            {{
                                                localise(
                                                    ticketObjects
                                                        .map((object) =>
                                                            object[
                                                                'ticketOrders_' +
                                                                    reportData.labels[reportData.labels.length - 1].key
                                                            ]
                                                                ? parseFloat(
                                                                      object[
                                                                          'ticketOrders_' +
                                                                              reportData.labels[
                                                                                  reportData.labels.length - 1
                                                                              ].key
                                                                      ]
                                                                  )
                                                                : 0
                                                        )
                                                        .reduce((c, a) => c + a, 0),
                                                    2,
                                                    true
                                                )
                                            }}
                                        </template>
                                        <template v-else>{{ localise(0, 2, true) }}</template>
                                    </th>
                                    <th class="history-report-data">
                                        {{
                                            localise(
                                                ticketObjects
                                                    .map((r) => (r.gross_fixed ? parseFloat(r.gross_fixed) : 0))
                                                    .reduce((c, a) => c + a, 0),
                                                2,
                                                true
                                            )
                                        }}
                                    </th>
                                    <th v-for="label in reportData.labels" :key="label.key" class="history-report-data">
                                        {{
                                            localise(
                                                ticketObjects
                                                    .map((r) =>
                                                        r['ticketOrders_' + label.key]
                                                            ? parseFloat(r['ticketOrders_' + label.key])
                                                            : 0
                                                    )
                                                    .reduce((c, a) => c + a, 0),
                                                2,
                                                true
                                            )
                                        }}
                                    </th>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <LoadingSpinner v-else label="Loading Information" />
    </div>
</template>
<script>
import Banner from '../../../Layout/Banner.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        LoadingSpinner,
        Banner,
        FormField,
        Button,
        Checkbox,
    },
    props: {
        events: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            reportScope: 'departments',
            displaying: 'all',
            cumulative: false,
            reportData: null,
            ticketOrders: [],
            ticketMap: [],
            ticketDeptMap: [],
            ticketSum: 0,
            tally: 0,
            labels: [],

            totalSales: 0,
            totalFixed: 0,
            totalMonthlies: [],
        };
    },
    computed: {
        revenueObjects() {
            if (!this.reportData) return [];
            return this.reportData.data.filter((d) => d.type === 2 || d.is_revenue);
        },
        costObjects() {
            if (!this.reportData) return [];
            return this.reportData.data.filter((d) => d.type === 1 || (d.type !== 2 && !d.is_revenue));
        },
        ticketObjects() {
            return this.reportScope === 'tickets' ? this.reportData.data : [];
        },
    },
    watch: {
        reportScope() {
            this.reloadData();
        },
        cumulative() {
            this.reloadData();
        },
    },
    mounted() {
        if (this.events.length) {
            this.reloadData();
        }
    },

    methods: {
        reloadData() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.reporting.historyReport', {
                        event: this.events[0],
                        type: this.reportScope,
                        cumulative: this.cumulative ? 1 : 0,
                    })
                )
                .then(({ data }) => {
                    this.reportData = data;
                    this.labels = data.labels;
                    if (this.reportScope === 'departments') {
                        this.createDeptTicketRows();
                    } else if (this.reportScope === 'categories') {
                        this.createCatTicketRows();
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        totalDeptFigure(object) {
            let deptFigure = 0;
            if (!this.cumulative) {
                deptFigure = localise(
                    this.reportData.labels
                        .map((label) =>
                            object['budgeted_' + label.key] ? parseFloat(object['budgeted_' + label.key]) : 0
                        )
                        .reduce((c, a) => c + a, 0),
                    2,
                    true
                );
            } else {
                const figuresMap = this.reportData.labels.map((label) =>
                    object['budgeted_' + label.key] ? parseFloat(object['budgeted_' + label.key]) : 0
                );
                deptFigure = localise(figuresMap[this.reportData.labels.length - 1], 2, true);
            }
            return deptFigure;
        },
        ticketRow(object) {
            return (
                (object.type === 2 && object.revenue_stream_type === 0) ||
                (object.is_revenue === true && object.stream_type === 0)
            );
        },
        createDeptTicketRows() {
            // get the department ticket numbers on reloads to avoid loops
            const ticketDepts = this.reportData.data.filter((d) => d.type === 2 && d.revenue_stream_type === 0);
            this.ticketDeptMap = [];
            this.ticketMap = [];
            ticketDepts.forEach((dept) => {
                this.ticketDeptMap = [];
                this.ticketDeptMap['dept'] = dept.id;
                this.ticketDeptMap['name'] = dept.name;
                this.ticketDeptMap['total'] = this.totalTicketData(dept.id);
                this.ticketDeptMap['available'] = this.totalTicketAvailable(dept.id);

                this.reportData.labels.forEach((label) => {
                    const ticketTitle = 'ticket_orders_' + label.key;
                    this.ticketDeptMap[ticketTitle] = this.periodTicketFigure(dept.id, label.key);
                });
                this.ticketMap[dept.id] = this.ticketDeptMap;
            });
            this.totalSales = this.totalSalesFigure();
            this.totalFixed = this.totalFixedFigure();
            this.totalMonthlyFigures();
        },
        createCatTicketRows() {
            // get the Category ticket numbers on reloads to avoid loops
            const ticketCats = this.reportData.data.filter((c) => c.is_revenue === true && c.stream_type === 0);
            this.ticketDeptMap = [];
            this.ticketMap = [];
            ticketCats.forEach((cat) => {
                this.ticketDeptMap = [];
                this.ticketDeptMap['cat'] = cat.id;
                this.ticketDeptMap['name'] = cat.name;
                this.ticketDeptMap['total'] = this.totalTicketData(cat.id);
                this.ticketDeptMap['available'] = this.totalTicketAvailable(cat.id);

                this.reportData.labels.forEach((label) => {
                    const ticketTitle = 'ticket_orders_' + label.key;
                    this.ticketDeptMap[ticketTitle] = this.periodTicketFigure(cat.id, label.key);
                });
                this.ticketMap[cat.id] = this.ticketDeptMap;
            });
            this.totalSales = this.totalSalesFigure();
            this.totalFixed = this.totalFixedFigure();
            this.totalMonthlyFigures();
        },
        totalTicketData(id) {
            // get the sales Total for the tickets
            this.ticketSum = 0;
            let localTicketMap = [];
            let localTicketData = [];

            // Reduce the ticketData down by id
            if (this.reportScope === 'departments') {
                localTicketData = this.reportData.ticketData.filter((ticket) => ticket.department_id === id);
            } else if (this.reportScope === 'categories') {
                localTicketData = this.reportData.ticketData.filter((ticket) => ticket.category_id === id);
            }
            // localTicketData is the ticket Types

            this.reportData.labels.forEach((label) => {
                localTicketMap = localTicketData.map((ticket) =>
                    ticket['ticket_orders_' + label.key] != null ? parseFloat(ticket['ticket_orders_' + label.key]) : 0
                );
                if (!this.cumulative) {
                    this.ticketSum += localTicketMap.reduce((acc, a) => acc + a, 0);
                } else {
                    this.ticketSum = localTicketMap.reduce((acc, a) => acc + a, 0);
                }
            });
            return this.ticketSum;
        },
        totalTicketAvailable(id) {
            this.ticketSum = 0;
            let localTicketData = [];
            // Reduce the ticket type down by id
            if (this.reportScope === 'departments') {
                localTicketData = this.reportData.ticketData.filter((ticket) => ticket.department_id === id);
            } else if (this.reportScope === 'categories') {
                localTicketData = this.reportData.ticketData.filter((ticket) => ticket.category_id === id);
            }

            localTicketData.forEach((ticket) => {
                this.ticketSum += ticket.gross_fixed;
            });
            return this.ticketSum;
        },
        periodTicketFigure(id, labelKey) {
            // get all the figures for the given key and return the sum
            let localTicketData = [];
            if (this.reportScope === 'departments') {
                localTicketData = this.reportData.ticketData.filter((ticket) => ticket.department_id === id);
            } else if (this.reportScope === 'categories') {
                localTicketData = this.reportData.ticketData.filter((ticket) => ticket.category_id === id);
            }
            const periodMap = localTicketData.map((ticket) =>
                ticket['ticket_orders_' + labelKey] != null ? parseFloat(ticket['ticket_orders_' + labelKey]) : 0
            );

            return periodMap.reduce((acc, a) => acc + a, 0);
        },
        totalSalesFigure() {
            //Adds up all the Total Column
            let ticketGroupIds = [];
            if (this.reportScope === 'departments') {
                ticketGroupIds = this.ticketMap.map((ticket) => ticket.dept);
            } else if (this.reportScope === 'categories') {
                ticketGroupIds = this.ticketMap.map((ticket) => ticket.cat);
            }
            let ticketSumById = 0;
            for (const id in ticketGroupIds) {
                ticketSumById += this.totalTicketData(ticketGroupIds[id]);
            }

            if (!this.cumulative) {
                return (
                    this.reportData.labels
                        .map((label) => {
                            return this.revenueObjects
                                .map((object) =>
                                    object['budgeted_' + label.key] ? parseFloat(object['budgeted_' + label.key]) : 0
                                )
                                .reduce((c, a) => c + a, 0);
                        })
                        .reduce((c, a) => c + a, 0) + ticketSumById
                );
            } else {
                let reportLabels = this.reportData.labels.map((label) => {
                    return this.revenueObjects.map((object) =>
                        object['budgeted_' + label.key] ? parseFloat(object['budgeted_' + label.key]) : 0
                    );
                });
                return reportLabels[reportLabels.length - 1].reduce((c, a) => c + a, 0) + ticketSumById;
            }
        },
        totalFixedFigure() {
            let ticketGroupIds = [];
            if (this.reportScope === 'departments') {
                ticketGroupIds = this.ticketMap.map((ticket) => ticket.dept);
            } else if (this.reportScope === 'categories') {
                ticketGroupIds = this.ticketMap.map((ticket) => ticket.cat);
            }
            this.ticketSum = 0;

            ticketGroupIds.forEach((id) => {
                this.ticketSum += this.totalTicketAvailable(id);
            });
            return (
                this.revenueObjects
                    .map((r) => (r.fixed_total ? parseFloat(r.fixed_total) : 0))
                    .reduce((c, a) => c + a, 0) + this.ticketSum
            );
        },
        totalMonthlyFigures() {
            this.totalMonthlies = [];
            // Iterate thru the labels
            this.reportData.labels.forEach((label) => {
                // get the individual values and sum them
                this.totalMonthlies['total_sales_' + label.key] = 0;
                if (this.reportScope === 'departments') {
                    this.ticketMap
                        .map((ticket) => ticket.dept)
                        .forEach((id) => {
                            this.totalMonthlies['total_sales_' + label.key] +=
                                this.ticketMap[id]['ticket_orders_' + label.key];
                        });
                } else if (this.reportScope === 'categories') {
                    this.ticketMap
                        .map((ticket) => ticket.cat)
                        .forEach((id) => {
                            this.totalMonthlies['total_sales_' + label.key] +=
                                this.ticketMap[id]['ticket_orders_' + label.key];
                        });
                }
            });
            this.reportData.labels.forEach((label) => {
                this.totalMonthlies['total_sales_' + label.key] += this.revenueObjects
                    .map((r) => (r['budgeted_' + label.key] ? parseFloat(r['budgeted_' + label.key]) : 0))
                    .reduce((c, a) => c + a, 0);
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.reporting-options-row {
    display: flex;
    margin-top: 10px;
    overflow: auto;
}

.history-report-title {
    min-width: 150px;
    text-align: right !important;

    &:not(:first-child) {
        border-left: 1px solid rgba(black, 0.1) !important;
    }
}
.history-report-data {
    text-align: right !important;

    &.no-value {
        color: rgba(black, 0.4);
    }
    &:not(:first-child) {
        border-left: 1px solid rgba(black, 0.1) !important;
    }
}
.report-table {
    max-width: calc(100vw - 115px);
    overflow: hidden;

    .table {
        border-radius: 5px;
        overflow-x: auto;

        .selectable {
            cursor: pointer;
            user-select: none;

            i.mdi {
                transform: scale(1.33);
            }

            &:hover {
                background: rgba(black, 0.025);
            }
        }

        .start-row {
            border-top: 2px solid rgba(black, 0.2);
        }
        .end-row {
            border-bottom: 2px solid rgba(black, 0.2);
        }
        .selected {
            background: rgba(black, 0.025);
        }

        &:has(.sticky-column) {
            padding-left: 239px;
            border-right: 1px solid rgba(black, 0.1);

            thead > tr:has(.sticky-column),
            tbody > tr:has(.sticky-column) {
                .sticky-column {
                    position: absolute;
                    top: auto;
                    left: 0;
                    width: 240px;
                    background: white;
                    transform: translateY(1px);
                    border-right: 1px solid rgba(black, 0.1);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.noborder {
                        border-right: none;
                    }
                }
            }
        }
    }
}
</style>
