<template>
    <div>
        <Sidebar :page="currentPage" @set-page="setPage" />
        <div class="admintopbar">
            <img src="@/images/icon.svg" alt="Eventwise Logo" />
            <h4 class="m-0">
                <span class="brand"><strong>eventwise</strong></span>
                <span class="divider">|</span>
                {{ pageTitle }}
            </h4>
        </div>
        <div class="pl-4 mt-5 ml-3 pr-1">
            <div class="container-fluid pt-3 pl-5">
                <Dashboard v-if="!currentPage" @set-page="setPage" />
                <Accounts v-else-if="currentPage === 'accounts'" @view="viewAccount" />
                <Account
                    v-else-if="currentPage === 'account' && currentAccount"
                    :id="currentAccount"
                    @back="setPage('accounts')"
                    @title="setTitle"
                />
                <Events v-else-if="currentPage === 'events'" />
                <Users v-else-if="currentPage === 'users'" />
                <Help v-else-if="currentPage === 'help'" @view="viewHelpArticle" />
                <HelpArticle
                    v-else-if="currentPage === 'helpArticle'"
                    :id="currentHelpArticle"
                    @back="setPage('help')"
                    @title="setTitle"
                />
                <Settings v-else-if="currentPage === 'settings' && isSynergiUser" />
            </div>
        </div>
        <Notifications />
    </div>
</template>
<script>
import Notifications from '../../../assets/js/eventbooks/components/utility/notifications.vue';
import Sidebar from './Admin/Sidebar.vue';
import Dashboard from './Admin/Dashboard.vue';
import Accounts from './Admin/Accounts.vue';
import Account from './Admin/Account.vue';
import Events from './Admin/Events.vue';
import Users from './Admin/Users.vue';
import Help from './Admin/Help.vue';
import Settings from './Admin/Settings.vue';
import HelpArticle from './Admin/HelpArticle.vue';
import { setTitle } from '@/js/utils.js';
import { useDataStore } from '@/js/stores/DataStore';

export default {
    components: {
        Notifications,
        HelpArticle,
        Sidebar,
        Dashboard,
        Accounts,
        Account,
        Events,
        Users,
        Help,
        Settings,
    },
    data() {
        return {
            currentUser: useDataStore().get('current_user'),
            currentPage: null,
            currentAccount: null,
            currentHelpArticle: null,
            invite_only: window.Eventwise.invite_only,
        };
    },
    computed: {
        pageTitle() {
            switch (this.currentPage) {
                case 'accounts':
                    this.setTitle('Accounts', 'Eventwise Admin');
                    return 'Accounts';
                case 'account':
                    return 'Accounts';
                case 'events':
                    this.setTitle('Events', 'Eventwise Admin');
                    return 'Events';
                case 'users':
                    this.setTitle('Users', 'Eventwise Admin');
                    return 'Users';
                case 'help':
                    this.setTitle('Help Articles', 'Eventwise Admin');
                    return 'Help Articles';
                case 'helpArticle':
                    return 'Help Articles';
                default:
                    this.setTitle('Dashboard', 'Eventwise Admin');
                    return 'Dashboard';
            }
        },
        isSynergiUser() {
            return this.currentUser && this.currentUser.email.endsWith('@synergitech.co.uk');
        },
    },
    methods: {
        setPage(page = null) {
            if (page !== 'account') {
                this.currentAccount = null;
            }
            this.currentPage = page;
        },
        viewAccount(id) {
            this.currentAccount = id;
            this.setPage('account');
        },
        viewHelpArticle(id) {
            this.currentHelpArticle = id;
            this.setPage('helpArticle');
        },
        setTitle,
    },
};
</script>
<style lang="scss" scoped>
.admintopbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 5000;
    width: 100%;
    min-height: 55px;
    background: white;
    border-bottom: 1px solid rgba(black, 0.15);
    padding-left: 15px;

    & > img {
        display: inline-block;
        max-height: 32px;
        margin-right: 8px;
    }
    h4 {
        span.brand {
            font-size: 22px;
            & > strong {
                letter-spacing: -0.5px;
            }
        }
        span.divider {
            margin: 0 5px;
            opacity: 0.33;
        }
    }
}
</style>
