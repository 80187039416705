<template>
    <div class="col-12 department-budget-column">
        <div class="department-budget-column-option">
            <FormField label="Department Budget">
                <a href="#" @click.prevent="setManualBudget()">
                    {{ localise(manualBudgetAmount, 2, true) }}
                    <i class="mdi mdi-pencil"></i>
                </a>
            </FormField>
            <HelpLabel
                class="ml-3"
                text="An arbitrary figure that can be set to visualise against your current fixed and budgeted amounts to the right"
                position="bottom"
            />
        </div>
        <HorizontalStackedBarChart
            :key="updateKey"
            class="flex-fill"
            :series="chartSeries"
            :max="chartMax"
            :legend="true"
            :height="59"
        />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '../FormField.vue';
import HelpLabel from '../HelpLabel.vue';
import HorizontalStackedBarChart from '../../Chart/HorizontalStackedBarChart.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        FormField,
        HelpLabel,
        HorizontalStackedBarChart,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        const store = useDataStore();

        return {
            objectData: this.object,
            objectType: this.type,
            store: store,
            updateKey: 0,
        };
    },
    computed: {
        chartSeries() {
            this.updateKey; // Trigger reactivity
            const datasets = [
                {
                    label: 'Fixed Amount',
                    data: [this.objectData.total_fixed],
                    backgroundColor: ['#FE9C37'],
                    barPercentage: 1,
                    categoryPercentage: 1,
                },
                {
                    label: 'Budgeted Amount',
                    data: [this.objectData.total_budgeted],
                    backgroundColor: ['#2DDAB5'],
                    barPercentage: 1,
                    categoryPercentage: 1,
                },
                {
                    label: 'Department Budget',
                    data: [this.manualBudgetAmount],
                    backgroundColor: ['#7BDFE1'],
                    barPercentage: 1,
                    categoryPercentage: 1,
                },
            ].sort((a, b) => {
                if (a.data[0] > b.data[0]) {
                    return 1;
                } else if (a.data[0] < b.data[0]) {
                    return -1;
                }
                return 0;
            });

            return {
                labels: ['Budget'],
                datasets: JSON.parse(JSON.stringify(datasets)).map((dataset, index) => {
                    if (index === 0) {
                        return dataset;
                    }
                    dataset.data[0] = dataset.data[0] - datasets[index - 1].data[0];
                    return dataset;
                }),
            };
        },
        chartMax() {
            this.updateKey; // Trigger reactivity
            return this.chartSeries.datasets.reduce((acc, dataset) => {
                return acc + dataset.data[0];
            }, 0);
        },
        manualBudgetAmount() {
            this.updateKey; // Trigger reactivity
            if (!this.objectData.estimates.budgeted_amount) {
                return 0;
            }
            return this.objectData.estimates.budgeted_amount;
        },
    },
    watch: {
        object: {
            handler(newVal) {
                this.objectData = newVal;
            },
            deep: true,
        },
        type(newVal) {
            this.objectType = newVal;
        },
    },
    methods: {
        async setManualBudget(key = 'budgeted_amount') {
            const result = await swal.fire({
                title: 'Change ' + (this.objectType === 'category' ? 'Subcategory' : 'Department') + ' Estimate',
                text: 'Please enter the new budget for ' + this.objectData.name,
                input: 'number',
                inputValue:
                    this.objectData['estimates'] && this.objectData['estimates'][key]
                        ? this.objectData['estimates'][key]
                        : 0,
                inputAttributes: {
                    min: 0,
                    step: 0.01,
                    class: 'flex-fill',
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'The estimated value cannot be empty';
                    } else if (value < 0) {
                        return 'The estimated value must be above ' + localise(0, 2, true);
                    }
                },
            });

            if (
                result.hasOwnProperty('dismiss') &&
                [swal.DismissReason.cancel, swal.DismissReason.backdrop].includes(result.dismiss)
            ) {
                return;
            }

            const url =
                this.objectType === 'category'
                    ? route('api.account.event.categories.update', {
                          category: this.objectData.id,
                      })
                    : route('api.account.event.departments.update', {
                          department:
                              this.objectType === 'revenueStream' ? this.objectData.department_id : this.objectData.id,
                      });

            const response = await axios.post(url, {
                budgeted: result.value,
            });

            if (response.status !== 204) {
                return;
            }

            if (this.objectType === 'revenueStream') {
                this.store.set(
                    'current_event.revenue_streams.' + this.objectData.id + '.estimates.' + key,
                    parseFloat(result.value)
                );
            } else if (this.objectType === 'costDepartment') {
                this.store.set(
                    'current_event.cost_departments.' + this.objectData.id + '.estimates.' + key,
                    parseFloat(result.value)
                );
            } else {
                Object.values(this.store.get('current_event.revenue_streams')).forEach((revenueStream) => {
                    Object.values(revenueStream.categories).forEach((category) => {
                        if (category.id === this.objectData.id) {
                            this.store.set(
                                'current_event.revenue_streams.' +
                                    revenueStream.id +
                                    '.categories.' +
                                    category.id +
                                    '.estimates.' +
                                    key,
                                parseFloat(result.value)
                            );
                        }
                    });
                });
                Object.values(this.store.get('current_event.cost_departments')).forEach((costDepartment) => {
                    Object.values(costDepartment.categories).forEach((category) => {
                        if (category.id === this.objectData.id) {
                            this.store.set(
                                'current_event.cost_departments.' +
                                    costDepartment.id +
                                    '.categories.' +
                                    category.id +
                                    '.estimates.' +
                                    key,
                                parseFloat(result.value)
                            );
                        }
                    });
                });
            }
            this.updateKey++;
        },
    },
};
</script>
<style lang="scss" scoped>
.department-budget-column {
    border-bottom: 1px solid rgba(black, 0.1);
    display: flex;
    align-items: center;

    .department-budget-column-option {
        display: flex;
        align-items: center;
        padding: 10px;
        border-right: 1px solid rgba(black, 0.1);
    }
}
</style>
