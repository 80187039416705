<template>
    <div class="tw-flex tw-gap-3 tw-justify-between">
        <Checkbox v-model="daySelected[1]">Mon</Checkbox>
        <Checkbox v-model="daySelected[2]">Tue</Checkbox>
        <Checkbox v-model="daySelected[3]">Wed</Checkbox>
        <Checkbox v-model="daySelected[4]">Thur</Checkbox>
        <Checkbox v-model="daySelected[5]">Fri</Checkbox>
        <Checkbox v-model="daySelected[6]">Sat</Checkbox>
        <Checkbox v-model="daySelected[7]">Sun</Checkbox>
    </div>
</template>
<script>
import Checkbox from '@/js/components/controls/Checkbox.vue';
export default {
    components: {
        Checkbox,
    },
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            daySelected: [false, false, false, false, false, false, false, false],
            selectedValue: 0,
            componentKey: 0,
        };
    },
    watch: {
        modelValue: {
            handler(newValue) {
                if (newValue !== this.selectedValue) {
                    this.selectedValue = newValue;
                    this.populateCheckboxes();
                }
            },
        },
        daySelected: {
            handler() {
                this.selectedValue = 0;
                for (let i = 1; i <= 7; i++) {
                    this.selectedValue += this.daySelected[i] === true ? Math.pow(2, i - 1) : 0;
                }
                this.$emit('update:modelValue', this.selectedValue);
            },
            deep: true,
        },
    },
    mounted() {
        this.selectedValue = this.modelValue;
        this.populateCheckboxes();
    },
    methods: {
        populateCheckboxes() {
            for (let i = 1; i <= 7; i++) {
                let comparisonValue = Math.pow(2, i - 1);

                if (this.selectedValue && comparisonValue) {
                    this.daySelected[i] = true;
                } else {
                    this.daySelected[i] = false;
                }
            }
            this.forceRerender();
        },
        forceRerender() {
            this.componentKey += 1;
        },
    },
};
</script>
