<template>
    <div class="ew-figure tw-border tw-border-gray-300" :class="figureClass">
        <i v-if="dot" class="mdi mdi-checkbox-blank-circle" :class="figureClass"></i>
        <span class="tw-whitespace-nowrap tw-font-avenir tw-text-xs tw-text-gray-500">
            <slot name="title">{{ title }}</slot>
        </span>
        <div :class="[valueClass, additionalClasses]">
            <slot name="prepend"></slot>
            <slot v-if="$slots['default']"></slot>
            <span v-else>{{ localise(amount, decimals, currency) }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        dot: {
            type: Boolean,
            default: true,
        },
        amount: {
            type: Number,
            required: false,
            default: 0,
        },
        figureClass: {
            type: [String, Object, Array],
            required: false,
            default: null,
        },
        currency: {
            type: Boolean,
            default: true,
        },
        valueClass: {
            type: String,
            default: '',
        },
        decimals: {
            type: Number,
            default: 2,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
        invertHighlight: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        additionalClasses() {
            if (!this.highlight || !this.amount) {
                return false;
            }
            let classes = [];
            if (this.amount < 0 && !this.invertHighlight) {
                classes.push('text-danger');
            } else if (this.amount > 0 && this.invertHighlight) {
                classes.push('text-danger');
            }
            return classes.join(' ');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.ew-figure {
    border-radius: 4px;
    text-align: right;
    position: relative;
    padding: 1px 6px 3px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background: white;
    font-size: 0.99em;
    min-width: 120px;

    & > .mdi:first-child {
        position: absolute;
        top: 14px;
        left: -5px;
        font-size: 10px;
    }
    &:not(:last-child) {
        margin-right: 20px;
    }
}

.danger {
    color: $object-danger;
}
.scenario {
    border-color: $object-scenario;
    & > i {
        color: $object-scenario;
    }
}
.estimate {
    border-color: $object-estimate;
    & > i {
        color: $object-estimate;
    }
}
.fixed {
    border-color: $object-fixed;
    & > i {
        color: $object-fixed;
    }
}
.budgeted {
    border-color: $object-budgeted;
    & > i {
        color: $object-budgeted;
    }
}
.committed {
    border-color: $object-committed;
    & > i {
        color: $object-committed;
    }
}
.remaining {
    border-color: $object-remaining;
    & > i {
        color: $object-remaining;
    }
}
.invoiced {
    border-color: $object-invoiced;
    & > i {
        color: $object-invoiced;
    }
}
.paid {
    border-color: $object-paid;
    & > i {
        color: $object-paid;
    }
}
.primary {
    border-color: $object-primary;
    & > i {
        color: $object-primary;
    }
}
</style>
