<template>
    <div class="eventwise-selector">
        <div
            ref="input"
            class="selector-input tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400 tw-text-black"
            :class="inputClass"
        >
            <div v-if="selectorValue && Array.isArray(selectorValue) && !externalOptions" class="selector-options">
                <span
                    v-for="(optionValue, index) in selectorValue"
                    :key="optionValue"
                    class="selector-option"
                    :class="selectedOptionClass"
                >
                    {{ getOptionTitle(optionValue) }}
                    <span
                        v-if="getOptionRemoveable(optionValue)"
                        class="selector-option-remove"
                        @click.prevent="removeSelection(index)"
                    >
                        x
                    </span>
                </span>
            </div>
            <div
                v-else-if="
                    selectorValue && getOptionTitle(selectorValue) && !Array.isArray(selectorValue) && !externalOptions
                "
                class="selector-options"
            >
                <span class="selector-option" :class="(clipped ? 'clipped ' : null) + selectedOptionClass">
                    <slot name="prefix"></slot>
                    {{ getOptionTitle(selectorValue) }}
                </span>
            </div>
            <span
                v-else-if="placeholder && !isFocused && !searchValue"
                class="selector-placeholder"
                @click="setFocus()"
            >
                {{ placeholder }}
            </span>
            <div
                ref="inputBox"
                class="tw-flex-1"
                :contenteditable="editable"
                @keydown.up.prevent="selectionCursorUp"
                @keydown.down.prevent="selectionCursorDown"
                @keydown.enter.prevent="setSelection"
                @keydown.delete="removeLastSelection"
                @focus="isFocused = true"
                @blur="isFocused = false"
                @input="setSearchValue"
            ></div>
            <div
                v-if="allowClear && selectorValue && !Array.isArray(selectorValue)"
                class="selector-clear"
                @click="removeSelection()"
            >
                <i class="mdi mdi-close-circle"></i>
            </div>
        </div>
        <div class="selector-dropdown-container">
            <div
                v-if="isFocused && filteredSelectorOptions.length && editable"
                :class="computedDropdownClass"
                :style="isOverflowing ? 'bottom: ' + inputHeight + 'px;' : null"
            >
                <template v-if="selectorGroupBy">
                    <div
                        v-for="selectorGroup in groupedSelectorOptions"
                        :key="'group_' + selectorGroup.name"
                        class="selector-group"
                    >
                        <template v-if="selectorGroup.data.length">
                            <h6 class="selector-group-label">{{ selectorGroup.name }}</h6>
                            <a
                                v-for="option in selectorGroup.data"
                                :key="typeof option === 'object' ? option[objectValueKey] : option"
                                :class="{
                                    active:
                                        groupedSelectorOptionsData[searchSelectedIndex][objectValueKey] ===
                                        option[objectValueKey],
                                }"
                                @mousedown.prevent.stop="setSelection(null, option)"
                            >
                                <slot name="optionLabel" :option="option">
                                    {{ typeof option === 'object' ? option[objectNameKey] : option }}
                                </slot>
                            </a>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <a
                        v-for="(option, index) in filteredSelectorOptions"
                        :key="typeof option === 'object' ? option[objectValueKey] : option"
                        :class="{
                            active: index === searchSelectedIndex,
                        }"
                        @mousedown.prevent.stop="setSelection(null, option)"
                    >
                        <slot name="optionLabel" :option="option">
                            {{ typeof option === 'object' ? option[objectNameKey] : option }}
                        </slot>
                    </a>
                </template>
                <slot name="customOptions" :search-value="searchValue" :has-exact-match="hasExactMatch"></slot>
                <small v-if="undisplayedOptions">
                    Displaying {{ filteredSelectorOptions.length }}/{{ availableSelectorOptions.length }} options ({{
                        undisplayedOptions
                    }}
                    hidden)
                </small>
            </div>
            <div v-else-if="isFocused" class="selector-dropdown">
                <slot name="customOptions" :search-value="searchValue" :has-exact-match="hasExactMatch">
                    <span>There are no options available to select</span>
                </slot>
            </div>
        </div>
        <div
            v-if="selectorValue && Array.isArray(selectedOption) && externalOptions"
            class="tw-flex tw-gap-1 tw-flex-wrap tw-mt-2"
        >
            <Tag
                v-for="(optionValue, index) in selectedOption"
                :key="optionValue[objectValueKey]"
                class="tw-flex tw-gap-1 tw-items-center"
                :class="selectedOptionClass"
            >
                <slot name="externalTags" :option="optionValue">
                    {{ optionValue[objectNameKey] }}
                </slot>
                <span
                    v-if="getOptionRemoveable(optionValue)"
                    class="tw-cursor-pointer hover:tw-text-red-500 hover:tw-relative"
                    @click.prevent="removeSelection(index)"
                >
                    <i class="mdi mdi-close-thick"></i>
                </span>
            </Tag>
        </div>
    </div>
</template>
<script>
import Tag from '@/js/components/Tag.vue';

export default {
    components: {
        Tag,
    },
    props: {
        modelValue: {
            type: [String, Number, Array],
            required: false,
        },
        options: {
            type: Array,
            default() {
                return [];
            },
        },
        allOptions: {
            type: Array,
            default() {
                return [];
            },
        },
        groupBy: {
            type: String,
            required: false,
        },
        groups: {
            type: Array,
            default() {
                return [];
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        externalOptions: {
            type: Boolean,
            default: false,
        },
        selectedOptionClass: {
            type: String,
            default: 'tag',
        },
        maxDisplayedOptions: {
            type: Number,
            requried: false,
        },
        objectNameKey: {
            type: String,
            default: 'name',
        },
        additionalSearchKey: {
            type: String,
            required: false,
        },
        objectValueKey: {
            type: String,
            default: 'id',
        },
        allowClear: {
            type: Boolean,
            default: true,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        clipped: {
            type: Boolean,
            default: false,
        },
        inputClass: {
            type: String,
            required: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            isFocused: false,
            selectorOptions: [],
            selectorGroupBy: null,
            selectorValue: null,
            selectedOption: null,
            searchValue: '',
            searchSelectedIndex: 0,
        };
    },
    computed: {
        availableSelectorOptions() {
            return this.selectorOptions.filter((selectorOption) => {
                if (typeof selectorOption === 'object') {
                    if (Array.isArray(this.selectorValue)) {
                        return !this.selectorValue.includes(selectorOption[this.objectValueKey]);
                    }
                    return !(this.selectorValue === selectorOption[this.objectValueKey]);
                }
                if (Array.isArray(this.selectorValue)) {
                    return !this.selectorValue.includes(selectorOption);
                }
                return !(this.selectorValue === selectorOption);
            });
        },
        filteredSelectorOptions() {
            const options = this.availableSelectorOptions.filter((selectorOption) => {
                if (typeof selectorOption === 'object') {
                    return (
                        selectorOption[this.objectNameKey]
                            ?.toLowerCase()
                            .includes(this.searchValue.toLowerCase().trim()) ||
                        (this.additionalSearchKey &&
                            selectorOption[this.additionalSearchKey]
                                ?.toLowerCase()
                                .includes(this.searchValue.toLowerCase().trim()))
                    );
                }
                return selectorOption.toLowerCase().includes(this.searchValue.toLowerCase().trim());
            });

            return this.maxDisplayedOptions ? options.slice(0, this.maxDisplayedOptions) : options;
        },
        groupedSelectorOptions() {
            if (!this.selectorGroupBy) {
                return null;
            }
            const groupedOptions = {};
            this.filteredSelectorOptions.forEach((option) => {
                const groupKey = option[this.selectorGroupBy] ? option[this.selectorGroupBy] : 0;
                if (!groupedOptions[groupKey]) {
                    let group = this.groups.find((group) => group.id === option[this.selectorGroupBy]);
                    group = group
                        ? {
                              id: group.id,
                              name: group.name,
                              data: [],
                          }
                        : {
                              id: null,
                              name: 'Ungrouped',
                              data: [],
                          };
                    groupedOptions[groupKey] = group;
                }
                groupedOptions[groupKey]['data'].push(option);
            });
            return Object.values(groupedOptions);
        },
        groupedSelectorOptionsData() {
            if (!this.groupedSelectorOptions) {
                return null;
            }
            return this.groupedSelectorOptions.map((group) => group.data).flat();
        },
        undisplayedOptions() {
            if (!this.availableSelectorOptions.length) {
                return 0;
            }
            return this.availableSelectorOptions.length - this.filteredSelectorOptions.length;
        },
        inputHeight() {
            return this.$refs.input.offsetHeight;
        },
        isOverflowing() {
            this.isFocused;
            if (!this.$refs.input) {
                return false;
            }
            const inputBounds = this.$refs.input.getBoundingClientRect();
            return window.outerHeight - inputBounds.top < 242 && inputBounds.top > 242;
        },
        computedDropdownClass() {
            const classes = ['selector-dropdown'];
            if (this.isOverflowing) {
                classes.push('is-top');
            }
            if (!this.maxDisplayedOptions) {
                classes.push('is-overflowed');
            }
            return classes.join(' ');
        },
        hasExactMatch() {
            if (!this.filteredSelectorOptions.length) {
                return false;
            }
            let exactMatch = false;
            this.filteredSelectorOptions.forEach((selectorOption) => {
                if (typeof selectorOption === 'object') {
                    if (selectorOption[this.objectNameKey] === this.searchValue) {
                        exactMatch = true;
                    }
                    return;
                }
                if (selectorOption === this.searchValue) {
                    exactMatch = true;
                }
            });
            return exactMatch;
        },
    },
    watch: {
        selectorValue: {
            handler(newVal) {
                if (Array.isArray(newVal)) {
                    this.selectedOption = this.selectorOptions.filter((o) => newVal.includes(o[this.objectValueKey]));
                } else {
                    this.selectedOption = newVal
                        ? this.selectorOptions.find((o) => o[this.objectValueKey] === newVal)
                        : null;
                }
                this.$emit('update:modelValue', newVal);
            },
            deep: true,
        },
        modelValue: {
            handler(newVal) {
                this.selectorValue = newVal;
                this.resetSearchValue();
            },
            deep: true,
        },
        options: {
            handler(newVal) {
                this.selectorOptions = newVal;
            },
            deep: true,
        },
        groupBy(newVal) {
            this.selectorGroupBy = newVal;
        },
    },
    mounted() {
        this.selectorGroupBy = this.groupBy;
        this.selectorOptions = this.options;
        this.selectorValue = this.modelValue
            ? Array.isArray(this.modelValue)
                ? this.modelValue.filter((v) => {
                      // keep this check as a == to allow for string/number comparison
                      return !!this.selectorOptions.find((o) => o[this.objectValueKey] == v);
                  })
                : this.modelValue
            : null;
    },
    methods: {
        setSearchValue(e) {
            if (!this.editable) {
                return;
            }

            this.searchSelectedIndex = 0;
            this.searchValue = e.target.innerText;
        },
        resetSearchValue() {
            this.$refs.inputBox.innerText = '';
            this.searchValue = '';
        },
        selectionCursorUp() {
            if (!this.editable) {
                return;
            }

            if (this.searchSelectedIndex > 0) {
                this.searchSelectedIndex--;
            }
        },
        selectionCursorDown() {
            if (!this.editable) {
                return;
            }

            if (this.searchSelectedIndex < this.filteredSelectorOptions.length - 1) {
                this.searchSelectedIndex++;
            }
        },
        setSelection(event, selection = null) {
            if (!this.editable) {
                return;
            }

            if (!selection && !this.filteredSelectorOptions.length) {
                return;
            }
            if (this.availableSelectorOptions <= 1 || !this.multiple) {
                this.unsetFocus();
            }
            selection = selection
                ? selection
                : this.selectorGroupBy
                  ? this.groupedSelectorOptionsData[this.searchSelectedIndex]
                  : this.filteredSelectorOptions[this.searchSelectedIndex];
            selection = typeof selection === 'object' ? selection[this.objectValueKey] : selection;
            this.resetSearchValue();
            this.searchSelectedIndex = 0;
            this.$forceUpdate();
            if (this.multiple) {
                if (!Array.isArray(this.selectorValue)) {
                    this.selectorValue = [selection];
                } else {
                    this.selectorValue.push(selection);
                }
                if (!this.filteredSelectorOptions.length) {
                    this.unsetFocus();
                }
                return;
            }
            this.selectorValue = selection;
        },
        setFocus() {
            if (!this.editable) {
                return;
            }
            this.isFocused = true;
            this.$refs.inputBox.focus();
        },
        unsetFocus() {
            this.isFocused = false;
            this.$refs.inputBox.blur();
        },
        removeLastSelection() {
            if (!this.editable) {
                return;
            }

            if (!this.selectorValue || this.searchValue || !this.allowClear) {
                return;
            }
            if (Array.isArray(this.selectorValue)) {
                if (!this.selectorValue.length) {
                    return;
                }
                const option = this.getOption(this.selectorValue[this.selectorValue.length - 1]);
                if (option && !!option['cannotRemove']) {
                    return;
                }
                this.selectorValue.pop();
                if (!this.selectorValue.length) {
                    this.selectorValue = null;
                }
                return;
            }
            this.selectorValue = null;
        },
        removeSelection(index) {
            if (!this.editable) {
                return;
            }

            if (!Array.isArray(this.selectorValue)) {
                this.selectorValue = null;
                return;
            }
            this.selectorValue.splice(index, 1);
            if (!this.selectorValue.length) {
                this.selectorValue = null;
            }
        },
        getOption(value) {
            if (typeof this.selectorOptions[0] === 'object') {
                return this.selectorOptions.find((option) => option[this.objectValueKey] == value);
            }
            return value;
        },
        getOptionTitle(value = null) {
            if (this.allOptions.length) {
                const option = this.allOptions.find(
                    // keep this check as a == to allow for string/number comparison
                    (option) => option[this.objectValueKey] == (value ? value : this.selectorValue)
                );
                if (option) {
                    return option[this.objectNameKey];
                }

                return null;
            }

            if (typeof this.selectedOption === 'object') {
                return this.selectedOption[this.objectNameKey] ? this.selectedOption[this.objectNameKey] : 'Unknown';
            }
            return this.selectedOption;
        },
        getOptionRemoveable(value) {
            if (typeof this.selectorOptions[0] === 'object') {
                const option = this.selectorOptions.find((option) => option[this.objectValueKey] == value);
                return option ? !option['cannotRemove'] : true;
            }
            return true;
        },
    },
};
</script>
<style lang="scss">
.eventwise-selector {
    min-width: 100px;

    & > .selector-input {
        display: flex;
        width: 100%;
        height: auto;
        background: white;

        & > .selector-placeholder {
            opacity: 0.33;
        }

        & > .selector-clear {
            opacity: 0.33;
            transition: all 0.1s ease;
            cursor: pointer;
            transform: scale(1.2);

            &:hover {
                opacity: 0.5;
            }
        }
    }
    & > .selector-dropdown-container {
        position: relative;
        user-select: none;

        & > .selector-dropdown {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 99999;
            background: white;
            border: 1px solid #d2d2d2;
            padding: 4px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            &.is-top {
                top: unset;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &.is-overflowed {
                max-height: 177px;
                overflow-y: scroll;
            }

            & > .selector-group {
                & > .selector-group-label {
                    display: block;
                    padding: 0 2px;
                    margin: 4px 0 1px 0;
                    font-size: 0.9em;
                    opacity: 0.5;
                }
            }
            & > a,
            & > .selector-group > a {
                display: block;
                padding: 3px 5px;
                border-radius: 2px;

                &:not(:last-child) {
                    margin-bottom: 2px;
                }
                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
                &.active {
                    background: #4ea5d9;
                    color: white;
                }
            }
            & > small {
                opacity: 0.5;
                padding: 0 3px;
            }
        }
    }

    .selector-options {
        display: flex;
        flex-wrap: wrap;

        &.is-external {
            margin-top: 8px;
        }

        & > .selector-option {
            display: flex;
            align-items: center;
            margin: 1px 3px 1px 0;

            &.clipped {
                display: block;
                max-width: 225px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            & > .selector-option-remove {
                color: black;
                opacity: 0.5;
                margin-left: 4px;
                cursor: pointer;
                font-weight: bold;

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}
</style>
