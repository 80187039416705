<template>
    <div v-if="fieldFor" class="form-field">
        <slot name="label">
            <label :for="fieldFor" :class="customClass">{{ label }}</label>
        </slot>
        <div :class="fieldClass">
            <slot></slot>
            <span
                v-if="typeof error === 'string'"
                class="tw-text-red-500 tw-font-avenir tw-text-xs"
                v-html="error"
            ></span>
            <span
                v-if="typeof success === 'string'"
                class="tw-text-emerald-500 tw-font-avenir tw-text-xs"
                v-html="success"
            ></span>
        </div>
    </div>
    <dl v-else class="form-field tw-mb-0">
        <slot name="label">
            <dt :class="customClass">{{ label }}</dt>
        </slot>
        <dd class="tw-mb-0" :class="fieldClass">
            <slot></slot>
            <span
                v-if="typeof error === 'string'"
                class="tw-text-red-500 tw-font-avenir tw-text-xs"
                v-html="error"
            ></span>
            <span
                v-if="typeof success === 'string'"
                class="tw-text-emerald-500 tw-font-avenir tw-text-xs"
                v-html="success"
            ></span>
        </dd>
    </dl>
</template>
<script>
export default {
    provide() {
        return {
            error: () => this.error,
            success: () => this.success,
        };
    },
    props: {
        label: {
            type: String,
            required: false,
        },
        fieldFor: {
            type: String,
            required: false,
        },
        customClass: {
            type: [String, Object],
            required: false,
        },
        fieldClass: {
            type: String,
            required: false,
        },
        error: {
            type: [String, Boolean],
            default: '',
        },
        success: {
            type: [String, Boolean],
            default: '',
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.form-field {
    display: flex;
    flex-direction: column;

    & > label,
    & > dt {
        font-family: $eventwise-font-heading;
        margin-bottom: 0;
        font-size: 0.9em;
        font-weight: 400;
        color: #656d9a;
    }
}
</style>
