<template>
    <Drawer ref="drawer" title="Log Ticket Transactions" size="is-medium" :loading="isLoading" @closing="reset()">
        <div class="drawer-row px-3 pt-4">Select the ticket type, add quantity and date, then click button to add.</div>
        <div class="drawer-row flex-fill p-3">
            <div v-for="(transaction, index) in transactions" :key="index" class="border rounded mb-2 py-2 px-3">
                <FormField :label="'Transaction #' + (index + 1)">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-3">Ticket Type</div>
                            <div class="col-9">
                                <Selector
                                    v-model="transactions[index].ticket_type_id"
                                    :options="ticketData"
                                    input-class="input is-small"
                                ></Selector>
                            </div>
                        </div>
                        <div v-if="transactions[index].ticket_type_id">
                            <div class="row align-items-center mt-1">
                                <div class="col-3 mb-1">Ticket Seller</div>
                                <div class="col-9 mb-1">
                                    <Selector
                                        v-model="transactions[index].ticket_sale_id"
                                        :options="getTicketSellers(transactions[index].ticket_type_id)"
                                        input-class="input is-small"
                                    ></Selector>
                                </div>
                                <div class="col-3">Quantity</div>
                                <div class="col-3">
                                    <NumberInput v-model="transactions[index].quantity" min="1" />
                                </div>
                                <div class="col-2">Date</div>
                                <div class="col-4">
                                    <flat-pickr
                                        v-model="transactions[index].sold_at"
                                        placeholder="Required"
                                        :config="customFlatpickrConfig"
                                        class="input is-small"
                                    ></flat-pickr>
                                </div>
                                <div class="col-12 d-flex align-items-end">
                                    <div class="flex-fill mt-2">
                                        <Checkbox v-model="transactions[index].is_refunded">
                                            These ticket(s) have been refunded or cancelled
                                        </Checkbox>
                                        <Checkbox v-model="transactions[index].no_value">
                                            These ticket(s) have no monetary value
                                        </Checkbox>
                                    </div>
                                    <Button type="is-danger" @click="removeTransaction(index)">
                                        <i class="mdi mdi-close"></i>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormField>
            </div>
            <Button
                v-if="ticketData && ticketData.length"
                type="is-link"
                :disabled="!isValid"
                class="tw-w-full tw-flex tw-gap-1 tw-items-center"
                @click="addTransaction()"
            >
                <i class="mdi mdi-plus"></i>
                Add Transaction
            </Button>
        </div>
        <div class="drawer-row py-3 px-4 bg-light d-flex">
            <FormField class="tw-mr-auto" label="Total Tickets">
                {{ totalTickets }}
            </FormField>
            <Button type="is-success" :disabled="!isValid || !transactions.length" @click="createTransactions()">
                Create Transaction
            </Button>
        </div>
    </Drawer>
</template>
<script>
import Selector from '../../controls/Selector.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Drawer from '../../Drawer.vue';
import Button from '@/js/components/controls/Button.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Selector,
        Drawer,
        Button,
        NumberInput,
        Checkbox,
        FormField,
    },
    emits: ['created'],
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            ticketData: null,
            transactions: [],
            customFlatpickrConfig: {
                defaultDate: new Date(),
                minDate: store.get('current_event.project_start').split('T')[0],
                maxDate: store.get('current_event.ends_at').split('T')[0],
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
            },
            store: store,
        };
    },
    computed: {
        isValid() {
            return !this.transactions.filter((transaction) => {
                return (
                    !transaction.ticket_type_id ||
                    !transaction.ticket_sale_id ||
                    !transaction.quantity ||
                    !transaction.sold_at
                );
            }).length;
        },
        totalTickets() {
            return this.transactions
                .map((transaction) => {
                    return transaction.quantity
                        ? transaction.is_refunded
                            ? transaction.quantity * -1
                            : transaction.quantity
                        : 0;
                })
                .reduce((c, a) => c + a, 0);
        },
    },
    methods: {
        open(payload = {}) {
            this.getTicketData(payload);
            this.$refs.drawer.open();
        },
        close() {
            this.$refs.drawer.close();
        },
        reset() {
            this.isLoading = false;
            this.ticketData = null;
            this.transactions = [];
        },
        addTransaction() {
            this.transactions.push({
                quantity: 1,
                sold_at: new Date().toISOString().split('T')[0],
                is_refunded: false,
                no_value: false,
            });
        },
        removeTransaction(index) {
            this.transactions.splice(index, 1);
        },
        getTicketData(payload = {}) {
            this.isLoading = true;
            axios
                .get(route('api.account.event.tickets'))
                .then((response) => {
                    this.ticketData = response.data;
                    if (payload && payload.ticketType && payload.ticketSale) {
                        this.transactions.push({
                            ticket_type_id: payload.ticketType,
                            ticket_sale_id: payload.ticketSale,
                            quantity: 1,
                            sold_at: new Date().toISOString().split('T')[0],
                            is_refunded: false,
                            no_value: false,
                        });
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        getTicketSellers(ticketTypeId) {
            const ticketType = this.ticketData.find((ticketType) => ticketType.id === ticketTypeId);
            if (!ticketType) {
                return [];
            }
            return ticketType.ticket_sales
                .filter((ticketSale) => {
                    return ticketSale.supplier_id === null || (ticketSale.supplier && !ticketSale.supplier.is_system);
                })
                .map((ticketSale) => {
                    return {
                        id: ticketSale.id,
                        name: ticketSale.supplier ? ticketSale.supplier.name : 'Direct Sales',
                    };
                });
        },
        createTransactions() {
            this.isLoading = true;
            axios
                .post(route('api.account.event.orders.create'), {
                    transactions: this.transactions,
                })
                .then(() => {
                    this.$emit('created');
                    toast('Success', 'Your ticket transactions have been created successfully', 'success');
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>

<style scoped></style>

<style scoped lang="scss">
.tickets-manual {
    .manual_type_input {
        width: 100%;
    }
    .manual_qty_input {
        width: 100px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .manual_date_input {
        width: 250px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .manual_label {
        width: 100%;
        margin-left: 0;
    }
}
</style>
