<template>
    <PageAdmin v-if="route().current('admin*') && currentUser.is_superadmin" />
    <div v-else>
        <template v-if="!errorCode">
            <template v-if="!currentUser && currentRoute === 'help'">
                <UnauthenticatedLayout />
                <Help />
            </template>
            <InvitationAccept v-else-if="!currentUser && currentRoute === 'invitation'" />
            <Auth v-else-if="!currentUser" />
            <Onboarding v-else-if="currentUser && !currentAccount && !currentEvent && currentRoute === 'onboarding'" />
            <template v-else>
                <Sidebar @navigate="navigate" />
                <Topbar />
                <Me v-if="currentRoute === 'me'" />
                <Notifications v-else-if="currentRoute === 'notifications'" />
                <Help v-else-if="currentRoute === 'help'" />
                <Portal v-else-if="currentAccount && currentAccount.is_supplier" />
                <Account v-else-if="currentAccount && !currentEvent" />
                <Event v-else-if="currentAccount && currentEvent" />
            </template>
        </template>
        <Error v-else :code="errorCode" :message="errorMessage" :reference="errorReference" />
        <GlobalComponents />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import InvitationAccept from '@/js/components/InvitationAccept.vue';
import Auth from './Auth.vue';
import GlobalComponents from './Globals.vue';
import Onboarding from './Onboarding.vue';
import Notifications from './pages/Notifications.vue';
import Sidebar from './Layout/Sidebar.vue';
import Topbar from './Layout/Topbar.vue';
import Me from './pages/Me.vue';
import Account from './Account.vue';
import Event from './Event.vue';
import Portal from './pages/Portal.vue';
import PageAdmin from '@/js/components/pages/Admin.vue';
import Help from './Help.vue';
import Error from './Error.vue';
import UnauthenticatedLayout from './Layout/UnauthenticatedLayout.vue';
import { setRoute, toast } from '@/js/utils.js';

export default {
    components: {
        UnauthenticatedLayout,
        Help,
        Auth,
        InvitationAccept,
        GlobalComponents,
        Onboarding,
        Notifications,
        Sidebar,
        PageAdmin,
        Topbar,
        Me,
        Account,
        Event,
        Portal,
        Error,
    },
    data() {
        const dataStore = useDataStore();
        return {
            currentUser: dataStore.get('current_user'),
            currentAccount: dataStore.get('account'),
            currentEvent: dataStore.get('current_event'),
            currentRoute: route().current(),
            errorCode: window.errorCode,
            errorMessage: window.errorMessage,
            errorReference: window.errorReference,
        };
    },
    mounted() {
        window.addEventListener('popstate', () => {
            Eventbus.$emit('pageChanged');
            Eventbus.$emit('routeChanged');
        });
        Eventbus.$on('routeChanged', () => (this.currentRoute = route().current()));
        if (route().params.action) {
            setTimeout(() => this.handleRouteAction(JSON.parse(route().params.action)), 250);
        }
    },
    methods: {
        navigate(route = null, params = {}) {
            setRoute(route, params);
        },
        handleRouteAction(action) {
            if (!action.e) {
                toast('Error', 'No emitter defined in route action', 'error');
                return;
            }
            const args = action.a ?? [];
            Eventbus.$emit(action.e, ...args);
            const url = new URL(window.location.href);
            url.searchParams.delete('action');
            window.history.replaceState({}, '', url);
        },
    },
};
</script>
<style lang="scss">
.spa-page-wrapper {
    padding: 60px 0 0 0;
}

#app:has(.top-banner) {
    margin-top: 28px;

    #topbar {
        top: 28px;
    }

    #spa-sidebar {
        padding-top: 32px;
    }

    .top-banner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        padding: 5px 0;
        font-size: 0.9em;
        text-align: center;
        z-index: 4950;
    }
}
</style>
