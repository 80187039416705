<template>
    <page :title="pageTitle">
        <event-settings class="mt-4" :tracking-options="xeroTrackingCategories"></event-settings>
    </page>
</template>
<script>
import Page from '../Page.vue';
import EventSettings from '../../../assets/js/eventbooks/components/event/event-settings.vue';
import { useDataStore } from '@/js/stores/DataStore.js';

export default {
    components: {
        Page,
        EventSettings,
    },
    data() {
        const store = useDataStore();
        return {
            xeroTrackingCategories: null,
            store: store,
        };
    },
    computed: {
        pageTitle() {
            return this.store.get('current_event.name')
                ? this.store.get('current_event.name') + ' Settings'
                : 'Event Settings';
        },
    },
    mounted() {
        this.getXeroTrackingCategories();
    },
    methods: {
        getXeroTrackingCategories() {
            if (!this.store.get('account.xero_organisation.tracking_category')) {
                return;
            }
            axios.get(route('api.account.xero.trackingCategories.trackingOptions')).then(({ data }) => {
                this.xeroTrackingCategories = data;
            });
        },
    },
};
</script>
