<template>
    <div>
        <!-- set a frame to put the tags into -->
        <div class="tw-grid tw-grid-cols-2 tw-gap-3">
            <div class="tw-grid tw-gap-3">
                <p class="new-tag-header">New Tags</p>
                <FormField label="Tag Name" class="tw-px-3" size="is-small">
                    <Textbox v-model="newTag.name" placeholder="Add a new tag name..." maxlength="12" />
                </FormField>

                <div class="tw-grid tw-grid-cols-2 tw-px-3">
                    <div class="tw-flex tw-flex-col">
                        <label for="backgroundColor" class="color-picker-label">Background</label>
                        <input id="backgroundColor" v-model="newTag.background" type="color" class="color-picker" />
                    </div>
                    <div class="tw-flex tw-flex-col">
                        <label for="foregroundColor" class="color-picker-label">Foreground</label>
                        <input id="foregroundColor" v-model="newTag.foreground" type="color" class="color-picker" />
                    </div>
                </div>

                <div class="tw-mx-auto tw-border tw-px-3">
                    <GroupTag :tag="newTag" />
                </div>
                <Button
                    class="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-w-fit tw-mx-auto"
                    type="is-info"
                    :disabled="!newTag.name"
                    @click.prevent="addTag"
                >
                    <i class="mdi mdi-plus"></i>

                    Add
                </Button>
            </div>
            <div>
                <p class="new-tag-header">Existing Tags</p>
                <div class="tw-flex tw-flex-wrap tw-gap-3">
                    <div v-for="tag of existingTags" :key="tag.id" class="tw-cursor-pointer" @click="selectTag(tag.id)">
                        <GroupTag :tag="tag" />
                    </div>
                </div>
                <template v-if="reporting && associatedModels.length > 0">
                    <div class="full-width new-tag-header px-5 mt-5">Associated Events</div>
                    <div class="overflow-auto ml-2" style="max-height: 130px">
                        <div v-for="event in associatedModels" :key="event.id">
                            {{ event.name }}
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div v-if="tagSelected" class="tw-flex tw-border tw-items-center tw-p-3 tw-mt-3">
            <p>The selected tag</p>
            <GroupTag class="tw-mx-1" :tag="selectedTag" />
            <p v-if="assignedTagsCount > 0">
                has {{ assignedTagsCount }} assignments which must be removed before deleting
            </p>
            <p v-else>has {{ assignedTagsCount }} assignments and can be deleted</p>
            <div class="tw-ml-auto tw-flex tw-gap-3">
                <Button type="is-primary" class="m-1" @click.prevent="cancelSelection">Cancel</Button>
                <Button :disabled="assignedTagsCount > 0" type="is-primary" @click.prevent="deleteTag(selectedTag.id)">
                    Delete
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
import GroupTag from '@/js/components/GroupTag.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';

export default {
    components: {
        GroupTag,
        Button,
        FormField,
        Textbox,
    },
    props: {
        reporting: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            tagRecords: [],
            existingTags: [],
            selectedTag: [],
            assignedTagsCount: 0,
            tagSelected: false,
            newTag: {
                name: '',
                background: '#FFFFFF',
                foreground: '#000000',
            },
            eventClass: 'App\\Models\\Event',
            associatedModels: [],
        };
    },
    mounted() {
        this.fetchTags();
    },
    methods: {
        fetchTags() {
            // Fetch the tags for this account, all of them
            this.isLoading = true;
            axios
                .get(route('api.account.tags'))
                .then(({ data }) => {
                    this.tagRecords = data.grouptags;
                    this.aligningTagRecords();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        aligningTagRecords() {
            this.tagRecords.forEach((tag) => {
                const tempTag = {
                    id: tag.id,
                    name: tag.name,
                    background: tag.background,
                    foreground: tag.foreground,
                };
                this.existingTags.push(tempTag);
            });
        },
        fetchAssignedTags(tagId) {
            this.isLoading = true;
            if (tagId == null) {
                return;
            }
            axios
                .get(
                    route('api.account.tags.assigned', {
                        class: null,
                        id: tagId,
                    })
                )
                .then(({ data }) => {
                    this.assignedTagsCount = data.assigned_tags.length;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        fetchAssociatedEvents(tagId) {
            if (!this.reporting) {
                this.associatedModels = [];
                return;
            }
            axios
                .get(
                    route('api.account.tags.associated', {
                        class: this.eventClass,
                        id: tagId,
                    })
                )
                .then(({ data }) => {
                    this.associatedModels = data.models;
                });
        },
        addTag() {
            if (this.newTag.name.length === 0) {
                return;
            }
            // Push the new tag to the database
            axios
                .post(
                    route('api.account.tags.add', {
                        name: this.newTag.name,
                        background: this.newTag.background,
                        foreground: this.newTag.foreground,
                    })
                )
                .then(() => {
                    this.reset();
                });
        },
        async deleteTag(tagId) {
            const { value: confirmation } = await swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete the ${this.selectedTag.name} tag.`,
                showCancelButton: true,
            });
            if (!confirmation) {
                return;
            }
            this.isLoading = true;
            axios
                .delete(
                    route('api.account.tags.delete', {
                        groupTag: tagId,
                    })
                )
                .then(() => {
                    this.existingTags = this.existingTags.filter((tag) => tag !== tagId);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.cancelSelection();
                });
        },
        selectTag(tagId) {
            this.tagSelected = true;
            this.fetchAssignedTags(tagId);
            this.selectedTag = this.existingTags.filter((tag) => tag.id === tagId)[0];
            this.fetchAssociatedEvents(tagId);
        },
        cancelSelection() {
            this.tagSelected = false;
            this.selectedTag = 0;
            this.assignedTagsCount = 0;
            this.reset();
        },
        reset() {
            this.newTag = {
                name: '',
                background: '#FFFFFF',
                foreground: '#000000',
            };
            this.existingTags = [];
            this.selectedTag = [];
            this.associatedModels = [];
            this.fetchTags();
        },
    },
};
</script>
<style scoped>
.new-tag-header {
    background-color: #f2f2f2;
    @apply tw-text-center tw-py-2;
}
.color-picker {
    width: 40%;
    height: 50px;
    margin: 0 10px 10px 10px;
}
.color-picker-label {
    width: 40%;
    margin: 0 10px 0 10px;
}
</style>
