<template>
    <div v-if="objectData" class="row department-info">
        <div class="col-12 col-lg-4 col-xl-3">
            <FormField label="Description" class="px-4 pt-3 pb-2">
                <p v-if="!Auth.can('manage departments') || objectData.is_locked">{{ objectData.description }}</p>
                <Textbox
                    v-else
                    v-model="objectData.description"
                    type="textarea"
                    toggle
                    @update:model-value="updateDepartment()"
                ></Textbox>
            </FormField>
            <FormField label="Members" class="px-4 pb-3 mb-5">
                <UserDisplay :users="objectData.members" class="mt-1">
                    <template #default="{ user }">
                        <Tooltip position="right">
                            <User :user="user" size="small" />
                            <template #content>
                                <div class="row">
                                    <div class="col-12 text-left border-bottom pb-1 mb-1">
                                        <span class="!tw-font-semibold">{{ user.name }}</span>
                                        can approve:
                                    </div>
                                    <div class="col-7 text-left">Budget Entries</div>
                                    <div
                                        class="col-5 text-right"
                                        :class="user.approvables.budgets ? 'text-success' : 'text-danger'"
                                    >
                                        {{ user.approvables.budgets ? 'Yes' : 'No' }}
                                        <i
                                            :class="
                                                user.approvables.budgets ? 'mdi mdi-check-bold' : 'mdi mdi-close-thick'
                                            "
                                        ></i>
                                    </div>
                                    <div class="col-7 text-left">Purchase Orders</div>
                                    <div
                                        class="col-5 text-right"
                                        :class="user.approvables.purchases ? 'text-success' : 'text-danger'"
                                    >
                                        {{ user.approvables.purchases ? 'Yes' : 'No' }}
                                        <i
                                            :class="
                                                user.approvables.purchases
                                                    ? 'mdi mdi-check-bold'
                                                    : 'mdi mdi-close-thick'
                                            "
                                        ></i>
                                    </div>
                                    <div class="col-7 text-left">Payment Runs</div>
                                    <div
                                        class="col-5 text-right"
                                        :class="user.approvables.payruns ? 'text-success' : 'text-danger'"
                                    >
                                        {{ user.approvables.payruns ? 'Yes' : 'No' }}
                                        <i
                                            :class="
                                                user.approvables.payruns ? 'mdi mdi-check-bold' : 'mdi mdi-close-thick'
                                            "
                                        ></i>
                                    </div>
                                </div>
                            </template>
                        </Tooltip>
                    </template>
                </UserDisplay>
            </FormField>
        </div>
        <div class="col-12 col-lg-8 col-xl-9 department-info-meta">
            <div class="department-info">
                <Banner v-if="!objectData.configurable_fields.length" class="tw-text-center">
                    There aren&apos;t any configurable fields set up for this department yet.
                    {{
                        Auth.can('manage configurable fields') && !objectData.is_locked
                            ? 'Click the button below to add some.'
                            : null
                    }}
                </Banner>
                <ConfigurableFields
                    v-model="objectData.configurable_fields"
                    :editable="Auth.can('edit configurable fields') && !objectData.is_locked"
                    @updated="updateConfigurableFields"
                />
                <Button
                    v-if="Auth.can('manage configurable fields') && !objectData.is_locked"
                    class="tw-w-full tw-mt-auto tw-flex tw-gap-1 tw-items-center tw-justify-center"
                    @click="createConfigurableField"
                >
                    <i class="mdi mdi-plus"></i>
                    Add Field
                </Button>
            </div>
        </div>
        <CreateConfigurableField ref="createConfigurableFieldModal" @created="reloadData()" />
    </div>
</template>
<script>
import FormField from '../FormField.vue';
import Textbox from '../../controls/Textbox.vue';
import UserDisplay from '../UserDisplay.vue';
import User from '../User.vue';
import Banner from '../../Layout/Banner.vue';
import ConfigurableFields from '../../controls/ConfigurableFields.vue';
import CreateConfigurableField from '../../modals/CreateConfigurableField.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        FormField,
        Textbox,
        UserDisplay,
        User,
        Banner,
        ConfigurableFields,
        CreateConfigurableField,
        Button,
        Tooltip,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            objectData: null,
            currentEvent: useDataStore().get('current_event.id'),
        };
    },
    mounted() {
        this.reloadData();
    },
    methods: {
        reloadData() {
            if (!this.currentEvent) {
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.departments.get', {
                        event: this.currentEvent,
                        department: this.object.department_id ? this.object.department_id : this.object.id,
                    })
                )
                .then(({ data }) => (this.objectData = data))
                .finally(() => (this.isLoading = false));
        },
        createConfigurableField() {
            this.$refs.createConfigurableFieldModal.open('App\\Models\\Department', this.objectData);
        },
        updateDepartment() {
            if (!Auth.can('manage departments')) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.departments.update', {
                        department: this.objectData.id,
                    }),
                    {
                        description: this.objectData.description,
                    }
                )
                .then(({ data }) => (this.objectData = data))
                .finally(() => (this.isLoading = false));
        },
        updateConfigurableFields(fields) {
            if (!Auth.can('edit configurable fields')) {
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.fields.createOrUpdate'), {
                    fields: fields,
                })
                .then(({ data }) => (this.objectData.configurable_fields = data))
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss" scoped>
.department-info {
    max-width: 100%;
    overflow: hidden;

    & > .department-info-meta {
        @media (min-width: 992px) {
            border-left: 1px solid rgba(black, 0.1);
        }

        & > .department-info {
            padding: 15px 5px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}
</style>
