<template>
    <LoadingSpinner v-if="!isLoaded" label="Loading Information" />
    <div v-else class="tw-flex tw-items-center">
        <Textbox v-if="selectedItem" class="tw-flex-1" :model-value="selectedItemName()" />
        <Button v-if="selectedItem !== null && editable" @click="openModal()">
            <i class="mdi mdi-pencil"></i>
        </Button>
        <Button v-else-if="editable" type="is-link" @click="openModal()">
            <i class="mdi mdi-plus"></i>
            {{ buttonText }}
        </Button>
        <div v-else>None</div>
    </div>
    <Modal ref="modal" :title="modalTitle">
        <template #default>
            <div class="modal-card text-left" style="width: auto">
                <section class="modal-card-body">
                    <LoadingSpinner v-if="!isLoaded" label="Loading Information" />
                    <template v-else-if="isLoaded && !isCreating">
                        <Banner v-if="updateError" type="is-danger" :indefinite="true" class="mb-2" role="alert">
                            Something went wrong when assigning the
                            {{ itemName }}, please try again.
                            <br />
                            If the issue persists please contact support.
                        </Banner>
                        <Textbox
                            v-if="data.length > 0 && allowSearch"
                            v-model="searchValue"
                            placeholder="Type to search..."
                            :clearable="true"
                            class="mb-3"
                            @update:model-value="filterData()"
                        ></Textbox>
                        <ItemList v-if="filteredData.length > 0" :items="filteredData" class="list-selection">
                            <template #default="{ item: dataItem }">
                                <div
                                    class="tw-flex tw-items-center tw-border tw-px-2 tw-py-1 tw-rounded tw-cursor-pointer tw-text-gray-400 hover:tw-bg-gray-100"
                                    :class="{
                                        '!tw-bg-eventwise !tw-text-[rgba(255,255,255,.8)] tw-border-eventwise-600':
                                            selectedItemId && selectedItemId === dataItem.id,
                                    }"
                                    @click="selectItem(dataItem.id)"
                                >
                                    {{ dataItem.name }}
                                </div>
                            </template>
                        </ItemList>
                        <Banner
                            v-else
                            type="is-warning"
                            :class="{ 'mb-2': allowCreate }"
                            role="alert"
                            :closable="false"
                        >
                            <span v-if="errorLoadingData">
                                An error occurred whilst loading the data. Please try again later.
                            </span>
                            <span v-else>
                                You haven't setup any
                                {{ itemsName }} just yet
                            </span>
                            <span v-if="allowCreate" class="d-block">But don't worry, you can create one below</span>
                        </Banner>
                        <FormField v-if="allowCreate">
                            <Button icon="plus" :label="'Create New ' + itemName" @click="toggleCreate()"></Button>
                        </FormField>
                    </template>
                    <template v-else>
                        <Banner v-if="createError" type="is-danger" :indefinite="true" class="mb-2" role="alert">
                            Something went wrong when creating the
                            {{ itemName }}, please try again.
                            <br />
                            If the issue persists please contact support.
                        </Banner>
                        <FormField :label="'Give your new ' + itemName + ' a name'">
                            <Textbox
                                v-model="createData['name']"
                                class="create-name"
                                :class="{
                                    'border border-danger rounded': createValidationError !== null,
                                }"
                            ></Textbox>
                        </FormField>
                        <FormField
                            v-if="createValidationError !== null"
                            icon-left="alert-circle-icon"
                            :label="createValidationError"
                            class="text-danger"
                        ></FormField>
                    </template>
                </section>
            </div>
        </template>

        <template #footer>
            <FormField>
                <Button @click="cancelSelection()">Cancel</Button>
            </FormField>
            <FormField class="tw-ml-auto">
                <Button :disabled="selectedItemId === null && !isCreating" type="is-primary" @click="submitModal()">
                    Select
                </Button>
            </FormField>
        </template>
    </Modal>
</template>
<script>
import LoadingSpinner from '../../../../../js/components/widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import ItemList from '@/js/components/ItemList.vue';
import Modal from '@/js/components/Modal.vue';
import FormField from '@/js/components/widgets/FormField.vue';

export default {
    components: { LoadingSpinner, Button, Textbox, Banner, ItemList, Modal, FormField },
    props: {
        modelValue: {
            type: [String, Number],
            required: false,
        },
        endpoint: {
            type: String,
            default: '',
        },
        createEndpoint: {
            type: String,
            default: null,
        },
        allowCreate: {
            type: Boolean,
            default: false,
        },
        allowSearch: {
            type: Boolean,
            default: false,
        },
        modalTitle: {
            type: String,
            default: 'Select',
        },
        buttonTitle: {
            type: String,
            default: 'Select',
        },
        // button size, item meta, item icon, loadin gtext, expanded
        itemName: {
            type: String,
            default: 'item',
        },
        itemsName: {
            type: String,
            default: 'items',
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue', 'loaded', 'updated'],
    data() {
        return {
            isLoaded: false,
            isCreating: false,
            data: [],
            filteredData: [],
            selectedItem: null,
            selectedItemId: null,
            createError: false,
            updateError: false,
            createValidationError: null,
            createData: {},
            searchValue: '',
            errorLoadingData: false,
            initialValue: null,
            buttonText: this.buttonTitle,
        };
    },
    watch: {
        endpoint(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.reloadData();
            }
        },
        modelValue(newVal) {
            this.selectedItemId = newVal;
            if (!newVal) {
                this.selectedItem = null;
            }
        },
    },
    mounted() {
        this.reloadData(true);
        if (this.modelValue) {
            this.initialValue = this.modelValue;
            this.selectedItemId = this.initialValue;
        }
    },
    methods: {
        reloadData(initial = false) {
            axios
                .get(this.endpoint)
                .then(({ data }) => {
                    this.data = data;
                    this.filteredData = this.sortAlphabetically(data);
                    this.errorLoadingData = false;
                    if (initial) {
                        this.setFromValue();
                    }
                    this.$emit('loaded', data);
                })
                .catch(() => {
                    this.errorLoadingData = true;
                })
                .finally(() => {
                    this.isLoaded = true;
                });
        },
        sortAlphabetically(obj = null) {
            if (obj) {
                return obj.sort((a, b) => (this.getOptionTitle(a) > this.getOptionTitle(b) ? 1 : -1));
            } else {
                return obj;
            }
        },
        openModal() {
            this.resetModal();
            if (this.modelValue) {
                this.initialValue = this.modelValue;
            }
            this.$refs.modal.open();
        },
        close() {},
        setFromValue() {
            if (!this.selectedItemId) {
                return;
            }
            this.filteredData.forEach((element) => {
                if (element.id === this.selectedItemId) {
                    this.selectItem(element.id);
                }
            });
        },
        cancelSelection() {
            if (this.initialValue) {
                this.selectedItem = this.initialValue;
                this.$emit('update:modelValue', this.initialValue);
            }
            this.closeModal();
        },
        closeModal(force = false) {
            if (this.initialValue !== this.selectedItem) {
                this.$emit('updated');
            }
            if (force) {
                this.resetModal();
            } else {
                if (this.isCreating) {
                    this.toggleCreate();
                }
            }
            this.$refs.modal.close();
        },
        toggleCreate() {
            this.isCreating = !this.isCreating;
            this.buttonText = this.isCreating ? 'Create & Select' : this.buttonTitle;
        },
        selectItem(id) {
            this.selectedItem = id;
            this.$emit('update:modelValue', this.selectedItem);
        },
        submitModal() {
            if (this.isCreating) {
                this.createError = false;
                this.createValidationError = null;
                axios
                    .post(this.createEndpoint, this.createData)
                    .then(({ data }) => {
                        this.toggleCreate();
                        this.reloadData();
                        if (data && data.id) {
                            this.data.push(data);
                            this.selectItem(data.id);
                            this.closeModal();
                        }
                    })
                    .catch(() => {
                        this.createError = true;
                    });
                return;
            }
            this.$emit('update:modelValue', this.selectedItemId);
            this.closeModal();
            Eventbus.$emit('component-selector:updated');
        },
        resetModal() {
            this.createData = {};
            this.createError = false;
            this.createValidationError = null;
            if (this.isCreating) {
                this.isCreating = false;
            }
        },
        selectedItemName() {
            let name = 'Unknown';
            this.data.forEach((obj) => {
                if (obj.id === this.selectedItemId) {
                    name = this.getOptionTitle(obj);
                }
            });

            return name;
        },
        // This component is due to be refactored, and yes, I'll use an object to match the filters instead of the ugly replaces :P
        // Putting this in just to get it working for now
        filterData() {
            this.filteredData = this.sortAlphabetically(
                this.data.filter((option) => {
                    return (
                        this.getOptionTitle(option).toString().toLowerCase().indexOf(this.searchValue.toLowerCase()) >=
                        0
                    );
                })
            );
        },
        getOptionTitle(option) {
            return option.name;
        },
    },
};
</script>
<style lang="scss" scoped>
.modal-card {
    min-width: 350px;
}
.list-selection {
    max-height: 40vh;
    overflow-y: auto;
}
.field.text-danger {
    label.label {
        color: #ef4d56 !important;
    }
}
@media screen and (min-width: 967px) {
    .modal-card {
        max-width: 35vw;
    }
}
.modal-card-body {
    max-height: 60vh;
}
</style>
