<template>
    <div class="buefy-wrapper">
        <template v-if="!viewingUser">
            <div class="d-flex tw-mt-4">
                <h3>Team Members</h3>
                <div class="flex-fill"></div>
                <FormField class="tw-mr-2">
                    <Textbox v-model="searchQuery" placeholder="Search for a team member..." />
                </FormField>
                <Button type="is-primary" class="tw-flex tw-gap-2 tw-h-7" @click="inviteUser()">
                    <i class="mdi mdi-plus"></i>
                    Invite Member
                </Button>
                <FormField class="tw-ml-2" field-class="tw-flex tw-gap-1 tw-items-center">
                    <Button
                        :active="viewStyle === 'card'"
                        class="tw-flex tw-gap-2 tw-items-center"
                        @click="viewStyle = 'card'"
                    >
                        <i class="mdi mdi-cards-outline"></i>
                        Card
                    </Button>
                    <Button
                        :active="viewStyle === 'table'"
                        class="tw-flex tw-gap-2 tw-items-center"
                        @click="viewStyle = 'table'"
                    >
                        <i class="mdi mdi-table"></i>
                        Table
                    </Button>
                </FormField>
            </div>
            <div v-if="viewStyle === 'card'" class="team-member-cards">
                <div
                    v-for="teamUser in teamMembers"
                    :key="teamUser.id"
                    class="team-member-card tw-grid tw-gap-2 tw-rounded-md"
                >
                    <div class="tw-flex tw-items-center tw-px-2">
                        <User :user="teamUser" size="large" />
                        <div class="pl-2">
                            <span>{{ teamUser.name }}</span>
                            <small class="d-block text-muted">{{ teamUser.email }}</small>
                        </div>
                    </div>
                    <small v-if="teamUser.invite_pending" class="tw-my-auto text-center bg-light py-1">
                        <i class="mdi mdi-send"></i>
                        Invite Pending
                    </small>
                    <small v-else class="tw-my-auto text-center bg-light py-1">
                        <i class="mdi mdi-clock-outline"></i>
                        <template v-if="teamUser.last_active_at">
                            Last active {{ relativeTimestamp(teamUser.last_active_at, 'utc', false) }}
                        </template>
                        <template v-else>Never logged in</template>
                    </small>
                    <div class="team-member-section tw-px-3">
                        <FormField label="Group">
                            {{ teamUser.roles.map((r) => r.name).join(', ') }}
                        </FormField>
                        <Tag type="info" class="tw-w-fit tw-flex tw-gap-3 tw-items-center tw-mt-2">
                            <i class="mdi mdi-calendar"></i>
                            {{ teamUser.events.length }} {{ teamUser.events.length === 1 ? 'event' : 'events' }}
                        </Tag>
                        <Tag v-if="!isDirector(teamUser)" type="info" class="tw-w-fit tw-mt-1">
                            <i class="mdi mdi-tag"></i>
                            {{ teamUser.categories.length }}
                            {{ teamUser.categories.length === 1 ? 'subcategory' : 'subcategories' }}
                        </Tag>
                    </div>
                    <a href="#" class="team-member-actions tw-p-2 tw-mt-auto" @click.prevent="viewUser(teamUser.id)">
                        View Member
                    </a>
                </div>
            </div>
            <div v-else-if="viewStyle === 'table'" class="team-member-table py-4">
                <DataTable
                    :data="teamMembers"
                    :columns="[
                        { title: 'Name', field: 'name', sortable: true },
                        { title: 'Group', field: 'roles' },
                        { title: 'Events', field: 'events' },
                        { title: 'Subcategories', field: 'subcategories', centered: true },
                        { title: 'Last Active', field: 'last_active_at', sortable: true },
                    ]"
                    @select="(row) => viewUser(row.id)"
                >
                    <template #column(name)="{ row }">
                        <User :hide-name="false" :user="row" />
                    </template>
                    <template #column(roles)="{ row }">
                        {{ row.roles.map((r) => r.name).join(', ') }}
                    </template>
                    <template #column(events)="{ row }">
                        {{ row.events.length }}
                    </template>
                    <template #column(subcategories)="{ row }">
                        <span v-if="isDirector(row)">All</span>
                        <span v-else>{{ row.categories.length }}</span>
                    </template>
                    <template #column(last_active_at)="{ row }">
                        <span v-if="row.invite_pending">Invite Pending</span>
                        <span v-else-if="row.last_active_at">
                            {{ relativeTimestamp(row.last_active_at, 'utc', false) }}
                        </span>
                        <span v-else>Never logged in</span>
                    </template>
                </DataTable>
            </div>
            <InviteUser ref="inviteUserModal" @invited="userInvited" />
        </template>
        <UserPage
            v-else-if="viewingUser"
            :user-id="viewingUser"
            @back="
                viewingUser = null;
                getTeamMembers();
            "
        />
    </div>
</template>
<script>
import axios from 'axios';
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import User from '../../../widgets/User.vue';
import InviteUser from './Users/InviteUser.vue';
import UserPage from './Users/User.vue';
import Button from '@/js/components/controls/Button.vue';
import debounce from 'lodash/debounce';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tag from '@/js/components/Tag.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { relativeTimestamp } from '@/js/utils.js';

export default {
    components: {
        User,
        InviteUser,
        UserPage,
        Button,
        FormField,
        Textbox,
        Tag,
        DataTable,
    },
    data() {
        const settings = useSettingsStore();
        const store = useDataStore();

        return {
            currentAccount: store.get('account'),
            viewStyle: settings.account(store.get('account.id'), 'teamViewStyle')
                ? settings.account(store.get('account.id'), 'teamViewStyle')
                : 'card',
            settings: settings,
            teamMembers: [],
            viewingUser: null,
            searchQuery: null,
            sorts: {},
        };
    },
    watch: {
        viewStyle(newVal) {
            this.settings.setAccount(this.currentAccount.id, 'teamViewStyle', newVal ?? 'card');
        },
        searchQuery() {
            this.debouncedGetTeamMembers();
        },
    },
    mounted() {
        this.getTeamMembers();
    },
    methods: {
        relativeTimestamp,
        getTeamMembers() {
            const url = new URL(route('api.account.team'));
            if (this.searchQuery) {
                url.searchParams.append('query', this.searchQuery);
            }
            if (this.teamMembers && this.teamMembers.current_page) {
                url.searchParams.append('page', this.teamMembers.current_page);
            }
            url.searchParams.append(
                'sort',
                Object.keys(this.sorts)
                    .map((key) => `${key}=${this.sorts[key]}`)
                    .join(',')
            );
            axios.get(url.href).then(({ data }) => (this.teamMembers = data));
        },
        debouncedGetTeamMembers: debounce(function () {
            this.getTeamMembers();
        }, 500),
        inviteUser() {
            this.$refs.inviteUserModal.open();
        },
        userInvited(userId) {
            this.getTeamMembers();
            this.viewingUser = userId;
        },
        viewUser(userId) {
            this.viewingUser = userId;
        },
        isDirector(user) {
            return !!user.roles.find((role) => role.reference === 'director');
        },
    },
};
</script>
<style lang="scss" scoped>
.team-member-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 15px 0;

    & > .team-member-card {
        padding: 15px 0 0 0;
        background: white;
        min-height: 50px;
        min-width: min(325px, 100%);
        line-height: 100%;

        & > .d-flex {
            padding: 0 15px 15px 15px;
        }

        & > .team-member-actions {
            border-top: 1px solid rgba(black, 0.1);

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
