<template>
    <FormField :label="text ?? 'Upload'">
        <div
            class="tw-border-dashed tw-border tw-text-slate-500 tw-border-gray-200 tw-rounded tw-px-2 tw-py-3 tw-text-center tw-cursor-pointer hover:tw-border-slate-400 hover:tw-bg-slate-50 hover:tw-text-slate-600"
            @click="input.click()"
        >
            <div
                class="tw-flex tw-flex-col tw-items-center tw-justify-center"
                @drop="
                    (evt) => {
                        evt.preventDefault();
                        handleFiles(
                            [...evt.dataTransfer.items]
                                .filter((item) => item.kind === 'file')
                                .map((item) => item.getAsFile())
                        );
                    }
                "
                @dragover="handleDragOver"
            >
                <template v-if="!model">
                    <i class="mdi mdi-upload tw-text-2xl"></i>
                    Drop {{ multiple ? 'your files' : 'a file' }} here or click to upload
                </template>
                <div v-else-if="model.length" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
                    <i
                        class="mdi tw-text-2xl"
                        :class="{
                            'mdi-file-multiple': model.length > 1,
                            'mdi-file': model.length === 1,
                        }"
                    ></i>
                    <div class="tw-truncate tw-max-w-full">
                        {{ model.length > 1 ? model.length + ' files' : model[0].name }}
                    </div>

                    <span class="tw-text-xs tw-text-gray-500">{{ totalSize }}</span>
                </div>
                <Tooltip v-else position="bottom" :label="model.name">
                    <i class="mdi mdi-file tw-text-2xl"></i>
                    <div class="tw-truncate tw-max-w-full">{{ model.name }}</div>
                    <span class="tw-text-xs tw-text-gray-500">{{ totalSize }}</span>
                </Tooltip>
            </div>
            <input
                ref="input"
                class="tw-hidden"
                type="file"
                :multiple="multiple"
                :accept="accept"
                @change="(evt) => handleFiles([...evt.target.files])"
            />
        </div>
    </FormField>
</template>

<script setup>
import FormField from '@/js/components/widgets/FormField.vue';
import { ref, computed } from 'vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { filesize } from 'filesize';

const model = defineModel({
    type: [Object, Array],
    default: null,
});
const input = ref(null);
const props = defineProps({
    text: {
        type: String,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    accept: {
        type: Array,
        default: () => ['*'],
    },
});

const totalSize = computed(() => {
    if (!props.multiple) {
        return filesize(model.value.size ?? 0);
    }
    return filesize(model.value.reduce((acc, file) => acc + file.size, 0));
});

function handleDragOver(ev) {
    ev.preventDefault();
}
function handleFiles(files) {
    if (!files.length) {
        return;
    }
    if (!props.multiple) {
        model.value = files[0];
        return;
    }
    model.value = files;
}
</script>
