<template>
    <div class="ew-files">
        <Upload v-if="!disabled" text="Click to upload files" multiple @update:model-value="processUpload" />
        <ul v-if="files.length" class="tw-flex tw-flex-col">
            <li v-for="file in files" :key="file.id">
                <div class="tw-flex" @click="selectedFile = selectedFile === file.id ? null : file.id">
                    <i class="mdi mdi-file-word tw-mr-2 tw-mt-2"></i>
                    <div class="file-label">
                        <h5>{{ file.description ? file.description : file.file_name }}</h5>
                        <div class="file-meta">
                            <p class="text-muted">
                                {{ filesize(file.file_size) }}
                            </p>
                            <p v-if="file.description" class="text-muted">
                                {{ file.file_name }}
                            </p>
                            <p class="text-muted">Added {{ timeAgo(file.created_at) }}</p>
                        </div>
                    </div>
                </div>
                <div
                    class="inline-collapsible file-actions tw-flex tw-gap-2"
                    :class="{ 'open mt-2': selectedFile === file.id }"
                >
                    <Button type="is-danger" class="tw-ml-auto tw-flex tw-gap-2" @click="deleteFile(file)">
                        <i class="mdi mdi-delete"></i>
                        Delete
                    </Button>
                    <Button class="tw-ml-auto tw-flex tw-gap-2" @click="downloadFile(file)">
                        <i class="mdi mdi-download"></i>
                        Download File
                    </Button>
                </div>
            </li>
        </ul>
        <p v-else class="empty-label py-3">There haven't been any files uploaded just yet</p>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Upload from '@/js/components/controls/Upload.vue';
import { toast, timeAgo } from '@/js/utils.js';

export default {
    components: {
        Button,
        Upload,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        endpoint: {
            type: String,
            required: false,
        },
        completeEndpoint: {
            type: String,
            required: false,
        },
        files: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: ['updated'],
    data() {
        return {
            selectedFile: null,
        };
    },
    methods: {
        timeAgo,
        async processUpload(files) {
            if (!this.endpoint || !this.completeEndpoint) {
                toast('Error', 'Unable to upload file as no upload endpoint has been specified', 'error');
                return;
            }
            for (const file of files) {
                const uploadHandshake = await axios
                    .post(this.endpoint, {
                        name: file.name,
                        size: file.size,
                    })
                    .then((response) => {
                        return response.data;
                    });

                if (!uploadHandshake || !uploadHandshake.action) {
                    return;
                }

                const formData = new FormData();
                for (const key in uploadHandshake.inputs) {
                    formData.append(key, uploadHandshake.inputs[key]);
                }
                formData.append('key', uploadHandshake.destination);
                formData.append('file', file);
                await axios
                    .post(uploadHandshake.action, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then((response) => {
                        return response.data;
                    });

                await axios
                    .post(this.completeEndpoint, {
                        internalSignature: uploadHandshake['internalSignature'],
                    })
                    .then((response) => this.$emit('updated', response.data))
                    .finally(() => {
                        toast('Success', file.name + ' has been uploaded successfully', 'success');
                    });
            }
        },
        filesize(value) {
            if (value) {
                // value must be in KB
                if (value >= 1024000000) {
                    // Greater than 1GB - display in GB
                    return parseFloat(value / 1024000000).toFixed(1) + 'GB';
                } else if (value >= 1024000) {
                    // Greater than 1MB - display in MB
                    return parseFloat(value / 1024000).toFixed(1) + 'MB';
                }
                // display in KB
                return parseFloat(value / 1024).toFixed(1) + 'KB';
            }
        },
        downloadFile(file) {
            window.open(file.download_url, '_blank');
        },
        deleteFile(file) {
            axios.delete(route('account.files.delete', { fileupload: file.id })).then(() => {
                toast('Success', file.file_name + ' has been deleted successfully', 'success');
                this.$emit('updated');
            });
        },
    },
};
</script>
