<template>
    <div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />

        <div v-else class="buefy-wrapper">
            <Banner class="tw-mt-4 tw-py-4" type="is-primary">
                <p>
                    The outstanding report displays a list of all of your events and their respective figures split
                    between costs, sales, ticketing numbers.
                </p>
            </Banner>

            <div class="card tw-mt-4">
                <div class="table rounded mb-0">
                    <table class="w-100">
                        <thead>
                            <tr>
                                <th rowspan="2" style="border-right: 2px solid rgba(0, 0, 0, 0.1)">Event</th>
                                <th colspan="3" style="border-right: 2px solid rgba(0, 0, 0, 0.1)">Total Costs</th>
                                <th colspan="3" style="border-right: 2px solid rgba(0, 0, 0, 0.1)">Total Sales</th>
                                <th colspan="2">Tickets</th>
                            </tr>
                            <tr>
                                <td class="has-help-label" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    <Tooltip label="Payments assigned to supplier invoices marked as paid">
                                        Paid
                                    </Tooltip>
                                </td>
                                <td class="has-help-label" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    <Tooltip label="Payments assigned to supplier invoices not yet marked as paid">
                                        Pending
                                    </Tooltip>
                                </td>
                                <td class="has-help-label" style="border-right: 2px solid rgba(0, 0, 0, 0.1)">
                                    <Tooltip label="Total invoiced from supplier invoices minus the total paid">
                                        Outstanding
                                    </Tooltip>
                                </td>
                                <td class="has-help-label" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    <Tooltip label="Payments assigned to customer invoices marked as paid">
                                        Paid
                                    </Tooltip>
                                </td>
                                <td class="has-help-label" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    <Tooltip label="Payments assigned to customer invoices not yet marked as paid">
                                        Pending
                                    </Tooltip>
                                </td>
                                <td class="has-help-label" style="border-right: 2px solid rgba(0, 0, 0, 0.1)">
                                    <Tooltip label="Total invoiced from customer invoices minus the total paid">
                                        Outstanding
                                    </Tooltip>
                                </td>
                                <td class="has-help-label" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    <Tooltip label="Total number of tickets sold within the event">Sold</Tooltip>
                                </td>
                                <td class="has-help-label">
                                    <Tooltip label="Total number of tickets available within the event">
                                        Available
                                    </Tooltip>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in events" :key="item.id" class="table-row">
                                <td style="border-right: 2px solid rgba(0, 0, 0, 0.1)">{{ item.name }}</td>
                                <td class="text-center" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    {{ localise(totalCostPaidSum(item), 2, true) }}
                                </td>
                                <td class="text-center" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    {{ localise(totalCostPending(item), 2, true) }}
                                </td>
                                <td class="text-center" style="border-right: 2px solid rgba(0, 0, 0, 0.1)">
                                    {{ localise(totalCostOutstanding(item), 2, true) }}
                                </td>
                                <td class="text-center" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    {{ localise(totalSalesSum(item), 2, true) }}
                                </td>
                                <td class="text-center" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    {{ localise(totalSalesPending(item), 2, true) }}
                                </td>
                                <td class="text-center" style="border-right: 2px solid rgba(0, 0, 0, 0.1)">
                                    {{ localise(totalSalesOutstanding(item), 2, true) }}
                                </td>
                                <td class="text-center" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                                    {{ localise(item.ticket_orders_count, 0, false) }}
                                </td>
                                <td class="text-center">
                                    {{
                                        localise(
                                            item.ticket_sales_sum_tickets_available
                                                ? item.ticket_sales_sum_tickets_available
                                                : 0,
                                            0,
                                            false
                                        )
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '../../../Layout/Banner.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        LoadingSpinner,
        Banner,
        Tooltip,
    },
    data() {
        return {
            isLoading: false,
            eventCount: 0,
            categories: [],
            returnData: [],
            events: [],
            accountName: '',
            localData: [],
            visibleColumns: [],
            componentKey: 0,
        };
    },
    mounted() {
        this.reloadData();
    },
    methods: {
        reloadData() {
            this.isLoading = true;
            axios
                .get(route('api.account.reporting.accountAnalysis'))
                .then(({ data }) => {
                    this.accountName = data.accountName;
                    this.events = data.events;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        totalCostPaidSum(singleEvent) {
            const costDepts = singleEvent.departments.filter((item) => item.type === 1);
            return costDepts.map((dept) => dept.total_paid).reduce((a, b) => a + b, 0);
        },
        totalCostOutstanding(singleEvent) {
            const costDepts = singleEvent.departments.filter((item) => item.type === 1);
            const costTotalValueGross = costDepts.map((dept) => dept.total_invoiced_gross).reduce((a, b) => a + b, 0);
            return costTotalValueGross - this.totalCostPaidSum(singleEvent);
        },
        totalCostPending(singleEvent) {
            const costDepts = singleEvent.departments.filter((item) => item.type === 1);
            return costDepts.map((dept) => dept.total_pending).reduce((a, b) => a + b, 0);
        },
        totalSalesSum(singleEvent) {
            const revenueDepts = singleEvent.departments.filter((item) => item.type === 2);
            return revenueDepts.map((dept) => dept.total_paid).reduce((a, b) => a + b, 0);
        },
        totalSalesOutstanding(singleEvent) {
            const revenueDepts = singleEvent.departments.filter((item) => item.type === 2);
            const revenueTotalValueGross = revenueDepts
                .map((dept) => dept.total_invoiced_gross)
                .reduce((a, b) => a + b, 0);
            return revenueTotalValueGross - this.totalSalesSum(singleEvent);
        },
        totalSalesPending(singleEvent) {
            const revenueDepts = singleEvent.departments.filter((item) => item.type === 2);
            return revenueDepts.map((dept) => dept.total_pending).reduce((a, b) => a + b, 0);
        },
    },
};
</script>
<style lang="scss">
.table {
    table {
        vertical-align: middle;

        thead {
            th[colspan],
            td {
                text-align: center !important;

                &.has-help-label {
                    .tooltip-trigger {
                        cursor: help;
                        text-decoration: underline !important;
                        text-decoration-style: dashed !important;
                        opacity: 0.75;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
            th[colspan] {
                border-bottom: none !important;
            }
        }
    }
}

.banner-panel {
    padding: 15px;
    border-radius: 6px;
    background: white;

    .table-panel {
        padding: 15px;
        background: white;
        border-radius: 6px;
        margin: 15px 0;
        //overflow-x: scroll;
        //max-width: calc(100vw - 125px);
    }
    table {
        border-top: 1px solid lightgray;

        .table-event {
            width: 12%;
        }

        .header-row {
            height: 30px;
            vertical-align: middle;
            border-bottom: 2px solid lightgray;

            .table-column {
                width: 11%;
                text-align: center !important;
            }
        }

        .table-row {
            height: 30px;
            border-bottom: 1px solid lightgray;

            td {
                vertical-align: middle !important;
                padding-right: 20px;

                &.tickets {
                    text-align: center !important;
                    padding-right: 0;
                }

                &.currency {
                    text-align: right !important;
                }
            }
        }
    }
}
</style>
