<template>
    <div class="tw-grid tw-gap-3">
        <template v-if="isSigningUp">
            <i class="mdi mdi-lock tw-block tw-mx-auto"></i>
            <div class="tw-flex">
                <FormField>
                    <Textbox v-model="invite_code" placeholder="Invite Code" />
                </FormField>
                <Button
                    type="is-primary"
                    :disabled="isCheckingCode || invite_code.length === 0"
                    :loading="isCheckingCode"
                    @click="checkInviteCode()"
                >
                    Continue
                </Button>
            </div>
            <Banner type="is-info">
                <strong>Eventwise is currently invite-only</strong>
                <p>Enter a valid invite code above to create a new account</p>
            </Banner>
        </template>
        <Banner v-else-if="newUserData.invite_code !== '' && creationError === null" type="is-success">
            <strong>Welcome aboard, your invite was valid</strong>
            <br />
            You will be redirected in a few moments...
        </Banner>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        Button,
        FormField,
        Textbox,
        Banner,
    },
    props: {
        socialiteData: {
            type: Object,
        },
        socialiteProvider: {
            type: String,
        },
    },
    data() {
        return {
            isCheckingCode: false,
            isSigningUp: true,
            invite_code: '',
        };
    },
    methods: {
        checkInviteCode() {
            this.isCheckingCode = true;
            const codeRequest = new URLSearchParams({
                invite_code: this.invite_code,
            });
            axios
                .get(route('api.onboarding.check.inviteCode') + '?' + codeRequest.toString())
                .then(() => {
                    this.completeSignup();
                })
                .catch(() => {
                    this.invite_code = '';
                    setTimeout(() => {
                        this.isCheckingCode = false;
                    }, 500);
                });
        },
        completeSignup() {
            axios
                .post(route('api.onboarding.register.socialInvite'), {
                    provider: this.socialiteProvider,
                    invite_code: this.invite_code,
                    socialite: this.socialiteData,
                })
                .then(() => {
                    this.creationComplete = true;
                    this.isSigningUp = false;
                    setTimeout(() => {
                        window.location.href = route('onboarding');
                    }, 500);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.creationError = Object.values(error.response.data.errors).join(' ');
                    }
                });
        },
    },
};
</script>
