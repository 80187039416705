<template>
    <div class="buefy-wrapper tw-py-4">
        <Banner type="is-primary">
            <div class="d-flex align-items-center">
                <img src="@/images/email-notification.svg" style="max-height: 95px; margin-right: 25px" />
                <div class="flex-fill">
                    <h4 class="mb-2">Introduction to Contact Users</h4>
                    <p>
                        You can invite your contacts to join your Eventwise account. Once they accept your invitation,
                        they will be able to view any approved purchase orders, supplier invoices, and sales invoices
                        assigned to them. They will also have the ability to raise their own supplier invoices from
                        their approved purchase orders.
                        <br />
                        Your can manage the access for your contact users here and completely remove their access if
                        necessary.
                    </p>
                </div>
            </div>
        </Banner>
        <div v-if="contacts" class="d-flex align-items-center mt-2">
            <FormField>
                <Textbox v-model="searchQuery" placeholder="Search for a contact..." size="is-small" />
            </FormField>
        </div>
        <div v-if="contacts" class="mt-2">
            <DataTable
                :data="contacts.data"
                :columns="[
                    {
                        field: 'name',
                        title: 'Name',
                        sortable: true,
                    },
                    {
                        field: 'email',
                        title: 'Email Address',
                    },
                    {
                        field: 'suppliers',
                        title: 'Contact',
                    },
                    {
                        field: 'status',
                        title: 'Status',
                    },
                    {
                        field: 'actions',
                        title: '',
                    },
                ]"
            >
                <template #column(name)="{ row }">
                    <User :user="row" :hide-name="false" />
                </template>
                <template #column(email)="{ row }">
                    {{ row.email }}
                </template>
                <template #column(suppliers)="{ row }">
                    <a v-for="supplier in row.suppliers" :key="supplier.id" href="#" @click="viewSupplier(supplier)">
                        {{ supplier.name }}
                    </a>
                </template>
                <template #column(status)="{ row }">
                    <Tag v-if="row.invite_pending" type="info">Invite Pending</Tag>
                    <Tag v-else type="success">Active</Tag>
                </template>
                <template #column(actions)="{ row }">
                    <Button
                        v-if="row.invite_pending"
                        class="tw-mr-1"
                        :disabled="resentInvites.includes(row.id)"
                        @click="resendInvite(row)"
                    >
                        Resend Invite
                    </Button>
                    <Button type="is-danger" @click="revokeAccess(row)">Revoke Access</Button>
                </template>
                <template #empty>
                    <div class="empty-label tw-w-full">There are no contact users to show</div>
                </template>
                <template #bottom-left>
                    <div class="d-flex align-items-center">
                        <Select v-model="perPage" class="tw-mr-2">
                            <option :value="10">10</option>
                            <option :value="25">25</option>
                            <option :value="40">40</option>
                        </Select>
                        items per page
                        <span class="text-muted ml-2">
                            ({{ contacts.total === 1 ? '1 item in total' : contacts.total + ' items in total' }})
                        </span>
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>
<script>
import Banner from '../../../Layout/Banner.vue';
import User from '../../../widgets/User.vue';
import Button from '@/js/components/controls/Button.vue';
import debounce from 'lodash/debounce';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tag from '@/js/components/Tag.vue';
import Select from '@/js/components/controls/Select.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Banner,
        User,
        Button,
        FormField,
        Textbox,
        Tag,
        Select,
        DataTable,
    },
    data() {
        return {
            isLoading: false,
            contacts: null,
            searchQuery: null,
            perPage: 10,
            sorts: {},
            resentInvites: [],
        };
    },
    watch: {
        searchQuery() {
            this.debouncedGetContacts();
        },
    },
    mounted() {
        this.getContacts();
    },
    methods: {
        getContacts(newPage = null) {
            this.isLoading = true;
            const url = new URL(route('api.account.team.contacts'));
            url.searchParams.set('perPage', this.perPage);
            if (this.searchQuery) {
                url.searchParams.append('query', this.searchQuery);
            }
            if (newPage) {
                url.searchParams.append('page', newPage);
            } else if (this.contacts && this.contacts.current_page) {
                url.searchParams.append('page', this.contacts.current_page);
            }
            url.searchParams.append(
                'sort',
                Object.keys(this.sorts)
                    .map((key) => `${key}=${this.sorts[key]}`)
                    .join(',')
            );
            axios.get(url.href).then(({ data }) => {
                this.contacts = data;
                this.isLoading = false;
            });
        },
        debouncedGetContacts: debounce(function () {
            this.getContacts();
        }, 500),
        viewSupplier(supplier) {
            Eventbus.$emit('view:supplier', supplier);
        },
        revokeAccess(user) {
            swal.fire({
                title: 'Revoke Access',
                text: 'Are you sure that you want to remove this contact user from your account? They will no longer be able to access your account and will need to be re-invited in order to regain access.',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#4EA5D9',
                confirmButtonText: 'Revoke Access',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.isLoading = true;
                axios
                    .delete(
                        route('api.account.team.contacts.revoke', {
                            user: user.id,
                        })
                    )
                    .then(() => {
                        this.getContacts();
                        toast('Sucess', user.name + ' has been removed from your account.', 'success');
                    })
                    .finally(() => (this.isLoading = false));
            });
        },
        resendInvite(user) {
            if (!user.invite_pending) {
                return;
            }
            this.isLoading = true;
            axios
                .post(
                    route('api.account.team.contacts.resend', {
                        user: user.id,
                    })
                )
                .then(() => {
                    this.resentInvites.push(user.id);
                    toast('Success', 'The invitation has been resent to ' + user.name + '.', 'success');
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
