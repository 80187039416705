<template>
    <ul class="tw-flex tw-flex-col">
        <li v-for="item in items" :key="item[itemKey]">
            <slot :item="item">{{ item[titleKey] }}</slot>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        itemKey: {
            type: String,
            default: 'id',
        },
        titleKey: {
            type: String,
            default: 'name',
        },
    },
};
</script>
