<template>
    <DraggableModal ref="shortcodePickerModal" :title="'{{ shortcodes }}'" :z-index="5000" style="max-width: 500px">
        <div class="buefy-wrapper">
            <Tooltip v-for="(code, key) in availableCodes" :key="key" :label="code['description']">
                <Tag class="m-1 cursor-pointer" type="primary" @click="$emit('insert', `{{ ${key} }}`)">
                    {{ code.name }}
                </Tag>
            </Tooltip>
        </div>
    </DraggableModal>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import DraggableModal from '../DraggableModal.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';

export default {
    components: {
        DraggableModal,
        Tooltip,
        Tag,
    },
    props: {
        shortcodes: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['insert'],
    data() {
        const store = useDataStore();

        return {
            store: store,
            availableCodes: {},
        };
    },
    watch: {
        shortcodes: {
            handler(newVal) {
                this.availableCodes = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        Eventbus.$on('shortcodePicker', () => this.$refs.shortcodePickerModal.open());
    },
};
</script>
