<template>
    <div class="spa-page-wrapper">
        <Page title="Supplier Portal">
            <SupplierDetails
                v-if="isLoaded"
                :supplier-id="currentSupplier"
                :account-id="currentAccount.id"
            ></SupplierDetails>
        </Page>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Page from '../Page.vue';
import SupplierDetails from './Account/Contacts/SupplierDetails.vue';

export default {
    components: {
        Page,
        SupplierDetails,
    },
    data() {
        const store = useDataStore();
        return {
            isLoaded: false,
            accounts: [],
            suppliers: [],
            currentAccount: store.get('account'),
            currentSupplier: null,
            currentUser: store.get('current_user'),
        };
    },
    mounted() {
        this.fetchAllSuppliers();
    },
    methods: {
        fetchAllSuppliers() {
            axios
                .get(
                    route('portal.portalsuppliers', {
                        user: this.currentUser.id,
                    })
                )
                .then(({ data }) => {
                    if (data.length > 0) {
                        this.suppliers = data;
                        this.currentSupplier = this.suppliers[0];
                    }
                })
                .finally(() => (this.isLoaded = true));
        },
    },
};
</script>
