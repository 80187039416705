<template>
    <FormField :label="label" class="eventwise-stream-type-field" field-class="tw-flex tw-gap-2">
        <Tooltip label="A standard revenue stream, with the ability to create revenue entries">
            <Button :active="streamType === 3" :disabled="disabled" @click="streamType = 3">Revenue</Button>
        </Tooltip>
        <Tooltip label="Grants the ability to create tickets as well as revenue entries against this revenue stream">
            <Button
                :active="streamType === 0"
                :disabled="disabled || disableTickets"
                class="tw-flex tw-gap-2 tw-items-center"
                @click="streamType = 0"
            >
                <i class="mdi mdi-ticket"></i>
                Tickets
            </Button>
        </Tooltip>
        <Tooltip label="A standard revenue stream, with the ability to create revenue entries">
            <Button
                :active="streamType === 2"
                :disabled="disabled"
                class="tw-flex tw-gap-2 tw-items-center"
                @click="streamType = 2"
            >
                <i class="mdi mdi-store"></i>
                Traders
            </Button>
        </Tooltip>
        <Tooltip
            label="Allows budgeting for estimated revenue-per-head, against the total tickets available and sold across your event"
        >
            <Button
                :active="streamType === 1"
                :disabled="disabled"
                class="tw-flex tw-gap-2 tw-items-center"
                @click="streamType = 1"
            >
                <i class="mdi mdi-beer"></i>
                Bars
            </Button>
        </Tooltip>
    </FormField>
</template>
<script>
import FormField from '@/js/components/widgets/FormField.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        FormField,
        Button,
        Tooltip,
    },
    props: {
        modelValue: {
            type: Number,
            default: 3,
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableTickets: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            streamType: 0,
        };
    },
    watch: {
        value: function (val) {
            this.streamType = val;
        },
        streamType() {
            this.emitValue();
        },
    },
    mounted() {
        this.streamType = this.modelValue;
        this.emitValue();
    },
    methods: {
        emitValue() {
            this.$emit('update:modelValue', this.streamType);
        },
    },
};
</script>
