<template>
    <Modal
        ref="modal"
        title="Delete Subcategory"
        :loading="isLoading"
        paddingless
        size="is-xlarge"
        @closed="resetData()"
    >
        <p class="px-3 pt-3 pb-2">You may only delete a subcategory when there are no entries associated with it.</p>
        <div v-if="category" class="px-4">
            <div class="row align-items-center mb-1">
                <div class="col-1">
                    <i
                        class="mdi h3"
                        :class="{
                            'mdi-alert-circle text-danger': category.budget_entries_count,
                            'mdi-check-circle text-success': !category.budget_entries_count,
                        }"
                    ></i>
                </div>
                <div class="col-11 h5">
                    {{ category.budget_entries_count }} Budget Entr{{
                        category.budget_entries_count === 1 ? 'y' : 'ies'
                    }}
                    <small
                        v-if="category.budget_entries_count && Auth.can('delete budget entries')"
                        class="d-block text-muted"
                    >
                        <div v-if="!isDeletingBudgetEntries">
                            You must
                            <a href="#" @click.prevent="purgeBudgetEntries()">purge these entries</a>
                            to continue deleting this subcategory
                        </div>
                        <div v-else class="d-flex align-items-center mt-1">
                            <div
                                class="spinner-border mr-2"
                                role="status"
                                style="border-width: 1px; height: 8px; width: 8px; color: gray"
                            ></div>
                            <small>Deleting budget entries...</small>
                        </div>
                    </small>
                </div>
            </div>
            <div class="row align-items-center mb-1">
                <div class="col-1">
                    <i
                        class="mdi h3"
                        :class="{
                            'mdi-alert-circle text-danger': category.purchase_orders_count,
                            'mdi-check-circle text-success': !category.purchase_orders_count,
                        }"
                    ></i>
                </div>
                <div class="col-11 h5">
                    {{ category.purchase_orders_count }} Purchase Order{{
                        category.purchase_orders_count === 1 ? '' : 's'
                    }}
                    <small
                        v-if="category.purchase_orders_count && Auth.can('delete purchase orders')"
                        class="d-block text-muted"
                    >
                        <div v-if="!isDeletingPurchaseOrders">
                            You must
                            <a href="#" @click.prevent="purgePurchaseOrders()">purge these entries</a>
                            to continue deleting this subcategory
                        </div>
                        <div v-else class="d-flex align-items-center mt-1">
                            <div
                                class="spinner-border mr-2"
                                role="status"
                                style="border-width: 1px; height: 8px; width: 8px; color: gray"
                            ></div>
                            <small>Deleting purchase orders...</small>
                        </div>
                    </small>
                </div>
            </div>
            <div class="row align-items-center mb-1">
                <div class="col-1">
                    <i
                        class="mdi h3"
                        :class="{
                            'mdi-alert-circle text-danger': category.revenue_entries_count,
                            'mdi-check-circle text-success': !category.revenue_entries_count,
                        }"
                    ></i>
                </div>
                <div class="col-11 h5">
                    {{ category.revenue_entries_count }} Revenue Entr{{
                        category.revenue_entries_count === 1 ? 'y' : 'ies'
                    }}
                    <small
                        v-if="category.revenue_entries_count && Auth.can('delete revenue entries')"
                        class="d-block text-muted"
                    >
                        <div v-if="!isDeletingRevenueEntries">
                            You must
                            <a href="#" @click.prevent="purgeRevenueEntries()">purge these entries</a>
                            to continue deleting this subcategory
                        </div>
                        <div v-else class="d-flex align-items-center mt-1">
                            <div
                                class="spinner-border mr-2"
                                role="status"
                                style="border-width: 1px; height: 8px; width: 8px; color: gray"
                            ></div>
                            <small>Deleting revenue entries...</small>
                        </div>
                    </small>
                </div>
            </div>
            <div class="row align-items-center mb-4">
                <div class="col-1">
                    <i
                        class="mdi h3"
                        :class="{
                            'mdi-alert-circle text-danger': category.ticket_types_count,
                            'mdi-check-circle text-success': !category.ticket_types_count,
                        }"
                    ></i>
                </div>
                <div class="col-11 h5">
                    {{ category.ticket_types_count }} Ticket Type{{ category.ticket_types_count === 1 ? '' : 's' }}
                    <small v-if="category.ticket_types_count && Auth.can('delete tickets')" class="d-block text-muted">
                        <div v-if="!isDeletingTickets">
                            You must
                            <a href="#" @click.prevent="purgeTickets()">purge these tickets</a>
                            to continue deleting this subcategory
                        </div>
                        <div v-else class="d-flex align-items-center mt-1">
                            <div
                                class="spinner-border mr-2"
                                role="status"
                                style="border-width: 1px; height: 8px; width: 8px; color: gray"
                            ></div>
                            <small>Deleting tickets...</small>
                        </div>
                    </small>
                </div>
            </div>
        </div>
        <template #footer>
            <Button type="is-success" :disabled="!canDelete" @click="confirmDeletion()">Delete Subcategory</Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: {
        Modal,
        Button,
    },
    emits: ['delete'],
    data() {
        return {
            isLoading: false,
            isDeletingBudgetEntries: false,
            isDeletingRevenueEntries: false,
            isDeletingPurchaseOrders: false,
            isDeletingTickets: false,
            category: null,
        };
    },
    computed: {
        canDelete() {
            if (!this.category) {
                return false;
            }
            return (
                !this.category.budget_entries_count &&
                !this.category.purchase_orders_count &&
                !this.category.revenue_entries_count &&
                !this.category.ticket_types_count
            );
        },
    },
    methods: {
        resetData() {
            this.isLoading = false;
            this.isDeletingBudgetEntries = false;
            this.isDeletingRevenueEntries = false;
            this.isDeletingPurchaseOrders = false;
            this.isDeletingTickets = false;
            this.category = null;
        },
        open(departmentId, categoryId) {
            this.isLoading = true;
            this.$refs.modal.open();
            axios
                .get(
                    route('api.account.departments.categories.get', {
                        department: departmentId,
                        category: categoryId,
                    })
                )
                .then(({ data }) => (this.category = data))
                .finally(() => (this.isLoading = false));
        },
        close() {
            this.$refs.modal.close();
        },
        async confirmPurge() {
            const { value: confirmation } = await swal.fire({
                title: 'Are you sure?',
                text: 'This action will permanently delete all current and historical entries, invoices, and tickets in this subcategory and is irreversible, are you sure?',
                showCancelButton: true,
            });
            if (!confirmation) {
                return false;
            }
            return true;
        },
        confirmDeletion() {
            if (!this.canDelete) {
                return;
            }
            this.close();
            this.$emit('delete', this.category.id);
        },
        async purgeBudgetEntries() {
            const confirmation = await this.confirmPurge();
            if (!confirmation) {
                return;
            }
            this.isDeletingBudgetEntries = true;
            axios
                .delete(
                    route('api.account.departments.categories.purge.budgetEntries', {
                        department: this.category.department_id,
                        category: this.category.id,
                    })
                )
                .then(() => this.open(this.category.department_id, this.category.id))
                .finally(() => (this.isDeletingBudgetEntries = false));
        },
        async purgePurchaseOrders() {
            const confirmation = await this.confirmPurge();
            if (!confirmation) {
                return;
            }
            this.isDeletingPurchaseOrders = true;
            axios
                .delete(
                    route('api.account.departments.categories.purge.purchaseOrders', {
                        department: this.category.department_id,
                        category: this.category.id,
                    })
                )
                .then(() => this.open(this.category.department_id, this.category.id))
                .finally(() => (this.isDeletingPurchaseOrders = false));
        },
        async purgeRevenueEntries() {
            const confirmation = await this.confirmPurge();
            if (!confirmation) {
                return;
            }
            this.isDeletingRevenueEntries = true;
            axios
                .delete(
                    route('api.account.departments.categories.purge.revenueEntries', {
                        department: this.category.department_id,
                        category: this.category.id,
                    })
                )
                .then(() => this.open(this.category.department_id, this.category.id))
                .finally(() => (this.isDeletingRevenueEntries = false));
        },
        async purgeTickets() {
            const confirmation = await this.confirmPurge();
            if (!confirmation) {
                return;
            }
            this.isDeletingTickets = true;
            axios
                .delete(
                    route('api.account.departments.categories.purge.tickets', {
                        department: this.category.department_id,
                        category: this.category.id,
                    })
                )
                .then(() => this.open(this.category.department_id, this.category.id))
                .finally(() => (this.isDeletingTickets = false));
        },
    },
};
</script>
