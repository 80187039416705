<template>
    <Modal ref="budgetmarkup" title="Budget Markup" size="is-large">
        <div v-if="budgetEntry" class="tw-grid tw-gap-3">
            <FormField label="Entry Name">
                <Textbox v-model="budgetEntry.name" class="tw-w-full" disabled />
            </FormField>
            <div class="tw-flex">
                <FormField label="Raised By" class="tw-mr-2">
                    <Textbox v-model="user.name" disabled />
                </FormField>
                <FormField class="tw-mx-2" label="Budgeted Cost">
                    <Textbox v-model="budgetEntry.budgeted_cost" disabled />
                </FormField>
                <FormField class="tw-ml-5" label="Markup Amount">
                    <PercentageValue
                        v-model="markupValue"
                        class="markup-control"
                        :base-value="budgetEntry.budgeted_cost"
                        :default-input-value="defaultMarkupPercentage"
                        :step="0.0001"
                        size="is-form"
                        currency
                    />
                    <Checkbox v-model="applyToFixed" class="tw-mt-2">Apply markup to fixed amount</Checkbox>
                </FormField>
            </div>
            <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                <FormField label="Cost Department Name">
                    <Textbox v-model="entryDepartment.name" disabled />
                </FormField>
                <FormField label="Cost Department Category">
                    <Textbox :model-value="entryCategory.name" disabled />
                </FormField>
            </div>
            <div class="tw-grid tw-gap-3">
                <div class="tw-flex">
                    <FormField v-if="!creatingDepartment" label="Revenue Streams" class="tw-w-full">
                        <Select
                            v-model="markupDept"
                            placeholder="Select a Revenue Stream"
                            @update:model-value="markupDeptChange"
                        >
                            <option
                                v-for="item in revenueStreams"
                                id="line-item"
                                :key="item.id"
                                :value="item.department_id"
                            >
                                {{ item.name }}
                            </option>
                        </Select>
                    </FormField>
                    <FormField v-else label="New Revenue Stream" class="tw-w-full">
                        <Textbox v-model="markupDepartmentName" maxlength="255" />
                    </FormField>
                    <Tooltip
                        class="tw-ml-1"
                        position="left"
                        :label="
                            creatingDepartment
                                ? 'Switch to selecting an existing Revenue Stream'
                                : 'Switch to creating a new Revenue Stream'
                        "
                    >
                        <Button class="tw-mt-4" type="is-primary" @click="newDepartment">
                            {{ creatingDepartment ? 'Select' : 'New' }}
                        </Button>
                    </Tooltip>
                </div>
                <div class="tw-flex">
                    <FormField v-if="!creatingCategory" label="Revenue Categories" class="tw-w-full">
                        <Select
                            v-model="markupCat"
                            placeholder="Select a Revenue Category"
                            :disabled="!markupDept"
                            @update:model-value="(v) => (markupCat = v)"
                        >
                            <option v-for="item in categories" id="line-item" :key="item.id" :value="item.id">
                                {{ item.name }}
                            </option>
                        </Select>
                    </FormField>
                    <FormField v-else label="New Revenue Category" class="tw-w-full">
                        <Textbox v-model="markupCategoryName" maxlength="255" />
                    </FormField>
                    <Tooltip
                        position="left"
                        :label="
                            creatingCategory
                                ? 'Switch to creating a new subcategory'
                                : 'Switch to selecting an existing subcategory'
                        "
                        class="tw-ml-1"
                    >
                        <Button v-if="!hideCategoryButton" class="tw-mt-4" type="is-primary" @click="newCategory">
                            {{ creatingCategory ? 'Select' : 'New' }}
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
        <template #footer>
            <Button :disabled="!isValid" type="is-success" @click="createRevenue">Create</Button>
        </template>
    </Modal>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import PercentageValue from '@/js/components/widgets/PercentageValue.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';

export default {
    components: {
        PercentageValue,
        Modal,
        Button,
        FormField,
        Textbox,
        Select,
        Tooltip,
        Checkbox,
    },
    emits: ['created'],
    data() {
        const store = useDataStore();
        return {
            store: store,
            budgetEntry: null,
            markupValue: 0,
            markupDept: 0,
            markupCat: 0,
            markupDepartmentName: '',
            markupCategoryName: '',
            applyToFixed: true,
            creatingDepartment: false,
            creatingCategory: false,
            hideCategoryButton: false,
            defaultMarkupPercentage: 0,
        };
    },
    computed: {
        user() {
            return this.store.get('current_user');
        },
        allDepartments() {
            return Object.values(this.store.get('account.departments'));
        },
        revenueStreams() {
            return Object.values(this.store.get('current_event.revenue_streams')).filter(
                (stream) => stream.stream_type !== 0
            );
        },
        entryDepartment() {
            if (!this.budgetEntry) {
                return null;
            }
            return this.store.get('account.departments.' + this.budgetEntry.category.department_id);
        },
        categories() {
            if (this.markupDept) {
                return this.revenueStreams.find((rs) => rs.department_id === this.markupDept)?.categories ?? [];
            }
            return this.store.get('current_event.categories');
        },
        allCategories() {
            const accountDepts = Object.values(this.store.get('account.departments'));
            const allCats = [];
            accountDepts.forEach((dept) => {
                const deptCats = Object.values(this.store.get('account.departments.' + dept.id + '.categories'));
                deptCats.forEach((deptCat) => {
                    allCats.push(deptCat);
                });
            });
            return allCats;
        },
        entryCategory() {
            if (!this.budgetEntry) {
                return null;
            }
            return this.store.get('current_event.categories.' + this.budgetEntry.category_id);
        },
        event() {
            if (!this.budgetEntry) {
                return null;
            }
            if (this.budgetEntry.event_id) {
                return this.store.get('account.events.' + this.budgetEntry.event_id);
            }
            return null;
        },
        isValid() {
            return (
                (this.markupDepartmentName || this.markupDept) &&
                (this.markupCategoryName || this.markupCat) &&
                this.markupValue
            );
        },
    },
    watch: {
        markupValue(newVal) {
            if (newVal > 0) {
                return;
            }
            this.markupValue = 0;
        },
    },
    methods: {
        open(payload) {
            this.budgetEntry = payload['donorEntry'];
            if (payload.donorEntry.category.default_markup_destination) {
                this.defaultMarkupPercentage = payload.donorEntry.category.default_markup_amount;
                const revenueStream = this.revenueStreams.find((r) => {
                    return !!Object.values(r.categories ?? {}).find(
                        (c) => c.id === payload.donorEntry.category.default_markup_destination
                    );
                });
                if (revenueStream) {
                    // Unfortunately because these get set before vue has time to work out the valid options
                    // we need to delay the setting of the markupDept and markupCat
                    setTimeout(() => {
                        this.markupDept = revenueStream.department_id;
                        this.markupDepartmentName = revenueStream.name;
                        setTimeout(() => {
                            this.markupCat = payload.donorEntry.category.default_markup_destination;
                            this.markupCategoryName = Object.values(revenueStream.categories).find(
                                (c) => c.id === payload.donorEntry.category.default_markup_destination
                            ).name;
                        }, 100);
                    }, 100);
                }
            }
            this.markupValue = this.store.get('current_event.default_markup') * this.budgetEntry.budgeted_cost;
            this.reset();
            this.$refs.budgetmarkup.open();
        },
        close() {
            this.reset();
            this.$refs.budgetmarkup.close();
        },
        reset() {
            this.markupValue = 0;
            this.markupDept = 0;
            this.markupCat = 0;
            this.creatingDepartment = false;
            this.creatingCategory = false;
            this.applyToFixed = true;
        },
        markupDeptChange(value) {
            this.markupDept = value;
            this.markupDepartmentName = this.revenueStreams.filter(
                (rs) => rs.department_id === this.markupDept
            )[0].name;
            this.markupCat = null;
        },
        newDepartment() {
            this.creatingDepartment = !this.creatingDepartment;
            if (this.creatingDepartment) {
                this.creatingCategory = true;
                this.hideCategoryButton = true;
            } else {
                this.hideCategoryButton = false;
            }
            this.markupCat = null;
            this.markupDept = null;
            this.markupCategoryName = '';
            this.markupDepartmentName = '';
        },
        newCategory() {
            this.creatingCategory = !this.creatingCategory;
            this.markupCat = null;
            this.markupCategoryName = '';
        },
        errorMessage(text) {
            swal.fire('Error', text, 'error');
        },
        createRevenue() {
            // create formData
            const formData = new FormData();
            if (this.creatingDepartment) {
                this.markupDept = null;
                if (
                    Object.values(this.allDepartments).filter(
                        (dept) => dept.name.toLowerCase() === this.markupDepartmentName.toLowerCase()
                    ).length
                ) {
                    this.errorMessage('You cannot create a department name if the same already exists');
                    return;
                }
                formData.append('department_name', this.markupDepartmentName);
            } else {
                formData.append('department_id', this.markupDept);
                formData.append('department_name', this.markupDepartmentName);
            }
            if (this.creatingCategory) {
                this.markupCat = null;
                if (
                    this.allCategories.filter(
                        (dept) => dept.name.toLowerCase() === this.markupCategoryName.toLowerCase()
                    ).length
                ) {
                    this.errorMessage('You cannot create a category name if the same already exists');
                    return;
                }
                formData.append('category_name', this.markupCategoryName);
            } else {
                this.markupCategoryName = Object.values(this.categories).filter(
                    (category) => category.id === this.markupCat
                )[0].name;
                formData.append('category_id', this.markupCat);
                formData.append('category_name', this.markupCategoryName);
            }

            formData.append('markup_value', this.markupValue);
            formData.append('apply_to_fixed', this.applyToFixed ? 1 : 0);
            axios.post(route('api.account.event.entries.markup', { entry: this.budgetEntry.id }), formData).then(() => {
                this.$emit('created');
                this.close();
            });
        },
    },
};
</script>

<style scoped lang="scss">
.markup-control {
    margin: 0;
    width: 175px;
}
</style>
