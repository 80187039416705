<template>
    <FormField :label="label ? label : null" class="eventwise-currency-field" @click="onClick($event)">
        <NumberInput
            v-model="amount"
            :currency="localise(0, 0, true).replace('0', '')"
            :min
            :step
            :max
            :disabled
            class="eventwise-currency-input"
            dp="2"
            @update:model-value="emitValue()"
        />
    </FormField>
</template>
<script>
import FormField from '@/js/components/widgets/FormField.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';

export default {
    components: { FormField, NumberInput },
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        size: {
            type: String,
            default: '',
        },
        controls: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        label: {
            type: [String, Boolean],
            default: false,
        },
        preventDefault: {
            type: Boolean,
            default: true,
        },
        stopPropogation: {
            type: Boolean,
            default: true,
        },
        min: {
            type: [Number, String],
            default: null,
        },
        step: {
            type: [Number, String],
            default: 1,
        },
        max: {
            type: [Number, String],
            default: null,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            amount: 0,
        };
    },
    watch: {
        modelValue: function (val) {
            const amount = parseFloat(val);
            if (val === '-') {
                return;
            } else if (isNaN(amount)) {
                this.amount = 0;
            } else {
                this.amount = amount;
            }
        },
    },
    mounted() {
        this.amount = parseFloat(this.modelValue).toFixed(2);
        this.emitValue();
    },
    methods: {
        emitValue() {
            if (this.amount === '-') {
                return;
            }
            this.$emit('update:modelValue', parseFloat(this.amount));
        },
        onClick(e) {
            if (this.stopPropogation) {
                e.stopPropagation();
            }
            if (this.preventDefault) {
                e.preventDefault();
            }
        },
    },
};
</script>
<style scoped>
.eventwise-currency-input {
    input {
        text-align: right;
    }
    &:not(.is-expanded) {
        input {
            max-width: 125px;
        }
    }
}
</style>
