<template>
    <div class="tw-flex tw-items-center tw-justify-end tw-mb-2">
        <Button
            @click="
                editSetting({
                    key: '',
                    value: '',
                })
            "
        >
            <i class="mdi mdi-plus"></i>
            Create a setting
        </Button>
    </div>
    <DataTable
        ref="table"
        :endpoint="route('api.admin.settings')"
        :columns="[
            {
                title: 'ID',
                field: 'id',
            },
            {
                title: 'Key',
                field: 'key',
            },
            {
                title: 'Value',
                field: 'value',
            },
            {
                title: 'Created',
                field: 'created_at',
            },
            {
                title: 'Updated',
                field: 'updated_at',
            },
            {
                title: '',
                field: 'actions',
                class: '!tw-text-right',
            },
        ]"
        centered
    >
        <template #column(created_at)="{ row }">
            {{ DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS) }}
        </template>
        <template #column(updated_at)="{ row }">
            {{ DateTime.fromISO(row.updated_at).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS) }}
        </template>
        <template #column(actions)="{ row }">
            <div class="tw-flex tw-justify-end tw-gap-1">
                <Tooltip label="Edit">
                    <Button @click="editSetting(row)">
                        <i class="mdi mdi-pencil"></i>
                    </Button>
                </Tooltip>
                <Tooltip label="Remove">
                    <Button type="is-danger" @click="removeSetting(row)">
                        <i class="mdi mdi-close"></i>
                    </Button>
                </Tooltip>
            </div>
        </template>
        <template #empty>
            <div class="empty-label">There are no settings to show</div>
        </template>
    </DataTable>
    <Modal ref="editModal" title="Update Setting" @closed="editingSetting = null">
        <FormField label="Key">
            <Textbox v-model="editingSetting.key" />
        </FormField>
        <FormField label="Value">
            <Textbox v-model="editingSetting.value" />
        </FormField>
        <template #footer>
            <Button type="is-success" :disabled="!isValid" @click="saveSetting">Save</Button>
        </template>
    </Modal>
</template>
<script setup>
import Button from '@/js/components/controls/Button.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Modal from '@/js/components/Modal.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { DateTime } from 'luxon';
import { computed, ref } from 'vue';
import Swal from 'sweetalert2';

const editingSetting = ref(null);
const editModal = ref(null);
const table = ref(null);

const editSetting = (setting) => {
    editingSetting.value = { ...setting };
    editModal.value.open();
};

const saveSetting = async () => {
    if (!editingSetting.value) {
        return;
    }
    await axios.put(route('api.admin.settings.update'), editingSetting.value);
    editModal.value.close();
    table.value.refreshData();
};

const removeSetting = async (setting) => {
    const { value: confirmation } = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to permanently remove this setting',
        showCancelButton: true,
    });

    if (!confirmation) {
        return;
    }
    await axios.delete(route('api.admin.settings.delete', { key: setting.key }));
    table.value.refreshData();
};

const isValid = computed(() => {
    return editingSetting.value && editingSetting.value.key && editingSetting.value.value;
});
</script>
