<template>
    <div class="eventwise-inline-edit">
        <FormField v-if="editable" :label="inputLabel">
            <Button
                v-if="!isEditing"
                :type="buttonType"
                class="tw-w-full !tw-text-left !tw-p-0"
                :class="textClass"
                @click="toggleEdit()"
            >
                <span class="tw-mr-1">{{ inputValue ? displayValue : 'Add' }}</span>
                <i :class="inputValue ? 'mdi mdi-pencil' : 'mdi mdi-plus'"></i>
            </Button>

            <Tooltip v-else-if="currency" :class="containerClasses" :triggers="['hover', 'focus']">
                <template #content>
                    <span class="key-prompt">
                        <i class="mdi mdi-keyboard-return"></i>
                        Enter
                    </span>
                    Save
                    <span class="key-prompt">
                        <i class="mdi mdi-keyboard-esc"></i>
                        Esc
                    </span>
                    Cancel
                </template>
                <Currency
                    v-model="inputValue"
                    @keyup.native.enter.stop="update()"
                    @keyup.native.esc.prevent="toggleEdit()"
                />
            </Tooltip>
            <Tooltip v-else :class="containerClasses" :triggers="['hover', 'focus']">
                <template #content>
                    Press
                    <span class="key-prompt">
                        <i class="mdi mdi-keyboard-return"></i>
                        Enter
                    </span>
                    to save or
                    <span class="key-prompt">
                        <i class="mdi mdi-keyboard-esc"></i>
                        Esc
                    </span>
                    to cancel
                </template>
                <Textbox
                    v-model="inputValue"
                    :size="size"
                    :type="textArea ? 'textarea' : 'text'"
                    @keyup.native.enter.stop="update()"
                    @keyup.native.esc.prevent="toggleEdit()"
                />
            </Tooltip>
        </FormField>
        <FormField v-else :label="inputLabel">
            {{ displayValue }}
        </FormField>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Currency from '@/js/components/controls/Currency.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        Button,
        Tooltip,
        FormField,
        Textbox,
        Currency,
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
        textArea: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            required: false,
        },
        containerClasses: {
            type: String,
            default: 'w-100',
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        currency: {
            type: Boolean,
            default: false,
        },
        textClass: {
            type: String,
            required: false,
        },
        buttonType: {
            type: String,
            default: 'is-link',
        },
    },
    emits: ['update:modelValue', 'toggled', 'updated'],
    data() {
        return {
            isEditing: false,
            inputLabel: '',
            inputValue: '',
        };
    },
    computed: {
        displayValue() {
            return this.inputValue ? (this.currency ? localise(this.inputValue, 2, true) : this.inputValue) : 'Not set';
        },
    },
    watch: {
        label: function (val) {
            this.inputLabel = val;
        },
        modelValue: function (val) {
            this.inputValue = val;
            this.isEditing = false;
        },
    },
    mounted() {
        this.inputLabel = this.label;
        this.inputValue = typeof this.modelValue === 'number' ? this.modelValue.toString() : this.modelValue;
    },
    methods: {
        toggleEdit() {
            this.inputValue = this.modelValue;
            this.isEditing = !this.isEditing;
            this.$emit('toggled', this.isEditing);
        },
        update() {
            this.$emit('update:modelValue', this.inputValue.toString());
            this.isEditing = false;
            this.$emit('updated', this.inputValue.toString());
        },
    },
};
</script>
<style>
.eventwise-inline-edit {
    > .field {
        margin-bottom: 0 !important;

        > .label {
            margin-bottom: 1px !important;
        }

        > button {
            margin: 0 !important;
            padding: 0 !important;
            justify-content: flex-start !important;
            font-size: 100%;
            white-space: normal !important;
            font-family: 'Poppins' !important;
        }
    }
}
</style>
