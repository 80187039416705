<template>
    <div class="tw-grid tw-grid-cols-3 tw-gap-3">
        <div class="tw-col-span-1">
            <p class="new-tag-header">{{ tabName }}</p>

            <div class="tw-px-3">
                <FormField label="Search">
                    <Textbox v-model="searchName" placeholder="Search for a name..." />
                </FormField>

                <p class="tw-mt-1">Number of {{ tabName }}: {{ filteredModels.length }}</p>

                <ul class="tw-grid tw-gap-2 tw-max-h-[256px] tw-overflow-y-auto">
                    <li
                        v-for="model in filteredModels"
                        :key="model.id"
                        class="model-name tw-p-2"
                        :class="
                            model.id === selectedModel?.id
                                ? 'tw-bg-[linear-gradient(14deg,_#2d84b6_36%,_#60b7e9)] hover:cursor-pointer rounded-5 text-white'
                                : 'tw-bg-[#f7f7f7] tw-text-black hover:cursor-pointer rounded-5 '
                        "
                        @click.prevent="selectModels(model)"
                    >
                        <span v-if="reporting">{{ model.name.substring(0, 15) }}</span>
                        <span v-else>{{ model.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="!isLoading" class="tw-col-span-2">
            <p class="new-tag-header">Existing Tags</p>

            <div class="tw-px-3">
                <template v-if="selectedModel">
                    <p class="tw-text-center">
                        <strong>{{ selectedModel.name }}:</strong>
                        There are {{ assignedTags.length }} assigned tags
                    </p>

                    <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                        <div>
                            <p>Assigned ({{ assignedTags.length }})</p>
                            <div class="tw-flex tw-flex-wrap tw-gap-3">
                                <GroupTag
                                    v-for="tag in assignedTags"
                                    :key="tag.id"
                                    :tag="tag"
                                    class="tw-cursor-pointer"
                                    @click="updateAssignedTags(tag)"
                                />
                            </div>
                        </div>
                        <div>
                            <p>Unassigned ({{ unassignedTags.length }})</p>
                            <div class="tw-flex tw-flex-wrap tw-gap-3">
                                <GroupTag
                                    v-for="tag in unassignedTags"
                                    :key="tag.id"
                                    :tag="tag"
                                    class="tw-cursor-pointer"
                                    @click="updateAssignedTags(tag)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <Banner v-else type="is-primary">
                    Please select one of the {{ tabName }} on the left to show the assigned tags.
                </Banner>
            </div>
        </div>
    </div>
</template>

<script>
import GroupTag from '../../../../GroupTag.vue';
import Banner from '../../../../Layout/Banner.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Banner,
        GroupTag,
        FormField,
        Textbox,
    },
    props: {
        tabName: {
            type: String,
            required: true,
        },
        className: {
            type: String,
            required: true,
        },
        reporting: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            searchName: '',
            models: [],
            selectedModel: null,
            assignedTags: [],
            tagRecords: [],
        };
    },
    computed: {
        filteredModels() {
            if (this.searchName.length > 0) {
                return this.models.filter((model) => model.name.includes(searchStr));
            } else {
                return this.models;
            }
        },
        unassignedTags() {
            return this.tagRecords.filter((t) => !this.assignedTags.map((t) => t.id).includes(t.id));
        },
    },
    watch: {
        searchName() {
            // filter the selected models by the searchName
            if (this.selectedModel) {
                this.selectModels();
            }
        },
    },
    mounted() {
        this.fetchModelsList();
        this.fetchAssignedTags();
        this.fetchTags();
    },
    methods: {
        fetchModelsList() {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.tags.models', {
                        class: this.className,
                    })
                )
                .then(({ data }) => {
                    this.models = data.map((d) => ({
                        ...d,
                        selected: false,
                    }));
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        selectModels(model = null) {
            if (this.selectedModel === model?.id) {
                this.selectedModel = null;
            } else {
                this.selectedModel = model;
            }
            this.fetchAssignedTags();
        },
        fetchAssignedTags() {
            this.isLoading = true;
            if (!this.selectedModel) {
                this.isLoading = false;
                return;
            }
            axios
                .get(
                    route('api.account.tags.assigned', {
                        class: this.className,
                        id: this.selectedModel.id,
                    })
                )
                .then(({ data }) => {
                    this.assignedTags = data.assigned_tags;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        updateAssignedTags(tag) {
            if (this.assignedTags.map((t) => t.id).includes(tag.id)) {
                this.assignedTags = this.assignedTags.filter((t) => t.id !== tag.id);
            } else {
                this.assignedTags.push(tag);
            }

            axios
                .post(
                    route('api.account.tags.sync', {
                        class: this.className,
                        id: this.selectedModel.id,
                        tagIds: this.assignedTags.map((t) => t.id),
                    })
                )
                .then(() => {
                    toast('Success', 'Updated assigned tags', 'success');
                })
                .finally(() => (this.isLoading = false));
        },
        fetchTags() {
            // Fetch the tags for this account, all of them
            this.isLoading = true;
            axios
                .get(route('api.account.tags'))
                .then(({ data }) => {
                    this.tagRecords = data.grouptags;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">
.new-tag-header {
    background-color: #f2f2f2;
    @apply tw-text-center tw-py-2;
}
</style>
