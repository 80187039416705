<template>
    <page :title="pageTitle">
        <ew-card class="mt-3" :title="title" :expanded="true" :max-height="400">
            <template #actions>
                <div class="tw-gap-1 tw-flex [&>*]:tw-gap-2 [&>*]:tw-flex [&>*]:tw-items-center">
                    <Button :active="chartView === 'summary'" @click="chartView = 'summary'">
                        <i class="mdi mdi-monitor-dashboard" />
                        <span>Summary</span>
                    </Button>

                    <Button :active="chartView === 'revenue'" @click="chartView = 'revenue'">
                        <i class="mdi mdi-cash-multiple" />
                        <span>Revenue</span>
                    </Button>

                    <Button :active="chartView === 'costs'" @click="chartView = 'costs'">
                        <i class="mdi mdi-credit-card-outline" />
                        <span>Cost</span>
                    </Button>
                </div>
            </template>

            <chart-departments-overview :height="260" :default-view="chartView" />
        </ew-card>

        <div class="row mb-4">
            <div
                v-if="Auth.can('view budget entries')"
                class="col-12"
                :class="{ 'col-lg-6': Auth.can('view purchase orders') }"
            >
                <budget-approvals-card></budget-approvals-card>
            </div>
            <div
                v-if="Auth.can('view purchase orders')"
                class="col-12"
                :class="{ 'col-lg-6': Auth.can('view budget entries') }"
            >
                <purchasing-approvals-card></purchasing-approvals-card>
            </div>
        </div>

        <CreateInitialTickets
            v-if="Auth.can('manage tickets') && !Object.values(store.get('current_event.ticket_types')).length"
        />
    </page>
</template>
<script>
import Page from '../Page.vue';
import ChartDepartmentsOverview from '../../../assets/js/eventbooks/components/charts/departments/departments-overview.vue';
import BudgetApprovalsCard from '../../../assets/js/eventbooks/components/budget/approvals/card.vue';
import PurchasingApprovalsCard from '../../../assets/js/eventbooks/components/purchasing/approvals/card.vue';
import CreateInitialTickets from '../modals/Ticketing/CreateInitialTickets.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: {
        Page,
        ChartDepartmentsOverview,
        BudgetApprovalsCard,
        PurchasingApprovalsCard,
        CreateInitialTickets,
        Button,
    },
    data() {
        const store = useDataStore();
        return {
            chartView: 'costs',
            pageTitle: data.current_event.name + ' Overview',
            store: store,
        };
    },
    computed: {
        title() {
            switch (true) {
                case this.chartView === 'revenue':
                    return 'Revenue Overview';
                case this.chartView === 'costs':
                    return 'Cost Overview';
                default:
                    return 'Event Overview';
            }
        },
    },
};
</script>
