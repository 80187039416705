<template>
    <div class="horizontal-stacked-bar-chart">
        <Chart
            type="bar"
            :options="chartOptions"
            :data="chartSeries"
            :min-height="chartHeight"
            :max-height="chartHeight"
            :downloadable="false"
        />
    </div>
</template>
<script>
import Chart from '../Chart.vue';

export default {
    components: {
        Chart,
    },
    props: {
        series: {
            type: Object,
            default() {
                return {};
            },
        },
        legend: {
            type: Boolean,
            default: false,
        },
        max: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            default: 10,
        },
    },
    data() {
        return {
            chartSeries: this.series,
            showLegend: this.legend,
            chartMax: this.max,
            chartHeight: this.height,
        };
    },
    computed: {
        chartOptions() {
            return {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0,
                },
                layout: {
                    padding: {
                        left: -10,
                        right: -10,
                        top: -5,
                        bottom: -10,
                    },
                },
                scales: {
                    x: {
                        border: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                        stacked: true,
                        title: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                        max: this.chartMax,
                    },
                    y: {
                        border: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                        stacked: true,
                        title: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: this.showLegend,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
            };
        },
    },
    watch: {
        series: {
            handler(newVal) {
                this.chartSeries = newVal;
            },
            deep: true,
        },
        legend(newVal) {
            this.showLegend = newVal;
        },
        max(newVal) {
            this.chartMax = newVal;
        },
        height(newVal) {
            this.chartHeight = newVal;
        },
    },
};
</script>
<style lang="scss" scoped>
.horizontal-stacked-bar-chart {
    overflow: hidden;
}
</style>
