<template>
    <div class="spa-page-wrapper">
        <Page title="Help & Support" container-class="h-auto">
            <template #sidebar>
                <Submenu
                    class="[@media(max-width:900px)]:tw-flex-row tw-flex-col"
                    :pages="availableSections"
                    @update:model-value="(v) => setSection(v)"
                >
                    <template #footer>
                        <Button
                            v-if="isAuthenticated"
                            type="is-primary"
                            class="intercom-launcher tw-flex tw-gap-2 tw-items-center tw-justify-center"
                            :disabled="store.get('environment') !== 'production'"
                        >
                            <i class="mdi mdi-message-text"></i>
                            Get in touch
                        </Button>
                    </template>
                </Submenu>
            </template>

            <div class="row mx-0">
                <div class="col p-3">
                    <div v-if="activeArticle" class="row mx-4">
                        <div class="col-12 d-flex align-items-center my-4 buefy-wrapper">
                            <Tooltip position="right" label="Return to Help Articles">
                                <Button @click="activeArticle = null">
                                    <i class="mdi mdi-arrow-left"></i>
                                </Button>
                            </Tooltip>
                            <div class="ml-3">
                                <h4>{{ activeArticle.title }}</h4>
                                <small class="text-muted">
                                    Created {{ timeAgo(activeArticle.created_at) }}
                                    <span v-if="activeArticle.creator">by {{ activeArticle.creator.name }}</span>
                                </small>
                            </div>
                        </div>

                        <div class="col-12" v-html="activeArticle.content" />
                    </div>

                    <template v-else>
                        <form @submit.prevent="searchArticles">
                            <div
                                class="input-group my-4 w-50 mx-auto"
                                style="box-shadow: 0 4px 8px rgba(31, 30, 47, 0.3); border: 0; border-radius: 0.25rem"
                            >
                                <input
                                    v-model="search"
                                    type="text"
                                    class="form-control"
                                    placeholder="What can we help you with today?"
                                    style="border: 0"
                                />
                                <div class="input-group-append">
                                    <button
                                        class="btn btn-primary bg-eventwise-gradient border-0"
                                        type="submit"
                                        style="box-shadow: unset"
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div class="mx-4">
                            <div class="row">
                                <a
                                    v-for="article in articles.data"
                                    :key="article.id"
                                    href="#"
                                    class="item col-6 py-2"
                                    style="border-radius: 15px"
                                    @click.prevent="activeArticle = article"
                                >
                                    <div class="card card-body mx-3 h-100">
                                        <h3 class="mt-0">{{ article.title }}</h3>
                                        {{ article.description }}
                                    </div>
                                </a>
                            </div>

                            <Paginator
                                :data="articles"
                                class="m-3"
                                @change-page="(value) => changePaginatedPage(value)"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </Page>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Page from './Page.vue';
import Paginator from './controls/Paginator.vue';
import Submenu from './controls/Submenu.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { setRoute, timeAgo } from '@/js/utils.js';

export default {
    components: {
        Submenu,
        Paginator,
        Page,
        Button,
        Tooltip,
    },
    data() {
        return {
            currentSection: null,
            search: '',
            sections: [],
            articles: [],
            activeArticle: null,
            store: useDataStore(),
        };
    },
    computed: {
        availableSections() {
            const sections = this.sections.map((section) => {
                return {
                    title: section.name,
                    value: section.slug,
                    icon: this.store.get('icons').find((icon) => icon.value === section.icon).value,
                };
            });

            sections.unshift({
                title: 'Latest Help Articles',
                value: null,
                icon: 'New',
            });

            return sections;
        },
        isAuthenticated() {
            return this.store.get('current_user');
        },
    },
    mounted() {
        Eventbus.$on('pageChanged', () => this.handlePageChange());
        this.loadPageResources();
        this.handlePageChange();
    },
    methods: {
        timeAgo,
        loadPageResources() {
            axios.get(route('public-api.help.categories')).then(({ data }) => {
                this.sections = data.categories;
            });
        },
        loadArticles(page = 1) {
            axios
                .get(
                    route('public-api.help.articles', {
                        helpCategory: this.currentSection,
                        page: page,
                    })
                )
                .then(({ data }) => {
                    this.articles = data.articles;
                });
        },
        searchArticles() {
            axios
                .post(
                    route('public-api.help.articles', {
                        helpCategory: this.currentSection,
                    }),
                    {
                        search: this.search,
                    }
                )
                .then(({ data }) => {
                    this.articles = data.articles;
                });
        },
        changePaginatedPage(value) {
            this.loadArticles(value);
        },
        handlePageChange() {
            this.currentSection = route().params.section ?? null;
            this.loadArticles();
        },
        setSection(section = null) {
            this.activeArticle = null;

            if (section) {
                setRoute('help', {
                    section: section,
                });
            } else {
                setRoute('help');
            }

            this.search = '';
            this.articles.current_page = 1;
        },
    },
};
</script>
<style lang="scss" scoped>
a.item {
    transition: transform 0.4s;
}
a.item:hover {
    transform: scale(1.025);
    position: relative;
}
</style>
