<template>
    <Modal ref="modal" title="Connect to Ticketmaster" size="is-xlarge" @closed="reset()">
        <p>
            In order to integrate with Ticketmaster you require an organisation ID and API key. If you need assistance
            with retrieving these please get in touch with us from the help centre.
        </p>
        <div class="row mt-3">
            <div class="col-5">
                <FormField label="Organisation ID">
                    <Textbox v-model="organisationId" placeholder="Required" maxlength="255" :disabled="isLoading" />
                </FormField>
            </div>
            <div class="col-7">
                <FormField label="API Key">
                    <Textbox v-model="apiKey" placeholder="Required" maxlength="255" :disabled="isLoading" />
                </FormField>
            </div>
        </div>
        <template #footer>
            <Button class="!tw-mr-auto" @click="close()">Cancel</Button>
            <Button type="is-success" :loading="isLoading" :disabled="!isValid" @click="validateDetails()">
                Validate Connection
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
    },
    emits: ['connected'],
    data() {
        return {
            isLoading: false,
            organisationId: '',
            apiKey: '',
        };
    },
    computed: {
        isValid() {
            return this.organisationId && this.apiKey;
        },
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
        open() {
            this.$refs.modal.open();
        },
        reset() {
            this.organisationId = '';
            this.apiKey = '';
        },
        validateDetails() {
            this.isLoading = true;
            axios
                .post(route('api.account.integrations.ticketmaster.connect'), {
                    organisation_id: this.organisationId,
                    api_key: this.apiKey,
                })
                .then(() => {
                    toast('Success', "You're now connected to Ticketmaster", 'success');
                    this.$emit('connected');
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
