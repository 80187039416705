<template>
    <Modal ref="modal" title="Delete Invoice" @closed="reset()">
        <p v-if="invoice && !isConnectedToXero">
            Are you sure you want to delete invoice {{ invoice.invoice_number }}?
        </p>
        <template v-else-if="invoice && isConnectedToXero && !xeroReconnectionRequired">
            <p v-if="!hasCheckedXero">
                You can only void an invoice that has been pushed to Xero if it is in a draft status. Click the button
                below to check whether it is possible to void this invoice.
            </p>
            <Banner v-else-if="hasCheckedXero && canDeleteXeroInvoice" type="is-success">
                <p>
                    This invoice can be voided in Xero.
                    <br />
                    Click the button below to void the invoice in Xero and delete it from Eventwise.
                </p>
            </Banner>
        </template>
        <p v-else>Loading invoice details...</p>
        <template #footer>
            <Button :disabled="isLoading" @click="close()">Cancel</Button>
            <div class="flex-fill"></div>
            <Button v-if="invoice && !isConnectedToXero" type="danger" :loading="isLoading" @click="deleteInvoice()">
                Delete
            </Button>
            <template v-else-if="invoice && isConnectedToXero && !xeroReconnectionRequired">
                <Button v-if="!canDeleteXeroInvoice" type="is-info" :loading="isLoading" @click="checkXeroStatus()">
                    Check Invoice
                </Button>
                <Button v-else type="is-danger" :loading="isLoading" @click="deleteInvoice()">
                    Void in Xero &amp; Delete
                </Button>
            </template>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import Banner from '../../Layout/Banner.vue';
import Button from '@/js/components/controls/Button.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Banner,
        Button,
    },
    emits: ['deleted'],
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            invoice: null,
            invoiceType: null,
            hasCheckedXero: false,
            canDeleteXeroInvoice: false,
            currentAccount: store.get('account'),
        };
    },
    computed: {
        hasPermissionToDelete() {
            this.invoiceType; // Referencing triggers recalculation when invoiceType changes
            return Auth.can(
                'delete ' + (this.invoiceType === 'SupplierInvoice' ? 'supplier' : 'customer') + ' invoices'
            );
        },
        isConnectedToXero() {
            this.invoiceType; // Referencing triggers recalculation when invoiceType changes
            return this.currentAccount && this.currentAccount.is_connected_to_xero;
        },
        xeroReconnectionRequired() {
            if (this.isConnectedToXero) {
                return false;
            }
            return !!this.currentAccount.xero_organisation.reconnection_required;
        },
    },
    methods: {
        open(invoice, type) {
            this.invoice = invoice;
            this.invoiceType = type;
            this.$refs.modal.open();
        },
        reset() {
            this.isLoading = false;
            this.invoice = null;
            this.invoiceType = null;
            this.hasCheckedXero = false;
            this.canDeleteXeroInvoice = false;
        },
        close() {
            this.reset();
            this.$refs.modal.close();
        },
        checkXeroStatus() {
            this.isLoading = true;
            this.hasCheckedXero = true;
            axios
                .get(
                    this.invoiceType === 'CustomerInvoice'
                        ? route('api.account.event.revenue.invoices.checkXeroStatus', {
                              event: this.invoice.event_id,
                              revenueEntry: this.invoice.revenue_entry_id,
                              customerInvoice: this.invoice.id,
                          })
                        : route('api.account.event.purchaseorders.invoices.checkXeroStatus', {
                              event: this.invoice.event_id,
                              purchaseOrder: this.invoice.purchase_orders[0].id,
                              supplierInvoice: this.invoice.id,
                          })
                )
                .then(() => (this.canDeleteXeroInvoice = true))
                .catch(() => this.close())
                .finally(() => (this.isLoading = false));
        },
        deleteInvoice() {
            if (!this.invoice) {
                return;
            }
            this.isLoading = true;
            axios
                .delete(
                    this.invoiceType === 'CustomerInvoice'
                        ? route('api.account.event.revenue.invoices.delete', {
                              event: this.invoice.event_id,
                              revenueEntry: this.invoice.revenue_entry_id,
                              customerInvoice: this.invoice.id,
                          })
                        : route('api.account.event.purchaseorders.invoices.delete', {
                              event: this.invoice.event_id,
                              purchaseOrder: this.invoice.purchase_orders[0].id,
                              supplierInvoice: this.invoice.id,
                          })
                )
                .then(() => {
                    if (this.isConnectedToXero && this.invoice.invoice_guid) {
                        toast(
                            'Invoice Deleted',
                            'It may take a few moments to fully delete invoice ' +
                                this.invoice.invoice_number +
                                ' from Xero, it should disappear momentarily',
                            'success'
                        );
                        this.$emit('deleted');
                        this.close();
                        return;
                    }
                    toast(
                        'Invoice Deleted',
                        'Invoice ' + this.invoice.invoice_number + ' has been deleted successfully',
                        'success'
                    );
                    this.$emit('deleted');
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
