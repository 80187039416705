<template>
    <div class="tw-py-4">
        <DataTable
            v-if="!activeUsergroup"
            :endpoint="route('api.account.team.groups')"
            :columns="[
                { title: 'Name', field: 'name' },
                { title: 'Created', field: 'created_at' },
                { title: 'Last Updated', field: 'updated_at' },
                { title: '', field: 'actions' },
            ]"
        >
            <template #column(created_at)="{ row }">
                {{ timeAgo(row.created_at) }}
            </template>
            <template #column(updated_at)="{ row }">
                {{ timeAgo(row.updated_at) }}
            </template>
            <template #column(actions)="{ row }">
                <Button
                    type="is-primary"
                    class="tw-flex tw-items-center tw-justify-center tw-gap-1"
                    :disabled="!Auth.can('manage roles')"
                    @click="activeUsergroup = row"
                >
                    <i class="mdi mdi-eye"></i>
                    View
                </Button>
            </template>
        </DataTable>
        <Usergroup v-else :user-group="activeUsergroup" :permissions="activeUsergroup.permissions" />
    </div>
</template>
<script setup>
import Usergroup from './Usergroups/Usergroup.vue';
import { timeAgo } from '@/js/utils.js';
import DataTable from '@/js/components/tables/DataTable.vue';
import Button from '@/js/components/controls/Button.vue';
import { ref } from 'vue';

const activeUsergroup = ref(null);
</script>
