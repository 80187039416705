<template>
    <div class="drawer-container">
        <transition name="backdrop">
            <div
                v-if="backdrop && isOpen"
                class="drawer-backdrop"
                :style="zIndex ? `z-index:${zIndex - 1};` : null"
                @click.prevent="close()"
            ></div>
        </transition>
        <transition name="drawer">
            <div v-if="isOpen" class="drawer buefy-wrapper" :class="size" :style="zIndex ? `z-index:${zIndex};` : null">
                <div class="drawer-title">
                    <slot v-if="$slots['title']" name="title" />
                    <h4 v-else>{{ title }}</h4>

                    <button class="drawer-close" @click="close()"><i class="mdi mdi-close tw-text-2xl"></i></button>
                </div>
                <div class="drawer-header" :class="headerClass"><slot name="header"></slot></div>
                <div class="drawer-content" :class="contentClass">
                    <slot></slot>
                </div>
                <div class="drawer-footer" :class="footerClass"><slot name="footer"></slot></div>
                <LoadingSpinner v-if="loading" />
            </div>
        </transition>
    </div>
</template>
<script>
import LoadingSpinner from './widgets/LoadingSpinner.vue';
export default {
    components: {
        LoadingSpinner,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
        headerClass: {
            type: String,
            default: '',
        },
        contentClass: {
            type: String,
            default: '',
        },
        footerClass: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        zIndex: {
            type: Number,
            default: null,
        },
        backdrop: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['opened', 'closed', 'opening', 'closing'],
    data() {
        return {
            isOpen: false,
        };
    },
    watch: {
        isOpen(newVal) {
            if (newVal) {
                document.body.style.overflow = 'hidden';
                document.body.style.paddingRight = '15px';
                this.$emit('opened');
            } else {
                document.body.style.overflow = null;
                document.body.style.paddingRight = null;
                this.$emit('closed');
            }
        },
    },
    methods: {
        open() {
            this.$emit('opening');
            this.isOpen = true;
        },
        close() {
            this.$emit('closing');
            this.isOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.drawer-container {
    .drawer {
        position: fixed;
        top: 0;
        right: 0;
        width: min(500px, 100vw);
        height: 100vh;
        background: white;
        display: flex;
        flex-direction: column;
        box-shadow: 0 -4px 24px rgba(0, 0, 0, 0.1);
        z-index: 5000;

        &.is-small {
            width: min(350px, 100vw);
        }
        &.is-large {
            width: min(750px, 100vw);
        }
        &.is-xlarge {
            width: min(900px, 100vw);
        }

        & > .drawer-title {
            display: flex;
            align-items: center;
            padding: 5px 12px;
            background: #eaf0f9;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            min-height: 60px;

            & > h4 {
                margin: 0 0 0 10px;
            }

            & > .drawer-close {
                width: 42px;
                height: 42px;
                border: none;
                background: transparent;
                margin-left: auto;
                border-radius: 4px;
                transition: all 0.1s ease;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }
            }
        }

        & > .drawer-header:not(:empty) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding: 0.5rem;
        }

        & > .drawer-content {
            overflow-x: hidden;
            flex: 1;
        }

        & > .drawer-footer:not(:empty) {
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            padding: 0.5rem;
        }
    }

    .drawer-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 4999;
        background: rgba(black, 0.33);
    }
}

.drawer-enter-active,
.drawer-leave-active {
    transition: all 0.25s ease;
    transform: scaleX(1) scaleY(1) translateX(0);
    opacity: 1;
}
.drawer-enter,
.drawer-leave-to {
    transform: scaleX(0.5) scaleY(0.8) translateX(100%);
    opacity: 0;
}

.backdrop-enter-active,
.backdrop-leave-active {
    transition: all 0.33s ease;
    opacity: 1;
}
.backdrop-enter,
.backdrop-leave-to {
    opacity: 0;
}
</style>
