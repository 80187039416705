<template>
    <Page v-if="Auth.can('manage integrations')" title="Integrations">
        <template #submenu>
            <a href="#" :class="{ active: !currentPage }" @click.prevent="setPage()">Accounting</a>
            <a
                href="#"
                :class="{ active: ['ticketing', 'eventbrite', 'ticketmaster'].includes(currentPage) }"
                @click.prevent="setPage('ticketing')"
            >
                Ticketing
            </a>
        </template>
        <div v-if="!currentPage" class="buefy-wrapper">
            <div class="integrations-list">
                <div class="integration">
                    <img src="@/images/brands/xero.svg" alt="Xero Logo" />
                    <h4>Xero</h4>
                    <p>Seamless integration with Xero's Cloud Accounting Software</p>
                    <div v-if="xeroOrganisation" class="actions mt-3">
                        <Banner class="tw-mb-2 tw-text-left" type="is-success">
                            Connected to
                            <strong>{{ xeroOrganisation.tenant_name }}</strong>
                        </Banner>
                        <Button class="tw-w-full" @click="setPage('xero')">Manage Integration</Button>
                    </div>
                    <div v-else class="actions mt-3">
                        <Button
                            type="is-success"
                            :loading="isConnecting"
                            class="tw-w-full tw-flex tw-gap-2 tw-justify-center"
                            @click="connectToXero()"
                        >
                            <i class="mdi mdi-link"></i>
                            Connect
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="currentPage === 'ticketing'" class="buefy-wrapper">
            <template
                v-if="
                    currentAccount.eventbrite_enabled ||
                    currentAccount.ticketmaster_enabled ||
                    currentAccount.skiddle_enabled
                "
            >
                <h4 class="mt-5">Ticketing</h4>
                <div class="integrations-list">
                    <div v-if="currentAccount.eventbrite_enabled" class="integration">
                        <img src="@/images/brands/eventbrite-white.svg" alt="Eventbrite Logo" />
                        <h4>Eventbrite</h4>
                        <p>Link your Eventbrite events and tickets to automatically sync your sales</p>
                        <div v-if="isConnectedToEventbrite" class="actions mt-3">
                            <Banner class="tw-mb-2 tw-text-left" type="is-success">Connected</Banner>
                            <Button class="tw-w-full" @click="setPage('eventbrite')">Manage Integration</Button>
                        </div>
                        <div v-else class="actions mt-3">
                            <Button
                                type="is-success"
                                :loading="isConnecting"
                                class="tw-w-full tw-flex tw-gap-2 tw-justify-center"
                                @click="connectToEventbrite()"
                            >
                                <i class="mdi mdi-link"></i>
                                Connect
                            </Button>
                        </div>
                    </div>
                    <div v-if="currentAccount.ticketmaster_enabled" class="integration">
                        <img src="@/images/brands/ticketmaster-icon-blue.webp" alt="Ticketmaster Logo" />
                        <h4 class="mt-auto">Ticketmaster</h4>
                        <p class="mb-0">Link your Ticketmaster events and tickets to automatically sync your sales</p>
                        <div v-if="ticketmasterIntegration" class="actions mt-3">
                            <Banner class="tw-mb-2 tw-text-left" type="is-success">
                                Connected to
                                <strong>{{ ticketmasterIntegration.organisation_name }}</strong>
                            </Banner>
                            <Button class="tw-w-full" @click="setPage('ticketmaster')">Manage Integration</Button>
                        </div>
                        <div v-else class="actions mt-3">
                            <Button
                                type="is-success"
                                :loading="isConnecting"
                                class="tw-w-full tw-flex tw-gap-2 tw-justify-center"
                                @click="connectToTicketmaster()"
                            >
                                <i class="mdi mdi-link"></i>
                                Connect
                            </Button>
                        </div>
                    </div>
                    <div v-if="currentAccount.skiddle_enabled" class="integration">
                        <img src="@/images/skiddle-logo-black-stacked.svg" alt="Skiddle Logo" />
                        <h4 class="mt-auto">Skiddle</h4>
                        <p class="mb-0">Link your Skiddle events and tickets to automatically sync your sales</p>
                        <div v-if="skiddleIntegration" class="actions mt-3">
                            <Banner class="tw-mb-2 tw-text-left" type="is-success">Connected</Banner>
                            <Button class="tw-w-full" @click="setPage('skiddle')">Manage Integration</Button>
                        </div>
                        <div v-else class="actions mt-3">
                            <Button
                                type="is-success"
                                :loading="isConnecting"
                                class="tw-w-full tw-flex tw-gap-2 tw-justify-center"
                                @click="connectToSkiddle()"
                            >
                                <i class="mdi mdi-link"></i>
                                Connect
                            </Button>
                        </div>
                    </div>
                </div>
            </template>
            <Banner v-else class="tw-mt-4">There are no ticketing integrations available to you just yet</Banner>
        </div>
        <IntegrationXero v-else-if="currentPage === 'xero'" />
        <IntegrationEventbrite v-else-if="currentPage === 'eventbrite'" @back="setPage('ticketing')" />
        <IntegrationTicketmaster v-else-if="currentPage === 'ticketmaster'" @back="setPage('ticketing')" />
        <IntegrationSkiddle v-else-if="currentPage === 'skiddle'" @back="setPage('ticketing')" />
        <ConnectTicketmasterModal
            v-if="currentAccount.ticketmaster_enabled"
            ref="connectTicketmasterModal"
            @connected="reload()"
        />
        <ConnectSkiddleModal v-if="currentAccount.skiddle_enabled" ref="connectSkiddleModal" @connected="reload()" />
    </Page>
    <PageNotFound v-else />
</template>
<script>
import Page from '../../Page.vue';
import PageNotFound from '../../PageNotFound.vue';
import IntegrationXero from './Integrations/Xero.vue';
import IntegrationEventbrite from './Integrations/Eventbrite.vue';
import IntegrationTicketmaster from './Integrations/Ticketmaster.vue';
import IntegrationSkiddle from './Integrations/Skiddle.vue';
import ConnectTicketmasterModal from './Integrations/ConnectTicketmasterModal.vue';
import ConnectSkiddleModal from './Integrations/ConnectSkiddleModal.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        Page,
        PageNotFound,
        IntegrationXero,
        IntegrationEventbrite,
        IntegrationTicketmaster,
        IntegrationSkiddle,
        ConnectTicketmasterModal,
        ConnectSkiddleModal,
        Button,
        Banner,
    },
    data() {
        const store = useDataStore();
        return {
            isConnecting: false,
            currentPage: null,
            currentAccount: store.get('account'),
            xeroOrganisation: store.get('account.xero_organisation'),
            store: store,
        };
    },
    computed: {
        isConnectedToEventbrite() {
            if (!this.currentAccount) {
                return false;
            }
            return !!this.currentAccount.integrations.find((i) => i.integrator === 'eventbrite');
        },
        ticketmasterIntegration() {
            if (!this.currentAccount) {
                return false;
            }
            return this.currentAccount.integrations.find((i) => i.integrator === 'ticketmaster');
        },
        skiddleIntegration() {
            if (!this.currentAccount) {
                return false;
            }
            return this.currentAccount.integrations.find((i) => i.integrator === 'skiddle');
        },
    },
    mounted() {
        this.handlePageChange();
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        handlePageChange() {
            this.currentPage = route().params.page ?? null;
        },
        setPage(page = '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setRoute('account.integrations', {
                page: page,
            });
        },
        connectToXero() {
            this.isConnecting = true;
            window.location.href = route('account.xero.connect');
        },
        connectToEventbrite() {
            this.isConnecting = true;
            window.location.href = route('account.eventbrite.connect');
        },
        connectToTicketmaster() {
            if (!this.currentAccount.ticketmaster_enabled) {
                return;
            }
            this.$refs.connectTicketmasterModal.open();
        },
        connectToSkiddle() {
            if (!this.currentAccount.skiddle_enabled) {
                return;
            }
            this.$refs.connectSkiddleModal.open();
        },
        reload() {
            this.isConnecting = true;
            window.location.reload();
        },
    },
};
</script>
<style lang="scss" scoped>
.integrations-list {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
    gap: 15px;

    & > .integration {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 300px;
        background: white;
        padding: 15px;
        border-radius: 10px;

        & > img {
            max-height: 80px;
            max-width: 75%;
            margin-bottom: 15px;
        }

        & > p {
            margin-bottom: auto;
        }

        & > .actions {
            width: 100%;
        }
    }
}
</style>
