<template>
    <Modal ref="modal" :title="'Add ' + objectName" size="is-large" @closing="resetForm()">
        <div v-if="step === 'create'" class="tw-text-left">
            <Tooltip
                class="tw-w-full"
                position="bottom"
                label="This name is already in use, please choose another"
                :disabled="!nameIsInvalid"
                always-open
            >
                <Textbox
                    v-model="newDepartmentData.name"
                    :placeholder="'Give your new ' + objectName + ' a name'"
                    maxlength="255"
                    required
                    autofocus
                    @update:model-value="nameInUse"
                />
            </Tooltip>
            <div class="tw-flex tw-mt-2">
                <FormField class="tw-mb-0 tw-flex-1 tw-pr-3" label="Description">
                    <Textbox
                        v-model="newDepartmentData.description"
                        maxlength="255"
                        :disabled="!newDepartmentData.name || nameIsInvalid"
                    />
                </FormField>
                <Currency
                    v-model="newDepartmentData.fixed_amount"
                    label="Fixed Amount"
                    :disabled="!newDepartmentData.name || nameIsInvalid"
                />
            </div>
            <FormField v-if="revenue" label="Revenue Stream Type">
                <control-stream-type v-model="newDepartmentData.stream_type"></control-stream-type>
            </FormField>
            <Banner v-if="account && account.is_connected_to_xero" type="is-primary" class="tw-mt-5" :closable="false">
                <span class="tw-font-avenir">You&apos;re connected to Xero!</span>
                <br />
                You&apos;ll be prompted to link your Xero accounts with your subcategories on the next screen
            </Banner>
        </div>
        <div v-else-if="step === 'import'" class="tw-text-left">
            <FormField :label="objectName" class="tw-mb-2">
                <Select v-model="newDepartmentData" class="tw-w-full" :placeholder="'Select an existing ' + objectName">
                    <option
                        v-for="availableDepartment in availableDepartments"
                        :key="availableDepartment.id"
                        :value="availableDepartment"
                    >
                        {{ availableDepartment.name }}
                    </option>
                </Select>
            </FormField>
            <p v-if="newDepartmentData.name">
                Your existing subcategories for
                <span class="tw-font-semibold">{{ newDepartmentData.name }}</span>
                have been automatically selected, you will be able to edit your subcategories on the next screen.
            </p>
            <Currency
                v-if="newDepartmentData.name"
                v-model="newDepartmentData.fixed_amount"
                class="tw-mt-2"
                label="Fixed Amount"
                :disabled="!newDepartmentData.name"
            />
        </div>
        <div v-else class="d-flex">
            <div class="flex-fill pr-2">
                <Button class="tw-w-full tw-py-5" @click="step = 'create'">
                    <i class="mdi mdi-playlist-plus tw-text-4xl"></i>
                    <h4>Create New</h4>
                </Button>
            </div>
            <div class="flex-fill pl-2">
                <Tooltip
                    :disabled="!availableDepartments.length"
                    :label="'There aren\'t any more Revenue Streams in ' + account.name + ' to import'"
                    position="left"
                    class="tw-w-full"
                >
                    <Button class="tw-w-full tw-py-5" :disabled="!availableDepartments.length" @click="step = 'import'">
                        <i class="mdi mdi-account-arrow-left tw-text-4xl"></i>
                        <h4 class="tw-flex tw-gap-1 tw-justify-center">
                            Import Existing
                            <span
                                class="tw-text-white tw-bg-eventwise tw-text-sm tw-pt-[3px] tw-w-[22px] tw-h-[22px] tw-flex tw-items-center tw-justify-center tw-rounded-full"
                            >
                                {{ availableDepartments.length }}
                            </span>
                        </h4>
                    </Button>
                </Tooltip>
            </div>
        </div>
        <template #footer>
            <div class="tw-flex tw-justify-between">
                <Button v-if="!step" @click="$refs.modal.closeModal()">Cancel</Button>
                <Button
                    v-if="step === 'create' || step === 'import'"
                    type="is-primary"
                    :disabled="!newDepartmentData.name || nameIsInvalid"
                    @click="addDepartment()"
                >
                    {{ capitalise(step) }} {{ objectName }}
                </Button>
            </div>
        </template>
    </Modal>
</template>
<script setup>
import { ref, computed } from 'vue';
import Modal from '@/js/components/Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Select from '@/js/components/controls/Select.vue';
import Currency from '@/js/components/controls/Currency.vue';
import { capitalise } from '@/js/utils.js';

const emit = defineEmits(['created']);
const props = defineProps({
    account: {
        type: Object,
    },
    existingDepartments: {
        type: Array,
    },
    selectedDepartments: {
        type: Array,
    },
    revenue: {
        type: Boolean,
        default: false,
    },
});

const step = ref(null);
const newDepartmentData = ref({
    categories: [],
    type: props.revenue ? 2 : 1,
});
const nameIsInvalid = ref(false);
const modal = ref(null);

const objectName = computed(() => (props.revenue ? 'Revenue Stream' : 'Cost Department'));
const objectType = computed(() => (props.revenue ? 2 : 1));
const availableDepartments = computed(() =>
    props.existingDepartments.filter(
        (existingDepartment) =>
            existingDepartment.type === objectType.value &&
            !props.selectedDepartments.find((selectedDepartment) => selectedDepartment.name === existingDepartment.name)
    )
);

const openModal = () => {
    modal.value.open();
};
const addDepartment = () => {
    newDepartmentData.value.type = props.revenue ? 2 : 1;
    emit('created', newDepartmentData.value);
    modal.value.close();
};
const resetForm = () => {
    step.value = null;
    nameIsInvalid.value = false;
    newDepartmentData.value = {
        categories: [],
    };
};
const nameInUse = (value) => {
    if (!value) {
        nameIsInvalid.value = false;
    }
    nameIsInvalid.value = !!props.existingDepartments
        .concat(props.selectedDepartments)
        .find((department) => department.name === value);
};

defineExpose({
    openModal,
});
</script>
