<template>
    <Modal ref="modal" title="Raise Credit Note" size="is-large" :loading="isLoading" @closed="close()">
        <div class="tw-flex tw-gap-2">
            <FormField class="tw-flex-1" label="Date">
                <flat-pickr
                    v-model="formData.date"
                    placeholder="Required"
                    :config="flatpickrConfig"
                    class="input is-small"
                    required
                />
            </FormField>
            <NetGrossInput v-model="amount" :tax-rate="taxRateId" label="Amount" input="gross" allow-switching />
        </div>
        <FormField label="Description">
            <Textbox v-model="formData.description" maxlength="255" type="textarea" rows="2" />
        </FormField>
        <FormField label="Tax Rate" class="tw-mt-2">
            <TaxRateSelector
                v-model="taxRateId"
                :type="formData.creditable_type === 'App\\Models\\SupplierInvoice' ? 'expense' : 'income'"
            />
        </FormField>
        <template #footer>
            <Button type="is-success" :disabled="!isValid" @click="create()">Raise Credit Note</Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import TaxRateSelector from '../../controls/TaxRateSelector.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import NetGrossInput from '@/js/components/controls/NetGrossInput.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        TaxRateSelector,
        Button,
        FormField,
        Textbox,
        NetGrossInput,
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            store: store,
            maxAmount: null,
            formData: {
                creditable_type: null,
                creditable_id: null,
                date: null,
                description: null,
            },
            amount: 0,
            taxRateId: null,
            eventId: null,
        };
    },
    computed: {
        isValid() {
            return (
                this.formData &&
                this.formData.creditable_type &&
                this.formData.creditable_id &&
                this.formData.date &&
                this.amount
            );
        },
    },
    mounted() {
        Eventbus.$on('raiseCreditNote', (type, invoice, limit = null, eventId) => {
            this.eventId = eventId;
            this.open(type, invoice, limit);
        });
    },
    methods: {
        open(type, invoice, limit = null) {
            if (!type || !['CustomerInvoice', 'SupplierInvoice'].includes(type)) {
                toast('Error', 'Invalid type provided when attempting to create a credit note', 'error');
                return;
            } else if (!invoice) {
                toast('Error', 'Invalid invoice provided when attempting to credit a credit note', 'error');
                return;
            }
            this.maxAmount = limit;
            this.formData.creditable_type = 'App\\Models\\' + type;
            this.formData.creditable_id = invoice;
            this.amount = limit ? limit : 0;
            this.formData.date = new Date().toISOString().split('T')[0];
            this.formData.user_id = Auth.user.id;
            this.$refs.modal?.open();
        },
        close() {
            this.reset();
            this.$refs.modal.close();
        },
        reset() {
            this.maxAmount = null;
            this.amount = 0;
            this.taxRateId = null;
            this.formData = {
                creditable_type: null,
                creditable_id: null,
                date: null,
                description: null,
            };
        },
        create() {
            this.isLoading = true;
            this.formData.tax_rate_id = this.taxRateId;
            this.formData.amount = this.amount;
            axios
                .post(route('api.account.event.creditNotes.create', { event: this.eventId }), this.formData)
                .then(() => {
                    toast('Credit Note Raised', 'Your credit note has been raised successfully', 'success');
                    Eventbus.$emit('reloadInvoice');
                    this.close();
                    Eventbus.$emit('reload:purchaseorders');
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
