<template>
    <div v-if="article">
        <div class="tw-flex tw-items-center tw-gap-3">
            <Tooltip position="right" label="Return to Help Articles">
                <Button @click="$emit('back')">
                    <i class="mdi mdi-arrow-left"></i>
                </Button>
            </Tooltip>
            <div>
                <h4>{{ article.id ? article.title : 'New Article' }}</h4>
                <small v-if="article.id" class="text-muted">
                    Created {{ timeAgo(article.created_at) }}
                    <span v-if="article.creator">by {{ article.creator.name }}</span>
                </small>
            </div>
            <Tooltip v-if="article.id" class="ml-auto" position="left" label="Delete Help Article">
                <Button type="is-danger" @click="deleteHelpArticle">
                    <i class="mdi mdi-delete"></i>
                </Button>
            </Tooltip>
        </div>

        <div id="admin-section-content">
            <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-mb-3">
                <FormField for="title" label="Title">
                    <Textbox v-model="article.title" type="text" :max-length="255" class="flex-grow-1" expanded />
                </FormField>

                <FormField for="description" label="Short Description">
                    <Textbox v-model="article.description" type="text" :max-length="255" class="flex-grow-1" expanded />
                </FormField>

                <FormField for="help_category_id" label="Help Category">
                    <Select v-model="article.help_category_id">
                        <option
                            v-for="category in helpCategories"
                            :key="'help_category_' + category.id"
                            :value="category.id"
                        >
                            {{ category.name }}
                        </option>
                    </Select>
                </FormField>

                <div class="tw-flex tw-gap-3 tw-justify-evenly">
                    <FormField for="published" label="Published">
                        <Toggle v-model="article.published">Published</Toggle>
                    </FormField>

                    <FormField for="is_public" label="Public">
                        <Toggle v-model="article.is_public">Public</Toggle>
                    </FormField>
                </div>
            </div>

            <CKEditor v-model="article.content" />
            <Button type="is-success" class="tw-ml-auto tw-block tw-mt-2" @click="saveHelpArticle">
                Save Help Article
            </Button>
        </div>
    </div>
</template>

<script>
import CKEditor from '../../CKEditor.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Toggle from '@/js/components/Toggle.vue';
import { timeAgo, toast } from '@/js/utils.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';

export default {
    components: {
        FormField,
        CKEditor,
        Button,
        Tooltip,
        Textbox,
        Select,
        Toggle,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
    },
    emits: ['back', 'title'],
    data() {
        return {
            article: {
                id: null,
                title: null,
                description: null,
                help_category_id: null,
                published: false,
                is_public: false,
                content: '',
            },
            helpCategories: [],
        };
    },
    watch: {
        id(newVal) {
            this.reloadData(newVal);
        },
    },
    mounted() {
        this.reloadData(this.id);
    },
    methods: {
        timeAgo,
        reloadData(id = null) {
            axios
                .get(
                    route('api.admin.help.article', {
                        article: id,
                    })
                )
                .then(({ data }) => {
                    this.helpCategories = data.categories;
                    if (data.article) {
                        this.article = data.article;
                    }
                    this.$emit('title', data.name);
                });
        },
        saveHelpArticle() {
            if (!this.article) {
                return;
            }
            axios
                .post(
                    route('api.admin.help.article.post', {
                        article: this.article.id,
                    }),
                    this.article
                )
                .then(() => {
                    toast('Changes Saved', 'Your changes have been saved', 'success');
                    this.$emit('back');
                });
        },
        deleteHelpArticle() {
            if (!this.article.id) {
                return;
            }
            swal.fire({
                title: 'Warning',
                html:
                    'Are you sure that would like to delete this help article?' +
                    '<br /><br />This will remove the article permanently and users will no longer be able to access it.',
                showCancelButton: true,
                confirmButtonText: 'Continue',
            }).then((result) => {
                if (result.value) {
                    axios
                        .delete(
                            route('api.admin.help.article.delete', {
                                article: this.article.id,
                            })
                        )
                        .then(() => {
                            toast('Article Deleted', this.article.title + ' has been deleted successfully', 'success');
                            this.$emit('back');
                        });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#admin-section-content {
    padding: 20px;
    background: white;
    border-radius: 4px;
}
</style>
