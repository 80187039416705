<template>
    <div class="buefy-wrapper">
        <div class="d-flex mb-2" style="gap: 10px">
            <Dropdown :hide-on-click="false">
                <template #trigger>
                    <Button class="tw-flex tw-gap-1">
                        Record Types ({{ showingRecordTypes.length ? showingRecordTypes.length : 'All' }})
                        <i class="mdi mdi-menu-down"></i>
                    </Button>
                </template>
                <div class="d-flex flex-column px-2 pt-2">
                    <Checkbox
                        :model-value="showingRecordTypes.includes('legacy')"
                        :disabled="isLoading"
                        @click="changeShowTypes('legacy')"
                    >
                        Legacy
                    </Checkbox>
                    <Checkbox
                        :model-value="showingRecordTypes.includes('manual')"
                        :disabled="isLoading"
                        @click="changeShowTypes('manual')"
                    >
                        Manual
                    </Checkbox>
                    <Checkbox
                        :model-value="showingRecordTypes.includes('imported')"
                        :disabled="isLoading"
                        @click="changeShowTypes('imported')"
                    >
                        Imported
                    </Checkbox>
                    <Checkbox
                        :model-value="showingRecordTypes.includes('eventbrite')"
                        :disabled="isLoading"
                        @click="changeShowTypes('eventbrite')"
                    >
                        Eventbrite
                    </Checkbox>
                    <Checkbox
                        :model-value="showingRecordTypes.includes('skiddle')"
                        :disabled="isLoading"
                        @click="changeShowTypes('skiddle')"
                    >
                        Skiddle
                    </Checkbox>
                </div>
            </Dropdown>
            <Select v-if="availableTicketSellers.length" v-model="showingTicketSeller" :disabled="isLoading">
                <option :value="null">Showing: All Channels</option>
                <option v-for="ticketSeller in availableTicketSellers" :key="ticketSeller.id" :value="ticketSeller.id">
                    Showing: {{ ticketSeller.name }}
                </option>
            </Select>
            <Select v-model="showingPeriod" :disabled="isLoading">
                <option value="all">Period: All Time</option>
                <option value="custom">Custom Period</option>
            </Select>
            <div v-if="showingPeriod === 'custom'" class="d-flex" style="gap: 10px">
                <div class="d-flex">
                    <flat-pickr
                        v-model="customRangeStart"
                        placeholder="Start Date"
                        :config="flatpickrConfig"
                        class="input control is-small is-clearfix"
                        :disabled="isLoading"
                    />
                    <Button :disabled="!customRangeStart" @click="customRangeStart = null">
                        <i class="mdi mdi-close"></i>
                    </Button>
                </div>
                <div class="d-flex">
                    <flat-pickr
                        v-model="customRangeEnd"
                        placeholder="End Date"
                        :config="flatpickrConfig"
                        class="input control is-small is-clearfix"
                        :disabled="isLoading"
                    />
                    <Button :disabled="!customRangeEnd" @click="customRangeEnd = null">
                        <i class="mdi mdi-close"></i>
                    </Button>
                </div>
            </div>
            <div class="flex-fill"></div>
            <Button v-if="Auth.can('export data')" class="tw-flex tw-gap-1 tw-items-center" @click="exportData()">
                <i class="mdi mdi-application-export"></i>
                Export Data
            </Button>
        </div>
        <DataTable
            ref="table"
            :endpoint="dataEndpoint"
            :columns="[
                {
                    field: 'record_type',
                },
                {
                    field: 'sold_at',
                    title: 'Date',
                },
                {
                    field: 'name',
                    title: 'Ticket',
                },
                {
                    field: 'reference',
                    title: 'Reference',
                },
                {
                    field: 'supplier_name',
                    title: 'Channel',
                },
                {
                    field: 'status',
                    title: 'Status',
                },
                {
                    field: 'ticket_price',
                    title: 'Ticket Price',
                },
                {
                    field: 'rebate',
                    title: 'Rebate',
                },
                {
                    field: 'quantity',
                    title: 'Quantity',
                },
            ]"
        >
            <template #heading(record_type)>
                <div class="d-flex align-items-center">
                    Record Type
                    <HelpLabel class="ml-2" position="bottom">
                        <template #html>
                            <div class="row text-left border-bottom">
                                <div class="col-4 border-right pr-1">
                                    <strong class="text-white">Type</strong>
                                </div>
                                <div class="col-8 pl-1">
                                    <strong class="text-white">Description</strong>
                                </div>
                            </div>
                            <div class="row text-left border-bottom">
                                <div class="col-4 border-right pr-1">Legacy</div>
                                <div class="col-8 pl-1">Sold tickets pre-dating individual transactions.</div>
                            </div>
                            <div class="row text-left border-bottom">
                                <div class="col-4 border-right pr-1">Manual</div>
                                <div class="col-8 pl-1">Manually entered ticket transactions.</div>
                            </div>
                            <div class="row text-left border-bottom">
                                <div class="col-4 border-right pr-1">Imported</div>
                                <div class="col-8 pl-1">Transactions imported from a CSV file.</div>
                            </div>
                            <div class="row text-left">
                                <div class="col-4 border-right pr-1">Eventbrite</div>
                                <div class="col-8 pl-1">Transactions imported from Eventbrite.</div>
                            </div>
                        </template>
                    </HelpLabel>
                </div>
            </template>
            <template #column(record_type)="{ row }">
                <Tag v-if="row.record_type === 'legacy'">Legacy</Tag>
                <Tag v-else-if="row.record_type === 'manual'" type="info">Manual</Tag>
                <Tag v-else-if="row.record_type === 'imported'" type="success">Imported</Tag>
                <Tag v-else-if="row.record_type === 'eventbrite'" type="warning">Eventbrite</Tag>
            </template>
            <template #column(sold_at)="{ row }">
                {{ localTimestamp(row.sold_at) }}
            </template>
            <template #column(name)="{ row }">
                {{ row.ticket_sale.ticket_type.name }}
            </template>
            <template #column(reference)="{ row }">
                {{ row.ticket_sale.reference }}
            </template>
            <template #column(supplier_name)="{ row }">
                <span v-if="!row.ticket_sale.supplier" class="text-muted">Direct Sales</span>
                <span v-else>{{ row.ticket_sale.supplier.name }}</span>
            </template>
            <template #column(status)="{ row }">
                <Tag v-if="row.is_refunded" type="warning">Refund</Tag>
                <Tag v-else-if="row.is_cancelled" type="danger">Cancellation</Tag>
                <Tag v-else type="info">Sale</Tag>
            </template>
            <template #column(ticket_price)="{ row }">
                {{ localise(row.ticket_price, 2, true) }}
            </template>
            <template #column(rebate)="{ row }">
                {{ localise(row.data.kickback ? row.data.kickback : 0, 2, true) }}
            </template>
            <template #empty>
                <div class="text-center text-muted">
                    There are no records to display.
                    <a :href="route('account.event.tickets', { page: 'transactions' })">Reset your filters</a>
                </div>
            </template>
        </DataTable>
        <ExportTicketOrders ref="exportTicketOrdersModal" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import HelpLabel from '../../widgets/HelpLabel.vue';
import Dropdown from '../../controls/Dropdown.vue';
import ExportTicketOrders from './Transactions/ExportTicketOrders.vue';
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Select from '@/js/components/controls/Select.vue';
import Tag from '@/js/components/Tag.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { uniqueArray, localTimestamp } from '@/js/utils.js';

export default {
    components: {
        HelpLabel,
        Dropdown,
        ExportTicketOrders,
        Button,
        Checkbox,
        Select,
        Tag,
        DataTable,
    },
    data() {
        const store = useDataStore();

        return {
            isLoading: false,
            showingRecordTypes: ['legacy', 'manual', 'imported', 'eventbrite', 'skiddle'],
            showingTicketSeller: null,
            showingPeriod: 'all',
            customRangeStart: null,
            customRangeEnd: null,
            store: store,
        };
    },
    computed: {
        availableTicketSellers() {
            const availableSuppliers = uniqueArray(
                Object.values(this.store.get('current_event.ticket_types'))
                    .map((t) => t.ticket_sales.map((s) => s.supplier_id))
                    .flat()
            );
            return Object.values(this.store.get('account.suppliers')).filter(
                (s) => s.supplier_type === 1 && availableSuppliers.includes(s.id)
            );
        },
        dataEndpoint() {
            return route('api.account.event.tickets.orders', {
                types: this.showingRecordTypes.join(','),
                supplier: this.showingTicketSeller ?? '',
                start: this.showingPeriod === 'custom' ? (this.customRangeStart ?? '') : '',
                end: this.showingPeriod === 'custom' ? (this.customRangeEnd ?? '') : '',
            });
        },
    },
    watch: {
        perPage() {
            this.reloadData();
        },
        showingRecordTypes: {
            deep: true,
            handler() {
                this.reloadData();
            },
        },
        showingPeriod() {
            this.reloadData();
        },
        customRangeStart() {
            this.reloadData();
        },
        customRangeEnd() {
            this.reloadData();
        },
        showingTicketSeller() {
            this.reloadData();
        },
    },
    mounted() {
        this.reloadData();
    },
    methods: {
        localTimestamp,
        reloadData() {
            this.$refs.table.debouncedRefreshData();
        },
        exportData() {
            this.$refs.exportTicketOrdersModal.open();
        },
        changeShowTypes(type) {
            this.showingRecordTypes.includes(type)
                ? (this.showingRecordTypes = this.showingRecordTypes.filter((t) => t !== type))
                : this.showingRecordTypes.push(type);
        },
    },
};
</script>
