<template>
    <Tooltip ref="floaty" :placement="position" :triggers :disabled class="tw-w-fit">
        <slot />

        <template #popper>
            <span v-if="label">{{ label }}</span>
            <slot v-else name="content" />
        </template>
    </Tooltip>
</template>

<script setup>
/**
 * <Tooltip label="I am the label when hovered"><button /></Tooltip>
 *
 * <Tooltip><button /> <template #content>I am the content when hovered</template></Tooltip>
 */
import 'floating-vue/dist/style.css';
import { Tooltip } from 'floating-vue';
import { ref, watchEffect } from 'vue';

const props = defineProps({
    position: {
        type: String,
        default: 'top',
    },
    label: {
        type: String,
        default: null,
        required: false,
    },
    triggers: {
        type: Array,
        default: () => ['hover'],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    alwaysOpen: {
        type: Boolean,
        default: false,
    },
});

const floaty = ref();
const triggers = ref(props.alwaysOpen ? [] : props.triggers);

watchEffect(() => {
    if (!props.disabled && props.alwaysOpen) {
        floaty.value.show();
    }
});
</script>
