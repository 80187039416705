<template>
    <div class="tw-relative">
        <span
            v-if="model === undefined"
            class="tw-absolute tw-font-system tw-top-[6px] tw-left-3 tw-text-xs tw-text-gray-400 tw-text-sm tw-font-medium tw-mb-[5px] tw-block"
        >
            {{ placeholder }}
        </span>
        <select
            v-model="model"
            class="tw-px-2 tw-py-[5px] tw-text-sm tw-w-full tw-text-gray-900 tw-text-xs tw-border tw-border-gray-300 tw-cursor-pointer tw-rounded-[4px] hover:tw-border-gray-400 focus:tw-ring-blue-500 disabled:tw-border-gray-200 disabled:tw-bg-gray-50 disabled:tw-cursor-default focus:tw-border-gray-500"
            :disabled="disabled || loading"
            :multiple="multiple"
            :size="size"
            :required="required"
        >
            <slot></slot>
        </select>
        <ContainedLoader v-if="loading" />
    </div>
</template>
<script setup>
import ContainedLoader from '@/js/components/controls/ContainedLoader.vue';

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: 'Select an option',
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const model = defineModel({
    type: [String, Number, Array],
    default: undefined,
});
</script>
