<template>
    <div
        :class="disabled ? 'conversion-disabled' : 'conversion-input'"
        class="tw-inline-flex tw-items-center"
        :style="`--percentage-select-input-value: '${percentageFlag ? '%' : toggleSymbol}'`"
    >
        <Tooltip :disabled="forcePercentage" label="Click to toggle between percentage and currency" placement="top">
            <Button
                :active="percentageFlag"
                :disabled="disabled || lock || forcePercentage"
                class="tw-w-[32px] !tw-px-0 tw-rounded-r-none tw-border-r-0"
                @click="toggleType"
            >
                <span v-html="percentageFlag ? '%' : toggleSymbol" />
            </Button>
        </Tooltip>
        <NumberInput
            v-model="inputValue"
            :disabled
            :loading
            :step="percentageFlag ? 0.1 : 0.01"
            :dp="2"
            input-class="tw-rounded-l-none tw-w-0 tw-min-w-[110px]"
        />
    </div>
</template>

<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';

export default {
    components: {
        Button,
        Tooltip,
        NumberInput,
    },
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        baseValue: {
            type: [String, Number],
            default: null,
        },
        step: {
            type: Number,
            default: 0.01,
        },
        currency: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'is-medium',
        },
        percentage: {
            type: Boolean,
            default: false,
        },
        lock: {
            type: Boolean,
            default: false,
        },
        forcePercentage: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        defaultInputValue: {
            type: [Number, String],
            default: null,
        },
    },
    emits: ['update:modelValue'],
    data() {
        const store = useDataStore();
        return {
            store: store,
            percentageFlag: this.forcePercentage || this.percentage,
            inputValue: 0,
            initialValue: 0,
        };
    },
    computed: {
        toggleSymbol() {
            return this.currency ? this.store.get('current_event.currency_symbol') : '#';
        },
        decimalPlaces() {
            return this.step.toString().split('.')[1].length ?? 2;
        },
    },
    watch: {
        inputValue() {
            this.calculateOutputValue();
        },
        modelValue() {
            this.calculateOutputValue();
        },
    },
    mounted() {
        // Only calculate a percentage if there is a base value
        // Otherwise treat this component as a number input for percentage

        if (this.modelValue > 0) {
            this.inputValue = parseFloat(this.modelValue).toFixed(this.decimalPlaces);
        }

        if ((this.forcePercentage || this.percentage) && this.baseValue !== null) {
            if (this.defaultInputValue) {
                this.inputValue = this.defaultInputValue;
            } else {
                this.inputValue = ((100 / this.baseValue) * this.inputValue).toFixed(this.decimalPlaces);
            }
        } else {
            this.inputValue = parseFloat(this.modelValue).toFixed(this.decimalPlaces);
        }

        this.initialValue = this.inputValue;
    },
    methods: {
        toggleType() {
            if (this.disabled || this.lock) {
                return;
            }
            this.percentageFlag = !this.percentageFlag;
            if (this.percentageFlag && this.baseValue !== null) {
                this.inputValue = ((100 / this.baseValue) * this.inputValue).toFixed(this.decimalPlaces);
            } else if (this.baseValue) {
                this.inputValue = (this.baseValue * (this.inputValue / 100)).toFixed(this.decimalPlaces);
            }
            this.calculateOutputValue();
        },
        calculateOutputValue() {
            let outputValue = 0;
            if (this.inputValue === '') {
                this.inputValue = 0;
            }
            if (this.percentageFlag && this.baseValue !== null) {
                outputValue = (parseFloat(this.baseValue) * (parseFloat(this.inputValue) / 100)).toFixed(
                    this.decimalPlaces
                );
            } else {
                outputValue = parseFloat(this.inputValue).toFixed(this.decimalPlaces);
            }
            this.$emit('update:modelValue', outputValue);
        },
    },
};
</script>
<style lang="scss" scoped>
.conversion-input {
    display: flex;

    & > input {
        margin-left: 2px;

        &.is-small {
            width: 75px !important;
            height: 26px;
        }

        &.is_form {
            width: 75px !important;
            height: 26px;
        }
    }
}
.conversion-disabled {
    display: flex;

    & > input {
        background: rgba(black, 0.25);
        color: rgba(black, 0.25);
        border: 1px solid rgba(black, 0.75);
        margin-left: 2px;

        &.is-small {
            width: 92px !important;
            height: 26px;
        }

        &:disabled {
            background: rgba(black, 0.1) !important;
            color: rgba(white, 0.9);
            border: 1px solid rgba(black, 0.1);
        }
    }
}

.conversion-input,
.conversion-disabled {
    & > .switch {
        margin-right: 0 !important;

        &.is-small > span.check {
            height: 26px !important;
        }

        &.is-medium > span.check {
            height: 50px !important;
        }

        & > span.check {
            &:before {
                content: var(--percentage-select-input-value) !important;
                color: black;
                height: 100% !important;
                align-content: center;
                text-align: center;
            }
        }
    }
}
</style>
