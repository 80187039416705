<template>
    <MinimalPage no-padding>
        <template #before>
            <div class="logo-display">
                <img src="@/images/icon-white.svg" />
                <h1>eventwise</h1>
            </div>
            <div v-if="currentUser" class="user-actions">
                <a v-if="currentUser.is_superadmin" class="py-3" :href="route('admin.index')">Admin</a>
                <a class="py-3" :href="route('me')">
                    <i class="mdi mdi-account"></i>
                    {{ currentUser.name }}
                </a>
                <a :href="route('auth.logout')">
                    <i class="mdi mdi-logout"></i>
                    Logout
                </a>
            </div>
        </template>
        <div class="text-center">
            <Banner :type="code && code.startsWith('5') ? 'is-danger' : null">
                <h3
                    class="mb-1"
                    :class="{
                        'text-danger': code && code.startsWith('5'),
                    }"
                >
                    {{ code ? code + ' Error' : 'Something went wrong' }}
                </h3>
                <template v-if="reference">
                    We have been automatically notified of this error.
                    <br />
                    If you have an urgent issue please get in touch quoting the following reference:
                    <br />
                    <div
                        class="mt-3 mx-auto p-2 border rounded bg-white text-dark"
                        style="font-family: monospace; max-width: 75%"
                    >
                        {{ eventwiseVersion }}
                        <br />
                        {{ reference }}
                    </div>
                </template>
                <template v-else>
                    {{ message }}
                </template>
                <div class="mt-3">
                    <Button class="tw-flex tw-gap-2" @click="returnHome()">
                        <i class="mdi mdi-home-account"></i>
                        Return Home
                    </Button>
                </div>
            </Banner>
        </div>
    </MinimalPage>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import MinimalPage from './MinimalPage.vue';
import Banner from '@/js/components/Layout/Banner.vue';

export default {
    components: {
        MinimalPage,
        Banner,
    },
    props: {
        code: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        reference: {
            type: String,
            default: null,
        },
    },
    data() {
        const store = useDataStore();
        return {
            currentUser: store.get('current_user'),
            eventwiseVersion: window.Eventwise.version,
        };
    },
    methods: {
        returnHome() {
            window.location.href = route('onboarding');
        },
    },
};
</script>
