import { useDataStore } from '@/js/stores/DataStore.js';
import { format, formatDistance } from 'date-fns';
import { DateTime } from 'luxon';
import { createFetch } from '@vueuse/core';
import { diffWordsWithSpace } from 'diff';

const currencyLocaleMap = {
    GBP: 'en-GB',
    EUR: 'en-GB',
    USD: 'en-US',
    AUD: 'en-AU',
    CHF: 'de-CH',
    JPY: 'ja-JA',
    RUB: 'ru-RU',
    SEK: 'sv-SE',
    DKK: 'da-DA',
    ZAR: 'en-ZA',
};

const currencyToLocale = function (currency_code) {
    if (typeof currency_code === 'string') {
        currency_code = currency_code.toUpperCase();
        let currency_index = Object.keys(currencyLocaleMap).indexOf(currency_code);
        if (currency_index > -1) {
            return Object.values(currencyLocaleMap)[currency_index];
        }
    }
    return false;
};

export function formatCurrency(value, places = 2, currency = 'GBP', rate = 1) {
    if (value === null || value === undefined) {
        value = 0;
    }
    return (value * rate).toLocaleString(navigator.language, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: places,
        maximumFractionDigits: places,
    });
}

export function capitalise(value) {
    return value
        .split(' ')
        .map((element) => {
            return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
        })
        .join(' ');
}

export function toLocal(timestamp, timezone = 'system', isSql = false) {
    if (isSql) {
        return DateTime.fromSQL(timestamp).setZone(timezone);
    }
    return DateTime.fromISO(timestamp).setZone(timezone);
}

const currentLocale = function (currency_code = false) {
    // If setting the current locale verify that we have a matched locale for that currency code
    if (typeof currency_code === 'string' && Object.keys(currencyLocaleMap).includes(currency_code)) {
        let locale = currencyToLocale(currency_code);
        sessionStorage.setItem('current-locale', locale);
        return locale;
    }

    // Get current locale from the users session, if we can't match it to a currency or it doesn't exist default it to en-GB
    let locale = sessionStorage.getItem('current-locale');
    if (!locale || !Object.values(currencyLocaleMap).includes(locale)) {
        sessionStorage.setItem('current-locale', 'en-GB');
        locale = 'en-GB';
    }
    return locale;
};

export function localise(value, places = 0, currency_symbol = false, target_currency = null, conversion_rate = 1) {
    const parseDecimal = function (value) {
        return Math.round(parseFloat(value * 100)) / 100;
    };
    // This is to address issues around toLocaleString and parseFloat's rounding
    value = parseDecimal(value) * conversion_rate;
    const locale = currentLocale();
    const store = useDataStore();
    const eventCurrencyCode =
        store.get('current_event.currency_code') != null ? store.get('current_event.currency_code') : null;
    const accountCurrencyCode = store.get('account.currency_code') != null ? store.get('account.currency_code') : null;

    // set min value as zero
    if (1 / value === -Infinity) {
        value = 0;
    }

    // If no currency symbol required, return without one.
    if (!currency_symbol) {
        return value.toLocaleString(locale, {
            minimumFractionDigits: places,
            maximumFractionDigits: places,
        });
    }
    // Get the index of the locale symbol from the currency map, below.
    const localeIndex = Object.values(currencyLocaleMap).indexOf(locale, {
        minimumFractionDigits: places,
        maximumFractionDigits: places,
    });

    // use the event over the account, if it exists
    let returningCode = accountCurrencyCode && accountCurrencyCode !== '' ? accountCurrencyCode : '';
    returningCode = eventCurrencyCode && eventCurrencyCode !== '' ? eventCurrencyCode : returningCode;
    returningCode = returningCode !== '' ? returningCode : Object.keys(currencyLocaleMap)[localeIndex];

    // if a valid value (base 0) use code to return with it
    if (localeIndex > -1) {
        return value.toLocaleString(locale, {
            style: 'currency',
            currency: target_currency ? target_currency : returningCode,
            minimumFractionDigits: places,
            maximumFractionDigits: places,
        });
    }

    return value.toLocaleString(locale, {
        minimumFractionDigits: places,
        maximumFractionDigits: places,
    });
}

const currencyToSymbol = function (currency_code) {
    const currencySymbolMap = {
        GBP: '£',
        EUR: '€',
        USD: '$',
        AUD: '$',
        CHF: 'CHF',
        JPY: '¥',
        RUB: '₽',
        SEK: 'kr',
        DKK: 'kr',
        ZAR: 'R',
    };
    if (typeof currency_code === 'string') {
        currency_code = currency_code.toUpperCase();
        let currency_index = Object.keys(currencySymbolMap).indexOf(currency_code);
        if (Object.keys(currencySymbolMap).indexOf(currency_code) > -1) {
            return Object.values(currencySymbolMap)[currency_index];
        }
    }
    return false;
};
export function eventCurrencySymbol() {
    return currencyToSymbol(eventCurrencyCode);
}

export const toast = (title, content, type = 'info', subtitle = '') =>
    dispatchEvent(
        new CustomEvent('toast', {
            detail: {
                title,
                content,
                type,
                subtitle,
            },
        })
    );

export function monetary(value) {
    if (value) {
        return parseFloat(value.toString().replace(/[^0-9\.]/, '')).toFixed(2);
    }
    const missingValue = '0.0000';
    return parseFloat(missingValue).toFixed(2);
}

export const setTitle = (title = null, appName = 'Eventwise') => {
    document.title = (title ? title + ' | ' : '') + appName;
};

export const htmlDiff = (oldString, newString) => {
    const diff = diffWordsWithSpace(oldString, newString);
    let html = '';
    diff.forEach((part) => {
        const classes = [];
        if (part.added) {
            classes.push('tw-bg-green-50 tw-text-green-600');
        }
        if (part.removed) {
            classes.push('tw-bg-red-50 tw-text-red-600');
        }
        html += `<span class="${classes.join(' ')}">${part.value}</span>`;
    });
    return html;
};

export const getPlatform = () => {
    const userAgent = window.navigator.userAgent;
    let os = null;

    const isIOS =
        (/iPad|iPhone|iPod/.test(userAgent) ||
            (/Mac|Mac OS|MacIntel/gi.test(userAgent) && (navigator.maxTouchPoints > 1 || 'ontouchend' in document))) &&
        !window.MSStream;

    if (/Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(userAgent)) {
        os = 'Mac';
    } else if (isIOS) {
        os = 'iOS';
    } else if (/'Win32|Win64|Windows|Windows NT|WinCE/gi.test(userAgent)) {
        os = 'Windows';
    } else if (/Android/gi.test(userAgent)) {
        os = 'Android';
    } else if (/Linux/gi.test(userAgent)) {
        os = 'Linux';
    }

    return os;
};

export const flatpickrConfig = {
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d',
    altInputClass:
        'tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400',
    locale: {
        firstDayOfWeek: 1,
    },
};

export const obj2form = (object) => {
    let formData = new FormData();
    Object.keys(object).forEach((key) => {
        if (Array.isArray(object[key])) {
            object[key].forEach((item, index) => {
                if (!Array.isArray(item) && !(item instanceof File) && typeof item === 'object') {
                    Object.keys(item).forEach((itemKey) => {
                        formData.append(
                            key + '[' + index + '][' + itemKey + ']',
                            item[itemKey] === null ? '' : item[itemKey]
                        );
                    });
                    return;
                }
                formData.append(key + '[]', item === null ? '' : item);
            });
            return;
        } else if (object[key] && typeof object[key] === 'object') {
            Object.keys(object[key]).forEach((objectKey) => {
                formData.append(
                    key + '[' + objectKey + ']',
                    object[key][objectKey] === null ? '' : object[key][objectKey]
                );
            });
            return;
        }
        formData.append(key, object[key] === null ? '' : object[key]);
    });
    return formData;
};

export function uniqueArray(arr, key = null) {
    if (key === null) {
        return [...new Set(arr)];
    }
    return arr.filter((item, index, self) => self.findIndex((t) => t[key] === item[key]) === index);
}

export const gross2net = (grossValue, taxRate) => {
    return (grossValue / (100 + taxRate)) * 100;
};
export const ordinalSuffix = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + 'st';
    }
    if (j == 2 && k != 12) {
        return i + 'nd';
    }
    if (j == 3 && k != 13) {
        return i + 'rd';
    }
    return i + 'th';
};

export const hashStringToColour = (str) => {
    const djb2 = (str) => {
        // This method uses djb2 to convert a string to a unique hash
        let hash = 5381;
        for (let i = 0; i < str.length; i++) {
            hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
        }
        return hash;
    };
    const hash = djb2(str);
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;
    return (
        '#' + ('0' + r.toString(16)).substr(-2) + ('0' + g.toString(16)).substr(-2) + ('0' + b.toString(16)).substr(-2)
    );
};
export const contrastingTextColour = (bgColor, lightColor, darkColor) => {
    let color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB
    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
};
export const setRoute = (route, params = {}, defaults = ['account', 'event'], preserveQueryParams = true) => {
    const ziggyRoute = Ziggy.routes[route];
    if (!ziggyRoute) {
        // eslint-disable-next-line no-console
        console.error('Unknown route provided to setRoute');
        return;
    }
    if (defaults.length) {
        defaults.forEach((defaultParam) => {
            if (
                window.route().params[defaultParam] &&
                !params[defaultParam] &&
                Object.keys(ziggyRoute.bindings).includes(defaultParam)
            ) {
                params[defaultParam] = window.route().params[defaultParam];
            }
        });
    }
    let uri = '/' + ziggyRoute.uri;
    Object.keys(params).forEach((param) => {
        uri = uri.replaceAll(`{${param}}`, params[param]);
        uri = uri.replaceAll(`{${param}?}`, params[param]);
    });
    // Replace any remaining optional parameters that have not been provided
    uri = uri.replaceAll(/ *\{[^}]*\?} */gi, '');
    uri = uri.replaceAll(/\/\/+/g, '/');
    if (preserveQueryParams) {
        uri = uri + window.location.search;
    }
    const routeData = {
        route: route,
        uri: uri,
        params: params,
    };
    history.pushState(routeData, '', uri);
    window.Eventbus.$emit('routeChanged', routeData);
    window.Eventbus.$emit('pageChanged');
};
export const flushPageEvents = () => {
    if (window.Eventbus._events.pageChanged) {
        removeEventListener('pageChanged', window.Eventbus._events.pageChanged);
    }
};
export const isValidEmail = (email) => {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
export const localTime = (value, isoMode = true) => {
    const dt = isoMode
        ? DateTime.fromISO(value, {
              zone: 'utc',
          })
        : DateTime.fromSQL(value, {
              zone: 'utc',
          });
    return dt.toLocal();
};
export const localTimestamp = (value, isoMode = true) => {
    return localTime(value, isoMode).toLocaleString(DateTime.DATETIME_MED);
};
export const relativeTimestamp = (value, zone = 'utc', isoMode = true) => {
    const relativeStamp = isoMode
        ? DateTime.fromISO(value, {
              zone: zone,
          }).toRelative()
        : DateTime.fromSQL(value, {
              zone: zone,
          }).toRelative();
    return relativeStamp == '0 seconds ago' ? 'now' : relativeStamp;
};

export const timeAgo = (value) => {
    return formatDistance(new Date(value), new Date(), {
        addSuffix: true,
    });
};
export const formatDate = (value, dateFormat = 'yyyy-MM-dd') => {
    if (!value) {
        return 'Invalid Date';
    }
    return format(new Date(value), dateFormat) + ' UTC';
};

export const initials = (value) => {
    if (value) {
        const initials = value.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
        return initials.join('');
    }
};

function getCookie(name) {
    if (!document.cookie) {
        return null;
    }

    const xsrfCookies = document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((c) => c.startsWith(name + '='));

    if (xsrfCookies.length === 0) {
        return null;
    }

    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}
export const useFetch = createFetch({
    options: {
        beforeFetch({ options }) {
            options.headers['Accept'] = 'application/json';

            const token = getCookie('XSRF-TOKEN');
            if (token) {
                options.headers['X-XSRF-TOKEN'] = token;
            }

            return { options };
        },
    },
});

const Auth = window.Auth;
const route = window.route;
const eventCurrencyCode = window.eventCurrencyCode;
export { eventCurrencyCode, Auth, route };
