<template>
    <Modal ref="modal" title="Tag Editor" paddingless size="is-xxxlarge" @closed="resetData()">
        <Tabs
            class="tw-w-full tw-mt-2"
            :tabs="[
                { title: 'Tags', name: 'tag_editor' },
                { title: 'Events', name: 'model_tags' },
            ]"
        >
            <template #tab(tag_editor)>
                <TagEditor reporting />
            </template>
            <template #tab(model_tags)>
                <ModelTags tab-name="Events" class-name="App\Models\Event" reporting />
            </template>
        </Tabs>
    </Modal>
</template>

<script>
import TagEditor from '@/js/components/pages/Account/Settings/GroupTags/TagEditor.vue';
import ModelTags from '@/js/components/pages/Account/Settings/GroupTags/ModelTags.vue';
import Modal from '@/js/components/Modal.vue';
import Tabs from '@/js/components/widgets/Tabs.vue';

export default {
    components: {
        ModelTags,
        TagEditor,
        Modal,
        Tabs,
    },

    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        resetData() {},
        openModal() {
            this.$refs.modal.open();
        },
        closeModal() {
            this.$refs.modal.close();
        },
    },
};
</script>

<style scoped lang="scss">
.w-quarter {
    width: 25%;
    height: 320px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.w-three-quarters {
    width: 75%;
    height: 320px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.full-width {
    width: 100%;
}
.panel {
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}
.new-tag-header {
    width: 100%;
    height: 30px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.selectable {
    &:hover {
        cursor: pointer;
    }
}
.color-picker {
    width: 40%;
    height: 50px;
    margin: 0 10px 10px 10px;
}
.color-picker-label {
    width: 40%;
    margin: 0 10px 0 10px;
}
</style>
