<template>
    <div class="row buefy-wrapper mt-4">
        <div class="col-12 col-lg-6 col-xl-5">
            <div class="card ew-user-profile-card tw-p-3">
                <div class="ew-user-profile-image" :style="avatarStyles">
                    <span v-if="!currentUser.avatar_url">{{ currentUser.initials }}</span>
                    <div class="ew-user-profile-image-action">
                        <Tooltip label="Upload an image" position="bottom">
                            <button @click="changeProfileImage()"><i class="mdi mdi-upload"></i></button>
                        </Tooltip>
                    </div>
                    <input
                        ref="profileImageInput"
                        type="file"
                        accept="image/png,image/jpeg"
                        hidden
                        @change="handleProfileImageChange"
                    />
                </div>
                <h3 class="text-center"><EditableText v-model="currentUser.name" :max-length="64" /></h3>
                <div class="row p-3">
                    <div v-if="socialProvider" class="col-12 mb-4">
                        <Banner>
                            Your account is connected via {{ capitalise(socialProvider.provider) }}
                            <br />
                            Some options are unavailable and changes must be made on the connecting platform instead
                        </Banner>
                    </div>
                    <div class="col-3 align-self-center"><label class="mb-0">Email Address</label></div>
                    <div class="col-9">
                        {{ currentUser.email }}
                        <a class="float-right" href="#" @click.prevent="changeEmailAddress()">Change email</a>
                    </div>
                    <div class="col-3 mt-4"><label class="mb-0">Password</label></div>
                    <div class="col-9 mt-4">
                        <span v-if="socialProvider" class="text-muted">Unavailable (see above)</span>
                        <a v-else href="#" @click.prevent="changePassword()">Change Password</a>
                    </div>
                    <div class="col-3 mt-4"><label class="mb-0">Authentication</label></div>
                    <div class="col-9 mt-4">
                        <a v-if="!currentUser.mfa_enabled" href="#" @click.prevent="changeTwoFactor()">
                            Enable Two-Factor Authentication
                        </a>
                        <div v-else>
                            <Tag type="success">
                                <i class="mdi mdi-check-circle"></i>
                                Two-factor authentication enabled
                            </Tag>
                            <a href="#" class="float-right" @click.prevent="changeTwoFactor()">Manage</a>
                        </div>
                    </div>
                    <div class="col-3 mt-4 align-self-center">
                        <label class="mb-0" for="userTimezone">Timezone</label>
                    </div>
                    <div class="col-9 mt-4">
                        <Select id="userTimezone" v-model="currentUser.timezone" expanded>
                            <option v-for="timezone in availableTimezones" :key="timezone" :value="timezone">
                                {{ timezone }}
                            </option>
                        </Select>
                    </div>
                    <div class="col-3 mt-4"><label class="mb-0">Email Preferences</label></div>
                    <div class="col-9 mt-4">
                        <Checkbox v-model="currentUser.optout_notifications">Opt out of notification emails</Checkbox>
                    </div>
                </div>
                <small v-if="!lastSaved && !isSaving" class="status-msg text-muted mt-1">Not yet saved</small>
                <small v-else-if="isSaving" class="status-msg text-muted mt-1">
                    <div class="loader"></div>
                    Saving your changes
                </small>
                <small v-else-if="lastSaved" :key="updateKey" class="status-msg text-muted mt-1">
                    <i class="mdi mdi-check-circle"></i>
                    Saved{{
                        (' ' + lastSaved.toRelative())
                            .replace(' 0 seconds ago', ' just now')
                            .replace(' in 0 seconds', ' just now')
                    }}
                </small>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-7">
            <ew-card title="Your Recent Activity" paddingless>
                <div class="bg-light pt-1 pb-2 px-3">
                    This panel shows a condensed version of your recent activity, it is designed to be used in
                    situations where you think you may have made an accidental change to an entry without realising. Not
                    all changes are displayed here, if you require further assistance please get in touch with support.
                </div>
                <DataTable
                    :columns="[
                        { title: 'Type', field: 'type', width: 140 },
                        { title: 'Action', field: 'action' },
                        { title: 'Details', field: 'details' },
                        { title: 'Occurred', field: 'occurred' },
                    ]"
                    :endpoint="route('api.user.activity')"
                >
                    <template #column(type)="{ row }">
                        {{ row.activityable_type.replace('App\\Models\\', '') }}
                    </template>
                    <template #column(action)="{ row }">
                        <span v-if="!row.description" class="text-muted">None</span>
                        <span v-else>{{ row.description }}</span>
                    </template>
                    <template #column(details)="{ row }">
                        <span v-if="!row.notes" class="text-muted">None</span>
                        <span v-else>{{ row.notes }}</span>
                    </template>
                    <template #column(occurred)="{ row }">
                        {{ DateTime.fromISO(row.occurred_at).toRelative() }}
                    </template>
                    <template #empty>
                        <div class="text-center text-muted">
                            There&apos;s no activity to show here just yet, check back later!
                        </div>
                    </template>
                </DataTable>
            </ew-card>
        </div>
        <ChangeEmail ref="changeEmailModal" />
        <ChangePassword ref="changePasswordModal" />
        <ChangeTwoFactor ref="changeTwoFactorModal" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import EditableText from '../../controls/EditableText.vue';
import ChangeEmail from './Personal/ChangeEmail.vue';
import ChangePassword from './Personal/ChangePassword.vue';
import ChangeTwoFactor from './Personal/ChangeTwoFactor.vue';
import axios from 'axios';
import Tooltip from '@/js/components/Tooltip.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Select from '@/js/components/controls/Select.vue';
import Tag from '@/js/components/Tag.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { DateTime } from 'luxon';

import { hashStringToColour, contrastingTextColour, capitalise, toast } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        EditableText,
        ChangeEmail,
        ChangePassword,
        ChangeTwoFactor,
        Tooltip,
        Banner,
        Select,
        Tag,
        Checkbox,
    },
    data() {
        const store = useDataStore();

        return {
            isSaving: false,
            lastSaved: null,
            currentUser: store.get('current_user'),
            availableTimezones: store.get('timezones'),
            updateInterval: null,
            updateKey: 0,
            userActivity: null,
            store: store,
            DateTime,
        };
    },
    computed: {
        socialProvider() {
            if (!this.currentUser || !this.currentUser.providers.length) {
                return null;
            }
            return this.currentUser.providers[this.currentUser.providers.length - 1];
        },
        avatarStyles() {
            if (!this.currentUser) {
                return;
            } else if (this.currentUser.avatar_url) {
                return 'background-image: url(' + this.currentUser.avatar_url + ')';
            }
            const backgroundColour = hashStringToColour(this.currentUser.name);
            const foregroundColour = contrastingTextColour(backgroundColour, '#FFFFFF', '#303e67');
            return `background-color: ${backgroundColour}; color: ${foregroundColour};`;
        },
    },
    watch: {
        'currentUser.name': function (newVal) {
            const initialComponents = newVal.split(' ');
            if (initialComponents.length === 1) {
                this.currentUser.initials = initialComponents[0][0].toUpperCase();
            } else {
                this.currentUser.initials =
                    initialComponents[0][0].toUpperCase() +
                    initialComponents[initialComponents.length - 1][0].toUpperCase();
            }
            this.debouncedUpdateUser();
        },
        'currentUser.email': function () {
            this.debouncedUpdateUser();
        },
        'currentUser.timezone': function () {
            this.debouncedUpdateUser();
        },
        'currentUser.optout_notifications': function () {
            this.debouncedUpdateUser();
        },
    },
    mounted() {
        this.updateInterval = setInterval(() => this.updateKey++, 1000);
    },
    destroyed() {
        clearInterval(this.updateInterval);
    },
    methods: {
        capitalise,
        updateUser() {
            axios
                .post(route('api.user.update'), this.currentUser)
                .then(() => (this.lastSaved = DateTime.now()))
                .finally(() => (this.isSaving = false));
        },
        debouncedUpdateUser: debounce(function () {
            this.updateUser();
        }, 500),
        changeEmailAddress() {
            this.$refs.changeEmailModal.open();
        },
        changePassword() {
            this.$refs.changePasswordModal.open();
        },
        changeTwoFactor() {
            this.$refs.changeTwoFactorModal.open();
        },
        changeProfileImage() {
            this.$refs.profileImageInput.click();
        },
        handleProfileImageChange(e) {
            if (!e.target || !e.target.files.length) {
                return;
            }
            const file = e.target.files[0];
            if (!file) {
                return;
            }
            this.isLoading = true;
            const formData = new FormData();
            formData.append('photo', file);
            axios
                .post(route('api.user.updatePhoto'), formData)
                .then(({ data }) => {
                    this.store.set('current_user.avatar', data.avatar);
                    this.store.set('current_user.avatar_url', data.avatar_url);
                    toast('Photo Updated', 'Your profile photo has been updated successfully', 'success');
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.ew-user-profile-card {
    position: relative;

    &:has(.ew-user-profile-image),
    &:has(.ew-account-profile-image) {
        padding-top: 70px !important;
        margin-top: 64px !important;
    }

    & > .ew-user-profile-image,
    & > .ew-account-profile-image {
        width: 128px;
        height: 128px;
        border-radius: 50%;
        border: 4px solid white;
        position: absolute;
        top: -64px;
        left: calc(50% - 64px);
        background-size: cover !important;
        background: rgb(240, 240, 240) no-repeat center center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 42px;
        font-weight: 400;

        &:before {
            content: '';
            position: absolute;
            top: -5px;
            left: -5px;
            width: 130px;
            height: 130px;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(black, 0.05);
            background: rgba(black, 0.066);
            z-index: -1;
        }

        & > .ew-user-profile-image-action {
            position: absolute;
            bottom: 0;
            right: -5px;

            button {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: $eventwise-primary;
                color: white;
                font-size: 18px;
                padding: 0 1px 0 0;
                border: 3px solid white;

                &:hover {
                    background: $eventwise-primary-light;
                }
            }
        }
    }

    .status-msg {
        display: flex;
        align-items: center;
        margin-left: 4px;
        gap: 5px;
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
