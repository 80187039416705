<template>
    <div class="buefy-wrapper">
        <Modal
            id="createTaxRateModal"
            ref="createTaxRateModal"
            size="is-large"
            title="Add Tax Rate"
            :loading="isLoading"
            @closed="resetData()"
        >
            <div>
                <div class="tw-mb-3">Enter a percentage value below to create a new saved tax rate.</div>
                <div class="tw-flex tw-mb-2">
                    <FormField label="Name" class="tw-pr-2 tw-flex-1">
                        <Textbox v-model="createName" type="text" class="tw-w-full" />
                    </FormField>
                    <FormField label="Percentage">
                        <div class="tw-flex tw-items-center">
                            <Textbox v-model="createPercentage" type="number" min="0" max="100" step=".01" />
                            <Button disabled>%</Button>
                        </div>
                    </FormField>
                </div>
                <FormField label="Type" class="tw-mb-2">
                    <Button :active="createType === 'income'" @click="createType = 'income'">Income</Button>
                    <Button :active="createType === 'expense'" @click="createType = 'expense'">Expense</Button>
                </FormField>
            </div>
            <template #footer>
                <Button class="!tw-mr-auto" @click="closeModal()">Close</Button>
                <Button type="is-success" :disabled="isLoading" @click="createRate()">Create</Button>
            </template>
        </Modal>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Textbox,
    },
    data() {
        const store = useDataStore();
        return {
            isOpen: false,
            isLoading: false,
            createName: '',
            createPercentage: 0,
            createType: '',
            store: store,
        };
    },
    mounted() {
        Eventbus.$on('taxRates:create', () => {
            this.$refs.createTaxRateModal.open();
        });
    },
    methods: {
        createRate() {
            if (!this.validateSelection()) {
                toast('Error', 'Please enter a valid name and percentage');
            }
            this.isLoading = true;
            axios
                .post(route('api.account.taxRates.create'), {
                    name: this.createName,
                    rate: this.createPercentage,
                    type: this.createType,
                })
                .then((response) => {
                    this.store.set(`account.tax_rates.${response.data.id}`, response.data);
                    this.closeModal(response.data);
                })
                .finally(() => (this.isLoading = false));
        },
        validateSelection() {
            return (
                this.createName.length && this.createPercentage >= 0 && this.createPercentage <= 100 && this.createType
            );
        },
        closeModal(data = false) {
            this.$refs.createTaxRateModal.close();
            this.resetData();
            if (data) {
                Eventbus.$emit('taxRates:closed', data);
            }
        },
        resetData() {
            this.isLoading = false;
            this.createName = '';
            this.createPercentage = '';
        },
    },
};
</script>
