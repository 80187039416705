<template>
    <div
        class="fullpage-container"
        :class="{
            'has-navbar': hasNavbar,
        }"
    >
        <div class="fullpage-header"><slot name="header"></slot></div>
        <slot></slot>
        <div class="fullpage-footer"><slot name="footer"></slot></div>
    </div>
</template>
<script>
export default {
    props: {
        hasNavbar: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
<style lang="scss">
.fullpage-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 125px);

    & > *:not(.fullpage-header):not(.fullpage-footer) {
        flex: 1;
    }

    &.has-navbar {
        height: calc(100vh - 185px);
    }
}
</style>
