<template>
    <div>
        <DataTable
            :data="purchaseOrdersData"
            class="tw-mb-0"
            centered
            :columns="[
                {
                    title: 'Status',
                    field: 'status',
                },
                {
                    title: 'Title',
                    field: 'title',
                },
                {
                    title: 'Net Value',
                    field: 'value',
                },
                {
                    title: 'Created',
                    field: 'created_at',
                },
                {
                    title: '',
                    field: 'actions',
                    width: 60,
                },
            ]"
        >
            <template #column(status)="{ row }">
                <Tag :status="row.status" />
            </template>
            <template #column(title)="{ row }">
                {{ row.title }}
            </template>
            <template #column(value)="{ row }">
                {{ formatCurrency(row.subtotal, 2, row.target_currency, row.conversion_rate) }}
            </template>
            <template #column(created_at)="{ row }">
                {{ toLocal(row.created_at).toLocaleString(DateTime.DATETIME_SHORT) }}
            </template>
            <template #column(actions)="{ row }">
                <div class="tw-flex tw-justify-end">
                    <Tooltip v-if="Auth.can('view purchase orders')" position="left" label="View">
                        <Button @click="openPO(row.id)"><i class="mdi mdi-eye"></i></Button>
                    </Tooltip>
                </div>
            </template>
        </DataTable>
        <div v-if="!disabled" class="tw-px-3">
            <Button class="tw-w-full" @click="createPO()">
                <i class="mdi mdi-plus !tw-inline"></i>
                Create a Purchase Order
            </Button>
        </div>
    </div>
</template>
<script>
import DataTable from '../../tables/DataTable.vue';
import { formatCurrency, toLocal } from '@/js/utils.js';
import Button from '../../controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        DataTable,
        Button,
        Tooltip,
        Tag,
    },
    props: {
        entry: {
            type: Object,
        },
        purchaseOrders: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['opened'],
    data() {
        return {
            purchaseOrdersData: this.purchaseOrders,
            formatCurrency,
            toLocal,
            DateTime,
        };
    },
    watch: {
        purchaseOrders: {
            handler(newVal) {
                this.purchaseOrdersData = newVal;
            },
            deep: true,
        },
    },
    methods: {
        openPO(id) {
            Eventbus.$emit('purchaseOrder:view', {
                purchaseOrder: id,
                source: {
                    emitter: 'budget:entry',
                    payload: {
                        entry: this.entry.id,
                    },
                },
            });
            this.$emit('opened');
        },
        createPO() {
            if (!Auth.can('create purchase orders')) {
                return;
            }
            Eventbus.$emit('purchase-order:create', {
                department: this.entry.category.department_id,
                category: this.entry.category.id,
                title: this.entry.name,
                supplier: this.entry.supplier_id,
                entry: this.entry.id,
            });
        },
    },
};
</script>
