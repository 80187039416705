<template>
    <div>
        <LoadingSpinner v-if="isLoading" :active="isLoading" label="Loading Information" />
        <div v-else class="buefy-wrapper multi-group-report">
            <Banner class="mt-5" type="is-primary">
                <div class="d-flex align-items-center">
                    <img src="@/images/reporting.svg" style="max-height: 95px; margin-right: 25px" />
                    <div class="flex-fill">
                        <h4 class="mb-2">Multi Group Analysis</h4>
                        <p>
                            This report shows all your Profit and Loss totals for the events in the selected groups.
                            <br />
                            The Profit and Losses Table shows the sum of Budgeted and Estimated amounts on Approved
                            Budget Entries, for all the departments, Cost and Revenue, within the selected group Events
                            <br />
                            along with the current Profit and Variance figures for those events. The Profits and Losses
                            table can be viewed graphically by selecting the Chart option.
                            <br />
                        </p>
                    </div>
                </div>
            </Banner>

            <div v-if="currentPage === 'profits'" class="w-100 py-5 my-5 card">
                <div class="d-flex justify-content-between tw-mt-5">
                    <div class="d-inline-flex w-25">
                        <FormField class="tw-mb-1 tw-ml-5" field-class="tw-flex tw-gap-1 tw-items-center">
                            <Button :active="viewType === 'table'" @click="viewType = 'table'">
                                <span>Table</span>
                            </Button>
                            <Button :active="viewType === 'chart'" @click="viewType = 'chart'">
                                <span>Chart</span>
                            </Button>
                        </FormField>
                    </div>
                    <div class="w-50 d-flex justify-content-center">
                        <h3>Multi Group Profits and Losses</h3>
                    </div>
                    <div class="w-25"></div>
                </div>
                <div v-if="tagCount > 0" class="m-5">
                    <ProfitAndLossComparison
                        v-if="!isLoading && viewType === 'table'"
                        :models="localTags"
                        :profits-and-losses="profitsAndLosses"
                        :visible-columns="tagIds"
                        :mode="mode"
                    />
                    <ProfitAndLossComparisonChart
                        v-if="!isLoading && viewType === 'chart'"
                        :events="localTags"
                        :profits-and-losses="profitsAndLosses"
                        :visible-columns="tagIds"
                    />
                </div>
                <div v-else class="py-5 w-100 text-center text-muted">
                    <i class="d-block font-18"></i>
                    Event Information has been reset, please reselect
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from '@/js/components/widgets/LoadingSpinner.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import ProfitAndLossComparison from '@/js/components/pages/Account/Reporting/Components/ProfitComparisonTable.vue';
import ProfitAndLossComparisonChart from '@/js/components/pages/Account/Reporting/Components/ProfitComparisonChart.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Button from '@/js/components/controls/Button.vue';

export default {
    components: {
        ProfitAndLossComparisonChart,
        ProfitAndLossComparison,
        Banner,
        LoadingSpinner,
        FormField,
        Button,
    },
    props: {
        tagIds: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            default: 'events',
        },
    },
    data() {
        return {
            isLoading: true,
            localTagIds: [],
            tagCount: 0,
            localTags: [],
            localEventIds: [],
            profitsAndLosses: [],
            viewType: 'table',
            currentPage: 'profits',
        };
    },
    computed: {
        eventMode() {
            return this.mode === 'events';
        },
    },
    watch: {},
    mounted() {
        this.localTagIds = this.tagIds;
        this.reloadAllData();
    },
    methods: {
        reloadAllData() {
            this.isLoading = true;
            this.localTags = [];
            this.profitsAndLosses = [];
            this.tagCount = this.tagIds.length;

            // foreach tagId
            for (const tagId of this.localTagIds) {
                // get the localTags for the tags
                this.reloadTagEvents(tagId);
            }
            this.isLoading = false;
        },
        reloadTagEvents(tagId) {
            axios
                .get(
                    route('api.account.reporting.tagAnalysis', {
                        groupTag: tagId,
                    })
                )
                .then(({ data }) => {
                    this.localTags.push(data.tag);
                    this.profitsAndLosses.push(data.profitAndLoss);
                });
        },
    },
};
</script>

<style lang="scss">
.multi-group-report {
    .reporting-options-row {
        margin-top: 10px;
    }
    .table {
        border-radius: 5px;
        overflow: hidden;
    }
}
</style>
