<template>
    <div>
        <label :for="name">{{ label }}</label>
        <div class="tw-gap-3 tw-w-full tw-flex tw-flex-wrap">
            <label v-for="(option, i) of options" :key="`option-${i}`" class="tw-flex tw-items-center">
                <input
                    type="radio"
                    :name="name"
                    class="tw-form-radio"
                    :required="required"
                    :model-value="option.value"
                    :disabled="disabled"
                    :checked="modelValue === option.value"
                    :aria-describedby="`${name}-error`"
                    @change="emit('update:model-value', option.value)"
                />
                <span class="text-sm ml-2">{{ option.label }}</span>
            </label>
        </div>

        <p class="tw-text-red">{{ error }}</p>
    </div>
</template>

<script setup>
const emit = defineEmits(['update:model-value']);
defineProps({
    modelValue: {
        type: [String, Number, Boolean],
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    label: String,
    options: {
        type: [Array, Object],
        required: true,
    },
    error: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
</script>
