<template>
    <div class="spa-page-wrapper">
        <Page title="Notification Centre">
            <div class="py-3">
                <Banner type="is-primary">
                    <div class="d-flex align-items-center">
                        <img src="@/images/laptop-notification.svg" style="max-height: 95px; margin-right: 25px" />
                        <div class="flex-fill">
                            <h4 class="mb-2">Welcome to your Notification Centre</h4>
                            <p>
                                A new way to keep track of all your notifications and visualise outstanding tasks in one
                                place.
                                <br />
                                You can mark notification as read, or leave them as unread if you need to follow-up
                                later.
                                <br />
                                All of your unread notifications are accessible anywhere by clicking the bell
                                <i class="mdi mdi-bell"></i>
                                icon in the top right corner.
                            </p>
                        </div>
                    </div>
                </Banner>
                <div class="d-flex mt-3 buefy-wrapper">
                    <Button v-if="notifications && notifications.total && !showRead" @click="markAllRead()">
                        Mark all
                        <strong>{{ notifications.total }}</strong>
                        {{ notifications.total === 1 ? 'notification' : 'notifications' }} as read
                    </Button>
                    <div class="flex-fill"></div>
                    <Checkbox v-model="showRead">Show Read Notifications</Checkbox>
                </div>
                <div v-if="notifications" class="notifications-list mt-2">
                    <div
                        v-for="notification in notifications.data"
                        :key="notification.id"
                        class="notification"
                        :class="{
                            'is-success': notification.data.type === 'success',
                            'is-warning': notification.data.type === 'warning',
                            'is-danger': notification.data.type === 'danger',
                            'is-info': notification.data.type === 'info',
                            'is-unread': !notification.read_at,
                        }"
                    >
                        <div class="notification-icon">
                            <i :class="notification.data.icon ? notification.data.icon : 'mdi mdi-cog'"></i>
                        </div>
                        <div class="notification-content">
                            <div class="notification-header">
                                <h5>{{ notification.data.title }}</h5>
                                <small>{{ relativeTimestamp(notification.created_at) }}</small>
                                <div class="notification-header-actions">
                                    <span v-if="notification.read_at" class="text-muted">
                                        Read {{ relativeTimestamp(notification.read_at) }}
                                    </span>
                                    <a href="#" @click.prevent.stop="toggleReadStatus(notification.id)">
                                        Mark {{ notification.read_at ? 'Unread' : 'Read' }}
                                    </a>
                                    <a v-if="notification.data.url" :href="notification.data.url" target="_blank">
                                        See More
                                        <i class="mdi mdi-open-in-new"></i>
                                    </a>
                                </div>
                            </div>
                            <p v-html="notification.data.message"></p>
                        </div>
                    </div>
                    <div v-if="!notifications.data.length" class="text-center text-muted py-4">
                        There are no notifications to show here just yet! &#127881;
                    </div>
                    <Paginator :data="notifications" @change-page="(value) => (currentPage = value)" />
                </div>
            </div>
            <Loader v-if="isLoading" />
        </Page>
    </div>
</template>
<script>
import Page from '../Page.vue';
import Loader from '../widgets/LoadingSpinner.vue';
import Banner from '../Layout/Banner.vue';
import Paginator from '../controls/Paginator.vue';
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { relativeTimestamp } from '@/js/utils.js';

export default {
    components: {
        Page,
        Loader,
        Banner,
        Paginator,
        Button,
        Checkbox,
    },
    data() {
        return {
            isLoading: false,
            showRead: false,
            notifications: null,
            currentPage: 1,
        };
    },
    watch: {
        currentPage(newVal) {
            this.getNotifications(newVal);
        },
        showRead() {
            this.getNotifications(this.currentPage);
        },
    },
    mounted() {
        this.getNotifications();
        Eventbus.$on('reloadNotificationsPage', () => this.getNotifications(this.currentPage));
    },
    methods: {
        relativeTimestamp,
        getNotifications(page = 1) {
            this.isLoading = true;
            const r = this.showRead ? route('api.user.notifications') : route('api.user.notifications.unread');
            axios
                .get(r, {
                    params: {
                        page: page,
                        include_accounts: '*', // Include all accounts
                    },
                })
                .then(({ data }) => (this.notifications = data))
                .finally(() => (this.isLoading = false));
        },
        toggleReadStatus(notificationId) {
            this.isLoading = true;
            axios
                .post(
                    route('api.user.notifications.toggleRead', {
                        notificationId: notificationId,
                    })
                )
                .then(() => {
                    this.getNotifications(this.currentPage);
                    Eventbus.$emit('reloadNotifications');
                })
                .finally(() => (this.isLoading = false));
        },
        markAllRead() {
            this.isLoading = true;
            axios
                .post(route('api.user.notifications.markAllRead'))
                .then(() => {
                    this.getNotifications(this.currentPage);
                    Eventbus.$emit('reloadNotifications');
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.notifications-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background: white;
    border-radius: 10px;

    & > .notification {
        display: flex;
        border-top-left-radius: 4px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 4px;
        padding: 15px 20px 13px 0;
        border-left: 3px solid transparent;

        & > .notification-icon {
            flex: 0 0 60px;
            display: flex;
            justify-content: center;
            font-size: 20px;
        }
        & > .notification-content {
            flex: 1;

            & > .notification-header {
                display: flex;
                gap: 10px;

                & > h5 {
                    margin: 0;
                }

                & > .notification-header-actions {
                    margin-left: auto;
                    display: flex;
                    gap: 12px;
                    font-size: 0.95em;
                }
            }

            & > p {
                margin: 0;
            }
        }

        &.is-unread {
            border-left-color: $eventwise-dark;
            background: rgba(black, 0.025);
        }
        &.is-success {
            background: lighten($eventwise-secondary, 35);
            color: darken($eventwise-secondary, 20);

            & > .notification-content > h6,
            & > .notification-icon {
                color: darken($eventwise-secondary, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-secondary;
            }
        }
        &.is-warning {
            background: lighten($eventwise-warning, 25);
            color: darken($eventwise-warning, 20);

            & > .notification-content > h6,
            & > .notification-icon {
                color: darken($eventwise-warning, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-warning;
            }
        }
        &.is-danger {
            background: lighten($eventwise-danger, 30);
            color: darken($eventwise-danger, 20);

            & > .notification-content > h6,
            & > .notification-icon {
                color: darken($eventwise-danger, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-danger;
            }
        }
        &.is-info {
            background: lighten($eventwise-primary, 40);
            color: darken($eventwise-primary, 20);

            & > .notification-content > h6,
            & > .notification-icon {
                color: darken($eventwise-primary, 30);
            }
            &.is-unread {
                border-left-color: $eventwise-primary;
            }
        }
    }
}
</style>
