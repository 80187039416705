<template>
    <Modal id="object-list-column-configuration" ref="modal" size="is-large" title="Customise columns shown">
        <div class="tw-grid tw-grid-cols-3 tw-gap-3">
            <Checkbox
                v-for="availableColumn in availableColumns"
                :key="availableColumn"
                :model-value="columnsSettings.includes(availableColumn)"
                @update:model-value="(v) => onChange(availableColumn, v)"
            >
                {{ availableColumn }}
            </Checkbox>
        </div>
    </Modal>
</template>

<script setup>
import Modal from '../Modal.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import { useDataStore } from '@/js/stores/DataStore.js';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    type: {
        type: String,
        required: true,
        validator(v) {
            return ['budget', 'revenue', 'purchase'].includes(v);
        },
    },
});

const modal = ref();

const availableColumns = computed(() => {
    const columns = ref();

    switch (props.type) {
        case 'budget':
            columns.value = [
                'Available Tickets',
                'Fixed',
                'Budgeted',
                'Actual',
                'Variance',
                'Scenario',
                'Markup Amount',
            ];
            break;
        case 'revenue':
            columns.value = [
                'Tickets Sold',
                'Fixed',
                'Budgeted',
                'Actual',
                'Variance',
                'Invoiced',
                'Paid',
                'Outstanding',
            ];
            break;
        case 'purchase':
            columns.value = [
                'Markup Amount',
                'Budgeted',
                'Actual',
                'Variance',
                'Remaining Budget',
                'Invoiced',
                'Paid',
                'Outstanding',
            ];
            break;
        default:
            columns.value = [
                'Markup Amount',
                'Fixed',
                'Budgeted',
                'Actual',
                'Remaining Budget',
                'Invoiced',
                'Paid',
                'Outstanding',
                // todo :: maybe we cant do this
                //'Total Left on PO',
            ];
            break;
    }

    if (!useDataStore().get('account.scenarios_enabled')) {
        columns.value = columns.value.filter((c) => c !== 'Scenario');
    }

    return columns.value;
});

const settingKey = computed(() => `event.${useDataStore().get('current_event').id}.pages.${props.type}.columns`);

const columnsSettings = computed(() => {
    const settings = useSettingsStore().get(settingKey.value) ?? [];
    if (!settings || !Array.isArray(settings)) {
        const columns = useSettingsStore().get(`pages.${props.type}.columns`)
            ? useSettingsStore().get(`pages.${props.type}.columns`)
            : {
                  budget: ['Available Tickets', 'Fixed', 'Budgeted'],
                  revenue: ['Budgeted', 'Actual', 'Variance', 'Invoiced', 'Outstanding'],
                  purchase: ['Budgeted', 'Actual', 'Remaining Budget', 'Invoiced', 'Outstanding'],
              }[props.type];

        useSettingsStore().set(settingKey.value, columns);
        return useSettingsStore().get(settingKey.value);
    }
    return settings;
});

function onChange(column, checked) {
    const columns = checked ? [...columnsSettings.value, column] : columnsSettings.value.filter((c) => c !== column);
    useSettingsStore().set(settingKey.value, orderColumns(columns));
}

function orderColumns(columns) {
    const desiredOrder = [
        'Available Tickets',
        'Markup Amount',
        'Manual Estimates',
        'Fixed',
        'Budgeted',
        'Actual',
        'Remaining Budget',
        'Variance',
        'Invoiced',
        'Outstanding',
        'Scenario',
        'Scenario Variance',
    ];

    columns.sort((a, b) => {
        const indexA = desiredOrder.indexOf(a);
        const indexB = desiredOrder.indexOf(b);

        // If a column is not found in the desired order, it will be placed at the end
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
    });

    return columns;
}

function openModal() {
    modal.value.open();
}

onMounted(() => {
    Eventbus.$on('includeEstimated', (v) => {
        const columns = v
            ? [...availableColumns.value, 'Manual Estimates']
            : availableColumns.value.filter((c) => c !== 'Manual Estimates');
        useSettingsStore().set(`pages.${this.type}.columns`, orderColumns(columns));
    });
});

defineExpose({
    openModal,
    columnsSettings,
});
</script>
