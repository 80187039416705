<template>
    <div id="page-utility">
        <div class="tw-grid tw-grid-cols-1 tw-h-full md:tw-grid-cols-2">
            <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
                <slot name="before"></slot>
            </div>
            <div class="tw-bg-eventwise-dark tw-flex tw-flex-col tw-justify-center tw-items-center">
                <slot></slot>
            </div>
        </div>
        <div class="footnote"><slot name="footnote"></slot></div>
    </div>
</template>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

body:has(#page-utility) {
    overflow: none;
    background: radial-gradient(
            circle at 39% 47%,
            hsla(0, 0%, 42%, 0.08) 0%,
            hsla(0, 0%, 42%, 0.04) 33.333%,
            rgba(72, 72, 72, 0.06) 0,
            rgba(72, 72, 72, 0.08) 66.666%,
            rgba(36, 36, 36, 0.04) 0,
            rgba(36, 36, 36, 0.04) 99.999%
        ),
        radial-gradient(
            circle at 53% 74%,
            hsla(0, 0%, 71%, 0.06) 0%,
            hsla(0, 0%, 71%, 0.04) 33.333%,
            hsla(0, 0%, 79%, 0.08) 0,
            hsla(0, 0%, 79%, 0.04) 66.666%,
            hsla(0, 0%, 87%, 0.04) 0,
            hsla(0, 0%, 87%, 0.06) 99.999%
        ),
        radial-gradient(
            circle at 14% 98%,
            hsla(0, 0%, 72%, 0.08) 0%,
            hsla(0, 0%, 72%, 0.06) 33.333%,
            rgba(96, 96, 96, 0.04) 0,
            rgba(96, 96, 96, 0.08) 66.666%,
            rgba(7, 7, 7, 0.04) 0,
            rgba(7, 7, 7, 0.06) 99.999%
        ),
        linear-gradient(to bottom right, #b0e3c1 25%, #95bafe 50%);
}
div#page-utility {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
div#page-utility .footnote {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 15px;
    left: 20px;
    right: 20px;
    color: rgba(white, 0.5);
    font-size: 0.9em;
    user-select: none;
    font-family: 'DM Sans', sans-serif;
}
</style>
