<template>
    <Modal ref="modal" size="is-large" title="Import Ticket Sales" @closing="reset()">
        <div>
            You can click the button to the right to download a template of the file to use.
            <br />
            Ensure that the heading column is kept as is, otherwise the first row of data will be lost.
            <br />
            <br />
            <strong>Warning</strong>
            <br />
            You must have set references for your tickets in order to import transactions.
            <div class="d-flex mt-4 align-items-end">
                <FormField label="File" class="tw-flex-1">
                    <Upload v-model="file" class="file-label" :accept="['.csv']" label="Upload" />
                    {{ file ? file.name : '' }}
                </FormField>
                <Button v-if="!file" class="tw-mb-1 tw-flex tw-gap-1 tw-items-center" @click="downloadTemplate()">
                    <i class="mdi mdi-download"></i>
                    Download Template
                </Button>
                <Button v-else class="tw-mb-1" @click="reset()">Clear</Button>
            </div>
            <FormField class="tw-mt-2">
                <Checkbox v-model="importReplace">Replace all existing sales data with the uploaded data</Checkbox>
            </FormField>
            <Banner v-if="fileData" type="is-info" class="tw-mt-2">
                The selected file contains
                <strong>{{ fileData.length - 1 }}</strong>
                {{ fileData.length - 1 != 1 ? 'rows' : 'row' }} of data
            </Banner>
        </div>

        <template #footer>
            <Button :loading="isLoading" @click="close()">Close</Button>
            <Button
                class="tw-ml-auto"
                type="is-primary"
                :disabled="!file"
                :loading="isLoading"
                @click="importProcess()"
            >
                Import
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/js/components/Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Upload from '@/js/components/controls/Upload.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        FormField,
        Upload,
        Checkbox,
        Banner,
    },
    data() {
        return {
            isLoading: false,
            supplier: null,
            file: null,
            fileData: null,
            importReplace: true,
        };
    },
    watch: {
        file(newVal) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.fileData = e.target.result.split('\n').filter((row) => !!row);
            };
            // this is a blob type despite the console errors saying it isn't
            reader.readAsText(newVal);
        },
    },
    methods: {
        // it's ok if id is null, that means we are importing direct sales
        open({ id, name }) {
            this.supplier = { id, name };
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.isLoading = false;
            this.file = null;
            this.fileData = null;
            this.importReplace = true;
            this.supplier = null;
        },
        downloadTemplate() {
            window.location.href = route('account.event.tickets.downloadTemplate');
        },
        async importProcess() {
            if (!this.file) {
                return;
            }
            this.isLoading = true;
            if (this.importReplace) {
                const { value: confirmation } = await swal.fire({
                    title: 'Are you sure?',
                    text:
                        `You are about to replace all ticket orders for ` +
                        (this.supplier ? this.supplier.name : 'Direct Sales'),
                    showCancelButton: true,
                });
                if (!confirmation) {
                    this.isLoading = false;
                    return;
                }
            }
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('replace', this.importReplace ? 1 : 0);
            axios
                .post(
                    route('api.account.event.csv.importOrders', {
                        supplier: this.supplier ? this.supplier.id : null,
                    }),
                    formData
                )
                .then(() => {
                    this.close();
                    toast('Data Imported', 'Your ticket sales data has been imported successfully', 'success');
                })
                .catch((error) => {
                    swal.fire('Data Error', error.response.data.message, 'error');
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
