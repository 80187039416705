<template>
    <page title="Budget v Actuals">
        <template #submenu>
            <div class="mx-auto"></div>
            <div
                v-if="
                    Auth.account.scenarios_enabled &&
                    (Auth.can('manage scenarios') || Auth.can('create scenario adjustments'))
                "
                class="dropdown mr-2"
            >
                <a href="#" class="ml-auto" :class="{ active: activeScenario }">
                    <i class="mdi mdi-weather-pouring mr-1"></i>
                    <span v-if="!activeScenario">Scenarios</span>
                    <span v-else>{{ activeScenario.name }}</span>
                </a>
                <div
                    v-if="Object.keys(store.get('current_event.scenarios')).length || activeScenario"
                    class="dropdown-menu dropdown-menu-right"
                >
                    <a v-if="activeScenario" class="dropdown-item" href="#" @click.prevent="setScenario()">
                        Close Scenario
                    </a>
                    <div
                        v-if="activeScenario && Object.keys(store.get('current_event.scenarios')).length"
                        class="dropdown-divider"
                    ></div>
                    <a
                        v-for="(scenario, id) in store.get('current_event.scenarios')"
                        :key="id"
                        class="dropdown-item"
                        href="#"
                        @click.prevent="setScenario(id)"
                    >
                        {{ scenario.name }}
                    </a>
                    <a
                        v-if="Object.values(store.get('current_event.scenarios')).length > 5"
                        class="dropdown-item"
                        href="#"
                        @click.prevent="setPage('scenarios')"
                    >
                        View all
                    </a>
                </div>
            </div>
            <div v-if="Auth.can('export data')" class="dropdown">
                <a href="#">
                    <i class="mdi mdi-download mr-1"></i>
                    Export PDF
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" :href="route('account.event.export.budgetVActuals')" target="_blank">
                        Budget v Actuals Summary
                        <span class="badge badge-light ml-2">PDF</span>
                    </a>
                </div>
            </div>
            <a href="#" @click.prevent="setRoute('account.event.budgetVActuals.settlementReport')">
                <i class="mdi mdi-newspaper mr-1" />
                Settlement Report
            </a>
        </template>

        <div class="row mt-3">
            <div class="col-12" :class="{ 'col-xl-8': showProfitOverview }">
                <ew-card title="Event Budget Overview" :expanded="true" :max-height="400">
                    <template #actions>
                        <div class="tw-flex tw-gap-1 tw-items-center">
                            <Button
                                :active="chartView === 'summary'"
                                class="tw-flex tw-gap-2 tw-items-center"
                                @click="chartView = 'summary'"
                            >
                                <i class="mdi mdi-monitor-dashboard"></i>
                                <span>Summary</span>
                            </Button>
                            <Button
                                :active="chartView === 'revenue'"
                                class="tw-flex tw-gap-2 tw-items-center"
                                @click="chartView = 'revenue'"
                            >
                                <i class="mdi mdi-cash-multiple"></i>
                                <span>Revenue</span>
                            </Button>
                            <Button
                                :active="chartView === 'costs'"
                                class="tw-flex tw-gap-2 tw-items-center"
                                @click="chartView = 'costs'"
                            >
                                <i class="mdi mdi-credit-card-outline"></i>
                                <span>Cost</span>
                            </Button>
                        </div>
                    </template>
                    <chart-departments-overview :height="260" :default-view="chartView" />
                </ew-card>
            </div>
            <div v-if="showProfitOverview" class="col-12 col-xl-4">
                <chart-profit-overview
                    height="270px"
                    :labels="['Budgeted', 'Actuals', 'Variance']"
                    :v-actuals="true"
                ></chart-profit-overview>
            </div>
        </div>
        <budget-v-actuals :event="currentEvent" :scenario="activeScenario"></budget-v-actuals>
        <div class="buefy-wrapper">
            <LoadingSpinner v-if="isRefetching" label="Loading Information" />
        </div>
    </page>
</template>
<script>
import Page from '../Page.vue';
import ChartDepartmentsOverview from '../../../assets/js/eventbooks/components/charts/departments/departments-overview.vue';
import ChartProfitOverview from '../../../assets/js/eventbooks/components/charts/event/profit-overview.vue';
import BudgetVActuals from '../../../assets/js/eventbooks/components/budget/v_actuals.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import LoadingSpinner from '../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import { setRoute } from '@/js/utils.js';

export default {
    components: {
        Page,
        ChartDepartmentsOverview,
        ChartProfitOverview,
        BudgetVActuals,
        LoadingSpinner,
        Button,
    },
    data() {
        const store = useDataStore();
        return {
            isRefetching: false,
            chartView: 'summary',
            currentEvent: store.get('current_event'),
            store: store,
        };
    },
    computed: {
        showProfitOverview() {
            let is_director = !!Auth?.user?.roles?.find((role) => role.name === 'Director');
            let is_bookkeeper = !!Auth?.user?.roles?.find((role) => role.name === 'Bookkeeper');
            return is_director || is_bookkeeper;
        },
        activeScenario() {
            return this.store.get('current_scenario');
        },
    },
    mounted() {
        Eventbus.$on('eventRefetched', () => (this.isRefetching = false));
    },
    methods: {
        setRoute,
        setScenario(scenarioId = null) {
            this.isRefetching = true;
            if (!scenarioId) {
                this.store.set('current_scenario', null);
                Eventbus.$emit('forceRefetchEvent');
                return;
            }
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.scenarios.get', {
                        scenario: scenarioId,
                    })
                )
                .then(({ data }) => {
                    this.store.set('current_scenario', data);
                    Eventbus.$emit('forceRefetchEvent');
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
