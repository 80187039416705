<template>
    <Card title="Tickets Sold">
        <Chart v-if="!isLoading" type="polarArea" :options="chartOptions" :data="chartData"></Chart>
    </Card>
</template>
<script>
import Card from '../../../../assets/js/eventbooks/components/templates/_card.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Chart from '../../Chart.vue';
import { localise } from '@/js/utils.js';

export default {
    components: {
        Card,
        Chart,
    },
    data() {
        const store = useDataStore();
        return {
            store: store,
            ticketTypes: Object.values(store.get('current_event.ticket_types')),
            lastUpdated: null,
            labelsDisplayed: Object.keys(store.get('current_event.ticket_types')).length <= 10,
            isLoading: true,
        };
    },
    computed: {
        chartData() {
            this.lastUpdated;
            if (!this.ticketTypes) {
                return {
                    labels: [],
                    datasets: [],
                };
            }
            return {
                labels: this.ticketTypes.map((t) => t.name),
                datasets: [
                    {
                        label: 'Tickets Sold',
                        data: this.ticketTypes.map((t) =>
                            t.tickets_available > 0
                                ? parseFloat(((t.tickets_sold / t.tickets_available) * 100).toFixed(1))
                                : 0
                        ),
                        ticket_types: this.ticketTypes,
                    },
                ],
            };
        },
        chartOptions() {
            return {
                responsive: true,
                scales: {
                    r: {
                        pointLabels: {
                            display: this.labelsDisplayed,
                            centerPointLabels: true,
                            font: {
                                size: 11,
                            },
                            callback(value) {
                                return value
                                    .replaceAll(' & ', '_&_')
                                    .split(' ')
                                    .map((v) => v.replaceAll('_&_', ' & '));
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        footerFont: {
                            size: 11,
                            weight: 'normal',
                        },
                        callbacks: {
                            label(tooltipItem) {
                                return ' ' + tooltipItem['dataset']['data'][tooltipItem.dataIndex] + '% sold';
                            },
                            footer(tooltipItem) {
                                const available =
                                    tooltipItem[0]['dataset']['ticket_types'][tooltipItem[0].dataIndex][
                                        'tickets_available'
                                    ];
                                const sold =
                                    tooltipItem[0]['dataset']['ticket_types'][tooltipItem[0].dataIndex]['tickets_sold'];
                                return [
                                    'Available: ' + localise(available, 0, false),
                                    'Sold: ' + localise(sold, 0, false),
                                    'Remaining: ' + localise(available - sold, 0, false),
                                ];
                            },
                        },
                    },
                },
            };
        },
    },
    watch: {
        ticketTypes: {
            handler() {
                this.lastUpdated = new Date();
            },
            deep: true,
        },
    },
    mounted() {
        this.isLoading = false;
    },
};
</script>
