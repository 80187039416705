/**
 * Returns the total fixed amount for a department.
 * Requires the department to have a categories array or object with each category having a total_fixed property.
 */
export function totalFixed(department) {
    return Object.values(department.categories ?? []).reduce((a, c) => a + c.total_fixed, 0);
}

/**
 * Return the total budgeted amount for a department.
 * Requires the department to have a categories array or object with each category having a total_budgeted property.
 */
export function totalBudgeted(department) {
    return Object.values(department.categories ?? []).reduce((a, c) => a + c.total_budgeted, 0);
}

/**
 * Return the manual estimate amount for a department.
 * Requires the department to have an estimates object with a budgeted_amount property.
 */
export function manualEstimate(department) {
    return department.estimates?.budgeted_amount ?? null;
}
