<template>
    <div v-if="categorisedTicketTypes.length">
        <div
            v-for="(ticketCategory, index) in categorisedTicketTypes"
            :key="'ticketCategory_' + index"
            class="ticket-category"
        >
            <div class="category-heading">
                <h6>{{ ticketCategory.name }}</h6>
            </div>
            <DataTable
                :data="ticketCategory.data.filter((tc) => tc.ticket_sales.length)"
                :columns="[
                    {
                        title: 'Name',
                        field: 'name',
                    },
                    {
                        title: 'Price',
                        field: 'ticket_price',
                        class: '!tw-text-right',
                        width: 120,
                    },
                    {
                        title: 'Available',
                        field: 'tickets_available',
                        class: '!tw-text-right',
                        width: 120,
                    },
                    {
                        title: 'Rebate',
                        field: 'rebate',
                        class: '!tw-text-right',
                        width: 100,
                    },
                    {
                        title: 'Fixed Sales (Net)',
                        field: 'fixed_sales',
                        class: '!tw-text-right',
                        width: 200,
                    },
                    {
                        title: 'Scenario Sales (Net)',
                        field: 'scenario_sales',
                        class: '!tw-text-right',
                        width: 200,
                    },
                    {
                        title: 'Fixed Sales (Gross)',
                        field: 'fixed_sales_gross',
                        class: '!tw-text-right',
                        width: 200,
                    },
                    {
                        title: 'Scenario Sales (Gross)',
                        field: 'scenario_sales_gross',
                        class: '!tw-text-right',
                        width: 200,
                    },
                    {
                        title: '',
                        field: 'actions',
                        class: '!tw-text-right',
                        width: 50,
                    },
                ]"
            >
                <template #heading(ticket_price)="{ column }">
                    <Tooltip position="bottom">
                        <template #content>
                            Adjusting the ticket price will change the scenario value for all sellers that have this
                            ticket assigned.
                        </template>
                        {{ column.title }}
                        <i class="mdi mdi-information"></i>
                    </Tooltip>
                </template>
                <template #column(name)="{ row }">
                    {{ row.name }}
                </template>
                <template #column(ticket_price)="{ row }">
                    <EditableText
                        v-if="Auth.can('manage tickets')"
                        :model-value="localise(scenarioTicketPrice(row), 2)"
                        type="currency"
                        @update:model-value="
                            (val) => {
                                debouncedUpdateTicket('ticket_price', val, row, row.ticket_sales[0]);
                            }
                        "
                    />
                    <template v-else>
                        {{ localise(scenarioTicketPrice(row), 2, true) }}
                    </template>
                </template>
                <template #column(tickets_available)="{ row }">
                    <EditableText
                        :model-value="scenarioTicketsAvailable(row)"
                        type="number"
                        @update:model-value="
                            (val) => {
                                debouncedUpdateTicket('tickets_available', val, row, row.ticket_sales[0]);
                            }
                        "
                    />
                </template>
                <template #column(rebate)="{ row }">
                    {{ localise(row.ticket_sales.length ? row.ticket_sales[0].kickback_cost : 0, 2, true) }}
                </template>
                <template #column(fixed_sales)="{ row }">
                    {{ localise(calcSales(row, false, false), 2, true) }}
                </template>
                <template #column(scenario_sales)="{ row }">
                    {{ localise(calcSales(row, true, false), 2, true) }}
                </template>
                <template #column(fixed_sales_gross)="{ row }">
                    {{ localise(calcSales(row, false, true), 2, true) }}
                </template>
                <template #column(scenario_sales_gross)="{ row }">
                    {{ localise(calcSales(row, true, true), 2, true) }}
                </template>
                <template #column(actions)="{ row }">
                    <Tooltip position="left" label="View Ticket">
                        <Button @click.prevent="viewTicket(row.id)">
                            <i class="mdi mdi-eye"></i>
                        </Button>
                    </Tooltip>
                </template>
            </DataTable>
        </div>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import debounce from 'lodash/debounce';
import EditableText from '../../controls/EditableText.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { gross2net } from '@/js/utils.js';

export default {
    components: {
        EditableText,
        Button,
        Tooltip,
        DataTable,
    },
    props: {
        ticketData: {
            type: Array,
            default: null,
        },
        categories: {
            type: Array,
            default: null,
        },
        selectedScenario: {
            type: Number,
            default: null,
        },
    },
    emits: ['reload:ticket-scenarios'],
    data() {
        const store = useDataStore();
        return {
            store: store,
        };
    },
    computed: {
        categorisedTicketTypes() {
            if (!this.ticketData) {
                return [];
            }
            const cats = {};
            this.ticketData.forEach((ticketType) => {
                const keyId = ticketType.category_id ? ticketType.category_id : 0;
                if (!cats[keyId]) {
                    const category = this.store.get('current_event.categories.' + keyId)
                        ? this.store.get('current_event.categories.' + keyId)
                        : {
                              id: 0,
                              name: 'Uncategorised',
                          };
                    cats[keyId] = {
                        id: category.id ? category.id : null,
                        name: category.name,
                        data: [],
                    };
                }
                cats[keyId]['data'].push(ticketType);
            });
            return this.categories
                ? Object.values(cats).filter((c) => this.categories.includes(c.id))
                : Object.values(cats);
        },
    },
    mounted() {
        if (!this.ticketData) {
            this.$emit('reload:ticket-scenarios');
        }
    },
    methods: {
        scenarioTicketPrice(row) {
            let adjustment = row.adjustments.find((a) => {
                return a.adjustmentable_type === 'App\\Models\\TicketType' && a.scenario_id === this.selectedScenario;
            });

            return adjustment?.value ?? row.ticket_price;
        },
        scenarioTicketsAvailable(row) {
            let adjustment = row.adjustments.find((a) => {
                return (
                    a.adjustmentable_type === 'App\\Models\\TicketSale' &&
                    a.scenario_id === this.selectedScenario &&
                    a.adjustmentable_id === row.ticket_sales[0].id
                );
            });

            return parseInt(adjustment?.value ?? row.ticket_sales[0].tickets_available);
        },
        calcSales(row, scenario = true, gross = false) {
            let total = 0;
            row.ticket_sales.forEach((ticketSale) => {
                const taxModifier = ticketSale.tax_rate ? ticketSale.tax_rate.rate : 0;
                const netTicketPrice = gross2net(
                    parseFloat(scenario ? this.scenarioTicketPrice(row) : row.ticket_price),
                    taxModifier
                );
                const netRebate = gross2net(parseFloat(ticketSale.kickback_cost), taxModifier);
                const subtotal =
                    (scenario ? this.scenarioTicketsAvailable(row) : ticketSale.tickets_available) *
                    (netTicketPrice + netRebate);
                const taxAmount = (subtotal / 100) * taxModifier;
                total += subtotal + (gross ? taxAmount : 0);
            });
            return total;
        },
        viewTicket(ticketTypeId = null) {
            Eventbus.$emit('view:ticketType', ticketTypeId);
        },
        updateTicket(adjustable, value, ticketType, ticketSale) {
            axios
                .post(
                    route('api.account.event.tickets.updateSingleAdjustment', {
                        ticketType: ticketType.id,
                        ticketSale: ticketSale.id,
                        scenario: this.selectedScenario,
                    }),
                    {
                        type: adjustable,
                        value: value,
                    }
                )
                .finally(() => {
                    this.$emit('reload:ticket-scenarios');
                });
        },
        debouncedUpdateTicket: debounce(function (adjustable, value, ticketType, ticketSale) {
            this.updateTicket(adjustable, value, ticketType, ticketSale);
        }, 400),
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.ticket-category {
    margin: 5px;
    border: 1px solid rgba(black, 0.1);
    border-radius: 4px;

    .category-heading {
        display: flex;
        align-items: center;
        color: rgba(black, 0.4);
        background: rgba(black, 0.01);
        border-bottom: 1px solid rgba(black, 0.1);
        width: 100%;
        padding: 0px 8px;

        & > h6 {
            padding: 8px 0 5px 0;
            margin-right: auto;
            color: rgba(black, 0.4);
        }
        & > a {
            font-family: $eventwise-font-heading;
            margin-top: 2px;
        }
    }
}
</style>
