<template>
    <Modal ref="modal" title="Invite Supplier" :loading="isLoading" @closed="reset()">
        <div v-if="!Auth.can('manage suppliers')">
            <Banner type="is-danger">
                You do not have permission to manage suppliers.
                <br />
                If you believe that this may be a mistake, please get in contact with your account owner.
            </Banner>
        </div>
        <div v-else>
            <FormField label="Contact Name">
                <Textbox v-model="formData.name" type="text" class="tw-w-full" required />
            </FormField>
            <FormField class="tw-mt-2" label="Contact Email Address">
                <Textbox v-model="formData.email" type="email" class="tw-w-full" required />
            </FormField>
        </div>
        <template #footer>
            <Button @click="closeModal()">Close</Button>
            <Button class="tw-ml-auto" type="is-success" :disabled="!isValid" @click="sendInvite()">Send Invite</Button>
        </template>
    </Modal>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Modal from '../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import { isValidEmail, toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        Button,
        Textbox,
        FormField,
        Banner,
    },
    data() {
        const store = useDataStore();
        return {
            supplier: null,
            formData: {
                name: '',
                email: '',
            },
            isLoading: false,
            store: store,
        };
    },
    computed: {
        isValid() {
            return this.supplier && this.formData.email && isValidEmail(this.formData.email) && this.formData.name;
        },
    },
    mounted() {
        Eventbus.$on('inviteSupplierModal:open', (supplier) => {
            const s = this.store.get('account.suppliers.' + supplier);
            if (!s) {
                toast('Error', 'Supplier not found', 'error');
                return;
            }
            this.formData.name = s.name ?? '';
            this.formData.email = s.email_address ?? '';
            this.supplier = s;
            this.$refs.modal.open();
        });
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
        reset() {
            this.formData = {
                name: '',
                email: '',
            };
            this.supplier = null;
            this.isLoading = false;
        },
        sendInvite() {
            this.isLoading = true;
            if (!this.isValid) {
                return;
            }
            axios
                .post(
                    route('api.account.portal.invite', {
                        supplier: this.supplier.id,
                    }),
                    this.formData
                )
                .then(() => {
                    toast('Success', 'Your invite has been sent', 'success');
                    this.close();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style scoped lang="scss"></style>
