<template>
    <Page class="ew-approvals" :title="pageTitle">
        <template #submenu>
            <a href="#" :class="{ active: activePage === 'budget-entries' }" @click.prevent="setPage('budget-entries')">
                Budget Entries
            </a>
            <a
                href="#"
                :class="{ active: activePage === 'purchase-orders' }"
                @click.prevent="setPage('purchase-orders')"
            >
                Purchase Orders
            </a>
            <a
                href="#"
                :class="{ active: activePage === 'supplier-invoices' }"
                @click.prevent="setPage('supplier-invoices')"
            >
                Supplier Invoices
            </a>
            <a href="#" :class="{ active: activePage === 'payruns' }" @click.prevent="setPage('payruns')">Payruns</a>
            <a href="#" :class="{ active: activePage === 'departments' }" @click.prevent="setPage('departments')">
                Departments
            </a>
        </template>
        <div v-if="activePage === 'budget-entries'" class="mt-3 buefy-wrapper tw-bg-white tw-rounded tw-border">
            <TableBudgetEntries
                :endpoint="route('api.account.event.entries.byApprovals')"
                checkable
                is-approvals-area
                :hidden-columns="['variance']"
            ></TableBudgetEntries>
        </div>
        <div v-else-if="activePage === 'purchase-orders'" class="mt-3">
            <TablePurchaseOrders
                :endpoint="route('api.account.event.purchaseorders.byApprovals')"
                :checkable="true"
                :is-approvals-area="true"
            ></TablePurchaseOrders>
        </div>
        <div v-else-if="activePage === 'supplier-invoices'" class="mt-3">
            <TableSupplierInvoices
                :endpoint="route('api.account.event.purchaseorders.invoices.approvals')"
                :is-approvals-area="true"
            />
        </div>
        <div v-else-if="activePage === 'payruns'" class="mt-3">
            <Payruns />
        </div>
        <div v-else-if="activePage === 'departments'" class="mt-3">
            <table-departments :endpoint="route('api.account.event.departments.approvalsList')"></table-departments>
        </div>
    </Page>
</template>
<script>
import Page from '../Page.vue';
import TableBudgetEntries from '../tables/Budget/BudgetEntries.vue';
import TablePurchaseOrders from '../tables/Purchasing/PurchaseOrders.vue';
import Payruns from '@/assets/js/eventbooks/components/tables/purchasing/payruns.vue';
import { setRoute, flushPageEvents } from '@/js/utils.js';

import TableSupplierInvoices from '../tables/Purchasing/SupplierInvoices.vue';

export default {
    components: {
        Page,
        Payruns,
        TableBudgetEntries,
        TablePurchaseOrders,
        TableSupplierInvoices,
    },
    data() {
        return {
            activePage: route().params.page ?? 'budget-entries',
        };
    },
    computed: {
        pageTitle() {
            switch (this.activePage) {
                case 'budget-entries':
                    return 'My Budget Entry Approvals';
                case 'purchase-orders':
                    return 'My Purchase Order Approvals';
                case 'payruns':
                    return 'My Payrun Approvals';
                case 'departments':
                    return 'My Department Approvals';
                default:
                    return 'My Approvals';
            }
        },
    },
    mounted() {
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        setPage(page = 'budget-entries') {
            setRoute('account.event.approvals', {
                page: page,
            });
        },
        handlePageChange() {
            this.activePage = route().params.page ?? 'dashboard';
        },
    },
};
</script>
