<template>
    <div class="row align-items-center position-relative d-flex flex-column">
        <p v-if="!data || !chartLastUpdated" class="col-12 text-muted text-center mt-5">
            <i class="las la-spinner la-spin mr-1"></i>
            Loading Data
        </p>
        <div v-else class="col-12 flex-fill">
            <Chart :key="keyComponent" type="line" :data="chartData" :options="chartOptions" :min-height="320" />
        </div>
    </div>
</template>
<script>
import Chart from '../../../Chart.vue';
export default {
    components: {
        Chart,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        events: {
            type: Array,
            required: true,
        },
        weekly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            chartLastUpdated: null,
            isWeekly: null,
            keyComponent: 0,
            backgrounds: [
                'rgba(54, 162, 235, .5)',
                'rgba(166, 214, 175, .5)',
                'rgba(255, 159, 63, .5)',
                'rgba(255, 205, 86, .5)',
            ],
            borderColours: [
                'rgba(54, 162, 235, 1)',
                'rgba(166, 214, 175, 1)',
                'rgba(255, 159, 63, 1)',
                'rgba(255, 205, 86, 1)',
            ],
        };
    },
    computed: {
        chartData() {
            this.chartLastUpdated;
            const datasets = [];
            const eventCount = this.events.length;
            const dataLabels = this.data[0].map((data) => (data.x + 1).toString());
            for (let i = 0; i < eventCount; i++) {
                datasets.push({
                    label: this.events[i].name,
                    data: this.data[i],
                    backgroundColor: this.backgrounds[i],
                    borderColor: this.borderColours[i],
                });
            }

            return {
                datasets: datasets,
                labels: dataLabels,
            };
        },
        chartOptions() {
            this.chartLastUpdated;

            return {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: this.isWeekly ? 'Week' : 'Month',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Tickets Sold',
                        },
                        beginAtZero: true,
                    },
                },
            };
        },
    },
    watch: {
        data: {
            handler() {
                this.reloadChartData();
            },
            deep: true,
        },
        weekly: {
            handler(newVal) {
                this.isWeekly = newVal;
                this.reloadChartData();
                this.keyComponent += 1;
            },
        },
    },
    mounted() {
        this.isWeekly = this.weekly;
        this.reloadChartData();
    },
    methods: {
        reloadChartData() {
            this.chartLastUpdated = new Date();
        },
    },
};
</script>
