<template>
    <div class="tw-flex tw-flex-col tw-items-end">
        <Currency v-model="amount" :label="label ? label + ' (' + inputType + ')' : null" :min :step :max />
        <Button
            v-if="allowSwitching"
            type="is-link"
            class="!tw-p-0 tw-text-end tw-text-xs tw-mr-1"
            @click="inputType = inputType === 'net' ? 'gross' : 'net'"
        >
            Switch to {{ inputType === 'net' ? 'gross' : 'net' }}
        </Button>
    </div>
</template>
<script setup>
import Button from '@/js/components/controls/Button.vue';
import Currency from '@/js/components/controls/Currency.vue';
import { useDataStore } from '@/js/stores/DataStore';
import { computed, ref, watchEffect } from 'vue';

const props = defineProps({
    input: {
        type: String,
        default: 'net',
        validator: (value) => ['net', 'gross'].includes(value),
    },
    output: {
        type: String,
        default: 'net',
        validator: (value) => ['net', 'gross'].includes(value),
    },
    taxRate: {
        type: Number,
        default: null,
    },
    allowSwitching: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        required: false,
    },
    min: {
        type: [Number, String],
        default: null,
    },
    step: {
        type: [Number, String],
        default: 1,
    },
    max: {
        type: [Number, String],
        default: null,
    },
});

const taxRates = useDataStore().get('account.tax_rates');
const inputType = ref(props.input);

const model = defineModel({
    type: [Number],
    default: 0,
});
const amount = ref(model.value);

const fullTaxRate = computed(() => {
    if (props.taxRate) {
        return taxRates[props.taxRate] ?? null;
    }

    return null;
});
const convertedAmount = computed(() => {
    if (inputType.value === 'net' && props.output === 'gross') {
        return amount.value + (fullTaxRate.value ? amount.value * (fullTaxRate.value.rate / 100) : 0);
    }
    if (inputType.value === 'gross' && props.output === 'net') {
        return amount.value / (1 + (fullTaxRate.value ? fullTaxRate.value.rate / 100 : 0));
    }

    return amount.value;
});

watchEffect(() => {
    model.value = isNaN(convertedAmount.value) ? 0 : convertedAmount.value;
});
</script>
