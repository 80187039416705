<template>
    <Tooltip :label="(locked ? 'Unlock ' : 'Lock ') + lockableName + ' Budget'" position="left">
        <Button
            type="is-danger"
            :class="{
                'tw-bg-red-500 tw-text-white': locked,
            }"
            :loading
            :disabled="disabled"
            @click="lockItem"
        >
            <i v-if="locked" class="mdi mdi-lock"></i>
            <i v-else class="mdi mdi-lock-open-variant"></i>
        </Button>
    </Tooltip>
</template>
<script setup>
import Tooltip from '@/js/components/Tooltip.vue';
import Button from '@/js/components/controls/Button.vue';
import { ref } from 'vue';
import axios from 'axios';

const props = defineProps({
    lockableName: {
        type: String,
        default: '',
    },
    lockableType: {
        type: String,
        default: '',
    },
    lockable: {
        type: Object,
        default: () => ({}),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const locked = ref(props.lockable.is_locked);
const loading = ref(false);
const emit = defineEmits(['toggle']);
const lockItem = async () => {
    loading.value = true;
    await axios.post(route('api.account.event.budget.lock'), {
        lockable: props.lockable,
        lockableType: props.lockableType,
    });
    loading.value = false;
    locked.value = !locked.value;
    emit('toggle');
};
</script>
