<template>
    <Card title="Monthly Ticket Sales">
        <Chart v-if="!isLoading" :options="chartOptions" :data="chartData" />
    </Card>
</template>
<script>
import Card from '../../../../assets/js/eventbooks/components/templates/_card.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Chart from '../../Chart.vue';

export default {
    components: {
        Card,
        Chart,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            rawData: null,
            lastUpdated: null,
            store: store,
            labelsDisplayed: true,
        };
    },
    computed: {
        chartOptions() {
            this.lastUpdated;
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
                plugins: {
                    legend: {
                        display: this.labelsDisplayed,
                    },
                },
            };
        },
        chartData() {
            this.lastUpdated;
            if (!this.rawData) {
                return {};
            }
            return {
                labels: this.rawData.labels,
                datasets: Object.values(this.rawData.datasets).map((ticketType) => {
                    ticketType.data = Object.values(ticketType.data);
                    return ticketType;
                }),
            };
        },
    },
    mounted() {
        this.reloadData();
    },
    methods: {
        reloadData() {
            this.isLoading = true;
            axios
                .get(route('api.account.event.chartData.revenue.monthlyTicketSales'))
                .then((response) => {
                    this.rawData = response.data;
                    this.labelsDisplayed = Object.keys(this.rawData.datasets).length <= 10;
                    this.lastUpdated = new Date();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
