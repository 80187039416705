<template>
    <div class="ew-user-display">
        <div
            v-if="!hideAvatar"
            class="ew-avatar"
            :class="{
                'is-small': size === 'small',
                'is-xsmall': size === 'xsmall',
                'is-large': size === 'large',
                'is-xlarge': size === 'xlarge',
                'is-xxlarge': size === 'xxlarge',
            }"
            :style="avatarStyles"
        >
            <div v-if="!user.avatar_url" class="ew-avatar-initials">{{ initials(user.name) }}</div>
            <div class="badge-wrapper">
                <div v-if="user.email && user.email.endsWith('@synergitech.co.uk')" class="synergi-badge"></div>
                <div v-else-if="user.is_superadmin" class="eventwise-badge"></div>
            </div>
        </div>
        <div v-if="!hideName" class="ew-user" :class="hideAvatar ? '' : 'ml-2'">
            <div
                v-if="hideAvatar && user.email && user.email.endsWith('@synergitech.co.uk')"
                class="synergi-badge mr-1"
            ></div>
            <div v-else-if="hideAvatar && user.is_superadmin" class="eventwise-badge mr-1"></div>
            <slot :user>{{ user.name }}</slot>
        </div>
    </div>
</template>
<script>
import { hashStringToColour, contrastingTextColour, initials } from '@/js/utils.js';

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        hideAvatar: {
            type: Boolean,
            default: false,
        },
        hideName: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: null,
        },
    },
    computed: {
        avatarStyles() {
            if (!this.user) {
                return;
            } else if (this.user.avatar_url) {
                return 'background-image: url(' + this.user.avatar_url + ')';
            }
            const backgroundColour = hashStringToColour(this.user.name);
            const foregroundColour = contrastingTextColour(backgroundColour, '#FFFFFF', '#303e67');
            return `background-color: ${backgroundColour}; color: ${foregroundColour};`;
        },
    },
    methods: {
        initials,
    },
};
</script>
<style lang="scss">
.ew-user-display {
    display: flex;
    align-items: center;

    & > .ew-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-right: 6px;
        position: relative;
        background-size: cover;
        background-position: center center;

        .image-wrapper {
            max-width: 36px;
            max-height: 36px;
            border-radius: 50%;
            overflow: hidden;

            & > img {
                max-width: 36px;
                max-height: 36px;
            }
        }

        .badge-wrapper {
            position: absolute;
            bottom: -3px;
            right: -3px;
        }

        &.is-xsmall {
            width: 28px;
            height: 28px;
        }
        &.is-small {
            width: 32px;
            height: 32px;
        }
        &.is-large {
            width: 42px;
            height: 42px;
        }
        &.is-xlarge {
            width: 48px;
            height: 48px;
        }
        &.is-xxlarge {
            width: 64px;
            height: 64px;
        }
    }
    & > .ew-user {
        display: flex;
        align-items: center;
    }
}

.synergi-badge {
    display: block;
    width: 15px;
    height: 15px;
    background: #0b102b;
    border-radius: 3px;
    background-image: url('/media/synergi-icon.png');
    background-size: 13px 13px;
    background-position: center center;
    background-repeat: no-repeat;
}
.eventwise-badge {
    display: block;
    width: 15px;
    height: 15px;
    background: #edf0f5;
    border-radius: 3px;
    background-image: url('/media/icon.svg');
    background-size: 13px 13px;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
