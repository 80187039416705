<template>
    <div>
        <div v-if="Object.values(scenarios).length" class="mb-4">
            <FormField class="tw-w-full" field-class="tw-flex tw-gap-2" label="Selected Scenario">
                <Select v-model="scenario" placeholder="Select a scenario to manage tickets" class="tw-flex-1">
                    <option v-for="s in Object.values(scenarios)" :key="s.id" :value="s.id">{{ s.name }}</option>
                </Select>
                <Button v-if="scenario" @click="scenario = null">
                    <i class="mdi mdi-close"></i>
                </Button>
            </FormField>
        </div>
        <Button
            v-else
            type="is-success"
            class="tw-m-1 tw-flex tw-gap-1 tw-items-center"
            style="width: -webkit-fill-available"
            @click="createScenario"
        >
            <i class="mdi mdi-plus"></i>
            Create A New Scenario
        </Button>

        <div v-if="scenario && ticketSellers" class="tw-flex tw-items-center tw-gap-1">
            <Button
                v-for="ticketSeller in ticketSellers"
                :key="ticketSeller.id"
                :active="ticketSeller.id === selectedTicketSeller?.id"
                @click="selectedTicketSeller = ticketSeller"
            >
                {{ ticketSeller.name }}
            </Button>
        </div>

        <TicketScenariosTable
            v-if="scenario"
            :categories="categories"
            :ticket-data="selectedTicketSeller.ticket_types"
            :selected-scenario="scenario"
            @reload:ticket-scenarios="reloadData"
        />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import TicketScenariosTable from './TicketScenariosTable.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';

export default {
    components: {
        TicketScenariosTable,
        Button,
        FormField,
        Select,
    },
    props: {
        categories: {
            type: Array,
            default: null,
        },
        revenueStream: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        const store = useDataStore();
        return {
            ticketSellers: null,
            selectedTicketSeller: null,
            scenarios: store.get('current_event.scenarios'),
            scenario: store.get('current_scenario.id') ? store.get('current_scenario.id') : null,
        };
    },
    mounted() {
        this.reloadData();
        Eventbus.$on('reload:stream', () => this.reloadData());
    },
    methods: {
        reloadData() {
            axios
                .get(
                    route('api.account.event.tickets.byStream', {
                        revenueStream: this.revenueStream,
                    })
                )
                .then((response) => (this.ticketSellers = this.formatTicketSellers(response.data)));
        },
        formatTicketSellers(ticketData) {
            let ticketSellers = {};
            ticketData.forEach((ticketType) => {
                ticketType.ticket_sales.forEach((ticketSale) => {
                    if (!ticketSale.supplier) {
                        ticketSellers[0] = {
                            id: null,
                            name: 'Direct Sales',
                            ticket_types: ticketData.map((tt) => {
                                tt = JSON.parse(JSON.stringify(tt));
                                tt.ticket_sales = tt.ticket_sales.filter((ts) => !ts.supplier);
                                return tt;
                            }),
                        };
                        return;
                    }
                    ticketSellers[ticketSale.supplier.id] = {
                        id: ticketSale.supplier.id,
                        name: ticketSale.supplier.name,
                        ticket_types: ticketData.map((tt) => {
                            tt = JSON.parse(JSON.stringify(tt));
                            tt.ticket_sales = tt.ticket_sales.filter((ts) => ts.supplier_id === ticketSale.supplier.id);
                            return tt;
                        }),
                    };
                });
            });
            this.selectedTicketSeller = Object.values(ticketSellers)[0];
            return Object.values(ticketSellers);
        },
        createScenario() {
            Eventbus.$emit('scenario:drawerOpen');
        },
    },
};
</script>
