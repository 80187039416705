<template>
    <div class="buefy-wrapper">
        <div class="d-flex align-items-center mb-2">
            <Textbox v-model="searchQuery" placeholder="Search for an event..." />
        </div>
        <DataTable
            ref="table"
            :endpoint="route('api.admin.events')"
            :columns="[
                {
                    title: 'ID',
                    field: 'id',
                },
                {
                    title: 'Name',
                    field: 'events.name',
                    sortable: true,
                },
                {
                    title: 'Account',
                    field: 'account',
                },
                {
                    title: '',
                    field: 'actions',
                    class: '!tw-text-right',
                },
            ]"
            centered
            :searchable-string="searchQuery"
        >
            <!-- eslint-disable -->
            <template #column(events.name)="{ row }">
                <h5>{{ row.name }}</h5>
            </template>
            <template #column(account)="{ row }">
                <h5>{{ row.account.name }}</h5>
            </template>
            <template #column(actions)="{ row }">
                <div class="tw-flex tw-justify-end tw-gap-1">
                    <Tooltip label="Recalculate Event">
                        <Button type="is-dark" :loading="row.is_recalculating" @click="recalculate(row)">
                            <i class="mdi mdi-cash-sync"></i>
                        </Button>
                    </Tooltip>
                    <Tooltip label="Shadow">
                        <Button type="is-dark" @click="shadow(row.view_url)">
                            <i class="mdi mdi-box-shadow"></i>
                        </Button>
                    </Tooltip>
                </div>
            </template>
            <template #empty>
                <div class="empty-label">There are no events to show</div>
            </template>
            <!-- eslint-enable -->
        </DataTable>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        Button,
        Textbox,
        Tooltip,
        DataTable,
    },
    data() {
        return {
            searchQuery: null,
        };
    },
    watch: {
        searchQuery() {
            this.debouncedReloadData();
        },
    },
    methods: {
        reloadData(newPage = null) {
            this.$refs.table.refreshData(newPage);
        },
        debouncedReloadData: debounce(function () {
            this.reloadData();
        }, 500),
        recalculate(row) {
            row.is_recalculating = true;
            axios
                .post(
                    route('api.admin.account.event.recalculate', {
                        account: row.account_id,
                        event: row.id,
                    })
                )
                .then(() => (row.is_recalculating = false));
        },
        shadow(url) {
            window.open(url, '_blank');
        },
    },
};
</script>
