<template>
    <div class="buefy-wrapper">
        <input
            ref="accountImageInput"
            type="file"
            accept="image/png,image/jpeg"
            hidden
            @change="handleAccountImageChange"
        />
        <div v-if="currentAccount" class="row">
            <div class="col-12">
                <Banner class="tw-mb-3" :type="lastSaved ? 'is-success' : null" :closable="false">
                    <LastSaved :saved="lastSaved" :saving="isSaving" />
                </Banner>
            </div>
            <div class="col-12 col-xl-6">
                <ew-card title="Your Organisation" :collapsible="false">
                    <div class="row p-2">
                        <div class="col-3 align-self-center">
                            <label for="accountName" class="mb-0">Name</label>
                        </div>
                        <div class="col-8">
                            <Textbox
                                id="accountName"
                                v-model="currentAccount.name"
                                maxlength="255"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 align-self-center text-right">
                            <HelpLabel
                                text="The primary name for your organisation, this is typically your company name and is displayed across Eventwise and on generated documents"
                            />
                        </div>
                        <div class="col-3 align-self-center mt-4">
                            <label class="mb-0">Logo</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Button class="!tw-px-0" type="is-link" @click="changeAccountImage()">
                                {{ currentAccount.image_url ? 'Change Logo' : 'Upload Logo' }}
                            </Button>
                            <Button
                                v-if="currentAccount.image_url"
                                class="!tw-px-0 tw-float-right tw-text-red-500"
                                type="is-link"
                                @click="deleteAccountImage()"
                            >
                                Delete Current Logo
                            </Button>
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel
                                text="You can upload a logo for your organisation to be displayed on any documents generated by Eventwise"
                            />
                        </div>
                        <div class="col-3 align-self-center mt-4">
                            <label for="accountVat" class="mb-0">VAT Number</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                id="accountVat"
                                v-model="currentAccount.vat_number"
                                maxlength="255"
                                placeholder="Not Specified"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel
                                text="If your organisation is VAT registered you can input your VAT registration number to be displayed on generated documents"
                            />
                        </div>
                        <div class="col-3 mt-4">
                            <label class="mb-0">Postal Address</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                v-model="currentAccount.primary_address.address1"
                                maxlength="255"
                                placeholder="Address Line 1"
                                @update:model-value="debouncedSaveAccount()"
                            />
                            <Textbox
                                v-model="currentAccount.primary_address.address2"
                                class="tw-mt-1"
                                maxlength="255"
                                placeholder="Address Line 2"
                                @update:model-value="debouncedSaveAccount()"
                            />
                            <div class="d-flex mt-1">
                                <div class="flex-fill pr-1">
                                    <Textbox
                                        v-model="currentAccount.primary_address.city"
                                        maxlength="255"
                                        placeholder="City"
                                        @update:model-value="debouncedSaveAccount()"
                                    />
                                </div>
                                <div class="flex-fill pl-2">
                                    <Textbox
                                        v-model="currentAccount.primary_address.postcode"
                                        maxlength="255"
                                        placeholder="Postal Code"
                                        @update:model-value="debouncedSaveAccount()"
                                    />
                                </div>
                            </div>
                            <Select
                                v-model="currentAccount.primary_address.country"
                                class="tw-mt-1 tw-w-full"
                                @update:model-value="debouncedSaveAccount()"
                            >
                                <option v-for="locale in locales" :key="locale" :value="locale">
                                    {{ locale }}
                                </option>
                            </Select>
                        </div>
                        <div class="col-1 mt-4 text-right">
                            <HelpLabel
                                text="This is the primary address for your organisation and will be included on any documents generated by Eventwise"
                            />
                        </div>
                        <div class="col-3 align-self-center mt-4">
                            <label class="mb-0">Base Currency</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Select
                                v-model="currentAccount.currency_code"
                                class="tw-w-full"
                                @update:model-value="debouncedSaveAccount()"
                            >
                                <option value="GBP">Pound Sterling (GBP)</option>
                                <option value="EUR">Euro (EUR)</option>
                                <option value="USD">US Dollar (USD)</option>
                                <option value="AUD">Australian Dollar (AUD)</option>
                                <option value="CHF">Swiss Franc (CHF)</option>
                                <option value="JPY">Japanese Yen (JPY)</option>
                                <option value="RUB">Russian Ruble (RUB)</option>
                                <option value="SEK">Swedish Krona (SEK)</option>
                                <option value="DKK">Danish Krone (DKK)</option>
                                <option value="ZAR">South African Rand (ZAR)</option>
                            </Select>
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel
                                text="The primary currency for your organisation, this is used as the default currency for all transactions and is displayed on generated documents"
                            />
                        </div>
                    </div>
                </ew-card>
                <ew-card title="Banking Details" :collapsible="false">
                    <p>
                        Here you can provide a UK bank account number and sort code.
                        <br />
                        These will be automatically included on any sales invoices generated and sent from Eventwise.
                    </p>
                    <div class="row p-2">
                        <div class="col-3 mt-4 align-self-center">
                            <label for="bankAccountName" class="mb-0">Account Name</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                id="bankAccountName"
                                v-model="currentAccount.bank_account_name"
                                maxlength="42"
                                placeholder="Not Specified"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel text="The name registered against the bank account" />
                        </div>
                        <div class="col-3 mt-4 align-self-center">
                            <label for="bankSortCode" class="mb-0">Sort Code</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                id="bankSortCode"
                                v-model="currentAccount.bank_sort_code"
                                maxlength="16"
                                placeholder="Not Specified"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel text="A UK banking sort code in the format 00-00-00" />
                        </div>
                        <div class="col-3 mt-4 align-self-center">
                            <label for="bankAccountNumber" class="mb-0">Account Number</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                id="bankAccountNumber"
                                v-model="currentAccount.bank_account_number"
                                maxlength="16"
                                placeholder="Not Specified"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel text="A UK banking account number in the format 12345678" />
                        </div>
                    </div>
                </ew-card>
            </div>
            <div class="col-12 col-xl-6">
                <ew-card title="General Preferences" :collapsible="false">
                    <div class="row p-2">
                        <div class="col-3 align-self-center">
                            <label for="overdueLimit" class="mb-0">Overdue Time</label>
                        </div>
                        <div class="col-8">
                            <div class="tw-flex tw-w-full">
                                <NumberInput
                                    v-model="currentAccount.days_until_overdue"
                                    name="overdueLimit"
                                    class="tw-flex-1"
                                    :min="0"
                                    :max="365"
                                    @update:model-value="debouncedSaveAccount()"
                                />
                                <Button>days</Button>
                            </div>
                        </div>
                        <div class="col-1 align-self-center text-right">
                            <HelpLabel
                                text="The number of days that an entry has been awaiting approval before it is to be considered overdue"
                            />
                        </div>
                        <div class="col-3 mt-4 align-self-center">
                            <label for="poPrefix" class="mb-0">Purchase Order Prefix</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                id="poPrefix"
                                v-model="currentAccount.po_prefix"
                                maxlength="255"
                                placeholder="Not Specified"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel
                                text="A piece of text that is automatically added and displayed before every purchase order number"
                            />
                        </div>
                        <div class="col-3 mt-4 align-self-center">
                            <label for="siPrefix" class="mb-0">Sales Invoice Prefix</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                id="siPrefix"
                                v-model="currentAccount.si_prefix"
                                maxlength="255"
                                placeholder="Not Specified"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel
                                text="A piece of text that is automatically added and displayed before every sales invoice number"
                            />
                        </div>
                        <div class="col-3 mt-4 align-self-center">
                            <label for="replyToEmail" class="mb-0">Reply-To Email Address</label>
                        </div>
                        <div class="col-8 mt-4">
                            <Textbox
                                id="replyToEmail"
                                v-model="currentAccount.reply_to"
                                type="email"
                                maxlength="255"
                                placeholder="no-reply@eventwise.com"
                                @update:model-value="debouncedSaveAccount()"
                            />
                        </div>
                        <div class="col-1 mt-4 align-self-center text-right">
                            <HelpLabel
                                text="By default emails from Eventwise cannot be replied to. This setting forwards any replies to the specified email address"
                            />
                        </div>
                    </div>
                </ew-card>
                <ew-card title="Security Preferences" :collapsible="false">
                    <div class="row p-2">
                        <div class="col-11 align-self-center">
                            <Checkbox
                                v-model="currentAccount.two_factor"
                                class="tw-m-0"
                                @update:model-value="debouncedSaveAccount()"
                            >
                                Require Two Factor Authentication (2FA) for all team members
                            </Checkbox>
                        </div>
                        <div class="col-1 text-right">
                            <HelpLabel
                                text="Two factor authentication is the best way of securing an account, requiring you to enter a code from a secure device each time you login"
                            />
                        </div>
                    </div>
                </ew-card>
            </div>
        </div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
    </div>
</template>
<script>
import axios from 'axios';
import { useDataStore } from '@/js/stores/DataStore.js';
import HelpLabel from '../../../widgets/HelpLabel.vue';
import LastSaved from '../../../widgets/LastSaved.vue';
import debounce from 'lodash/debounce';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Select from '@/js/components/controls/Select.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import { toast } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        LoadingSpinner,
        HelpLabel,
        LastSaved,
        Button,
        Checkbox,
        Banner,
        Select,
        Textbox,
        NumberInput,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            isSaving: false,
            lastSaved: null,
            currentAccount: null,
            locales: store.get('locales'),
            store: store,
        };
    },
    mounted() {
        this.getAccount();
    },
    methods: {
        getAccount() {
            this.isLoading = true;
            axios
                .get(route('api.account'))
                .then(({ data }) => (this.currentAccount = data))
                .finally(() => (this.isLoading = false));
        },
        debouncedSaveAccount: debounce(function () {
            this.saveAccount();
        }, 1500),
        saveAccount() {
            this.isSaving = true;
            axios
                .post(route('api.account.update'), this.currentAccount)
                .then(() => (this.lastSaved = DateTime.now()))
                .finally(() => (this.isSaving = false));
        },
        changeAccountImage() {
            this.$refs.accountImageInput.click();
        },
        deleteAccountImage() {
            this.isLoading = true;
            axios
                .delete(route('api.account.delete-image'))
                .then(() => {
                    this.store.set('account.image_url', null);
                    toast('Logo Deleted', 'Your account logo has been successfully removed', 'success');
                    this.getAccount();
                })
                .finally(() => (this.isLoading = false));
        },
        handleAccountImageChange(e) {
            if (!e.target || !e.target.files.length) {
                return;
            }
            const file = e.target.files[0];
            if (!file) {
                return;
            }
            this.isLoading = true;
            const formData = new FormData();
            formData.append('image', file);
            axios
                .post(route('api.account.update-image'), formData)
                .then(({ data }) => {
                    this.store.set('account.image_url', data.image_url);
                    toast('Logo Updated', 'Your account logo has been updated successfully', 'success');
                    this.getAccount();
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss">
.ew-user-profile-card {
    &:has(.ew-account-profile-image) {
        margin-top: 89px !important;
    }

    & > .ew-account-profile-image {
        border-radius: 10px;
        min-width: 128px;
        width: unset;
        left: 20px;

        & > img {
            height: 120px;
            max-width: 400px;
            border-radius: 6px;
        }

        &:before {
            border-radius: 12px;
            top: -6px;
            left: -6px;
            right: -6px;
            bottom: -6px;
            width: unset;
        }
    }
}
</style>
