<template>
    <div
        class="editable-text"
        :class="{ editable, editing: isEditing, bordered: bordered }"
        @click="editable ? focusOnInput() : null"
    >
        <div class="editable-text-wrapper">
            <div v-if="type === 'currency'" class="editable-text-currency">
                {{ currencySymbol ? currencySymbol : localise(0, 0, true).replace('0', '') }}
            </div>
            <div
                v-if="placeholder && !inputValue && !isEditing && editable"
                class="editable-text-placeholder"
                @click="focusOnInput"
            >
                {{ placeholder }}
            </div>
            <div
                ref="inputBox"
                class="editable-text-input"
                :contenteditable="editable"
                @focus="isEditing = true"
                @blur="isEditing = false"
                @keyup="handleInput"
                @keydown="handleKeyPress"
            ></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        currencySymbol: {
            type: String,
            default: null,
        },
        decimalPlaces: {
            type: Number,
            default: 2,
        },
        maxLength: {
            type: Number,
            default: null,
        },
        minValue: {
            type: Number,
            default: null,
        },
        maxValue: {
            type: Number,
            default: null,
        },
        bordered: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            isEditing: false,
            inputValue: null,
        };
    },
    watch: {
        modelValue(newVal) {
            if (newVal != this.inputValue) {
                this.inputValue = typeof newVal === 'number' ? newVal.toString() : newVal;
                this.$refs.inputBox.innerText =
                    (this.type === 'currency' || this.type === 'number') && isNaN(this.inputValue)
                        ? null
                        : this.inputValue;
            }
        },
    },
    mounted() {
        this.inputValue = typeof this.modelValue === 'number' ? this.modelValue.toString() : this.modelValue;
        this.$refs.inputBox.innerText = this.inputValue;
    },
    methods: {
        handleKeyPress(e) {
            if (this.type === 'currency' || this.type === 'number') {
                const allowedInputs = [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '.',
                    '-',
                    'Backspace',
                    'Delete',
                    'ArrowLeft',
                    'ArrowRight',
                    'Tab',
                ];
                const cursorIndex = document.getSelection().anchorOffset;
                if (!allowedInputs.find((c) => c === e.key)) {
                    // Prevent any disallowed inputs from working
                    e.preventDefault(true);
                } else if (e.key === '.' && (this.inputValue ?? '').includes('.')) {
                    // Don't allow multiple decimal points
                    e.preventDefault(true);
                } else if (
                    !isNaN(e.key) &&
                    this.inputValue.includes('.') &&
                    cursorIndex > this.inputValue.indexOf('.') &&
                    this.inputValue.split('.')[1].length > this.decimalPlaces - 1
                ) {
                    // Don't allow more than two digits after a decimal point
                    e.preventDefault(true);
                } else if (e.key === '-' && (this.inputValue.indexOf('-') !== -1 || cursorIndex !== 0)) {
                    // Don't allow a minus if either one already exists or caret is not at the start of the figure
                    e.preventDefault(true);
                } else if (cursorIndex === 0 && this.inputValue.startsWith('-')) {
                    // Don't allow figures to be input before a minus symbol
                    e.preventDefault(true);
                } else if (e.key === '.' && !this.decimalPlaces) {
                    // Don't allow decimal points if places is set to 0
                    e.preventDefault(true);
                } else if (e.key === '0' && cursorIndex === 0 && this.inputValue && this.inputValue.length > 0) {
                    const currentSelection = document.getSelection();
                    if (currentSelection && !currentSelection.isCollapsed) {
                        return;
                    }
                    // Don't allow a 0 to be put before existing figures
                    e.preventDefault(true);
                } else if (e.key === 'Tab') {
                    this.handlePostTabPress();
                }
            }
            if (
                this.maxLength &&
                this.inputValue &&
                this.inputValue.length >= this.maxLength &&
                !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)
            ) {
                // Don't allow input greather than the max length if specified
                e.preventDefault(true);
            } else if (e.key === 'Tab') {
                this.handlePostTabPress();
            }
        },
        handleInput(e) {
            let newVal = e.target.innerText;
            if (!isNaN(newVal) && (this.type === 'currency' || this.type === 'number')) {
                if (this.maxValue && !isNaN(this.maxValue) && parseFloat(newVal) >= this.maxValue) {
                    // Limit to max value if value exceeds
                    newVal = this.maxValue.toString();
                    this.$refs.inputBox.innerText = newVal;
                } else if (this.minValue && !isNaN(this.minValue) && parseFloat(newVal) <= this.minValue) {
                    // Limit to min value if value goes below
                    newVal = this.minValue.toString();
                    this.$refs.inputBox.innerText = newVal;
                } else if (newVal.startsWith('0') && newVal.length > 1 && !newVal.startsWith('0.')) {
                    // Replace any leading zeros (eg. replacing 0402.22 with 402.22)
                    newVal = newVal.replace('0', '');
                    this.$refs.inputBox.innerText = newVal;
                }
            }
            this.inputValue = newVal;
            this.$emit(
                'update:modelValue',
                ['currency', 'number'].includes(this.type)
                    ? isNaN(this.inputValue)
                        ? 0
                        : parseFloat(this.inputValue ? this.inputValue : 0)
                    : this.inputValue
            );
        },
        handlePostTabPress() {
            setTimeout(() => {
                const currentSelection = document.getSelection();
                if (!currentSelection.focusNode?.parentNode?.classList?.contains('editable-text-input')) {
                    return;
                }
                let range = document.createRange();
                if (currentSelection.focusNode.parentNode.innerText == '0') {
                    range.selectNodeContents(currentSelection.focusNode.parentNode);
                } else {
                    range.setStart(
                        currentSelection.focusNode.parentNode.childNodes[0],
                        currentSelection.focusNode.parentNode.innerText.length
                    );
                }
                currentSelection.removeAllRanges();
                currentSelection.addRange(range);
            }, 10);
        },
        focusOnInput() {
            this.isEditing = true;
            this.$refs.inputBox.focus();
        },
    },
};
</script>
<style lang="scss" scoped>
.editable-text {
    display: inline-block;
    padding: 2px 0px;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: all 0.1s ease;
    background: transparent;

    &:not(.editable) {
        cursor: default;
    }
    &.editable {
        padding: 2px 4px;

        &.bordered {
            border: 1px solid rgba(black, 0.1);
        }

        &:hover,
        &:focus-within {
            border: 1px solid rgba(black, 0.33);
            background: rgba(white, 0.33);
            transform: scale(1.05);
        }

        &.editing {
            border: 1px solid rgba(black, 0.25);
            background: white;
        }
    }

    & > .editable-text-wrapper {
        display: flex;

        & > .editable-text-input {
            min-width: 2px;
        }

        & > .editable-text-placeholder {
            opacity: 0.5;
            padding-left: 2px;
        }
    }
}
</style>
