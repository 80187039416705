<template>
    <div class="buefy-wrapper">
        <div v-if="!isLoading" class="d-flex align-items-center mb-2">
            <Textbox v-model="searchQuery" placeholder="Search for an account..." size="is-small" />
            <Checkbox v-model="softDeleted" @update:model-value="reloadData">Include Deleted</Checkbox>
        </div>
        <DataTable
            :endpoint="route('api.admin.accounts')"
            :columns="[
                { title: 'Name', field: 'name' },
                { title: 'Type', field: 'type', width: 100 },
                { title: 'Events', field: 'events_count', width: 60 },
                { title: 'Users', field: 'users_count', width: 60 },
                { title: 'Departments', field: 'departments_count', width: 60 },
                { title: 'Categories', field: 'categories_count', width: 60 },
                { title: 'Scenarios', field: 'scenarios_enabled', width: 60 },
                { title: 'System', field: 'has_system_supplier', width: 50 },
                { title: 'Xero', field: 'xero_organisation_count', width: 75 },
                { title: '', field: 'actions' },
            ]"
            class="align-middle"
            :searchable-string="searchQuery"
        >
            <template #column(name)="{ row }">
                <h5>
                    {{ row.name }}
                    <span v-if="row.deleted_at">(Deleted {{ timeAgo(row.deleted_at) }})</span>
                </h5>
                <small class="text-muted">
                    Created {{ timeAgo(row.created_at) }} by
                    <span v-if="row.created_by">{{ row.created_by.name }}</span>
                </small>
            </template>
            <template #column(type)="{ row }">
                <Tag v-if="row.is_demo" type="warning">Demo</Tag>
                <Tag v-else type="primary">Full</Tag>
            </template>
            <template #column(events_count)="{ row }">
                {{ localise(row.events_count, 0, false) }}
            </template>
            <template #column(users_count)="{ row }">
                {{ localise(row.users_count, 0, false) }}
            </template>

            <template #column(departments_count)="{ row }">
                {{ localise(row.departments_count, 0, false) }}
            </template>

            <template #column(categories_count)="{ row }">
                {{ localise(row.categories_count, 0, false) }}
            </template>
            <template #column(scenarios_enabled)="{ row }">
                <i v-if="row.scenarios_enabled" class="mdi mdi-check-circle" style="color: limegreen" />
                <i v-else class="mdi mdi-close" style="color: red" />
            </template>
            <template #column(has_system_supplier)="{ row }">
                <i v-if="row.has_system_supplier" class="mdi mdi-check-circle" style="color: limegreen" />
                <i v-else class="mdi mdi-close" style="color: red" />
            </template>
            <template #column(xero_organisation_count)="{ row }">
                <Tooltip
                    type="is-dark"
                    position="left"
                    :label="
                        row.xero_organisation
                            ? row.xero_organisation.reconnection_required
                                ? 'Xero Reconnection Required'
                                : 'Connected to Xero'
                            : 'Not connected to Xero'
                    "
                >
                    <div class="overlay-badge-wrapper">
                        <img
                            src="@/images/brands/xero_blue.png"
                            class="icon-sm"
                            :class="{ grayscale: !row.xero_organisation }"
                        />
                        <div
                            v-if="row.xero_organisation && row.xero_organisation.reconnection_required"
                            class="overlay-badge"
                        >
                            <i class="mdi mdi-link-variant-off"></i>
                        </div>
                    </div>
                </Tooltip>
            </template>

            <template #column(actions)="{ row }">
                <div class="tw-flex tw-justify-end">
                    <Tooltip type="is-dark" label="Shadow">
                        <Button type="is-dark" @click="shadow(row.id)">
                            <i class="mdi mdi-box-shadow"></i>
                        </Button>
                    </Tooltip>
                    <Tooltip type="is-dark" label="View">
                        <Button type="is-primary" @click="viewAccount(row.id)">
                            <i class="mdi mdi-magnify"></i>
                        </Button>
                    </Tooltip>
                </div>
            </template>
            <template #empty>
                <div class="empty-label">There are no accounts to show</div>
            </template>
        </DataTable>
    </div>
</template>
<script>
import Button from '@/js/components/controls/Button.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { timeAgo } from '@/js/utils.js';

export default {
    components: {
        DataTable,
        Textbox,
        Button,
        Checkbox,
        Tooltip,
        Tag,
        Textbox,
    },
    emits: ['view'],
    data() {
        return {
            isLoading: true,
            data: null,
            searchQuery: null,
            perPage: 10,
            sorts: {},
            softDeleted: false,
        };
    },
    methods: {
        timeAgo,
        toggleSort(field, order = null) {
            this.sorts = {};
            this.sorts[field] = order;
        },
        shadow(id) {
            const url = route('admin.switch', {
                accountToSwitchTo: id,
            });
            window.open(url, '_blank');
        },
        viewAccount(id) {
            this.$emit('view', id);
        },
    },
};
</script>
<style lang="scss">
.overlay-badge-wrapper {
    position: relative;

    & > .overlay-badge {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 14px;
        height: 14px;
        font-size: 12px;
        border-radius: 50%;
        background: #ad274d;
        color: white;
        position: absolute;
        bottom: -3px;
        right: -3px;
    }
}
</style>
