<template>
    <table v-if="currentPeriodData" class="table table-bordered table-sm smaller-text mb-0">
        <thead class="thead-light">
            <tr>
                <th scope="col"></th>
                <th scope="col">{{ isMonthly ? 'This Month' : 'This Week' }}</th>
                <th scope="col">YTD</th>
                <!-- <th scope="col">Total</th> -->
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">Fixed Sales</th>
                <td>
                    {{
                        localise(
                            currentPeriodData.data[isRevenue ? 'total_stretch_revenue' : 'total_stretch'],
                            isRevenue ? 2 : 0,
                            isRevenue
                        )
                    }}
                </td>
                <td>
                    {{
                        localise(
                            currentPeriodData.data[
                                isRevenue ? 'running_total_stretch_revenue' : 'running_total_stretch'
                            ],
                            isRevenue ? 2 : 0,
                            isRevenue
                        )
                    }}
                </td>
                <!-- <td>{{ localise(tableData.fixedSalesTotal, 2, true) }}</td> -->
            </tr>
            <tr>
                <th scope="row">Actual Sales</th>
                <td>
                    {{
                        localise(
                            currentPeriodData.data[isRevenue ? 'total_revenue' : 'total'],
                            isRevenue ? 2 : 0,
                            isRevenue
                        )
                    }}
                </td>
                <td>
                    {{
                        localise(
                            currentPeriodData.data[isRevenue ? 'running_total_revenue' : 'running_total'],
                            isRevenue ? 2 : 0,
                            isRevenue
                        )
                    }}
                </td>
                <!-- <td>{{ localise(tableData.actualSalesTotal, 0, currencySymbol) }}</td> -->
            </tr>
            <!-- <tr>
                <th scope="row">Actual : Fixed</th>
                <td>{{ localise((tableData.actualSalesWeek / tableData.fixedSalesWeek) * 100, 1, false) }}%</td>
                <td>{{ localise((tableData.actualSalesYTD / tableData.fixedSalesYTD) * 100, 1, false) }}%</td>
                <td>{{ localise((tableData.actualSalesTotal / tableData.fixedSalesTotal) * 100, 1, false) }}%</td>
            </tr> -->
            <!-- <tr v-if="selectedEventId > 0">
                <th scope="row">Prior year sales</th>
                <td>{{ localise(tableData.priorYearSalesWeek, 0, currencySymbol) }}</td>
                <td>{{ localise(tableData.priorYearSalesYTD, 0, currencySymbol) }}</td>
                <td>{{ localise(tableData.priorYearSalesTotal, 0, currencySymbol) }}</td>
            </tr>
            <tr v-if="selectedEventId > 0">
                <th scope="row">Actual : prior year sales</th>
                <td>{{ localise((tableData.actualSalesWeek / tableData.priorYearSalesWeek) * 100, 1, false) }}%</td>
                <td>{{ localise((tableData.actualSalesYTD / tableData.priorYearSalesYTD) * 100, 1, false) }}%</td>
                <td>{{ localise((tableData.actualSalesTotal / tableData.priorYearSalesTotal) * 100, 1, false) }}%</td>
            </tr>
            <tr v-if="selectedEventId > 0">
                <th scope="row">Prior YTD : Prior year sales</th>
                <td></td>
                <td></td>
                <td>{{ localise((tableData.priorYearSalesYTD / tableData.priorYearSalesTotal) * 100, 1, false) }}%</td>
            </tr> -->
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        currentPeriod: {
            type: Object,
            required: false,
        },
        revenue: {
            type: Boolean,
            default: false,
        },
        monthly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isRevenue: false,
            isMonthly: false,
            currentPeriodData: null,
        };
    },
    watch: {
        revenue(newVal) {
            this.isRevenue = newVal;
        },
        monthly(newVal) {
            this.isMonthly = newVal;
        },
        currentPeriod: {
            handler(newVal) {
                this.currentPeriodData = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        this.isRevenue = this.revenue;
        this.isMonthly = this.monthly;
        this.currentPeriodData = this.currentPeriod;
    },
};
</script>
