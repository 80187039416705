<template>
    <div class="buefy-wrapper">
        <WidgetStatistics v-slot="props" class="mt-3" :endpoint="route('api.account.payruns.statistics')">
            <WidgetStatistic
                title="Total"
                :subtext="
                    props.loading
                        ? ''
                        : props.data.total_payments_count +
                          ' payment' +
                          (props.data.total_payments_count === 1 ? '' : 's')
                "
                icon="cash-multiple"
                :loading="props.loading"
                :model-value="props.data.total_payments_value"
                :currency="true"
            />
            <WidgetStatistic
                title="Outstanding"
                :subtext="
                    props.loading
                        ? ''
                        : props.data.pending_payments_count +
                          ' payment' +
                          (props.data.pending_payments_count === 1 ? '' : 's')
                "
                icon="clock-outline"
                :loading="props.loading"
                :model-value="props.data.pending_payments_value"
                :currency="true"
            />
            <WidgetStatistic
                title="Overdue"
                :subtext="
                    props.loading
                        ? ''
                        : props.data.overdue_payments_count +
                          ' payment' +
                          (props.data.overdue_payments_count === 1 ? '' : 's')
                "
                icon="clock-alert-outline"
                :loading="props.loading"
                :model-value="props.data.overdue_payments_value"
                :currency="true"
            />
        </WidgetStatistics>
        <div v-if="!isLoading" class="d-flex align-items-center mt-3 mb-2" style="gap: 10px">
            <Select v-model="currentPeriod">
                <option value="lastWeek">
                    Last Week ({{
                        DateTime.now().minus({ week: 1 }).startOf('week').toLocaleString(DateTime.DATE_MED)
                    }}
                    - {{ DateTime.now().minus({ week: 1 }).endOf('week').toLocaleString(DateTime.DATE_MED) }})
                </option>
                <option value="thisWeek">
                    This Week ({{ DateTime.now().startOf('week').toLocaleString(DateTime.DATE_MED) }} -
                    {{ DateTime.now().endOf('week').toLocaleString(DateTime.DATE_MED) }})
                </option>
                <option value="custom">Custom period</option>
            </Select>
            <template v-if="currentPeriod === 'custom'">
                <div class="d-flex flex-fill">
                    <flat-pickr
                        v-model="customPeriodStart"
                        class="form-control input is-small"
                        placeholder="Period Start"
                        :config="flatpickrConfig"
                        required
                    />
                    <Button :disabled="!customPeriodStart" @click="customPeriodStart = null">
                        <i class="mdi mdi-close"></i>
                    </Button>
                </div>
                <div class="d-flex flex-fill">
                    <flat-pickr
                        v-model="customPeriodEnd"
                        class="form-control input is-small"
                        placeholder="Period End"
                        :config="flatpickrConfig"
                        required
                    />
                    <Button :disabled="!customPeriodEnd" @click="customPeriodEnd = null">
                        <i class="mdi mdi-close"></i>
                    </Button>
                </div>
            </template>
            <div class="flex-fill"></div>
            Filters:
            <Checkbox v-model="showRejected" class="tw-m-0">Rejected</Checkbox>
            <Checkbox v-model="showCompleted" class="tw-m-0">Paid</Checkbox>
            <Checkbox v-model="showEventRuns" class="tw-m-0">Event Payruns</Checkbox>
            <Button type="is-primary" class="tw-flex tw-gap-2" @click="$emit('create')">
                <i class="mdi mdi-plus"></i>
                New Payrun
            </Button>
        </div>
        <ew-card v-if="!isLoading" title="Payruns" paddingless focusable>
            <DataTable
                :endpoint="
                    route('api.account.payruns', {
                        show_rejected: showRejected,
                        show_completed: showCompleted,
                        show_events: showEventRuns,
                        start: currentPeriod === 'custom' ? (customPeriodStart ?? '') : currentPeriodStart.toISODate(),
                        end: currentPeriod === 'custom' ? (customPeriodEnd ?? '') : currentPeriodEnd.toISODate(),
                    })
                "
                :columns="[
                    { title: 'Event', field: 'event' },
                    { title: 'Title', field: 'title' },
                    { title: 'Items', field: 'items' },
                    { title: 'Payment Date', field: 'payment_date' },
                    { title: 'Status', field: 'status' },
                    { title: 'Value', field: 'subtotal' },
                ]"
                @select="viewPayrun"
            >
                <template #column(event)="{ row }">
                    <span v-if="!row.event" class="text-muted">Multiple Events</span>
                    <span v-else>{{ row.event.name }}</span>
                </template>
                <template #column(items)="{ row }">
                    {{ row.items.length }}
                </template>
                <template #column(payment_date)="{ row }">
                    <span v-if="!row.payment_date" class="text-muted">Unassigned</span>
                    <span v-else>{{ DateTime.fromSQL(row.payment_date).toLocaleString(DateTime.DATE_MED) }}</span>
                </template>
                <template #column(status)="{ row }">
                    <Tag :status="row.status" />
                </template>
                <template #column(subtotal)="{ row }">
                    {{ localise(row.subtotal, 2, true) }}
                </template>
                <template #empty>
                    <div class="text-center text-muted">
                        <span v-if="currentPeriodStart && currentPeriodEnd">
                            There are no payruns to display for the current period ({{
                                currentPeriodStart.toLocaleString(DateTime.DATE_MED)
                            }}
                            - {{ currentPeriodEnd.toLocaleString(DateTime.DATE_MED) }})
                        </span>
                        <span v-else>There is no current period set</span>
                    </div>
                </template>
            </DataTable>
        </ew-card>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import WidgetStatistics from '../../../../../js/components/widgets/statistics/StatisticsRow.vue';
import WidgetStatistic from '../../../../../js/components/widgets/statistics/StatisticsItem.vue';
import LoadingSpinner from '../../../widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Select from '@/js/components/controls/Select.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tag from '@/js/components/Tag.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        LoadingSpinner,
        WidgetStatistics,
        WidgetStatistic,
        Button,
        Select,
        Checkbox,
        DataTable,
        Tag,
    },
    emits: ['view', 'create'],
    data() {
        const store = useDataStore();
        const settings = useSettingsStore();
        const storedPeriod = settings.account(store.get('account.id'), 'payrun_period');

        return {
            isLoading: false,
            currentAccount: store.get('account'),
            currentPeriod: storedPeriod ? storedPeriod : 'thisWeek',
            customPeriodStart: null,
            customPeriodEnd: null,
            showRejected: !!settings.account(store.get('account.id'), 'payruns_show_rejected'),
            showCompleted: !!settings.account(store.get('account.id'), 'payruns_show_completed'),
            showEventRuns: !!settings.account(store.get('account.id'), 'payruns_show_events'),
            settings: settings,
            DateTime,
        };
    },
    computed: {
        currentPeriodStart() {
            if (this.currentPeriod === 'custom') {
                return this.customPeriodStart;
            } else if (this.currentPeriod === 'lastWeek') {
                return DateTime.now().minus({ week: 1 }).startOf('week').startOf('day');
            } else if (this.currentPeriod === 'thisWeek') {
                return DateTime.now().startOf('week').startOf('day');
            }
            return null;
        },
        currentPeriodEnd() {
            if (this.currentPeriod === 'custom') {
                return this.customPeriodEnd;
            } else if (this.currentPeriod === 'lastWeek') {
                return DateTime.now().minus({ week: 1 }).endOf('week').endOf('day');
            } else if (this.currentPeriod === 'thisWeek') {
                return DateTime.now().endOf('week').endOf('day');
            }
            return null;
        },
    },
    watch: {
        showRejected(newVal) {
            this.settings.setAccount(this.currentAccount.id, 'payruns_show_rejected', newVal ? 1 : 0);
        },
        showCompleted(newVal) {
            this.settings.setAccount(this.currentAccount.id, 'payruns_show_completed', newVal ? 1 : 0);
        },
        showEventRuns(newVal) {
            this.settings.setAccount(this.currentAccount.id, 'payruns_show_events', newVal ? 1 : 0);
        },
        currentPeriod(newVal) {
            this.settings.setAccount(this.currentAccount.id, 'payrun_period', newVal);
        },
    },
    methods: {
        viewPayrun(payrun) {
            this.$emit('view', payrun.id);
        },
    },
};
</script>
