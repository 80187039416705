<template>
    <Modal ref="addPaymentModal" title="Add Payment" size="is-xxxlarge" paddingless @closed="reset()">
        <DataTable
            checkable
            :endpoint="
                route('api.account.payruns.payments', {
                    end: payrun.payment_date.split(' ')[0],
                })
            "
            :columns="[
                { title: 'Payee', field: 'supplier_name' },
                { title: 'Event', field: 'event_name' },
                { title: 'Subcategory', field: 'category_name' },
                { title: 'Purchase Order', field: 'purchase_order' },
                { title: 'Invoice', field: 'invoice' },
                { title: 'Amount', field: 'amount' },
                { title: 'Payment Date', field: 'date' },
                { title: '', field: 'actions' },
            ]"
            @update:model-value="(v) => (selectedPayments = v)"
        >
            <template #column(supplier_name)="{ row }">
                {{ row.invoice.supplier.name }}
            </template>
            <template #column(event_name)="{ row }">
                {{ row.invoice.event.name }}
            </template>
            <template #column(category_name)="{ row }">
                <span v-if="hasMultipleSubcategories(row)" class="text-muted">Multiple</span>
                <span v-else>
                    {{ row.invoice.purchase_orders[0].category.name }}
                    ({{ row.invoice.purchase_orders[0].category.department.name }})
                </span>
            </template>
            <template #column(purchase_order)="{ row }">
                <span v-if="row.invoice.purchase_orders.length > 1" class="text-muted">Multiple</span>
                <span v-else>{{ row.invoice.purchase_orders[0].title }}</span>
            </template>
            <template #column(invoice)="{ row }">
                {{ row.invoice.invoice_number }}
            </template>
            <template #column(amount)="{ row }">
                {{ localise(row.amount, 2, true) }}
            </template>
            <template #column(date)="{ row }">
                {{ DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_MED) }}
            </template>
            <template #column(actions)="{ row }">
                <Tooltip v-if="row.reject_reason" :label="'Previously rejected: ' + row.reject_reason" position="left">
                    <i class="mdi mdi-alert text-danger"></i>
                </Tooltip>
            </template>
            <template #empty>
                <div class="text-center text-muted">There are no more available payments to add.</div>
            </template>
        </DataTable>
        <template #footer>
            <Button class="!tw-mr-auto" @click="close()">Cancel</Button>
            <Button type="is-success" :disabled="!selectedPayments.length" @click="addPayments()">Add Payments</Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { uniqueArray } from '@/js/utils.js';
import { DateTime } from 'luxon';

export default {
    components: {
        Modal,
        Button,
        Tooltip,
        DataTable,
    },
    props: {
        payrun: {
            type: Object,
            required: true,
        },
    },
    emits: ['add'],
    data() {
        return {
            selectedPayments: [],
            DateTime,
        };
    },
    methods: {
        open() {
            this.$refs.addPaymentModal.open();
        },
        close() {
            this.$refs.addPaymentModal.close();
        },
        reset() {
            this.selectedPayments = [];
        },
        addPayments() {
            this.$emit('add', this.selectedPayments);
            this.close();
        },
        hasMultipleSubcategories(row) {
            return uniqueArray(row.invoice.purchase_orders.map((po) => po.category_id)).length > 1;
        },
    },
};
</script>
