<template>
    <div>
        <LoadingSpinner v-if="!isLoaded" label="Loading Information" />
        <Chart v-else type="bar" :min-height="height" :options="chartOptions" :data="chartSeries" />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Chart from '../../../../../../js/components/Chart.vue';
import LoadingSpinner from '../../../../../../js/components/widgets/LoadingSpinner.vue';
import { localise } from '@/js/utils.js';
export default {
    components: {
        Chart,
        LoadingSpinner,
    },
    props: {
        height: {
            type: Number,
        },
        defaultView: {
            type: String,
            required: false,
            default: 'cost',
        },
        department: {
            required: false,
            type: Object,
        },
    },
    data() {
        const store = useDataStore();
        return {
            isLoaded: false,
            dataView: '',
            chartSeries: [],
            store: store,
        };
    },
    computed: {
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: false,
                        },
                    },
                    y: {
                        title: {
                            display: false,
                            text: 'Amount',
                        },
                        ticks: {
                            callback(value) {
                                return localise(value, 0, true);
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(context) {
                                return ' ' + localise(context.parsed.y, 2, true);
                            },
                        },
                    },
                },
            };
        },
    },
    watch: {
        defaultView(newVal) {
            this.dataView = newVal;
            this.reloadData();
        },
        department() {
            this.reloadData();
        },
    },
    mounted() {
        this.store.$subscribe(() => {
            this.reloadData();
        });

        Eventbus.$on('pinia:refresh', () => {
            this.reloadData();
        });

        this.dataView = this.defaultView;
        Eventbus.$on('reload:charts', () => {
            this.reloadData();
        });

        this.reloadData();
    },
    methods: {
        reloadData() {
            this.isLoaded = false;
            let chartables;

            if (this.department) {
                chartables = this.store.get(
                    'current_event.' +
                        (this.department.data.type === 1
                            ? 'cost_departments.' + this.department.data.id
                            : 'revenue_streams.' + this.department.data.id) +
                        '.categories'
                );
            } else {
                let departments = this.store.get('current_event.cost_departments');
                let streams = this.store.get('current_event.revenue_streams');

                if (this.dataView === 'revenue') {
                    chartables = streams;
                } else if (this.dataView === 'summary') {
                    chartables = {
                        ...streams,
                        ...departments,
                    };
                } else {
                    chartables = departments;
                }
            }

            const series = [
                {
                    data: [],
                    name: 'Fixed',
                    type: 'bar',
                    backgroundColor: '#FFAB5A',
                },
                {
                    data: [],
                    name: 'Budgeted',
                    type: 'bar',
                    backgroundColor: '#2DDAB5',
                },
                {
                    data: [],
                    name: 'Actual',
                    type: 'bar',
                    backgroundColor: '#4F6EE3',
                },
            ];
            const labels = [];
            Object.values(chartables).forEach(function (chartable) {
                labels.push(chartable.name.split(' '));
                series[0].data.push(
                    chartable.total_fixed - (chartable.stream_type === 0 ? chartable.total_fixed_costs : 0)
                );
                series[1].data.push(
                    chartable.total_budgeted - (chartable.stream_type === 0 ? chartable.total_fixed_costs : 0)
                );
                series[2].data.push(
                    chartable.total_actual - (chartable.stream_type === 0 ? chartable.total_actual_costs : 0)
                );
            });
            this.chartSeries = {
                labels,
                datasets: series.map((s) => ({
                    label: s.name,
                    data: s.data,
                    backgroundColor: s.backgroundColor,
                })),
            };

            this.isLoaded = true;
        },
    },
};
</script>
