<template>
    <div class="spa-page-wrapper">
        <Page title="Your Account">
            <!-- <template #submenu>
                <a href="#" :class="{ active: currentPage === 'personal' }" @click.prevent="setPage('personal')">
                    <i class="mdi mdi-card-account-details-outline mr-1"></i>
                    Personal Details
                </a>
                <a href="#" :class="{ active: currentPage === 'security' }" @click.prevent="setPage('security')">
                    <i class="mdi mdi-lock-outline mr-1"></i>
                    Security
                </a>
            </template> -->
            <Personal v-if="currentPage === 'personal'" />
            <!-- <Security v-else-if="currentPage === 'security'" /> -->
        </Page>
    </div>
</template>
<script>
import Page from '../Page.vue';
import Personal from './Me/Personal.vue';
import { setRoute } from '@/js/utils.js';

export default {
    components: {
        Page,
        Personal,
    },
    data() {
        return {
            currentPage: 'personal',
        };
    },
    mounted() {
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    methods: {
        handlePageChange() {
            this.currentPage = route().params.page ?? 'personal';
        },
        setPage(page = 'personal') {
            setRoute('me', {
                page: page,
            });
        },
    },
};
</script>
