<template>
    <div>
        <div class="tw-mt-4 tw-mb-2 tw-flex tw-gap-2 tw-border-b tw-border-l tw-border-r tw-bg-white tw-rounded">
            <FormField class="tw-px-2 tw-pb-2 tw-pt-1 tw-rounded tw-flex-[3]" label="Search">
                <Textbox v-model="searchQuery" placeholder="Search for a tag"></Textbox>
            </FormField>
        </div>
        <div class="buefy-wrapper tw-rounded tw-overflow-hidden tw-mb-3">
            <DataTable
                v-model="selectedTags"
                checkable
                centered
                :per-page-options="[10, 15, 20]"
                :header="false"
                :sidebar="false"
                :searchable-string="searchQuery"
                :endpoint="route('api.account.events.multiTagComparison')"
                :columns="[
                    {
                        title: 'Group',
                        field: 'name',
                    },
                    {
                        title: 'Tickets Sold',
                        field: 'tickets_sold',
                    },
                    {
                        title: 'Budgeted Revenue',
                        field: 'budgeted_revenue',
                    },
                    {
                        title: 'Actual Revenue',
                        field: 'actual_revenue',
                    },
                    {
                        title: 'Budgeted Costs',
                        field: 'budgeted_costs',
                    },
                    {
                        title: 'Actual Costs',
                        field: 'total_committed',
                    },
                    {
                        title: 'Budgeted Profit/Loss',
                        field: 'budgeted_profit_loss',
                    },
                    {
                        title: 'Actual Profit/Loss',
                        field: 'actual_profit_loss',
                    },
                ]"
                @options="updateOptions"
            >
                <template #column(name)="{ row }">
                    <GroupTag :tag="row" />
                </template>
                <template #column(tickets_sold)="{ row }">
                    <FigureCard title="Tickets Sold" :dot="false">
                        {{ row.tickets_sold }} / {{ row.tickets_available }}
                        <span
                            v-if="row.tickets_available"
                            class="tw-text-[10.5px] tw-text-gray-500 tw-absolute tw-bottom-[2px] tw-left-[3px]"
                        >
                            {{ ((row.tickets_sold / row.tickets_available) * 100).toFixed(1) }}%
                        </span>
                        <div class="tw-bg-red-500 tw-h-[2px] tw-absolute tw-w-[100%] tw-bottom-0 tw-left-0">
                            <div
                                v-if="row.tickets_available"
                                class="tw-bg-green-500 tw-h-[2px] tw-w-[100%]"
                                :style="{
                                    width:
                                        (row.tickets_sold / row.tickets_available) * 100 > 100
                                            ? 100
                                            : (row.tickets_sold / row.tickets_available) * 100 + '%',
                                }"
                            ></div>
                        </div>
                    </FigureCard>
                </template>
                <template #column(budgeted_revenue)="{ row }">
                    <FigureCard title="Budgeted Revenue" :amount="row.budgeted_revenue" figure-class="budgeted" />
                </template>
                <template #column(actual_revenue)="{ row }">
                    <FigureCard title="Actual Revenue" :amount="row.actual_revenue" figure-class="committed" />
                </template>
                <template #column(budgeted_costs)="{ row }">
                    <FigureCard title="Budgeted Costs" :amount="row.budgeted_costs" figure-class="budgeted" />
                </template>
                <template #column(total_committed)="{ row }">
                    <FigureCard title="Actual Costs" :amount="row.total_committed" figure-class="committed" />
                </template>
                <template #column(budgeted_profit_loss)="{ row }">
                    <FigureCard
                        :title="'Budgeted ' + (row.budgeted_revenue - row.budgeted_costs < 0 ? 'Loss' : 'Profit')"
                        :amount="row.budgeted_revenue - row.budgeted_costs"
                        :figure-class="[
                            'budgeted',
                            {
                                '!tw-text-red-500': row.budgeted_revenue - row.budgeted_costs < 0,
                            },
                        ]"
                    />
                </template>
                <template #column(actual_profit_loss)="{ row }">
                    <FigureCard
                        :title="'Actual ' + (row.actual_revenue - row.total_committed < 0 ? 'Loss' : 'Profit')"
                        :amount="row.actual_revenue - row.total_committed"
                        :figure-class="{
                            committed: true,
                            '!tw-text-red-500': row.actual_revenue - row.total_committed < 0,
                        }"
                    />
                </template>
                <template #prepend>
                    <tr class="tw-h-[60px]">
                        <td class="tw-bg-eventwise tw-leading-[16px]" colspan="2">
                            <span class="tw-block tw-font-avenir tw-text-white">Combined Figures</span>
                            <span class="tw-text-white tw-text-xs tw-opacity-[.66]">Sum of selected tags</span>
                        </td>
                        <template v-if="selectedTags.length">
                            <td class="tw-bg-eventwise">
                                <FigureCard title="Tickets Sold" class="tw-border-0" :dot="false">
                                    {{ totalTicketsSold }} / {{ totalTicketsAvailable }}
                                    <span
                                        v-if="totalTicketsAvailable"
                                        class="tw-text-[10.5px] tw-text-gray-500 tw-absolute tw-bottom-[2px] tw-left-[3px]"
                                    >
                                        {{ ((totalTicketsSold / totalTicketsAvailable) * 100).toFixed(1) }}%
                                    </span>
                                    <div class="tw-bg-red-500 tw-h-[2px] tw-absolute tw-w-[100%] tw-bottom-0 tw-left-0">
                                        <div
                                            v-if="totalTicketsAvailable"
                                            class="tw-bg-green-500 tw-h-[2px] tw-w-[100%]"
                                            :style="{
                                                width:
                                                    (totalTicketsSold / totalTicketsAvailable) * 100 > 100
                                                        ? 100
                                                        : (totalTicketsSold / totalTicketsAvailable) * 100 + '%',
                                            }"
                                        ></div>
                                    </div>
                                </FigureCard>
                            </td>
                            <td class="tw-bg-eventwise">
                                <FigureCard
                                    title="Budgeted Revenue"
                                    :amount="totalBudgetedRevenue"
                                    class="tw-border-0"
                                    :dot="false"
                                />
                            </td>
                            <td class="tw-bg-eventwise">
                                <FigureCard
                                    title="Actual Revenue"
                                    :amount="totalActualRevenue"
                                    class="tw-border-0"
                                    :dot="false"
                                />
                            </td>
                            <td class="tw-bg-eventwise">
                                <FigureCard
                                    title="Budgeted Costs"
                                    :amount="totalBudgetedCosts"
                                    class="tw-border-0"
                                    :dot="false"
                                />
                            </td>
                            <td class="tw-bg-eventwise">
                                <FigureCard
                                    title="Actual Costs"
                                    :amount="totalActualCosts"
                                    class="tw-border-0"
                                    :dot="false"
                                />
                            </td>
                            <td class="tw-bg-eventwise">
                                <FigureCard
                                    :title="'Budgeted ' + (totalBudgetedProfitLoss < 0 ? 'Loss' : 'Profit')"
                                    :amount="totalBudgetedProfitLoss"
                                    class="tw-border-0"
                                    :figure-class="{
                                        '!tw-text-red-500': totalBudgetedProfitLoss < 0,
                                    }"
                                    :dot="false"
                                />
                            </td>
                            <td class="tw-bg-eventwise">
                                <FigureCard
                                    :title="'Actual ' + (totalActualProfitLoss < 0 ? 'Loss' : 'Profit')"
                                    :amount="totalActualProfitLoss"
                                    class="tw-border-0"
                                    :figure-class="{
                                        '!tw-text-red-500': totalActualProfitLoss < 0,
                                    }"
                                    :dot="false"
                                />
                            </td>
                        </template>
                        <td
                            v-else
                            colspan="7"
                            class="tw-bg-eventwise !tw-text-center tw-text-white tw-uppercase tw-text-xs"
                        >
                            Select at least one group tag to view combined figures
                        </td>
                    </tr>
                </template>
            </DataTable>
        </div>
    </div>
</template>
<script>
import DataTable from '@/js/components/tables/DataTable.vue';
import GroupTag from '@/js/components/GroupTag.vue';
import FigureCard from '@/js/components/widgets/FigureCard.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';

export default {
    components: {
        DataTable,
        GroupTag,
        FigureCard,
        FormField,
        Textbox,
    },
    data() {
        return {
            searchQuery: '',
            availableTags: [],
            selectedTags: [],
        };
    },
    computed: {
        totalTicketsSold() {
            return this.selectedTags.reduce((acc, event) => acc + event.tickets_sold, 0);
        },
        totalTicketsAvailable() {
            return this.selectedTags.reduce((acc, event) => acc + event.tickets_available, 0);
        },
        totalBudgetedRevenue() {
            return this.selectedTags.reduce((acc, event) => acc + event.budgeted_revenue, 0);
        },
        totalActualRevenue() {
            return this.selectedTags.reduce((acc, event) => acc + event.actual_revenue, 0);
        },
        totalBudgetedCosts() {
            return this.selectedTags.reduce((acc, event) => acc + event.budgeted_costs, 0);
        },
        totalActualCosts() {
            return this.selectedTags.reduce((acc, event) => acc + event.total_committed, 0);
        },
        totalBudgetedProfitLoss() {
            return this.selectedTags.reduce((acc, event) => acc + (event.budgeted_revenue - event.budgeted_costs), 0);
        },
        totalActualProfitLoss() {
            return this.selectedTags.reduce((acc, event) => acc + (event.actual_revenue - event.total_committed), 0);
        },
    },
    methods: {
        updateOptions(options) {
            this.availableTags = options.tags ?? [];
        },
    },
};
</script>
