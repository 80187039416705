<template>
    <div class="buefy-wrapper">
        <div class="tw-flex tw-items-center tw-mb-2">
            <FormField>
                <Textbox v-model="searchQuery" placeholder="Search for an article..." />
            </FormField>

            <Button class="tw-ml-auto tw-flex tw-items-center tw-gap-1" @click="openHelpCategoryDrawer">
                <i class="mdi mdi-pencil"></i>
                Manage Categories
            </Button>
            <Button class="tw-ml-2 tw-flex tw-items-center tw-gap-1" type="is-primary" @click="viewArticle()">
                <i class="mdi mdi-plus"></i>
                New Article
            </Button>
        </div>
        <DataTable
            :columns="[
                {
                    title: 'ID',
                    field: 'id',
                },
                {
                    title: 'Help Article Title',
                    field: 'title',
                },
                {
                    title: 'Help Category',
                    field: 'help_category_name',
                },
                {
                    title: 'Published',
                    field: 'published',
                },
                {
                    title: 'Created By',
                    field: 'help_articles_creator_name',
                },
                {
                    title: 'Created At',
                    field: 'created_at_formatted',
                    sortable: true,
                },
                {
                    title: '',
                    field: 'actions',
                },
            ]"
            :endpoint="route('api.admin.help.articles')"
            :searchable-string="searchQuery"
        >
            <template #column(help_category_name)="{ row }">
                {{ row.help_category.name }}
            </template>
            <template #column(published)="{ row }">
                <i v-if="row.published" class="mdi mdi-check-circle" style="color: limegreen"></i>
                <i v-else class="mdi mdi-close-circle" style="color: red"></i>
            </template>
            <template #column(help_articles_creator_name)="{ row }">
                <User :user="row.creator" hide-avatar :hide-name="false" />
            </template>
            <template #column(actions)="{ row }">
                <Tooltip position="left" label="Edit Article">
                    <Button @click="viewArticle(row.id)">
                        <i class="mdi mdi-file-edit"></i>
                    </Button>
                </Tooltip>
            </template>
        </DataTable>
        <HelpCategories />
    </div>
</template>

<script>
import User from '../../widgets/User.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import HelpCategories from '../../drawers/HelpCategories.vue';
import Button from '@/js/components/controls/Button.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        HelpCategories,
        User,
        Button,
        FormField,
        Textbox,
        DataTable,
        Tooltip,
    },
    emits: ['view'],
    data() {
        const store = useDataStore();
        return {
            data: null,
            searchQuery: null,
            store: store,
        };
    },
    methods: {
        openHelpCategoryDrawer() {
            Eventbus.$emit('manage:help-categories');
        },
        viewArticle(id = null) {
            this.$emit('view', id);
        },
    },
};
</script>
