<template>
    <Drawer
        ref="drawer"
        :title="isCreating ? 'Create Subcategory' : 'Update Subcategory'"
        :loading="isLoading || isUpdating"
        size="is-medium"
        @closed="resetForm()"
    >
        <div class="tw-mx-3 tw-grid tw-gap-3">
            <FormField label="Name">
                <Textbox v-model="formData.name" placeholder="Please insert a name here" maxlength="255" required />
            </FormField>
            <FormField label="Description">
                <Textbox
                    v-model="formData.description"
                    placeholder="Please insert a description here"
                    maxlength="255"
                />
            </FormField>
            <FormField v-if="isCreating" label="Department">
                <Select
                    v-model="formData.department_id"
                    placeholder="Select a Department"
                    :loading="isLoading"
                    class="tw-w-full"
                >
                    <option v-for="department in departments" :key="department.id" :value="department.id">
                        {{ department.name }}
                    </option>
                </Select>
            </FormField>
            <FormField v-if="Auth.account && Auth.account.is_connected_to_xero" label="Xero Account">
                <Select
                    v-if="xeroAccounts.length"
                    v-model="formData.xero_account_id"
                    placeholder="Select a Xero Account"
                    :loading="isLoading"
                    class="tw-w-full"
                >
                    <option v-for="xeroAccount in xeroAccounts" :key="xeroAccount.id" :value="xeroAccount.id">
                        {{ xeroAccount.name }}
                    </option>
                </Select>
                <p v-else class="text-muted">You don't have any Xero Accounts available</p>
            </FormField>
            <p v-else-if="Auth.can('manage integrations')">
                Did you know? You can
                <a :href="route('account.integrations')">connect to Xero</a>
                to synchronise your data from Eventwise
            </p>
        </div>
        <template v-if="formData.name">
            <FormField label="Users" class="tw-bg-gray-100 tw-border tw-rounded tw-p-3 tw-mb-2">
                <template #label>
                    <div class="d-flex">
                        <div class="mr-auto">Users</div>
                        <a href="#" @click.prevent="toggleAllUsers()">
                            {{ formData.users.length === availableUsers.length ? 'Deselect all' : 'Select all' }}
                        </a>
                    </div>
                </template>
                <Checkbox
                    v-for="availableUser in availableUsers"
                    :key="availableUser.id"
                    :model-value="formData.users.includes(availableUser.id)"
                    @click="
                        formData.users.includes(availableUser.id)
                            ? (formData.users = formData.users.filter((u) => u !== availableUser.id))
                            : formData.users.push(availableUser.id)
                    "
                >
                    {{ availableUser.name }}
                </Checkbox>
            </FormField>
            <FormField label="Events" class="tw-bg-gray-100 tw-border tw-rounded tw-mb-2 tw-p-3">
                <template #label>
                    <div class="d-flex">
                        <div class="mr-auto">Events</div>
                        <a href="#" @click.prevent="toggleAllEvents()">
                            {{ formData.events.length === availableEvents.length ? 'Deselect all' : 'Select all' }}
                        </a>
                    </div>
                </template>
                <div v-for="availableEvent in availableEvents" :key="availableEvent.id">
                    <Checkbox
                        v-if="!hasEntries(availableEvent.id)"
                        :model-value="formData.events.includes(availableEvent.id)"
                        @click="
                            formData.events.includes(availableEvent.id)
                                ? (formData.events = formData.events.filter((e) => e !== availableEvent.id))
                                : formData.events.push(availableEvent.id)
                        "
                    >
                        {{ availableEvent.name }}
                    </Checkbox>
                    <Tooltip
                        v-else
                        :label="
                            'This subcategory contains active entries and cannot be unassigned (' +
                            getEntries(availableEvent.id) +
                            ')'
                        "
                    >
                        <Checkbox disabled>
                            {{ availableEvent.name }}
                        </Checkbox>
                    </Tooltip>
                </div>
            </FormField>
        </template>
        <template #footer>
            <Button
                type="is-primary"
                class="tw-block tw-ml-auto"
                :disabled="!formData.name || !(isCreating ? formData.department_id : true)"
                :loading="isLoading || isUpdating"
                @click="createSubcategory()"
            >
                {{ isCreating ? 'Create Subcategory' : 'Update Subcategory' }}
            </Button>
        </template>
    </Drawer>
</template>
<script>
import Drawer from '../../../../../js/components/Drawer.vue';
import Button from '@/js/components/controls/Button.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        Button,
        Textbox,
        Select,
        Checkbox,
        FormField,
        Tooltip,
    },
    data() {
        return {
            isLoading: true,
            isUpdating: false,
            isCreating: false,
            departments: [],
            xeroAccounts: [],
            availableEvents: [],
            availableUsers: [],
            formData: {
                department_id: null,
                users: [],
                events: [],
            },
            departmentId: 0,
        };
    },
    mounted() {
        Eventbus.$on('subcategory:open', (payload) => {
            this.resetForm();
            this.isCreating = false;
            if (payload) {
                this.departmentId = payload.department;
                if (!payload.hasOwnProperty('department')) {
                    toast(
                        'Error Opening Subcategory',
                        'No department was specified when opening the subcategory',
                        'error'
                    );
                    return;
                } else if (!payload.hasOwnProperty('category')) {
                    this.isCreating = true;
                    this.formData.department_id = this.departmentId;
                    this.openModal();
                    return;
                }
                this.openModal(this.departmentId, payload.category);
                return;
            }
            this.isCreating = true;
            this.openModal();
        });
    },
    methods: {
        openModal(departmentId = null, subcategoryId = null) {
            if (this.isCreating) {
                this.getDepartments();
            } else if (subcategoryId) {
                this.getSubcategory(departmentId, subcategoryId);
            }
            this.getXeroAccounts();
            this.getEvents();
            this.getUsers();
            this.$refs.drawer.open();
        },
        resetForm() {
            this.isLoading = true;
            this.isCreating = false;
            this.isUpdating = false;
            this.formData = {
                users: [],
                events: [],
            };
            this.xeroAccounts = [];
        },
        closeModal() {
            this.resetForm();
            this.$refs.drawer.close();
        },
        getDepartments() {
            this.isLoading = true;
            axios
                .get(route('api.account.departments'))
                .then((response) => (this.departments = response.data))
                .finally(() => (this.isLoading = false));
        },
        getSubcategory(departmentId, subcategoryId) {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.departments.categories.get', {
                        department: departmentId,
                        category: subcategoryId,
                    })
                )
                .then((response) => {
                    this.formData = {
                        ...response.data,
                        users: response.data.users.map((u) => u.id),
                        events: response.data.events.map((e) => e.id),
                    };
                })
                .finally(() => (this.isLoading = false));
        },
        getUsers() {
            axios.get(route('api.account.team')).then((response) => (this.availableUsers = response.data));
        },
        getEvents() {
            axios.get(route('api.account.event.allevents')).then((response) => (this.availableEvents = response.data));
        },
        getXeroAccounts() {
            if (!Auth.account || !Auth.account.is_connected_to_xero) {
                return;
            }
            axios.get(route('api.account.xero.accounts')).then((response) => (this.xeroAccounts = response.data));
        },
        createSubcategory() {
            this.isUpdating = true;
            const endpoint = this.isCreating
                ? route('api.account.departments.categories.create', {
                      department: this.formData.department_id,
                  })
                : route('api.account.departments.categories.update', {
                      department: this.formData.department_id,
                      category: this.formData.id,
                  });
            axios.post(endpoint, this.formData).then(() => {
                this.closeModal();
                toast('Success', 'Your subcategory has been ' + (this.isCreating ? 'created' : 'updated'), 'success');
                Eventbus.$emit('reload:category-list');
            });
        },
        hasEntries(eventId) {
            if (!this.formData || !this.formData.events || !this.formData.events.length) {
                return false;
            }
            const event = this.formData.events.find((availableEvent) => availableEvent.id === eventId);
            if (!event) {
                return false;
            }
            return !!(
                event.budget_entries_count ||
                event.purchase_orders_count ||
                event.revenue_entries_count ||
                event.ticket_types_count
            );
        },
        getEntries(eventId) {
            if (!this.formData || !this.formData.events || !this.formData.events.length) {
                return false;
            }
            const event = this.formData.events.find((availableEvent) => availableEvent.id === eventId);
            let messages = [];
            if (
                !(
                    event.budget_entries_count ||
                    event.purchase_orders_count ||
                    event.revenue_entries_count ||
                    event.ticket_types_count
                )
            ) {
                return false;
            }
            if (event.budget_entries_count) {
                messages.push(
                    event.budget_entries_count + ' budget entr' + (event.budget_entries_count === 1 ? 'y' : 'ies')
                );
            }
            if (event.purchase_orders_count) {
                messages.push(
                    event.purchase_orders_count + ' purchase order' + (event.purchase_orders_count === 1 ? '' : 's')
                );
            }
            if (event.revenue_entries_count) {
                messages.push(
                    event.revenue_entries_count + ' revenue entr' + (event.revenue_entries_count === 1 ? 'y' : 'ies')
                );
            }
            if (event.ticket_types_count) {
                messages.push(event.ticket_types_count + ' ticket type' + (event.ticket_types_count === 1 ? '' : 's'));
            }
            return messages.join(', ');
        },
        toggleAllUsers() {
            if (this.formData.users && this.formData.users.length === this.availableUsers.length) {
                this.formData.users = [];
            } else {
                this.formData.users = this.availableUsers.map((u) => u.id);
            }
        },
        toggleAllEvents() {
            if (this.formData.events && this.formData.events.length === this.availableEvents.length) {
                this.formData.events = [];
            } else {
                this.formData.events = this.availableEvents.map((e) => e.id);
            }
        },
    },
};
</script>
