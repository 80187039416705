<template>
    <div>
        <div v-if="!currentPage" class="onboarding-account-display">
            <div class="d-flex">
                <div id="onboarding-account-image"></div>
                <div>
                    <h5 class="m-0">{{ account.name }}</h5>
                    <small>Created {{ timeAgo(account.created_at) }} by {{ account.created_by.name }}</small>
                </div>
            </div>
            <a v-if="!currentPage" href="#" @click.prevent="$emit('switch')">Switch account</a>
            <a v-else href="#" @click.prevent="currentPage = null">Return to events</a>
        </div>
        <EventSelector v-if="!currentPage" :account="account" @navigate="setPage" />
        <CreateEvent v-else-if="currentPage == 'create'" :account="account" @return="currentPage = null" />
        <CreateEvent v-else-if="currentPage == 'copy'" :account="account" copy @return="currentPage = null" />
        <SetupPaymentDetails
            v-if="!account.is_demo && !account.has_payment_method && account.stripe_id"
            :account="account"
            @setup="completePaymentSetup()"
            @return="$emit('switch')"
        />
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import EventSelector from './Account/EventSelector.vue';
import CreateEvent from './Account/CreateEvent.vue';
import SetupPaymentDetails from './Account/SetupPaymentDetails.vue';
import { timeAgo } from '@/js/utils.js';

export default {
    components: {
        EventSelector,
        CreateEvent,
        SetupPaymentDetails,
    },
    props: {
        account: {
            type: Object,
        },
    },
    emits: ['switch'],
    data() {
        const store = useDataStore();

        return {
            currentPage: null,
            currentUser: store.get('current_user'),
            store: store,
        };
    },
    methods: {
        timeAgo,
        setPage(page = null) {
            this.currentPage = page;
        },
        completePaymentSetup() {
            this.store.set(
                'available_accounts',
                this.store.get('available_accounts').map((a) => {
                    if (a.id === this.account.id) {
                        a.stripe_id = true;
                        a.has_payment_method = true;
                    }
                    return a;
                })
            );
            // eslint-disable-next-line vue/no-mutating-props
            this.account.stripe_id = true;
            // eslint-disable-next-line vue/no-mutating-props
            this.account.has_payment_method = true;
        },
    },
};
</script>
<style lang="scss">
#onboarding-account-image {
    width: 36px;
    height: 36px;
    background: rgba(black, 0.1);
    border: 1px solid rgba(black, 0.1);
    border-radius: 5px;
    margin-right: 12px;
}
.onboarding-account-display {
    display: flex;
    align-items: center;
    padding: 0 25px 18px 25px;
    border-bottom: 1px solid rgba(black, 0.1);

    & > a {
        margin-left: auto;
    }
}

.onboarding-events-display {
    display: flex;

    & > div {
        flex: 1;
        padding: 20px 25px;

        & .onboarding-event-list {
            display: flex;
            flex-direction: column;
            gap: 6px;

            & > a {
                display: flex;
                align-items: center;
                color: inherit;
                line-height: 95%;
                border: 1px solid rgba(black, 0.1);
                padding: 8px;
                border-radius: 6px;

                & > .onboarding-event-image {
                    width: 32px;
                    height: 32px;
                    background: rgba(black, 0.05);
                    border-radius: 4px;
                    border: 1px solid rgba(black, 0.1);
                    margin-right: 8px;
                }

                h6 {
                    margin-bottom: 0;
                }
                small {
                    opacity: 0.5;
                }
            }
        }

        &.onboarding-events-panel {
            display: flex;
            flex-direction: column;
        }
        &.onboarding-actions-panel {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;
            border-left: 1px solid rgba(black, 0.05);
            border-bottom-right-radius: 15px;

            & > a {
                display: flex;
                width: 75%;
                background: white;
                padding: 10px;
                align-items: center;
                border-radius: 8px;
                border: 1px solid rgba(black, 0.1);
                color: inherit;
                position: relative;
                transition: all 0.2s ease;
                transform: sclae(1);

                & > i {
                    font-size: 20px;
                    margin-right: 15px;
                    margin-left: 10px;
                }
                & > div {
                    display: flex;
                    flex-direction: column;
                    font-weight: 500;
                    line-height: 125%;

                    & > small {
                        font-weight: 400;
                        opacity: 0.5;
                    }
                }

                &:not(.coming-soon):not(.upgrade):hover {
                    transform: scale(1.05);
                }

                &.coming-soon {
                    opacity: 0.6;
                    transform: scale(0.95);
                    cursor: default;

                    &:after {
                        content: 'COMING SOON';
                        position: absolute;
                        background: rgba(black, 0.9);
                        color: white;
                        padding: 3px 8px;
                        border-radius: 4px;
                        font-weight: 500;
                        right: 15px;
                    }
                }
                &.upgrade {
                    opacity: 0.6;
                    transform: scale(0.95);
                    cursor: default;

                    &:after {
                        content: 'DISABLED ON DEMO ACCOUNTS';
                        position: absolute;
                        background: rgba(black, 0.9);
                        color: white;
                        padding: 3px 8px;
                        border-radius: 4px;
                        font-weight: 500;
                        right: 15px;
                    }
                }
            }
        }
    }
}
</style>
