<template>
    <div class="buefy-wrapper">
        <DataTable
            :data="paymentDates"
            :columns="
                [
                    {
                        field: 'date',
                        title: 'Date',
                    },
                    {
                        field: 'amount',
                        title: 'Amount',
                    },
                    store.get('account.is_connected_to_xero')
                        ? {
                              field: 'xero_account_id',
                              title: 'Bank Account',
                          }
                        : null,
                    {
                        field: 'is_paid',
                        title: 'Paid',
                    },
                    {
                        field: 'actions',
                        title: '',
                    },
                ].filter((v) => !!v)
            "
        >
            <template #column(date)="{ row }">
                <flat-pickr
                    v-model="row.date"
                    :placeholder="row.date"
                    :config="flatpickrConfig"
                    class="is-small"
                    required
                />
            </template>
            <template #column(amount)="{ row }">
                <EditableText v-model="row.amount" type="currency" />
            </template>

            <template #column(xero_account_id)="{ row }">
                <Select v-model="row.xero_account_id" placeholder="Please select a bank account">
                    <option v-for="xeroAccount in filteredXeroAccounts" :key="xeroAccount.id" :value="xeroAccount.id">
                        {{ `${xeroAccount.code} - ${xeroAccount.name}` }}
                    </option>
                </Select>
            </template>
            <template #column(is_paid)="{ row }">
                <Checkbox v-model="row.is_paid">Paid</Checkbox>
            </template>
            <template #column(actions)="{ index }">
                <Button type="is-danger" @click="removePaymentDate(index)">
                    <i class="mdi mdi-close"></i>
                </Button>
            </template>
            <template #empty>
                <p class="empty-label py-2">No payment dates have been created</p>
            </template>
        </DataTable>
        <Button type="is-link" class="tw-w-full" @click="addPaymentDate()">New payment date</Button>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import EditableText from '../controls/EditableText.vue';
import Button from '@/js/components/controls/Button.vue';
import Select from '@/js/components/controls/Select.vue';
import Checkbox from '@/js/components/controls/Checkbox.vue';
import DataTable from '@/js/components/tables/DataTable.vue';

export default {
    components: {
        DataTable,
        EditableText,
        Button,
        Select,
        Checkbox,
    },
    props: {
        modelValue: {
            type: Array,
            default() {
                return [];
            },
        },
        default: {
            type: Number,
            default: 0,
        },
    },
    data() {
        const store = useDataStore();

        return {
            store: store,
            paymentDates: [],
            xeroAccounts: store.get('account.is_connected_to_xero')
                ? store.get('account.xero_organisation.xero_accounts')
                : [],
        };
    },
    computed: {
        filteredXeroAccounts() {
            if (!this.xeroAccounts) {
                return [];
            }
            return Object.values(this.xeroAccounts).filter(
                (xeroAccount) => xeroAccount.type === 'BANK' && xeroAccount.code
            );
        },
    },
    watch: {
        modelValue: {
            handler(newVal) {
                this.paymentDates = newVal;
            },
            deep: true,
        },
    },
    mounted() {
        this.paymentDates = this.modelValue;
    },
    methods: {
        addPaymentDate() {
            const currentDate = new Date();
            this.paymentDates.push({
                date: currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate(),
                amount: this.default,
                xero_account_id: null,
                is_paid: false,
            });
        },
        removePaymentDate(index) {
            this.paymentDates.splice(index, 1);
        },
    },
};
</script>
