<template>
    <div v-if="isLoading" class="py-5 w-100 text-center text-muted">
        <i class="las la-spinner la-spin d-block font-18"></i>
        Loading Information
    </div>
    <div v-else class="buefy-wrapper">
        <Banner class="mt-5 mb-4 py-3" type="is-primary">
            <p>
                This report displays the Ticket comparisons for events and or groups.
                <br />
                If no Events have been selected, the option to select groups is available to provide comparisons across
                tags
                <br />
            </p>
        </Banner>
        <div class="card justify-content-start p-3">
            <div>
                <span class="h3 ml-3 my-2">Ticket Report</span>
            </div>
            <div class="mt-2">
                <DataTable
                    :data="availableEvents"
                    :columns="[
                        {
                            title: 'Event',
                            field: 'name',
                        },
                        {
                            title: 'Starts At',
                            field: 'starts_at',
                        },
                        {
                            title: 'Ends At',
                            field: 'ends_at',
                        },
                        {
                            title: 'Tickets Available',
                            field: 'ticket_sales_sum_tickets_available',
                        },
                        {
                            title: 'Tickets Sold',
                            field: 'ticket_orders_sum_quantity',
                        },
                        {
                            title: 'Tickets left',
                            field: 'ticket_left',
                        },
                        {
                            title: 'Avg Price',
                            field: 'avg_price',
                        },
                    ]"
                    centered
                >
                    <template #column(starts_at)="{ row }">
                        {{ toDateString(row.starts_at) }}
                    </template>
                    <template #column(ends_at)="{ row }">
                        {{ toDateString(row.ends_at) }}
                    </template>
                    <template #column(ticket_sales_sum_tickets_available)="{ row }">
                        {{ catchNull(row.ticket_sales_sum_tickets_available) }}
                    </template>
                    <template #column(ticket_orders_sum_quantity)="{ row }">
                        {{ catchNull(row.ticket_orders_sum_quantity) }}
                        ({{
                            percentageSold(
                                row.ticket_sales_sum_tickets_available,

                                row.ticket_orders_sum_quantity
                            )
                        }}%)
                    </template>
                    <template #column(ticket_left)="{ row }">
                        {{ row.ticket_sales_sum_tickets_available - row.ticket_orders_sum_quantity }}
                    </template>
                    <template #column(avg_price)="{ row }">
                        {{ localise(row.ticketSales_mean, 2, true) }}
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '../../../../../js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';

export default {
    components: {
        DataTable,
        // GroupTag,
        Banner,
    },
    props: {
        eventIds: {
            type: Array,
            required: true,
        },
        // TODO tagIds comes from the Reporting component, it will need to be required
        tagIds: {
            type: Array,
            required: false,
        },
    },
    emits: [],
    data() {
        return {
            isLoading: true,
            eventData: [],
            availableEvents: [],
            localTags: [],
            tagCount: 0,
        };
    },
    computed: {},
    watch: {},
    mounted() {
        this.fetchEvents();
    },
    methods: {
        fetchEvents() {
            // Fetch the Selected Events
            this.isLoading = true;
            axios
                .get(route('api.account.events.fetch-events', { eventIds: this.eventIds }))
                .then(({ data }) => {
                    this.eventData = data;
                    this.filterNoTickets();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        fetchTags() {
            this.isLoading = true;
            this.localTags = [];
            this.profitsAndLosses = [];
            this.tagCount = this.tagIds.length;
            // foreach tagId
            for (const tagId of this.localTagIds) {
                // get the localTags for the tags
                this.reloadTagEvents(tagId);
            }
            this.isLoading = false;
        },
        toDateString(dateStr) {
            const date = new Date(dateStr);
            return date.toDateString();
        },
        catchNull(value) {
            if (value == null) {
                return 0;
            }
            return value;
        },
        percentageSold(available, quantity) {
            // check for available and quantity being 0 or null to avoid divide by zero errors
            if (available === 0 || quantity === 0 || available == null || quantity == null) {
                return 0;
            }
            return ((quantity / available) * 100).toFixed(2);
        },
        filterNoTickets(filter = false) {
            // filter against having no tickets
            if (!filter) {
                this.availableEvents = this.eventData;
                return;
            }
            this.availableEvents = this.eventData.filter((event) => event.ticket_sales_sum_tickets_available > 0);
        },
        reloadTagEvents(tagId) {
            axios
                .get(
                    route('api.account.reporting.tagAnalysis', {
                        groupTag: tagId,
                    })
                )
                .then(({ data }) => {
                    this.localTags.push(data.tag);
                    this.profitsAndLosses.push(data.profitAndLoss);
                });
        },
    },
};
</script>

<style scoped lang="scss"></style>
