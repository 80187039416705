<template>
    <div class="onboarding-manage-categories-modal">
        <Modal ref="modal" title="Manage Subcategories" paddingless size="is-4xlarge" @closing="resetForm()">
            <subcategory-editor
                v-if="department"
                v-model="department.categories"
                :estimates="true"
                :xero-accounts="account.xero_accounts"
                :show-editing="false"
                :enable-delete-existing="true"
                :revenue="!!department.stream_type"
                :source="source"
                :tickets="department.stream_type === 0"
            ></subcategory-editor>
            <template #footer>
                <div class="tw-flex tw-gap-2 tw-w-full">
                    <Button type="is-success" @click="department.categories.push({})">Create New</Button>
                    <Dropdown :active="!!availableExistingCategories.length">
                        <template #trigger="{ active }">
                            <Button class="tw-flex tw-gap-1" :disabled="!availableExistingCategories.length">
                                Add Existing
                                <i :class="active ? 'mdi mdi-menu-up' : 'mdi mdi-menu-down'"></i>
                            </Button>
                        </template>
                        <span v-if="!availableExistingCategories.length" class="tw-text-gray-400">
                            No existing categories available
                        </span>
                        <Button
                            v-for="(existingCategory, index) in availableExistingCategories"
                            :key="index"
                            type="is-link"
                            class="!tw-py-0"
                            @click="department.categories.push(existingCategory)"
                        >
                            {{ existingCategory.name }}
                        </Button>
                    </Dropdown>
                    <Button class="!tw-ml-auto" @click="saveChanges">Close</Button>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import Modal from '@/js/components/Modal.vue';
import SubcategoryEditor from '../../utility/subcategory-editor.vue';
import Button from '@/js/components/controls/Button.vue';
import Dropdown from '@/js/components/controls/Dropdown.vue';
import { localise } from '@/js/utils.js';

const props = defineProps({
    account: {
        type: Object,
    },
    source: {
        type: String,
        default: 'onboarding',
    },
    existingDepartments: {
        type: Array,
        default: () => [],
    },
});

const emit = defineEmits(['updated']);

const department = ref(null);
const originalDepartment = ref(null);
const modal = ref(null);

const availableExistingCategories = computed(() => {
    if (!originalDepartment.value || !originalDepartment.value.categories) {
        return [];
    }
    return originalDepartment.value.categories.filter(
        (existingCategory) => !department.value.categories.find((category) => category.id === existingCategory.id)
    );
});

const openModal = (dept) => {
    if (!dept) {
        return;
    }
    department.value = dept;
    originalDepartment.value = props.existingDepartments.find((d) => d.id === dept.id);
    modal.value.open();
};

const resetForm = () => {
    department.value = null;
    originalDepartment.value = null;
};

const saveChanges = () => {
    const totalCategoryFixed = department.value.categories
        .map((c) => (c.fixed_amount ? parseFloat(c.fixed_amount) : 0))
        .reduce((c, a) => c + a, 0);
    if (department.value.fixed_amount && department.value.fixed_amount != totalCategoryFixed) {
        swal.fire({
            title: 'Warning',
            text: `You specified that ${department.value.name} has a fixed amount of ${localise(
                department.value.fixed_amount,
                2,
                true
            )} but your subcategories add up to ${localise(
                totalCategoryFixed,
                2,
                true
            )}. Would you still like to save your changes?`,
            showCancelButton: true,
            confirmButtonText: 'Continue',
        }).then((result) => {
            if (result.value) {
                saveAndClose();
            }
        });
        return;
    }
    saveAndClose();
};

const saveAndClose = () => {
    emit('updated', department.value);
    modal.value.close();
};

defineExpose({
    openModal,
});
</script>
