<template>
    <Modal ref="modal" title="Raise Sales Invoice" size="is-xxlarge" :loading="isLoading" @closing="resetModal()">
        <div v-if="revenueEntry">
            <template v-if="!hasSavedSupplier">
                <Banner type="is-warning">
                    Please complete the address fields for
                    <strong>{{ revenueEntry.supplier.name }}</strong>
                    before continuing
                </Banner>
                <FormField label="Company Name (required)" class="tw-mb-2">
                    <Textbox v-model="revenueEntry.supplier.name" placeholder="Name" required />
                </FormField>
                <FormField label="Address Line 1" class="tw-mb-2">
                    <Textbox v-model="revenueEntry.supplier.address1" placeholder="Address Line 1" />
                </FormField>
                <FormField label="Address Line 2" class="tw-mb-2">
                    <Textbox v-model="revenueEntry.supplier.address2" placeholder="Address Line 2" />
                </FormField>
                <div class="tw-flex tw-mb-2">
                    <FormField class="tw-flex-1 pr-3" label="City">
                        <Textbox v-model="revenueEntry.supplier.city" placeholder="City" />
                    </FormField>
                    <FormField label="Postal Code">
                        <Textbox v-model="revenueEntry.supplier.postal_code" placeholder="Postal Code" />
                    </FormField>
                </div>
                <div class="d-flex" style="gap: 0.75rem">
                    <FormField style="width: 50%" label="Country">
                        <Select v-model="revenueEntry.supplier.country" placeholder="Country" expanded>
                            <option v-for="country in countries" :key="country" :value="country">
                                {{ country }}
                            </option>
                        </Select>
                    </FormField>
                    <FormField style="width: 50%" label="VAT Number">
                        <Textbox v-model="revenueEntry.supplier.tax_number" placeholder="VAT Number" />
                    </FormField>
                </div>
            </template>

            <template v-else-if="step === 0">
                <FormField v-if="lineItemCount" label="Line Items">
                    <Select
                        v-model="selectedLineItems"
                        multiple
                        native-size="4"
                        class="tw-w-full"
                        @update:model-value="loadItemsIntoForm"
                    >
                        <option v-for="lineItem in availableLineItems" :key="lineItem.id" :value="lineItem.id">
                            {{ lineItem.description }} - {{ localise(lineItem.subtotal, 2, true) }}
                        </option>
                    </Select>
                </FormField>
                <Banner v-else>
                    All available line items on this revenue entry have been invoiced.
                    <br />
                    Go back and add another line item to continue.
                </Banner>
            </template>

            <template v-else>
                <div class="tw-flex">
                    <FormField class="tw-flex-1 tw-pr-1" label="Invoice Date">
                        <flat-pickr
                            v-model="formData.invoice_date"
                            placeholder="Required"
                            :config="flatpickrConfig"
                            class="input"
                            required
                        />
                    </FormField>

                    <FormField class="tw-flex-1 tw-pl-1" label="Due Date">
                        <flat-pickr
                            v-model="formData.due_date"
                            placeholder="Required"
                            :config="flatpickrConfig"
                            class="input"
                            required
                        />
                    </FormField>
                </div>

                <FormField class="tw-flex-1" label="Invoice Number">
                    <Textbox v-model="formData.invoice_number" class="tw-w-full" />
                </FormField>

                <LineItemBuilder v-model="formData.items" tax-rate-type="income" />

                <FormField class="tw-mt-3">
                    <Upload v-model="formData.files" :accept="['application/pdf']" multiple label="Upload" />
                    <div v-if="formData.files.length" class="tags mt-2">
                        <span v-for="(file, index) in formData.files" :key="index" class="tag is-primary">
                            {{ file.name }}
                            <button
                                class="delete is-small"
                                type="button"
                                @click.prevent="formData.files.splice(index, 1)"
                            />
                        </span>
                    </div>
                </FormField>
            </template>
        </div>

        <template #footer>
            <Button :disabled="isLoading" @click="closeModal()">Cancel</Button>
            <FormField v-if="revenueEntry && revenueEntry.supplier" class="tw-pl-4" label="Customer">
                {{ revenueEntry.supplier.name }}
            </FormField>
            <div class="tw-flex-1"></div>
            <FormField v-if="formData.revenue_entry_line_item_id" class="tw-text-right tw-pr-5" label="Total">
                {{ localise(totalValue, 2, true) }}
            </FormField>

            <Button v-if="step > 0" @click="step--">Back</Button>
            <Button
                v-if="!hasSavedSupplier"
                :disabled="!revenueEntry?.supplier.name"
                type="is-success"
                @click="saveSupplier()"
            >
                Save Supplier
            </Button>
            <Button
                v-else-if="step === 0 || step < 1"
                :disabled="!selectedLineItems.length"
                type="is-success"
                @click="step++"
            >
                Next
            </Button>
            <Button v-else type="is-success" :disabled="!selectedLineItems.length" @click="raiseInvoice()">
                Raise Invoice
            </Button>
        </template>
    </Modal>
</template>
<script>
import Modal from '../../Modal.vue';
import LineItemBuilder from '../../widgets/LineItemBuilder.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Select from '@/js/components/controls/Select.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Upload from '@/js/components/controls/Upload.vue';
import { obj2form, toast } from '@/js/utils.js';

export default {
    components: {
        Modal,
        LineItemBuilder,
        Button,
        FormField,
        Textbox,
        Select,
        Banner,
        Upload,
    },
    emits: ['created', 'reload'],
    data() {
        const store = useDataStore();
        return {
            step: 0,
            isLoading: false,
            hasSavedSupplier: false,
            revenueEntry: null,
            selectedLineItems: [],
            formData: {
                invoice_date: new Date().toISOString().split('T')[0],
                due_date: new Date().toISOString().split('T')[0],
                items: [],
                files: [],
            },
            xeroTaxRates: Object.values(store.get('account.tax_rates')),
            countries: window.Locales,
            store: store,
        };
    },
    computed: {
        totalValue() {
            const subtotal = this.formData.subtotal ? parseFloat(this.formData.subtotal) : 0;
            const taxRate = this.store.get('account.tax_rates.' + this.formData.tax_rate_id);
            return subtotal + (subtotal / 100) * (taxRate ? taxRate.rate : 0);
        },
        lineItemCount() {
            return (
                Object.values(this.revenueEntry.line_items).filter(function (item) {
                    return item.is_invoiced === false;
                }).length ?? 0
            );
        },
        availableLineItems() {
            if (!this.revenueEntry) {
                return [];
            }
            return this.revenueEntry.line_items.filter((lineItem) => !lineItem.is_invoiced);
        },
        supplierIsValid() {
            if (!this.revenueEntry || !this.revenueEntry.supplier) {
                return false;
            }
            return (
                this.revenueEntry.supplier.address1 &&
                this.revenueEntry.supplier.city &&
                this.revenueEntry.supplier.postal_code &&
                this.revenueEntry.supplier.country
            );
        },
    },
    mounted() {
        Eventbus.$on('salesInvoice:create', (payload) => {
            this.loadRevenueEntry(payload.entry);
            this.openModal();
        });
    },
    methods: {
        loadRevenueEntry(revenueEntryId) {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.event.revenue.get', {
                        revenueEntry: revenueEntryId,
                    })
                )
                .then((response) => {
                    this.resetModal();
                    this.revenueEntry = response.data;
                    this.isLoading = false;
                    if (this.supplierIsValid) {
                        this.hasSavedSupplier = true;
                    }
                    if (this.lineItemCount === 1) {
                        let item = Object.values(this.revenueEntry.line_items).filter(function (item) {
                            return item.is_invoiced === false;
                        });

                        this.selectedLineItems.push(item[0].id);
                        this.loadItemsIntoForm();
                        if (this.supplierIsValid) {
                            this.step++;
                        }
                    }
                    const prefix = this.store.get('account.si_prefix');
                    this.formData.invoice_number =
                        (prefix ? prefix + '-' : '') + (this.store.get('account.last_si_number') + 1);
                });
        },
        openModal() {
            if (this.$refs.modal) {
                this.$refs.modal.open();
            }
        },
        closeModal() {
            if (this.$refs.modal) {
                this.$refs.modal.close();
                this.resetModal();
            }
        },
        resetModal() {
            this.step = 0;
            this.revenueEntry = null;
            this.hasSavedSupplier = false;
            this.selectedLineItems = [];
            this.formData = {
                invoice_number: '',
                invoice_date: new Date().toISOString().split('T')[0],
                due_date: new Date().toISOString().split('T')[0],
                items: [],
                files: [],
            };
        },
        loadItemsIntoForm() {
            this.formData.items = [];

            this.selectedLineItems.forEach((lineItemId) => {
                let lineItem = this.revenueEntry.line_items.find((item) => item.id === lineItemId);
                if (!lineItem) {
                    return;
                }
                this.formData.items.push({
                    revenue_entry_line_item_id: lineItem.id,
                    description: lineItem.description ?? null,
                    quantity: lineItem.quantity,
                    unit_price: parseFloat(lineItem.unit_price ?? 0).toFixed(2),
                    tax_rate_id: lineItem.tax_rate_id ?? null,
                });
            });
        },
        raiseInvoice() {
            this.isLoading = true;
            axios
                .post(
                    route('api.account.event.revenue.invoices.raiseInvoice', {
                        revenueEntry: this.revenueEntry.id,
                    }),
                    obj2form(this.formData)
                )
                .then((response) => {
                    this.isLoading = false;
                    data.account.last_si_number++;
                    this.$emit('created', response.data.id);
                    toast('Invoice Created', 'Your invoice has been created successfully', 'success');
                    this.closeModal();
                    this.$emit('reload');
                });
        },
        async saveSupplier() {
            this.isLoading = true;
            if (!this.supplierIsValid) {
                const { value: confirmation } = await swal.fire({
                    title: 'Warning',
                    text: 'Are you sure you would like to continue without a full postal address?',
                    showCancelButton: true,
                    confirmButtonText: "Yes, I'm sure",
                });

                if (!confirmation) {
                    this.isLoading = false;
                    return;
                }
            }

            axios
                .patch(
                    route('api.account.contacts.update', {
                        supplier: this.revenueEntry.supplier_id,
                    }),
                    {
                        name: this.revenueEntry.supplier.name,
                        address1: this.revenueEntry.supplier.address1,
                        address2: this.revenueEntry.supplier.address2,
                        city: this.revenueEntry.supplier.city,
                        postal_code: this.revenueEntry.supplier.postal_code,
                        country: this.revenueEntry.supplier.country,
                    }
                )
                .then(() => (this.hasSavedSupplier = true))
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
