import '../../../css/app.css';
import { createApp, h } from 'vue';
import * as Sentry from '@sentry/vue';
import '../../sass/metrica/app.scss';
import '../../sass/eventbooks/app.scss';
import '../../sass/eventbooks/minimal.scss';
import InstantSearch from 'vue-instantsearch/vue3/es';
import App from '@/js/components/App.vue';
import { createPinia } from 'pinia';
import FloatingVue from 'floating-vue';
import { localise, Auth, route } from '@/js/utils.js';

// Commented out until we can sort URL defaults.
// EW is a literal SPA currently so perhaps this gets done with Inertia where there is a middleware to update the defaults on each request.
// const ziggyResponse = await fetch('/api/ziggy');
// const Ziggy = await ziggyResponse.json();

const pinia = createPinia();
const app = createApp({ render: () => h(App, {}) });

if (['production', 'staging'].includes(document.querySelector('meta[name="sentry-environment"]')?.content)) {
    const propagationTargets = document
        .querySelector('meta[name="sentry-propagation-targets"]')
        .content.split(',')
        .map((r) => new RegExp(r));

    Sentry.init({
        app: app,
        dsn: document.querySelector('meta[name="sentry-public-dsn"]').content,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        environment: document.querySelector('meta[name="sentry-environment"]').content,
        release: document.querySelector('meta[name="sentry-release"]').content,
        tracesSampleRate: document.querySelector('meta[name="sentry-sample-rate"]').content,
        tracePropagationTargets: propagationTargets,
        replaysSessionSampleRate: document.querySelector('meta[name="sentry-replay-sample-rate"]').content,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event, hint) {
            if (hint?.originalException?.message?.startsWith('sentryIgnore-')) {
                return null;
            }

            return event;
        },
    });
}

import { Interval } from 'luxon';

// TODO :: Eventually remove these and import instead, or use `provide` and `inject` RE best practice.
app.config.globalProperties.localise = localise;
window.localise = localise;
app.config.globalProperties.Auth = Auth;
app.config.globalProperties.route = route;
app.config.globalProperties.Interval = Interval;
app.config.globalProperties.flatpickrConfig = {
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d',
    altInputClass:
        'tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400',
    locale: {
        firstDayOfWeek: 1,
    },
};
app.config.globalProperties.flatpickrMultiConfig = {
    mode: 'range',
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d',
    altInputClass:
        'tw-text-xs tw-border tw-border-gray-300 tw-w-full tw-px-3 tw-py-1.5 tw-rounded focus:tw-border-gray-400',
    locale: {
        firstDayOfWeek: 1,
    },
};

const eventbusEvents = {};
window.Eventbus = {
    _events: eventbusEvents,
    $emit: (name, ...data) => {
        dispatchEvent(new CustomEvent(name, { detail: data }));
    },
    $on: (name, callback) => {
        const func = (event) => {
            callback(...event.detail);
        };
        eventbusEvents[name] = func;
        addEventListener(name, func);
    },
};
import axios from 'axios';

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) {
            throw error;
        }
        if (error.response.status === 422) {
            if (error.response.data && error.response.data.errors) {
                const errors = Object.values(error.response.data.errors).join('\n');
                swal.fire('Error', errors, 'error');
                return Promise.reject(new Error('sentryIgnore-' + errors));
            }
        } else if (error.response.status === 500) {
            swal.fire(
                'Internal Server Error',
                "An internal server error has occurred and we're unable to process your request. We have been automatically notified of this error.",
                'error'
            );
            return Promise.reject(error);
        } else if (error.response.status === 403) {
            swal.fire('Forbidden', error.response.data.message, 'error');
            return Promise.reject(error);
        }
        throw error;
    }
);

window.axios = axios;

import * as DateFns from 'date-fns';
window.dateFns = DateFns;

import AppComponent from '@/js/components/App.vue';
import TwoFactorAuthenticate from '@/js/components/TwoFactorAuthenticate.vue';
import InvitationAccept from '@/js/components/InvitationAccept.vue';
import VueFlatPickr from 'vue-flatpickr-component';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
window.monthSelectPlugin = MonthSelectPlugin;
import Admin from '@/js/components/pages/Admin.vue';
import GlobalNotifications from './components/utility/global-notifications.vue';
import InputEdit from './components/input-edit.vue';

app.component('App', AppComponent);
app.component('twofactorauthenticate', TwoFactorAuthenticate);
app.component('invitationaccept', InvitationAccept);
app.component('flat-pickr', VueFlatPickr);
app.component('page-admin', Admin);
app.component('global-notifications', GlobalNotifications);
app.component('input-edit', InputEdit);

import Card from './components/templates/_card.vue';
import ApprovalsRow from './components/approvals/row.vue';
import BudgetApprovalsCard from './components/budget/approvals/card.vue';
import DrawerBudgetingTab from './components/drawer-tabs/budgeting.vue';
import DrawerFilesTab from './components/drawer-tabs/files.vue';
import DrawerCommentsTab from './components/drawer-tabs/comments.vue';
import DrawerActivityTab from './components/drawer-tabs/activity.vue';
import DrawerAdjustmentTab from './components/drawer-tabs/adjustment.vue';
import DrawerExtraInfoTab from './components/drawer-tabs/extra-info.vue';
import ControlAddresses from './components/utility/controls/addresses.vue';
import ControlCurrencies from './components/utility/controls/currencies.vue';
import ControlIcon from './components/utility/controls/icon.vue';
import ControlSelectTwoWay from './components/utility/controls/select/two-way.vue';
import ControlStreamType from './components/utility/controls/stream-type.vue';
import FormSocialiteInviteCode from './components/utility/forms/invite-code.vue';
import ModalSelector from './components/modals/selector.vue';
import OnboardingXeroMultiTenantSelector from './components/setup/xero-tenant-selector.vue';
import OnboardingAddDepartmentModal from './components/onboarding/modals/add-department.vue';
import OnboardingManageCategoriesModal from './components/onboarding/modals/manage-categories.vue';
import TableDepartments from './components/tables/departments-table.vue';
import TableCategories from './components/tables/categories-table.vue';
import TableScenarioAdjustments from './components/tables/scenario-adjustments-table.vue';
import WidgetAvatarList from './components/utility/widgets/avatar-list.vue';
import Activity from './components/utility/activity.vue';
import PurchasingApprovalsCard from './components/purchasing/approvals/card.vue';
import AddressForm from './components/forms/address.vue';

app.component('ew-card', Card);
app.component('approvals-row', ApprovalsRow);
app.component('budget-approvals-card', BudgetApprovalsCard);
app.component('drawer-budgeting-tab', DrawerBudgetingTab);
app.component('drawer-files-tab', DrawerFilesTab);
app.component('drawer-comments-tab', DrawerCommentsTab);
app.component('drawer-activity-tab', DrawerActivityTab);
app.component('drawer-adjustment-tab', DrawerAdjustmentTab);
app.component('drawer-extra-info-tab', DrawerExtraInfoTab);
app.component('control-addresses', ControlAddresses);
app.component('control-currencies', ControlCurrencies);
app.component('control-icon', ControlIcon);
app.component('control-select-two-way', ControlSelectTwoWay);
app.component('control-stream-type', ControlStreamType);
app.component('form-socialite-invite-code', FormSocialiteInviteCode);
app.component('modal-selector', ModalSelector);
app.component('onboarding-xero-multi-tenant-selector', OnboardingXeroMultiTenantSelector);
app.component('onboarding-add-department-modal', OnboardingAddDepartmentModal);
app.component('onboarding-manage-categories-modal', OnboardingManageCategoriesModal);
app.component('table-departments', TableDepartments);
app.component('table-categories', TableCategories);
app.component('table-scenario-adjustments', TableScenarioAdjustments);
app.component('widget-avatar-list', WidgetAvatarList);
app.component('activity', Activity);
app.component('purchasing-approvals-card', PurchasingApprovalsCard);
app.component('address-form', AddressForm);

app.use(pinia)
    .use(InstantSearch)
    .use(FloatingVue, {
        themes: {
            tooltip: {
                triggers: ['hover', 'focus'],
                placement: 'top',
                strategy: 'fixed',
                distance: 10,
            },
        },
    })
    //.use(ZiggyVue, Ziggy)
    .mount('#app');
