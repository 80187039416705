<template>
    <div class="configurable-fields-container">
        <div v-for="configurableField in configurableFields" :key="configurableField.id" class="configurable-field">
            <div class="field-title">
                <Textbox
                    v-model="configurableField.name"
                    :disabled="!canManage"
                    maxlength="255"
                    toggle
                    @update:model-value="saveChanges()"
                />
                <button v-if="canManage" class="remove-field" @click="removeField(configurableField)">Remove</button>
            </div>
            <div class="field-content">
                <p
                    v-if="!canEdit"
                    :class="{
                        'text-muted': !configurableField.value,
                    }"
                >
                    {{ configurableField.value ? configurableField.value : 'Not set' }}
                </p>
                <Textbox
                    v-else-if="canEdit"
                    v-model="configurableField.value"
                    maxlength="2000"
                    type="textarea"
                    toggle
                    @update:model-value="saveChanges()"
                    @toggled="(value) => (isEditing = value)"
                ></Textbox>
            </div>
        </div>
    </div>
</template>
<script>
import Textbox from './Textbox.vue';

export default {
    components: {
        Textbox,
    },
    props: {
        modelValue: {
            type: Array,
            default() {
                return [];
            },
        },
        editable: {
            type: Boolean,
            required: false,
            default: null,
        },
    },
    emits: ['updated'],
    data() {
        return {
            isLoading: false,
            isEditable: this.editable,
            isEditing: false,
            configurableFields: this.modelValue,
        };
    },
    computed: {
        canEdit() {
            // If the editable prop is set use that value, otherwise fall back to permissions so that this component can be used in places that might not have permissions available
            if (this.isEditable === true || this.isEditable === false) {
                return this.isEditable;
            }
            return Auth.can('edit configurable fields');
        },
        canManage() {
            // If the editable prop is set use that value, otherwise fall back to permissions so that this component can be used in places that might not have permissions available
            if (this.isEditable === true || this.isEditable === false) {
                return this.isEditable;
            }
            return Auth.can('manage configurable fields');
        },
    },
    watch: {
        editable(newVal) {
            this.isEditable = newVal;
        },
        modelValue(newVal) {
            this.configurableFields = newVal;
        },
    },
    methods: {
        toggleEdit() {
            if (!this.canEdit) {
                return;
            }
            this.isEditing = !this.isEditing;
        },
        saveChanges() {
            this.$emit('updated', this.configurableFields);
        },
        removeField(field) {
            if (!Auth.can('manage configurable fields')) {
                return;
            }
            swal.fire({
                title: 'Delete Configurable Field',
                text: 'Are you sure that you would like to permanently delete the ' + field.name + ' field?',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#4EA5D9',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete Field',
            }).then((result) => {
                if (!result.value) {
                    return;
                }
                this.isLoading = true;
                axios
                    .delete(
                        route('api.account.fields.delete', {
                            configurableField: field.id,
                        })
                    )
                    .then(() => {
                        this.configurableFields = this.configurableFields.filter((f) => f.id !== field.id);
                        this.saveChanges();
                    })
                    .finally(() => (this.isLoading = false));
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.configurable-fields-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > .configurable-field {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-template-rows: 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 0px;
        padding: 15px;
        border: 1px solid rgba(black, 0.1);
        border-radius: 3px;

        & > .field-title {
            grid-area: 1 / 1 / 2 / 2;

            &.is-editable {
                cursor: pointer;
                color: blue;
            }
        }
        & > .field-content {
            grid-area: 1 / 2 / 2 / 6;
        }

        .remove-field {
            border: none;
            background: none;
            color: $eventwise-danger;
            cursor: pointer;
            padding: 0;
            font-family: $eventwise-font-body;
            opacity: 0.75;
            font-size: 0.85em;

            &:hover {
                opacity: 1;
            }
        }
    }
}
</style>
