<template>
    <div class="row object-summary !tw-m-0">
        <DepartmentBudgetColumn :object="object" :type="objectType" />
        <div class="col-12 col-lg-7 col-xl-8 object-summary-table-container p-0">
            <DataTable
                :data="objectData.categories"
                :columns="[
                    {
                        title: 'Name',
                        field: 'name',
                    },
                    {
                        title: 'Fixed Amount',
                        field: 'total_fixed',
                    },
                    {
                        title: 'Budgeted Amount',
                        field: 'total_budgeted',
                    },
                ]"
            >
                <template #column(name)="{ row }">
                    {{ row.name }}
                </template>
                <template #column(total_fixed)="{ row }">
                    {{ localise(row.total_fixed, 2, true) }}
                </template>
                <template #column(total_budgeted)="{ row }">
                    {{ localise(row.total_budgeted, 2, true) }}
                </template>
                <template #footer>
                    <tfoot>
                        <tr>
                            <th>Remaining Budget</th>
                            <td class="tw-text-left">
                                {{ formatCurrency(totalFixed - totalActual, 2, currentEvent.currency_code) }}
                            </td>
                            <td class="tw-text-left">
                                {{ formatCurrency(totalBudgeted - totalActual, 2, currentEvent.currency_code) }}
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </DataTable>
        </div>
        <div class="col-12 col-lg-5 col-xl-4 object-summary-chart-container pl-3 pr-5 py-3">
            <Chart
                type="bar"
                :min-height="200"
                :max-height="200"
                :options="chartOptions"
                :data="categoryChartSeries"
                :downloadable="false"
            />
        </div>
    </div>
</template>
<script>
import DepartmentBudgetColumn from './DepartmentBudgetColumn.vue';
import DataTable from '../../tables/DataTable.vue';
import Chart from '../../Chart.vue';
import { formatCurrency } from '@/js/utils.js';
import { useDataStore } from '@/js/stores/DataStore.js';
import { totalFixed, totalBudgeted } from '@/js/utils/departmentTotals.js';
import { localise } from '@/js/utils.js';

export default {
    components: {
        DepartmentBudgetColumn,
        DataTable,
        Chart,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            objectData: this.object,
            objectType: this.type,
            currentEvent: useDataStore().get('current_event'),
            formatCurrency,
        };
    },
    computed: {
        categoryChartSeries() {
            return {
                labels: Object.values(this.objectData.categories).map((category) => category.name),
                datasets: [
                    {
                        label: 'Fixed Amount',
                        data: Object.values(this.objectData.categories).map((category) => category.total_fixed),
                        backgroundColor: ['#FE9C37'],
                    },
                    {
                        label: 'Budgeted Amount',
                        data: Object.values(this.objectData.categories).map((category) => category.total_budgeted),
                        backgroundColor: ['#2DDAB5'],
                    },
                ],
            };
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: false,
                        },
                    },
                    y: {
                        title: {
                            display: false,
                            text: 'Amount',
                        },
                        ticks: {
                            callback(value) {
                                return localise(value, 0, true);
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(context) {
                                return ' ' + localise(context.parsed.y, 2, true);
                            },
                        },
                    },
                },
            };
        },
        totalFixed() {
            return totalFixed(this.objectData);
        },
        totalBudgeted() {
            return totalBudgeted(this.objectData);
        },
        totalActual() {
            return this.objectData.total_actual;
        },
    },
    watch: {
        object: {
            handler(newVal) {
                this.objectData = newVal;
            },
            deep: true,
        },
        type(newVal) {
            this.objectType = newVal;
        },
    },
};
</script>
<style lang="scss" scoped>
.object-summary {
    .object-summary-table-container {
        @media (min-width: 992px) {
            padding-right: 0 !important;
        }
    }

    .object-summary-chart-container {
        @media (min-width: 992px) {
            border-left: 1px solid rgba(black, 0.1);
        }
    }
}
</style>
