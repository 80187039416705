<template>
    <CreatePayrun
        :selected-payments="selectedPayments"
        @return="$emit('return')"
        @create="({ date, title }) => createPayrun(date, title)"
    >
        <template #table="{ date }">
            <DataTable
                :endpoint="
                    route('api.account.event.payments.unassigned', {
                        end: date,
                    })
                "
                :columns="[
                    {
                        title: 'Department',
                        field: 'department_name',
                    },
                    {
                        title: 'Subcategory',
                        field: 'subcategory_name',
                    },
                    {
                        title: 'Purchase Order',
                        field: 'purchase_order',
                    },
                    {
                        title: 'Supplier',
                        field: 'supplier_name',
                    },
                    {
                        title: 'Invoice Number',
                        field: 'invoice_number',
                    },
                    {
                        title: 'Due Date',
                        field: 'date',
                    },
                    {
                        title: 'Amount',
                        field: 'amount',
                    },
                    {
                        title: '',
                        field: 'reject_reason',
                        width: 40,
                    },
                ]"
                checkable
                @update:model-value="(v) => (selectedPayments = v)"
            >
                <template #column(department_name)="{ row }">
                    <span
                        v-if="
                            row.invoice &&
                            row.invoice.purchase_orders &&
                            uniqueArray(row.invoice.purchase_orders, 'id').length === 1
                        "
                    >
                        {{ row.invoice.purchase_orders[0].category.department.name }}
                    </span>
                    <span
                        v-else-if="
                            row.invoice &&
                            row.invoice.purchase_orders &&
                            uniqueArray(row.invoice.purchase_orders, 'id').length > 1
                        "
                        class="text-muted"
                    >
                        Multiple
                    </span>
                    <span v-else class="text-muted">&mdash;</span>
                </template>
                <template #column(subcategory_name)="{ row }">
                    <span
                        v-if="
                            row.invoice &&
                            row.invoice.purchase_orders &&
                            uniqueArray(row.invoice.purchase_orders, 'id').length === 1
                        "
                    >
                        {{ row.invoice.purchase_orders[0].category.name }}
                    </span>
                    <span
                        v-else-if="
                            row.invoice &&
                            row.invoice.purchase_orders &&
                            uniqueArray(row.invoice.purchase_orders, 'id').length > 1
                        "
                        class="text-muted"
                    >
                        Multiple
                    </span>
                    <span v-else class="text-muted">&mdash;</span>
                </template>
                <template #column(purchase_order)="{ row }">
                    <span
                        v-if="
                            row.invoice &&
                            row.invoice.purchase_orders &&
                            uniqueArray(row.invoice.purchase_orders, 'id').length === 1
                        "
                    >
                        <a href="#" @click.prevent="$emit('viewPO', row.invoice.purchase_orders[0].id)">
                            {{ row.invoice.purchase_orders[0].title }}
                        </a>
                    </span>
                    <span
                        v-else-if="
                            row.invoice &&
                            row.invoice.purchase_orders &&
                            uniqueArray(row.invoice.purchase_orders, 'id').length > 1
                        "
                        class="text-muted"
                    >
                        Multiple
                    </span>
                    <span v-else class="text-muted">&mdash;</span>
                </template>
                <template #column(supplier_name)="{ row }">
                    <template v-if="row.invoice.purchase_orders[0].supplier">
                        {{ row.invoice.purchase_orders[0].supplier.name }}
                    </template>
                    <span v-else class="text-muted">None</span>
                </template>
                <template #column(invoice_number)="{ row }">
                    {{ row.invoice.invoice_number }}
                </template>
                <template #column(date)="{ row }">
                    {{ DateTime.fromISO(row.date).toLocaleString(DateTime.DATE_FULL) }}
                    <Tooltip
                        v-if="DateTime.fromISO(row.date).diff(DateTime.fromFormat(date, 'y-LL-dd'), ['days']).days < 0"
                        label="This payment would be overdue on the selected payrun date"
                    >
                        <i class="mdi mdi-alert text-danger"></i>
                    </Tooltip>
                </template>
                <template #column(amount)="{ row }">
                    {{ localise(row.amount, 2, true) }}
                </template>
                <template #column(reject_reason)="{ row }">
                    <Tooltip v-if="row.reject_reason && !row.is_paid" position="left">
                        <i class="mdi mdi-alert text-danger ml-1"></i>
                        <template #content>
                            This item was previously rejected for the following reason:
                            <div class="reject-quote">
                                {{ row.reject_reason }}
                            </div>
                        </template>
                    </Tooltip>
                </template>
            </DataTable>
        </template>
    </CreatePayrun>
</template>
<script>
import DataTable from '@/js/components/tables/DataTable.vue';
import CreatePayrun from '@/js/components/CreatePayrun.vue';
import { uniqueArray, toast } from '@/js/utils.js';
import Tooltip from '@/js/components/Tooltip.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        DataTable,
        CreatePayrun,
        Tooltip,
    },
    emits: ['created', 'viewPO', 'return'],
    data() {
        return {
            selectedPayments: [],
            DateTime,
        };
    },
    methods: {
        uniqueArray,
        createPayrun(date, title) {
            axios
                .post(route('api.account.event.payments.payruns.create'), {
                    title: title,
                    payment_date: date,
                    items: this.selectedPayments,
                })
                .then(() => {
                    toast('Success', 'Your payrun has been created successfully', 'success');
                    this.$emit('created');
                });
        },
    },
};
</script>
