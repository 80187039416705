<template>
    <MinimalPage :expanded="!!currentAccount">
        <template #before>
            <div class="logo-display">
                <img src="@/images/icon-white.svg" />
                <h1>eventwise</h1>
            </div>
            <div class="user-actions">
                <a v-if="currentUser.is_superadmin" class="py-3" :href="route('admin.index')">Admin</a>
                <a class="py-3" :href="route('me')">
                    <i class="mdi mdi-account"></i>
                    {{ currentUser.name }}
                </a>
                <a :href="route('auth.logout')">
                    <i class="mdi mdi-logout"></i>
                    Logout
                </a>
            </div>
        </template>
        <SelectAccount v-if="!currentPage && !currentAccount" @navigate="setPage" @select="selectAccount" />
        <CreateDemoAccount
            v-else-if="!currentAccount && currentPage == 'createDemo'"
            @created="selectAccount"
            @back="currentPage = null"
        />
        <CreateAccount
            v-else-if="!currentAccount && currentPage == 'createAccount'"
            @created="selectAccount"
            @back="currentPage = null"
        />
        <Account v-else-if="currentAccount" :account="currentAccount" @switch="currentAccount = null" />
        <div class="buefy-wrapper">
            <LoadingSpinner v-if="isLoading" label="Loading Information" />
        </div>
        <template #footnote>
            {{ eventwiseVersion === 'local' ? 'Development Mode (local)' : eventwiseVersion }}
            <span>&copy; Copyright Eventwise {{ new Date().getFullYear() }}.</span>
        </template>
    </MinimalPage>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import MinimalPage from './MinimalPage.vue';
import SelectAccount from './Onboarding/SelectAccount.vue';
import CreateDemoAccount from './Onboarding/CreateDemoAccount.vue';
import CreateAccount from './Onboarding/CreateAccount.vue';
import Account from './Onboarding/Account.vue';
import LoadingSpinner from './widgets/LoadingSpinner.vue';

export default {
    components: {
        LoadingSpinner,
        MinimalPage,
        SelectAccount,
        CreateDemoAccount,
        CreateAccount,
        Account,
    },
    data() {
        const store = useDataStore();
        return {
            isLoading: false,
            currentUser: store.get('current_user'),
            currentPage: null,
            currentAccount: null,
            eventwiseVersion: window.Eventwise.version,
        };
    },
    computed: {
        hasDemoAccount() {
            if (!this.currentUser) {
                return false;
            }
            return !!this.currentUser.accounts.find(
                (account) => account.is_demo && account.created_by === this.currentUser.id
            );
        },
    },
    watch: {
        currentAccount(newVal) {
            if (!newVal) {
                Auth.permissions = [];
            }
        },
    },
    methods: {
        setPage(page = null) {
            this.currentPage = page;
        },
        selectAccount(account) {
            this.isLoading = true;
            if (account.pivot && account.pivot.is_supplier) {
                window.location.href = route('account', {
                    account: account.id,
                });
                return;
            }
            axios
                .get(
                    route('api.account.permissions', {
                        account: account.id,
                    })
                )
                .then(({ data }) => {
                    Auth.permissions = data;
                    this.setPage();
                    this.currentAccount = account;
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.onboarding-action-list {
    padding: 0 20px;
    display: flex;
    gap: 10px;

    & > span {
        display: flex;
        align-items: center;
        user-select: none;
    }

    & > .onboarding-action-button {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        border-radius: 5px;
        font-size: 14px;
        color: inherit;
        background: rgba(black, 0.05);

        & > i {
            font-size: 20px;
            margin-right: 15px;
        }

        &:hover {
            background: rgba(black, 0.1);
        }

        & > div {
            display: flex;
            flex-direction: column;
            line-height: 120%;

            & > small {
                text-transform: uppercase;
                font-size: 11px;
                opacity: 0.66;
            }
        }

        &.disabled {
            opacity: 0.5;
            cursor: default;
            background: rgba(black, 0.05) !important;
        }
        &.loading {
            background: rgba(black, 0.05) !important;
            opacity: 1;
            font-size: 0px;

            &::after {
                font-size: 14px;
                font-family: 'Material Design Icons';
                content: '\F0772';
                animation: spin 0.5s infinite linear;
            }
        }
    }
}

.user-actions,
.buefy-wrapper > .user-actions {
    position: fixed;
    top: 0;
    right: 25px;
    display: flex;
    gap: 30px;
    align-items: center;

    & > .user-display {
        background: white;
        padding: 8px 12px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    & > a {
        color: white;
        opacity: 0.75;
        transition: all 0.1s ease;
        transform: scale(1);
        cursor: default;

        &[href]:hover {
            color: white;
            opacity: 1;
            transform: scale(1.05);
            cursor: pointer;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
