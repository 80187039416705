<template>
    <section class="page-content" style="padding-left: 70px">
        <div class="page-bar">
            <div class="page-info">
                <h4 v-if="pageTitle" class="page-title">{{ pageTitle }}</h4>
                <slot name="titleActions"></slot>
            </div>
            <nav class="submenu"><slot name="submenu" /></nav>
        </div>
        <div class="page-main-content">
            <div
                v-if="$slots.sidebar"
                class="page-sidebar"
                :class="{
                    'has-submenu': $slots.submenu,
                }"
            >
                <slot name="sidebar"></slot>
            </div>
            <div class="page-main-content-container tw-max-w-full">
                <slot name="above"></slot>
                <div
                    class="container-fluid"
                    :class="{
                        'px-0': paddingless,
                        '!tw-px-4': !paddingless,
                    }"
                >
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        paddingless: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pageTitle: null,
        };
    },
    watch: {
        title(newVal) {
            this.pageTitle = newVal;
        },
    },
    mounted() {
        if (this.title) {
            this.pageTitle = this.title;
        }
    },
};
</script>
<style lang="scss">
section.page-content {
    & > .page-bar {
        padding: 3px 16px 0 16px;
        background: white;

        & > .page-info {
            display: flex;
            align-items: center;
            width: 100%;

            .page-title {
                margin: 10px 0;
            }
        }

        & > .submenu:not(:empty) {
            display: flex;
            align-items: flex-end;
            min-height: 35px;

            & > .dropdown > button {
                color: theme-color('primary');
                background: none;
                border: none;
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                }
            }

            & > a,
            & > button,
            & > .dropdown > a,
            & > .dropdown > button {
                display: block;
                box-sizing: border-box;
                padding: 5px 4px;
                margin-top: 3px;
                opacity: 0.6;
                transition: all 0.1s ease;

                &.active {
                    border-bottom: 3px solid #3a667d;
                    color: #3a667d !important;
                    margin-top: 0;
                    opacity: 1;
                }

                &:hover:not(.active) {
                    border-bottom: 3px solid #4ea5d9;
                    margin-top: 0;
                    color: #4ea5d9;
                    opacity: 1;
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            & > .dropdown {
                &:hover {
                    & > a {
                        border-bottom: 3px solid #4ea5d9;
                        margin-top: 0;
                        color: #4ea5d9;
                        opacity: 1;
                    }
                    & > .dropdown-menu {
                        display: block;
                    }
                }
            }
        }
    }

    & > .page-main-content {
        display: flex;

        & > .page-sidebar {
            background: white;
            min-width: 260px;
            max-width: 300px;
            padding: 10px 20px;
            height: calc(100vh - 106px);

            &.has-submenu {
                height: calc(100vh - 140px);
            }

            border-right: 1px solid rgba(black, 0.05);
        }

        @media (max-width: 900px) {
            flex-direction: column;

            & > .page-sidebar {
                min-width: unset;
                padding: 16px;
                max-width: unset;
                height: unset;
            }
        }

        & > div:not(.page-sidebar) {
            flex: 1;
        }
    }
}
</style>
