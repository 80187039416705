<template>
    <Page :title="pageTitle">
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-if="!isLoading" class="buefy-wrapper">
            <Dashboard
                v-if="!activePayrun && !isCreating"
                @create="setPage('create')"
                @view="(payrunId) => (activePayrun = payrunId)"
            />
            <CreatePayrun v-else-if="isCreating" @return="isCreating = false" @created="isCreating = false" />
            <ViewPayrun v-else-if="activePayrun" :payrun-id="activePayrun" @return="activePayrun = null" />
        </div>
    </Page>
</template>
<script>
import Page from '../../Page.vue';
import Dashboard from './Payruns/Dashboard.vue';
import CreatePayrun from './Payruns/CreatePayrun.vue';
import ViewPayrun from './Payruns/ViewPayrun.vue';
import LoadingSpinner from '../../widgets/LoadingSpinner.vue';
import { setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        Page,
        Dashboard,
        CreatePayrun,
        ViewPayrun,
        LoadingSpinner,
    },
    data() {
        return {
            isCreating: false,
            isLoading: false,
            activePayrun: null,
        };
    },
    computed: {
        pageTitle() {
            return 'Payment Runs';
        },
    },
    mounted() {
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        setPage(page) {
            setRoute('account.payruns', {
                page: page,
            });
        },
        handlePageChange() {
            const payrunId = route().params.page ?? null;
            if (payrunId === 'create') {
                this.isCreating = true;
                this.activePayrun = null;
            } else if (payrunId) {
                this.fetchPayrun(payrunId);
            } else {
                this.activePayrun = null;
            }
        },
        fetchPayrun(payrunId) {
            this.isLoading = true;
            axios
                .get(
                    route('api.account.payruns.get', {
                        payrun: payrunId,
                    })
                )
                .then(({ data }) => (this.activePayrun = data))
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
