<template>
    <div class="integration-ticketmaster buefy-wrapper">
        <div class="d-flex align-items-center my-3">
            <a href="#" @click.prevent="$emit('back')">
                <i class="mdi mdi-arrow-left-thin"></i>
                Return to integrations
            </a>
        </div>
        <template v-if="!integration">
            <Banner type="is-warning">No integration!!</Banner>
        </template>
        <template v-else>
            <div class="integration-ticketmaster-organisation">
                <div class="flex-fill">
                    <h4>Organisation</h4>
                    <p class="flex-fill">
                        Connected to
                        <strong>{{ integration.organisation_name }}</strong>
                    </p>
                </div>
                <Button type="is-danger" @click="disconnect()">Disconnect</Button>
            </div>
            <div v-if="availableEvents" class="integration-ticketmaster-events">
                <h4>Events</h4>
                <p>Link your Eventwise events to those in Ticketmaster.</p>
                <DataTable
                    :data="events"
                    :columns="[
                        {
                            title: 'Eventwise',
                            field: 'eventwise',
                        },
                        {
                            title: 'Ticketmaster',
                            field: 'ticketmaster',
                            width: '50%',
                        },
                        {
                            field: 'actions',
                            numeric: true,
                        },
                    ]"
                >
                    <template #column(eventwise)="{ row }">
                        <h5>{{ row.name }}</h5>
                        <span v-if="row.starts_at">
                            {{ DateTime.fromISO(row.starts_at).toFormat('dd LLL yyyy') }}
                        </span>
                        <span v-if="row.starts_at && row.ends_at">&mdash;</span>
                        <span v-if="row.ends_at">
                            {{ DateTime.fromISO(row.ends_at).toFormat('dd LLL yyyy') }}
                        </span>
                    </template>
                    <template #column(ticketmaster)="{ row }">
                        <div class="tw-flex tw-gap-1 tw-items-center">
                            <Selector
                                v-model="row.integrations"
                                class="flex-fill me-2"
                                :options="availableEvents"
                                :all-options="availableEvents"
                                placeholder="Not linked"
                                object-value-key="eventId"
                                object-name-key="eventName"
                                multiple
                            >
                                <template #optionLabel="{ option }">
                                    {{ option.eventName }}
                                    <template v-if="option.startTime">
                                        ({{ DateTime.fromISO(option.startTime).toFormat('dd LLL yyyy') }})
                                    </template>
                                </template>
                            </Selector>
                        </div>
                    </template>
                    <template #actions>
                        <Tooltip
                            v-if="row.integrations && row.integrations.length"
                            :label="!hasChangedEvents ? 'Link Tickets' : 'Save your changes to link tickets'"
                            :position="!hasChangedEvents ? 'top' : 'left'"
                        >
                            <Button :disabled="hasChangedEvents" @click="linkTickets(row)">
                                <i class="mdi mdi-ticket-confirmation-outline"></i>
                            </Button>
                        </Tooltip>
                    </template>
                </DataTable>
                <div class="d-flex mt-3">
                    <Button class="!tw-ml-auto" type="is-success" :disabled="!hasChangedEvents" @click="saveEvents()">
                        Save Changes
                    </Button>
                </div>
            </div>
            <Modal
                ref="linkTicketsModal"
                :title="'Link Tickets' + (linkingEvent ? ' (' + linkingEvent.name + ')' : '')"
                :loading="isLoadingTickets"
                size="is-xxxlarge"
                paddingless
                @close="
                    linkingEvent = null;
                    linkingTickets = null;
                    ticketmasterTickets = null;
                "
            >
                <div v-if="linkingEvent">
                    <DataTable
                        :data="linkingTickets"
                        :columns="[
                            {
                                title: 'Eventwise',
                                field: 'eventwise',
                            },
                            {
                                title: 'Ticketmaster',
                                field: 'ticketmaster',
                            },
                        ]"
                    >
                        <template #column(eventwise)="{ row }">
                            <div class="d-flex flex-column">
                                {{ row.name }}
                                <small class="text-muted">
                                    {{ row.ticket_price ? localise(row.ticket_price, 2, true) : 'Free' }}
                                </small>
                            </div>
                        </template>
                        <template #column(ticketmaster)="{ row }">
                            <Selector
                                v-model="row.ticketmaster_id"
                                class="flex-fill me-2"
                                :options="ticketmasterTickets"
                                :all-options="ticketmasterTickets"
                                placeholder="Not linked"
                                object-value-key="priceId"
                                object-name-key="priceName"
                            >
                                <template #optionLabel="{ option }">
                                    <div class="d-flex flex-column">
                                        {{ option.priceName }} ({{
                                            option.face ? localise(option.face, 2, true) : 'Free'
                                        }})
                                        <small style="color: rgba(0, 0, 0, 0.5)">
                                            {{ option.secName ? option.secName + ' / ' : '' }}{{ option.eventName }}
                                        </small>
                                    </div>
                                </template>
                            </Selector>
                        </template>
                    </DataTable>
                </div>
                <template #footer>
                    <Button type="is-success" @click="saveTickets()">Save Changes</Button>
                </template>
            </Modal>
        </template>
    </div>
</template>
<script>
import { useDataStore } from '@/js/stores/DataStore.js';
import Selector from '../../../controls/Selector.vue';
import Modal from '../../../Modal.vue';
import Button from '@/js/components/controls/Button.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        Selector,
        Modal,
        Button,
        Banner,
        DataTable,
        Tooltip,
    },
    emits: ['back'],
    data() {
        const store = useDataStore();
        const formattedEvents = this.formatIntegrations(Object.values(store.get('account.events')));

        return {
            isLoading: false,
            isLoadingTickets: false,
            integration: store.get('account.integrations').find((i) => i.integrator === 'ticketmaster'),
            events: formattedEvents,
            originalEvents: JSON.stringify(formattedEvents),
            availableEvents: null,
            linkingEvent: null,
            linkingTickets: null,
            ticketmasterTickets: null,
            DateTime,
        };
    },
    computed: {
        hasChangedEvents() {
            return JSON.stringify(this.events) !== this.originalEvents;
        },
    },
    mounted() {
        if (this.integration) {
            this.getAvailableEvents();
        }
    },
    methods: {
        formatIntegrations(events) {
            return events.map((event) => {
                return {
                    ...event,
                    integrations: event.integrations
                        .filter((i) => i.integrator === 'ticketmaster')
                        .map((i) => parseInt(i.integration_id)),
                };
            });
        },
        getAvailableEvents() {
            this.isLoading = true;
            axios
                .get(route('api.account.integrations.ticketmaster.events'))
                .then(({ data }) => (this.availableEvents = data))
                .finally(() => (this.isLoading = false));
        },
        saveEvents() {
            this.isLoading = true;
            axios
                .post(route('api.account.integrations.ticketmaster.events.update'), {
                    events: this.events,
                })
                .then(() => (this.originalEvents = JSON.stringify(this.events)))
                .finally(() => (this.isLoading = false));
        },
        linkTickets(event) {
            this.linkingEvent = event;
            this.$refs.linkTicketsModal.open();
            this.isLoadingTickets = true;
            axios
                .get(
                    route('api.account.event.tickets', {
                        event: event.id,
                    })
                )
                .then(({ data }) => {
                    this.linkingTickets = data;
                    axios
                        .get(
                            route('api.account.integrations.ticketmaster.event.tickets', {
                                eventId: event.integrations.join(','),
                            })
                        )
                        .then((response) => (this.ticketmasterTickets = response.data))
                        .finally(() => (this.isLoadingTickets = false));
                });
        },
        saveTickets() {
            if (!this.linkingEvent) {
                return;
            }
            this.isLoadingTickets = true;
            axios
                .post(
                    route('api.account.event.tickets.update', {
                        event: this.linkingEvent.id,
                    }),
                    {
                        data: this.linkingTickets.map((t) => {
                            return {
                                id: t.id,
                                ticketmaster_id: t.ticketmaster_id,
                            };
                        }),
                    }
                )
                .then(() => this.$refs.linkTicketsModal.close())
                .finally(() => (this.isLoadingTickets = false));
        },
        disconnect() {
            this.isLoading = true;
            axios
                .delete(route('api.account.integrations.ticketmaster.disconnect'))
                .then(() => (window.location.href = route('account.integrations')));
        },
    },
};
</script>
<style lang="scss">
.integration-ticketmaster {
    & > .integration-ticketmaster-organisation {
        background: white;
        padding: 15px;
        display: flex;
        border-radius: 10px;
        margin-top: 10px;
    }
    & > .integration-ticketmaster-events {
        background: white;
        padding: 15px;
        border-radius: 10px;
        margin-top: 15px;
    }
}
</style>
