<template>
    <Page v-if="Auth.can('view tickets')" title="Ticketing">
        <template v-if="Object.values(store.get('current_event.ticket_types')).length && hasValidEventPeriod" #submenu>
            <a
                v-if="Auth.can('view tickets')"
                :href="
                    route('account.event.tickets', {
                        page: 'dashboard',
                    })
                "
                :class="{ active: activePage === 'dashboard' }"
                @click.prevent="setPage('dashboard')"
            >
                Dashboard
            </a>
            <a
                v-if="Auth.can('manage tickets')"
                :href="
                    route('account.event.tickets', {
                        page: 'bulk',
                    })
                "
                :class="{ active: activePage === 'bulk' }"
                @click.prevent="setPage('bulk')"
            >
                Bulk Entry
            </a>
            <a
                v-if="Auth.can('manage tickets')"
                :href="
                    route('account.event.tickets', {
                        page: 'transactions',
                    })
                "
                :class="{ active: activePage === 'transactions' }"
                @click.prevent="setPage('transactions')"
            >
                Transactions
            </a>
        </template>
        <template v-if="hasValidEventPeriod">
            <div v-if="Object.values(store.get('current_event.ticket_types')).length" class="mt-3">
                <PageDashboard
                    v-if="activePage === 'dashboard' && Auth.can('view tickets')"
                    @add-transaction="logTicketTransaction"
                />
                <PageBulkEdit
                    v-else-if="activePage === 'bulk' && Auth.can('manage tickets')"
                    ref="ticketEditor"
                    @add-transaction="logTicketTransaction"
                    @import-transaction="importTicketTransaction"
                />
                <PageTransactions v-else-if="activePage === 'transactions'" />
            </div>
            <p v-else class="empty-label m-3">
                <i class="las la-frown"></i>
                You haven't created any tickets just yet,
                <br />
                please
                <button class="btn btn-link px-0" @click.prevent="createTicketType">click here</button>
                to set your tickets up.
            </p>
        </template>
        <Banner v-else type="is-warning" class="mt-4">
            <strong class="tw-text-xl">Invalid Event Dates</strong>
            <p>
                You are unable to access the ticketing area until your event has valid dates.
                <br />
                Your event start and end dates must fall between your project start and end dates.
            </p>
            <Button v-if="Auth.can('update events')" class="tw-flex tw-gap-2 tw-items-center" @click="visitSettings()">
                <i class="mdi mdi-cog"></i>
                Visit Event Settings
            </Button>
        </Banner>
        <DrawerAddTicketTransaction ref="logTicketTransactionDrawer" @created="ticketTransactionCreated()" />
        <ModalImportTicketTransaction ref="importTicketTransactionModal" />
        <ModalCreateBankTransaction v-if="Auth.can('manage bank transactions')" />
        <CreateInitialTickets
            v-if="Auth.can('manage tickets') && !Object.values(store.get('current_event.ticket_types')).length"
        />
    </Page>
</template>
<script>
import Page from '../Page.vue';
import PageDashboard from './Ticketing/Dashboard.vue';
import PageBulkEdit from './Ticketing/BulkEdit.vue';
import PageTransactions from './Ticketing/Transactions.vue';
import DrawerAddTicketTransaction from './Ticketing/AddTicketTransaction.vue';
import ModalImportTicketTransaction from './Ticketing/ImportTicketTransaction.vue';
import ModalCreateBankTransaction from '../../../assets/js/eventbooks/components/modals/create/bank-transaction.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import CreateInitialTickets from '../modals/Ticketing/CreateInitialTickets.vue';
import Banner from '../Layout/Banner.vue';
import Button from '@/js/components/controls/Button.vue';
import { DateTime } from 'luxon';
import { setRoute, flushPageEvents } from '@/js/utils.js';

export default {
    components: {
        Page,
        PageDashboard,
        PageBulkEdit,
        PageTransactions,
        DrawerAddTicketTransaction,
        ModalImportTicketTransaction,
        ModalCreateBankTransaction,
        CreateInitialTickets,
        Banner,
        Button,
    },
    data() {
        const store = useDataStore();
        return {
            activePage: route().params.page ?? 'dashboard',
            currentEvent: store.get('current_event'),
            store: store,
        };
    },
    computed: {
        hasValidEventPeriod() {
            if (
                !this.currentEvent.project_start ||
                !this.currentEvent.project_end ||
                !this.currentEvent.starts_at ||
                !this.currentEvent.ends_at
            ) {
                return false;
            }
            const projectStart = DateTime.fromISO(this.currentEvent.project_start);
            const projectEnd = DateTime.fromISO(this.currentEvent.project_end);
            const startsAt = DateTime.fromISO(this.currentEvent.starts_at);
            const endsAt = DateTime.fromISO(this.currentEvent.ends_at);
            if (startsAt < projectStart || endsAt > projectEnd) {
                return false;
            }
            return true;
        },
    },
    mounted() {
        this.setPage(this.activePage);
        Eventbus.$on('pageChanged', () => this.handlePageChange());
    },
    beforeUnmount() {
        flushPageEvents();
    },
    methods: {
        handlePageChange() {
            this.activePage = route().params.page ?? 'dashboard';
        },
        setPage(page = 'dashboard') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setRoute('account.event.tickets', {
                page: page,
            });
        },
        logTicketTransaction(payload = null) {
            this.$refs.logTicketTransactionDrawer.open(payload);
        },
        importTicketTransaction({ id, name }) {
            this.$refs.importTicketTransactionModal.open({
                id,
                name,
            });
        },
        ticketTransactionCreated() {
            setTimeout(() => {
                if (!this.$refs.ticketEditor) {
                    return;
                }
                this.$refs.ticketEditor.initialise();
            }, 50);
        },
        createTicketType() {
            Eventbus.$emit('create:ticketType');
        },
        visitSettings() {
            window.location.href = route('account.event.eventsettings');
        },
    },
};
</script>
