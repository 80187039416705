<template>
    <div class="line-item-builder">
        <DataTable
            :data="lineItems"
            :columns="
                [
                    {
                        title: 'Description',
                        field: 'description',
                    },
                    {
                        title: 'Qty',
                        field: 'quantity',
                    },
                    {
                        title: 'Unit Price',
                        field: 'unit_price',
                    },
                    {
                        title: 'Net Total',
                        field: 'net_total',
                    },
                    {
                        title: 'Gross Total',
                        field: 'gross_total',
                    },
                    {
                        title: 'Invoiced',
                        field: 'is_invoiced',
                        hidden: hideInvoiced || hiddenColumns.includes('is_invoiced'),
                    },
                    {
                        title: 'Tax',
                        field: 'tax_rate_id',
                    },
                    isApprovedPurchaseOrder
                        ? {
                              title: 'Invoiced',
                              field: 'total_invoiced_gross',
                          }
                        : null,
                    editable
                        ? {
                              title: '',
                              field: 'actions',
                              class: '!tw-text-right',
                              width: 75,
                              hidden: hiddenColumns.includes('actions'),
                          }
                        : null,
                ].filter((c) => c)
            "
        >
            <template v-if="$slots['col-prepend-header']" #col-prepend-header>
                <slot name="col-prepend-header"></slot>
            </template>
            <template v-if="$slots['col-prepend']" #col-prepend="{ row, index }">
                <slot name="col-prepend" :row="row" :index="index"></slot>
            </template>
            <template #column(index)="{ index }">
                <span v-if="index + 1 === 0"></span>
                <span v-else>{{ index + 1 }}</span>
            </template>
            <template #column(description)="{ index }">
                <Textarea
                    v-if="editable && !hasInvoices(index)"
                    v-model="lineItems[index].description"
                    maxlength="64"
                    placeholder="Enter a description"
                    expandable
                />
                <span v-else>{{ lineItems[index].description }}</span>
            </template>
            <template #column(quantity)="{ row, index }">
                <EditableText
                    v-if="editable && !hasInvoices(index)"
                    v-model="row.quantity"
                    type="number"
                    :min-value="0"
                    :decimal-places="0"
                    @update:model-value="handleQuantityChange(row, index)"
                />
                <span v-else>{{ row.quantity > 0 ? row.quantity : '' }}</span>
            </template>
            <template #column(unit_price)="{ row, index }">
                <EditableText
                    v-if="editable && !hasInvoices(index)"
                    v-model="row.unit_price"
                    type="currency"
                    @update:model-value="handleUnitPriceChange(row, index)"
                />
                <span v-else>{{ localise(row.unit_price, 2, true) }}</span>
            </template>
            <template #column(tax_rate_id)="{ index, row }">
                <TaxRateSelector
                    v-if="editable && !hasInvoices(index)"
                    v-model="row.tax_rate_id"
                    :type="taxRateType"
                    nullable
                    compact
                />
                <span v-else>
                    {{
                        row.tax_rate_id ? localise(taxRates[lineItems[index].tax_rate_id].rate, 2, false) + '%' : 'None'
                    }}
                </span>
            </template>
            <template #column(net_total)="{ row }">
                <span>{{ localise(getSubtotal(row), 2, true) }}</span>
            </template>
            <template #column(gross_total)="{ row }">
                <span>{{ localise(getTotal(row), 2, true) }}</span>
            </template>
            <template #column(is_invoiced)="{ row, index }">
                <Tooltip v-if="row.is_invoiced" label="Fully Invoiced">
                    <i class="tw-text-green-500 mdi mdi-check-circle" />
                </Tooltip>
                <Tooltip v-else-if="hasInvoices(index)" label="Part Invoiced">
                    <i class="mdi mdi-dots-horizontal-circle tw-text-orange-500"></i>
                </Tooltip>
                <Tooltip v-else label="Not Invoiced">
                    <i class="tw-text-red-500 mdi mdi-close-circle" />
                </Tooltip>
            </template>
            <template #column(total_invoiced_gross)="{ row }">
                {{ localise(row.total_invoiced_gross, 2, true) }}
            </template>
            <template #column(actions)="{ index }">
                <Button v-if="!hasInvoices(index)" type="is-danger" @click="lineItems.splice(index, 1)">
                    <i class="mdi mdi-close"></i>
                </Button>
            </template>
            <template v-if="$slots['col-append-header']" #col-append-header>
                <slot name="col-append-header"></slot>
            </template>
            <template v-if="$slots['col-append']" #col-append="{ row, index }">
                <slot name="col-append" :row="row" :index="index"></slot>
            </template>
            <template v-if="lineItems.length > 1 && !hideTotals" #footer>
                <tfoot>
                    <tr>
                        <th colspan="3"></th>
                        <th>
                            {{ localise(subtotalSum, 2, true) }}
                        </th>
                        <th>
                            {{ localise(totalSum, 2, true) }}
                        </th>
                    </tr>
                </tfoot>
            </template>
        </DataTable>
        <Button
            v-if="editable"
            type="is-link"
            class="tw-w-full tw-flex tw-gap-1 tw-items-center"
            @click="
                lineItems.push({
                    description: '',
                    quantity: 0,
                    unit_price: 0,
                    tax_rate_id: defaultTaxRateId,
                })
            "
        >
            <i class="mdi mdi-plus"></i>
            Add line item
        </Button>
    </div>
</template>
<script setup>
import EditableText from '../controls/EditableText.vue';
import Textarea from '@/js/components/controls/Textarea.vue';
import TaxRateSelector from '../controls/TaxRateSelector.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import { computed, ref, watch } from 'vue';
import DataTable from '@/js/components/tables/DataTable.vue';

const props = defineProps({
    editable: {
        type: Boolean,
        default: true,
    },
    taxRateType: {
        type: String,
        required: false,
        default: null,
    },
    defaultTaxRateId: {
        type: Number,
        default: null,
    },
    hideInvoiced: {
        type: Boolean,
        default: false,
    },
    hideTotals: {
        type: Boolean,
        default: false,
    },
    isApprovedPurchaseOrder: {
        type: Boolean,
        default: false,
    },
    hiddenColumns: {
        type: Array,
        default: () => [],
    },
    modelValue: {
        type: Array,
        default: () => [],
    },
});
const lineItems = ref(props.modelValue);
const emit = defineEmits(['update:model-value', 'unit-price-change', 'quantity-change']);
const store = useDataStore();
const taxRates = store.get('account.tax_rates');

watch(props.modelValue, (newVal) => {
    lineItems.value = newVal;
});
watch(lineItems.value, (newVal) => {
    emit('update:model-value', newVal);
});

const subtotalSum = computed(() => {
    if (lineItems.value.length <= 1) {
        return 0;
    }
    return lineItems.value.reduce((accumulator, currentValue) => accumulator + getSubtotal(currentValue), 0);
});
const totalSum = computed(() => {
    return lineItems.value.reduce((accumulator, currentValue) => accumulator + getTotal(currentValue), 0);
});
const getSubtotal = (lineItem) => {
    return (lineItem.quantity ? lineItem.quantity : 0) * (lineItem.unit_price ? parseFloat(lineItem.unit_price) : 0);
};
const getTotal = (lineItem) => {
    const subtotal = getSubtotal(lineItem);
    if (!lineItem.tax_rate_id) {
        return subtotal;
    }
    const taxRate = taxRates[lineItem.tax_rate_id];
    return subtotal + (subtotal / 100) * taxRate.rate;
};
const hasInvoices = (index) => {
    const item = lineItems.value[index] ?? null;
    if (!item) {
        return false;
    }
    return item.hasOwnProperty('customer_invoices') && Object.values(item.customer_invoices).length;
};
const handleUnitPriceChange = (row, index) => {
    emit('unit-price-change', row, index);
};
const handleQuantityChange = (row, index) => {
    emit('quantity-change', row, index);
};
</script>
<style lang="scss" scoped>
.line-item-builder {
    user-select: none;
}
.purchase-totals {
    width: 11%;
    padding-top: 10px;
    text-align: right !important;
    color: #363636;

    &.topline {
        border-top: 1px #c3c3c3 solid;
    }
}
.amount-total {
    width: 11%;
    padding-top: 10px;
    text-align: right !important;
    color: #363636;

    &.topline {
        border-top: 1px #c3c3c3 solid;
    }
}
</style>
