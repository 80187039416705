<template>
    <Drawer ref="modal" title="New Budget Entry" :loading="!isLoaded" size="is-medium" @closing="resetSelection()">
        <div class="tw-flex tw-gap-2 tw-p-4 tw-border-b">
            <FormField class="tw-flex-1" label="Department">
                <Select v-model="selectedDepartmentId">
                    <option v-for="option in departments" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </Select>
            </FormField>
            <FormField v-if="selectedDepartmentId" class="tw-flex-1" label="Subcategory">
                <Select v-model="selectedCategory">
                    <option v-for="option in selectedDepartment.categories" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </Select>
            </FormField>
        </div>
        <div v-if="selectedCategory" class="tw-flex tw-flex-col tw-gap-2.5 tw-p-4">
            <FormField
                label="Title"
                :error="
                    duplicateEntry && !isCheckingDuplicateEntry ? 'A budget entry with this title already exists' : null
                "
                :success="
                    duplicateEntry === false && !isCheckingDuplicateEntry
                        ? 'This title has not been used elsewhere'
                        : null
                "
            >
                <Textbox
                    v-model="newEntryData.name"
                    maxlength="255"
                    @update:model-value="debouncedDuplicateEntryName()"
                />
            </FormField>
            <FormField label="Contact">
                <EndpointSelector
                    v-model="newEntryData.user_id"
                    :endpoint="
                        route('api.account.event.categories.contacts', {
                            category: selectedCategory,
                        })
                    "
                />
            </FormField>
            <FormField label="Supplier">
                <SupplierSelector v-if="selectedCategory" v-model="newEntryData.supplier_id" auto-default />
            </FormField>
            <FormField label="Fixed Amount">
                <NumberInput v-model="newEntryData.fixed_cost" :currency="store.get('current_event.currency_symbol')" />
            </FormField>
            <FormField label="Budgeted Amount">
                <NumberInput
                    v-model="newEntryData.budgeted_cost"
                    :currency="store.get('current_event.currency_symbol')"
                />
            </FormField>
        </div>
        <div class="drawer-row fill"></div>
        <template #footer>
            <div class="tw-flex tw-justify-between">
                <Button @click="closeDrawer()">Cancel</Button>
                <Button :disabled="!validateData() || !isLoaded" type="is-info" @click="createEntry()">
                    Create Budget Entry
                </Button>
            </div>
        </template>
    </Drawer>
</template>
<script>
import SupplierSelector from '@/js/components/controls/SupplierSelector.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import Button from '@/js/components/controls/Button.vue';
import Drawer from '@/js/components/Drawer.vue';
import FormField from '@/js/components/widgets/FormField.vue';
import Select from '@/js/components/controls/Select.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import { debounce } from 'lodash';
import EndpointSelector from '@/js/components/controls/EndpointSelector.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';

export default {
    components: {
        EndpointSelector,
        SupplierSelector,
        Select,
        Drawer,
        Button,
        FormField,
        Textbox,
        NumberInput,
    },
    data() {
        const store = useDataStore();
        return {
            store,
            isLoaded: true,
            departments: JSON.parse(JSON.stringify(Object.values(store.get('current_event.revenue_streams'))))
                .filter((rs) => rs.stream_type !== 0)
                .map((rs) => {
                    rs.id = rs.department_id;
                    return rs;
                })
                .concat(Object.values(store.get('current_event.cost_departments'))),
            selectedDepartment: null,
            selectedDepartmentId: null,
            selectedCategory: null,
            newEntryData: {
                supplier_id: null,
            },
            isCheckingDuplicateEntry: false,
            duplicateEntry: null,
        };
    },
    watch: {
        selectedDepartmentId: {
            handler(newVal) {
                this.selectedDepartment = this.departments.filter((dept) => dept.id === newVal)[0];
            },
        },
    },
    mounted() {
        Eventbus.$on('newBudgetEntry:open', (payload) => {
            this.resetSelection();
            this.resetForm();
            if (!Auth.can('create budget entries')) {
                swal.fire('Error', 'You do not have permission to create budget entries', 'error');
                this.closeDrawer();
                this.$refs.modal.close();
                return;
            }
            if (payload) {
                if (payload.department) {
                    const department = this.departments.find((dept) => dept.id === payload.department);
                    this.selectedDepartment = department;
                    this.selectedDepartmentId = payload.department;
                }
                if (payload.category && this.selectedDepartment) {
                    const category = Object.values(this.selectedDepartment.categories).find(
                        (cat) => cat.id === payload.category
                    );
                    if (category) {
                        this.selectedCategory = category.id;
                    }
                }
            }
            this.openModal();
        });
    },
    methods: {
        openModal() {
            this.$refs.modal.open();
        },
        resetData() {
            this.selectedDepartment = null;
            this.selectedDepartmentId = null;
            this.selectedCategory = null;
            this.newEntryData = {};
            this.duplicateEntry = null;
        },
        closeDrawer() {
            this.isLoaded = false;
            this.resetSelection();
            this.resetForm();
            this.$refs.modal.close();
        },
        resetSelection() {
            this.selectedCategory = null;
            this.selectedDepartment = null;
        },
        resetForm() {
            this.newEntryData = {
                supplier_id: null,
                fixed_cost: 0,
                budgeted_cost: 0,
                user_id: null,
                name: '',
                is_markup: false,
            };
        },
        validateData() {
            if (!this.selectedDepartmentId || !this.selectedCategory) {
                return false;
            }
            const validateProperties = ['name', 'user_id', 'fixed_cost', 'budgeted_cost'];
            let isValid = true;
            validateProperties.forEach((property) => {
                isValid = isValid && (this.newEntryData[property] || typeof this.newEntryData[property] === 'number');
            });
            return isValid;
        },
        createEntry() {
            if (!this.validateData()) {
                return;
            }
            this.isLoaded = false;
            this.newEntryData.category_id = this.selectedCategory;
            axios
                .post(route('api.account.event.entries.create'), this.newEntryData)
                .then((response) => {
                    Eventbus.$emit('budget:entry', {
                        entry: response.data.id,
                    });
                    Eventbus.$emit('reload:entries', `department_${this.selectedDepartment.id}`);
                    Eventbus.$emit('reload:entries', `category_${this.newEntryData.category_id}`);
                    this.closeDrawer();
                    this.$refs.modal.close();
                    setTimeout(() => {
                        this.resetData();
                    }, 100);
                })
                .finally(() => {
                    this.isLoaded = true;
                });
        },
        //duplicate entry and category
        duplicateEntryName() {
            if (!this.newEntryData.name) {
                return;
            }
            this.isCheckingDuplicateEntry = true;
            axios
                .get(
                    route('api.account.event.entries.checkName', {
                        name: this.newEntryData.name,
                    })
                )
                .then(({ data }) => {
                    this.duplicateEntry = data;
                })
                .finally(() => (this.isCheckingDuplicateEntry = false));
        },
        debouncedDuplicateEntryName: debounce(function () {
            this.duplicateEntryName();
        }, 1000),
        selectCurrentUser(data) {
            if (!Auth || !Auth.user) {
                return;
            } else if (!data.find((user) => user.id === Auth.user.id)) {
                return;
            }
            this.newEntryData.user_id = Auth.user.id;
        },
    },
};
</script>
<style lang="scss" scoped>
.drawer-row {
    display: flex;
    padding: 15px 25px;
    max-height: 999vh;
    transition: all 0.2s ease-in-out;
    flex-wrap: wrap;
    &.collapsed {
        overflow: hidden;
        max-height: 0px;
        transition: all 0.2s ease-in-out;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-color: transparent !important;
    }
    .fill,
    &.fill {
        flex-grow: 1;
    }
    .break {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        height: 0;
    }
    .toggle-icon {
        margin: 0 !important;
    }
    &.nowrap {
        flex-wrap: nowrap;
    }
}

.drawer-column {
    display: flex;
    flex-direction: column;
    .fill {
        flex-grow: 1;
    }
}
</style>
