<template>
    <div class="chart-container">
        <div v-if="downloadable && chart" class="chart-options">
            <Dropdown :anchor-right="true">
                <a v-if="downloadable" download="chart.png" :href="chartImageString" class="tw-w-full" target="_blank">
                    <i class="mdi mdi-image-frame"></i>
                    Download Image
                </a>
            </Dropdown>
        </div>
        <div
            class="chart"
            :style="{
                'min-height': minHeight + 'px',
                'max-height': maxHeight + 'px',
            }"
        >
            <canvas ref="chart"></canvas>
        </div>
    </div>
</template>
<script>
import { Chart } from 'chart.js/auto';
import DragDataPlugin from 'chartjs-plugin-dragdata';
import Dropdown from './controls/Dropdown.vue';

export default {
    components: {
        Dropdown,
    },
    props: {
        type: {
            type: String,
            default: 'bar',
        },
        data: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        downloadable: {
            type: Boolean,
            default: true,
        },
        minHeight: {
            type: [String, Number],
            default: 400,
        },
        maxHeight: {
            type: [String, Number],
            default: 400,
        },
    },
    data() {
        this.chart = null;
        return {
            chartImageString: null,
        };
    },
    computed: {
        chartData() {
            if (!this.data || !this.data.datasets) {
                return null;
            }
            return {
                labels: this.data.labels,
                datasets: this.data.datasets.map((dataset) => {
                    return {
                        ...dataset,
                        dragData: dataset.dragData || false,
                    };
                }),
            };
        },
    },
    watch: {
        data: {
            handler() {
                setTimeout(() => {
                    this.chart.data = this.chartData;
                    this.chart.update();
                }, 100);
            },
            deep: true,
        },
        options: {
            handler() {
                const ctx = this.$refs.chart;
                this.generateDownloadUrl(ctx);
            },
            deep: true,
        },
    },
    mounted() {
        const ctx = this.$refs.chart;
        const extOptions = this.options;
        Chart.defaults.font.family = 'Poppins';
        Chart.register(DragDataPlugin);
        this.chart = new Chart(ctx, {
            type: this.type,
            data: this.chartData,
            backgroundColor: ['rgb(255, 255, 255)'],
            options: {
                ...extOptions,
                layout: {
                    padding: {
                        left: 5,
                        right: 5,
                    },
                },
                animation: {
                    onComplete: (ctx) => {
                        this.generateDownloadUrl(ctx);
                    },
                },
            },
        });
    },
    methods: {
        generateDownloadUrl(ctx) {
            if (this.downloadable && this.chart && ctx && ctx.chart) {
                this.chartImageString = ctx.chart.toBase64Image();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.chart-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & .chart-options {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }

    & > .chart {
        flex: 1;
        overflow: auto;
    }
}
</style>
