<template>
    <div class="stepper-container">
        <div v-for="(step, index) in availableSteps" :key="index" class="stepper-step">
            <div
                class="stepper-step-content"
                :class="{
                    active: stepIndex === index,
                    'is-future': stepIndex < index,
                    'is-last': index === stepIndex - 1,
                    'is-past': stepIndex > index,
                }"
            >
                <div class="stepper-step-badge">
                    <i v-if="stepIndex > index" class="mdi mdi-check-bold"></i>
                    <span v-else>{{ index + 1 }}</span>
                </div>
                <span>{{ step }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        steps: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: [String],
            default: null,
        },
    },
    data() {
        return {
            availableSteps: [],
            currentStep: null,
        };
    },
    computed: {
        stepIndex() {
            return this.availableSteps.indexOf(this.currentStep);
        },
    },
    watch: {
        steps(newVal) {
            this.availableSteps = newVal;
        },
        modelValue(newVal) {
            this.currentStep = newVal;
        },
    },
    mounted() {
        this.availableSteps = this.steps;
        this.currentStep = this.modelValue;
    },
};
</script>
<style lang="scss">
@import '@/assets/sass/eventbooks/_variables.scss';

.stepper-container {
    width: 100%;
    display: flex;
    align-items: center;
    user-select: none;

    & > .stepper-step {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 1px;
            background: rgb(225, 225, 225);
            position: absolute;
            top: 12px;
            left: 0;
        }

        & > .stepper-step-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & > .stepper-step-badge {
                width: 24px;
                height: 24px;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                z-index: 10;
                border: 1px solid rgb(225, 225, 225);
                font-size: 14px;
            }
            & > span {
                font-size: 12px;
                margin-top: 2px;
            }

            &.active {
                & > .stepper-step-badge {
                    background: $eventwise-primary;
                    border-color: $eventwise-primary;
                    color: white;
                }
                &:before {
                    content: '';
                    height: 2px;
                    background: $eventwise-primary-light;
                    width: 50%;
                    position: absolute;
                    top: 11px;
                    left: 0;
                }
            }
            &.is-future {
                color: rgba(black, 0.33);
            }
            &.is-past {
                & > .stepper-step-badge {
                    background: $eventwise-secondary-dark;
                    border-color: $eventwise-secondary-dark;
                    color: white;
                }
                &:before {
                    content: '';
                    height: 2px;
                    background: $eventwise-secondary;
                    width: 50%;
                    position: absolute;
                    top: 11px;
                    left: 0;
                }
                &:after {
                    content: '';
                    height: 2px;
                    background: $eventwise-secondary;
                    width: 50%;
                    position: absolute;
                    top: 11px;
                    right: 0;
                }

                &.is-last {
                    &:after {
                        content: '';
                        height: 2px;
                        background: $eventwise-primary-light;
                        width: 50%;
                        position: absolute;
                        top: 11px;
                        right: 0;
                    }
                }
            }
        }
    }
}
</style>
