<template>
    <div>
        <LoadingSpinner v-if="isLoading" label="Loading Information" />
        <div v-else class="eventwise-two-way-selector d-flex">
            <div class="flex-fill pr-4">
                <h4 v-if="titles" class="mb-2">Available {{ itemName }}</h4>
                <slot name="description-left"></slot>
                <div class="dragArea">
                    <div
                        v-for="element in availableOptions"
                        :key="id + '.optionId' + element.id"
                        class="item"
                        @click="selectItem(element)"
                    >
                        <slot name="item" :element="element">
                            {{ element.name }}
                        </slot>
                    </div>
                    <p v-if="!availableOptions.length" class="empty-label py-2">
                        There are no available {{ itemName }}
                    </p>
                </div>
            </div>
            <div v-show="buttonsEnabled" class="px-3 align-self-center button-panel">
                <Tooltip label="Select all available options">
                    <Button type="is-info" :disabled="!availableOptions.length" @click="selectAll()">
                        <i class="mdi mdi-chevron-double-right"></i>
                    </Button>
                </Tooltip>
                <br />
                <Tooltip label="Clear all selected options">
                    <Button type="is-info" :disabled="!selectedOptions.length" @click="deselectAll()">
                        <i class="mdi mdi-chevron-double-left"></i>
                    </Button>
                </Tooltip>
            </div>
            <div class="flex-fill pl-4">
                <h4 v-if="titles" class="mb-2">Selected {{ itemName }}</h4>
                <slot name="description-right"></slot>
                <div class="dragArea">
                    <div
                        v-for="element in selectedOptions"
                        :key="id + '.selectedOptionId' + element.id"
                        class="item selected"
                        @click="deselectItem(element)"
                    >
                        <slot name="item" :element="element">
                            {{ element.name }}
                        </slot>
                    </div>
                    <p v-if="!selectedOptions.length" class="empty-label py-2">There are no selected {{ itemName }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from '../../../../../../../js/components/widgets/LoadingSpinner.vue';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        LoadingSpinner,
        Button,
        Tooltip,
    },
    props: {
        id: {
            type: String,
            default: Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(0, 8),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        options: {
            type: [Array, Object, Boolean],
            default: false,
        },
        modelValue: {
            type: [Array, Object, Boolean],
            default: false,
        },
        itemName: {
            type: String,
            default: 'Items',
        },
        buttons: {
            type: Boolean,
            default: true,
        },
        titles: {
            type: Boolean,
            default: true,
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            isLoading: false,
            buttonsEnabled: true,
            availableOptions: [],
            selectedOptions: [],
        };
    },
    watch: {
        options: function (newOptions) {
            this.reprocessOptions(newOptions, this.selectedOptions);
        },
        buttons: function (newButtons) {
            this.buttonsEnabled = newButtons;
        },
        value: function (newValue) {
            this.reprocessSelectedOptions(newValue);
        },
        loading: function (newLoading) {
            this.isLoading = newLoading;
        },
    },
    mounted() {
        this.isLoading = this.loading;
        this.isLoading = false;
        this.buttonsEnabled = this.buttons;
        this.reprocessOptions(this.options, this.modelValue);
        Eventbus.$on('two-way:reload', (payload) => {
            this.selectedOptions = payload.options;
            this.reprocessOptions(this.options, this.selectedOptions);
        });
    },
    methods: {
        reprocessOptions(options, selected) {
            if (!options) {
                this.reprocessSelectedOptions(selected);
                return;
            } else if (options instanceof Array) {
                this.availableOptions = options;
                this.reprocessSelectedOptions(selected);
                return;
            }
            this.availableOptions = [options];
            this.reprocessSelectedOptions(selected);
        },
        reprocessSelectedOptions(selected) {
            if (!selected.length) {
                return;
            } else if ((!selected) instanceof Array) {
                selected = [selected];
            }
            this.selectedOptions = selected;
            this.availableOptions = this.options.filter(
                (option) => !selected.some((selectedOption) => selectedOption.id == option.id)
            );
        },
        selectAll() {
            this.selectedOptions = this.selectedOptions.concat(this.availableOptions);
            this.availableOptions = [];
            this.emitValue();
        },
        deselectAll() {
            this.availableOptions = this.availableOptions.concat(this.selectedOptions);
            this.selectedOptions = [];
            this.emitValue();
        },
        selectItem(item) {
            this.availableOptions = this.availableOptions.filter((option) => option.id !== item.id);
            this.selectedOptions.push(item);
            this.emitValue();
        },
        deselectItem(item) {
            this.selectedOptions = this.selectedOptions.filter((option) => option.id !== item.id);
            this.availableOptions.push(item);
            this.emitValue();
        },
        filterList(value) {
            if (!value || value.length < 1) {
                this.availableOptions = this.options;
                return;
            }
            this.availableOptions = this.options.filter((option) =>
                option.name.toLowerCase().includes(value.toLowerCase())
            );
        },
        emitValue() {
            this.$emit('update:modelValue', this.selectedOptions);
        },
    },
};
</script>
<style scoped>
.eventwise-two-way-selector {
    overflow-y: auto;

    h3 {
        padding-left: 10px;
    }

    .dragArea {
        box-sizing: border-box;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        min-height: 300px;
        max-height: 300px;
        overflow-y: auto;
        transition: all 0.05s ease-in-out;

        .item {
            background: rgba(0, 0, 0, 0.025);
            cursor: pointer;
            box-sizing: border-box;
            padding: 8px 15px;
            transition: all 0.05s ease-in-out;
            margin-bottom: 4px;
            border-radius: 4px;

            &.selected {
                background: rgba($eventwise-primary, 0.1);
            }

            &:hover {
                background: rgba(0, 0, 0, 0.05);
            }
        }
    }

    .button-panel {
        display: flex;
        flex-direction: column;
    }
}
</style>
