<template>
    <DataTable
        ref="table"
        :endpoint="endpoint"
        :columns="[
            {
                title: 'Invoice #',
                field: 'invoice_number',
                sortable: true,
            },
            {
                title: 'Title',
                field: 'title',
                sortable: true,
            },
            {
                title: 'Date',
                field: 'invoice_date',
                sortable: true,
            },
            {
                title: 'Due Date',
                field: 'due_date',
                sortable: true,
            },
            {
                title: 'Subtotal',
                field: 'subtotal',
                sortable: true,
            },
            {
                title: 'Total',
                field: 'total_value',
                sortable: true,
            },
            {
                title: 'Paid',
                field: 'total_paid',
                sortable: true,
            },
            {
                title: 'Credit',
                field: 'total_credit',
                sortable: true,
            },
            {
                title: 'Outstanding',
                field: 'outstanding',
            },
            {
                title: '',
                field: 'actions',
                width: 100,
                class: '!tw-text-right',
                visible: Auth.can('view supplier invoices'),
            },
        ]"
    >
        <template #column(invoice_number)="{ row }">
            {{ row.invoice_number }}
        </template>
        <template #column(title)="{ row }">
            {{ row.title }}
        </template>
        <template #column(invoice_date)="{ row }">
            {{ DateTime.fromISO(row.invoice_date).toLocaleString(DateTime.DATE_MED) }}
        </template>
        <template #column(due_date)="{ row }">
            {{ DateTime.fromISO(row.due_date).toLocaleString(DateTime.DATE_MED) }}
        </template>
        <template #column(subtotal)="{ row }">
            {{ localise(row.subtotal, 2, true) }}
        </template>
        <template #column(total_value)="{ row }">
            {{ localise(row.total_value, 2, true) }}
        </template>
        <template #column(total_paid)="{ row }">
            {{ localise(row.total_paid, 2, true) }}
        </template>
        <template #column(total_credit)="{ row }">
            {{ localise(row.total_credit, 2, true) }}
        </template>
        <template #column(outstanding)="{ row }">
            {{ localise(row.total_value - row.total_credit - row.total_paid, 2, true) }}
        </template>
        <template #column(actions)="{ row }">
            <Tooltip v-if="Auth.can('view supplier invoices')" label="View" class="tw-ml-1" position="left">
                <Button @click="viewInvoice(row)">
                    <i class="mdi mdi-eye-outline"></i>
                </Button>
            </Tooltip>
        </template>
    </DataTable>
</template>
<script>
import debounce from 'lodash/debounce';
import Button from '@/js/components/controls/Button.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import DataTable from '@/js/components/tables/DataTable.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        Button,
        Tooltip,
        DataTable,
    },
    props: {
        endpoint: {
            type: String,
        },
    },
    data() {
        return {
            DateTime,
        };
    },
    watch: {
        searchQuery() {
            this.debouncedReloadData();
        },
    },
    mounted() {
        Eventbus.$on('supplierInvoice:authorised', () => this.reloadData());
    },
    methods: {
        reloadData(newPage = null) {
            this.$refs.table.refreshData(newPage);
        },
        debouncedReloadData: debounce(function () {
            this.reloadData();
        }, 500),
        viewInvoice(invoice) {
            Eventbus.$emit('viewInvoice', 'SupplierInvoice', {
                event: invoice.purchase_orders[0].event_id,
                purchaseOrder: invoice.purchase_orders[0].id,
                supplierInvoice: invoice.id,
            });
        },
    },
};
</script>
